import React from 'react';
import { Collapse } from '@mui/material';
import { SuspenseBox } from '@components/styled/suspense';
import CardList from './CardList';

const ManageCard: React.FC<{ open: boolean }> = ({ open }) => (
  <Collapse in={open}>
    <SuspenseBox mt={10} border="1px solid #F0F0F7" borderRadius="4px">
      <CardList />
    </SuspenseBox>
  </Collapse>
);

export default ManageCard;
