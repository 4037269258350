import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCreateCur } from '@hooks/api/onboard';
import { OnboardPageWrapper } from '@pages/private/onboard/Onboard.styled';
import { ROUTES } from '@routes';
import { AWS_REGION } from '@constants';
import { useSnackbar, useTagManager } from '@hooks/common';
import { isAxiosError } from '@utils';
import { useConfigureCURForm } from '../../ConfigureCURPage.form';

const PayerView: React.FC<{ uid: string }> = ({ uid }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('onboard');
  const { t: commonT } = useTranslation('common');
  const { t: awsT } = useTranslation('aws');
  const { t: toastT } = useTranslation('toast');
  const { t: validationT } = useTranslation('validation');
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const regions: Record<SupportedAWSRegion, string> = awsT('region', {
    returnObjects: true,
  });
  const { isLoading, mutateAsync } = useCreateCur();
  const { sendDataLayer } = useTagManager();
  const { initialValues, validationSchema } = useConfigureCURForm();
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (v) => {
      try {
        await mutateAsync({ ...v, uid });
        showSuccessSnackbar(toastT('success.onboard.cur.created'));
        sendDataLayer('cc-onboard-cur-done');
        navigate(ROUTES.OVERVIEW.ROOT);
      } catch (e) {
        if (isAxiosError(e) && e.response?.data?.msg) {
          switch (true) {
            case e.response.data.msg.startsWith('Same Bucket already exist'):
              setFieldError('bucketName', validationT('onboard.cur.bucket'));
              return;
            case e.response.data.msg.startsWith(
              'Same report name already exist',
            ):
              setFieldError('reportName', validationT('onboard.cur.report'));
              return;
            case e.response.data.msg.startsWith(
              'Error during create s3 bucket',
            ):
              showErrorSnackbar(toastT('error.onboard.cur.s3'));
              return;
            case e.response.data.msg.startsWith('CUR connection error'):
              showErrorSnackbar(toastT('error.onboard.cur.connection'));
              return;
            case e.response.data.msg.startsWith('Error during create CUR'):
              showErrorSnackbar(toastT('error.onboard.cur.report'));
              return;
            default:
              showErrorSnackbar(toastT('error.general'));
          }
        }
      }
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <OnboardPageWrapper title={t('title.configureCUR')}>
        <Typography variant="h3" mb={2}>
          {t('header.configureCUR')}
        </Typography>
        <Typography
          variant="body2"
          mb={8}
          dangerouslySetInnerHTML={{
            __html: t('description.configureCUR', {
              URL: 'https://docs.aws.amazon.com/cur/latest/userguide/what-is-cur.html',
            }),
          }}
        />
        <Grid container item direction="column" md={7} xs={12}>
          <TextField
            color="secondary"
            name="reportName"
            value={values.reportName}
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('label.reportName')}
            placeholder={t('holder.reportName')}
            autoCapitalize="off"
            error={Boolean(errors.reportName && touched.reportName)}
            helperText={
              Boolean(errors.reportName && touched.reportName) &&
              String(errors.reportName)
            }
            sx={{ mb: 4 }}
            fullWidth
          />
          <TextField
            color="secondary"
            name="bucketName"
            value={values.bucketName}
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('label.bucketName')}
            placeholder={t('holder.bucketName')}
            autoCapitalize="off"
            error={Boolean(errors.bucketName && touched.bucketName)}
            helperText={
              Boolean(errors.bucketName && touched.bucketName) &&
              String(errors.bucketName)
            }
            sx={{ mb: 4 }}
            fullWidth
          />
          <TextField
            color="secondary"
            size="medium"
            name="region"
            value={values.region}
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('label.region')}
            autoCapitalize="off"
            error={Boolean(errors.region && touched.region)}
            helperText={
              Boolean(errors.region && touched.region) && String(errors.region)
            }
            select
            fullWidth
          >
            {AWS_REGION.map((region) => (
              <MenuItem key={`menuitem-${region}`} value={region}>
                {regions[region]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Box mt={8} textAlign="right">
          <LoadingButton
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            loading={isLoading}
            disabled={!isValid}
          >
            {commonT('button.done')}
          </LoadingButton>
        </Box>
      </OnboardPageWrapper>
    </form>
  );
};

export default PayerView;
