export default {
  COMMON: {
    TOKEN: 'COMMON_TOKEN',
    SILENT_REFRESH: 'COMMON_SILENT_REFRESH',
  },
  AUTH: {
    LOGIN_WITH_TOKEN: 'LOGIN_WITH_TOKEN',
    REGISTER: 'AUTH_REGISTER',
    RESET_PASSWORD: 'RESET_PASSWORD',
    UPDATE_PASSWORD: 'UPDATE_PASSWORD',
    RESEND_VERIFICATION: 'RESEND_VERIFICATION',
  },
  ONBOARD: {
    EXTERNAL_ID: 'ONBOARD_EXTERNAL_ID',
    FROM_CLI: 'ONBOARD_FROM_CLI',
    VERIFY_ARN: 'ONBOARD_VERIFY_ARN',
    CREATE_ACCOUNT: 'ONBOARD_CREATE_ACCOUNT',
    GET_MASTER: 'ONBOARD_GET_MASTER',
    GET_LINKED: 'ONBOARD_GET_LINKED',
    SEND_EMAIL: 'ONBOARD_SEND_EMAIL',
    RUN_DIAGNOSTIC: 'ONBOARD_RUN_DIAGNOSTIC',
    GET_DIAGNOSTIC: 'ONBOARD_GET_DIAGNOSTIC',
    REQUEST_LINKED: 'ONBOARD_REQUEST_LINKED',
    REQUEST_MASTER: 'ONBOARD_REQUEST_MASTER',
    CREATE_CUR: 'ONBOARD_CREATE_CUR',
    AUTHORIZE_EXTERNAL: 'ONBOARD_AUTHORIZE_EXTERNAL',
  },
  OVERVIEW: {
    SUMMARY: 'OVERVIEW_SUMMARY',
    MONTH_TO_DATE: 'OVERVIEW_MONTH_TO_DATE',
    COST_COMPARISON: 'OVERVIEW_COST_COMPARISON',
    ANNUAL_TREND: 'OVERVIEW_ANNUAL_TREND',
    POSSIBLE_SAVINGS: 'OVERVIEW_POSSIBLE_SAVINGS',
    NOTIFICATION: 'OVERVIEW_NOTIFICATION',
    IGNORE_NOTIFICATION: 'OVERVIEW_IGNORE_NOTIFICATION',
  },
  CURRENT: {
    COMPANIES: 'CURRENT_COMPANY',
    USERS: 'CURRENT_USER',
    AWS: 'CURRENT_AWS_ACCOUNT',
  },
  OPTIMIZATION: {
    EC2: {
      UTILIZATION: 'OPTIMIZATION_EC2_UTILIZATION',
      PURCHASING: 'OPTIMIZATION_EC2_PURCHASING',
      RI_UTILIZATION: 'OPTIMIZATION_EC2_RI_UTILIZATION',
      SP_UTILIZATION: 'OPTIMIZATION_EC2_SP_UTILIZATION',
      TRANSFER: 'OPTIMIZATION_EC2_TRANSFER',
      TRANSFER_SUMMARY: 'OPTIMIZATION_EC2_TRANSFER_SUMMARY',
      OTHER: 'OPTIMIZATION_EC2_OTHER',
      UTILIZATION_CHART: 'OPTIMIZATION_EC2_UTILIZATION_CHART',
      PURCHASING_CHART: 'OPTIMIZATION_EC2_PURCHASING_CHART',
      OTHER_CHART: 'OPTIMIZATION_EC2_OTHER_CHART',
      DETAILS_GENERAL: 'OPTIMIZATION_EC2_DETAILS_GENERAL',
      DETAILS_UTILIZATION: 'OPTIMIZATION_EC2_DETAILS_UTILIZATION',
      DETAILS_RECOMMEND: 'OPTIMIZATION_EC2_DETAILS_RECOMMEND',
      DETAILS_PURCHASING: 'OPTIMIZATION_EC2_DETAILS_PURCHASING',
      DETAILS_TRANSFER_SUMMARY: 'OPTIMIZATION_EC2_DETAILS_TRANSFER_SUMMARY',
      DETAILS_TRANSFER_DETAIL: 'OPTIMIZATION_EC2_DETAILS_TRANSFER_DETAIL',
      DETAILS_OTHERS: 'OPTIMIZATION_EC2_DETAILS_OTHERS',
      TRANSFER_CHART: 'OPTIMIZATION_EC2_TRANSFER_CHART',
      AC_DATA: 'OPTIMIZATION_EC2_AC_DATA',
      COVERAGE_AC_DATA: 'OPTIMIZATION_EC2_COVERAGE_AC_DATA',
      RI_UTIL_AC_DATA: 'OPTIMIZATION_EC2_RI_UTIL_AC_DATA',
      SP_UTIL_AC_DATA: 'OPTIMIZATION_EC2_SP_UTIL_AC_DATA',
      TRANSFER_AC_DATA: 'OPTIMIZATION_EC2_TRANSFER_AC_DATA',
      OTHER_AC_DATA: 'OPTIMIZATION_EC2_OTHER_AC_DATA',
      MISCELLANEOUS: 'OPTIMIZATION_EC2_MISCELLANEOUS',
    },
    RDS: {
      INFORMATION: 'OPTIMIZATION_RDS_INFORMATION',
      COVERAGE: 'OPTIMIZATION_RDS_COVERAGE',
      UTILIZATION: 'OPTIMIZATION_RDS_UTILIZATION',
      STORAGE: 'OPTIMIZATION_RDS_STORAGE',
      UTILIZATION_CHART: 'OPTIMIZATION_RDS_UTILIZATION_CHART',
      STORAGE_CHART: 'OPTIMIZATION_RDS_STORAGE_CHART',
      PURCHASING_COVERAGE_CHART: 'OPTIMIZATION_RDS_PURCHASING_COVERAGE_CHART',
      PURCHASING_UTILIZATION_CHART:
        'OPTIMIZATION_RDS_PURCHASING_UTILIZATION_CHART',
      AC_DATA: 'OPTIMIZATION_RDS_AC_DATA',
      COVERAGE_AC_DATA: 'OPTIMIZATION_RDS_COVERAGE_AC_DATA',
      UTIL_AC_DATA: 'OPTIMIZATION_RDS_UTIL_AC_DATA',
      STORAGE_AC_DATA: 'OPTIMIZATION_RDS_STORAGE_AC_DATA',
      DETAILS_PURCHASING: 'OPTIMIZATION_RDS_DETAILS_PURCHASING',
      DETAILS_UTILIZATION: 'OPTIMIZATION_RDS_DETAILS_UTILIZATION',
      DETAILS_STORAGE: 'OPTIMIZATION_RDS_DETAILS_STORAGE',
      DETAILS_GENERAL: 'OPTIMIZATION_RDS_DETAILS_GENERAL',
      DETAILS_RECOMMEND: 'OPTIMIZATION_RDS_DETAILS_RECOMMEND',
    },
    S3: {
      INFORMATION: 'OPTIMIZATION_S3_INFORMATION',
      USAGE_CHART: 'OPTIMIZATION_S3_USAGE_CHART',
      AC_DATA: 'OPTIMIZATION_S3_AC_DATA',
      DETAILS_GENERAL: 'OPTIMIZATION_S3_DETAILS_GENERAL',
      DETAILS_SUMMARY: 'OPTIMIZATION_S3_DETAILS_SUMMARY',
    },
    EBS: {
      INFORMATION: 'OPTIMIZATION_EBS_INFORMATION',
      USAGE_CHART: 'OPTIMIZATION_EBS_USAGE_CHART',
      AC_DATA: 'OPTIMIZATION_EBS_AC_DATA',
      DETAILS_GENERAL: 'OPTIMIZATION_S3_DETAILS_GENERAL',
      DETAILS_SUMMARY: 'OPTIMIZATION_S3_DETAILS_SUMMARY',
    },
    ANOMALY: {
      THRESHOLD: 'OPTIMIZATION_THRESHOLD',
      ANOMALY_OVERVIEW: 'OPTIMIZATION_ANOMALY_OVERVIEW',
      ANOMALY: 'OPTIMIZATION_ANOMALY',
      AC_DATA: 'OPTIMIZATION_ANOMALY_AC_DATA',
      ADD: 'OPTIMIZATION_THRESHOLD_ADD',
      CREATE: 'OPTIMIZATION_THRESHOLD_CREATE',
      UPDATE: 'OPTIMIZATION_THRESHOLD_UPDATE',
      DELETE: 'OPTIMIZATION_THRESHOLD_DELETE',
    },
    CSV: 'OPTIMIZATION_DOWNLOAD_CSV',
  },
  PLANNER: {
    OD_LIST: 'PLANNER_OD_LIST',
    RI_LIST: 'PLANNER_RI_LIST',
    SP_LIST: 'PLANNER_SP_LIST',
    OD_LIST_AC_DATA: 'PLANNER_OD_LIST_AC_DATA',
    RI_LIST_AC_DATA: 'PLANNER_RI_LIST_AC_DATA',
    SP_LIST_AC_DATA: 'PLANNER_SP_LIST_AC_DATA',
    OD_DETAIL: 'PLANNER_OD_DETAIL',
    RI_DETAIL: 'PLANNER_RI_DETAIL',
    SP_DETAIL: 'PLANNER_SP_DETAIL',
    SIMULATION: 'PLANNER_SIMULATION',
    SIMULATION_RECOMMEND: 'PLANNER_SIMULATION_RECOMMEND',
    SIMULATION_GENERAL: 'PLANNER_SIMULATION_GENERAL',
    SIMULATION_AUTOCOMPLETE: 'PLANNER_SIMULATION_AUTOCOMPLETE',
    PURCHASE_RI: 'PLANNER_SIMULATION_PURCHASE_RI',
    PURCHASE_SP: 'PLANNER_SIMULATION_PURCHASE_SP',
    CHART: 'PLANNER_CHART',
  },
  CMP: {
    LAST_MONTH: 'CMP_LAST_MONTH',
    PAYMENT_HISTORY: 'CMP_PAYMENT_HISTORY',
    DEPOSIT_HISTORY: 'CMP_DEPOSIT_HISTORY',
    INVOICE_DETAIL: 'CMP_INVOICE_DETAIL',
    DOWNLOAD_INVOICE: 'CMP_DOWNLOAD_INVOICE',
  },
  SETTINGS: {
    ACCOUNT: {
      ASSIGN: 'SETTINGS_ACCOUNT_ASSIGN',
      RELEASE: 'SETTINGS_ACCOUNT_RELEASE',
      DETAIL: 'SETTINGS_ACCOUNT_DETAIL',
      AC_DATA: 'SETTINGS_ACCOUNT_AC_DATA',
    },
    PAYMENT: {
      GET_HISTORY: 'SETTINGS_PAYMENT_GET_HISTORY',
      GET_METHOD: 'SETTINGS_PAYMENT_GET_METHOD',
      GET_STRIPE: 'SETTINGS_PAYMENT_GET_STRIPE',
      CREATE_METHOD: 'SETTINGS_PAYMENT_CREATE_METHOD',
      GET_PURCHASE_STATUS: 'SETTINGS_PAYMENT_GET_PURCHASE_STATUS',
      GET_RECOMMENDED_TIER: 'SETTINGS_PAYMENT_GET_RECOMMENDED_TIER',
      VALIDATE_PROMOTION: 'SETTINGS_PAYMENT_VALIDATE_PROMOTION',
      CHANGE_METHOD: 'SETTINGS_PAYMENT_CHANGE_METHOD',
      DELETE_METHOD: 'SETTINGS_PAYMENT_DELETE_METHOD',
      PURCHASE_PRODUCT: 'SETTINGS_PAYMENT_PURCHASE_PRODUCT',
    },
    INTEGRATION: {
      GET_DATADOG: 'SETTINGS_GET_DATADOG',
      GET_NEWRELIC: 'SETTINGS_GET_NEWRELIC',
      CREATE_DATADOG: 'SETTINGS_CREATE_DATADOG',
      CREATE_NEWRELIC: 'SETTINGS_CREATE_NEWRELIC',
      UPDATE_DATADOG: 'SETTINGS_UPDATE_DATADOG',
      UPDATE_NEWRELIC: 'SETTINGS_UPDATE_NEWRELIC',
      VERIFY_DATADOG: 'SETTINGS_VERIFY_DATADOG',
      VERIFY_NEWRELIC: 'SETTINGS_VERIFY_NEWRELIC',
      DELETE_DATADOG: 'SETTINGS_DELETE_DATADOG',
      DELETE_NEWRELIC: 'SETTINGS_DELETE_NEWRELIC',
    },
    USER: {
      CREATE_USER: 'SETTINGS_CREATE_USER',
      INVITE_USER: 'SETTINGS_INVITE_USER',
      UPDATE_USER: 'SETTINGS_UPDATE_USER',
      DELETE_USER: 'SETTINGS_DELETE_USER',
      GET_USER: 'SETTINGS_GET_USER',
      GET_USERS: 'SETTINGS_GET_USERS',
      RESEND_INVITATION: 'SETTINGS_RESEND_INVITATION',
      LOGIN_MASQUERADE: 'SETTINGS_USER_LOGIN_MASQUERADE',
    },
  },
  ADMIN: {
    PROMOTION: {
      CREATE_PROMOTION: 'ADMIN_CREATE_PROMOTION',
      DELETE_PROMOTION: 'ADMIN_DELETE_PROMOTION',
      GET_PROMOTION: 'ADMIN_GET_PROMOTION',
      GET_PROMOTIONS: 'ADMIN_GET_PROMOTIONS',
    },
    SUBSCRIPTION: {
      GET_SUBSCRIPTIONS: 'ADMIN_GET_SUBSCRIPTIONS',
      DELETE_SUBSCRIPTION: 'ADMIN_DELETE_SUBSCRIPTION',
    },
    COUPON: {
      CREATE_COUPON: 'ADMIN_CREATE_COUPON',
      DELETE_COUPON: 'ADMIN_DELETE_COUPON',
      GET_COUPONS: 'ADMIN_GET_COUPONS',
    },
    COMPANY: {
      CREATE_COMPANY: 'ADMIN_CREATE_COMPANY',
      UPDATE_COMPANY: 'ADMIN_UPDATE_COMPANY',
      DELETE_COMPANY: 'ADMIN_DELETE_COMPANY',
      GET_COMPANY: 'ADMIN_GET_COMPANY',
      GET_COMPANIES: 'ADMIN_GET_COMPANIES',
    },
  },
  AWS: {
    CE: {
      GET_USAGE: 'AWS_CE_GET_USAGE',
      GET_USAGE_AC: 'AWS_CE_GET_USAGE_AC',
      GET_USAGE_TAG_AC: 'AWS_CE_GET_USAGE_TAG_AC',
      DOWNLOAD_CSV: 'AWS_CE_DOWNLOAD_CSV',
    },
  },
  ETC: {
    TECH_SUPPORT: 'SETTINGS_TECH_SUPPORT',
  },
} as const;
