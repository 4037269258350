import React, { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  ChartDefaultGradient,
  GrmtAxisDefaultProps,
  GrmtGridDefaultProps,
  GrmtTooltipTextItem,
  GrmtTooltipWrapper,
} from '@components/chart';
import { ValueFormatter } from '@utils';
import { ChartCoverBox } from '@components/styled/noData';

type DetailChartProps = {
  type: 'OD' | 'RI' | 'SP';
  data: Array<PlannerChartInfo>;
};

const DUMMY = [
  {
    InstanceFamily: 't3',
    Ondemand: 50,
    Reserved: 50,
    SavingsPlan: 50,
  },
  {
    InstanceFamily: 't2',
    Ondemand: 35,
    Reserved: 35,
    SavingsPlan: 35,
  },
  {
    InstanceFamily: 'c5',
    Ondemand: 30,
    Reserved: 30,
    SavingsPlan: 30,
  },
  {
    InstanceFamily: 'r5',
    Ondemand: 20,
    Reserved: 20,
    SavingsPlan: 20,
  },
  {
    InstanceFamily: 'Other',
    Ondemand: 10,
    Reserved: 10,
    SavingsPlan: 10,
  },
];

const ALPHAS = ['FF', 'CC', '99', '66', '33'] as const;

const PlannerCoverageDetailChart: React.FC<DetailChartProps> = ({
  type,
  data,
}) => {
  const { t } = useTranslation('planner');
  const { t: optT } = useTranslation('optimization');
  const COLOR = useMemo(() => {
    switch (type) {
      case 'RI':
        return '#57CAEB';
      case 'SP':
        return '#9594FF';
      default:
        return '#FF7976';
    }
  }, [type]);
  const HourKey = useMemo(() => {
    switch (type) {
      case 'RI':
        return 'Reserved';
      case 'SP':
        return 'SavingsPlan';
      default:
        return 'Ondemand';
    }
  }, [type]);
  const CostKey = useMemo(() => {
    switch (type) {
      case 'RI':
        return 'ReservedCost';
      case 'SP':
        return 'SavingsPlanCost';
      default:
        return 'OndemandCost';
    }
  }, [type]);
  const chartData = !_.isEmpty(data) ? data : DUMMY;
  return (
    <ChartCoverBox isEmpty={_.isEmpty(data)}>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={chartData}
          barSize={14}
          margin={{ left: 24, right: 24, top: 24, bottom: 24 }}
        >
          {ChartDefaultGradient}
          <XAxis dataKey="InstanceFamily" {...GrmtAxisDefaultProps} />
          <YAxis
            scale="linear"
            label={{
              value: 'Hours',
              position: 'insideLeft',
              angle: -90,
              offset: -16,
              fill: '#A0A1B2',
              fontSize: '12px',
              fontWeight: 400,
            }}
            tickFormatter={(value: any) =>
              ValueFormatter.toLocaleString(value, 0)
            }
            {...GrmtAxisDefaultProps}
          />
          <CartesianGrid {...GrmtGridDefaultProps} />
          <Tooltip
            cursor={false}
            content={({ active, payload, label }) => {
              if (payload?.length && payload[0].payload) {
                return (
                  <GrmtTooltipWrapper
                    active={active}
                    payload={payload}
                    label={label}
                  >
                    <GrmtTooltipTextItem
                      name={t('label.usageHours')}
                      value={ValueFormatter.toLocaleString(
                        payload[0].payload[HourKey],
                      )}
                    />
                    <GrmtTooltipTextItem
                      name={optT('label.common.cost')}
                      value={ValueFormatter.toCurrency(
                        payload[0].payload[CostKey],
                      )}
                    />
                  </GrmtTooltipWrapper>
                );
              }
              return null;
            }}
          />
          <Bar dataKey={HourKey} radius={[6, 6, 6, 6]}>
            {chartData.map((entry, idx) => (
              <Cell
                key={`pl_cv_detail_cell_${HourKey}_${idx.toString()}`}
                fill={`${COLOR}${ALPHAS[idx]}`}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </ChartCoverBox>
  );
};

export default React.memo(PlannerCoverageDetailChart);
