import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { hashPassword } from '@utils';

type ProfileForm = {
  firstName: string;
  lastName: string;
  email?: string;
  language?: string;
};

export const useProfileForm = () => {
  const { t } = useTranslation('validation');
  const { t: formT } = useTranslation('form');

  const initialValues: ProfileForm = {
    firstName: '',
    lastName: '',
    email: '',
    language: 'en',
  };

  const validationSchema: Yup.SchemaOf<ProfileForm> = Yup.object({
    firstName: Yup.string()
      .trim()
      .defined(
        t('custom.required', {
          context: 'post1',
          name: formT('holder.firstName'),
        }),
      ),
    lastName: Yup.string()
      .trim()
      .defined(
        t('custom.required', {
          context: 'post1',
          name: formT('holder.lastName'),
        }),
      ),
    email: Yup.string(),
    language: Yup.string(),
  }).defined();

  return { initialValues, validationSchema };
};

type ChangePasswordForm = {
  current: string;
  password: string;
  confirm: string;
  original?: string;
};

export const useChangePasswordForm = () => {
  const { t } = useTranslation('validation');
  const { t: formT } = useTranslation('form');

  const initialValues: ChangePasswordForm = {
    current: '',
    password: '',
    confirm: '',
    original: '',
  };

  const validationSchema: Yup.SchemaOf<ChangePasswordForm> = Yup.object({
    original: Yup.string().trim(),
    current: Yup.string()
      .defined(
        t('custom.required', {
          context: 'post1',
          name: formT('holder.currentPassword'),
        }),
      )
      .test('matches', t('auth.password.current'), (value, ctx) => {
        if (ctx && value) {
          return hashPassword(value) === ctx.parent.original;
        }
        return false;
      }),
    password: Yup.string()
      .trim()
      .matches(/^.*[a-z]+.*$/, t('auth.password.lower'))
      .matches(/^.*[0-9]+.*$/, t('auth.password.digit'))
      .matches(/^.*[A-Z]+.*$/, t('auth.password.upper'))
      .min(8, t('auth.password.length'))
      .defined(
        t('custom.required', {
          context: 'post2',
          name: formT('holder.password'),
        }),
      ),
    confirm: Yup.string()
      .trim()
      .oneOf([Yup.ref('password')], t('auth.confirm'))
      .defined(
        t('custom.required', {
          context: 'post1',
          name: formT('holder.confirm'),
        }),
      ),
  }).defined();

  return { initialValues, validationSchema };
};
