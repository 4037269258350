import React, { forwardRef } from 'react';
import { TextField, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment';
import { IMaskInput, IMask } from 'react-imask';
import { useRecoilState } from 'recoil';
import { apiEndDateState, apiStartDateState } from '@atoms/global';
import { bluegrey } from '@theme/colors';

interface CustomProps {
  onChange: (event: {
    target: { name: string; value: string };
    currentTarget: { name: string; value: string };
  }) => void;
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DatetimeMask = forwardRef<HTMLInputElement, CustomProps>((props, ref) => {
  const { onChange, ...children } = props;
  const internalRef = React.useRef<HTMLInputElement>(null);
  const blocks = {
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1970,
      to: 2030,
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
    },
    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
    },
    HH: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 23,
    },
    mm: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
    },
  };

  return (
    <IMaskInput
      {...children}
      mask={Date}
      blocks={blocks}
      pattern="YYYY/MM/DD HH:mmm"
      ref={internalRef}
      onAccept={(value: any) => {
        if (
          value.match(
            // eslint-disable-next-line no-useless-escape
            /^([1-9]\d{3}\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])[\ ]([01]?[0-9]|2[0-3]):[0-5][0-9])$/,
          )
        ) {
          onChange({
            target: { name: props.name, value },
            currentTarget: { name: props.name, value },
          });
        }
      }}
      overwrite
    />
  );
});
DatetimeMask.displayName = 'DatetimeMask';

const GrmtDateTimeRangePicker: React.FC = () => {
  const [startDate, setStartDate] = useRecoilState(apiStartDateState);
  const [endDate, setEndDate] = useRecoilState(apiEndDateState);
  return (
    <>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DateTimePicker
          value={moment(startDate)}
          onChange={(newValue) => {
            setStartDate(newValue.format('YYYY-MM-DDTHH:00:00'));
          }}
          mask="____/__/__ __:__"
          inputFormat="YYYY/MM/DD HH:mm"
          components={{ OpenPickerIcon: CalendarTodayIcon }}
          views={['year', 'month', 'day', 'hours']}
          ampm={false}
          PopperProps={{
            disablePortal: true,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onKeyDown={(e) => e.preventDefault()}
              sx={(theme) => ({
                width: '10rem',
                '> .MuiOutlinedInput-root': {
                  color: theme.palette.text.secondary,
                  fontSize: theme.typography.caption.fontSize,
                  lineHeight: theme.typography.caption.lineHeight,
                  fontWeight: theme.typography.caption.fontWeight,
                  borderColor: bluegrey[200],
                  '> .MuiOutlinedInput-input': {
                    padding: '0.6875rem 0.5rem',
                    paddingRight: 0,
                  },
                  '> .MuiInputAdornment-root > .MuiIconButton-root > .MuiSvgIcon-root':
                    {
                      width: '1.5rem',
                      height: '1.5rem',
                    },
                },
              })}
            />
          )}
          disableIgnoringDatePartForTimeValidation
          disableFuture
          hideTabs
        />
      </LocalizationProvider>
      <Typography variant="caption" color="textSecondary" mx={1}>
        ~
      </Typography>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DateTimePicker
          value={moment(endDate)}
          onChange={(newValue) =>
            setEndDate(newValue.format('YYYY-MM-DDTHH:00:00'))
          }
          mask="____/__/__ __:__"
          inputFormat="YYYY/MM/DD HH:mm"
          components={{ OpenPickerIcon: CalendarTodayIcon }}
          views={['year', 'month', 'day', 'hours']}
          ampm={false}
          minDateTime={moment(startDate)}
          PopperProps={{
            disablePortal: true,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onKeyDown={(e) => e.preventDefault()}
              sx={(theme) => ({
                width: '10rem',
                '> .MuiOutlinedInput-root': {
                  color: theme.palette.text.secondary,
                  fontSize: theme.typography.caption.fontSize,
                  lineHeight: theme.typography.caption.lineHeight,
                  fontWeight: theme.typography.caption.fontWeight,
                  borderColor: bluegrey[200],
                  '> .MuiOutlinedInput-input': {
                    padding: '0.6875rem 0.5rem',
                    paddingRight: 0,
                  },
                  '> .MuiInputAdornment-root > .MuiIconButton-root > .MuiSvgIcon-root':
                    {
                      width: '1.5rem',
                      height: '1.5rem',
                    },
                },
              })}
            />
          )}
          disableIgnoringDatePartForTimeValidation
          disableFuture
          hideTabs
        />
      </LocalizationProvider>
    </>
  );
};

export default GrmtDateTimeRangePicker;
