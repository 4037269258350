import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, SvgIcon, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCurrentInfo } from '@hooks/api/common';
import { ReactComponent as NewrelicLogo } from '@assets/images/newrelic_logo.svg';
import { useSnackbar } from '@hooks/common';
import { useNewrelicForm } from '../SettingsIntegration.form';
import { useNewrelic } from '../hooks';

const NewrelicForm: React.FC = () => {
  const { currentCompany } = useCurrentInfo();
  const {
    hasNewrelic,
    newrelicItem,
    verifyNewrelic,
    updateNewrelic,
    createNewrelic,
    deleteNewrelic,
    isVerified,
    isVerifying,
    isCreating,
    isUpdating,
    isDeleting,
  } = useNewrelic();
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const { showErrorSnackbar } = useSnackbar();
  const [verifiedAPIKey, setVerifiedAPIKey] = useState('');
  const [verifiedAccountId, setVerifiedAccountId] = useState('');
  const [verifiedQueryKey, setVerifiedQueryKey] = useState('');
  const { initialValues, validationSchema } = useNewrelicForm();
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (v) => {
      try {
        await verifyNewrelic(v);
        setVerifiedAPIKey(v.APIKey);
        setVerifiedAccountId(v.AccountId);
        setVerifiedQueryKey(v.QueryKey);
      } catch (e) {
        showErrorSnackbar(t('integration.label.verifyFailed'));
      }
    },
  });

  const verifiedNotMatched = useMemo(
    () =>
      values.APIKey !== verifiedAPIKey ||
      values.AccountId !== verifiedAccountId ||
      values.QueryKey !== verifiedQueryKey,
    [values, verifiedAPIKey, verifiedAccountId, verifiedQueryKey],
  );

  const handleNewrelic = useCallback(async () => {
    if (isValid && !verifiedNotMatched) {
      try {
        if (hasNewrelic && newrelicItem?.uid) {
          await updateNewrelic({ ...values, uid: newrelicItem.uid });
        } else {
          await createNewrelic(values);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [values, isValid, verifiedNotMatched, hasNewrelic, newrelicItem?.uid]);

  useEffect(() => {
    if (hasNewrelic && newrelicItem) {
      setFieldValue('AccountId', newrelicItem.AccountId);
      setFieldValue('APIKey', newrelicItem.APIKey);
      setFieldValue('QueryKey', newrelicItem.QueryKey);
    } else {
      setFieldValue('AccountId', '');
      setFieldValue('APIKey', '');
      setFieldValue('QueryKey', '');
    }
  }, [hasNewrelic, newrelicItem]);

  useEffect(() => {
    if (currentCompany?.id) {
      setFieldValue('companyId', currentCompany.id);
    }
  }, [currentCompany?.id]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        direction={{ lg: 'row', xs: 'column' }}
        justifyContent="space-between"
        rowSpacing={3}
      >
        <Grid
          container
          item
          direction={{ lg: 'column', xs: 'row' }}
          justifyContent={{ lg: 'center', xs: 'flex-start' }}
          alignItems="center"
          rowSpacing={2}
          columnSpacing={2}
          lg={1.5}
          xs={12}
        >
          <Grid item>
            <SvgIcon
              component={NewrelicLogo}
              sx={{ width: '62px', height: '62px' }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h3">Newrelic</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          lg={5}
          xs={12}
        >
          <Grid container item alignItems="center">
            <Grid item xs={2}>
              <Typography variant="caption">Account ID</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                name="AccountId"
                type="text"
                inputMode="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.AccountId}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                disabled={isVerifying}
                error={Boolean(touched.AccountId && errors.AccountId)}
                helperText={
                  Boolean(touched.AccountId && errors.AccountId) &&
                  String(errors.AccountId)
                }
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="center" mt={3}>
            <Grid item xs={2}>
              <Typography variant="caption">API Key</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                name="APIKey"
                type="text"
                inputMode="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.APIKey}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                disabled={isVerifying}
                error={Boolean(touched.APIKey && errors.APIKey)}
                helperText={
                  Boolean(touched.APIKey && errors.APIKey) &&
                  String(errors.APIKey)
                }
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="center" mt={3}>
            <Grid item xs={2}>
              <Typography variant="caption">Query Key</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                name="QueryKey"
                type="text"
                inputMode="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.QueryKey}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                disabled={isVerifying}
                error={Boolean(touched.QueryKey && errors.QueryKey)}
                helperText={
                  Boolean(touched.QueryKey && errors.QueryKey) &&
                  String(errors.QueryKey)
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction={{ lg: 'column-reverse', xs: 'row' }}
          justifyContent={{ lg: 'center', xs: 'flex-end' }}
          rowSpacing={3}
          columnSpacing={2}
          lg={1.3}
          xs={12}
        >
          <Grid item>
            <LoadingButton
              type="button"
              variant="outlined"
              size="small"
              color="inherit"
              onClick={deleteNewrelic}
              loading={isDeleting}
              disabled={!hasNewrelic}
              fullWidth
            >
              {t('integration.button.disconnect')}
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              variant="outlined"
              size="small"
              loading={isVerifying}
              disabled={!isValid}
              fullWidth
            >
              {commonT('button.verify')}
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              type="button"
              variant="outlined"
              size="small"
              onClick={handleNewrelic}
              loading={isCreating || isUpdating}
              disabled={!isValid || !isVerified || verifiedNotMatched}
              fullWidth
            >
              {t('integration.button.save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default React.memo(NewrelicForm);
