import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export type SignInForm = {
  email: string;
  password: string;
  keepLoggedIn: boolean;
};

export const useSignInForm = (): UseValidation<SignInForm> => {
  const { t } = useTranslation('validation');
  const { t: formT } = useTranslation('form');
  return {
    initialValues: {
      email: '',
      password: '',
      keepLoggedIn: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .strict(false)
        .trim()
        .email(t('auth.email'))
        .defined(
          t('custom.required', {
            context: 'post1',
            name: formT('holder.email'),
          }),
        ),
      password: Yup.string()
        .strict(false)
        .trim()
        .matches(/^.*[a-z]+.*$/, t('auth.password.lower'))
        .matches(/^.*[0-9]+.*$/, t('auth.password.digit'))
        .matches(/^.*[A-Z]+.*$/, t('auth.password.upper'))
        .min(8, t('auth.password.length'))
        .defined(
          t('custom.required', {
            context: 'post2',
            name: formT('holder.password'),
          }),
        ),
      keepLoggedIn: Yup.boolean().defined(),
    }),
  };
};
