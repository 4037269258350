import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import moment from 'moment';
import { useAnnualTrend } from '@hooks/api/overview';
import {
  GrmtAxisDefaultProps,
  GrmtTooltipItem,
  GrmtTooltipWrapper,
} from '@components/chart';
import { ValueFormatter } from '@utils';
import NoChartData from '@components/chart/overview/NoChartData';

type AnnualTrendChartData = {
  date: Date;
} & Record<OverviewSupportedService, number | null>;

const AnnualTrendChart: React.FC = () => {
  const [chartData, setChartData] = useState<Array<AnnualTrendChartData>>([]);
  const { data: response } = useAnnualTrend();
  useEffect(() => {
    if (response?.items?.length) {
      const data: Array<AnnualTrendChartData> = [];
      const offset = moment(response.items[0].date).subtract(
        11 - response.items.length,
        'months',
      );
      for (let i = 0; i < 11 - response.items.length; i += 1) {
        data.push({
          date: offset.toDate(),
          'Amazon Elastic Compute Cloud': null,
          'Amazon Relational Database Service': null,
          'Amazon Simple Storage Service': null,
          Others: null,
        });
        offset.add(1, 'months');
      }
      data.push(...response.items);
      setChartData(data);
    }
  }, [response?.items]);
  // useEffect(() => {
  //   if (response?.items?.length) {
  //     const rrrr = response.items.slice(-1);
  //     const data: Array<AnnualTrendChartData> = [];
  //     const offset = moment(rrrr[0].date).subtract(11 - rrrr.length, 'months');
  //     for (let i = 0; i < 11 - rrrr.length; i += 1) {
  //       data.push({
  //         date: offset.toDate(),
  //         'Amazon Elastic Compute Cloud': null,
  //         'Amazon Relational Database Service': null,
  //         'Amazon Simple Storage Service': null,
  //         Others: null,
  //       });
  //       offset.add(1, 'months');
  //     }
  //     data.push(...rrrr);
  //     setChartData(data);
  //   }
  // }, [response?.items]);
  if (!response?.items?.length || !chartData.length) {
    return <NoChartData />;
  }
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={chartData}
        margin={{ top: 8, bottom: 8, left: 36, right: 36 }}
      >
        <XAxis
          dataKey="date"
          interval={0}
          tickFormatter={(v: string) => moment(v).format('MMM YYYY')}
          {...GrmtAxisDefaultProps}
        />
        <YAxis
          scale="sequential"
          domain={[0, (dataMax: number) => (dataMax / 500 + 1) * 500]}
          {...GrmtAxisDefaultProps}
        />
        <CartesianGrid vertical={false} stroke="#F0F0F7" />
        <Tooltip
          content={({ active, payload, label }) => (
            <GrmtTooltipWrapper
              active={active}
              payload={payload}
              label={moment(label).format('MMM YYYY')}
            >
              {payload?.length &&
                payload.map((item) => (
                  <GrmtTooltipItem
                    key={`tooltip_${item.name}_${label}`}
                    dotColor={item.color}
                    name={item.name}
                    value={ValueFormatter.toCurrency(item.value)}
                  />
                ))}
            </GrmtTooltipWrapper>
          )}
        />
        <Line
          type="linear"
          dataKey="Amazon Elastic Compute Cloud"
          name="Amazon EC2"
          stroke="#9594FF"
          strokeWidth={3}
          dot={{ r: 3, fill: '#9594FF' }}
          connectNulls
        />
        <Line
          type="linear"
          dataKey="Amazon Relational Database Service"
          name="Amazon RDS"
          stroke="#5BD9B3"
          strokeWidth={3}
          dot={{ r: 3, fill: '#5BD9B3' }}
          connectNulls
        />
        <Line
          type="linear"
          dataKey="Amazon Simple Storage Service"
          name="Amazon S3"
          stroke="#57CAEB"
          strokeWidth={3}
          dot={{ r: 3, fill: '#57CAEB' }}
          connectNulls
        />
        <Line
          type="linear"
          dataKey="Others"
          name="Others"
          stroke="#FF7976"
          strokeWidth={3}
          dot={{ r: 3, fill: '#FF7976' }}
          connectNulls
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default React.memo(AnnualTrendChart);
