import { useMutation, useQueryClient } from 'react-query';
import { PaymentService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.SETTINGS.PAYMENT.DELETE_METHOD,
    mutationFn: (variables: { PaymentMethodId: string }) =>
      PaymentService.deletePaymentMethod(variables.PaymentMethodId),
    onSuccess: () =>
      queryClient.invalidateQueries(QUERY_KEY.SETTINGS.PAYMENT.GET_METHOD),
  });
};
