import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export type ResetPasswordForm = {
  email: string;
};

export const useResetPasswordForm = (): UseValidation<ResetPasswordForm> => {
  const { t } = useTranslation('validation');
  const { t: formT } = useTranslation('form');
  return {
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email(t('auth.email'))
        .defined(
          t('custom.required', {
            context: 'post1',
            name: formT('holder.email'),
          }),
        ),
    }),
  };
};
