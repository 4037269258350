import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Paper,
  SvgIcon,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useValidation } from '@pages/private/onboard/connect/steps/VerifyRoleStep.form';
import { useFormik } from 'formik';
import shadows from '@theme/shadows';
import { PermissionStatusItem } from '@pages/private/onboard/connect/ConnectAWSPage.styled';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import { ReactComponent as WarningCircle } from '@assets/icons/common/WarningCircle.svg';
import {
  usePublicCreateAccount,
  usePublicExternalId,
  usePublicFromCli,
  usePublicVerifyArn,
} from '@hooks/api/external';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSnackbar } from '@hooks/common';
import {
  externalCompanyIdState,
  externalStepState,
  externalUidState,
} from '../ExternalAccount.atoms';

const ExternalVerifyRoleStep: React.FC<{ isPayer: boolean }> = ({
  isPayer,
}) => {
  const { t } = useTranslation('onboard');
  const { t: commonT } = useTranslation('common');
  const { t: toastT } = useTranslation('toast');
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const permissions = t('permission', { returnObjects: true });
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { isLoading: isCreating, mutateAsync: mutateAccount } =
    usePublicCreateAccount();
  const companyId = useRecoilValue(externalCompanyIdState);
  const setActive = useSetRecoilState(externalStepState);
  const setUid = useSetRecoilState(externalUidState);
  const { externalId } = usePublicExternalId();
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifyError, setIsVerifyError] = useState(false);
  const [isManagement, setIsManagement] = useState(false);
  const {
    isLoading: isVerifying,
    isError: isVerifyingError,
    mutateAsync: mutateArn,
    data: verifyResponse,
  } = usePublicVerifyArn();
  const { isSuccess: arnFetched, createdArn } = usePublicFromCli();
  const { initialValues, validationSchema } = useValidation();
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      onSubmit: () => {},
    });

  const handleSubmit = useCallback(
    async (hasNext: boolean) => {
      if (companyId && externalId) {
        try {
          const data = await mutateAccount({
            name: values.name,
            arn: values.arn,
            isPayer: isManagement,
            companyId,
            externalId,
          });
          showSuccessSnackbar(toastT('success.onboard.account.connected'));
          if (hasNext && data?.items?.length) {
            setUid(data.items[0].uid);
            setActive((v) => v + 1);
          } else {
            setActive(4);
          }
        } catch (e) {
          showErrorSnackbar(toastT('error.onboard.account.connect'));
        }
      }
    },
    [companyId, externalId, values.name, values.arn],
  );

  useEffect(() => {
    if (arnFetched && createdArn) {
      setFieldValue('arn', createdArn);
    }
  }, [arnFetched, createdArn]);

  const verifyArn = useCallback(async () => {
    if (values.arn && !errors.arn && externalId) {
      try {
        const { items } = await mutateArn({ arn: values.arn, externalId });
        if (items?.length) {
          const idx = Object.values(items[0].CheckList).findIndex((v) => !v);
          setIsVerified(idx === -1);
          setIsVerifyError(idx !== -1);
          setIsManagement(items[0].IsPayer);
        }
      } catch (e) {
        setIsVerified(false);
        setIsVerifyError(true);
      }
      mutateArn({ arn: values.arn, externalId });
    }
  }, [values.arn, errors.arn, externalId]);

  return (
    <>
      <Grid
        container
        item
        direction={isSmall ? 'column-reverse' : 'row'}
        justifyContent="space-between"
      >
        <Grid item lg={5} xs={12}>
          <Paper
            variant="elevation"
            sx={{
              px: { lg: 8, xs: 2 },
              py: { lg: 5, xs: 2 },
              my: { lg: 0, xs: 3 },
              boxShadow: shadows[1],
            }}
          >
            <Grid container direction="column" spacing={2}>
              <Grid container item spacing={1} justifyContent="space-between">
                <Grid item xs={2}>
                  <Typography variant="subtitle2" align="center">
                    {t('label.status')}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="subtitle2">
                    {t('label.permissions')}
                  </Typography>
                </Grid>
              </Grid>
              {Object.entries(permissions).map(([key, value]) => (
                <PermissionStatusItem
                  key={key}
                  label={value}
                  status={
                    verifyResponse?.items && verifyResponse.items.length
                      ? _.get(verifyResponse.items[0].CheckList, key)
                      : undefined
                  }
                />
              ))}
            </Grid>
          </Paper>
        </Grid>
        <Grid container item direction="column" lg={6} xs={12}>
          <Grid item>
            <Typography variant="h3">{t('step.verifyRole')}</Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography variant="body2">
              {t('description.permission')}
            </Typography>
          </Grid>
          {!isVerified && (
            <>
              <Grid container item mt={3}>
                <Grid item mr={1}>
                  <Typography variant="body2" color="secondary">
                    *
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: t('description.verifyRole'),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item columnSpacing={3} mt={5} alignItems="center">
                <Grid item xs>
                  <TextField
                    name="arn"
                    color="secondary"
                    label={t('label.arn')}
                    placeholder={t('holder.arn')}
                    value={values.arn}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!arnFetched}
                    error={Boolean(errors.arn && touched.arn)}
                    helperText={
                      Boolean(errors.arn && touched.arn) && String(errors.arn)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <LoadingButton
                    type="button"
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={verifyArn}
                    loading={!arnFetched || isVerifying}
                    disabled={Boolean(errors.arn) || isVerifying}
                  >
                    {commonT('button.verify')}
                  </LoadingButton>
                </Grid>
              </Grid>
              {isVerifying && (
                <Grid container item mt={5}>
                  <Grid item mr={1}>
                    <Typography variant="body2" color="secondary">
                      *
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      dangerouslySetInnerHTML={{
                        __html: t('description.verifyingRole'),
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {(isVerifyError || isVerifyingError) && (
                <Grid container item columnSpacing={1} mt={5}>
                  <Grid item>
                    <SvgIcon
                      component={WarningCircle}
                      sx={{ width: '1.125rem', height: '1.125rem' }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      color="error"
                      dangerouslySetInnerHTML={{
                        __html: t('description.verifyError'),
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {isVerified && (
            <Grid container item direction="column" mt={8}>
              <Grid item mt={8}>
                <Typography variant="body2" color="textSecondary">
                  {t('description.nickname')}
                </Typography>
              </Grid>
              <Grid item mt={1}>
                <TextField
                  name="name"
                  color="secondary"
                  label={t('label.nickname')}
                  placeholder={t('holder.nickname')}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(errors.name && touched.name)}
                  helperText={
                    Boolean(errors.name && touched.name) && String(errors.name)
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {isVerified && (
        <Box display="flex" justifyContent="flex-end" mt={{ md: 11, xs: 7 }}>
          {isPayer ? (
            <LoadingButton
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => handleSubmit(true)}
              loading={isCreating}
              disabled={Boolean(errors.name)}
            >
              {commonT('button.next')}
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => handleSubmit(false)}
              loading={isCreating}
              disabled={Boolean(errors.name)}
            >
              {commonT('button.done')}
            </LoadingButton>
          )}
        </Box>
      )}
    </>
  );
};

export default ExternalVerifyRoleStep;
