import { useQuery } from 'react-query';
import { SubscriptionService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useQuery(
    QUERY_KEY.ADMIN.SUBSCRIPTION.GET_SUBSCRIPTIONS,
    () => SubscriptionService.getSubscriptions(),
    { suspense: true, useErrorBoundary: true },
  );
