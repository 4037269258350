import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useConfigureCURForm } from '@pages/private/onboard/cur/ConfigureCURPage.form';
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { AWS_REGION } from '@constants';
import { LoadingButton } from '@mui/lab';
import { usePublicCreateCur } from '@hooks/api/external';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  externalStepState,
  externalUidState,
} from '@pages/private/onboard/external/ExternalAccount.atoms';
import { isAxiosError } from '@utils';
import { useSnackbar } from '@hooks/common';

const ExternalConfigureCURStep: React.FC = () => {
  const { t } = useTranslation('onboard');
  const { t: commonT } = useTranslation('common');
  const { t: awsT } = useTranslation('aws');
  const { t: toastT } = useTranslation('toast');
  const { t: validationT } = useTranslation('validation');
  const regions: Record<SupportedAWSRegion, string> = awsT('region', {
    returnObjects: true,
  });
  const setActive = useSetRecoilState(externalStepState);
  const uid = useRecoilValue(externalUidState);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { isLoading, mutateAsync } = usePublicCreateCur();
  const { initialValues, validationSchema } = useConfigureCURForm();
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    isValid,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (v) => {
      try {
        await mutateAsync({ ...v, uid });
        showSuccessSnackbar(toastT('success.onboard.cur.created'));
        setActive((s) => s + 1);
      } catch (e) {
        if (isAxiosError(e) && e.response?.data?.msg) {
          switch (true) {
            case e.response.data.msg.startsWith('Same Bucket already exist'):
              setFieldError('bucketName', validationT('onboard.cur.bucket'));
              return;
            case e.response.data.msg.startsWith(
              'Same report name already exist',
            ):
              setFieldError('reportName', validationT('onboard.cur.report'));
              return;
            case e.response.data.msg.startsWith(
              'Error during create s3 bucket',
            ):
              showErrorSnackbar(toastT('error.onboard.cur.s3'));
              return;
            case e.response.data.msg.startsWith('CUR connection error'):
              showErrorSnackbar(toastT('error.onboard.cur.connection'));
              return;
            case e.response.data.msg.startsWith('Error during create CUR'):
              showErrorSnackbar(toastT('error.onboard.cur.report'));
              return;
            default:
              showErrorSnackbar(toastT('error.general'));
          }
        }
      }
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h3">{t('header.configureCUR')}</Typography>
      <Typography
        variant="body2"
        mt={2}
        dangerouslySetInnerHTML={{
          __html: t('description.configureCUR', {
            URL: 'https://docs.aws.amazon.com/cur/latest/userguide/what-is-cur.html',
          }),
        }}
      />
      <Grid
        container
        item
        direction="column"
        rowSpacing={4}
        mt={8}
        md={7}
        xs={12}
      >
        <Grid item>
          <TextField
            color="secondary"
            name="reportName"
            value={values.reportName}
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('label.reportName')}
            placeholder={t('holder.reportName')}
            autoCapitalize="off"
            error={Boolean(errors.reportName && touched.reportName)}
            helperText={
              Boolean(errors.reportName && touched.reportName) &&
              String(errors.reportName)
            }
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            color="secondary"
            name="bucketName"
            value={values.bucketName}
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('label.bucketName')}
            placeholder={t('holder.bucketName')}
            autoCapitalize="off"
            error={Boolean(errors.bucketName && touched.bucketName)}
            helperText={
              Boolean(errors.bucketName && touched.bucketName) &&
              String(errors.bucketName)
            }
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            color="secondary"
            size="medium"
            name="region"
            value={values.region}
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('label.region')}
            autoCapitalize="off"
            error={Boolean(errors.region && touched.region)}
            helperText={
              Boolean(errors.region && touched.region) && String(errors.region)
            }
            select
            fullWidth
          >
            {AWS_REGION.map((region) => (
              <MenuItem key={`menuitem-${region}`} value={region}>
                {regions[region]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Box mt={8} textAlign="right">
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          loading={isLoading}
          disabled={!isValid}
        >
          {commonT('button.done')}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default ExternalConfigureCURStep;
