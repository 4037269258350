import { useQuery } from 'react-query';
import { usePaginatedQuery } from '@hooks/api/base';
import { useSelectedAWSAccounts } from '@hooks/api/common';
import { OptimizationEC2Service, PlannerService } from '@api/service';
import { QUERY_KEY } from '@constants';

export const usePlannerUncovered = () =>
  usePaginatedQuery(
    QUERY_KEY.PLANNER.OD_LIST,
    PlannerService.getPlannerUncovered,
    { suspense: true, useErrorBoundary: true },
  );

export const usePlannerRI = () =>
  usePaginatedQuery(
    QUERY_KEY.PLANNER.RI_LIST,
    OptimizationEC2Service.getEC2RIUtilizationList,
    { suspense: true, useErrorBoundary: true },
  );

export const usePlannerSP = () =>
  usePaginatedQuery(
    QUERY_KEY.PLANNER.SP_LIST,
    OptimizationEC2Service.getEC2SPUtilizationList,
    { suspense: true, useErrorBoundary: true },
  );

export const usePlannerChart = () => {
  const { apiQueryString } = useSelectedAWSAccounts();
  return useQuery(
    [QUERY_KEY.PLANNER.CHART, apiQueryString],
    ({ queryKey }) => PlannerService.getPlannerChart(queryKey[1]),
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
};
