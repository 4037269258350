import React from 'react';
import { useS3UsageChart } from '@hooks/api/optimization/s3';
import { StorageChart } from '@components/chart/optimization';
import moment from 'moment';
import { ValueFormatter } from '@utils';
import { useTranslation } from 'react-i18next';
import { ChartCoverBox } from '@components/styled/noData';
import {
  STORAGE_DUMMY_EXTRA,
  STORAGE_DUMMY,
} from '@components/chart/chart.dummy';

const StorageCostChart: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { data } = useS3UsageChart();
  return (
    <ChartCoverBox isEmpty={Boolean(!data?.items?.length)}>
      <StorageChart
        data={data?.items?.length ? data.items : STORAGE_DUMMY}
        xAxis={{
          dataKey: 'Date',
          interval: 0,
          tickFormatter: (v) => moment(v).format('YYYY MMM'),
        }}
        chartInfo={{
          line: {
            dataKey: 'StorageCost',
            name: t('label.chart.storage.storage'),
          },
          area: {
            dataKey: 'TransferCost',
            name: t('label.chart.storage.transfer'),
          },
        }}
        extraInfo={[
          {
            label: t('label.chart.storage.thisMonthStorage'),
            value: ValueFormatter.toCurrency(
              data?.items?.length
                ? data.extras.TotalStorageCost
                : STORAGE_DUMMY_EXTRA.Storage,
            ),
          },
          {
            label: t('label.chart.storage.thisMonthTransfer'),
            value: ValueFormatter.toCurrency(
              data?.items?.length
                ? data.extras.TotalTransferCost
                : STORAGE_DUMMY_EXTRA.Transfer,
            ),
          },
          {
            label: t('label.chart.storage.thisMonthCost'),
            value: ValueFormatter.toCurrency(
              data?.items?.length
                ? data?.extras?.TotalCost || 0
                : STORAGE_DUMMY_EXTRA.Total,
            ),
          },
        ]}
      />
    </ChartCoverBox>
  );
};

export default StorageCostChart;
