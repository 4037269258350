import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { usePaginatedQueryWithAPM } from '@hooks/api/base';
import { OptimizationEBSService } from '@api/service';
import { apmSourceState } from '@atoms/global';
import { detailsEBSState } from '@atoms/details';
import { QUERY_KEY } from '@constants';
import { useSelectedAWSAccounts } from '@hooks/api/common';

export const useEBSInformation = () =>
  usePaginatedQueryWithAPM(
    QUERY_KEY.OPTIMIZATION.EBS.INFORMATION,
    OptimizationEBSService.getEBSInformationList,
    { suspense: true, useErrorBoundary: true },
  );

export const useEBSUsageChart = () => {
  const apmSource = useRecoilValue(apmSourceState);
  const { apiQueryString } = useSelectedAWSAccounts();
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.EBS.USAGE_CHART, apmSource, apiQueryString],
    ({ queryKey }) =>
      OptimizationEBSService.getEBSUsageChart(
        queryKey[1] as APMSource,
        queryKey[2],
      ),
    { suspense: true, useErrorBoundary: true },
  );
};

export const useEBSAutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.EBS.AC_DATA,
    () => OptimizationEBSService.getEBSAutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useEBSDetailsGeneral = () => {
  const volumeId = useRecoilValue(detailsEBSState);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.EBS.DETAILS_GENERAL, volumeId],
    ({ queryKey }) => OptimizationEBSService.getEBSDetailsGeneral(queryKey[1]),
    { suspense: true, useErrorBoundary: true },
  );
};

export const useEBSDetailsSummary = () => {
  const volumeId = useRecoilValue(detailsEBSState);
  const apmSource = useRecoilValue(apmSourceState);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.EBS.DETAILS_SUMMARY, volumeId, apmSource],
    ({ queryKey }) =>
      OptimizationEBSService.getEBSDetailsSummary(
        queryKey[1],
        queryKey[2] as APMSource,
      ),
    { suspense: true, useErrorBoundary: true },
  );
};
