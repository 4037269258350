import { API_URL } from '@constants';
import axios from '@utils/axios';

const getById = (id: string) => axios.get(`${API_URL.PAYMENT}/coupon/${id}`);

const getCoupons = async (): Promise<CCQueryResponse<CouponType>> => {
  const { data } = await axios.get(`${API_URL.PAYMENT}/coupon`);
  return data;
};

const createCoupon = (
  Name: string,
  Duration: string,
  PercentOff: number,
  DurationInMonths: number,
) =>
  axios.post(`${API_URL.PAYMENT}/coupon`, {
    Name,
    PercentOff,
    Duration,
    DurationInMonths: Duration === 'repeating' ? DurationInMonths : undefined,
  });

const deleteCoupon = (CouponId: string) =>
  axios.delete(`${API_URL.PAYMENT}/coupon/${CouponId}`);

const updateCoupon = (coupon: any) =>
  axios.put(`${API_URL.PAYMENT}/coupon`, coupon);

export default {
  getById,
  getCoupons,
  createCoupon,
  updateCoupon,
  deleteCoupon,
};
