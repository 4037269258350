import React, { useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { useGetCoupons } from '@hooks/api/admin/coupon';
import { useToggle } from '@hooks/common';
import { GrmtTableColumnInfo, SimplePaginationTable } from '@components/table';
import { DetailButtonIcon } from '@components/table/table.styled';
import { ValueFormatter } from '@utils';
import CreateCouponModal from './CreateCouponModal';
import DeleteCouponModal from './DeleteCouponModal';

const CouponTable: React.FC = () => {
  const [openCreate, toggleCreate] = useToggle();
  const [openDelete, toggleDelete] = useToggle();
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const { data } = useGetCoupons();
  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      { Header: 'UID', accessor: 'UID' as const },
      { Header: 'Coupon Name', accessor: 'Name' as const },
      {
        Header: 'Discount',
        accessor: 'PercentOff' as const,
        Cell: ({ value }) => ValueFormatter.toPercent(value),
      },
      {
        Header: 'Duration Type',
        accessor: 'Duration' as const,
      },
      {
        Header: 'Duration',
        accessor: 'DurationInMonths' as const,
      },
      {
        Header: 'Status',
        accessor: 'Valid' as const,
        Cell: ({ value }) => (value ? 'Active' : 'Inactive'),
      },
      {
        Header: 'Delete',
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        Cell: ({ row }) => (
          <DetailButtonIcon
            onClick={() => {
              setSelected(row.original.UID);
              toggleDelete();
            }}
          />
        ),
      },
    ],
    [],
  );
  return (
    <>
      <SimplePaginationTable
        data={data?.items}
        columnInfo={columnInfo}
        ExtraElement={
          <Button variant="outlined" onClick={toggleCreate}>
            Add coupon
          </Button>
        }
      />
      {openCreate && (
        <CreateCouponModal open={openCreate} onClose={toggleCreate} />
      )}
      {openDelete && selected && (
        <DeleteCouponModal
          open={openDelete}
          onClose={toggleDelete}
          CouponId={selected}
        />
      )}
    </>
  );
};

export default CouponTable;
