import React from 'react';
import { Paper } from '@mui/material';
import { SuspenseBox } from '@components/styled/suspense';
import { DatabaseUtilizationChart } from './chart';
import { DatabaseUtilTable } from './table';

const DatabaseUtilizationPage: React.FC = () => (
  <Paper square sx={{ px: 7, py: 5 }}>
    <SuspenseBox>
      <DatabaseUtilizationChart />
    </SuspenseBox>
    <SuspenseBox mt={12}>
      <DatabaseUtilTable />
    </SuspenseBox>
  </Paper>
);

export default DatabaseUtilizationPage;
