import axios from '@utils/axios';
import { API_URL } from '@constants';
import { generateHash, treeShakeObject } from '@utils';

const getById = async (id: string): Promise<CCQueryResponse<User>> => {
  const { data } = await axios.get(`${API_URL.USERS}/${id}`);
  return data;
};

const getByCompanyId = (id: string) =>
  axios.get(`${API_URL.USERS}/get_by_company/${id}`);

const getUsers = async (): Promise<CCQueryResponse<User>> => {
  const { data } = await axios.get(API_URL.USERS);
  return data;
};

const createUser = async (
  companyId: string,
  email: string,
  password: string,
  lastname: string,
  firstname: string,
  language: string,
) => {
  const { data } = await axios.post(API_URL.USERS, {
    uid: generateHash(),
    companyId,
    email,
    password,
    lastname,
    firstname,
    language,
    roles: [3],
  });
  return data;
};

const inviteUser = async (
  inviterName: string,
  companyName: string,
  userEmail: string,
  password: string,
) => {
  const { data } = await axios.post(`${API_URL.USERS}/invite`, {
    inviterName,
    companyName,
    userEmail,
    password,
  });
  return data;
};

const resendInvitation = async (
  email: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.post(`${API_URL.USERS}/invite/${email}`, {});
  return data;
};

const updateUser = async (
  original: User,
  changes: Partial<User>,
): Promise<CCQueryResponse<User>> => {
  const { data } = await axios.put(API_URL.USERS, {
    ...treeShakeObject(original),
    ...changes,
  });
  return data;
};

const deleteUser = async (uid: string): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.delete(`${API_URL.USERS}/${uid}`);
  return data;
};

const deleteUsers = (ids: string[]) =>
  axios.delete('/users', {
    data: {
      ItemList: ids,
    },
  });

const getUserExternal = async (
  id: string,
  token: string,
): Promise<CCQueryResponse<User>> => {
  const { data } = await axios.get(`${API_URL.USERS}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

const loginAsMasquerade = async (
  userId: string,
): Promise<CCQueryResponse<LoginResponse>> => {
  const { data } = await axios.post(`${API_URL.USERS}/masquer/login/${userId}`);
  return data;
};

export default {
  getById,
  getByCompanyId,
  getUsers,
  createUser,
  inviteUser,
  updateUser,
  deleteUser,
  deleteUsers,
  resendInvitation,
  getUserExternal,
  loginAsMasquerade,
};
