import { useQuery } from 'react-query';
import { OverviewService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useQuery(
    QUERY_KEY.OVERVIEW.NOTIFICATION,
    () => OverviewService.getNotifications(),
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
