import React, { useCallback, useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  ChartDefaultGradient,
  GrmtAxisDefaultProps,
  GrmtDefaultChartProps,
  GrmtGridDefaultProps,
  GrmtTooltipItem,
} from '@components/chart';
import shadows from '@theme/shadows';
import { ValueFormatter } from '@utils';
import { ColorDot } from '@components/styled';

type MultipleUsageChartProps = GrmtDefaultChartProps<{
  Date: Date;
  Cost: number;
  Items: Array<{ Name: string; Cost: number }>;
}> & {
  Granularity: CEGranularity;
  GroupBy: CEGroupBy;
  TotalCostByItems: Record<string, number>;
};

type PathRadius = 'NONE' | 'BOTH' | 'TOP' | 'BOTTOM';

const makePath = (
  type: PathRadius,
  x: number,
  y: number,
  width: number,
  height: number,
) => {
  switch (type) {
    case 'BOTH':
      return `M${x},${y + 4}A 4,4,0,0,1,${x + 4},${y}L ${
        x + width - 4
      },${y}A 4,4,0,0,1,${x + width},${y + 4}L ${x + width},${
        y + height - 4
      }A 4,4,0,0,1,${x + width - 4},${y + height}L ${x + 4},${
        y + height
      }A 4,4,0,0,1,${x},${y + height - 4}Z`;
    case 'TOP':
      return `M${x},${y + 4}A 4,4,0,0,1,${x + 4},${y}L ${
        x + width - 4
      },${y}A 4,4,0,0,1,${x + width},${y + 4}L ${x + width},${
        y + height
      }L ${x},${y + height}Z`;
    case 'BOTTOM':
      return `M${x},${y}L ${x + width},${y}L ${x + width},${
        y + height - 4
      }A 4,4,0,0,1,${x + width - 4},${y + height}L ${x + 4},${
        y + height
      }A 4,4,0,0,1,${x},${y + height - 4}Z`;
    default:
      return `M ${x},${y} h ${width} v ${height} h -${width} Z`;
  }
};

const COLORS = [
  '#9594ff',
  '#57caeb',
  '#ffe380',
  '#c0d100',
  '#be96f2',
  '#e52297',
  '#5bd9b3',
  '#7c7fa6',
  '#ff7976',
];

function MultipleUsageChart({
  data,
  Granularity,
  GroupBy,
  TotalCostByItems,
}: MultipleUsageChartProps) {
  const { t } = useTranslation('optimization');
  const { t: usageT } = useTranslation('usage');
  const labels = useMemo<string[]>(() => {
    const items = Object.keys(
      _(TotalCostByItems).toPairs().orderBy(1, 'desc').fromPairs().value(),
    ).filter((v) => v !== 'TotalCost');
    if (items.length > 10) {
      return [...items.slice(0, 8), 'Others'];
    }
    return items;
  }, [TotalCostByItems]);
  const chartData = useMemo<Array<Record<string, any>>>(() => {
    return data.map((v) => {
      const items: Record<string, number> = {};
      labels.forEach((k) => {
        Object.assign(items, { [k]: null });
      });
      v.Items.forEach((k) => {
        if (_.isNumber(k.Cost)) {
          if (labels.includes(k.Name)) {
            items[k.Name] = Number(items[k.Name]) + k.Cost;
          } else if (_.has(items, 'Others')) {
            items.Others = Number(items.Others) + k.Cost;
          }
          // const Name = labels.includes(k.Name) ? k.Name : 'Others';
          // if (_.has(items, Name)) {
          //   items[Name] += k.Cost;
          // } else {
          //   Object.assign(items, { [Name]: k.Cost });
          // }
        }
      });
      return {
        Date: v.Date,
        Cost: v.Cost,
        ...items,
      };
    });
  }, [data, labels]);
  const formatName = useCallback(
    (name: string) => {
      if (GroupBy === 'TAG' && _.isEmpty(name)) {
        return usageT('text.empty_tag_value');
      }
      if (name === 'Others') {
        return usageT('text.others');
      }
      return name;
    },
    [GroupBy, usageT],
  );
  return (
    <Box>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={chartData}
          barSize={24}
          margin={{ top: 8, bottom: 8, left: 24 }}
        >
          {ChartDefaultGradient}
          <XAxis
            dataKey="Date"
            interval={Granularity === 'MONTHLY' ? 0 : 1}
            tickFormatter={(v) =>
              ValueFormatter.toDateString(
                v,
                Granularity === 'MONTHLY' ? 'YYYY.MM' : 'M/DD',
              )
            }
            {...GrmtAxisDefaultProps}
          />
          <YAxis
            scale="linear"
            {...GrmtAxisDefaultProps}
            tickFormatter={(value) => ValueFormatter.toLocaleString(value, 0)}
            padding={{ top: 16, bottom: 16 }}
            label={{
              value: t('label.chart.common.cost'),
              position: 'insideLeft',
              angle: -90,
              offset: -16,
              stroke: 'none',
              fill: '#A0A1B2',
              fontSize: '12px',
              fontWeight: 400,
            }}
          />
          <CartesianGrid {...GrmtGridDefaultProps} />
          <Tooltip
            cursor={false}
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const { payload: item } = payload[0];
                return (
                  <Box
                    sx={{
                      width: '400px',
                      borderRadius: '4px',
                      backgroundColor: '#ffffff',
                      boxShadow: shadows[2],
                      p: '24px',
                    }}
                  >
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography variant="subtitle2">
                        {ValueFormatter.toDateString(
                          item.Date,
                          Granularity === 'MONTHLY' ? 'YYYY.MM' : 'M/DD',
                        )}
                      </Typography>
                      <Typography variant="subtitle2">
                        {ValueFormatter.toCurrency(item.Cost)}
                      </Typography>
                    </Box>
                    <Box mt="24px">
                      {labels.map((v, idx) => {
                        if (!_.isNumber(_.get(item, v))) {
                          return null;
                        }
                        return (
                          <GrmtTooltipItem
                            key={`multi_usage_tooltip_${item.Date.toString()}_${v}`}
                            dotColor={COLORS[idx % 9]}
                            name={formatName(v)}
                            value={ValueFormatter.toCurrency(_.get(item, v))}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                );
              }
              return null;
            }}
          />
          {labels.map((label, idx) => (
            <Bar
              key={`multi_usage_bar_${label}`}
              dataKey={label}
              stackId="stack"
              fill={COLORS[idx % 9]}
              shape={({ width, height, fill, x, y, payload }) => {
                if (!height) {
                  return null;
                }
                const origin = _.omit(payload, ['Date', 'Cost']);
                const firstIdx = _.findIndex(labels, (v) => origin[v] !== null);
                const lastIdx = _.findLastIndex(
                  labels,
                  (v) => origin[v] !== null,
                );
                let type: PathRadius = 'NONE';
                if (idx === lastIdx) {
                  type = 'TOP';
                } else if (idx === firstIdx) {
                  type = 'BOTTOM';
                }
                return (
                  <path fill={fill} d={makePath(type, x, y, width, height)} />
                );
              }}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '16px',
          my: '16px',
        }}
      >
        {labels.map((label, idx) => (
          <Box
            key={`multi_usage_legend_${label}`}
            sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
          >
            <ColorDot color={COLORS[idx % 9]} />
            <Typography variant="body2" color="textSecondary">
              {formatName(label)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default MultipleUsageChart;
