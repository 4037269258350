import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useEBSUsageChart } from '@hooks/api/optimization/ebs';
import { CoverageChart } from '@components/chart/optimization';
import { GrmtTooltipItem, GrmtTooltipWrapper } from '@components/chart';
import { primary } from '@theme/colors';
import { ValueFormatter } from '@utils';
import { VOLUME_DUMMY } from '@components/chart/chart.dummy';
import { ChartCoverBox } from '@components/styled/noData';

const VolumeUsageChart: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { data } = useEBSUsageChart();
  return (
    <ChartCoverBox isEmpty={Boolean(!data?.items?.length)}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        columns={20}
      >
        <Grid item md={15} xs={20}>
          <CoverageChart
            data={data?.items?.length ? data.items : VOLUME_DUMMY}
            xAxis={{
              dataKey: 'Date',
              interval: 0,
              tickFormatter: (v) => moment(v).format('YYYY MMM'),
            }}
            chartInfo={[
              {
                name: 'gp3',
                dataKey: 'gp3',
              },
              {
                name: 'gp2',
                dataKey: 'gp2',
              },
              {
                name: 'io2',
                dataKey: 'io2',
              },
            ]}
            tooltipContent={({ active, payload, label }) => {
              if (payload?.length) {
                const { payload: item } = payload[0];
                return (
                  <Box mx={2}>
                    <GrmtTooltipWrapper
                      active={active}
                      payload={payload}
                      label={moment(label).format('YYYY MMM')}
                    >
                      <GrmtTooltipItem
                        dotColor="#FF7976"
                        name="gp3"
                        value={
                          _.has(item, 'gp3')
                            ? ValueFormatter.toCurrency(item.gp3)
                            : '-'
                        }
                      />
                      <GrmtTooltipItem
                        dotColor="#57CAEB"
                        name="gp2"
                        value={
                          _.has(item, 'gp2')
                            ? ValueFormatter.toCurrency(item.gp2)
                            : '-'
                        }
                      />
                      <GrmtTooltipItem
                        dotColor="#9594FF"
                        name="io2"
                        value={
                          _.has(item, 'io2')
                            ? ValueFormatter.toCurrency(item.io2)
                            : '-'
                        }
                      />
                    </GrmtTooltipWrapper>
                  </Box>
                );
              }
              return null;
            }}
          />
        </Grid>
        <Grid
          item
          display={{ md: 'block', xs: 'flex' }}
          justifyContent="space-between"
          md={4}
          xs={20}
          mt={{ md: 0, xs: 4 }}
        >
          <Box borderLeft="2px solid #5C5C66" pl={2}>
            <Typography variant="subtitle2">
              {ValueFormatter.toLocaleString(
                data?.extras?.TotalNumberOfVolumes || 0,
                0,
              )}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {t('label.chart.volume.totalVolume')}
            </Typography>
          </Box>
          <Box borderLeft="2px solid #5C5C66" mt={{ md: 7, xs: 0 }} pl={2}>
            <Typography variant="subtitle2">
              {ValueFormatter.toLocaleString(
                data?.extras?.TotalNumberOfSavingsvolumes || 0,
                0,
              )}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {t('label.chart.volume.totalModified')}
            </Typography>
          </Box>
          <Box
            borderLeft={`2px solid ${primary[600]}`}
            color={primary[600]}
            mt={{ md: 7, xs: 0 }}
            pl={2}
          >
            <Typography variant="subtitle2">
              {ValueFormatter.toCurrency(
                data?.extras?.TotalPossibleSaving || 0,
              )}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {t('label.chart.volume.totalSavings')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ChartCoverBox>
  );
};

export default VolumeUsageChart;
