import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { WithAdmin } from '@hooks/hoc';
import { OpenCloudShellStep, RunScriptStep, VerifyRoleStep } from './steps';
import { connectAWSStepState } from './ConnectAWS.atoms';
import { OnboardPageWrapper, OnboardStepper } from '../Onboard.styled';

const ConnectAWSPage: React.FC = () => {
  const { t } = useTranslation('onboard');
  const active = useRecoilValue(connectAWSStepState);
  const resetActive = useResetRecoilState(connectAWSStepState);
  useEffect(() => {
    resetActive();
  }, []);
  return (
    <OnboardPageWrapper title={t('title.connect')}>
      <OnboardStepper
        activeStep={active}
        stepLabels={[
          t('step.openShell'),
          t('step.runScript'),
          t('step.verifyRole'),
        ]}
      />
      <Box mt={11}>
        {active === 0 && <OpenCloudShellStep />}
        {active === 1 && <RunScriptStep />}
        {active === 2 && <VerifyRoleStep />}
      </Box>
    </OnboardPageWrapper>
  );
};

export default WithAdmin(ConnectAWSPage);
