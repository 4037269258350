import * as Yup from 'yup';

export type AddUserForm = {
  UserUID: string;
  AWSAccountID: string;
  ReadOnly: boolean;
};

export const useAddUserForm = (): UseValidation<AddUserForm> => ({
  initialValues: {
    UserUID: '',
    AWSAccountID: '',
    ReadOnly: true,
  },
  validationSchema: Yup.object({
    UserUID: Yup.string().trim().defined(),
    AWSAccountID: Yup.string().trim().defined(),
    ReadOnly: Yup.boolean(),
  }).defined(),
});
