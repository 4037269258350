/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { Link as InternalLink } from 'react-router-dom';
import {
  Box,
  Button,
  Paper,
  Link,
  styled,
  Typography,
  BoxProps,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { useBestPracticeHelper } from '@hooks/helper';
import { bluegrey, tertiary } from '@theme/colors';
import { ValueFormatter } from '@utils';
import { useIgnoreNotification } from '@hooks/api/overview';
import {
  LoadingButton,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';

export const IntelligenceCard: React.FC<{
  item: IntelligenceItem;
  region?: string;
  showSavings?: boolean;
}> = ({ item, region, showSavings }) => {
  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');
  const { isLoading, mutateAsync } = useIgnoreNotification();
  const ignoreNotification = useCallback(async () => {
    try {
      await mutateAsync({ uid: item.UID });
    } catch (e) {
      console.error(e);
    }
  }, [item.UID]);
  const {
    getTitle,
    getDescription,
    hasRecommendation,
    getRecommendation,
    getCTAText,
    hasExternalLink,
    getExternalLink,
    getInternalLink,
  } = useBestPracticeHelper();
  return (
    <Paper variant="elevation" elevation={1}>
      <Box px={5} pt={3}>
        <Box display="flex" alignItems="center" mb={3}>
          <Typography variant="subtitle2" mr={3}>
            {getTitle(item) || item.Title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {ValueFormatter.toDateString(item.Created, 'YYYY-MM-DD')}
          </Typography>
          {showSavings && (
            <Typography variant="body2" color="primary">
              {`${t(
                'label.common.savings_possible',
              )} ${ValueFormatter.toCurrency(item.MonthlyCostSaving)}`}
            </Typography>
          )}
        </Box>
        <Typography
          variant="body2"
          color="textSecondary"
          dangerouslySetInnerHTML={{
            __html: getDescription(item) || item.Description,
          }}
        />
      </Box>
      {hasRecommendation(item) ? (
        <>
          <Typography
            variant="body2"
            color="textSecondary"
            dangerouslySetInnerHTML={{
              __html: getRecommendation(item) || '',
            }}
            mx={5}
            mt={3}
            mb={4}
          />
          <Box textAlign="right" px={2} py={1}>
            {hasExternalLink(item) ? (
              <Button
                variant="text"
                component={Link}
                href={getExternalLink(item, { region })}
                target="_blank"
                endIcon={
                  <ArrowForwardIcon shapeRendering="geometricPrecision" />
                }
                sx={{ color: tertiary[400] }}
              >
                {getCTAText(item)}
              </Button>
            ) : (
              <Button
                variant="text"
                component={InternalLink}
                to={getInternalLink(item)}
                endIcon={
                  <ArrowForwardIcon shapeRendering="geometricPrecision" />
                }
                sx={{ color: tertiary[400] }}
              >
                {getCTAText(item)}
              </Button>
            )}
          </Box>
        </>
      ) : (
        <Box textAlign="right" mt={4} px={2} py={1}>
          <LoadingButton
            variant="text"
            onClick={ignoreNotification}
            loading={isLoading}
            sx={{ color: tertiary[400] }}
          >
            {commonT('button.okay')}
          </LoadingButton>
        </Box>
      )}
    </Paper>
  );
};
IntelligenceCard.defaultProps = {
  region: undefined,
  showSavings: false,
};

const NotificationDot = React.memo(
  ({ CaseId }: Pick<IntelligenceItem, 'CaseId'>) => {
    switch (CaseId) {
      case 'AWS-EC2-0001':
      case 'AWS-EC2-0010':
      case 'AWS-EC2-0013':
      case 'AWS-EC2-0014':
      case 'AWS-EC2-0015':
      case 'AWS-EC2-0020':
      case 'AWS-EC2-0023':
      case 'AWS-EC2-0024':
      case 'AWS-Billing-0001':
      case 'AWS-Billing-0007':
      case 'AWS-Billing-0008':
        return <TimelineDot />;
      case 'AWS-EC2-0011':
      case 'AWS-EC2-0012':
      case 'AWS-EC2-0021':
      case 'AWS-EC2-0022':
      case 'AWS-GENERAL-0001':
      case 'AWS-GENERAL-0003':
      case 'AWS-GENERAL-0004':
        return <TimelineDot variant="outlined" />;
      default:
        return null;
    }
  },
);
const NotificationConnector = styled(TimelineConnector)({
  width: 0,
  background: 'none',
  borderLeft: '2px dashed #f0f0f7',
});

const NotificationTimelineItemComponent: React.FC<{
  item: IntelligenceItem;
  region?: string;
}> = ({ item, region }) => {
  const {
    getTitle,
    getDescription,
    getCategoryName,
    hasRecommendation,
    getRecommendation,
    hasExternalLink,
    getExternalLink,
    getInternalLink,
    getCTAText,
    getNotificationType,
  } = useBestPracticeHelper();
  return (
    <TimelineItem>
      <TimelineOppositeContent sx={{ flex: 0, px: 2 }}>
        <Box minWidth={128}>
          <Typography variant="subtitle2" align="right" mb={0.5}>
            {getNotificationType(item)}
          </Typography>
          <Typography variant="body2" color="#a0a1b2" align="right" mb={1}>
            {getCategoryName(item.Category)}
          </Typography>
          <Typography variant="body2" color="#a0a1b2" align="right">
            {ValueFormatter.toDateString(item.Created, 'YYYY-MM-DD')}
          </Typography>
        </Box>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <NotificationDot {...item} />
        <NotificationConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ px: 2, pb: 10 }}>
        <Box>
          <Typography variant="subtitle2" mb={1}>
            {getTitle(item) || item.Title}
          </Typography>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: getDescription(item) || item.Description,
            }}
          />
          {hasRecommendation(item) && (
            <Box mt={2}>
              <Box bgcolor={bluegrey[100]} mb={2} p={1}>
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: getRecommendation(item) || '',
                  }}
                />
              </Box>
              {hasExternalLink(item) ? (
                <Button
                  variant="text"
                  component={Link}
                  href={getExternalLink(item, { region })}
                  target="_blank"
                  endIcon={
                    <ArrowForwardIcon
                      shapeRendering="geometricPrecision"
                      sx={{ width: '1rem', height: '1rem' }}
                    />
                  }
                >
                  {getCTAText(item)}
                </Button>
              ) : (
                <Button
                  variant="text"
                  component={InternalLink}
                  to={getInternalLink(item)}
                  endIcon={
                    <ArrowForwardIcon
                      shapeRendering="geometricPrecision"
                      sx={{ width: '1rem', height: '1rem' }}
                    />
                  }
                >
                  {getCTAText(item)}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};
NotificationTimelineItemComponent.defaultProps = {
  region: undefined,
};
export const NotificationTimelineItem = React.memo(
  NotificationTimelineItemComponent,
);

const NotificationMenuItemComponent: React.FC<
  { item: NotificationItem } & Pick<BoxProps, 'onClick'>
> = ({ item, onClick }) => {
  const { getTitle, getNotificationType, isGeneralAlert } =
    useBestPracticeHelper();
  return (
    <Box
      px={2}
      py={1}
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          bgcolor: bluegrey[100],
        },
      }}
    >
      <Typography
        variant="caption"
        sx={{
          bgcolor: isGeneralAlert(item) ? bluegrey[200] : '#f1e5fc',
          borderRadius: '2px',
          px: 0.5,
          py: 0.25,
        }}
      >
        {getNotificationType(item)}
      </Typography>
      <Typography variant="body2" mt={1} mb={0.5} noWrap>
        {getTitle(item)}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {ValueFormatter.toDateString(item.Created, 'YYYY.MM.DD')}
      </Typography>
    </Box>
  );
};
export const NotificationMenuItem = React.memo(NotificationMenuItemComponent);
