import { useMutation, useQueryClient } from 'react-query';
import { PaymentService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.SETTINGS.PAYMENT.PURCHASE_PRODUCT,
    mutationFn: (variable: {
      ProductId: string;
      PaymentMethod: string;
      PromotionCode?: string;
    }) =>
      PaymentService.purchaseProduct(
        variable.ProductId,
        variable.PaymentMethod,
        variable.PromotionCode,
      ),
    onSuccess: () =>
      queryClient.invalidateQueries(
        QUERY_KEY.SETTINGS.PAYMENT.GET_PURCHASE_STATUS,
      ),
  });
};
