import { useMutation } from 'react-query';
import { AWSCEService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default function useDownloadCEUsage() {
  return useMutation({
    mutationKey: QUERY_KEY.AWS.CE.DOWNLOAD_CSV,
    mutationFn: (v: CEUsageRequestInternal) => AWSCEService.downloadCSV(v),
  });
}
