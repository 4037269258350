import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const DT_MAP = {
  'US East (Ohio)': 'us-east-2',
  'US East (N. Virginia)': 'us-east-1',
  'US East (Boston)': 'us-east-1-bos-1',
  'US East (Dallas)': 'us-east-1-dfw-1',
  'US East (Houston)': 'us-east-1-iah-a',
  'US East (Miami)': 'us-east-1-mia-1',
  'US East (Philadelphia)': 'us-east-1-phl-1',
  'US East (Verizon) - Atlanta': 'us-east-1-wl1-atl-wlz-1',
  'US East (Verizon) - Boston': 'us-east-1-wl1-bos-wlz-1',
  'US East (Verizon) - Chicago': 'us-east-1-wl1-chi-wlz-1',
  'US East (Verizon) - Dallas': 'us-east-1-wl1-dfw-wlz-1',
  'US East (Verizon) - Houston': 'us-east-1-wl1-iah-wlz-1',
  'US East (Verizon) - Miami': 'us-east-1-wl1-mia-wlz-1',
  'US East (Verizon) - New York': 'us-east-1-wl1-nyc-wlz-1',
  'US East (Verizon) - Washington DC': 'us-east-1-wl1-was-wlz-1',
  'US West (N. California)': 'us-west-1',
  'US West (Oregon)': 'us-west-2',
  'US West (Denver)': 'us-west-2-den-1a',
  'US West (Los Angeles)': 'us-west-2-lax-1',
  'US West (Verizon) - Denver': 'us-west-2-wl1-den-wlz-1',
  'US West (Verizon) - Las Vegas': 'us-west-2-wl1-las-wlz-1',
  'US West (Verizon) - Phoenix': 'us-west-2-wl1-phx-wlz-1',
  'US West (Verizon) - San Francisco Bay Area': 'us-west-2-wl1-sfo-wlz-1',
  'US West (Verizon) - Seattle': 'us-west-2-wl1-sea-wlz-1',
  'Africa (Cape Town)': 'af-south-1',
  'Asia Pacific (Hong Kong)': 'ap-east-1',
  'Asia Pacific (Jakarta)': 'ap-southeast-3',
  'Asia Pacific (Mumbai)': 'ap-south-1',
  'Asia Pacific (Osaka)': 'ap-northeast-3',
  'Asia Pacific (Seoul)': 'ap-northeast-2',
  'Asia Pacific (Singapore)': 'ap-southeast-1',
  'Asia Pacific (Sydney)': 'ap-southeast-2',
  'Asia Pacific (Tokyo)': 'ap-northeast-1',
  'Asia Pacific (SKT) - Daejeon': 'ap-northeast-2-wl1-cjj-wlz-1',
  'Asia Pacific (KDDI) - Osaka': 'ap-northeast-1-wl1-kix-wlz-1',
  'Asia Pacific (KDDI) - Tokyo': 'ap-northeast-1-wl1-nrt-wlz-1',
  'Canada (Central)': 'ca-central-1',
  'EU (Frankfurt)': 'eu-central-1',
  'EU (Ireland)': 'eu-west-1',
  'EU (London)': 'eu-west-2',
  'EU (Milan)': 'eu-south-1',
  'EU (Paris)': 'eu-west-3',
  'EU (Stockholm)': 'eu-north-1',
  'EU West (Vodafone) - London': 'eu-west-2-wl1-lon-wlz-1',
  'Middle East (Bahrain)': 'me-south-1',
  'South America (Sao Paulo)': 'sa-east-1',
  'AWS GovCloud (US-East)': 'us-gov-east-1',
  'AWS GovCloud (US-West)': 'us-gov-west-1',
  'China (Beijing)': 'cn-north-1',
  'China (Ningxia)': 'cn-northwest-1',
} as const;

export default () => {
  const { t: awsT } = useTranslation('aws', { useSuspense: false });
  const { t: optT } = useTranslation('optimization', { useSuspense: false });
  const regions = awsT('region', { returnObjects: true });
  const continents = awsT('continent', { returnObjects: true });
  const cities = awsT('city', { returnObjects: true });

  const getAWSRegionName = useCallback(
    (code: string): string => {
      if (Object.keys(regions).includes(code)) {
        return _.get(regions, code);
      }
      if (code === 'All') {
        return awsT('anomaly.All');
      }
      return code || null;
    },
    [awsT, regions],
  );

  const getAWSRegionContinent = useCallback(
    (code: string) => {
      const key = Object.keys(continents).find((v) => code.startsWith(v));
      return _.get(continents, key) || code;
    },
    [continents],
  );

  const getAWSRegionCity = useCallback(
    (code: string) => {
      if (Object.keys(cities).includes(code)) {
        return _.get(cities, code);
      }
      return code;
    },
    [cities],
  );

  const getAWSRegionNameWithCode = useCallback(
    (code: string): string => {
      if (Object.keys(cities).includes(code)) {
        return `${_.get(cities, code)} (${code})`;
      }
      if (code === 'All') {
        return awsT('anomaly.All');
      }
      return code || null;
    },
    [awsT, cities],
  );

  const getOptimizationAWSRegion = useCallback(
    (code: string) => {
      if (Object.keys(cities).includes(code)) {
        return (
          <>
            {_.get(cities, code)}
            <br />
            {`(${code})`}
          </>
        );
      }
      if (code === 'All') {
        return awsT('anomaly.All');
      }
      return code || null;
    },
    [awsT, cities],
  );

  const getAnomalyAWSRegion = useCallback(
    (code: string): string => {
      if (Object.keys(regions).includes(code)) {
        return `${_.get(regions, code)} / ${code}`;
      }
      if (code === 'All') {
        return awsT('anomaly.All');
      }
      return code || null;
    },
    [awsT, regions],
  );

  const getDataTransferRegion = useCallback(
    (v: string): string => {
      if (_.has(DT_MAP, v)) {
        return getAWSRegionName(_.get(DT_MAP, v));
      }
      if (v === 'External') {
        return awsT('service.External');
      }
      return v;
    },
    [getAWSRegionName, awsT],
  );

  const getDataTransferType = useCallback((v: string): string => {
    const transferType = optT('label.transfer', { returnObjects: true });
    if (_.has(transferType, v)) {
      return _.get(transferType, v) || v;
    }
    return v;
  }, []);

  const getMiscellaneousAWSRegion = useCallback(
    (code: string) => {
      if (Object.keys(cities).includes(code)) {
        return (
          <>
            {code}
            {` (${_.get(cities, code)})`}
          </>
        );
      }
      if (code === 'global') {
        return awsT('region.global');
      }
      return code || null;
    },
    [awsT, cities],
  );

  const convertAWSProductCode = useCallback(
    (v: string): string => {
      switch (v) {
        case 'Amazon Elastic Compute Cloud':
          return 'Amazon EC2';
        case 'Amazon Relational Database Service':
          return 'Amazon RDS';
        case 'Amazon Simple Storage Service':
          return 'Amazon S3';
        case 'Amazon Simple Email Service':
          return 'Amazon SES';
        case 'Amazon Simple Queue Service':
          return 'Amazon SQS';
        case 'AWS Key Management Service':
          return 'AWS KMS';
        case 'Amazon Simple Notification Service':
          return 'Amazon SNS';
        case 'Amazon EC2 Container Registry (ECR)':
          return 'Amazon ECR';
        case 'AmazonCloudWatch':
          return 'CloudWatch';
        case 'Savings Plans for AWS Compute usage':
          return 'Savings Plans';
        case 'Elastic Load Balancing':
          return 'AWS ELB';
        case 'Amazon Elastic Container Service for Kubernetes':
          return 'Amazon EKS';
        case 'Amazon DocumentDB (with MongoDB compatibility)':
          return 'Amazon DocumentDB';
        case 'AWS Database Migration Service':
          return 'AWS DMS';
        case 'Amazon Elastic File System':
          return 'Amazon EFS';
        case 'Amazon Managed Streaming for Apache Kafka':
          return 'Amazon MSK';
        case 'Amazon Elastic Container Registry Public':
          return 'Amazon ECR Public';
        case 'MarketPlace':
          return 'AWS MarketPlace';
        case 'Others':
          return awsT('service.Others');
        default:
          return v;
      }
    },
    [awsT],
  );

  return {
    getAWSRegionName,
    getAWSRegionContinent,
    getAWSRegionCity,
    getAWSRegionNameWithCode,
    getOptimizationAWSRegion,
    getAnomalyAWSRegion,
    getDataTransferRegion,
    getDataTransferType,
    getMiscellaneousAWSRegion,
    convertAWSProductCode,
  };
};
