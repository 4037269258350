import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { AWSAccountService } from '@api/service';
import { QUERY_KEY } from '@constants';
import {
  externalTokenState,
  externalUserIdState,
} from '@pages/private/onboard/external/ExternalAccount.atoms';

export default () => {
  const token = useRecoilValue(externalTokenState);
  const userId = useRecoilValue(externalUserIdState);
  const { isSuccess, data } = useQuery(
    QUERY_KEY.ONBOARD.FROM_CLI,
    () => AWSAccountService.getArnFromCli(userId, token),
    {
      retryDelay: 10000,
      suspense: true,
    },
  );
  return {
    isSuccess,
    createdArn: data?.items?.length ? data.items[0].Arn : undefined,
  };
};
