import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEC2DetailsTransferDetails } from '@hooks/api/optimization/ec2';
import { useAWSHelper } from '@hooks/helper';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { ValueFormatter } from '@utils';
import DetailsComputeTransferFilter from './DetailsComputeTransferFilter';

const DetailsComputeTransferTable: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { getDataTransferRegion } = useAWSHelper();
  const { data, onPageChange, pageSize } = useEC2DetailsTransferDetails();

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.transfer.from'),
        accessor: 'FromLocation' as const,
        disableSortBy: true,
        Cell: ({ value }) => getDataTransferRegion(value),
      },
      {
        Header: t('label.transfer.to'),
        accessor: 'ToLocation' as const,
        disableSortBy: true,
        Cell: ({ value }) => getDataTransferRegion(value),
      },
      {
        Header: `${t('label.transfer.traffic')} (GB)`,
        accessor: 'Traffic' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toLocaleString(value) || '-',
      },
      {
        Header: t('label.common.cost'),
        accessor: 'CurrentCost' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value) || '-',
      },
    ],
    [getDataTransferRegion],
  );

  return (
    <DataPaginationTable
      TitleElement={t('label.common.detail')}
      data={data?.body?.items}
      columnInfo={columnInfo}
      onPageChange={onPageChange}
      totalCount={data?.body?.totalCount}
      pageSize={pageSize}
      FilterElement={DetailsComputeTransferFilter}
      enableSort
    />
  );
};

export default DetailsComputeTransferTable;
