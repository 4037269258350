import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEC2DetailsRecommend } from '@hooks/api/optimization/ec2';
import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import _ from 'lodash';
import { isRecommendCategory, isRecommendPeriod, ValueFormatter } from '@utils';
import { RecommendActionDivider } from '@components/styled';
import { QuestionTooltip } from '@components/styled/tooltip';
import { TableRecommendationPeriod } from '@components/table/table.styled';
import { bluegrey } from '@theme/colors';
import { OptimizationRecommendRadioPaper } from '@components/styled/paper';
import { useRecoilValue } from 'recoil';
import { apmSourceState } from '@atoms/global';
import { recommendPeriodState } from '@atoms/optimization';

type RecommendationItem = Record<RecommendCategory, OptimizationRecommendItem>;

const DetailsComputeUtilRecommend: React.FC = () => {
  const { t } = useTranslation('optimization');
  const apmSource = useRecoilValue(apmSourceState);
  const period = useRecoilValue(recommendPeriodState);
  const { data } = useEC2DetailsRecommend();
  const [option, setOption] = useState<RecommendCategory>('SavingsFirst');
  const recommendVisible = useMemo<boolean>(() => {
    let result = false;
    if (data?.items?.length && data.items[0].RecommendType) {
      const { RecommendType } = data.items[0];
      Object.keys(RecommendType).forEach((p) => {
        if (isRecommendPeriod(p) && RecommendType[p][apmSource]) {
          Object.keys(RecommendType[p][apmSource]).forEach((c) => {
            if (
              isRecommendCategory(c) &&
              RecommendType[p][apmSource][c]?.RecommendType
            ) {
              result = true;
            }
          });
        }
      });
    }
    return result;
  }, [data?.items?.length, apmSource]);
  const recommendOptions = useMemo<RecommendationItem | undefined>(() => {
    if (data?.items?.length && data.items[0].RecommendType) {
      const item: RecommendationItem | undefined = _.get(
        data.items[0].RecommendType,
        `${period}.${apmSource}`,
      );
      if (
        item &&
        (item.SavingsFirst?.RecommendType ||
          item.StabilityFirst?.RecommendType ||
          item.Chipset?.RecommendType)
      ) {
        return item;
      }
    }
    return undefined;
  }, [data?.items?.length, period, apmSource]);
  useEffect(() => {
    if (
      recommendOptions &&
      !_.get(recommendOptions, `${option}.RecommendType`)
    ) {
      for (let i = 0; i < Object.keys(recommendOptions).length; i += 1) {
        const c = Object.keys(recommendOptions)[i];
        if (isRecommendCategory(c) && recommendOptions[c]?.RecommendType) {
          setOption(c);
          break;
        }
      }
    }
  }, [option, recommendOptions]);
  if (!data?.items?.length || !recommendVisible) {
    return null;
  }
  return (
    <Box mt={10}>
      <RecommendActionDivider
        TitleExtra={
          <QuestionTooltip
            title={t('label.tooltip.ec2Recommend')}
            color="bluegrey"
            size="0.875rem"
          />
        }
      />
      <Stack direction="row" alignItems="center" spacing={2} mt={5}>
        <Typography color={bluegrey[400]}>
          {t('label.util.recommendPeriod', { returnObjects: true })[0]}
        </Typography>
        <TableRecommendationPeriod />
        <Typography color={bluegrey[400]}>
          {t('label.util.recommendPeriod', { returnObjects: true })[1]}
        </Typography>
      </Stack>
      <RadioGroup
        value={option}
        onChange={(e, v) => setOption(v as RecommendCategory)}
        sx={{ mt: 4 }}
      >
        <Grid container spacing={3}>
          <Grid item lg={4} xs={12}>
            <OptimizationRecommendRadioPaper
              value="SavingsFirst"
              disabled={Boolean(!recommendOptions?.SavingsFirst?.RecommendType)}
              label={t('label.common.savingsFirst')}
              selected={option}
              cost={recommendOptions?.SavingsFirst?.PossibleSavings}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <OptimizationRecommendRadioPaper
              value="StabilityFirst"
              disabled={Boolean(
                !recommendOptions?.StabilityFirst?.RecommendType,
              )}
              label={t('label.common.stabilityFirst')}
              selected={option}
              cost={recommendOptions?.StabilityFirst?.PossibleSavings}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <OptimizationRecommendRadioPaper
              value="Chipset"
              disabled={Boolean(!recommendOptions?.Chipset?.RecommendType)}
              label={t('label.common.chipsetCompatibility')}
              selected={option}
              cost={recommendOptions?.Chipset?.PossibleSavings}
            />
          </Grid>
        </Grid>
      </RadioGroup>
      <Paper variant="elevation" sx={{ px: 6, py: 5, mt: 4 }}>
        {recommendOptions ? (
          <Box
            display="flex"
            flexDirection={{ lg: 'row', xs: 'column' }}
            justifyContent={{ lg: 'space-between', xs: undefined }}
            alignItems={{ lg: 'center', xs: undefined }}
          >
            <Box display="flex">
              <Typography variant="h3" color="textSecondary" mr={3}>
                {t('label.common.changeType')}:
              </Typography>
              <Typography variant="h3">{data.items[0].CurrentType}</Typography>
              <ArrowRightIcon sx={{ width: '2rem', height: '2rem', mx: 1 }} />
              <Typography variant="h3">
                {Boolean(recommendOptions) &&
                  recommendOptions[option]?.RecommendType}
              </Typography>
            </Box>
            <Box display="flex" mt={{ lg: 0, xs: 3 }}>
              <Typography variant="subtitle2" mr={5}>
                {t('label.common.cost_new')}
              </Typography>
              <Typography variant="subtitle2" color="primary">
                {Boolean(recommendOptions) &&
                  ValueFormatter.toCurrency(
                    data.items[0].MonthlyCost -
                      recommendOptions[option]?.PossibleSavings,
                  )}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography variant="subtitle2" color="textSecondary">
            {t('label.util.noRecommend')}
          </Typography>
        )}
      </Paper>
      <Box textAlign="right" mt={6}>
        <Button
          size="large"
          component={Link}
          href={`https://console.aws.amazon.com/ec2/home?region=${data.items[0].Region}#InstanceDetails:instanceId=${data.items[0].InstanceId}`}
          target="_blank"
          disabled={Boolean(!recommendOptions)}
          endIcon={<ArrowForwardIcon sx={{ width: '19px', height: '19px' }} />}
        >
          {t('button.change.ec2')}
        </Button>
      </Box>
    </Box>
  );
};

export default DetailsComputeUtilRecommend;
