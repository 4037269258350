import React, { useEffect, useMemo } from 'react';
import { Box, Grid, SvgIcon, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { CCModalProps, DialogAction, DialogModal } from '@components/modal';
import { useCreateUser, useInviteUser } from '@hooks/api/settings/user';
import { useCurrentInfo } from '@hooks/api/common';
import { ReactComponent as CheckCircle } from '@assets/icons/common/CheckCircle.svg';
import { generatePassword, hashPassword, isAxiosError } from '@utils';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes';

type CreateUserForm = {
  companyId: string;
  email: string;
  firstname: string;
  lastname: string;
  language: string;
};

const useCreateUserForm = (): UseValidation<CreateUserForm> => {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      companyId: '',
      email: '',
      firstname: '',
      lastname: '',
      language: 'en',
    },
    validationSchema: Yup.object({
      companyId: Yup.string().trim().defined(),
      email: Yup.string()
        .trim()
        .email(t('auth.email'))
        .defined(t('common.required')),
      firstname: Yup.string().trim().defined(t('common.required')),
      lastname: Yup.string()
        .matches(/^\s*\S[\s\S]*$/)
        .defined(t('common.required')),
      language: Yup.string().trim().defined(),
    }).defined(),
  };
};

const CreateUserModal: React.FC<CCModalProps> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dialog');
  const { t: commonT } = useTranslation('common');
  const { t: formT } = useTranslation('form');
  const { t: settingsT } = useTranslation('settings');
  const { t: validationT } = useTranslation('validation');
  const { currentCompany, currentUser } = useCurrentInfo();
  const { isLoading: isCreating, mutateAsync: createUser } = useCreateUser();
  const {
    isSuccess,
    isLoading: isSending,
    mutateAsync: sendInvitation,
  } = useInviteUser();
  const isLoading = isCreating || isSending;
  const { initialValues, validationSchema } = useCreateUserForm();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (v) => {
      try {
        const password = generatePassword();
        await createUser({
          ...v,
          password: hashPassword(password),
        });
        await sendInvitation({
          inviterName: currentUser?.firstname,
          companyName: currentCompany?.name,
          userEmail: v.email,
          password,
        });
      } catch (e) {
        if (isAxiosError(e)) {
          if (e.response.data?.message) {
            switch (e.response.data.message) {
              case 'Same users email or id exists':
                setFieldError('email', validationT('user.exist'));
                break;
              default:
                break;
            }
          }
        }
      }
    },
  });

  useEffect(() => {
    if (currentUser?.language) {
      setFieldValue('language', currentUser.language);
    }
  }, [currentUser?.language]);

  useEffect(() => {
    if (currentCompany?.id) {
      setFieldValue('companyId', currentCompany.id);
    }
  }, [currentCompany?.id]);

  const actions = useMemo<Array<DialogAction>>(() => {
    if (isSuccess) {
      return [
        {
          color: 'inherit',
          onClick: onClose,
          label: t('settings.user.invite.button', { context: 'back' }),
        },
        {
          onClick: () => navigate(ROUTES.SETTINGS.ACCOUNTS),
          label: t('settings.user.invite.button', { context: 'success' }),
        },
      ];
    }
    return [
      {
        color: 'inherit',
        onClick: onClose,
        label: commonT('button.cancel'),
      },
      {
        type: 'submit',
        loading: isLoading,
        label: t('settings.user.invite.button'),
      },
    ];
  }, [isSuccess, isLoading, onClose, navigate]);

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="sm"
      HeaderProps={
        isSuccess
          ? undefined
          : {
              icon: 'Settings',
              label: t('settings.user.invite.title'),
            }
      }
      Actions={actions}
    >
      {isSuccess ? (
        <>
          <Box display="flex" alignItems="center" mb={5}>
            <SvgIcon
              component={CheckCircle}
              sx={{ width: '40px', height: '40px' }}
            />
            <Typography variant="h3" ml={3}>
              {t('settings.user.invite.title', { context: 'success' })}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            color="textSecondary"
            dangerouslySetInnerHTML={{
              __html: t('settings.user.invite.description', {
                ...values,
                company: currentCompany?.name,
              }),
            }}
          />
        </>
      ) : (
        <>
          <Typography variant="subtitle2" color="textSecondary" mb={3}>
            {settingsT('user.header.info')}
          </Typography>
          <Grid container columnSpacing={3} mb={4}>
            <Grid item xs={6}>
              <TextField
                name="firstname"
                type="text"
                inputMode="text"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="off"
                label={formT('holder.firstName')}
                value={values.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.firstname && errors.firstname)}
                helperText={
                  Boolean(touched.firstname && errors.firstname) &&
                  String(errors.firstname)
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="lastname"
                type="text"
                inputMode="text"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="off"
                label={formT('holder.lastName')}
                value={values.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.lastname && errors.lastname)}
                helperText={
                  Boolean(touched.lastname && errors.lastname) &&
                  String(errors.lastname)
                }
                fullWidth
              />
            </Grid>
          </Grid>
          <TextField
            name="email"
            type="email"
            inputMode="email"
            label={formT('holder.email')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            helperText={
              Boolean(touched.email && errors.email) && String(errors.email)
            }
            sx={{ mb: 3 }}
            fullWidth
          />
        </>
      )}
    </DialogModal>
  );
};

export default CreateUserModal;
