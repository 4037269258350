import React from 'react';
import { WithAdmin } from '@hooks/hoc';
import { SuspensePaper } from '@components/styled/suspense';
import { CloudWatchGuide, DatadogForm, NewrelicForm } from './components';

const SettingsIntegrationPage: React.FC = () => (
  <>
    <SuspensePaper square sx={{ p: 8 }}>
      <DatadogForm />
    </SuspensePaper>
    <SuspensePaper square sx={{ mt: 6, p: 8 }}>
      <NewrelicForm />
    </SuspensePaper>
    <SuspensePaper square sx={{ mt: 6, p: 8 }}>
      <CloudWatchGuide />
    </SuspensePaper>
  </>
);

export default WithAdmin(SettingsIntegrationPage);
