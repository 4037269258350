import { useMutation } from 'react-query';
import { CompanyService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useMutation({
    mutationKey: QUERY_KEY.AUTH.REGISTER,
    mutationFn: (variables: {
      firstName: string;
      lastName: string;
      companyName: string;
      email: string;
      password: string;
      promotion?: string;
      reseller?: string;
    }) =>
      CompanyService.registerCompany(
        variables.firstName,
        variables.lastName,
        variables.companyName,
        variables.email,
        variables.password,
        variables.promotion,
        variables.reseller,
      ),
  });
