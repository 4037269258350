import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { ValueFormatter } from '@utils/index';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV !== 'production',
    fallbackLng: 'ko',
    supportedLngs: ['en', 'ko'],
    defaultNS: 'common',
    nonExplicitSupportedLngs: true,
    load: 'languageOnly',
    returnEmptyString: true,
    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        if (format === 'currency') {
          return ValueFormatter.toCurrency(value);
        }
        if (format === 'percent') {
          return ValueFormatter.toPercent(value);
        }
        if (format === 'locale') {
          return ValueFormatter.toLocaleString(value);
        }
        if (format === 'localezero') {
          return ValueFormatter.toLocaleString(value, 0);
        }
        if (format === 'timestamp') {
          return ValueFormatter.toDateString(value);
        }
        if (format === 'date') {
          return ValueFormatter.toDateString(value, 'YYYY/MM/DD');
        }
        return value;
      },
    },
    backend: {
      loadPath: '/console/locale/{{lng}}/{{ns}}.json',
    },
    detection: {
      order: ['querystring', 'localStorage'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: '@grumatic/lang',
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    },
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: false,
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    },
  });

export default i18n;
