export default {
  ACCESS_TOKEN: '@grumatic/access_token',
  ACCESS_TOKEN_EXPIRED: '@grumatic/access_token_expired',
  REFRESH_TOKEN: '@grumatic/refresh_token',
  REFRESH_TOKEN_EXPIRED: '@grumatic/refresh_token_expired',
  MASQUERADE_MODE: '@grumatic/masquerade_mode',
  RECOIL_STATE: '@grumatic/state',
  RECOMMEND_CATEGORY: '@grumatic/recommend_category',
  RECOMMEND_PERIOD: '@grumatic/recommend_period',
  APM_SOURCE: '@grumatic/apm',
  SELECTED_ACCOUNT: '@grumatic/account_list',
} as const;
