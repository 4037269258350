import { useMutation } from 'react-query';
import { QUERY_KEY } from '@constants';
import { PaymentService } from '@api/service';

export default () =>
  useMutation({
    mutationKey: QUERY_KEY.SETTINGS.PAYMENT.VALIDATE_PROMOTION,
    mutationFn: (variables: { ProductId: string; PromotionCode: string }) =>
      PaymentService.validatePromotion(
        variables.ProductId,
        variables.PromotionCode,
      ),
  });
