import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  useAssignAWSAccount,
  useUserAutocompleteAWS,
} from '@hooks/api/settings/account';
import { useCurrentInfo } from '@hooks/api/common';
import { SimpleTypoTable } from '@components/styled';
import { useSnackbar } from '@hooks/common';
import { useAWSAccountHelper } from '@hooks/helper';
import { SuspensePaper } from '@components/styled/suspense';
import { useAddUserForm } from './AddUserForm';
import AccountDetailAccess from './AccountDetailAccess';

const SettingsAccountDetailPage: React.FC = () => {
  const { uid } = useParams();
  const { t } = useTranslation('settings');
  const { t: optT } = useTranslation('optimization');
  const { t: toastT } = useTranslation('toast');
  const { currentUser, currentRole } = useCurrentInfo();
  const { findAWSAccountByUid } = useAWSAccountHelper();
  const { data: acData } = useUserAutocompleteAWS();
  const { isLoading: isFetching, mutateAsync: fetchAssign } =
    useAssignAWSAccount();
  const selectedAccount = findAWSAccountByUid(uid);
  const isAccessOnly = useMemo<boolean>(() => {
    if (currentRole < 3) {
      return false;
    }
    if (currentUser?.assignedAWSAccount) {
      const acc = currentUser.assignedAWSAccount.find((v) => v.id === uid);
      if (acc) {
        return acc.readOnly;
      }
    }
    return true;
  }, [currentUser, currentRole, uid]);
  const { showSnackbar } = useSnackbar();
  const { initialValues, validationSchema } = useAddUserForm();
  const { values, isValid, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      validateOnChange: true,
      validateOnMount: true,
      onSubmit: async (v) => {
        try {
          await fetchAssign(v);
          showSnackbar(
            toastT('success.settings.account.detail.add'),
            'success',
          );
        } catch (e) {
          showSnackbar(toastT('error.general'), 'error');
        }
      },
    });

  const typoTableData = useMemo<Array<{ label: string; value: any }>>(() => {
    const isManagement = selectedAccount?.isPayer || false;
    const items: Array<{ label: string; value: any }> = [
      {
        label: `AWS ${t('account.label.account', { context: 'type' })}`,
        value: selectedAccount.isPayer
          ? t('account.accountType.management')
          : t('account.accountType.member'),
      },
      {
        label: `AWS ${optT('label.common.accountId')}`,
        value: selectedAccount.id,
      },
      {
        label: t('account.label.account', { context: 'nickname' }),
        value: selectedAccount.name,
      },
      {
        label: t('account.label.role', { context: 'arn' }),
        value: selectedAccount.arn || '-',
      },
    ];
    if (
      isManagement &&
      typeof selectedAccount?.reportName === 'string' &&
      selectedAccount.reportName.length
    ) {
      items.push({
        label: t('account.label.cur'),
        value: selectedAccount.reportName,
      });
      items.push({
        label: t('account.label.bucket'),
        value: selectedAccount.bucketName,
      });
    }
    return items;
  }, [selectedAccount]);

  const getOptionText = useCallback(
    (option: {
      email: string;
      firstname: string;
      lastname: string;
      readOnly: boolean;
    }) => {
      let name = '';
      switch (true) {
        case Boolean(option.firstname.length) &&
          Boolean(option.lastname.length):
          name = ` (${option.firstname} ${option.lastname})`;
          break;
        case Boolean(option.lastname.length):
          name = ` (${option.lastname})`;
          break;
        case Boolean(option.firstname.length):
          name = ` (${option.firstname})`;
          break;
        default:
          break;
      }
      return `${option.email}${name}`;
    },
    [],
  );

  const getOptionLabel = useCallback(
    (option: any) => {
      if (typeof option === 'object') {
        return getOptionText(option);
      }
      if (typeof option === 'string') {
        const info = acData?.items.find((v) => v.uid === option);
        if (info) {
          return getOptionText(info);
        }
      }
      return '';
    },
    [acData?.items],
  );

  useEffect(() => {
    if (uid) {
      setFieldValue('AWSAccountID', uid);
    }
  }, [uid]);

  return (
    <>
      <Typography variant="subtitle2" color="textSecondary" mb={2}>
        {t('account.header.detail')}
      </Typography>
      <Paper square sx={{ border: 'none' }}>
        <SimpleTypoTable data={typoTableData} />
      </Paper>
      <Typography variant="subtitle2" color="textSecondary" mt={6} mb={2}>
        {t('account.header.access')}
      </Typography>
      <Paper square sx={{ px: 7, py: 5 }}>
        <Typography mb={2}>{t('account.label.available')}:</Typography>
        <Grid container columnSpacing={3} rowSpacing={3}>
          <Grid item md={isAccessOnly ? 12 : 7} xs={12}>
            <SuspensePaper>
              <AccountDetailAccess accessOnly={isAccessOnly} />
            </SuspensePaper>
          </Grid>
          <Grid item display={isAccessOnly ? 'none' : 'block'} md={5} xs={12}>
            <form onSubmit={handleSubmit}>
              <Paper sx={{ px: 4, py: 5 }}>
                <Typography variant="h3" mb={4}>
                  {t('account.label.user', { context: 'add' })}
                </Typography>
                <Typography variant="body2" mb={1}>
                  {t('account.label.email')}
                </Typography>
                <Autocomplete
                  value={values.UserUID}
                  onChange={(e, v) => {
                    if (v?.uid) {
                      setFieldValue('UserUID', v.uid);
                    } else {
                      setFieldValue('UserUID', '');
                    }
                  }}
                  onOpen={handleBlur('UserUID')}
                  getOptionLabel={getOptionLabel}
                  isOptionEqualToValue={(option, value) => option.uid === value}
                  renderInput={(params) => (
                    <TextField name="UserUID" {...params} fullWidth />
                  )}
                  options={acData?.items || []}
                  noOptionsText={t('account.label.noUser')}
                  includeInputInList
                  sx={{ mb: 2 }}
                />
                <RadioGroup
                  name="ReadOnly"
                  value={values.ReadOnly}
                  onChange={(e, v) => setFieldValue('ReadOnly', v === 'true')}
                  onBlur={handleBlur}
                  row
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio size="small" />}
                    label={t('account.roleType.manager')}
                  />
                  <FormControlLabel
                    value
                    control={<Radio size="small" />}
                    label={t('account.roleType.user')}
                  />
                </RadioGroup>
                <LoadingButton
                  type="submit"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  loading={isFetching}
                  disabled={!isValid}
                  sx={{ mt: 8 }}
                >
                  {t('account.label.add')}
                </LoadingButton>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default SettingsAccountDetailPage;
