import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { bluegrey } from '@theme/colors';
import { NavIcon, NavIconName } from '@components/common';

const NavItemWrapper = styled('div')`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const CollapsibleItemWrapper = styled('div')`
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
`;

const NavItem: React.FC<ListItemButtonProps & { isOpen?: boolean }> = ({
  onClick,
  disabled,
  children,
  isOpen = false,
}) => (
  <NavItemWrapper>
    <ListItemButton
      onClick={onClick}
      disabled={disabled}
      sx={{
        p: 0,
        mr: 2,
        color: bluegrey[700],
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '> svg': {
          width: '20px',
          height: '20px',
        },
      }}
    >
      {children}
      {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
    </ListItemButton>
  </NavItemWrapper>
);
NavItem.propTypes = {
  isOpen: PropTypes.bool,
};
NavItem.defaultProps = {
  isOpen: false,
};

const NavItemIcon = styled(ListItemIcon)`
  min-width: 28px;
  > svg {
    width: 18px;
    height: 18px;
  }
`;

export const DefaultNavItem = React.memo(
  ({
    label,
    iconName,
    onNavigate,
    path,
  }: {
    label: string;
    iconName: NavIconName;
    onNavigate: (path: string) => void;
    path: string;
  }) => (
    <NavItem onClick={() => onNavigate(path)}>
      <NavItemIcon>
        <NavIcon name={iconName} />
      </NavItemIcon>
      <ListItemText
        primary={label}
        primaryTypographyProps={{ variant: 'body1' }}
      />
    </NavItem>
  ),
);

export const CollapsibleNavItem = React.memo(
  ({
    isOpen,
    label,
    iconName,
    onToggle,
    onNavigate,
    navItems,
  }: {
    isOpen: boolean;
    label: string;
    iconName: NavIconName;
    onToggle: () => void;
    onNavigate: (path: string) => void;
    navItems: Array<{ path: string; label: string }>;
  }) => (
    <>
      <NavItem onClick={onToggle} isOpen={isOpen}>
        <NavItemIcon>
          <NavIcon name={iconName} />
        </NavItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{ variant: 'body1' }}
        />
      </NavItem>
      <Collapse in={isOpen}>
        <List disablePadding>
          {navItems.map((item) => (
            <CollapsibleItemWrapper key={item.path}>
              <ListItemButton
                onClick={() => onNavigate(item.path)}
                sx={{
                  mr: 2,
                  p: 0,
                  pl: 1,
                  color: bluegrey[500],
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  '&::before': {
                    content: '"\\2022"',
                    marginRight: '8px',
                  },
                }}
              >
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItemButton>
            </CollapsibleItemWrapper>
          ))}
        </List>
      </Collapse>
    </>
  ),
);
