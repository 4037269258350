import { useQuery } from 'react-query';
import { OverviewService } from '@api/service';
import { QUERY_KEY } from '@constants';
import { useSelectedAWSAccounts } from '@hooks/api/common';

export default () => {
  const { apiQueryString } = useSelectedAWSAccounts();
  return useQuery(
    [QUERY_KEY.OVERVIEW.SUMMARY, apiQueryString],
    ({ queryKey }) => OverviewService.getSummary(queryKey[1]),
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
};
