import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  ChartDefaultGradient,
  GrmtAxisDefaultProps,
  GrmtDefaultChartProps,
  GrmtGridDefaultProps,
  GrmtTooltipTextItem,
  GrmtTooltipWrapper,
} from '@components/chart';
import { DotLegendItem, DotLegendWrapper } from '@components/styled';
import { ValueFormatter } from '@utils';

type DataTransferChartProps = GrmtDefaultChartProps<{
  Date: string | Date;
  Cost: number;
}>;

const DataTransferChart: React.FC<DataTransferChartProps> = ({ data }) => {
  const { t } = useTranslation('optimization');
  return (
    <Box>
      <DotLegendWrapper>
        <DotLegendItem color="#9594FF" label={t('label.common.cost')} />
      </DotLegendWrapper>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart data={data} margin={{ left: 24, right: 24, top: 24 }}>
          {ChartDefaultGradient}
          <defs>
            <linearGradient
              id="dataTransferGradient"
              x1="0"
              y1="0"
              x2="0"
              y2="100%"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9594FF" stopOpacity="0.56" offset="0%" />
              <stop stopColor="#9594FF" stopOpacity="0.08" offset="100%" />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="Date"
            height={72}
            interval={0}
            angle={-45}
            textAnchor="end"
            tickFormatter={(v) => moment(v).format('YYYY-MM-DD')}
            {...GrmtAxisDefaultProps}
          />
          <YAxis scale="sequential" {...GrmtAxisDefaultProps} />
          <CartesianGrid {...GrmtGridDefaultProps} />
          <Tooltip
            cursor={false}
            content={({ active, payload, label }) => (
              <GrmtTooltipWrapper
                active={active}
                payload={payload}
                label={moment(label).format('YYYY-MM-DD')}
                width={150}
              >
                <GrmtTooltipTextItem
                  name={`${t('label.common.cost')}: `}
                  value={ValueFormatter.toCurrency(
                    payload?.length ? payload[0].value : 0,
                  )}
                />
              </GrmtTooltipWrapper>
            )}
          />
          <Area
            dataKey="Cost"
            stroke="#9594FF"
            strokeWidth={2}
            fillOpacity={2}
            fill="url(#dataTransferGradient)"
            dot={{ stroke: '#9594FF', fill: '#9594FF', r: 3 }}
            activeDot={{ stroke: '#9594FF', fill: '#9594FF', r: 3 }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default React.memo(DataTransferChart);
