import React from 'react';
import { Box } from '@mui/material';
import DetailsComputeTransferSummary from './DetailsComputeTransferSummary';
import DetailsComputeTransferTable from './DetailsComputeTransferTable';

const DetailsComputeTransferPage: React.FC = () => (
  <>
    <DetailsComputeTransferSummary />
    <Box mt={10}>
      <DetailsComputeTransferTable />
    </Box>
  </>
);

export default DetailsComputeTransferPage;
