import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import {
  selectedAccountState,
  selectedAccountTemporaryState,
} from '@atoms/global';
import useCurrentAccounts from './useCurrentAccounts';

export default () => {
  const [selected, setSelected] = useRecoilState(selectedAccountState);
  const [temporary, setTemporary] = useRecoilState(
    selectedAccountTemporaryState,
  );
  const { data } = useCurrentAccounts(false);
  const handleAccountChange = useCallback(
    (v: string | string[]) => {
      if (typeof v !== 'string') {
        if (v.includes(null)) {
          if (data?.items?.length && temporary.length !== data.items.length) {
            setTemporary(data.items.map((a) => a.id));
          }
        } else {
          setTemporary(v);
        }
      }
    },
    [setTemporary, temporary, data?.items],
  );
  const apiAccounts = useMemo<string[] | undefined>(
    () =>
      data?.items?.length && data.items.length === selected.length
        ? []
        : selected,
    [selected, data?.items],
  );
  const apiQueryString = useMemo<string | undefined>(
    () =>
      data?.items?.length && data.items.length === selected.length
        ? undefined
        : JSON.stringify(selected),
    [selected, data?.items],
  );
  const openSelect = useCallback(() => {
    setTemporary(selected);
  }, [selected, setTemporary]);
  const saveChanges = useCallback(() => {
    setSelected(temporary);
    return data?.items?.length && data.items.length === temporary.length
      ? undefined
      : temporary;
  }, [data?.items, temporary, setSelected]);
  useEffect(() => {
    setTemporary([]);
  }, []);
  return {
    selectedAccounts: selected,
    temporary,
    currentAccounts: data?.items?.length ? data.items : [],
    apiAccounts,
    apiQueryString,
    handleAccountChange,
    onOpen: openSelect,
    saveChanges,
  };
};
