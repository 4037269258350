import axios from '@utils/axios';
import { API_URL } from '@constants';

const downloadOptimizationCSV = async (
  url: string,
  filter: string | undefined = undefined,
  sort: string | undefined = undefined,
  apm = 'cloudwatch',
  period = 'Days14',
): Promise<{
  blob: Blob;
  filename?: string;
}> => {
  const elem: Record<string, any> | undefined = filter ? {} : undefined;
  if (filter && elem) {
    const parsed = JSON.parse(filter);
    Object.keys(parsed).forEach((key) => {
      if (
        key === 'RICoverage' ||
        key === 'SavingsPlanCoverage' ||
        key === 'Coverage' ||
        key === 'Utilization'
      ) {
        elem[key] = { $lte: Number(parsed[key]) };
      } else {
        elem[key] = parsed[key];
      }
    });
  }
  const newFilter = filter ? JSON.stringify(elem) : undefined;
  const { data, headers } = await axios.get(
    `${API_URL.OPTIMIZATION}/${url}/csv/`,
    {
      params: {
        filter: newFilter,
        sort,
        apm,
        period,
      },
      responseType: 'blob',
    },
  );
  let filename: string | undefined;
  const disposition = headers['content-disposition'];
  if (disposition && disposition.indexOf('attachment !== -1')) {
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return {
    blob: data,
    filename,
  };
};

export default {
  downloadOptimizationCSV,
};
