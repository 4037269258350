import React, { useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  discountProgramState,
  riSimulationState,
  simulateRequestState,
  simulateResponseState,
  spSimulationState,
} from '@pages/private/planner/simulation/Simulation.atoms';
import { bluegrey } from '@theme/colors';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ValueFormatter } from '@utils';
import { useParams } from 'react-router-dom';
import { useSimulation, useSimulationAutoComplete } from '@hooks/api/planner';
import { useTranslation } from 'react-i18next';
import SimulatedResult from '@pages/private/planner/simulation/components/SimulatedResult';
import * as Yup from 'yup';
import { useFormik } from 'formik';

type CalculatorForm = {
  InstanceType: string;
  NumberOfRI: string;
  Commitment: string;
};
const useCalculatorForm = (): UseValidation<CalculatorForm> => {
  const { t } = useTranslation('validation');
  const { t: plannerT } = useTranslation('planner');
  return {
    initialValues: {
      InstanceType: '',
      NumberOfRI: '1',
      Commitment: '0.001',
    },
    validationSchema: Yup.object({
      InstanceType: Yup.string().defined(),
      NumberOfRI: Yup.string()
        .matches(/^[0-9]*$/, t('planner.numberOfRI.essence'))
        .matches(/^[1-9]+([0-9]+)?$/, t('planner.numberOfRI.positive'))
        .defined(
          t('custom.required', {
            context: 'post2',
            name: plannerT('label.numberOfRI'),
          }),
        ),
      Commitment: Yup.string()
        .matches(/^[0-9.]*$/, t('planner.commitment.number'))
        .matches(/^(\d*)[.]?(\d{1,3})?$/, t('planner.commitment.digit'))
        .defined(
          t('custom.required', {
            context: 'post1',
            name: plannerT('aws.commitment'),
          }),
        )
        .test('matches', t('planner.commitment.positive'), (value) => {
          if (value) {
            return Number(value) > 0;
          }
          return false;
        }),
    }).defined(),
  };
};

const SimulationCalculator: React.FC<{
  InstanceFamily: string;
  recommendation?: RIRecommend | SPRecommend;
}> = ({ InstanceFamily, recommendation }) => {
  const { instanceId } = useParams();
  const { t } = useTranslation('planner');
  const discountProgram = useRecoilValue(discountProgramState);
  const selectedRI = useRecoilValue(riSimulationState);
  const selectedSP = useRecoilValue(spSimulationState);
  const setRequest = useSetRecoilState(simulateRequestState);
  const setResponse = useSetRecoilState(simulateResponseState);
  const { initialValues, validationSchema } = useCalculatorForm();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    validateField,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
  });
  const { isLoading, data, mutateAsync } = useSimulation();
  const fetchRIGuide = useCallback(async () => {
    try {
      const req: SimulationRequest = {
        InstanceId: instanceId,
        DiscountProgram: {
          DiscountProgram: 'reserved',
          Type: selectedRI.OfferingClass,
          NumberValue: Number(values.NumberOfRI),
          PaymentOption: selectedRI.PaymentOption,
          Duration: selectedRI.Duration,
          SimulateInstanceType: values.InstanceType,
        },
      };
      const res = await mutateAsync(req);
      setRequest(req);
      if (res?.items?.length) {
        setResponse(res.items[0]);
      }
    } catch (e) {
      console.error(e);
    }
  }, [instanceId, selectedRI, values.InstanceType, values.NumberOfRI]);
  const fetchSPGuide = useCallback(async () => {
    try {
      const req: SimulationRequest = {
        InstanceId: instanceId,
        DiscountProgram: {
          DiscountProgram: 'savingsplan',
          Type: selectedSP.PlanType,
          NumberValue: Number(values.Commitment),
          PaymentOption: selectedSP.PaymentOption,
          Duration: selectedSP.Duration,
        },
      };
      const res = await mutateAsync(req);
      setRequest(req);
      if (res?.items?.length) {
        setResponse(res.items[0]);
      }
    } catch (e) {
      console.error(e);
    }
  }, [instanceId, selectedSP, values.Commitment]);
  const { data: acData } = useSimulationAutoComplete(InstanceFamily);
  if (!acData?.items?.length) {
    return null;
  }
  const AvailableTypes = acData.items;
  useEffect(() => {
    if (AvailableTypes.length) {
      setFieldValue('InstanceType', AvailableTypes[0]);
    }
  }, [AvailableTypes.length]);
  useEffect(() => {
    const setDefaultValue = async () => {
      if (recommendation) {
        if (recommendation.Type === 'ReservedInstance') {
          await setFieldValue(
            'InstanceType',
            recommendation.PurchaseOption.InstanceType,
          );
          await setFieldValue(
            'NumberOfRI',
            ValueFormatter.toFixed(recommendation.NumberOfRI, 0),
          );
          await validateField('InstanceType');
          await validateField('NumberOfRI');
        } else {
          await setFieldValue(
            'Commitment',
            ValueFormatter.toFixed(recommendation.Commitment, 3),
          );
          await validateField('Commitment');
        }
      }
    };
    setDefaultValue();
  }, []);
  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        bgcolor={bluegrey[50]}
        borderRadius="4px"
        mt={4}
        p={2}
      >
        <>
          {discountProgram === 'reserved' && (
            <Grid item md={6} xs={12}>
              <Box
                display="flex"
                alignItems="center"
                mb={touched.NumberOfRI && errors.NumberOfRI ? 3 : 0}
              >
                <Stack direction="row" flexGrow={1} spacing={2} mr={2}>
                  <TextField
                    name="InstanceType"
                    value={values.InstanceType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.InstanceType && errors.InstanceType)}
                    helperText={
                      Boolean(touched.InstanceType && errors.InstanceType) &&
                      String(errors.InstanceType)
                    }
                    fullWidth
                    select
                  >
                    {AvailableTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    name="NumberOfRI"
                    value={values.NumberOfRI}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.NumberOfRI && errors.NumberOfRI)}
                    helperText={
                      Boolean(touched.NumberOfRI && errors.NumberOfRI) &&
                      String(errors.NumberOfRI)
                    }
                    fullWidth
                  />
                </Stack>
                <Button
                  size="large"
                  onClick={fetchRIGuide}
                  disabled={
                    Boolean(errors.InstanceType) || Boolean(errors.NumberOfRI)
                  }
                >
                  {t('button.calculate')}
                </Button>
              </Box>
              <Typography variant="body2" color="textSecondary" mt={1}>
                {t('label.ourRecommendation')}:{' '}
                {t('label.riRecommend', {
                  count: selectedRI.NumberOfRI,
                  type: selectedRI.InstanceType,
                })}
              </Typography>
            </Grid>
          )}
          {discountProgram === 'savingsplan' && (
            <Grid item md={6} xs={12}>
              <Typography variant="body2" mb={2}>
                {t('label.purchaseCommitment')}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                spacing={3}
                mb={touched.Commitment && errors.Commitment ? 3 : 0}
              >
                <TextField
                  name="Commitment"
                  value={values.Commitment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.Commitment && errors.Commitment)}
                  helperText={
                    Boolean(touched.Commitment && errors.Commitment) &&
                    String(errors.Commitment)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {t('label.perHourAdornment')}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    flexGrow: 1,
                  }}
                />
                <Button
                  size="large"
                  onClick={fetchSPGuide}
                  disabled={Boolean(errors.Commitment)}
                >
                  {t('button.calculate')}
                </Button>
              </Stack>
              <Typography variant="body2" color="textSecondary" mt={1}>
                {t('label.recommendCommitment')}:{' '}
                {ValueFormatter.toCurrency(selectedSP.Commitment, undefined, 3)}
              </Typography>
            </Grid>
          )}
        </>
      </Grid>
      <SimulatedResult isLoading={isLoading} data={data} />
    </>
  );
};
SimulationCalculator.defaultProps = {
  recommendation: undefined,
};

export default SimulationCalculator;
