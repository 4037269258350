import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { usePaymentHistory } from '@hooks/api/settings/payment';
import { GrmtTableColumnInfo, SimplePaginationTable } from '@components/table';
import { DetailButtonIcon } from '@components/table/table.styled';
import { ValueFormatter } from '@utils';

const PaymentHistory: React.FC = () => {
  const { t } = useTranslation('settings');
  const { data } = usePaymentHistory();

  const columns = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('payment.label.date'),
        accessor: 'PaymentDate' as const,
        Cell: ({ value }) => moment(value).format('YYYY/MM/DD'),
      },
      {
        Header: t('payment.label.amount'),
        align: 'right',
        accessor: 'Amount' as const,
        Cell: ({ value }) => ValueFormatter.toCurrency(value),
      },
      {
        Header: t('payment.label.payment', { context: 'method' }),
        accessor: 'CardBrand' as const,
        Cell: ({ row }) =>
          t('payment.label.card', {
            context: 'format',
            card: row.original.CardBrand.toUpperCase(),
            last: row.original.CardLast4Digits,
          }),
      },
      {
        Header: t('payment.label.status'),
        accessor: 'Status' as const,
        Cell: ({ value }) => {
          switch (value) {
            case 'paid':
            case 'unpaid':
              return t('payment.label.status', { context: value });
            default:
              return value;
          }
        },
      },
      {
        Header: t('payment.label.invoice'),
        accessor: 'ReceiptUrl' as const,
        align: 'center',
        width: 50,
        Cell: ({ value }) => (
          <DetailButtonIcon onClick={() => window.open(value, '_blank')} />
        ),
      },
    ],
    [],
  );

  return (
    <SimplePaginationTable
      columnInfo={columns}
      data={data?.items}
      pageSize={5}
    />
  );
};

export default PaymentHistory;
