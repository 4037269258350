import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEC2Coverage } from '@hooks/api/optimization/ec2';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { DetailLinkIcon } from '@components/table/table.styled';
import { ROUTES } from '@routes';
import { ValueFormatter } from '@utils';
import { useAWSHelper } from '@hooks/helper';
import ComputeCoverageFilter from './ComputeCoverageFilter';

const ComputeCoverageTable: React.FC = () => {
  const { data, onPageChange, pageSize } = useEC2Coverage();
  const { t } = useTranslation('optimization');
  const { getOptimizationAWSRegion } = useAWSHelper();

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        minWidth: 140,
        maxWidth: 140,
        disableSortBy: true,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.common.instanceId'),
        accessor: 'InstanceId' as const,
        minWidth: 180,
        maxWidth: 180,
        disableSortBy: true,
      },
      {
        Header: t('label.common.tag'),
        accessor: 'Tag' as const,
        minWidth: 290,
        maxWidth: 290,
        disableSortBy: true,
        Cell: ({ value }) => (
          <>
            {value?.length
              ? Object.keys(value[0]).map((v) => {
                  const key = v.split(':');
                  return (
                    <React.Fragment key={v}>
                      {`${key[key.length - 1]}: ${value[0][v]}`}
                    </React.Fragment>
                  );
                })
              : '-'}
          </>
        ),
      },
      {
        Header: t('label.common.od'),
        accessor: 'OnDemandHours' as const,
        minWidth: 160,
        maxWidth: 160,
        Cell: ({ value, row }) => (
          <>
            {t('label.common.h', {
              value: ValueFormatter.toLocaleString(value),
            })}
            <br />
            {`${t('label.common.cost')}: ${ValueFormatter.toCurrency(
              row.original.OnDemandCost,
            )}`}
          </>
        ),
      },
      {
        Header: t('label.common.ri', { context: 'short' }),
        accessor: 'RICoverage' as const,
        minWidth: 145,
        maxWidth: 145,
        Cell: ({ value, row }) => (
          <>
            {t('label.common.h', {
              value: ValueFormatter.toLocaleString(row.original.ReservedHours),
            })}
            <br />
            {`${t('label.header.coverage')}: ${ValueFormatter.toPercent(
              value,
            )}`}
          </>
        ),
      },
      {
        Header: t('label.common.sp', { context: 'short' }),
        accessor: 'SavingsPlanCoverage' as const,
        minWidth: 145,
        maxWidth: 145,
        Cell: ({ value, row }) => (
          <>
            {t('label.common.h', {
              value: ValueFormatter.toLocaleString(
                row.original.SavingsPlanHours,
              ),
            })}
            <br />
            {`${t('label.header.coverage')}: ${ValueFormatter.toPercent(
              value,
            )}`}
          </>
        ),
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailLinkIcon
            to={`${ROUTES.DETAILS.COMPUTE}/${row.original.InstanceId}?tab=purchasing`}
            requireCUR
          />
        ),
      },
    ],
    [getOptimizationAWSRegion],
  );
  return (
    <DataPaginationTable
      data={data?.body?.items}
      columnInfo={columnInfo}
      totalCount={data?.body?.totalCount}
      pageSize={pageSize}
      onPageChange={onPageChange}
      FilterElement={ComputeCoverageFilter}
      CSVUrl="ec2/dc_coverage"
      enableFilter
      enableSort
      LastUpdate={data.body.extras.LatestUpdate}
    />
  );
};

export default ComputeCoverageTable;
