import React, { useMemo } from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Text,
  XAxis,
  YAxis,
} from 'recharts';
import _ from 'lodash';
import { bluegrey } from '@theme/colors';
import { ValueFormatter } from '@utils';
import { GrmtDefaultChartProps } from '@components/chart';

type InfoData = {
  label: string;
  accessor: string;
  formatter?: (v: any) => string;
};

type CostSummaryChartInfo = {
  header: InfoData;
  data: InfoData;
  extra?: InfoData;
};

type CostSummaryChartProps = GrmtDefaultChartProps & {
  chartInfo: CostSummaryChartInfo;
};

const COLORS = [
  '#9594FF',
  '#57CAEB',
  '#5BD9B3',
  '#FF7976',
  '#C0DFF9',
  '#D8C0F7',
  '#F0B8DC',
  '#C7C9E7',
  '#B7E0A9',
  '#FFEEB2',
] as const;

const CostSummaryChart: React.FC<CostSummaryChartProps> = ({
  chartInfo,
  data,
}) => {
  const chartHeight = useMemo<number>(
    () => data.length * 14 + (data.length - 1) * 24 + 66,
    [data?.length],
  );
  return (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <BarChart
        data={data}
        layout="vertical"
        barSize={14}
        maxBarSize={14}
        barCategoryGap={24}
      >
        <XAxis type="number" padding={{ left: 24 }} hide />
        <YAxis
          yAxisId="left"
          type="category"
          orientation="left"
          width={256}
          dataKey={chartInfo.header.accessor}
          axisLine={false}
          tickLine={false}
          padding={{ top: 32 }}
          label={() => (
            <Text
              x={0}
              y={7}
              fontSize={14}
              textAnchor="start"
              verticalAnchor="middle"
            >
              {chartInfo.header.label}
            </Text>
          )}
          tick={({ y, index, payload: { value } }) => (
            <>
              <ellipse
                cx={4}
                cy={y}
                rx={4}
                ry={4}
                fill={COLORS[index % COLORS.length]}
              />
              <Text
                x={24}
                y={y}
                fontSize={14}
                textAnchor="start"
                verticalAnchor="middle"
              >
                {value}
              </Text>
            </>
          )}
        />
        <YAxis
          yAxisId="right"
          type="category"
          orientation="right"
          width={chartInfo.extra ? 256 : 200}
          axisLine={false}
          tickLine={false}
          padding={{ top: 32 }}
          label={({ viewBox: { x, y, width } }: any) => {
            if (chartInfo.extra) {
              return (
                <>
                  <Text
                    x={x + 136}
                    y={y + 7}
                    fontSize={14}
                    textAnchor="end"
                    verticalAnchor="middle"
                  >
                    {chartInfo.data.label}
                  </Text>
                  <Text
                    x={x + width}
                    y={y + 7}
                    fontSize={14}
                    textAnchor="end"
                    verticalAnchor="middle"
                  >
                    {chartInfo.extra.label}
                  </Text>
                </>
              );
            }
            return (
              <Text
                x={x + width}
                y={y + 7}
                fontSize={14}
                textAnchor="end"
                verticalAnchor="middle"
              >
                {chartInfo.data.label}
              </Text>
            );
          }}
          tick={({ x, y, width, payload: { index } }) => {
            if (data.length > index) {
              if (chartInfo.extra) {
                return (
                  <>
                    <Text
                      x={x + 128}
                      y={y}
                      fontSize={14}
                      textAnchor="end"
                      verticalAnchor="middle"
                    >
                      {chartInfo.data.formatter
                        ? chartInfo.data.formatter(
                            _.get(data[index], chartInfo.data.accessor),
                          )
                        : ValueFormatter.toCurrency(
                            _.get(data[index], chartInfo.data.accessor),
                          )}
                    </Text>
                    <Text
                      x={x + width - 8}
                      y={y}
                      fontSize={14}
                      textAnchor="end"
                      verticalAnchor="middle"
                    >
                      {chartInfo.extra.formatter
                        ? chartInfo.extra.formatter(
                            _.get(data[index], chartInfo.extra.accessor),
                          )
                        : ValueFormatter.toLocaleString(
                            _.get(data[index], chartInfo.extra.accessor),
                            0,
                          )}
                    </Text>
                  </>
                );
              }
              return (
                <Text
                  x={x + width - 8}
                  y={y}
                  fontSize={14}
                  textAnchor="end"
                  verticalAnchor="middle"
                >
                  {chartInfo.data.formatter
                    ? chartInfo.data.formatter(
                        _.get(data[index], chartInfo.data.accessor),
                      )
                    : ValueFormatter.toCurrency(
                        _.get(data[index], chartInfo.data.accessor),
                      )}
                </Text>
              );
            }
            return null;
          }}
        />
        <Bar
          yAxisId="left"
          dataKey={chartInfo.data.accessor}
          isAnimationActive={false}
          shape={({ x, y, width, height, index, value }) => (
            <>
              <rect
                x={x}
                y={y}
                rx={7}
                width={width + 32}
                height={height}
                fill={value ? COLORS[index % COLORS.length] : bluegrey[300]}
              />
            </>
          )}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CostSummaryChart;
