import React, { useCallback, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box } from '@mui/material';
import {
  ChartDefaultGradient,
  GrmtAxisDefaultProps,
  GrmtChartTooltipProps,
  GrmtChartXAxisProps,
  GrmtDefaultChartProps,
  GrmtGridDefaultProps,
} from '@components/chart';
import { DotLegendItem, DotLegendWrapper } from '@components/styled';
import { ValueFormatter } from '@utils';
import { useTranslation } from 'react-i18next';

type UtilizationChartInfo = {
  dataKey: string;
  percentDataKey: string;
  name?: string;
  percentName?: string;
};

type UtilizationChartProps = GrmtDefaultChartProps &
  GrmtChartXAxisProps &
  GrmtChartTooltipProps & {
    chartInfo:
      | [UtilizationChartInfo]
      | [UtilizationChartInfo, UtilizationChartInfo];
  };

const UtilizationChart: React.FC<UtilizationChartProps> = ({
  data,
  xAxis,
  chartInfo,
  tooltipContent,
}) => {
  const { t } = useTranslation('optimization');
  const [focused, setFocused] = useState<number | null>(null);
  const [mouseLeave, setMouseLeave] = useState(true);
  const handleMouseMove = useCallback((state: any) => {
    if (state.isTooltipActive) {
      setFocused(state.activeTooltipIndex);
      setMouseLeave(false);
    } else {
      setMouseLeave(true);
      setFocused(null);
    }
  }, []);
  return (
    <Box>
      <DotLegendWrapper>
        <DotLegendItem color="#57CAEB66" label={chartInfo[0].name} />
        <DotLegendItem color="#57CAEB" label={chartInfo[0].percentName} />
        {chartInfo.length === 2 && (
          <>
            <DotLegendItem color="#9594FF66" label={chartInfo[1].name} />
            <DotLegendItem color="#9594FF" label={chartInfo[1].percentName} />
          </>
        )}
      </DotLegendWrapper>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          barSize={14}
          onMouseMove={handleMouseMove}
          margin={{ left: 36, bottom: 8 }}
        >
          {ChartDefaultGradient}
          <XAxis {...xAxis} {...GrmtAxisDefaultProps} />
          <YAxis
            scale="linear"
            {...GrmtAxisDefaultProps}
            tickFormatter={(value) => ValueFormatter.toLocaleString(value, 0)}
            label={{
              value: t('label.chart.common.hour'),
              position: 'insideLeft',
              angle: -90,
              offset: -24,
              stroke: 'none',
              fill: '#A0A1B2',
              fontSize: '12px',
              fontWeight: 400,
            }}
          />
          <CartesianGrid {...GrmtGridDefaultProps} />
          {tooltipContent && (
            <Tooltip cursor={false} content={tooltipContent} />
          )}
          {chartInfo.length >= 1 && (
            <Bar
              dataKey={chartInfo[0].dataKey}
              shape={({ width, height, fill, x, y, payload }) => {
                const percentHeight =
                  !Number.isNaN(Number(height)) &&
                  !Number.isNaN(Number(payload[chartInfo[0].percentDataKey]))
                    ? (height * payload[chartInfo[0].percentDataKey]) / 100.0
                    : 0.0;
                return (
                  <>
                    <rect
                      width={width}
                      height={percentHeight}
                      x={x}
                      y={y + height - percentHeight}
                      rx={6}
                      fill={fill}
                    />
                    <rect
                      width={width}
                      height={height}
                      x={x}
                      y={y}
                      rx={6}
                      fill={fill}
                      fillOpacity={0.3}
                    />
                  </>
                );
              }}
            >
              {data.map((entry, idx) => (
                <Cell
                  key={`cell_ut_1_${idx.toString()}`}
                  fill={focused === idx || mouseLeave ? '#57CAEB' : '#57CAEB66'}
                />
              ))}
            </Bar>
          )}
          {chartInfo.length === 2 && (
            <Bar
              dataKey={chartInfo[1].dataKey}
              shape={({ width, height, fill, x, y, payload }) => {
                const percentHeight =
                  !Number.isNaN(Number(height)) &&
                  !Number.isNaN(Number(payload[chartInfo[1].percentDataKey]))
                    ? (height * payload[chartInfo[1].percentDataKey]) / 100.0
                    : 0.0;
                return (
                  <>
                    <rect
                      width={width}
                      height={percentHeight}
                      x={x}
                      y={y + height - percentHeight}
                      rx={6}
                      fill={fill}
                    />
                    <rect
                      width={width}
                      height={height}
                      x={x}
                      y={y}
                      rx={6}
                      fill={fill}
                      fillOpacity={0.3}
                    />
                  </>
                );
              }}
            >
              {data.map((entry, idx) => (
                <Cell
                  key={`cell_ut_2_${idx.toString()}`}
                  fill={focused === idx || mouseLeave ? '#9594FF' : '#9594FF66'}
                />
              ))}
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default React.memo(UtilizationChart);
