import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { bluegrey } from '@theme/colors';
import { DotLegendItem } from '@components/styled';
import {
  ChartDefaultGradient,
  GrmtAxisDefaultProps,
  GrmtGridDefaultProps,
} from '@components/chart';
import { ValueFormatter } from '@utils';

type LinearChartData = {
  before: number;
  after: number;
};

type LinearChartProps = {
  data: [
    LinearChartData & { type: 'OD' },
    LinearChartData & { type: 'RI' },
    LinearChartData & { type: 'SP' },
  ];
};

const COLORS = ['#FF7976', '#57CAEB', '#9594FF'] as const;

const PlannerLinearChart: React.FC<LinearChartProps> = ({ data }) => {
  const { t } = useTranslation('optimization');
  return (
    <Box>
      <ResponsiveContainer width="100%" height={360}>
        <BarChart data={data} layout="vertical" barSize={24} barGap={8}>
          {ChartDefaultGradient}
          <XAxis
            type="number"
            height={2}
            {...GrmtAxisDefaultProps}
            padding={{ left: 24, right: 96 }}
          />
          <YAxis
            type="category"
            dataKey="type"
            {...GrmtAxisDefaultProps}
            padding={{ top: 38, bottom: 38 }}
            hide
          />
          <CartesianGrid
            {...GrmtGridDefaultProps}
            horizontal={false}
            vertical
          />
          <Bar
            dataKey="before"
            isAnimationActive={false}
            shape={({ width, height, fill, x, y, payload }) => {
              if (payload.type === 'OD') {
                const toShow = width - 8 >= 36;
                return (
                  <>
                    <rect
                      width={width}
                      height={height}
                      x={x}
                      y={y}
                      rx={4}
                      fill={fill}
                      fillOpacity={0.4}
                    />
                    {toShow && (
                      <text
                        dx={x + 8}
                        dy={y + 16}
                        stroke="none"
                        fill={bluegrey[600]}
                        fontSize="12px"
                      >
                        Before
                      </text>
                    )}
                    <text
                      dx={x + width + 16}
                      dy={y + 16}
                      stroke="none"
                      fill={bluegrey[400]}
                      fontSize="12px"
                    >
                      {!toShow && 'Before: '}
                      {ValueFormatter.toCurrency(payload.before)}
                    </text>
                  </>
                );
              }
              return (
                <>
                  <rect
                    width={width}
                    height={height}
                    x={x}
                    y={y}
                    rx={4}
                    fill={fill}
                    fillOpacity={0.4}
                  />
                  <text
                    dx={x + width + 16}
                    dy={y + 16}
                    stroke="none"
                    fill={bluegrey[400]}
                    fontSize="12px"
                  >
                    {ValueFormatter.toCurrency(payload.before)}
                  </text>
                </>
              );
            }}
          >
            {data.map((entry, idx) => (
              <Cell key={`comp_bf_${idx.toString()}`} fill={COLORS[idx]} />
            ))}
          </Bar>
          <Bar
            dataKey="after"
            isAnimationActive={false}
            shape={({ width, height, fill, x, y, payload }) => {
              if (payload.type === 'OD') {
                const toShow = width - 8 >= 27;
                return (
                  <>
                    <rect
                      width={width}
                      height={height}
                      x={x}
                      y={y}
                      rx={4}
                      fill={fill}
                    />
                    {toShow && (
                      <text
                        dx={x + 8}
                        dy={y + 16}
                        stroke="none"
                        fill="#fff"
                        fontSize="12px"
                      >
                        After
                      </text>
                    )}
                    <text
                      dx={x + width + 16}
                      dy={y + 17}
                      stroke="none"
                      fill={bluegrey[900]}
                      fontSize="16px"
                      fontWeight={500}
                    >
                      {!toShow && 'After: '}
                      {ValueFormatter.toCurrency(payload.after)}
                    </text>
                  </>
                );
              }
              return (
                <>
                  <rect
                    width={width}
                    height={height}
                    x={x}
                    y={y}
                    rx={4}
                    fill={fill}
                  />
                  <text
                    dx={x + width + 16}
                    dy={y + 17}
                    stroke="none"
                    fill={bluegrey[900]}
                    fontSize="16px"
                    fontWeight={500}
                  >
                    {ValueFormatter.toCurrency(payload.after)}
                  </text>
                </>
              );
            }}
          >
            {data.map((entry, idx) => (
              <Cell key={`comp_af_${idx.toString()}`} fill={COLORS[idx]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <Box
        display="flex"
        mt={1}
        pl={1}
        sx={{
          '> .cc-legend-dot-item:not(:first-of-type)': {
            ml: 2,
          },
        }}
      >
        <DotLegendItem
          color={COLORS[0]}
          textColor={bluegrey[400]}
          label={t('label.common.od')}
        />
        <DotLegendItem
          color={COLORS[1]}
          textColor={bluegrey[400]}
          label={t('label.common.ri')}
        />
        <DotLegendItem
          color={COLORS[2]}
          textColor={bluegrey[400]}
          label={t('label.common.sp')}
        />
      </Box>
    </Box>
  );
};

export default PlannerLinearChart;
