import React, { useMemo } from 'react';
import { Chip, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SimpleTypoTable } from '@components/styled';
import { useAWSHelper } from '@hooks/helper';
import { useEBSDetailsGeneral } from '@hooks/api/optimization/ebs';
import { ValueFormatter } from '@utils';
import _ from 'lodash';

const DetailsVolumeGeneral: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');
  const { getAWSRegionName } = useAWSHelper();
  const { data } = useEBSDetailsGeneral();
  const generalInfo = useMemo(() => {
    if (data?.items?.length && !_.isEmpty(data.items[0])) {
      return [
        {
          label: t('label.common.accountId'),
          value: _.get(data, 'items.0.AccountId') || '-',
        },
        {
          label: t('label.common.region'),
          value: getAWSRegionName(_.get(data, 'items.0.Region') || '-'),
        },
        {
          label: t('label.volume.type_long'),
          value: _.get(data, 'items.0.VolumeType') || '-',
        },
        {
          label: t('label.volume.id'),
          value: _.get(data, 'items.0.VolumeId') || '-',
        },

        {
          label: t('label.volume.state'),
          value: _.get(data, 'items.0.State') || '-',
        },
        {
          label: `${t('label.common.size')} (GiB)`,
          value: _.get(data, 'items.0.Size') || '-',
        },
        {
          label: t('label.volume.iops_provisioned'),
          value: _.get(data, 'items.0.PorvisionedIOPS') || '-',
        },
        {
          label: `${t('label.common.cost')} (${commonT('label.days', {
            count: 30,
          })})`,
          value: ValueFormatter.toCurrency(_.get(data, 'items.0.Cost')) || 0,
        },
        {
          label: `${t('label.common.tags')} (${data.items[0].Tag.length})`,
          value: (
            <>
              {!_.isEmpty(data.items[0].Tag) ? (
                <Grid container spacing={1}>
                  {data.items[0].Tag.map((item) =>
                    Object.keys(item).map((tag, idx) => {
                      const key = tag.split(':');
                      return (
                        <Grid
                          key={`${tag}_${item[tag]}_${idx.toString()}`}
                          item
                        >
                          <Chip
                            label={`${key[key.length - 1]}: ${item[tag]}`}
                          />
                        </Grid>
                      );
                    }),
                  )}
                </Grid>
              ) : (
                data.items[0].Tag?.length === 0 && <Chip label="No tag" />
              )}
            </>
          ),
        },
      ];
    }
    return [];
  }, [data?.items, t, getAWSRegionName]);
  if (!data?.items?.length) {
    return null;
  }
  return <SimpleTypoTable data={generalInfo} disableOutline />;
};

export default DetailsVolumeGeneral;
