import { useMutation, useQuery } from 'react-query';
import { PlannerService } from '@api/service';
import { QUERY_KEY } from '@constants';

export const useSimulationGeneral = (instanceId: string, suspense = true) =>
  useQuery(
    [QUERY_KEY.PLANNER.SIMULATION_GENERAL, instanceId],
    ({ queryKey }) => PlannerService.getSimulationGeneral(queryKey[1]),
    { suspense, useErrorBoundary: true },
  );

export const useSimulationRecommendation = (instanceId: string) =>
  useQuery(
    [QUERY_KEY.PLANNER.SIMULATION_RECOMMEND, instanceId],
    ({ queryKey }) => PlannerService.getSimulationRecommendation(queryKey[1]),
    { suspense: true, useErrorBoundary: true },
  );

export const useSimulationAutoComplete = (instanceFamily: string) =>
  useQuery(
    [QUERY_KEY.PLANNER.SIMULATION_AUTOCOMPLETE, instanceFamily],
    ({ queryKey }) => PlannerService.getSimulationAutoComplete(queryKey[1]),
    { suspense: true, useErrorBoundary: true },
  );

export const useSimulation = () =>
  useMutation({
    mutationKey: QUERY_KEY.PLANNER.SIMULATION,
    mutationFn: (variables: {
      InstanceId: string;
      DiscountProgram: RIDiscountProgram | SPDiscountProgram;
    }) =>
      PlannerService.simulatePlanner(
        variables.InstanceId,
        variables.DiscountProgram,
      ),
  });

export const usePurchaseRI = () =>
  useMutation({
    mutationKey: QUERY_KEY.PLANNER.PURCHASE_RI,
    mutationFn: (variables: PurchaseRIRequest) =>
      PlannerService.purchaseReserved(variables),
  });

export const usePurchaseSP = () =>
  useMutation({
    mutationKey: QUERY_KEY.PLANNER.PURCHASE_SP,
    mutationFn: (variables: PurchaseSPRequest) =>
      PlannerService.purchaseSavingsPlan(variables),
  });
