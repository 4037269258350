import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import { GuideBox } from '@components/common';
import { PageNotFound } from '@components/ui/common';

const PageNotFoundPage: React.FC = () => (
  <>
    <Helmet>
      <title>Not Found</title>
    </Helmet>
    <Container maxWidth="lg">
      <Box mt={20} mb={20}>
        <PageNotFound />
      </Box>
      <GuideBox />
    </Container>
  </>
);

export default PageNotFoundPage;
