import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  experimental_sx as sx,
  styled,
  Tab,
  tabClasses,
  TabProps,
  Tabs,
  tabsClasses,
  TabsProps,
} from '@mui/material';
import {
  TabsListUnstyled,
  TabsUnstyled,
  TabUnstyled,
  tabUnstyledClasses,
} from '@mui/base';
import { TabContext, TabList } from '@mui/lab';
import { Link, useLocation } from 'react-router-dom';
import { bluegrey, primary } from '@theme/colors';
import { RecoilState, useRecoilState } from 'recoil';

export const RoundTabs = styled(TabsUnstyled)``;
export const RoundTabsList = styled(TabsListUnstyled)(
  sx({
    mb: 4,
    [`> .${tabUnstyledClasses.root}:not(:first-of-type)`]: {
      ml: 1,
    },
  }),
);
export const RoundTab = styled(TabUnstyled)(
  sx({
    p: 1,
    color: 'text.secondary',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    background: bluegrey[200],
    border: 'none',
    borderRadius: '24px',
    cursor: 'pointer',
    [`&.${tabUnstyledClasses.selected}`]: {
      color: 'text.primary',
      background: primary[100],
    },
  }),
);

export const CCTabs = styled((props: TabsProps) => <Tabs {...props} />)({
  // [`.${tabsClasses.root}`]: {
  minHeight: '36px',
  [`.${tabsClasses.scroller} > .${tabsClasses.indicator}`]: {
    height: '4px',
    borderRadius: '64px',
  },
  [`.${tabsClasses.scroller} > .${tabsClasses.flexContainer}`]: {
    [`> .${tabClasses.root}:not(:first-of-type)`]: {
      marginLeft: '40px',
    },
  },
  // },
});

export const CCTab = styled((props: TabProps) => <Tab {...props} />)(
  ({ theme }) => ({
    [`.${tabClasses.root}`]: {
      padding: 0,
      paddingBottom: '12px',
      fontSize: theme.typography.subtitle2.fontSize,
      lineHeight: theme.typography.subtitle2.lineHeight,
      fontWeight: theme.typography.subtitle2.fontWeight,
      minWidth: 0,
      minHeight: '36px',
      color: bluegrey[400],
    },
  }),
);

export const HeaderLinkTabs = React.memo(
  ({ paths }: { paths: Array<{ label: string; to: string }> }) => {
    const { pathname } = useLocation();
    const active = useMemo<number>(() => {
      for (let i = 0; i < paths.length; i += 1) {
        if (pathname.includes(paths[i].to)) {
          return i;
        }
      }
      return 0;
    }, [pathname, paths]);
    return (
      <Tabs
        value={active}
        sx={{
          mt: 3,
          mb: 6,
          minHeight: '36px',
          [`.${tabsClasses.scroller} > .${tabsClasses.indicator}`]: {
            height: '4px',
            borderRadius: '64px',
          },
          [`.${tabsClasses.scroller} > .${tabsClasses.flexContainer}`]: {
            [`> .${tabClasses.root}:not(:first-of-type)`]: {
              marginLeft: '40px',
            },
          },
        }}
      >
        {paths.map((path) => (
          <Tab
            key={path.label}
            component={Link}
            {...path}
            sx={{
              padding: 0,
              paddingBottom: '12px',
              fontSize: '1rem',
              lineHeight: '1.5rem',
              fontWeight: 500,
              minWidth: 0,
              minHeight: '36px',
              color: bluegrey[400],
            }}
          />
        ))}
      </Tabs>
    );
  },
);

export const HeaderTabContext: React.FC<{
  items: Array<{ label: string; value: string }>;
  defaultValue: string;
}> = ({ items, defaultValue, children }) => {
  const [active, setActive] = useState(defaultValue);
  const handleChange = useCallback((e: React.SyntheticEvent, value: string) => {
    setActive(value);
  }, []);
  return (
    <Box>
      <TabContext value={active.toString()}>
        <TabList
          onChange={handleChange}
          sx={{
            mt: 3,
            mb: 6,
            minHeight: '36px',
            [`.${tabsClasses.scroller} > .${tabsClasses.indicator}`]: {
              height: '4px',
              borderRadius: '64px',
            },
            [`.${tabsClasses.scroller} > .${tabsClasses.flexContainer}`]: {
              [`> .${tabClasses.root}:not(:first-of-type)`]: {
                marginLeft: '40px',
              },
            },
          }}
        >
          {items.map((item) => (
            <Tab
              key={item.label}
              label={item.label}
              value={item.value}
              sx={{
                padding: 0,
                paddingBottom: '12px',
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontWeight: 500,
                minWidth: 0,
                minHeight: '36px',
                color: bluegrey[400],
              }}
            />
          ))}
        </TabList>
        {children}
      </TabContext>
    </Box>
  );
};

export const HeaderTabContextWithRecoil: React.FC<{
  items: Array<{ label: string; value: string }>;
  state: RecoilState<any>;
}> = ({ items, state, children }) => {
  const [active, setActive] = useRecoilState(state);
  const handleChange = useCallback((e: React.SyntheticEvent, value: string) => {
    setActive(value);
  }, []);
  return (
    <Box>
      <TabContext value={active.toString()}>
        <TabList
          onChange={handleChange}
          sx={{
            mt: 3,
            mb: 6,
            minHeight: '36px',
            [`.${tabsClasses.scroller} > .${tabsClasses.indicator}`]: {
              height: '4px',
              borderRadius: '64px',
            },
            [`.${tabsClasses.scroller} > .${tabsClasses.flexContainer}`]: {
              [`> .${tabClasses.root}:not(:first-of-type)`]: {
                marginLeft: '40px',
              },
            },
          }}
        >
          {items.map((item) => (
            <Tab
              key={item.label}
              label={item.label}
              value={item.value}
              sx={{
                padding: 0,
                paddingBottom: '12px',
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontWeight: 500,
                minWidth: 0,
                minHeight: '36px',
                color: bluegrey[400],
              }}
            />
          ))}
        </TabList>
        {children}
      </TabContext>
    </Box>
  );
};
