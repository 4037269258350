import React, { useCallback, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  Container,
  css,
  Drawer,
  GlobalStyles,
  IconButton,
  Link,
  Stack,
  styled,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useToggle } from '@hooks/common';
import { LanguageSelector, SupportModal } from '@components/common';
import { CursorTypography } from '@components/styled';
import { SuspenseBox } from '@components/styled/suspense';
import CCLogo from '@assets/images/cc_logo.png';
import CCLogoWordMark from '@assets/images/cc_logo_wordmark.png';
import { ROUTES } from '@routes';
import { bluegrey } from '@theme/colors';
import { useTranslation } from 'react-i18next';
import MainHeader from './MainHeader';
import MainSidebar from './MainSidebar';
import { NotificationMenu, ProfileMenu } from './MainLayout.styled';

const drawerWidth: number = 320 as const;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MainLayout: React.FC = ({ children }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [dialogOpen, toggleDialog] = useToggle();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = useCallback(() => {
    setDrawerOpen(true);
  }, []);
  const handleDrawerClose = useCallback(() => {
    setDrawerOpen(false);
  }, []);
  const drawerContainer = useMemo<() => HTMLElement | undefined>(
    () => (window !== undefined ? () => window.document.body : undefined),
    [window],
  );
  return (
    <Box display="flex" minHeight="100vh">
      <GlobalStyles
        styles={css`
          body {
            overflow-y: scroll;
          }
        `}
      />
      <AppBar position="fixed" color="tertiary" variant="elevation">
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" flexGrow={1} alignItems="center">
            <Link href="/console" display="flex" alignItems="center">
              <img src={CCLogoWordMark} alt="CostClipper Logo WordMark" />
            </Link>
          </Box>
          <Box>
            <NotificationMenu />
          </Box>
          <Box ml={3}>
            <ProfileMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        container={drawerContainer}
        open={drawerOpen}
        anchor="left"
        ModalProps={{
          keepMounted: true,
          onBackdropClick: () => drawerOpen && handleDrawerClose(),
        }}
        PaperProps={{
          sx: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        sx={{
          width: drawerWidth,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          display="flex"
          flexGrow={1}
          flexDirection="column"
          pt={7}
          pl={3}
          pr={5}
        >
          <Box>
            <img src={CCLogo} alt="CostClipper Logo" />
          </Box>
          <Box mt={7} pl={2}>
            <MainSidebar isOpen={drawerOpen} onClose={handleDrawerClose} />
          </Box>
        </Box>
        <Box bgcolor="rgba(250, 250, 252, 0.8)" mt={7} px={5} py={3}>
          <Link href="/docs" target="_blank" color="inherit" underline="none">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography color="textSecondary">User Guide</Typography>
              <ArrowForwardIosIcon
                sx={{ width: '10px', height: '10px', color: bluegrey[400] }}
              />
            </Stack>
          </Link>
        </Box>
      </Drawer>
      <Box component="main" display="flex" flexDirection="column" flexGrow={1}>
        <Box mt={14} mb={20} flexGrow={1}>
          <Container maxWidth="xl">
            {children || (
              <>
                <MainHeader />
                <SuspenseBox>
                  <Outlet />
                </SuspenseBox>
              </>
            )}
          </Container>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          bgcolor="white"
          px={4}
          py={2}
        >
          <Box>
            <Typography variant="footer" color="textSecondary">
              &copy; {new Date().getFullYear()}, Grumatic, Inc. All rights
              reserved.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <LanguageSelector />
            <Link
              href="https://www.grumatic.com/privacy-policy"
              target="_blank"
              variant="footer"
              color="textSecondary"
              underline="none"
              ml={2}
            >
              {t('label.privacyPolicy')}
            </Link>
            <CursorTypography
              variant="footer"
              color="textSecondary"
              onClick={toggleDialog}
              ml={2}
            >
              {t('label.support')}
            </CursorTypography>
            {dialogOpen && (
              <SupportModal open={dialogOpen} onClose={toggleDialog} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
