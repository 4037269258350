import React, { useMemo, useState } from 'react';
import { Button, Paper } from '@mui/material';
import { useGetCompanies } from '@hooks/api/admin/company';
import { WithRoot } from '@hooks/hoc';
import { useToggle } from '@hooks/common';
import { GrmtTableColumnInfo, SimplePaginationTable } from '@components/table';
import { DetailMenu } from '@components/common';
import {
  CreateCompanyModal,
  DeleteCompanyModal,
  UpdateCompanyModal,
} from './modal';

const AdminCompanyPage: React.FC = () => {
  const [openCreate, toggleCreate] = useToggle();
  const [openUpdate, toggleUpdate] = useToggle();
  const [openDelete, toggleDelete] = useToggle();
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const { data } = useGetCompanies();
  const columns = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      { Header: 'Company name', accessor: 'name' as const },
      { Header: 'Email', accessor: 'email' as const },
      { Header: 'Tier', accessor: 'plan' as const },
      {
        Header: 'Actions',
        align: 'center',
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        Cell: ({ row }) => (
          <DetailMenu
            items={[
              {
                label: 'Edit',
                onClick: () => {
                  setSelected(row.original.id);
                  toggleUpdate();
                },
              },
              {
                label: 'Delete',
                color: 'error.main',
                onClick: () => {
                  setSelected(row.original.id);
                  toggleDelete();
                },
              },
            ]}
          />
        ),
      },
    ],
    [],
  );
  return (
    <>
      <Paper square sx={{ px: 7, py: 5 }}>
        <SimplePaginationTable
          data={data?.items}
          columnInfo={columns}
          ExtraElement={
            <Button size="large" variant="outlined" onClick={toggleCreate}>
              Add new company
            </Button>
          }
          enableFilter
        />
      </Paper>
      {openCreate && (
        <CreateCompanyModal open={openCreate} onClose={toggleCreate} />
      )}
      {openUpdate && selected && (
        <UpdateCompanyModal
          open={openUpdate}
          onClose={toggleUpdate}
          companyId={selected}
        />
      )}
      {openDelete && selected && (
        <DeleteCompanyModal
          open={openDelete}
          onClose={toggleDelete}
          companyId={selected}
        />
      )}
    </>
  );
};

export default WithRoot(AdminCompanyPage);
