import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ValueFormatter } from '@utils';

type CircularChartData = {
  cost: number;
};

type CircularChartProps = {
  data: [CircularChartData, CircularChartData, CircularChartData];
  size?: 'medium' | 'small';
  showPercent?: boolean;
};

const COLORS = ['#FF7976', '#9594FF', '#57CAEB'] as const;

const RADIAN = Math.PI / 180;

const PlannerCircularChart: React.FC<CircularChartProps> = ({
  data,
  size = 'medium',
  showPercent,
}) => {
  const { t } = useTranslation('planner');
  return (
    <ResponsiveContainer width="100%" height={size === 'medium' ? 260 : 100}>
      <PieChart>
        <Pie
          data={data}
          dataKey="cost"
          startAngle={90}
          endAngle={-270}
          outerRadius="100%"
          cx="50%"
          cy="50%"
          labelLine={false}
          isAnimationActive={false}
          label={
            size === 'medium'
              ? ({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  percent,
                  index,
                }) => {
                  const radius =
                    innerRadius + (outerRadius - innerRadius) * 0.6;
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);
                  return (
                    <text
                      dx={x}
                      dy={y}
                      fill="#fff"
                      textAnchor="middle"
                      fontSize={16}
                      fontWeight={500}
                    >
                      {percent > 0.0 &&
                        (showPercent ? (
                          <>
                            {percent > 0.2 &&
                              ValueFormatter.toPercent(percent * 100, 0)}
                          </>
                        ) : (
                          <>
                            {percent > 0.2 && index === 0 && t('label.od')}
                            {percent > 0.075 && index === 1 && 'SP'}
                            {percent > 0.075 && index === 2 && 'RI'}
                          </>
                        ))}
                    </text>
                  );
                }
              : undefined
          }
        >
          {data.map((entry, idx) => (
            <Cell key={`cell_circular_${idx.toString()}`} fill={COLORS[idx]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
PlannerCircularChart.propTypes = {
  size: PropTypes.oneOf(['medium', 'small']),
};
PlannerCircularChart.defaultProps = {
  size: 'medium',
  showPercent: false,
};

export default React.memo(PlannerCircularChart);
