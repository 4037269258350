import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useEBSInformation,
  useEBSUsageChart,
} from '@hooks/api/optimization/ebs';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { DetailLinkIcon } from '@components/table/table.styled';
import { ROUTES } from '@routes';
import { ValueFormatter } from '@utils';
import { useAWSHelper } from '@hooks/helper';
import VolumeFilter from './VolumeFilter';

const VolumeTable: React.FC = () => {
  const { data, onPageChange, pageSize } = useEBSInformation();
  const { data: chartData } = useEBSUsageChart();

  const { t } = useTranslation('optimization');
  const { getOptimizationAWSRegion } = useAWSHelper();

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        minWidth: 140,
        maxWidth: 140,
        disableSortBy: true,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.volume.id'),
        accessor: 'VolumeId' as const,
        minWidth: 190,
        maxWidth: 190,
        disableSortBy: true,
      },
      {
        Header: t('label.volume.type'),
        accessor: 'CurrentType' as const,
        minWidth: 96,
        maxWidth: 96,
        disableSortBy: true,
      },
      {
        Header: t('label.volume.state'),
        accessor: 'State' as const,
        minWidth: 96,
        maxWidth: 96,
        disableSortBy: true,
      },
      {
        Header: t('label.common.tag'),
        accessor: 'Tag' as const,
        maxWidth: 208,
        disableSortBy: true,
        Cell: ({ value }) => (
          <>
            {value?.length
              ? Object.keys(value[0]).map((v) => {
                  const key = v.split(':');
                  return (
                    <React.Fragment key={v}>
                      {`${key[key.length - 1]}: ${value[0][v]}`}
                    </React.Fragment>
                  );
                })
              : '-'}
          </>
        ),
      },
      {
        Header: 'IOPS',
        accessor: 'IOPS' as const,
        align: 'right',
        minWidth: 88,
        maxWidth: 88,
      },
      {
        Header: t('label.volume.throughput'),
        accessor: 'Throughput' as const,
        align: 'right',
        minWidth: 128,
        Cell: ({ value }: any) => {
          if (typeof value === 'number') {
            return `${ValueFormatter.toLocaleString(value, 0)} MiB/s`;
          }
          return '-';
        },
      },
      {
        Header: t('label.common.savings'),
        accessor: 'MonthlySaving' as const,
        align: 'right',
        minWidth: 112,
        Cell: ({ value }) => ValueFormatter.toCurrency(value) || '-',
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailLinkIcon
            to={`${ROUTES.DETAILS.VOLUME}/${row.original.VolumeId}`}
            requireCUR
          />
        ),
      },
    ],
    [getOptimizationAWSRegion],
  );

  return (
    <DataPaginationTable
      columnInfo={columnInfo}
      data={data?.body?.items}
      totalCount={data?.body?.totalCount}
      onPageChange={onPageChange}
      pageSize={pageSize}
      FilterElement={VolumeFilter}
      CSVUrl="ebs"
      enableFilter
      enableSort
      LastUpdate={chartData.extras.LatestUpdate}
    />
  );
};

export default VolumeTable;
