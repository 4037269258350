import { useCallback, useMemo } from 'react';
import {
  useCreateNewrelic,
  useDeleteNewrelic,
  useGetNewrelic,
  useUpdateNewrelic,
  useVerifyNewrelic,
} from '@hooks/api/settings/integration';
import { useCurrentInfo } from '@hooks/api/common';

export default () => {
  const { currentCompany } = useCurrentInfo();
  const { data } = useGetNewrelic();
  const {
    isSuccess: isVerified,
    isLoading: isVerifying,
    mutateAsync: verifyNewrelic,
  } = useVerifyNewrelic();
  const { isLoading: isCreating, mutateAsync: createNewrelic } =
    useCreateNewrelic();
  const { isLoading: isUpdating, mutateAsync: updateNewrelic } =
    useUpdateNewrelic();
  const { isLoading: isDeleting, mutateAsync: fetchDelete } =
    useDeleteNewrelic();

  const hasNewrelic = useMemo<boolean>(
    () => Boolean(data?.items?.length),
    [data?.items],
  );

  const newrelicItem = useMemo<any>(() => {
    if (hasNewrelic) {
      return data.items[0];
    }
    return undefined;
  }, [hasNewrelic, data?.items]);

  const deleteNewrelic = useCallback(async () => {
    if (currentCompany?.id) {
      try {
        await fetchDelete({ companyId: currentCompany.id });
      } catch (e) {
        console.error(e);
      }
    }
  }, [currentCompany?.id]);

  return {
    hasNewrelic,
    newrelicItem,
    verifyNewrelic,
    createNewrelic,
    updateNewrelic,
    deleteNewrelic,
    isVerified,
    isVerifying,
    isCreating,
    isUpdating,
    isDeleting,
  };
};
