import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Divider,
  Button,
  Stack,
  Chip,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEC2DetailsPurchasing } from '@hooks/api/optimization/ec2';
import { CoverageChart } from '@components/chart/optimization';
import { PlannerCircularChart } from '@components/chart/planner';
import { SimulationTable } from '@pages/private/planner/simulation/Simulation.styled';
import { getPercentageProportion, ValueFormatter } from '@utils';
import _ from 'lodash';
import { PurchasingOptionRadioGroup } from '@pages/private/details/compute/DetailsCompute.styled';
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { bluegrey } from '@theme/colors';
import { DotLegendItem, RecommendActionDivider } from '@components/styled';
import { ChartCoverBox, NoAvailableData } from '@components/styled/noData';
import { ROUTES } from '@routes';
import { COVERAGE_DUMMY } from '@components/chart/chart.dummy';

const DetailsComputePurchasingPage: React.FC = () => {
  const { instanceId } = useParams();
  const navigate = useNavigate();
  const [option, setOption] = useState<'Aggressive' | 'Passive'>('Aggressive');
  const { t } = useTranslation('optimization');
  const { t: plannerT } = useTranslation('planner');
  const { t: commonT } = useTranslation('common');
  const { data } = useEC2DetailsPurchasing();
  const usageTableBody = useMemo(() => {
    if (data?.items?.length) {
      const { InstanceUsage } = data.items[0];
      return [
        {
          label: t('label.common.od'),
          items: InstanceUsage.slice(-3).map((v) =>
            ValueFormatter.toLocaleString(v.Ondemand, 0),
          ),
        },
        {
          label: (
            <>
              {t('label.common.ri')}
              <br />({t('label.header.coverage')})
            </>
          ),
          items: InstanceUsage.slice(-3).map((v) => (
            <>
              {ValueFormatter.toLocaleString(v.Reserved, 0)}
              <br />(
              {ValueFormatter.toPercent(
                getPercentageProportion(v.Reserved, v.TotalUsage),
              )}
              )
            </>
          )),
        },
        {
          label: (
            <>
              {t('label.common.sp')}
              <br />({t('label.header.coverage')})
            </>
          ),
          items: InstanceUsage.slice(-3).map((v) => (
            <>
              {ValueFormatter.toLocaleString(v.SavingsPlan, 0)}
              <br />(
              {ValueFormatter.toPercent(
                getPercentageProportion(v.SavingsPlan, v.TotalUsage),
              )}
              )
            </>
          )),
        },
      ];
    }
    return [];
  }, [data?.items]);
  const coverageTableBody = useMemo(() => {
    if (data?.items?.length) {
      const { FamilyUsage } = data.items[0];
      return [
        {
          label: t('label.common.od'),
          items: FamilyUsage.slice(-6).map((v) =>
            ValueFormatter.toLocaleString(v.Ondemand, 0),
          ),
        },
        {
          label: (
            <>
              {t('label.common.ri')}
              <br />({t('label.header.coverage')})
            </>
          ),
          items: FamilyUsage.slice(-6).map((v) => (
            <>
              {ValueFormatter.toLocaleString(v.Reserved, 0)}
              <br />(
              {ValueFormatter.toPercent(
                getPercentageProportion(v.Reserved, v.TotalUsage),
              )}
              )
            </>
          )),
        },
        {
          label: (
            <>
              {t('label.common.sp')}
              <br />({t('label.header.coverage')})
            </>
          ),
          items: FamilyUsage.slice(-6).map((v) => (
            <>
              {ValueFormatter.toLocaleString(v.SavingsPlan, 0)}
              <br />(
              {ValueFormatter.toPercent(
                getPercentageProportion(v.SavingsPlan, v.TotalUsage),
              )}
              )
            </>
          )),
        },
      ];
    }
    return [];
  }, [data?.items]);
  const purchaseOptions = useMemo<Array<string>>(() => {
    if (
      data?.items?.length &&
      !_.isEmpty(data.items[0].RecommendPricingPlan?.Recommend)
    ) {
      const { RecommendPricingPlan } = data.items[0];
      const selected = RecommendPricingPlan.Recommend[option];
      if (selected.Type === 'ReservedInstance') {
        return [
          plannerT(`RIClass.${selected.PurchaseOption.OfferingClass}`),
          selected.PurchaseOption.DurationSeconds === 94608000
            ? plannerT('Term.3-yr')
            : plannerT('Term.1-yr'),
          plannerT(`PaymentOption.${selected.PurchaseOption.PaymentOption}`),
        ];
      }
      return [
        plannerT(`SPType.${selected.PurchaseOption.PlanType}`),
        selected.PurchaseOption.DurationSeconds === 94608000
          ? plannerT('Term.3-yr')
          : plannerT('Term.1-yr'),
        plannerT(`PaymentOption.${selected.PurchaseOption.PaymentOption}`),
      ];
    }
    return [];
  }, [data?.items, option]);
  const buyMoreText = useMemo<string>(() => {
    if (
      data?.items?.length &&
      !_.isEmpty(data.items[0].RecommendPricingPlan?.Recommend)
    ) {
      const { RecommendPricingPlan } = data.items[0];
      const selected = RecommendPricingPlan.Recommend[option];
      if (selected.Type === 'ReservedInstance') {
        return plannerT('buyMore.riHtmlDetails', {
          count: selected.PurchaseOption.NumberOfRI,
        });
      }
      return plannerT('buyMore.spHtmlDetails', {
        cost: ValueFormatter.toCurrency(selected.Commitment),
      });
    }
    return '';
  }, [data?.items, option]);
  const familyChip = useMemo<string>(() => {
    if (
      data?.items?.length &&
      !_.isEmpty(data.items[0].RecommendPricingPlan?.Recommend)
    ) {
      const { RecommendPricingPlan } = data.items[0];
      const selected = RecommendPricingPlan.Recommend[option];
      if (selected.Type === 'ReservedInstance') {
        return selected.PurchaseOption.InstanceType;
      }
      return `${data?.extras?.InstanceFamily} Family`;
    }
    return '';
  }, [data?.items, option, data?.extras?.InstanceFamily]);

  if (!data?.items?.length) {
    return <NoAvailableData />;
  }
  const { LastMonthUsage, InstanceUsage, FamilyUsage, RecommendPricingPlan } =
    data.items[0];
  if (
    _.isEmpty(LastMonthUsage) &&
    _.isEmpty(InstanceUsage) &&
    _.isEmpty(FamilyUsage)
  ) {
    return <NoAvailableData />;
  }
  return (
    <>
      <Grid container columnSpacing={11} rowSpacing={5}>
        <Grid item xs={4}>
          <Typography color="textSecondary" mb={1}>
            {plannerT('label.usage')} ({commonT('label.days', { count: 30 })})
          </Typography>
          <ChartCoverBox isEmpty={_.isEmpty(LastMonthUsage)}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <PlannerCircularChart
                data={
                  !_.isEmpty(LastMonthUsage)
                    ? [
                        { cost: LastMonthUsage.Ondemand },
                        { cost: LastMonthUsage.SavingsPlan },
                        { cost: LastMonthUsage.Reserved },
                      ]
                    : [{ cost: 6 }, { cost: 1 }, { cost: 3 }]
                }
                size="medium"
                showPercent
              />
              <Typography variant="body2" mt={5} mb={2}>
                {plannerT('label.totalUsage')} (
                {commonT('label.days', { count: 30 })})
              </Typography>
              <Typography variant="subtitle2">
                {ValueFormatter.toLocaleString(
                  LastMonthUsage.TotalUsage || 0,
                  0,
                )}
                h
              </Typography>
            </Box>
          </ChartCoverBox>
        </Grid>
        <Grid item xs={8}>
          <Typography color="textSecondary" mb={1}>
            {plannerT('label.instanceCoverage')} (
            {commonT('label.months', { count: 3 })})
          </Typography>
          <ChartCoverBox isEmpty={_.isEmpty(InstanceUsage)}>
            <CoverageChart
              data={
                !_.isEmpty(InstanceUsage)
                  ? InstanceUsage.slice(-3)
                  : COVERAGE_DUMMY.slice(-3)
              }
              xAxis={{
                dataKey: 'Date',
                tickFormatter: (value: any) => moment(value).format('YYYY MMM'),
              }}
              chartInfo={[
                {
                  name: t('label.common.od'),
                  dataKey: 'Ondemand',
                },
                {
                  name: t('label.common.ri'),
                  dataKey: 'Reserved',
                },
                {
                  name: t('label.common.sp'),
                  dataKey: 'SavingsPlan',
                },
              ]}
            />
          </ChartCoverBox>
        </Grid>
      </Grid>
      {!_.isEmpty(InstanceUsage) && (
        <Box
          mt={5}
          borderTop="2px solid #f0f0f7"
          borderBottom="2px solid #f0f0f7"
        >
          <SimulationTable
            title={t('label.header.purchasing')}
            bodyAlign="right"
            header={InstanceUsage.slice(-3).map((v) =>
              moment(v.Date).format('YYYY MMM'),
            )}
            body={usageTableBody}
          />
        </Box>
      )}
      <Typography color="textSecondary" mt={20}>
        {plannerT('label.familyCoverage')} (
        {commonT('label.months', { count: 6 })})
      </Typography>
      <ChartCoverBox isEmpty={_.isEmpty(FamilyUsage)}>
        <CoverageChart
          data={
            !_.isEmpty(FamilyUsage) ? FamilyUsage.slice(-6) : COVERAGE_DUMMY
          }
          xAxis={{
            dataKey: 'Date',
            tickFormatter: (value: any) => moment(value).format('YYYY MMM'),
          }}
          chartInfo={[
            {
              name: t('label.common.od'),
              dataKey: 'Ondemand',
            },
            {
              name: t('label.common.ri'),
              dataKey: 'Reserved',
            },
            {
              name: t('label.common.sp'),
              dataKey: 'SavingsPlan',
            },
          ]}
        />
      </ChartCoverBox>
      {!_.isEmpty(FamilyUsage) && (
        <Box
          mt={5}
          borderTop="2px solid #f0f0f7"
          borderBottom="2px solid #f0f0f7"
        >
          <SimulationTable
            title={plannerT('label.coveragePerMonth')}
            bodyAlign="right"
            header={FamilyUsage.slice(-6).map((v) =>
              moment(v.Date).format('YYYY MMM'),
            )}
            body={coverageTableBody}
          />
        </Box>
      )}
      {!_.isEmpty(RecommendPricingPlan?.Recommend) && (
        <Box mt={22}>
          <Box mb={5}>
            <RecommendActionDivider />
          </Box>
          <PurchasingOptionRadioGroup
            value={option}
            onChange={(e, v) => setOption(v as 'Aggressive' | 'Passive')}
            Aggressive={RecommendPricingPlan.Recommend.Aggressive.CostSaving}
            Passive={RecommendPricingPlan.Recommend.Passive.CostSaving}
          />
          <Paper variant="elevation" sx={{ px: 7, py: 5, mt: 7 }}>
            <Typography
              variant="h3"
              color="textSecondary"
              dangerouslySetInnerHTML={{ __html: buyMoreText }}
              mb={2}
              sx={{
                '> span': {
                  color: bluegrey[900],
                },
              }}
            />
            <Box display="flex" mb={3}>
              <Stack direction="row" spacing={1} flexGrow={1}>
                {purchaseOptions.map((v) => (
                  <Chip key={v} label={v} />
                ))}
              </Stack>
              <Chip label={familyChip} />
            </Box>
            <Divider />
            <Grid container direction="column" rowSpacing={9} mt={1} px={6}>
              <Grid
                container
                item
                columnSpacing={5}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item md={5}>
                  <PlannerCircularChart
                    data={[
                      {
                        cost: RecommendPricingPlan.Usage
                          .NormalizedOndemandUsage,
                      },
                      {
                        cost: RecommendPricingPlan.Usage
                          .NormalizedSavingsPlanUsage,
                      },
                      {
                        cost: RecommendPricingPlan.Usage
                          .NormalizedReservedUsage,
                      },
                    ]}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  flexDirection={{ md: 'column', xs: 'row' }}
                  alignItems="center"
                  md={2}
                >
                  <ArrowRightIcon
                    sx={{
                      width: '100px',
                      height: '100px',
                      color: bluegrey[600],
                    }}
                  />
                  <Box>
                    <Typography color="textSecondary" align="center">
                      {plannerT('label.estimatedSavings')}
                    </Typography>
                    <Typography variant="h2" align="center" mt={2}>
                      {ValueFormatter.toCurrency(
                        RecommendPricingPlan.Recommend[option].CostSaving,
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={5}>
                  <PlannerCircularChart
                    data={[
                      {
                        cost: RecommendPricingPlan.Recommend[option].Coverage
                          .NormalizedOndemandUsage,
                      },
                      {
                        cost: data.items[0].RecommendPricingPlan.Recommend[
                          option
                        ].Coverage.NoramlizedSavingsPlanUsage,
                      },
                      {
                        cost: data.items[0].RecommendPricingPlan.Recommend[
                          option
                        ].Coverage.NormalizedReservedUsage,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                columnSpacing={5}
                justifyContent="space-between"
              >
                <Grid item md={5}>
                  <Box px={9} pt={11} pb={5}>
                    <Box>
                      <Typography color="textSecondary" align="center">
                        {plannerT('label.past')}
                      </Typography>
                      <Typography variant="h3" align="center" mt={1}>
                        {ValueFormatter.toCurrency(
                          data.items[0].RecommendPricingPlan.Recommend[option]
                            .ChangedMonthlyCost +
                            data.items[0].RecommendPricingPlan.Recommend[option]
                              .CostSaving,
                        )}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={6}
                    >
                      <DotLegendItem
                        color="#57CAEB"
                        label={t('label.common.ri_coverage')}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {ValueFormatter.toPercent(
                          getPercentageProportion(
                            data.items[0].RecommendPricingPlan.Usage.Reserved,
                            data.items[0].RecommendPricingPlan.Usage.TotalUsage,
                          ),
                        )}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={2}
                    >
                      <DotLegendItem
                        color="#9594FF"
                        label={t('label.common.sp_coverage')}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {ValueFormatter.toPercent(
                          getPercentageProportion(
                            data.items[0].RecommendPricingPlan.Usage
                              .SavingsPlan,
                            data.items[0].RecommendPricingPlan.Usage.TotalUsage,
                          ),
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={5}>
                  <Box px={9} pt={11} pb={5}>
                    <Box>
                      <Typography color="textSecondary" align="center">
                        {plannerT('label.next')}
                      </Typography>
                      <Typography variant="h3" align="center" mt={1}>
                        {ValueFormatter.toCurrency(
                          data.items[0].RecommendPricingPlan.Recommend[option]
                            .ChangedMonthlyCost,
                        )}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={6}
                    >
                      <DotLegendItem
                        color="#57CAEB"
                        label={t('label.common.ri_coverage')}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {ValueFormatter.toPercent(
                          getPercentageProportion(
                            data.items[0].RecommendPricingPlan.Recommend[option]
                              .Coverage.NormalizedReservedUsage,
                            data.items[0].RecommendPricingPlan.Usage
                              .TotalNormalizedUsage,
                          ),
                        )}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={2}
                    >
                      <DotLegendItem
                        color="#9594FF"
                        label={t('label.common.sp_coverage')}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {ValueFormatter.toPercent(
                          getPercentageProportion(
                            data.items[0].RecommendPricingPlan.Recommend[option]
                              .Coverage.NoramlizedSavingsPlanUsage,
                            data.items[0].RecommendPricingPlan.Usage
                              .TotalNormalizedUsage,
                          ),
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Box display="flex" justifyContent="flex-end" mt={6}>
            <Button
              size="large"
              onClick={() =>
                navigate(`${ROUTES.PLANNER.SIMULATION_UNCOVERED}/${instanceId}`)
              }
              endIcon={
                <ArrowForwardIcon sx={{ width: '19px', height: '19px' }} />
              }
            >
              Smart Planner
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DetailsComputePurchasingPage;
