/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  inputBaseClasses,
  svgIconClasses,
  TextField,
  inputAdornmentClasses,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { CalendarToday } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { useToggle } from '@hooks/common';
import { FieldLabel } from './styled';

type UsageDatePickerProps = {
  filter: CEUsageRequest;
  setFilter: React.Dispatch<React.SetStateAction<CEUsageRequest>>;
};
export default function UsageDatePicker({
  filter,
  setFilter,
}: UsageDatePickerProps) {
  const { t } = useTranslation('usage');
  // const [filter, setFilter] = useRecoilState(ceUsageState);
  const [FromDate, setFromDate] = useState<Moment>(moment(filter.FromDate));
  const [ToDate, setToDate] = useState<Moment>(moment(filter.ToDate));
  const [openFrom, toggleFrom] = useToggle();
  const [openTo, toggleTo] = useToggle();
  const isDateBefore = useCallback((source: Moment, target: Date) => {
    if (source.isSameOrAfter(moment(target), 'years')) {
      if (source.isSameOrAfter(moment(target), 'months')) {
        return source.isBefore(moment(target), 'days');
      }
    }
    return true;
  }, []);
  const isDateAfter = useCallback((source: Moment, target: Date) => {
    if (source.isSameOrBefore(moment(target), 'years')) {
      if (source.isSameOrBefore(moment(target), 'months')) {
        return source.isAfter(moment(target), 'days');
      }
    }
    return true;
  }, []);
  const handleClose = useCallback(() => {
    setFromDate(moment(filter.FromDate));
    toggleTo();
  }, [filter.FromDate]);
  const handleAccept = useCallback(
    (v: Moment | null) => {
      if (v) {
        setToDate(v);
        if (isDateAfter(v, FromDate.toDate())) {
          setFilter((s) => ({
            ...s,
            FromDate: FromDate.toDate(),
            ToDate: v.toDate(),
          }));
        } else {
          setFromDate(v);
          setFilter((s) => ({
            ...s,
            FromDate: v.toDate(),
            ToDate: v.toDate(),
          }));
        }
      }
      toggleTo();
    },
    [setFilter, FromDate],
  );
  useEffect(() => {
    setFromDate(moment(filter.FromDate));
  }, [filter.FromDate]);
  useEffect(() => {
    setToDate(moment(filter.ToDate));
  }, [filter.ToDate]);
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Grid
        container
        item
        rowSpacing="24px"
        columnSpacing="16px"
        lg={6}
        xs={12}
      >
        <Grid item xs={6}>
          <FieldLabel>{t('filter.date_range')}</FieldLabel>
          <DesktopDatePicker
            open={openFrom}
            onClose={toggleFrom}
            value={FromDate}
            onAccept={toggleTo}
            onChange={(newValue) => {
              if (newValue) {
                setFromDate(newValue);
              }
            }}
            renderInput={(props) => (
              <TextField
                {...props}
                onClick={toggleFrom}
                inputProps={{
                  ...props.inputProps,
                  readOnly: true,
                }}
                sx={{
                  [`> .${inputBaseClasses.root}`]: {
                    userSelect: 'none',
                    '> *': {
                      cursor: 'pointer',
                      userSelect: 'none',
                    },
                    [`> .${inputAdornmentClasses.root}`]: {
                      [`& .${svgIconClasses.root}`]: {
                        width: '24px',
                        height: '24px',
                        color: '#a0a1b2',
                      },
                    },
                  },
                }}
                fullWidth
              />
            )}
            components={{
              OpenPickerIcon: CalendarToday,
            }}
            showDaysOutsideCurrentMonth
            disableFuture
          />
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <DesktopDatePicker
            open={openTo}
            onClose={handleClose}
            value={ToDate}
            onChange={handleAccept}
            renderInput={(props) => (
              <TextField
                {...props}
                onClick={toggleTo}
                inputProps={{
                  ...props.inputProps,
                  readOnly: true,
                }}
                sx={{
                  [`> .${inputBaseClasses.root}`]: {
                    userSelect: 'none',
                    '> *': {
                      cursor: 'pointer',
                      userSelect: 'none',
                    },
                    [`> .${inputAdornmentClasses.root}`]: {
                      [`& .${svgIconClasses.root}`]: {
                        width: '24px',
                        height: '24px',
                        color: '#a0a1b2',
                      },
                    },
                  },
                }}
                fullWidth
              />
            )}
            components={{
              OpenPickerIcon: CalendarToday,
            }}
            shouldDisableDate={(v) => v.isBefore(FromDate, 'days')}
            shouldDisableYear={(v) => v.isBefore(FromDate, 'years')}
            disableCloseOnSelect
            showDaysOutsideCurrentMonth
            disableFuture
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}
