import axios from '@utils/axios';
import { API_URL } from '@constants';

const getSummary = async (
  AccountList: string | undefined = undefined,
): Promise<CCQueryResponse<OverviewSummary, { LatestUpdate: Date }>> => {
  const { data } = await axios.get(`${API_URL.OVERVIEW}/summary/`, {
    params: {
      AccountList,
    },
  });
  return data;
};

const getMonthToDate = async (
  AccountList: string | undefined = undefined,
): Promise<CCQueryResponse<MonthToDateResponse, MonthToDateExtra>> => {
  const { data } = await axios.get(`${API_URL.OVERVIEW}/up_to_date/`, {
    params: {
      AccountList,
    },
  });
  return data;
};

const getLastMonthComparison = async (
  AccountList: string | undefined = undefined,
): Promise<CCQueryResponse<CostComparisonResponse, CostComparisonExtra>> => {
  const { data } = await axios.get(`${API_URL.OVERVIEW}/comparison/last/`, {
    params: {
      AccountList,
    },
  });
  return data;
};

const getThisMonthComparison = async (
  AccountList: string | undefined = undefined,
): Promise<CCQueryResponse<CostComparisonResponse, CostComparisonExtra>> => {
  const { data } = await axios.get(`${API_URL.OVERVIEW}/comparison/this/`, {
    params: {
      AccountList,
    },
  });
  return data;
};

const getAnnualTrend = async (
  AccountList: string | undefined = undefined,
): Promise<CCQueryResponse<CostOverviewResponse>> => {
  const { data } = await axios.get(`${API_URL.OVERVIEW}/annual/`, {
    params: {
      AccountList,
    },
  });
  return data;
};

const getPossibleSavings = async (
  AccountList: string | undefined = undefined,
): Promise<CCQuerySingleResponse<PossibleSavings, PossibleSavingsExtra>> => {
  const { data } = await axios.get(`${API_URL.OVERVIEW}/possiblesavings/`, {
    params: {
      AccountList,
    },
  });
  return data;
};

const getNotifications = async (): Promise<CCQueryResponse<Notifications>> => {
  const { data } = await axios.get(`${API_URL.OVERVIEW}/notifications`);
  return data;
};

const ignoreNotification = async (
  uid: string,
): Promise<CCQueryResponse<GeneralAlertItem>> => {
  const { data } = await axios.put(
    `${API_URL.OPTIMIZATION}/intelligence/${uid}`,
  );
  return data;
};

export default {
  getSummary,
  getMonthToDate,
  getLastMonthComparison,
  getThisMonthComparison,
  getAnnualTrend,
  getPossibleSavings,
  getNotifications,
  ignoreNotification,
};
