import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useCurrentInfo } from '@hooks/api/common';
import { WithAdmin } from '@hooks/hoc';
import { useTagManager } from '@hooks/common';
import {
  InitialDiagnosticStep,
  LinkedAccountStep,
  SelectRegionStep,
} from './steps';
import { diagnosticStepState } from './RunDiagnostic.atoms';
import { OnboardPageWrapper, OnboardStepper } from '../Onboard.styled';

const RunDiagnosticPage: React.FC = () => {
  const { t } = useTranslation('onboard');
  const { t: commonT } = useTranslation('common');
  const navigate = useNavigate();
  const { isLoading, currentAWSAccounts } = useCurrentInfo();
  if (isLoading && !currentAWSAccounts?.length) {
    navigate(-1);
  }
  const isPayer = currentAWSAccounts?.length
    ? Boolean(currentAWSAccounts.find((a) => a.isPayer))
    : false;
  const [active, setActive] = useRecoilState(diagnosticStepState);
  const { sendDataLayer } = useTagManager();
  const handleClick = useCallback(() => {
    if (isPayer && active === 0) {
      sendDataLayer('cc-onboard-diagnostic-organization-next');
    }
    setActive((v) => v + 1);
  }, [sendDataLayer, setActive, active, isPayer]);
  return (
    <OnboardPageWrapper
      title={t('title.diagnostic')}
      disablePadding={active > Number(isPayer)}
    >
      <OnboardStepper
        activeStep={active}
        stepLabels={
          isPayer
            ? [
                t('step.linkedAccount'),
                t('step.selectRegion'),
                t('step.runDiagnostic'),
              ]
            : [t('step.selectRegion'), t('step.runDiagnostic')]
        }
        sx={{
          pt: active > Number(isPayer) ? 7 : 0,
          px: active > Number(isPayer) ? 7 : 0,
        }}
      />
      <Box mt={11} mb={active < 1 ? 7 : 0}>
        <>
          {isPayer ? (
            <>
              {active === 0 && <LinkedAccountStep />}
              {active === 1 && <SelectRegionStep isPayer={isPayer} />}
              {active === 2 && <InitialDiagnosticStep />}
            </>
          ) : (
            <>
              {active === 0 && <SelectRegionStep isPayer={isPayer} />}
              {active === 1 && <InitialDiagnosticStep />}
            </>
          )}
        </>
      </Box>
      {active < Number(isPayer) && (
        <Box ml="auto">
          <Button size="large" color="secondary" onClick={handleClick}>
            {commonT('button.next')}
          </Button>
        </Box>
      )}
    </OnboardPageWrapper>
  );
};

export default WithAdmin(RunDiagnosticPage);
