/* eslint-disable default-case */
import React, { useCallback } from 'react';
import { ButtonBase, Grid, Paper, Typography } from '@mui/material';
import { useTagManager, useToggle } from '@hooks/common';
import { ReactComponent as GuideIcon } from '@assets/icons/etc/guide.svg';
import { ReactComponent as SupportIcon } from '@assets/icons/etc/support.svg';
import { ReactComponent as WebsiteIcon } from '@assets/icons/etc/website.svg';
import SupportModal from './SupportModal';
import { ExternalSupportModal } from '../external';

const GuideBox: React.FC<{ external?: boolean }> = ({ external }) => {
  const [supportOpen, toggleSupport] = useToggle();
  const { sendDataLayer } = useTagManager();
  const handleClick = useCallback(() => {
    sendDataLayer('cc-onboard-guide');
  }, [sendDataLayer]);
  return (
    <Paper square sx={{ mt: 6, py: 8 }}>
      <Grid container justifyContent="space-evenly" rowSpacing={3}>
        <Grid item md={3} xs={12} textAlign="center">
          <ButtonBase
            component="a"
            href="/docs/getting-started"
            target="_blank"
            onClick={handleClick}
            sx={{ display: 'inline-block' }}
          >
            <GuideIcon />
            <Typography variant="subtitle1" color="textSecondary" mt={2}>
              Onboarding Guide
            </Typography>
          </ButtonBase>
        </Grid>
        <Grid item md={3} xs={12} textAlign="center">
          <ButtonBase onClick={toggleSupport} sx={{ display: 'inline-block' }}>
            <SupportIcon />
            <Typography variant="subtitle1" color="textSecondary" mt={2}>
              Tech Support
            </Typography>
          </ButtonBase>
        </Grid>
        <Grid item md={3} xs={12} textAlign="center">
          <ButtonBase
            component="a"
            href="https://www.grumatic.com"
            target="_blank"
            sx={{ display: 'inline-block' }}
          >
            <WebsiteIcon />
            <Typography variant="subtitle1" color="textSecondary" mt={2}>
              Website
            </Typography>
          </ButtonBase>
        </Grid>
      </Grid>
      {supportOpen &&
        (external ? (
          <ExternalSupportModal open={supportOpen} onClose={toggleSupport} />
        ) : (
          <SupportModal open={supportOpen} onClose={toggleSupport} />
        ))}
    </Paper>
  );
};
GuideBox.defaultProps = {
  external: false,
};

export default GuideBox;
