import axios from '@utils/axios';
import { API_URL } from '@constants';
import { parseContentRange } from '@utils';

const getEBSInformationList: PaginatedAxios<
  WithContentRange<CCQueryResponse<EBSInformation>>
> = async (skip, limit, filter, sort, apm) => {
  const { headers, data } = await axios.get(`${API_URL.OPTIMIZATION}/ebs/`, {
    params: {
      skip,
      limit,
      filter,
      sort,
      apm,
    },
  });
  return { headers: parseContentRange(headers), body: data };
};

const getEBSUsageChart = async (
  apm: APMSource = 'cloudwatch',
  AccountList: string | undefined = undefined,
): Promise<
  CCQueryResponse<
    EBSChart,
    {
      TotalNumberOfSavingsvolumes: number;
      TotalNumberOfVolumes: number;
      TotalPossibleSaving: number;
      LatestUpdate: Date;
    }
  >
> => {
  const { data } = await axios.get(`${API_URL.OPTIMIZATION}/ebs/graph/usage/`, {
    params: {
      apm,
      AccountList,
    },
  });
  return data;
};

const getEBSAutoComplete = async (): Promise<
  CCQueryResponse<EBSAutoComplete>
> => {
  const { data } = await axios.get(`${API_URL.OPTIMIZATION}/ebs/acdata`);
  return data;
};

const getEBSDetailsGeneral = async (
  volumeId: string,
): Promise<CCQueryResponse<EBSDetailsGeneral>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ebs/details/general/${volumeId}`,
  );
  return data;
};

const getEBSDetailsSummary = async (
  volumeId: string,
  apm: APMSource,
): Promise<CCQueryResponse<EBSDetailsSummary, { TotalCost: number }>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ebs/details/summary/${volumeId}/${apm}`,
  );
  return data;
};

export default {
  getEBSInformationList,
  getEBSUsageChart,
  getEBSAutoComplete,
  getEBSDetailsGeneral,
  getEBSDetailsSummary,
};
