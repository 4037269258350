import React, { ComponentType, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes';
import { GLOBAL } from '@constants';
import { useSetRecoilState } from 'recoil';
import { callbackUrlState } from '@atoms/global';
import { useToken } from '@hooks/api/common';

const WithToken = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
): React.FC<P> => {
  return function ComponentWithToken(props) {
    const [isVerified, setIsVerified] = useState(false);
    const { data: token } = useToken();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const setCallbackUrl = useSetRecoilState(callbackUrlState);
    useEffect(() => {
      if (token === null) {
        localStorage.removeItem(GLOBAL.REFRESH_TOKEN_EXPIRED);
        localStorage.removeItem(GLOBAL.REFRESH_TOKEN);
        localStorage.removeItem(GLOBAL.ACCESS_TOKEN_EXPIRED);
        localStorage.removeItem(GLOBAL.ACCESS_TOKEN);
        setCallbackUrl(pathname);
        navigate(ROUTES.AUTH.SIGNIN);
      } else {
        setIsVerified(true);
      }
    }, [token, pathname, setCallbackUrl, navigate]);
    if (isVerified) {
      return <Component {...props} />;
    }
    return null;
  };
};

export default WithToken;
