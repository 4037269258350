import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useS3Information, useS3UsageChart } from '@hooks/api/optimization/s3';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { DetailLinkIcon } from '@components/table/table.styled';
import { ROUTES } from '@routes';
import { byteToGib, ValueFormatter } from '@utils';
import StorageFilter from './StorageFilter';

const StorageTable: React.FC = () => {
  const { data, onPageChange, pageSize } = useS3Information();
  const { data: chartData } = useS3UsageChart();

  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.storage.bucket', { context: 'name' }),
        accessor: 'BucketName' as const,
        maxWidth: 464,
        disableSortBy: true,
      },
      {
        Header: t('label.storage.objects'),
        accessor: 'NumberOfObjects' as const,
        align: 'right',
        minWidth: 104,
        Cell: ({ value }) => ValueFormatter.toLocaleString(value, 0),
      },
      {
        Header: `${t('label.common.size')} (GiB)`,
        accessor: 'Size' as const,
        align: 'right',
        minWidth: 120,
        Cell: ({ value }) => {
          if (typeof value === 'number') {
            return ValueFormatter.toLocaleString(byteToGib(value));
          }
          return '-';
        },
      },
      {
        Header: t('label.common.modified', { context: 'last' }),
        accessor: 'LastModified' as const,
        minWidth: 152,
        Cell: ({ value }) =>
          ValueFormatter.toDateString(value, 'YYYY-MM-DD (hh:mm)') || '-',
      },
      {
        Header: `${t('label.common.cost')} (${commonT('label.days', {
          count: 30,
        })})`,
        accessor: 'CostInfo' as const,
        align: 'right',
        minWidth: 112,
        Cell: ({ value }) =>
          ValueFormatter.toCurrency(_.get(value, 'AmazonS3')) || '-',
      },
      {
        Header: t('label.common.savings'),
        accessor: 'MonthlySaving' as const,
        align: 'right',
        minWidth: 112,
        Cell: ({ value }) => ValueFormatter.toCurrency(value) || '-',
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailLinkIcon
            to={`${ROUTES.DETAILS.STORAGE}/${row.original.BucketName}`}
            requireCUR
          />
        ),
      },
    ],
    [t, commonT],
  );

  return (
    <DataPaginationTable
      columnInfo={columnInfo}
      data={data?.body?.items}
      totalCount={data?.body?.totalCount}
      onPageChange={onPageChange}
      pageSize={pageSize}
      FilterElement={StorageFilter}
      CSVUrl="s3"
      enableFilter
      enableSort
      LastUpdate={chartData.extras.LatestUpdate}
    />
  );
};

export default StorageTable;
