import axios from '@utils/axios';
import { generateHash, treeShakeObject } from '@utils';
import { API_URL } from '@constants';

const getById = async (id: string): Promise<CCQueryResponse<Company>> => {
  const { data } = await axios.get(`/companies/${id}`);
  return data;
};

const getCompanies = async (): Promise<CCQueryResponse<Company>> => {
  const { data } = await axios.get(`${API_URL.COMPANIES}`);
  return data;
};

const registerCompany = async (
  FirstName: string,
  LastName: string,
  CompanyName: string,
  Email: string,
  Password: string,
  PromotionRaw: string | null,
  ResellerRaw: string | null,
): Promise<CCQueryResponse<LoginResponse>> => {
  const Promotion = PromotionRaw || undefined;
  const Reseller = ResellerRaw || undefined;
  const { data } = await axios.post(`${API_URL.COMPANIES}/register`, {
    UID: generateHash(),
    FirstName,
    LastName,
    CompanyName,
    Email,
    Password,
    Promotion,
    Reseller,
  });
  return data;
};

const createCompany = async (
  name: string,
  email: string,
  // phone: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.post(`${API_URL.COMPANIES}`, {
    id: generateHash(),
    name,
    email,
    // phone,
  });
  return data;
};

const updateCompany = async (
  original: Company,
  changes: Partial<Company>,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.put(`${API_URL.COMPANIES}`, {
    ...treeShakeObject(original),
    ...changes,
  });
  return data;
};

const deleteCompany = async (
  companyId: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.delete(`/companies/${companyId}`);
  return data;
};

const verifyCompany = async (
  id: string,
): Promise<CCQueryResponse<{ access_token: string }>> => {
  const { data } = await axios.post(`${API_URL.COMPANIES}/verify/${id}`);
  return data;
};

const getCompanyExternal = async (
  id: string,
  token: string,
): Promise<CCQueryResponse<Company>> => {
  const { data } = await axios.get(`/companies/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export default {
  registerCompany,
  createCompany,
  getById,
  getCompanies,
  updateCompany,
  deleteCompany,
  verifyCompany,
  getCompanyExternal,
};
