/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import {
  GrmtAxisDefaultProps,
  GrmtTooltipItem,
  GrmtTooltipWrapper,
} from '@components/chart';
import {
  DotLegendItem,
  DotLegendWrapper,
  LegendWithPercent,
} from '@components/styled';
import { useMonthToDate } from '@hooks/api/overview';
import { bluegrey, primary, secondary, tertiary } from '@theme/colors';
import { getPercentageProportion, ValueFormatter } from '@utils';
import NoChartData from '@components/chart/overview/NoChartData';

type UpToDateChartData = {
  Last: number | null;
  This: number | null;
  Forecast: number | null;
};

type UpToDateExtra = {
  Last: number | null;
  This: number | null;
  Forecast: number | null;
  Percent: number;
};

const MonthToDateChart: React.FC = () => {
  const { t } = useTranslation('overview');
  const [chartData, setChartData] = useState<Array<UpToDateChartData>>([]);
  const [extraData, setExtraData] = useState<UpToDateExtra>({
    Last: 0,
    This: 0,
    Forecast: 0,
    Percent: 0,
  });
  const { data: response } = useMonthToDate();
  useEffect(() => {
    if (response?.items?.length && response.items[0]) {
      const { LastUsageResults, UsageResults, ForecastResults } =
        response.items[0];
      const daysInMonth = Math.max(
        moment().subtract(1, 'months').daysInMonth(),
        moment().daysInMonth(),
      );
      const todayOffset = Math.max(
        Number(moment(response?.extras?.Now).format('DD')) - 1,
        0,
      );
      const data: Array<UpToDateChartData> = [];
      for (let i = 0; i < daysInMonth; i += 1) {
        const lastMonth = LastUsageResults?.find(
          (v) => Number(moment(v.Date).format('DD')) === i + 1,
        );
        const thisMonth = UsageResults?.find(
          (v) => Number(moment(v.Date).format('DD')) === i + 1,
        );
        const forecasted = ForecastResults?.find(
          (v) => Number(moment(v.Date).format('DD')) === i + 1,
        );
        const lastMonthCost =
          typeof lastMonth?.Cost === 'number' ? lastMonth?.Cost : null;
        const forecastedCost =
          typeof forecasted?.Cost === 'number' ? forecasted?.Cost : null;
        let thisMonthCost =
          typeof thisMonth?.Cost === 'number' ? thisMonth.Cost : null;
        if (i === todayOffset) {
          if (forecasted) {
            thisMonthCost =
              typeof forecasted?.Cost === 'number' ? forecasted.Cost : null;
          } else if (thisMonth) {
            thisMonthCost =
              typeof thisMonth?.Cost === 'number' ? thisMonth.Cost : null;
          }
        }
        data.push({
          Last: lastMonthCost,
          This: thisMonthCost,
          Forecast: forecastedCost,
        });
      }
      setChartData(data);
    }
  }, [response?.items]);
  useEffect(() => {
    if (response?.items?.length && response.items[0]) {
      const { LastUsageResults, UsageResults, ForecastResults } =
        response.items[0];
      const lastMonthCost = _.maxBy(LastUsageResults, (v) =>
        Number(v?.Cost),
      )?.Cost;
      let thisMonthCost = _.maxBy(UsageResults, (v) => Number(v?.Cost))?.Cost;
      const forecastedCost = _.maxBy(ForecastResults, (v) =>
        Number(v?.Cost),
      )?.Cost;
      if (
        typeof thisMonthCost === 'number' &&
        ForecastResults?.length &&
        thisMonthCost < ForecastResults[0].Cost
      ) {
        thisMonthCost = ForecastResults[0].Cost;
      }
      setExtraData({
        Last: typeof lastMonthCost === 'number' ? lastMonthCost : null,
        This: typeof thisMonthCost === 'number' ? thisMonthCost : null,
        Forecast: typeof forecastedCost === 'number' ? forecastedCost : null,
        Percent: Number(
          getPercentageProportion(
            forecastedCost - lastMonthCost,
            lastMonthCost,
            0.0,
          ),
        ),
      });
    }
  }, [response?.items]);
  if (!response?.items?.length || !chartData.length) {
    return <NoChartData />;
  }
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      columns={20}
    >
      <Grid item xs={15}>
        <DotLegendWrapper>
          <DotLegendItem
            color={bluegrey[300]}
            label={t('label.monthlyBilling.lastMonth')}
          />
          <DotLegendItem
            color={primary[600]}
            label={t('label.monthlyBilling.thisMonth')}
          />
          <DotLegendItem
            color={secondary[500]}
            label={t('label.monthlyBilling.prediction')}
          />
        </DotLegendWrapper>
        <ResponsiveContainer width="100%" height={352}>
          <LineChart
            data={chartData}
            margin={{ top: 8, bottom: 8, left: 8, right: 8 }}
          >
            <XAxis
              interval={1}
              tickFormatter={(v, idx) => String(idx * 2 + 1)}
              {...GrmtAxisDefaultProps}
            />
            <YAxis
              scale="linear"
              domain={[0, (dataMax: number) => (dataMax / 500 + 1) * 500]}
              {...GrmtAxisDefaultProps}
            />
            <CartesianGrid vertical={false} stroke="#F0F0F7" />
            <Tooltip
              content={({ active, payload, label }) => (
                <GrmtTooltipWrapper
                  active={active}
                  payload={payload}
                  label={label + 1}
                >
                  {payload?.length &&
                    payload.map((item) => (
                      <GrmtTooltipItem
                        key={`tooltip_${item.name}_${label}`}
                        dotColor={item.color}
                        name={item.name}
                        value={ValueFormatter.toCurrency(item.value)}
                      />
                    ))}
                </GrmtTooltipWrapper>
              )}
            />
            <Line
              type="monotone"
              name={t('label.monthlyBilling.lastMonth')}
              dataKey="Last"
              stroke={bluegrey[300]}
              strokeWidth={3}
              dot={false}
              connectNulls
            />
            <Line
              type="monotone"
              name={t('label.monthlyBilling.prediction')}
              dataKey="Forecast"
              stroke={secondary[500]}
              strokeWidth={3}
              dot={false}
              connectNulls
            />
            <Line
              type="monotone"
              name={t('label.monthlyBilling.thisMonth')}
              dataKey="This"
              stroke={primary[600]}
              strokeWidth={3}
              dot={false}
              connectNulls
            />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item justifyContent="space-between" xs={4}>
        <Box borderLeft="2px solid #5C5C66" pl={2}>
          <Typography variant="subtitle2">
            {ValueFormatter.toCurrency(extraData.Last) ||
              t('label.monthlyBilling.noCUR')}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {t('label.monthlyBilling.lastMonthCost')}
          </Typography>
        </Box>
        <Box borderLeft="2px solid #5C5C66" mt={7} pl={2}>
          <Typography variant="subtitle2">
            {ValueFormatter.toCurrency(extraData.This) ||
              t('label.monthlyBilling.noCUR')}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {t('label.monthlyBilling.monthToDate')}
          </Typography>
        </Box>
        <Box
          borderLeft={`2px solid ${
            Number(extraData.Percent) === 0
              ? bluegrey[600]
              : extraData.Percent > 0.0
              ? secondary[500]
              : tertiary[300]
          }`}
          color={
            Number(extraData.Percent) === 0
              ? bluegrey[600]
              : extraData.Percent > 0.0
              ? secondary[500]
              : tertiary[300]
          }
          mt={7}
          pl={2}
        >
          <LegendWithPercent
            label={
              ValueFormatter.toCurrency(extraData.Forecast) ||
              t('label.monthlyBilling.noCUR')
            }
            percent={Number(extraData.Percent)}
          />
          <Typography variant="caption" color="textSecondary">
            {t('label.monthlyBilling.estimated')}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(MonthToDateChart);
