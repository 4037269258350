import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  FilterGrid,
  FilterGridItem,
  FilterProps,
  FilterSelect,
  FilterWrapper,
} from '@components/filter';
import { useAnomalyThresholdAutoComplete } from '@hooks/api/optimization/anomaly';
import { useAWSAccountHelper, useAWSHelper } from '@hooks/helper';
import { useFilterFormik } from '@hooks/common';

type AnomalySettingsFilterForm = {
  AccountId: Array<string>;
  Region: Array<string>;
  Service: Array<string>;
};

const useAnomalySettingsFilterForm =
  (): UseValidation<AnomalySettingsFilterForm> => ({
    initialValues: {
      AccountId: [],
      Region: [],
      Service: [],
    },
    validationSchema: Yup.object({
      AccountId: Yup.array().of(Yup.string()),
      Region: Yup.array().of(Yup.string()),
      Service: Yup.array().of(Yup.string()),
    }).defined(),
  });

const AnomalySettingsFilter: React.FC<FilterProps> = ({
  onClose,
  onFilterChange,
  previousValues,
}) => {
  const { t } = useTranslation('optimization');
  const { getAWSAccountNickname } = useAWSAccountHelper();
  const { getAWSRegionName } = useAWSHelper();
  const { data } = useAnomalyThresholdAutoComplete();
  const { initialValues, validationSchema } = useAnomalySettingsFilterForm();
  const { values, handleArrayChange, handleBlur, handleSubmit } =
    useFilterFormik(
      {
        initialValues: previousValues || initialValues,
        validationSchema,
        onSubmit: (v) => {
          onFilterChange(v);
          onClose();
        },
      },
      data?.items,
    );
  if (!data?.items?.length) {
    return null;
  }
  const { AccountId, Region, Service } = data.items[0];
  return (
    <FilterWrapper onClose={onClose} onSubmit={handleSubmit}>
      <FilterGrid>
        <FilterGridItem
          label={`${t('label.common.accountId')} (${t(
            'label.common.nickname',
          )})`}
        >
          <FilterSelect
            name="AccountId"
            value={values.AccountId}
            onChange={(e) => handleArrayChange('AccountId', e.target.value)}
            onBlur={handleBlur}
            renderValue={() => values.AccountId.join(', ')}
            selected={values.AccountId}
            candidate={AccountId}
            formatter={getAWSAccountNickname}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.region')}>
          <FilterSelect
            name="Region"
            value={values.Region}
            onChange={(e) => handleArrayChange('Region', e.target.value)}
            onBlur={handleBlur}
            renderValue={() => values.Region.join(', ')}
            selected={values.Region}
            candidate={Region}
            formatter={getAWSRegionName}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.service')}>
          <FilterSelect
            name="Service"
            value={values.Service}
            onChange={(e) => handleArrayChange('Service', e.target.value)}
            onBlur={handleBlur}
            renderValue={() => values.Service.join(', ')}
            selected={values.Service}
            candidate={Service}
          />
        </FilterGridItem>
      </FilterGrid>
    </FilterWrapper>
  );
};

export default AnomalySettingsFilter;
