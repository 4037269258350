import React, { useMemo, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import { Box, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { bluegrey } from '@theme/colors';
import { ValueFormatter } from '@utils';
import { DotLegendItem } from '@components/styled';
import { ChartCoverBox } from '@components/styled/noData';

type CoverageChartProps = {
  data: [{ value: number }, { value: number }, { value: number }];
  active?: 0 | 1 | 2;
};

const COLORS = ['#FF7976', '#57CAEB', '#9594FF'] as const;
const DISABLED_COLORS = [
  ['', bluegrey[300], bluegrey[200]],
  [bluegrey[300], '', bluegrey[200]],
  [bluegrey[300], bluegrey[200]],
] as const;

const RADIAN = Math.PI / 180;

const PlannerCoverageChart: React.FC<CoverageChartProps> = ({
  data,
  active = 0,
}) => {
  const { t } = useTranslation('planner');
  const { t: commonT } = useTranslation('common');
  const [isEmpty, setEmpty] = useState(false);
  const formatData = useMemo(() => {
    if (!data[0]?.value && !data[1]?.value && !data[2]?.value) {
      setEmpty(true);
      return [{ value: 10 }, { value: 10 }, { value: 10 }];
    }
    return data;
  }, [data]);
  return (
    <Box>
      <Typography color="textSecondary" align="center">
        {t('label.coverage')} ({commonT('label.months', { count: 12 })})
      </Typography>
      <ChartCoverBox isEmpty={isEmpty}>
        <ResponsiveContainer width="100%" aspect={1.3}>
          <PieChart width={200} height={200}>
            <defs>
              <filter id="pieShadow">
                <feGaussianBlur
                  in="BackgroundAlpha"
                  stdDeviation={4}
                  result="blur"
                />
                <feOffset in="blur" dx={2} dy={2} result="offsetBlur" />
                <feMerge>
                  <feMergeNode in="offsetBlur" />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
            </defs>
            <Pie
              data={formatData}
              dataKey="value"
              outerRadius={100}
              startAngle={90}
              endAngle={-270}
              cx="50%"
              cy="50%"
              labelLine={false}
              isAnimationActive={false}
              label={({
                cx,
                cy,
                midAngle,
                innerRadius,
                outerRadius,
                index,
                fill,
                percent,
              }) => {
                if (percent < 0.2) {
                  const sin = Math.sin(-RADIAN * midAngle);
                  const cos = Math.cos(-RADIAN * midAngle);
                  const offset = index === active ? 12 : 0;
                  const dir = index === 2 && percent < 0.05 ? -1 : 1;
                  const sx = cx + (outerRadius + offset) * cos;
                  const sy = cy + (outerRadius + offset) * sin;
                  const mx = cx + (outerRadius + offset + 18) * cos;
                  const my = cy + (outerRadius + offset + 18) * sin;
                  const ex = mx + (cos >= 0 ? 1 : -1) * dir * 16;
                  const ey = my;
                  const textAnchor = cos * dir >= 0 ? 'start' : 'end';
                  return (
                    <g>
                      <path
                        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                        stroke={index === active ? fill : bluegrey[400]}
                        fill="none"
                      />
                      <text
                        x={ex + (cos >= 0 ? 1 : -1) * dir * 8}
                        y={ey}
                        dy={6}
                        fill={bluegrey[600]}
                        textAnchor={textAnchor}
                        fontSize={14}
                        fontWeight={500}
                      >
                        {ValueFormatter.toPercent(percent * 100)}
                      </text>
                    </g>
                  );
                }
                const radius = innerRadius + (outerRadius - innerRadius) * 0.55;
                const tx = cx + radius * Math.cos(-midAngle * RADIAN);
                const ty = cy + radius * Math.sin(-midAngle * RADIAN);
                return (
                  <g>
                    <text
                      x={tx}
                      y={ty}
                      fill={index === active ? '#fff' : bluegrey[600]}
                      textAnchor="middle"
                      dominantBaseline="middle"
                      fontSize={16}
                      fontWeight={500}
                    >
                      {ValueFormatter.toPercent(percent * 100)}
                    </text>
                  </g>
                );
              }}
              activeIndex={active}
              activeShape={({
                cx,
                cy,
                midAngle,
                innerRadius,
                outerRadius,
                startAngle,
                endAngle,
                fill,
              }) => {
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (innerRadius + 4) * cos;
                const sy = cy + (innerRadius + 4) * sin;
                return (
                  <Sector
                    cx={sx}
                    cy={sy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius + 4}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                    // filter="url(#pieShadow)"
                  />
                );
              }}
            >
              {data.map((entry, idx) => (
                <Cell
                  key={`cell_pie_cv_${idx.toString()}`}
                  stroke="none"
                  fill={
                    idx === active ? COLORS[idx] : DISABLED_COLORS[active][idx]
                  }
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </ChartCoverBox>
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          '> .cc-legend-dot-item:not(:first-of-type)': {
            ml: 3,
          },
        }}
      >
        <DotLegendItem
          color={active === 0 ? COLORS[0] : DISABLED_COLORS[active][0]}
          label={t('label.od')}
        />
        <DotLegendItem
          color={active === 1 ? COLORS[1] : DISABLED_COLORS[active][1]}
          label="RI"
        />
        <DotLegendItem
          color={active === 2 ? COLORS[2] : DISABLED_COLORS[active][2]}
          label="SP"
        />
      </Box>
    </Box>
  );
};
PlannerCoverageChart.propTypes = {
  active: PropTypes.oneOf([0, 1, 2]),
};
PlannerCoverageChart.defaultProps = {
  active: 0,
};

export default React.memo(PlannerCoverageChart);
