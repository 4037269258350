import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import { AWSAccountService } from '@api/service';
import { companyIdState, selectedAccountState } from '@atoms/global';
import { GLOBAL, QUERY_KEY } from '@constants';
import _ from 'lodash';

export default (suspense = true) => {
  const { pathname } = useLocation();
  const activeToken = useMemo<string | null>(
    () => localStorage.getItem(GLOBAL.ACCESS_TOKEN),
    [pathname],
  );
  const companyId = useRecoilValue(companyIdState);
  const [selected, setSelected] = useRecoilState(selectedAccountState);
  const query = useQuery(
    [QUERY_KEY.CURRENT.AWS, companyId],
    ({ queryKey }) => {
      if (queryKey[1]) {
        return AWSAccountService.getByCompanyId(queryKey[1]);
      }
      return undefined;
    },
    {
      cacheTime: 15000,
      suspense,
      enabled: Boolean(activeToken) && Boolean(companyId),
    },
  );
  useEffect(() => {
    if (selected.length) {
      if (query?.data?.items?.length) {
        const clone = _.cloneDeep(selected);
        const mismatched: Array<number> = [];
        clone.forEach((id, idx) => {
          if (query.data.items.findIndex((v) => v.id === id) === -1) {
            mismatched.push(idx);
          }
        });
        mismatched.forEach((v) => {
          clone.splice(v, 1);
        });
        setSelected(clone);
      } else {
        setSelected([]);
      }
    } else if (query?.data?.items?.length) {
      setSelected(query.data.items.map((v) => v.id));
    }
  }, [query?.data?.items, selected.length]);
  return query;
};
