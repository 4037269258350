import React, { useMemo } from 'react';
import {
  Box,
  experimental_sx as sx,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import {
  TabsListUnstyled,
  TabsUnstyled,
  TabUnstyled,
  tabUnstyledClasses,
} from '@mui/base';
import { ArrowForwardIos as ArrowForwardIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { GrmtTableColumnInfo, SimpleTable } from '@components/table';
import { ValueFormatter } from '@utils';
import { tertiary } from '@theme/colors';
import { useCurrentInfo } from '@hooks/api/common';
import { useAWSAccountHelper } from '@hooks/helper';

const SavingsTabs = styled(TabsUnstyled)(
  sx({
    display: 'flex',
  }),
);

const SavingsTabsList = styled(TabsListUnstyled)(
  sx({
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '240px',
  }),
);

const SavingsTab = styled(TabUnstyled)(
  sx({
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 4,
    py: 5,
    background: '#fff',
    border: 'none',
    cursor: 'pointer',
    borderRight: '1px solid #F0F0F7',
    ':not(:last-child)': {
      borderBottom: '1px solid #F0F0F7',
    },
    '& .costSummary': {
      color: tertiary[500],
    },
    [`&.${tabUnstyledClasses.selected}`]: {
      borderRight: '1px solid transparent',
      '& .costSummary': {
        color: 'primary.main',
      },
    },
  }),
);

const DUMMY = [
  {
    UID: 'e3897c5d155df71b3c0e51d86eb57399aa5f4e99ed4b8ff6c4acc8723ed3a384',
    CaseId: 'AWS-EC2-EBS-0001',
    CompanyId: '00001',
    AWSAccountId: '328341376253',
    Category: 'UnusedResources',
    ResourceId: 'vol-0e617bbe6eafea314',
    Title: 'EBS volume not in-use',
    Description: 'EBS volume is not in-use or detached',
    ServiceName: 'AmazonEC2',
    MonthlyCostSaving: 39.9,
    Details:
      'EBS volume (Volume ID = vol-0e617bbe6eafea314) is not attached. You can save about $39.9/month if you remove it.',
    Ack: true,
    Created: '2021-01-18T02:00:00.239000+00:00',
    Acknowledged: '2021-09-08T05:18:09.879000',
    Importance: 2,
    LastCURDate: '1970-01-01T00:00:00+00:00',
  },
  {
    UID: 'a1349295a20e3e3f6b9cd36a7c0aa25900633fc15bf6c057d1c71c811d046553',
    CaseId: 'AWS-EC2-EBS-0001',
    CompanyId: '00001',
    AWSAccountId: '328341376253',
    ResourceId: 'vol-036b2a11dd4801045',
    Title: 'EBS volume not in-use',
    Description: 'EBS volume is not in-use or detached',
    ServiceName: 'AmazonEC2',
    MonthlyCostSaving: 26.1,
    Details:
      'EBS volume (Volume ID = vol-036b2a11dd4801045) is not attached. You can save about $26.1/month if you remove it.',
    Ack: true,
    Created: '2020-06-25T11:00:00.691000+00:00',
    Acknowledged: '2021-09-08T05:18:09.919000',
    Importance: 2,
    LastCURDate: '1970-01-01T00:00:00+00:00',
    Category: 'UnusedResources',
  },
  {
    UID: 'bb6e00b40ebe41a5fd4869ffafa60a91eee4e5518661b721315e702ccc73261a',
    CaseId: 'AWS-EC2-0007',
    CompanyId: '00001',
    AWSAccountId: '328341376253',
    Category: 'Notifications',
    ResourceId:
      'bdbca6fc166ff5a3a9964f2c395a7017980ba45c78c9fc3210403db5d8242a9e',
    Title: 'Unused Elastic Load Balancers',
    Description:
      'Checks for all Elastic Load Balancers that are not associated with any EC2 instances',
    ServiceName: 'AmazonEC2',
    MonthlyCostSaving: 16.200000000000003,
    Details:
      'Unused ELB detected. Region - us-west-2, ELB name - messagefront-viki. You can save $16 per month. \n',
    Ack: true,
    Created: '2020-06-26T10:02:56.922000+00:00',
    Acknowledged: '2021-08-02T04:29:57.274000',
    Importance: 3,
    LastCURDate: '1970-01-01T00:00:00+00:00',
  },
  {
    UID: '2c2f936dba757e327d912a84984574ac3a97e88b40ab81de2aa9441924a69aad',
    CaseId: 'AWS-EC2-0007',
    CompanyId: '00001',
    AWSAccountId: '328341376253',
    Category: 'Notifications',
    ResourceId: 'cco-deploy-registry',
    Title: 'Unused Elastic Load Balancers',
    Description:
      'Checks for all Elastic Load Balancers that are not associated with any EC2 instances',
    ServiceName: 'AmazonEC2',
    MonthlyCostSaving: 16.200000000000003,
    Details:
      'Unused ELB detected. Region - ap-northeast-2, ELB name - cco-deploy-registry. You can save $16 per month. \n',
    Ack: false,
    Created: '2022-01-27T12:21:23.818000',
    Acknowledged: '2022-01-27T12:21:23.818000',
    Importance: 3,
    DetailsValue: {
      Text: 'Unused ELB detected. Region - REGION, ELB name - ELBNAME. You can save $MONTHLYSAVING per month. \n',
      REGION: 'ap-northeast-2',
      ELBNAME: 'cco-deploy-registry',
      MONTHLYSAVING: 16,
    },
    LastCURDate: '1970-01-01T00:00:00',
  },
  {
    UID: 'b162f81b2cb6b57ad96802751c2c70f0ddffd0139122cf0d7843a13ec2661fd1',
    CaseId: 'AWS-EC2-0007',
    CompanyId: '00001',
    AWSAccountId: '328341376253',
    Category: 'Notifications',
    ResourceId: 'cco-wp-elb',
    Title: 'Unused Elastic Load Balancers',
    Description:
      'Checks for all Elastic Load Balancers that are not associated with any EC2 instances',
    ServiceName: 'AmazonEC2',
    MonthlyCostSaving: 16.200000000000003,
    Details:
      'Unused ELB detected. Region - us-west-2, ELB name - cco-wp-elb. You can save $16 per month. \n',
    Ack: false,
    Created: '2022-01-30T18:51:42.103000',
    Acknowledged: '2022-01-30T18:51:42.103000',
    Importance: 3,
    DetailsValue: {
      Text: 'Unused ELB detected. Region - REGION, ELB name - ELBNAME. You can save $MONTHLYSAVING per month. \n',
      REGION: 'us-west-2',
      ELBNAME: 'cco-wp-elb',
      MONTHLYSAVING: 16,
    },
    LastCURDate: '1970-01-01T00:00:00',
  },
];

const PossibleSavingsDummy: React.FC = () => {
  const { t } = useTranslation('overview');
  const { t: optimizationT } = useTranslation('optimization');
  const { navigateToCur } = useAWSAccountHelper();
  const { curLoading } = useCurrentInfo();
  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: optimizationT('label.intelligence.category'),
        accessor: 'Category' as const,
        width: 200,
        maxWidth: 200,
      },
      {
        Header: optimizationT('label.common.resourceId'),
        accessor: 'ResourceId' as const,
        width: 230,
        maxWidth: 230,
      },
      {
        Header: optimizationT('label.common.service'),
        accessor: 'ServiceName' as const,
        width: 170,
        maxWidth: 170,
      },
      {
        Header: optimizationT('label.common.savings'),
        accessor: 'MonthlyCostSaving' as const,
        align: 'right',
        width: 185,
        maxWidth: 185,
        Cell: ({ value }) => ValueFormatter.toCurrency(value),
      },
      {
        Header: optimizationT('label.common.details'),
        align: 'center',
        width: 90,
        maxWidth: 90,
        Cell: () => (
          <IconButton size="small" color="primary">
            <ArrowForwardIcon fontSize="inherit" />
          </IconButton>
        ),
      },
    ],
    [optimizationT],
  );

  return (
    <Box position="relative">
      <SavingsTabs value="Total" orientation="vertical">
        <SavingsTabsList aria-orientation="vertical">
          <SavingsTab value="Total">
            <Typography variant="body2" align="left">
              {t('tab.possibleSavings.total')}
            </Typography>
            <Typography className="costSummary" variant="subtitle2">
              {ValueFormatter.toCurrency(3012)}
            </Typography>
          </SavingsTab>
          <SavingsTab value="RightSizing">
            <Typography variant="body2" align="left">
              {t('tab.possibleSavings.utilization')}
            </Typography>
            <Typography className="costSummary" variant="subtitle2">
              {ValueFormatter.toCurrency(1802)}
            </Typography>
          </SavingsTab>
          <SavingsTab value="PurchasingOptions">
            <Typography
              variant="body2"
              align="left"
              dangerouslySetInnerHTML={{
                __html: t('tab.possibleSavings.purchasing'),
              }}
            />
            <Typography className="costSummary" variant="subtitle2">
              {ValueFormatter.toCurrency(800)}
            </Typography>
          </SavingsTab>
          <SavingsTab value="UnusedResources">
            <Typography
              variant="body2"
              align="left"
              dangerouslySetInnerHTML={{
                __html: t('tab.possibleSavings.unused'),
              }}
            />
            <Typography className="costSummary" variant="subtitle2">
              {ValueFormatter.toCurrency(900)}
            </Typography>
          </SavingsTab>
          <SavingsTab value="Others">
            <Typography variant="body2" align="left">
              {t('tab.possibleSavings.others')}
            </Typography>
            <Typography className="costSummary" variant="subtitle2">
              {ValueFormatter.toCurrency(500)}
            </Typography>
          </SavingsTab>
        </SavingsTabsList>
        <Box
          display="flex"
          flexGrow={1}
          p={7}
          minHeight={571}
          justifyContent="center"
          alignItems="center"
          role="tabpanel"
        >
          <SimpleTable columnInfo={columnInfo} data={DUMMY} />
        </Box>
      </SavingsTabs>
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={curLoading ? undefined : navigateToCur}
        sx={{
          background: 'rgba(255, 255, 255, 0.45)',
          backdropFilter: 'blur(4px)',
          cursor: curLoading ? 'default' : 'pointer',
        }}
      >
        <Typography variant="subtitle2">
          {curLoading
            ? t('label.savingsSummary.curLoading')
            : t('label.savingsSummary.configure')}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(PossibleSavingsDummy);
