import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEC2CoverageAutoComplete } from '@hooks/api/optimization/ec2';
import { useFilterFormik } from '@hooks/common';
import { useAWSHelper } from '@hooks/helper';
import {
  FilterGrid,
  FilterGridItem,
  FilterProps,
  FilterSelect,
  FilterWrapper,
} from '@components/filter';

type ComputeCoverageFilterForm = {
  Region: Array<string>;
  InstanceId: string;
  InstanceType: Array<string>;
  Tag: string;
  RICoverage: string;
  SavingsPlanCoverage: string;
};

const useComputeCoverageFilterForm =
  (): UseValidation<ComputeCoverageFilterForm> => {
    const { t } = useTranslation('validation');
    return {
      initialValues: {
        Region: [],
        InstanceId: '',
        InstanceType: [],
        Tag: '',
        RICoverage: '',
        SavingsPlanCoverage: '',
      },
      validationSchema: Yup.object({
        Region: Yup.array().of(Yup.string()),
        InstanceId: Yup.string(),
        InstanceType: Yup.array().of(Yup.string()),
        Tag: Yup.string(),
        RICoverage: Yup.string().matches(
          /^[0-9]+([.][0-9]+)?$/,
          t('optimization.decimal'),
        ),
        SavingsPlanCoverage: Yup.string().matches(
          /^[0-9]+([.][0-9]+)?$/,
          t('optimization.decimal'),
        ),
      }).defined(),
    };
  };

const ComputeCoverageFilter: React.FC<FilterProps> = ({
  onClose,
  onFilterChange,
  previousValues,
}) => {
  const { t } = useTranslation('optimization');
  const { getAWSRegionName } = useAWSHelper();
  const { data } = useEC2CoverageAutoComplete();
  const { initialValues, validationSchema } = useComputeCoverageFilterForm();
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleArrayChange,
    handleBlur,
    handleSubmit,
  } = useFilterFormik(
    {
      initialValues: previousValues || initialValues,
      validationSchema,
      onSubmit: (v) => {
        onFilterChange(v);
        if (onClose) {
          onClose();
        }
      },
    },
    data?.items,
  );
  if (!data?.items?.length) {
    return null;
  }
  const { InstanceType, Region } = data.items[0];
  return (
    <FilterWrapper onClose={onClose} onSubmit={handleSubmit} isValid={isValid}>
      <FilterGrid>
        <FilterGridItem label={t('label.common.region')}>
          <FilterSelect
            name="Region"
            value={values.Region}
            onChange={(e) => handleArrayChange('Region', e.target.value)}
            onBlur={handleBlur}
            renderValue={() =>
              values.Region.map((v: string) => getAWSRegionName(v)).join(', ')
            }
            selected={values.Region}
            candidate={Region}
            formatter={getAWSRegionName}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.instanceId')}>
          <TextField
            name="InstanceId"
            value={values.InstanceId}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.instanceType')}>
          <FilterSelect
            name="InstanceType"
            value={values.InstanceType}
            onChange={(e) => handleArrayChange('InstanceType', e.target.value)}
            onBlur={handleBlur}
            renderValue={() => values.InstanceType.join(', ')}
            selected={values.InstanceType}
            candidate={InstanceType}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.tags')}>
          <TextField
            name="Tag"
            value={values.Tag}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.ri_coverage')}>
          <TextField
            name="RICoverage"
            value={values.RICoverage}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{
              inputProps: {
                style: { textAlign: 'right' },
              },
              startAdornment: t('label.filter.under')?.length ? (
                <InputAdornment position="start">
                  {t('label.filter.under')}
                </InputAdornment>
              ) : undefined,
              endAdornment: (
                <InputAdornment position="end">
                  {t('label.filter.percent')}
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.RICoverage && errors.RICoverage)}
            helperText={
              Boolean(touched.RICoverage && errors.RICoverage) &&
              String(errors.RICoverage)
            }
            fullWidth
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.sp_coverage')}>
          <TextField
            name="SavingsPlanCoverage"
            value={values.SavingsPlanCoverage}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{
              inputProps: {
                style: { textAlign: 'right' },
              },
              startAdornment: t('label.filter.under')?.length ? (
                <InputAdornment position="start">
                  {t('label.filter.under')}
                </InputAdornment>
              ) : undefined,
              endAdornment: (
                <InputAdornment position="end">
                  {t('label.filter.percent')}
                </InputAdornment>
              ),
            }}
            error={Boolean(
              touched.SavingsPlanCoverage && errors.SavingsPlanCoverage,
            )}
            helperText={
              Boolean(
                touched.SavingsPlanCoverage && errors.SavingsPlanCoverage,
              ) && String(errors.SavingsPlanCoverage)
            }
            fullWidth
          />
        </FilterGridItem>
      </FilterGrid>
    </FilterWrapper>
  );
};

export default ComputeCoverageFilter;
