import moment from 'moment';

export const parseStartOfUTC = (value: string | undefined): moment.Moment => {
  const parsed = moment(value);
  return moment()
    .utc()
    .set('years', parsed.get('years'))
    .set('months', parsed.get('months'))
    .set('date', parsed.get('date'))
    .startOf('day');
};
