import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  Box,
  ButtonBase,
  Chip,
  chipClasses,
  ClickAwayListener,
  FormControlLabel,
  InputAdornment,
  inputAdornmentClasses,
  outlinedInputClasses,
  Radio,
  RadioGroup,
  svgIconClasses,
  TextField,
  Typography,
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { SuspenseBox } from '@components/styled/suspense';
import { ceTagKeysState, ceUsageState } from '@atoms/aws';
import { tertiary } from '@theme/colors';
import { useGetUsageAutoComplete } from '@hooks/api/aws/ce';
import TagKeySelector from './TagKeySelector';
import TagValueSelector from './TagValueSelector';
import { FieldLabel, UsagePopper } from './styled';

type TagFilterProps = {
  filter: CEUsageRequest;
  setFilter: React.Dispatch<React.SetStateAction<CEUsageRequest>>;
};
export default function TagFilter({ filter, setFilter }: TagFilterProps) {
  const { t } = useTranslation('usage');
  const [isExclude, setIsExclude] = useState(false);
  const [isKeySelected, setKeySelected] = useState(false);
  const [pendingKeys, setPendingKeys] = useState<string[]>([]);
  const [pendingValues, setPendingValues] = useState<
    Array<CEUsageTagItemInternal>
  >([]);
  const setTagKeys = useSetRecoilState(ceTagKeysState);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const popperId = open ? 'usage-popper-TAG' : undefined;
  const { data: response } = useGetUsageAutoComplete();
  const handleOpen = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setPendingKeys(
        filter.TagsExclude?.map((v) => v.TagKey) ??
          filter.Tags?.map((v) => v.TagKey) ??
          [],
      );
      setPendingValues(
        _.flattenDeep(
          filter.TagsExclude?.map((v) =>
            v.Items.map((k) => ({ TagKey: v.TagKey, Value: k })),
          ) ??
            filter.Tags?.map((v) =>
              v.Items.map((k) => ({ TagKey: v.TagKey, Value: k })),
            ) ??
            [],
        ),
      );
      setIsExclude(typeof filter.TagsExclude !== 'undefined');
      setAnchorEl(e.currentTarget);
    },
    [filter.Tags, filter.TagsExclude],
  );
  const handleClose = useCallback(() => {
    setKeySelected(false);
    setAnchorEl(null);
  }, []);
  const handleTagKeysConfirm = useCallback(() => {
    setTagKeys(pendingKeys);
    setKeySelected(true);
  }, [pendingKeys, setTagKeys]);
  const handleTagValueChange = useCallback(
    (values: CEUsageTagItemInternal[]) => {
      setPendingValues(values);
      const items: Array<CEUsageTagItem> = [];
      values.forEach((v) => {
        let idx = items.findIndex((k) => k.TagKey === v.TagKey);
        if (idx < 0) {
          items.push({ TagKey: v.TagKey, Items: [] });
          idx = items.length - 1;
        }
        items[idx].Items.push(v.Value);
      });
      if (isExclude) {
        setFilter((v) => ({
          ...v,
          TagsExclude: !_.isEmpty(items) ? items : undefined,
          Tags: undefined,
        }));
      } else {
        setFilter((v) => ({
          ...v,
          Tags: !_.isEmpty(items) ? items : undefined,
          TagsExclude: undefined,
        }));
      }
    },
    [],
  );
  const applyChange = useCallback(() => {
    const items: Array<CEUsageTagItem> = [];
    pendingValues.forEach((v) => {
      let idx = items.findIndex((k) => k.TagKey === v.TagKey);
      if (idx < 0) {
        items.push({ TagKey: v.TagKey, Items: [] });
        idx = items.length - 1;
      }
      items[idx].Items.push(v.Value);
    });
    if (isExclude) {
      setFilter((v) => ({
        ...v,
        TagsExclude: !_.isEmpty(items) ? items : undefined,
        Tags: undefined,
      }));
    } else {
      setFilter((v) => ({
        ...v,
        Tags: !_.isEmpty(items) ? items : undefined,
        TagsExclude: undefined,
      }));
    }
    handleClose();
  }, [handleClose, isExclude, pendingValues, setFilter]);
  const values = useMemo(() => {
    return filter.TagsExclude ?? filter.Tags ?? [];
  }, [filter]);
  const setValues = useCallback(
    (newValue: CEUsageTagItem[], manualExclude?: boolean) => {
      const exclusion = manualExclude ?? isExclude;
      if (exclusion) {
        setFilter((v) => ({
          ...v,
          TagsExclude: newValue,
          Tags: undefined,
        }));
      } else {
        setFilter((v) => ({
          ...v,
          Tags: newValue,
          TagsExclude: undefined,
        }));
      }
    },
    [setFilter, isExclude],
  );
  const optionsOrigin = useMemo(() => {
    if (response?.items?.length) {
      return _.uniq(response.items[0].TagKeys ?? []);
    }
    return [];
  }, [response]);
  const placeholder = useMemo(() => {
    if (!optionsOrigin.length) {
      return t('filter.disabled', { name: t('filter.tag') });
    }
    if (values.length) {
      return t(`filter.${isExclude ? 'excluded' : 'included'}`, {
        count: _.flattenDeep(values.map((v) => v.Items)).length,
      });
    }
    return t('filter.selected_all', { name: t('filter.tag') });
  }, [values, optionsOrigin, isExclude, t]);
  const handleChangeExclusion = useCallback(
    (v: boolean) => {
      setIsExclude(v);
      setValues(values, v);
    },
    [values, setValues],
  );
  return (
    <>
      <FieldLabel>{t('filter.label', { name: t('filter.tag') })}</FieldLabel>
      <TextField
        placeholder={placeholder}
        InputProps={{
          onClick: handleOpen,
          endAdornment: (
            <InputAdornment position="end">
              {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </InputAdornment>
          ),
        }}
        fullWidth
        sx={(theme) => ({
          [`> .${outlinedInputClasses.root}`]: {
            cursor: 'pointer',
            pr: '8px',
            [`> .${outlinedInputClasses.input}`]: {
              cursor: 'pointer',
              userSelect: 'none',
              pr: 0,
              ...(Boolean(values.length) && {
                '&::placeholder': {
                  color: theme.palette.text.primary,
                },
                '&::-webkit-input-placeholder': {
                  color: theme.palette.text.primary,
                },
                '&::-ms-input-placeholder': {
                  color: theme.palette.text.primary,
                },
              }),
            },
            [`> .${inputAdornmentClasses.root}`]: {
              '> svg': {
                width: '20px',
                height: '20px',
                color: '#a0a1b2',
              },
            },
          },
        })}
      />
      <UsagePopper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box sx={{ p: '16px' }}>
              <RadioGroup
                value={isExclude}
                onChange={(e, v) => setIsExclude(v === 'true')}
                row
              >
                <FormControlLabel
                  control={<Radio color="tertiary" sx={{ p: 0, pr: '8px' }} />}
                  value={false}
                  label={t('filter.include')}
                  componentsProps={{
                    typography: {
                      variant: 'body2',
                    },
                  }}
                  sx={{ ml: 0 }}
                />
                <FormControlLabel
                  control={<Radio color="tertiary" sx={{ p: 0, pr: '8px' }} />}
                  value
                  label={t('filter.exclude')}
                  componentsProps={{
                    typography: {
                      variant: 'body2',
                    },
                  }}
                  sx={{ ml: 0 }}
                />
              </RadioGroup>
            </Box>
            <SuspenseBox>
              {isKeySelected ? (
                <TagValueSelector
                  onClose={handleClose}
                  onConfirm={applyChange}
                  values={pendingValues}
                  setValues={setPendingValues}
                />
              ) : (
                <TagKeySelector
                  onClose={handleClose}
                  onConfirm={handleTagKeysConfirm}
                  values={pendingKeys}
                  setValues={setPendingKeys}
                />
              )}
            </SuspenseBox>
          </div>
        </ClickAwayListener>
      </UsagePopper>
    </>
  );
}
