import { useQuery } from 'react-query';
import { GLOBAL, QUERY_KEY } from '@constants';

export default () =>
  useQuery(
    [QUERY_KEY.COMMON.TOKEN],
    async () =>
      new Promise<string | null>((resolve) => {
        const token = localStorage.getItem(GLOBAL.ACCESS_TOKEN);
        if (token !== null) {
          const tokenExp = Number(
            localStorage.getItem(GLOBAL.ACCESS_TOKEN_EXPIRED),
          );
          if (!Number.isNaN(tokenExp) && Date.now() < tokenExp * 1000) {
            resolve(token);
            return;
          }
        }
        resolve(null);
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: 60 * 1000,
      refetchIntervalInBackground: true,
    },
  );
