import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEC2SPUtilization } from '@hooks/api/optimization/ec2';
import { useAWSHelper } from '@hooks/helper';
import { useToggleWithValue } from '@hooks/common';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { CommittedDetailModal } from '@pages/private/planner/committed/table';
import { DetailButtonIcon } from '@components/table/table.styled';
import { ValueFormatter } from '@utils';
import ComputeSPUtilFilter from './ComputeSPUtilFilter';

const ComputeSPUtilTable: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { getOptimizationAWSRegion } = useAWSHelper();
  const { data, onPageChange, pageSize } = useEC2SPUtilization();
  const [selected, openDetail, toggleDetail] = useToggleWithValue();

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        minWidth: 140,
        maxWidth: 140,
        disableSortBy: true,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.common.instanceFamily'),
        accessor: 'InstanceFamily' as const,
        minWidth: 135,
        maxWidth: 135,
        disableSortBy: true,
      },
      {
        Header: t('label.common.cost', { context: 'committed' }),
        accessor: 'CommitmentCost' as const,
        Cell: ({ value, row }) => (
          <>
            {`${t('label.common.month', {
              context: 'last',
            })}: ${
              ValueFormatter.toCurrency(row.original.LastMonthCommitmentCost) ||
              '-'
            }`}
            <br />
            {`${t('label.common.month', {
              context: 'this',
            })}: ${ValueFormatter.toCurrency(value) || '-'}`}
          </>
        ),
      },
      {
        Header: t('label.common.cost', { context: 'unused' }),
        accessor: 'UnusedCost' as const,
        Cell: ({ value, row }) => (
          <>
            {`${t('label.common.month', {
              context: 'last',
            })}: ${
              ValueFormatter.toCurrency(row.original.LastMonthUnusedCost) || '-'
            }`}
            <br />
            {`${t('label.common.month', {
              context: 'this',
            })}: ${ValueFormatter.toCurrency(value) || '-'}`}
          </>
        ),
      },
      {
        Header: t('label.header.utilization'),
        accessor: 'Utilization' as const,
        minWidth: 170,
        maxWidth: 170,
        Cell: ({ value, row }) => (
          <>
            {`${t('label.common.month', {
              context: 'last',
            })}: ${
              ValueFormatter.toPercent(row.original.LastMonthUtilization) || '-'
            }`}
            <br />
            {`${t('label.common.month', {
              context: 'this',
            })}: ${ValueFormatter.toPercent(value) || '-'}`}
          </>
        ),
      },
      {
        Header: t('label.common.expiresOn'),
        accessor: 'ExpiredOn' as const,
        minWidth: 120,
        maxWidth: 120,
        disableSortBy: true,
        Cell: ({ value }) => ValueFormatter.toDateString(value, 'YYYY-MM-DD'),
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailButtonIcon onClick={() => toggleDetail(row.original.UID)} />
        ),
      },
    ],
    [getOptimizationAWSRegion],
  );

  return (
    <>
      <DataPaginationTable
        data={data?.body?.items}
        columnInfo={columnInfo}
        totalCount={data?.body?.totalCount}
        onPageChange={onPageChange}
        pageSize={pageSize}
        FilterElement={ComputeSPUtilFilter}
        CSVUrl="savings_plan"
        enableFilter
        enableSort
        LastUpdate={data.body.extras.LatestUpdate}
      />
      {openDetail && selected && (
        <CommittedDetailModal
          open={openDetail}
          onClose={toggleDetail}
          UID={selected}
        />
      )}
    </>
  );
};

export default ComputeSPUtilTable;
