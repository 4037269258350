import React from 'react';
import { useParams } from 'react-router-dom';
import { useAWSAccountHelper } from '@hooks/helper';
import { WithAdmin } from '@hooks/hoc';
import { LinkedView, PayerView } from './components';

const ConfigureCURPage: React.FC = () => {
  const { uid } = useParams();
  const { findAWSAccountByUid } = useAWSAccountHelper();
  const selectedAccount = findAWSAccountByUid(uid);
  return (
    <>
      {selectedAccount &&
        (selectedAccount.isPayer ? (
          <PayerView uid={selectedAccount.uid} />
        ) : (
          <LinkedView uid={selectedAccount.uid} />
        ))}
    </>
  );
};

export default WithAdmin(ConfigureCURPage);
