import axios from '@utils/axios';
import { API_URL } from '@constants';
import { generateHash } from '@utils';

const getDatadog = async (): Promise<CCQueryResponse<Datadog>> => {
  const { data } = await axios.get(`${API_URL.INTEGRATION}/datadog`);
  return data;
};

const getNewrelic = async (): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.get(`${API_URL.INTEGRATION}/newrelic`);
  return data;
};

const createDatadog = async (
  companyId: string,
  APIKey: string,
  AppKey: string,
): Promise<CCQueryResponse<Datadog>> => {
  const { data } = await axios.post(`${API_URL.INTEGRATION}/datadog`, {
    uid: generateHash(),
    companyId,
    APIKey,
    AppKey,
  });
  return data;
};

const createNewrelic = async (
  companyId: string,
  APIKey: string,
  AccountId: string,
  QueryKey: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.post(`${API_URL.INTEGRATION}/newrelic`, {
    uid: generateHash(),
    companyId,
    APIKey,
    AccountId,
    QueryKey,
  });
  return data;
};

const updateDatadog = async (
  uid: string,
  companyId: string,
  APIKey: string,
  AppKey: string,
): Promise<CCQueryResponse<Datadog>> => {
  const { data } = await axios.put(`${API_URL.INTEGRATION}/datadog`, {
    uid,
    companyId,
    APIKey,
    AppKey,
  });
  return data;
};

const updateNewrelic = async (
  uid: string,
  companyId: string,
  APIKey: string,
  AccountId: string,
  QueryKey: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.put(`${API_URL.INTEGRATION}/newrelic`, {
    uid,
    companyId,
    APIKey,
    AccountId,
    QueryKey,
  });
  return data;
};

const verifyDatadog = async (
  APIKey: string,
  AppKey: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.post(`${API_URL.INTEGRATION}/datadog/verify`, {
    APIKey,
    AppKey,
  });
  return data;
};

const verifyNewrelic = async (
  APIKey: string,
  AccountId: string,
  QueryKey: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.post(`${API_URL.INTEGRATION}/newrelic/verify`, {
    APIKey,
    AccountId,
    QueryKey,
  });
  return data;
};

const deleteDatadog = async (
  companyId: string,
): Promise<CCQueryResponse<Datadog>> => {
  const { data } = await axios.delete(
    `${API_URL.INTEGRATION}/datadog/company/${companyId}`,
  );
  return data;
};

const deleteNewrelic = async (
  companyId: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.delete(
    `${API_URL.INTEGRATION}/newrelic/company/${companyId}`,
  );
  return data;
};

export default {
  getDatadog,
  getNewrelic,
  createDatadog,
  createNewrelic,
  updateDatadog,
  updateNewrelic,
  verifyDatadog,
  verifyNewrelic,
  deleteDatadog,
  deleteNewrelic,
};
