import { useMutation, useQueryClient } from 'react-query';
import { CompanyService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const client = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.ADMIN.COMPANY.DELETE_COMPANY,
    mutationFn: (variables: { companyId: string }) =>
      CompanyService.deleteCompany(variables.companyId),
    onSuccess: () =>
      client.invalidateQueries(QUERY_KEY.ADMIN.COMPANY.GET_COMPANIES),
  });
};
