import axios from '@utils/axios';
import { API_URL } from '@constants';

const getPaymentHistory = async (): Promise<
  CCQueryResponse<
    PaymentHistory,
    {
      HasMore: boolean;
      LastItem: string;
    }
  >
> => {
  const { data } = await axios.get(`${API_URL.PAYMENT}/history`, {
    params: {
      skip: 0,
      limit: 20000,
    },
  });
  return data;
};

const getPaymentMethod = async (): Promise<CCQueryResponse<PaymentMethod>> => {
  const { data } = await axios.get(`${API_URL.PAYMENT}/payment_method`);
  return data;
};

const getRecommendedTier = async (): Promise<
  CCQueryResponse<RecommendedTier>
> => {
  const { data } = await axios.get(`${API_URL.PAYMENT}/recommend`);
  return data;
};

const getSubscriptions = async (): Promise<
  CCQueryResponse<PaymentSubscription>
> => {
  const { data } = await axios.get(`${API_URL.PAYMENT}/get_subscriptions`);
  return data;
};

const getStripeKey = async (): Promise<
  CCQueryResponse<{
    APIKey: string;
  }>
> => {
  const { data } = await axios.get(`${API_URL.PAYMENT}/get_stripe_key`);
  return data;
};

const createPaymentMethod = async (
  CardToken: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.post(`${API_URL.PAYMENT}/payment_method`, {
    CardToken,
  });
  return data;
};

const putDefaultPaymentMethod = async (
  PaymentMethodId: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.put(
    `${API_URL.PAYMENT}/change_default_payment_method`,
    { PaymentMethodId },
  );
  return data;
};

const deletePaymentMethod = async (
  PaymentMethodId: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.delete(
    `${API_URL.PAYMENT}/detach_payment_method/${PaymentMethodId}`,
  );
  return data;
};

const getPurchaseStatus = async (): Promise<
  CCQueryResponse<PurchaseStatus>
> => {
  const { data } = await axios.get(`${API_URL.PAYMENT}/purchase_status`);
  return data;
};

const validatePromotion = async (
  ProductId: string,
  PromotionCode: string,
): Promise<
  CCQueryResponse<
    PromotionValidation,
    {
      IncommingFee: number;
      IncommingFeeWithTax: ReadonlyArray<{
        Country: string;
        IncommingFee: number;
        Percentage: number;
        TaxName: string;
        TaxUID: string;
      }>;
    }
  >
> => {
  const { data } = await axios.post(`${API_URL.PAYMENT}/promotion_validate`, {
    ProductId,
    PromotionCode,
  });
  return data;
};

const purchaseProduct = async (
  ProductId: string,
  PaymentMethod: string,
  PromotionCode?: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.post(`${API_URL.PAYMENT}/purchase`, {
    ProductId,
    PaymentMethod,
    PromotionCode,
  });
  return data;
};

export default {
  getPaymentHistory,
  getPaymentMethod,
  getRecommendedTier,
  getSubscriptions,
  getStripeKey,
  createPaymentMethod,
  putDefaultPaymentMethod,
  deletePaymentMethod,
  getPurchaseStatus,
  validatePromotion,
  purchaseProduct,
};
