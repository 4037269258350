import { useQuery } from 'react-query';
import { useCurrentInfo } from '@hooks/api/common';
import { AWSAccountService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const { isLoading, currentUser } = useCurrentInfo();
  const { isSuccess, data } = useQuery(
    QUERY_KEY.ONBOARD.FROM_CLI,
    () => AWSAccountService.getArnFromCli(currentUser?.uid ?? ''),
    {
      enabled: !isLoading && Boolean(currentUser?.uid),
      retryDelay: 10000,
      suspense: true,
    },
  );
  return {
    isSuccess,
    createdArn: data?.items?.length ? data.items[0].Arn : undefined,
  };
};
