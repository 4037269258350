import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { DotLegendItem, DotLegendWrapper } from '@components/styled';
import {
  GrmtAxisDefaultProps,
  GrmtChartTooltipProps,
  GrmtDefaultChartProps,
} from '@components/chart';
import { bluegrey, primary, secondary } from '@theme/colors';
import RightSizingGrid from './RightSizingGrid';

type ScatterAxis = {
  dataKey: string;
  name?: string;
};

type RightSizingChartExtra = {
  value: string | number;
  label: string;
};

type RightSizingChartProps = GrmtDefaultChartProps &
  GrmtChartTooltipProps & {
    xAxis: [ScatterAxis, ScatterAxis];
    yAxis: [ScatterAxis, ScatterAxis];
    extraInfo: [
      RightSizingChartExtra,
      RightSizingChartExtra,
      RightSizingChartExtra,
    ];
    cpuThreshold?: number;
    memThreshold?: number;
  };

const SAFE_MARGIN: number = 8 as const;

const RightSizingChart: React.FC<RightSizingChartProps> = ({
  data,
  xAxis,
  yAxis,
  tooltipContent,
  extraInfo,
  cpuThreshold,
  memThreshold,
}) => {
  const { t } = useTranslation('optimization');
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      columns={20}
    >
      <Grid item md={13} xs={20}>
        <DotLegendWrapper>
          <DotLegendItem color="#9594FF" label={t('label.util.avg')} />
          <DotLegendItem
            color={secondary[500]}
            label={t('label.util.max')}
            diamond
          />
        </DotLegendWrapper>
        <ResponsiveContainer width="100%" aspect={1.5}>
          <ScatterChart
            data={data}
            margin={{
              top: SAFE_MARGIN,
              right: SAFE_MARGIN,
              bottom: 36,
              left: 24,
            }}
          >
            <XAxis
              type="number"
              xAxisId="first"
              domain={[0, 100]}
              ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              {...xAxis[0]}
              {...GrmtAxisDefaultProps}
              label={{
                value: `${t('label.chart.compute.cpuUtil')} (%)`,
                position: 'bottom',
                offset: 24,
                fill: '#A0A1B2',
                fontSize: '12px',
                fontWeight: 400,
              }}
            />
            <YAxis
              type="number"
              yAxisId="first"
              domain={[0, 100]}
              ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              {...yAxis[0]}
              {...GrmtAxisDefaultProps}
              label={{
                value: `${t('label.chart.compute.memUtil')} (%)`,
                position: 'insideLeft',
                angle: -90,
                offset: 0,
                stroke: 'none',
                fill: '#A0A1B2',
                fontSize: '12px',
                fontWeight: 400,
              }}
            />
            <XAxis
              type="number"
              xAxisId="second"
              domain={[0, 100]}
              hide
              {...xAxis[1]}
            />
            <YAxis
              type="number"
              yAxisId="second"
              domain={[0, 100]}
              hide
              {...yAxis[1]}
            />
            <RightSizingGrid
              vertical={false}
              stroke={bluegrey[200]}
              cpu={cpuThreshold}
              mem={memThreshold}
            />
            {tooltipContent && (
              <Tooltip cursor={false} content={tooltipContent} />
            )}
            <Scatter
              fill="#9594FF"
              xAxisId="first"
              yAxisId="first"
              r={6}
              shape={({
                width,
                height,
                fill,
                x,
                y,
                r,
                node,
                yAxis: { height: yHeight },
              }) => {
                const fillColor =
                  Number(node?.x) <= Number(cpuThreshold) &&
                  Number(node?.y) <= Number(memThreshold)
                    ? fill
                    : bluegrey[300];
                return (
                  <svg
                    width={width}
                    height={height}
                    x={Math.max(x, 0)}
                    y={y >= 0.0 ? y : yHeight + SAFE_MARGIN / 2}
                    fill={fillColor}
                    viewBox="0 0 12 12"
                  >
                    <ellipse cx={6} cy={6} rx={r} ry={r} />
                  </svg>
                );
              }}
            />
            <Scatter
              fill={secondary[500]}
              xAxisId="second"
              yAxisId="second"
              shape={({
                width,
                height,
                fill,
                x,
                y,
                node,
                yAxis: { height: yHeight },
              }) => {
                const fillColor =
                  Number(node?.x) <= Number(cpuThreshold) &&
                  Number(node?.y) <= Number(memThreshold)
                    ? fill
                    : bluegrey[300];
                return (
                  <svg
                    width={width}
                    height={height}
                    x={Math.max(x, 0)}
                    y={y >= 0.0 ? y : yHeight + SAFE_MARGIN / 2}
                    fill={fillColor}
                    viewBox="0 0 12 12"
                  >
                    <rect
                      x="6"
                      width="8.48528"
                      height="8.48528"
                      transform="rotate(45 6 0)"
                    />
                  </svg>
                );
              }}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </Grid>
      <Grid
        item
        display={{ md: 'block', xs: 'flex' }}
        justifyContent="space-between"
        md={5}
        xs={20}
        mt={{ md: 0, xs: 4 }}
      >
        <Box borderLeft="2px solid #5C5C66" pl={2}>
          <Typography variant="subtitle2">{extraInfo[0].value}</Typography>
          <Typography variant="caption" color="textSecondary">
            {extraInfo[0].label}
          </Typography>
        </Box>
        <Box borderLeft="2px solid #5C5C66" mt={{ md: 7, xs: 0 }} pl={2}>
          <Typography variant="subtitle2">{extraInfo[1].value}</Typography>
          <Typography variant="caption" color="textSecondary">
            {extraInfo[1].label}
          </Typography>
        </Box>
        <Box
          borderLeft={`2px solid ${primary[600]}`}
          color={primary[600]}
          mt={{ md: 7, xs: 0 }}
          pl={2}
        >
          <Typography variant="subtitle2">{extraInfo[2].value}</Typography>
          <Typography variant="caption" color="textSecondary">
            {extraInfo[2].label}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
RightSizingChart.defaultProps = {
  cpuThreshold: 100.0,
  memThreshold: 100.0,
};

export default React.memo(RightSizingChart);
