import React from 'react';
import { Box, Button, Checkbox, TextField, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSignUp } from '@hooks/api/auth';
import { hashPassword } from '@utils';
import { TypoWithLink } from '@components/styled';
import { useSnackbar, useTagManager } from '@hooks/common';
import { GLOBAL } from '@constants';
import { ROUTES } from '@routes';
import { ReactComponent as LoginIcon } from '@assets/icons/common/Login.svg';
import { useSignUpForm } from './SignUpPage.form';

const SignUpPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { initialValues, validationSchema } = useSignUpForm();
  const { t } = useTranslation('auth');
  const { t: formT } = useTranslation('form');
  const { t: toastT } = useTranslation('toast');
  const { showErrorSnackbar } = useSnackbar();
  const { sendDataLayer } = useTagManager();
  const { mutateAsync } = useSignUp();
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    isValid,
    dirty,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (v) => {
      try {
        const data = await mutateAsync({
          ...v,
          password: hashPassword(v.password),
          promotion: searchParams.get('promotion'),
          reseller: searchParams.get('reseller'),
        });
        localStorage.removeItem(GLOBAL.REFRESH_TOKEN);
        localStorage.removeItem(GLOBAL.REFRESH_TOKEN_EXPIRED);
        localStorage.setItem(GLOBAL.ACCESS_TOKEN, data.items[0].access_token);
        localStorage.setItem(
          GLOBAL.ACCESS_TOKEN_EXPIRED,
          data.items[0].access_token_expired.toString(),
        );
        sendDataLayer('cc-signup');
        navigate(ROUTES.OVERVIEW.ROOT);
      } catch (e: any) {
        if (e.response?.data?.message) {
          switch (e.response.data.message) {
            case 'User already exists':
              showErrorSnackbar(toastT('error.auth.signUp.duplicated'));
              setFieldError('email', toastT('error.auth.signUp.duplicated'));
              break;
            default:
              showErrorSnackbar(toastT('error.general'));
              break;
          }
        }
      }
    },
  });

  return (
    <form id="cc_signup" onSubmit={handleSubmit}>
      <Typography variant="h1" align="center" mb={7}>
        {t('title.signUp')}
      </Typography>
      <Box display="flex" flexDirection={{ lg: 'row', xs: 'column' }} mb={1}>
        <TextField
          name="firstName"
          type="text"
          inputMode="text"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={formT('holder.firstName')}
          autoCapitalize="off"
          error={Boolean(errors.firstName && touched.firstName)}
          helperText={
            Boolean(errors.firstName && touched.firstName) &&
            String(errors.firstName)
          }
          sx={{ mb: { lg: 0, xs: 1 } }}
          fullWidth
        />
        <TextField
          name="lastName"
          type="text"
          inputMode="text"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={formT('holder.lastName')}
          autoCapitalize="off"
          error={Boolean(errors.lastName && touched.lastName)}
          helperText={
            Boolean(errors.lastName && touched.lastName) &&
            String(errors.lastName)
          }
          sx={{ ml: { lg: 2, xs: 0 } }}
          fullWidth
        />
      </Box>
      <TextField
        name="companyName"
        type="text"
        inputMode="text"
        value={values.companyName}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={formT('holder.company')}
        autoCapitalize="off"
        error={Boolean(errors.companyName && touched.companyName)}
        helperText={
          Boolean(errors.companyName && touched.companyName) &&
          String(errors.companyName)
        }
        sx={{ mb: 1 }}
        fullWidth
      />
      <TextField
        name="email"
        type="email"
        inputMode="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={formT('holder.email')}
        autoComplete="username"
        autoCapitalize="off"
        error={Boolean(errors.email && touched.email)}
        helperText={
          Boolean(errors.email && touched.email) && String(errors.email)
        }
        sx={{ mb: 1 }}
        fullWidth
      />
      <TextField
        name="password"
        type="password"
        inputMode="text"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={formT('holder.password')}
        autoComplete="new-password"
        autoCapitalize="off"
        error={Boolean(errors.password && touched.password)}
        helperText={
          Boolean(errors.password && touched.password) &&
          String(errors.password)
        }
        sx={{ mb: 1 }}
        fullWidth
      />
      <Box mb={5}>
        <TextField
          name="confirm"
          type="password"
          inputMode="text"
          value={values.confirm}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={formT('holder.confirm')}
          autoComplete="new-password"
          autoCapitalize="off"
          error={Boolean(errors.confirm && touched.confirm)}
          helperText={
            Boolean(errors.confirm && touched.confirm) && String(errors.confirm)
          }
          fullWidth
        />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
        <Checkbox
          name="accept"
          value={values.accept}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
        />
        <TypoWithLink
          variant="caption"
          dangerouslySetInnerHTML={{
            __html: t('label.terms', {
              termsUrl: 'https://www.grumatic.com/terms-of-service',
              privacyUrl: 'https://www.grumatic.com/privacy-policy',
            }),
          }}
        />
      </Box>
      <Box mb={3}>
        <Button
          type="submit"
          size="large"
          disabled={!isValid || !dirty}
          fullWidth
        >
          {t('button.signUp')}
        </Button>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box>
          <Typography variant="caption">{t('cta.signIn.title')}</Typography>
        </Box>
        <Box ml={2}>
          <Typography
            variant="caption"
            color="primary"
            onClick={() => navigate('/signin', { replace: true })}
            sx={{
              cursor: 'pointer',
              '> span': {
                verticalAlign: 'middle',
              },
              '> svg': {
                verticalAlign: 'middle',
              },
            }}
          >
            <LoginIcon />
            <span> {t('cta.signIn.label')}</span>
          </Typography>
        </Box>
      </Box>
    </form>
  );
};

export default SignUpPage;
