import React from 'react';
import { TextField } from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useRDSStorageAutoComplete } from '@hooks/api/optimization/rds';
import { useFilterFormik } from '@hooks/common';
import { useAWSHelper } from '@hooks/helper';
import {
  FilterGrid,
  FilterGridItem,
  FilterProps,
  FilterSelect,
  FilterWrapper,
} from '@components/filter';

type DatabaseStorageFilterForm = {
  Region: Array<string>;
  ClusterId: string;
  InstanceId: string;
  InstanceType: Array<string>;
  DatabaseEngine: Array<string>;
  StorageType: Array<string>;
  Tag: string;
};

const useDatabaseStorageFilterForm =
  (): UseValidation<DatabaseStorageFilterForm> => ({
    initialValues: {
      Region: [],
      ClusterId: '',
      InstanceId: '',
      InstanceType: [],
      DatabaseEngine: [],
      StorageType: [],
      Tag: '',
    },
    validationSchema: Yup.object({
      Region: Yup.array().of(Yup.string()),
      ClusterId: Yup.string(),
      InstanceId: Yup.string(),
      InstanceType: Yup.array().of(Yup.string()),
      DatabaseEngine: Yup.array().of(Yup.string()),
      StorageType: Yup.array().of(Yup.string()),
      Tag: Yup.string(),
    }).defined(),
  });

const DatabaseStorageFilter: React.FC<FilterProps> = ({
  onClose,
  onFilterChange,
  previousValues,
}) => {
  const { t } = useTranslation('optimization');
  const { getAWSRegionName } = useAWSHelper();
  const { data } = useRDSStorageAutoComplete();
  const { initialValues, validationSchema } = useDatabaseStorageFilterForm();
  const { values, handleChange, handleArrayChange, handleBlur, handleSubmit } =
    useFilterFormik(
      {
        initialValues: previousValues || initialValues,
        validationSchema,
        onSubmit: (v) => {
          onFilterChange(v);
          onClose();
        },
      },
      data?.items,
    );
  if (!data?.items?.length) {
    return null;
  }
  const { DatabaseEngine, Region, InstanceType, StorageType } = data.items[0];
  return (
    <FilterWrapper onClose={onClose} onSubmit={handleSubmit}>
      <FilterGrid>
        <FilterGridItem label={t('label.common.region')}>
          <FilterSelect
            name="Region"
            value={values.Region}
            onChange={(e) => handleArrayChange('Region', e.target.value)}
            onBlur={handleBlur}
            renderValue={() =>
              values.Region.map((v: string) => getAWSRegionName(v)).join(', ')
            }
            selected={values.Region}
            candidate={Region}
            formatter={getAWSRegionName}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.db.clusterId')}>
          <TextField
            name="ClusterId"
            value={values.ClusterId}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.db.databaseId')}>
          <TextField
            name="InstanceId"
            value={values.InstanceId}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.db.databaseType')}>
          <FilterSelect
            name="InstanceType"
            value={values.InstanceType}
            onChange={(e) => handleArrayChange('InstanceType', e.target.value)}
            onBlur={handleBlur}
            renderValue={() => values.InstanceType.join(', ')}
            selected={values.InstanceType}
            candidate={InstanceType}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.db.databaseEngine')}>
          <FilterSelect
            name="DatabaseEngine"
            value={values.DatabaseEngine}
            onChange={(e) =>
              handleArrayChange('DatabaseEngine', e.target.value)
            }
            onBlur={handleBlur}
            renderValue={() => values.DatabaseEngine.join(', ')}
            selected={values.DatabaseEngine}
            candidate={DatabaseEngine}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.storage', { context: 'type' })}>
          <FilterSelect
            name="StorageType"
            value={values.StorageType}
            onChange={(e) => handleArrayChange('StorageType', e.target.value)}
            onBlur={handleBlur}
            renderValue={() => values.StorageType.join(', ')}
            selected={values.StorageType}
            candidate={StorageType}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.tags')}>
          <TextField
            name="Tag"
            value={values.Tag}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </FilterGridItem>
      </FilterGrid>
    </FilterWrapper>
  );
};

export default DatabaseStorageFilter;
