import { useQuery } from 'react-query';
import { AWSAccountService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default (uid: string) =>
  useQuery(
    [QUERY_KEY.SETTINGS.ACCOUNT.DETAIL, uid],
    () => AWSAccountService.getAccountDetails(uid),
    { suspense: true, useErrorBoundary: true },
  );
