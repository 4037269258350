import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

type ConfigureCURForm = {
  reportName: string;
  bucketName: string;
  region: string;
};

export const useConfigureCURForm = (): UseValidation<ConfigureCURForm> => {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      reportName: '',
      bucketName: '',
      region: 'ap-northeast-2',
    },
    validationSchema: Yup.object({
      reportName: Yup.string()
        .matches(/^[a-zA-Z\d!\-_.*'()]+$/, t('onboard.report.contain'))
        .defined(t('common.required')),
      bucketName: Yup.string()
        .min(3, t('onboard.bucket.length'))
        .max(63, t('onboard.bucket.length'))
        .matches(/^[a-z\d.-]*$/, t('onboard.bucket.consist'))
        .matches(/^[a-z\d].*[a-z\d]$/, t('onboard.bucket.beginEnd'))
        .matches(/^(?!xn--).*$/, t('onboard.bucket.xn'))
        .matches(/^.*((?!-s3alias).{8}|^.{0,7})$/, t('onboard.bucket.s3alias'))
        .test('matches', t('onboard.bucket.ip'), (value) => {
          if (value) {
            return !value.match(
              /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            );
          }
          return true;
        })
        .defined(t('common.required')),
      region: Yup.string().trim().defined(),
    }).defined(),
  };
};
