import React, { useEffect, useState } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useEC2DetailsDailyUtilization } from '@hooks/api/optimization/ec2';
import { DailyInstanceUtilChart } from '@components/chart/optimization';
import { CCModalProps, DialogModal } from '@components/modal';
import { SuspenseBox } from '@components/styled/suspense';
import { detailsEC2DateState } from '@atoms/details';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { bluegrey } from '@theme/colors';
import { filterPositive } from '@utils';

const PeriodicBody: React.FC = () => {
  const [chartData, setChartData] = useState<
    Array<{ Timestamps: Date; CPU: number; Mem: number }>
  >([]);
  const { data } = useEC2DetailsDailyUtilization();
  useEffect(() => {
    if (data?.Utilization?.length) {
      const keys = _.union(
        data.Utilization[0]?.CPUUtilization?.map((v) => v.Timestamps),
        data.Utilization[0]?.MemUtilization?.map((v) => v.Timestamps),
      );
      keys.sort();
      setChartData(
        keys.map((date) => {
          const cpu = data.Utilization[0]?.CPUUtilization?.find(
            (v) => v.Timestamps === date,
          );
          const mem = data.Utilization[0]?.MemUtilization?.find(
            (v) => v.Timestamps === date,
          );
          return {
            Timestamps: date,
            CPU: filterPositive(cpu?.Value),
            Mem: filterPositive(mem?.Value),
          };
        }),
      );
    }
  }, [data?.Utilization]);
  if (!data?.Utilization?.length) {
    return null;
  }
  return <DailyInstanceUtilChart data={chartData} />;
};

const DetailsComputeDailyModal: React.FC<CCModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');
  const [date, setDate] = useRecoilState(detailsEC2DateState);
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      maxWidth="xl"
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: commonT('button.close'),
        },
      ]}
    >
      <Typography variant="h3" mb={3}>
        {t('label.util.daily')}
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="center"
        mb={3}
      >
        <Typography variant="caption" color="textSecondary">
          {t('label.util.date')} (UTC)
        </Typography>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DatePicker
            inputFormat="YYYY/MM/DD"
            value={moment(date)}
            onChange={(newValue) => {
              if (newValue) {
                setDate(newValue.format('YYYY-MM-DD'));
              }
            }}
            views={['day']}
            minDate={moment().utc().subtract(59, 'days')}
            maxDate={moment().utc()}
            components={{
              OpenPickerIcon: CalendarTodayIcon,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                onKeyDown={(e) => e.preventDefault()}
                sx={(theme) => ({
                  width: '8.5rem',
                  '> .MuiOutlinedInput-root': {
                    color: theme.palette.text.secondary,
                    fontSize: theme.typography.caption.fontSize,
                    lineHeight: theme.typography.caption.lineHeight,
                    fontWeight: theme.typography.caption.fontWeight,
                    borderColor: bluegrey[200],
                    '> .MuiOutlinedInput-input': {
                      padding: '0.6875rem 0.5rem',
                      paddingRight: 0,
                    },
                    '> .MuiInputAdornment-root > .MuiIconButton-root > .MuiSvgIcon-root':
                      {
                        width: '1.5rem',
                        height: '1.5rem',
                      },
                  },
                })}
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
      <SuspenseBox>
        <PeriodicBody />
      </SuspenseBox>
    </DialogModal>
  );
};

export default DetailsComputeDailyModal;
