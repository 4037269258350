import React from 'react';
import { DetailsDatabaseUtilChart } from './chart';
import DetailsComputeUtilRecommend from './recommendation/DetailsDatabaseUtilRecommend';

const DetailsDatabaseUtilizationPage: React.FC = () => (
  <>
    <DetailsDatabaseUtilChart />
    <DetailsComputeUtilRecommend />
  </>
);

export default DetailsDatabaseUtilizationPage;
