import { useQuery } from 'react-query';
import {
  usePaginatedQuery,
  usePaginatedQueryWithUtilization,
} from '@hooks/api/base';
import { OptimizationRDSService } from '@api/service';
import { QUERY_KEY } from '@constants';
import { useRecoilValue } from 'recoil';
import { detailsRDSDateState, detailsRDSState } from '@atoms/details';
import { useSelectedAWSAccounts } from '@hooks/api/common';
import {
  recommendCategoryState,
  recommendPeriodState,
  tableFilterState,
} from '@atoms/optimization';

export const useRDSInformation = () =>
  usePaginatedQueryWithUtilization(
    QUERY_KEY.OPTIMIZATION.RDS.INFORMATION,
    OptimizationRDSService.getRDSInformationList,
    { suspense: true, useErrorBoundary: true },
  );

export const useRDSCoverage = () =>
  usePaginatedQuery(
    QUERY_KEY.OPTIMIZATION.RDS.COVERAGE,
    OptimizationRDSService.getRDSCoverageList,
    { suspense: true, useErrorBoundary: true },
  );

export const useRDSUtilization = () =>
  usePaginatedQuery(
    QUERY_KEY.OPTIMIZATION.RDS.UTILIZATION,
    OptimizationRDSService.getRDSUtilizationList,
    { suspense: true, useErrorBoundary: true },
  );

export const useRDSStorage = () =>
  usePaginatedQuery(
    QUERY_KEY.OPTIMIZATION.RDS.STORAGE,
    OptimizationRDSService.getRDSStorageList,
    { suspense: true, useErrorBoundary: true },
  );

export const useRDSUtilizationChart = () => {
  const period = useRecoilValue(recommendPeriodState);
  const category = useRecoilValue(recommendCategoryState);
  const { apiQueryString } = useSelectedAWSAccounts();
  const tableFilter = useRecoilValue(tableFilterState);
  return useQuery(
    [
      QUERY_KEY.OPTIMIZATION.RDS.UTILIZATION_CHART,
      period,
      category,
      apiQueryString,
      tableFilter,
    ],
    ({ queryKey }) =>
      OptimizationRDSService.getRDSUtilizationChart(
        queryKey[1] as RecommendPeriod,
        queryKey[2] as RecommendCategory,
        queryKey[3],
        queryKey[4],
      ),
    { suspense: true, useErrorBoundary: true },
  );
};

export const useRDSStorageChart = () => {
  const { apiQueryString } = useSelectedAWSAccounts();
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.RDS.STORAGE_CHART, apiQueryString],
    ({ queryKey }) => OptimizationRDSService.getRDSStorageChart(queryKey[1]),
    { suspense: true, useErrorBoundary: true },
  );
};

export const useRDSPurchasingCoverageChart = () => {
  const { apiQueryString } = useSelectedAWSAccounts();
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.RDS.PURCHASING_COVERAGE_CHART, apiQueryString],
    ({ queryKey }) =>
      OptimizationRDSService.getRDSPurchasingCoverageChart(queryKey[1]),
    { suspense: true, useErrorBoundary: true },
  );
};

export const useRDSPurchasingUtilizationChart = () => {
  const { apiQueryString } = useSelectedAWSAccounts();
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.RDS.PURCHASING_UTILIZATION_CHART, apiQueryString],
    ({ queryKey }) =>
      OptimizationRDSService.getRDSPurchasingUtilizationChart(queryKey[1]),
    { suspense: true, useErrorBoundary: true },
  );
};

export const useRDSAutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.RDS.AC_DATA,
    () => OptimizationRDSService.getRDSAutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useRDSCoverageAutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.RDS.COVERAGE_AC_DATA,
    () => OptimizationRDSService.getRDSCoverageAutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useRDSRIUtilAutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.RDS.UTIL_AC_DATA,
    () => OptimizationRDSService.getRDSRIUtilizationAutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useRDSStorageAutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.RDS.STORAGE_AC_DATA,
    () => OptimizationRDSService.getRDSStorageAutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useRDSDetailsGeneral = () => {
  const databaseId = useRecoilValue(detailsRDSState);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.RDS.DETAILS_GENERAL, databaseId],
    ({ queryKey }) => OptimizationRDSService.getRDSDetailsGeneral(queryKey[1]),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(databaseId) },
  );
};

export const useRDSDetailsPurchasing = () => {
  const databaseId = useRecoilValue(detailsRDSState);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.RDS.DETAILS_PURCHASING, databaseId],
    ({ queryKey }) =>
      OptimizationRDSService.getRDSDetailsPricingPlan(queryKey[1]),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(databaseId) },
  );
};

export const useRDSDetailsStorage = () => {
  const databaseId = useRecoilValue(detailsRDSState);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.RDS.DETAILS_STORAGE, databaseId],
    ({ queryKey }) => OptimizationRDSService.getRDSDetailsStorage(queryKey[1]),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(databaseId) },
  );
};

export const useRDSDetailsUtilization = () => {
  const databaseId = useRecoilValue(detailsRDSState);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.RDS.DETAILS_UTILIZATION, databaseId],
    ({ queryKey }) =>
      OptimizationRDSService.getRDSDetailsUtilization(queryKey[1]),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(databaseId) },
  );
};

export const useRDSDetailsDailyUtilization = () => {
  const databaseId = useRecoilValue(detailsRDSState);
  const selectedDate = useRecoilValue(detailsRDSDateState);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.RDS.DETAILS_UTILIZATION, databaseId, selectedDate],
    ({ queryKey }) =>
      OptimizationRDSService.getRDSDetailsDailyUtilization(
        queryKey[1],
        queryKey[2],
      ),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(databaseId) },
  );
};

export const useRDSDetailsRecommend = () => {
  const databaseId = useRecoilValue(detailsRDSState);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.RDS.DETAILS_RECOMMEND, databaseId],
    ({ queryKey }) =>
      OptimizationRDSService.getRDSDetailsRecommend(queryKey[1]),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(databaseId) },
  );
};
