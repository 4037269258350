import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WithCUR, WithTrial } from '@hooks/hoc';
import { detailsEBSState } from '@atoms/details';
import { SuspensePaper } from '@components/styled/suspense';
import { OptimizationPaper } from '@components/styled/paper';
import DetailsVolumeInformation from './DetailsVolumeInformation';
import DetailsVolumeGeneral from './DetailsVolumeGeneral';

const DetailsVolumePage: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { volumeId } = useParams();
  const setVolumeId = useSetRecoilState(detailsEBSState);
  useEffect(() => {
    if (volumeId) {
      setVolumeId(volumeId);
    }
  }, [volumeId]);
  useEffect(() => () => setVolumeId(undefined), []);
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary" mb={2}>
        {t('label.common.general')}
      </Typography>
      <SuspensePaper sx={{ mb: 6 }}>
        <DetailsVolumeGeneral />
      </SuspensePaper>
      <OptimizationPaper showAPM>
        <DetailsVolumeInformation />
      </OptimizationPaper>
    </>
  );
};
export default WithTrial(WithCUR(DetailsVolumePage));
