import { useMutation } from 'react-query';
import { QUERY_KEY } from '@constants';
import { AWSAccountService } from '@api/service';

export default () =>
  useMutation({
    mutationKey: QUERY_KEY.ONBOARD.REQUEST_LINKED,
    mutationFn: (variables: {
      destination: string;
      params: {
        senderEmail: string;
        senderFirst: string;
        receiverName: string;
        companyName: string;
        fromAwsAccountId: string;
        toAccountId: string;
        hashSix: string;
      };
    }) =>
      AWSAccountService.sendEmail(
        'connectLinkedAccount',
        variables.destination,
        variables.params,
      ),
  });
