import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useUpdatePassword } from '@hooks/api/auth';
import { ROUTES } from '@routes';
import { hashPassword } from '@utils';
import { useUpdatePasswordForm } from './UpdatePasswordPage.form';
import { PureAuthCompleted, PureAuthContent } from '../AuthPage.styled';

const UpdatePasswordPage: React.FC = () => {
  const { token } = useParams();
  const { t } = useTranslation('auth');
  const { t: formT } = useTranslation('form');
  const { t: commonT } = useTranslation('common');
  const { mutate, isSuccess } = useUpdatePassword();
  const { initialValues, validationSchema } = useUpdatePasswordForm();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      validateOnChange: true,
      onSubmit: async (v) => {
        if (token) {
          mutate({
            token,
            password: hashPassword(v.password),
          });
        }
      },
    });
  return (
    <>
      {isSuccess ? (
        <PureAuthCompleted
          title={t('cta.updatePw.completeTitle')}
          description={t('cta.updatePw.completeDesc')}
          label={t('cta.updatePw.completeButton')}
          link={ROUTES.OVERVIEW.ROOT}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <PureAuthContent
            title={t('cta.updatePw.title')}
            description={t('cta.updatePw.desc')}
          >
            <TextField
              name="password"
              type="password"
              inputMode="text"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              label={formT('holder.newPassword')}
              autoComplete="new-password"
              autoCapitalize="off"
              error={Boolean(errors.password && touched.password)}
              helperText={
                Boolean(errors.password && touched.password) &&
                String(errors.password)
              }
              sx={{ mb: 2 }}
              fullWidth
            />
            <TextField
              name="confirm"
              type="password"
              inputMode="text"
              value={values.confirm}
              onChange={handleChange}
              onBlur={handleBlur}
              label={formT('holder.confirm')}
              autoComplete="new-password"
              autoCapitalize="off"
              error={Boolean(errors.confirm && touched.confirm)}
              helperText={
                Boolean(errors.confirm && touched.confirm) &&
                String(errors.confirm)
              }
              sx={{ mb: 2 }}
              fullWidth
            />
            <Button type="submit" size="large">
              {commonT('button.continue')}
            </Button>
          </PureAuthContent>
        </form>
      )}
    </>
  );
};

export default UpdatePasswordPage;
