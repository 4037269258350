import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  experimental_sx as sx,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuthorizeExternal } from '@hooks/api/onboard';
import WelcomeImg from '@assets/images/onboard_welcome_image.png';
import { useSetRecoilState } from 'recoil';
import { isAxiosError } from '@utils';
import {
  externalCompanyIdState,
  externalPageState,
  externalTokenState,
  externalUserIdState,
} from './ExternalAccount.atoms';

const StepOptionText = styled(Typography)(
  sx({
    color: 'textSecondary',
    mt: 1,
  }),
);

const ExternalWelcome: React.FC<{ isPayer: boolean }> = ({ isPayer }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<
    'default' | 'account' | 'exist' | 'hash' | 'user'
  >('default');
  const { t } = useTranslation('onboard');
  const [searchParams] = useSearchParams();
  const setToken = useSetRecoilState(externalTokenState);
  const setUserId = useSetRecoilState(externalUserIdState);
  const setCompanyId = useSetRecoilState(externalCompanyIdState);
  const setHasNext = useSetRecoilState(externalPageState);
  const { mutateAsync } = useAuthorizeExternal();
  const checkParams = useCallback(async () => {
    const from = searchParams.get('from');
    const to = searchParams.get('to');
    const hash = searchParams.get('hash');
    if (from && to && hash) {
      try {
        const data = await mutateAsync({ from, to, hash });
        if (data?.items?.length) {
          const { Token, CompanyId } = data.items[0];
          setToken(Token.access_token);
          setUserId(Token.uid);
          setCompanyId(CompanyId);
          setIsError(false);
          setIsLoaded(true);
        }
      } catch (e) {
        if (isAxiosError(e)) {
          switch (e.response.data.message) {
            case 'From account not found':
              setError('account');
              break;
            case 'Account already exists and has cur':
              setError('exist');
              break;
            case 'Unauthorized':
              setError('hash');
              break;
            case 'There are no user':
              setError('user');
              break;
            default:
              setError('default');
              break;
          }
          setIsError(true);
          setIsLoaded(true);
        }
      }
    }
  }, [searchParams]);
  useEffect(() => {
    checkParams();
  }, [searchParams]);
  return (
    <>
      {isLoaded && !isError && (
        <Box display="flex" p={10}>
          <Box mt={{ md: 5, xs: 0 }} mr={{ md: 3, xs: 0 }}>
            <Typography variant="h1">
              {isPayer ? t('title.setup') : t('title.connect')}
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              dangerouslySetInnerHTML={{
                __html: isPayer
                  ? t('external.subtitle.payer')
                  : t('external.subtitle.linked', {
                      ACCOUNT_ID: searchParams.get('to'),
                      URL: 'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_introduction.html',
                    }),
              }}
              mt={2}
            />
            <Stepper
              activeStep={0}
              orientation="vertical"
              sx={{ mt: { md: 20, xs: 6 } }}
            >
              <Step>
                <StepLabel
                  StepIconProps={{ variant: 'default' }}
                  sx={{ alignItems: 'flex-start' }}
                  optional={
                    <StepOptionText>
                      {t('external.description.openShell')}
                    </StepOptionText>
                  }
                >
                  {t('step.openShell')}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  StepIconProps={{ variant: 'default' }}
                  sx={{ alignItems: 'flex-start' }}
                  optional={
                    <StepOptionText>
                      {t('external.description.runScript')}
                    </StepOptionText>
                  }
                >
                  {t('step.runScript')}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  StepIconProps={{ variant: 'default' }}
                  sx={{ alignItems: 'flex-start' }}
                  optional={
                    <StepOptionText>
                      {t('external.description.verifyRole')}
                    </StepOptionText>
                  }
                >
                  {t('step.verifyRole')}
                </StepLabel>
              </Step>
              {isPayer && (
                <Step>
                  <StepLabel
                    StepIconProps={{ variant: 'default' }}
                    sx={{ alignItems: 'flex-start' }}
                    optional={
                      <StepOptionText>
                        {t('external.description.configureCUR')}
                      </StepOptionText>
                    }
                  >
                    {t('step.configure')}
                  </StepLabel>
                </Step>
              )}
            </Stepper>
            <Button
              size="large"
              onClick={() => setHasNext(false)}
              sx={{ mt: 10 }}
            >
              {t('external.button.getStarted')}
            </Button>
          </Box>
          <Box display={{ md: 'block', xs: 'none' }}>
            <img src={WelcomeImg} alt="welcome" />
          </Box>
        </Box>
      )}
      {isLoaded && isError && (
        <Box p={10}>
          {error === 'default' && <Typography>Error</Typography>}
          {error === 'account' && <Typography>Invalid Account</Typography>}
          {error === 'exist' && <Typography>Exist</Typography>}
          {error === 'hash' && <Typography>Invalid Hash</Typography>}
        </Box>
      )}
      {!isLoaded && (
        <Box display="flex" justifyContent="center" alignItems="center" p={10}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default ExternalWelcome;
