import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  useAWSAccountDetails,
  useReleaseAWSAccount,
} from '@hooks/api/settings/account';
import { useSnackbar, useToggle } from '@hooks/common';
import { GrmtTableColumnInfo, SimpleTable } from '@components/table';
import { QuestionTooltip } from '@components/styled/tooltip';
import { ConfirmDialog } from '@components/modal';

const AccountDetailAccess: React.FC<{ accessOnly: boolean }> = ({
  accessOnly,
}) => {
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const { t: dialogT } = useTranslation('dialog');
  const { t: toastT } = useTranslation('toast');
  const { uid } = useParams();
  const [showConfirm, toggleConfirm] = useToggle();
  const [selected, setSelected] = useState<AssignedUser | undefined>(undefined);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutateAsync, isLoading } = useReleaseAWSAccount();
  const { data } = useAWSAccountDetails(uid);
  const deleteUser = useCallback(async () => {
    if (selected) {
      try {
        await mutateAsync({
          UserUID: selected.uid,
          AWSAccountID: uid,
          ReadOnly: selected.readOnly,
        });
        showSuccessSnackbar(toastT('success.settings.account.detail.delete'));
        toggleConfirm();
      } catch (e) {
        showErrorSnackbar(toastT('error.general'));
      }
    }
  }, [selected]);

  const columns = useMemo<Array<GrmtTableColumnInfo>>(() => {
    const items: Array<GrmtTableColumnInfo> = [
      {
        Header: t('account.label.user'),
        accessor: 'lastname' as const,
        Cell: ({ value, row }) => `${row.original.firstname} ${value}`,
      },
      {
        Header: t('account.label.email'),
        accessor: 'email' as const,
      },
      {
        Header: (
          <Box display="flex" alignItems="center" width="inherit">
            <Typography variant="inherit" component="span" mr={1}>
              {t('account.label.permission')}
            </Typography>
            <QuestionTooltip
              title={
                <Typography
                  variant="body2"
                  color="textSecondary"
                  dangerouslySetInnerHTML={{
                    __html: t('account.label.role', { context: 'desc' }),
                  }}
                />
              }
            />
          </Box>
        ),
        accessor: 'readOnly' as const,
        Cell: ({ value }) =>
          value ? t('account.roleType.user') : t('account.roleType.manager'),
      },
    ];
    if (!accessOnly) {
      items.push({
        Header: t('account.label.user_delete'),
        accessor: 'isAdmin' as const,
        align: 'center',
        maxWidth: 80,
        Cell: ({ value, row }) => {
          if (value) {
            return '-';
          }
          return (
            <IconButton
              onClick={() => {
                setSelected(row.original as AssignedUser);
                toggleConfirm();
              }}
            >
              <DeleteIcon sx={{ width: '18px', height: '18px' }} />
            </IconButton>
          );
        },
      });
    }
    return items;
  }, [accessOnly]);

  return (
    <>
      <SimpleTable
        data={_.sortBy(data?.items, (v) => v.isAdmin)}
        columnInfo={columns}
      />
      {showConfirm && selected && (
        <ConfirmDialog
          open={showConfirm}
          onClose={toggleConfirm}
          title={dialogT('settings.account.delete.title')}
          content={dialogT('settings.account.delete.content')}
          Actions={[
            {
              label: commonT('button.cancel'),
              color: 'inherit',
              onClick: toggleConfirm,
            },
            {
              label: commonT('button.delete'),
              onClick: deleteUser,
              loading: isLoading,
            },
          ]}
        />
      )}
    </>
  );
};

export default AccountDetailAccess;
