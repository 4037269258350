import React, { useMemo } from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CCModalProps, DialogModal } from '@components/modal';
import { SimpleTypoTable } from '@components/styled';
import { ValueFormatter } from '@utils';
import { PredictionChart } from '@components/chart/anomaly';
import { useAWSAccountHelper, useAWSHelper } from '@hooks/helper';
import { bluegrey } from '@theme/colors';
import { AnomalyRecipientList } from '@pages/private/anomaly/AnomalyModal.styled';

const AnomalySummaryDetailsModal: React.FC<
  CCModalProps & { anomaly: OptimizationAnomaly }
> = ({ open, onClose, anomaly }) => {
  const { t } = useTranslation('optimization', { useSuspense: false });
  const { t: commonT } = useTranslation('common', { useSuspense: false });
  const { getAnomalyAWSRegion } = useAWSHelper();
  const { getAWSAccountNickname } = useAWSAccountHelper();
  const anomalyInfo = useMemo(
    () => [
      {
        label: t('label.common.accountId'),
        value: getAWSAccountNickname(anomaly.AccountId),
      },
      {
        label: t('label.common.region'),
        value: getAnomalyAWSRegion(anomaly.Region),
      },
      {
        label: t('label.common.service'),
        value: anomaly.Service,
      },
      {
        label: t('label.common.service_type'),
        value: anomaly.Usage,
      },
      {
        label: t('label.common.resourceId'),
        value: anomaly.ResourceId || commonT('label.none'),
      },
    ],
    [anomaly, getAWSAccountNickname, getAnomalyAWSRegion],
  );

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      maxWidth="lg"
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: t('label.anomaly.backToList'),
        },
        {
          onClick: () =>
            window.open(
              `https://${anomaly.Region}.console.aws.amazon.com/cost-management/home?region=${anomaly.Region}#/dashboard`,
              '_blank',
            ),
          label: t('label.anomaly.checkAnomaly'),
        },
      ]}
      HeaderProps={{
        icon: 'Anomaly Detection',
        label: t('label.anomaly.anomalyDetail'),
        extra: `${t('label.anomaly.excessCost')}: ${ValueFormatter.toCurrency(
          anomaly.AnomalySpend,
        )}`,
      }}
    >
      <Paper sx={{ mb: 6 }}>
        <SimpleTypoTable
          data={anomalyInfo}
          background={bluegrey[50]}
          disableBorder
        />
      </Paper>
      <Stack direction="row" spacing={2} mb={2}>
        <Typography variant="subtitle2">
          {t('label.anomaly.detected')}
        </Typography>
        <Typography color={bluegrey[400]}>
          {ValueFormatter.toDateString(
            anomaly.DetectedDate,
            'YYYY-MM-DD HH:mm',
          )}
        </Typography>
      </Stack>
      <PredictionChart data={anomaly.CostData} />
      <AnomalyRecipientList recipients={anomaly.EmailList} readOnly />
    </DialogModal>
  );
};

export default AnomalySummaryDetailsModal;
