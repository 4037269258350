/* eslint-disable react/jsx-key */
import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
  PluginHook,
  useAsyncDebounce,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {
  Box,
  Checkbox,
  Divider,
  InputAdornment,
  outlinedInputClasses,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { NoAvailableData } from '@components/styled/noData';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { ValueFormatter } from '@utils';
import { bluegrey } from '@theme/colors';
import { TableSortIcon } from '../table.styled';
import { SimpleTableProps } from '../table.types';

const SimpleTable: React.FC<SimpleTableProps> = ({
  columnInfo,
  data,
  maxHeight,
  titleColor,
  noDataText,
  enableSort,
  enableSelect,
  enableFilter,
  TitleElement,
  ExtraElement,
  LastUpdate,
}) => {
  const { t } = useTranslation('common');
  const tablePlugins = useMemo<Array<PluginHook<any>>>(() => {
    const plugins: Array<PluginHook<any>> = [];
    if (enableFilter) {
      plugins.push(useGlobalFilter);
    }
    if (enableSort) {
      plugins.push(useSortBy);
    }
    if (enableSelect) {
      plugins.push(useRowSelect);
    }
    return plugins;
  }, [enableSort, enableSelect, enableFilter]);

  const {
    getTableProps,
    getTableBodyProps,
    getToggleAllRowsSelectedProps,
    headerGroups,
    prepareRow,
    rows,
    state: { globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns: columnInfo,
      data,
      defaultCanSort: false,
    },
    ...tablePlugins,
  );

  const [filterValue, setFilterValue] = useState(globalFilter);
  const onFilterChange = useAsyncDebounce((v) => {
    setGlobalFilter(v || undefined);
  }, 200);

  return (
    <>
      {(LastUpdate || enableFilter || TitleElement || ExtraElement) && (
        <Box display="flex" alignItems="center" minHeight="3rem" pl={2} mb={2}>
          {Boolean(TitleElement) && (
            <>
              {typeof TitleElement === 'string' ? (
                <Typography color={titleColor}>{TitleElement}</Typography>
              ) : (
                TitleElement
              )}
            </>
          )}
          {(enableFilter || Boolean(ExtraElement) || Boolean(LastUpdate)) && (
            <Stack
              direction="row"
              flexGrow={1}
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              {Boolean(LastUpdate) && (
                <Box
                  display="flex"
                  alignItems="center"
                  minHeight="3rem"
                  py={1}
                  mr={2}
                >
                  <Typography variant="body2">
                    {t('label.lastUpdate')}
                  </Typography>
                  <Typography variant="body2" ml={2}>
                    {ValueFormatter.toDateString(
                      LastUpdate,
                      'YYYY/MM/DD HH:mm',
                    )}
                  </Typography>
                  {(enableFilter || Boolean(ExtraElement)) && (
                    <Divider orientation="vertical" flexItem sx={{ ml: 2 }} />
                  )}
                </Box>
              )}
              {enableFilter && (
                <TextField
                  size="small"
                  value={filterValue}
                  placeholder={t('label.search')}
                  onChange={(e) => {
                    setFilterValue(e.target.value);
                    onFilterChange(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon
                          shapeRendering="geometricPrecision"
                          sx={{ color: bluegrey[400] }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    mr: 2,
                    [`> .${outlinedInputClasses.root} > .${outlinedInputClasses.input}`]:
                      {
                        paddingTop: '0.622rem',
                        paddingBottom: '0.622rem',
                      },
                  }}
                />
              )}
              {Boolean(ExtraElement) && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  {ExtraElement}
                </Stack>
              )}
            </Stack>
          )}
        </Box>
      )}
      <Box
        sx={{
          maxHeight: maxHeight || undefined,
          overflowY: maxHeight ? 'auto' : 'visible',
        }}
      >
        <Table stickyHeader {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, idx) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {enableSelect && idx === headerGroups.length - 1 && (
                  <TableCell
                    padding="checkbox"
                    sx={{ bgcolor: '#fff', borderBottom: '2px solid #F0F0F7' }}
                  >
                    <Checkbox {...getToggleAllRowsSelectedProps()} />
                  </TableCell>
                )}
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps(
                      enableSort ? column.getSortByToggleProps() : undefined,
                    )}
                    width={column.width}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      bgcolor: '#fff',
                      borderBottom: '2px solid #F0F0F7',
                    }}
                  >
                    {column.render('Header')}
                    {enableSort && column.canSort && (
                      <TableSortIcon
                        isSorted={column.isSorted}
                        isDesc={column.isSortedDesc}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  selected={row.isSelected}
                  hover
                >
                  {enableSelect && (
                    <TableCell padding="checkbox">
                      <Checkbox {...row.getToggleRowSelectedProps()} />
                    </TableCell>
                  )}
                  {row.cells.map((cell) => (
                    <TableCell
                      {...cell.getCellProps()}
                      width={cell.column.width}
                      align={cell.column.align}
                      title={
                        typeof cell.value === 'string' ||
                        typeof cell.value === 'number'
                          ? cell.value.toString()
                          : undefined
                      }
                      sx={{
                        color: cell.column.color,
                        backgroundColor: cell.column.backgroundColor,
                        minWidth: cell.column.minWidth,
                        maxWidth: cell.column.maxWidth,
                      }}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {Boolean(!data?.length) && (
          <Box p={3}>
            <NoAvailableData text={noDataText} />
          </Box>
        )}
      </Box>
    </>
  );
};

SimpleTable.propTypes = {
  columnInfo: PropTypes.arrayOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.any),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleColor: PropTypes.string,
  noDataText: PropTypes.string,
  enableSort: PropTypes.bool,
  enableSelect: PropTypes.bool,
  enableFilter: PropTypes.bool,
  TitleElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  ExtraElement: PropTypes.element,
  LastUpdate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
};
SimpleTable.defaultProps = {
  data: [],
  maxHeight: undefined,
  titleColor: undefined,
  noDataText: undefined,
  enableSort: false,
  enableSelect: false,
  enableFilter: false,
  TitleElement: undefined,
  ExtraElement: undefined,
  LastUpdate: undefined,
};

export default React.memo(SimpleTable);
