import React from 'react';
import {
  Box,
  BoxProps,
  FormControlLabel,
  FormControlLabelProps,
  Paper,
  Radio,
  Typography,
} from '@mui/material';
import { APMSelector } from '@components/common';
import { SuspenseBox } from '@components/styled/suspense';
import { ValueFormatter } from '@utils';
import { useTranslation } from 'react-i18next';
import RecommendCategory from '@atoms/optimization/recommendCategory';
import { disabled as disabledColor } from '@theme/colors';

export const OptimizationPaper: React.FC<
  {
    showAPM?: boolean;
  } & Pick<BoxProps, 'px' | 'py'>
> = ({ children, showAPM }) => (
  <Paper square sx={{ px: 7, py: 5 }}>
    {showAPM && (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={6}
      >
        <APMSelector />
      </Box>
    )}
    <SuspenseBox>{children}</SuspenseBox>
  </Paper>
);
OptimizationPaper.defaultProps = {
  showAPM: false,
};

type OptimizationRecommendRadioPaperProps = Pick<
  FormControlLabelProps,
  'label' | 'disabled'
> & {
  value: RecommendCategory;
  selected: string;
  cost: number | undefined;
};
export const OptimizationRecommendRadioPaper: React.FC<
  OptimizationRecommendRadioPaperProps
> = ({ value, disabled, label, selected, cost }) => {
  const { t } = useTranslation('optimization');
  return (
    <Paper variant="elevation" sx={{ pl: 3, pr: 4, py: 3 }}>
      <Box>
        <FormControlLabel
          value={value}
          control={<Radio size="small" sx={{ p: 0, pr: 2 }} />}
          disabled={disabled}
          label={label}
          componentsProps={{
            typography: {
              color: selected === value ? 'textPrimary' : 'textSecondary',
            },
          }}
          sx={{
            ml: 0,
          }}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          pl={4}
        >
          {!disabled ? (
            <>
              <Typography
                variant="body2"
                color={selected === value ? 'textPrimary' : 'textSecondary'}
              >
                {t('label.common.savings_monthly')}
              </Typography>
              <Typography
                variant="body2"
                color={selected === value ? 'primary' : 'textSecondary'}
              >
                {ValueFormatter.toCurrency(cost)}
              </Typography>
            </>
          ) : (
            <Typography variant="body2" color={disabledColor[300]}>
              {t('label.common.savings_noPossible')}
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
