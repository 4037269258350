import React from 'react';
import {
  Box,
  buttonBaseClasses,
  Paper,
  Tab,
  tabClasses,
  Tabs,
  tabsClasses,
} from '@mui/material';
import { TabPanelUnstyled } from '@mui/base';
import { RoundTab, RoundTabs, RoundTabsList } from '@components/styled';
import TabPanel from '@components/tabpanel';
import { SuspenseBox } from '@components/styled/suspense';
import { useTranslation } from 'react-i18next';
import { bluegrey } from '@theme/colors';
import { ComputeCoverageChart, ComputeUtilChart } from './chart';
import {
  ComputeCoverageTable,
  ComputeRIUtilTable,
  ComputeSPUtilTable,
} from './table';

const ComputePurchasingPage: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation('optimization');

  return (
    <>
      <RoundTabs value={activeTab} onChange={(e, v) => setActiveTab(Number(v))}>
        <RoundTabsList sx={{ textAlign: 'right' }}>
          <RoundTab value={0}>{t('label.header.coverage')}</RoundTab>
          <RoundTab value={1}>{t('label.header.utilization')}</RoundTab>
        </RoundTabsList>
        <Paper square sx={{ px: 7, py: 5 }}>
          <TabPanelUnstyled value={0}>
            <SuspenseBox>
              <ComputeCoverageChart />
            </SuspenseBox>
            <SuspenseBox mt={12}>
              <ComputeCoverageTable />
            </SuspenseBox>
          </TabPanelUnstyled>
          <TabPanelUnstyled value={1}>
            <SuspenseBox>
              <ComputeUtilChart />
            </SuspenseBox>
            <Box mt={12}>
              <Tabs
                value={value}
                onChange={(e, v) => setValue(Number(v))}
                sx={{
                  mb: '-38px',
                  minHeight: '36px',
                  [`> .${tabsClasses.scroller} > .${tabsClasses.indicator}`]: {
                    height: '4px',
                    borderRadius: '64px',
                    backgroundColor: bluegrey[600],
                  },
                  [`> .${tabsClasses.scroller} > .${tabsClasses.flexContainer}`]:
                    {
                      [`> .${tabClasses.root}:not(:first-of-type)`]: {
                        marginLeft: '40px',
                      },
                      [`> .${buttonBaseClasses.root}.Mui-selected`]: {
                        color: bluegrey[600],
                      },
                    },
                }}
              >
                <Tab
                  label={t('label.common.ri', { context: 'plural' })}
                  sx={{
                    padding: 0,
                    paddingBottom: '12px',
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                    fontWeight: 500,
                    minWidth: 0,
                    minHeight: '36px',
                    color: bluegrey[400],
                  }}
                />
                <Tab
                  label={t('label.common.sp', { context: 'plural' })}
                  sx={{
                    padding: 0,
                    paddingBottom: '12px',
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                    fontWeight: 500,
                    minWidth: 0,
                    minHeight: '36px',
                    color: bluegrey[400],
                  }}
                />
              </Tabs>
              <SuspenseBox>
                <TabPanel value={value} index={0}>
                  <ComputeRIUtilTable />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ComputeSPUtilTable />
                </TabPanel>
              </SuspenseBox>
            </Box>
          </TabPanelUnstyled>
        </Paper>
      </RoundTabs>
    </>
  );
};

export default ComputePurchasingPage;
