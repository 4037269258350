import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import _ from 'lodash';
import {
  discountProgramState,
  riSimulationState,
  spSimulationState,
} from '@pages/private/planner/simulation/Simulation.atoms';
import { useSimulationRecommendation } from '@hooks/api/planner';
import { RoundTab, RoundTabs, RoundTabsList } from '@components/styled';
import { Box, Grid, Typography } from '@mui/material';
import { TabPanelUnstyled } from '@mui/base';
import {
  GuideHeader,
  GuideItem,
} from '@pages/private/planner/simulation/Simulation.styled';
import { useTranslation } from 'react-i18next';

const SORT_ORDER = {
  'No Upfront': 1,
  'Partial Upfront': 2,
  'All Upfront': 3,
};

const SimulationGuide: React.FC<{
  recommendation?: RIRecommend | SPRecommend;
}> = ({ recommendation }) => {
  const { instanceId } = useParams();
  const { t } = useTranslation('planner');
  const { t: optT } = useTranslation('optimization');
  const [selectedOption, setSelectedOption] =
    useRecoilState(discountProgramState);
  const [selectedRI, setSelectedRI] = useRecoilState(riSimulationState);
  const [selectedSP, setSelectedSP] = useRecoilState(spSimulationState);
  const compareRIGuide = useCallback(
    (item: RIGuide) =>
      item.OfferingClass === selectedRI.OfferingClass &&
      item.Duration === selectedRI.Duration &&
      item.PaymentOption === selectedRI.PaymentOption,
    [selectedRI.OfferingClass, selectedRI.Duration, selectedRI.PaymentOption],
  );
  const compareSPGuide = useCallback(
    (item: SPGuide) =>
      item.PlanType === selectedSP.PlanType &&
      item.Duration === selectedSP.Duration &&
      item.PaymentOption === selectedSP.PaymentOption,
    [selectedSP.PlanType, selectedSP.Duration, selectedSP.PaymentOption],
  );
  const { data } = useSimulationRecommendation(instanceId);
  if (!data?.items?.length) {
    return null;
  }
  const { ReservedInstance, SavingsPlan } = data.items[0];
  const Standard = useMemo(() => {
    const sorted = _.sortBy(
      ReservedInstance.filter((v) => v.OfferingClass === 'standard'),
      [(v) => SORT_ORDER[v.PaymentOption], 'Duration'],
    );
    setSelectedRI(sorted[0]);
    return sorted;
  }, [ReservedInstance]);
  const Convertible = useMemo(
    () =>
      _.sortBy(
        ReservedInstance.filter((v) => v.OfferingClass === 'convertible'),
        [(v) => SORT_ORDER[v.PaymentOption], 'Duration'],
      ),
    [ReservedInstance],
  );
  const EC2Instance = useMemo(() => {
    const sorted = _.sortBy(
      SavingsPlan.filter((v) => v.PlanType === 'EC2Instance'),
      [(v) => SORT_ORDER[v.PaymentOption], 'Duration'],
    );
    setSelectedSP(sorted[0]);
    return sorted;
  }, [SavingsPlan]);
  const Compute = useMemo(
    () =>
      _.sortBy(
        SavingsPlan.filter((v) => v.PlanType === 'Compute'),
        [(v) => SORT_ORDER[v.PaymentOption], 'Duration'],
      ),
    [SavingsPlan],
  );
  useEffect(() => {
    if (recommendation) {
      if (recommendation.Type === 'ReservedInstance') {
        setSelectedOption('reserved');
        setSelectedRI({
          OfferingClass: recommendation.PurchaseOption.OfferingClass,
          PaymentOption: recommendation.PurchaseOption.PaymentOption,
          Duration:
            recommendation.PurchaseOption.DurationSeconds === 94608000 ? 3 : 1,
          InstanceType: recommendation.PurchaseOption.InstanceType,
          NumberOfRI: recommendation.NumberOfRI,
        });
      } else {
        setSelectedOption('savingsplan');
        setSelectedSP({
          PlanType: recommendation.PurchaseOption.PlanType,
          PaymentOption: recommendation.PurchaseOption.PaymentOption,
          Duration:
            recommendation.PurchaseOption.DurationSeconds === 94608000 ? 3 : 1,
          Commitment: recommendation.Commitment,
        });
      }
    }
  }, []);
  return (
    <RoundTabs
      value={selectedOption}
      onChange={(e, v) => setSelectedOption(v as 'reserved' | 'savingsplan')}
    >
      <Box display="flex" alignItems="center" mb={3}>
        <Typography mr={3}>{t('label.selectOption')}</Typography>
        <RoundTabsList sx={{ mb: 0 }}>
          <RoundTab value="reserved">{optT('label.common.ri')}</RoundTab>
          <RoundTab value="savingsplan">{optT('label.common.sp')}</RoundTab>
        </RoundTabsList>
      </Box>
      <TabPanelUnstyled value="reserved">
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <GuideHeader label={t('RIClass.standard')} />
            <Grid container spacing={3}>
              {Standard.map((item) => (
                <GuideItem
                  {...item}
                  key={`${item.OfferingClass}_${item.Duration}_${item.PaymentOption}`}
                  onClick={() => setSelectedRI(item)}
                  selected={compareRIGuide(item)}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <GuideHeader label={t('RIClass.convertible')} />
            <Grid container spacing={3}>
              {Convertible.map((item) => (
                <GuideItem
                  {...item}
                  key={`${item.OfferingClass}_${item.Duration}_${item.PaymentOption}`}
                  onClick={() => setSelectedRI(item)}
                  selected={compareRIGuide(item)}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </TabPanelUnstyled>
      <TabPanelUnstyled value="savingsplan">
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <GuideHeader label={t('SPType.EC2Instance')} />
            <Grid container spacing={3}>
              {EC2Instance.map((item) => (
                <GuideItem
                  {...item}
                  key={`${item.PlanType}_${item.Duration}_${item.PaymentOption}`}
                  onClick={() => setSelectedSP(item)}
                  selected={compareSPGuide(item)}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <GuideHeader label={t('SPType.Compute')} />
            <Grid container spacing={3}>
              {Compute.map((item) => (
                <GuideItem
                  {...item}
                  key={`${item.PlanType}_${item.Duration}_${item.PaymentOption}`}
                  onClick={() => setSelectedSP(item)}
                  selected={compareSPGuide(item)}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </TabPanelUnstyled>
    </RoundTabs>
  );
};
SimulationGuide.defaultProps = {
  recommendation: undefined,
};

export default SimulationGuide;
