import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useEC2OthersChart } from '@hooks/api/optimization/ec2';
import { useBestPracticeHelper } from '@hooks/helper';
import { CostSummaryChart } from '@components/chart/optimization';
import { bluegrey } from '@theme/colors';
import { ValueFormatter } from '@utils';

const ComputeOtherChart: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { getTitle } = useBestPracticeHelper();
  const { data: response } = useEC2OthersChart();
  const data = useMemo(() => {
    const items = {
      'AWS-EC2-0002': {
        Cost: 0,
        Items: 0,
      },
      'AWS-EC2-0003': {
        Cost: 0,
        Items: 0,
      },
      'AWS-EC2-0009': {
        Cost: 0,
        Items: 0,
      },
      'AWS-EC2-0019': {
        Cost: 0,
        Items: 0,
      },
    };
    if (response?.body?.extras?.CategoryCost) {
      const list = response.body.extras.CategoryCost;
      Object.keys(list).forEach((key) => {
        if (_.has(items, key)) {
          items[key as EC2CaseId].Cost = list[key as EC2CaseId].Cost;
          items[key as EC2CaseId].Items = list[key as EC2CaseId].Items;
        }
      });
    }
    return _.orderBy(
      Object.keys(items).map((key) => ({
        Category: getTitle({ CaseId: key as EC2CaseId }),
        Cost: items[key as EC2CaseId].Cost,
        Items: items[key as EC2CaseId].Items,
      })),
      'Cost',
      'desc',
    );
  }, [response?.body?.extras?.CategoryCost]);
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        border="2px solid #f0f0f7"
        px={6}
        py={3}
      >
        <Typography variant="subtitle1">
          {t('label.intelligence.totalOpportunity')}
        </Typography>
        <Grid width="50%" container>
          <Grid item xs={6}>
            <Typography variant="h2" color={bluegrey[400]} align="right">
              {t('label.common.case', {
                count: _.sumBy(data, (v) => v.Items),
              })}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h2" color="primary" align="right">
              {ValueFormatter.toCurrency(
                Number(response?.body?.extras?.TotalCost),
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        bgcolor={bluegrey[50]}
        border="2px solid #f0f0f7"
        mt={3}
        px={6}
        py={4}
      >
        <CostSummaryChart
          data={data}
          chartInfo={{
            header: {
              label: t('label.common.item'),
              accessor: 'Category',
            },
            data: {
              label: t('label.intelligence.incurred'),
              accessor: 'Cost',
            },
            extra: {
              label: t('label.intelligence.cases'),
              accessor: 'Items',
            },
          }}
        />
      </Box>
    </>
  );
};

export default ComputeOtherChart;
