import { useMutation, useQueryClient } from 'react-query';
import { CompanyService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const client = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.ADMIN.COMPANY.UPDATE_COMPANY,
    mutationFn: (variables: {
      original: Company;
      changes: Partial<Omit<Company, 'id'>>;
    }) => CompanyService.updateCompany(variables.original, variables.changes),
    onSuccess: () =>
      client.invalidateQueries(QUERY_KEY.ADMIN.COMPANY.GET_COMPANIES),
  });
};
