import axios from '@utils/axios';
import { API_URL } from '@constants';
import { generateHash } from '@utils';

const getByCompanyId = async (
  id: string,
): Promise<CCQueryResponse<AWSAccount, { NeedMoreLinked: boolean }>> => {
  const { data } = await axios.post(`${API_URL.AWS.ACCOUNTS}/find`, {
    filter: { companyId: id },
    sortOrder: 'asc',
    sortField: '',
    pageNumber: 0,
    pageSize: 0,
  });
  return data;
};

const getAccountDetails = async (
  uid: string,
): Promise<CCQueryResponse<AssignedUser>> => {
  const { data } = await axios.get(`${API_URL.AWS.ACCOUNTS}/details/${uid}`);
  return data;
};

const getUserAutocomplete = async (): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.get(`${API_URL.USERS}/acdata/aws`);
  return data;
};

const getExternalId = async (
  token?: string,
): Promise<CCQueryResponse<{ ExternalId: string }>> => {
  const config = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : undefined;
  const { data } = await axios.post(
    `${API_URL.AWS.ACCOUNTS}/external_id`,
    undefined,
    config,
  );
  return data;
};

const verifyARN = async (
  arn: string,
  externalId: string,
  token?: string,
): Promise<
  CCQueryResponse<{
    CheckList: Record<OnboardCheckListKey, boolean>;
    IsPayer: boolean;
  }>
> => {
  const config = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : undefined;
  const { data } = await axios.post(
    `${API_URL.AWS.ACCOUNTS}/verify`,
    {
      arn,
      externalId,
    },
    config,
  );
  return data;
};

const getArnFromCli = async (
  userId: string,
  token?: string,
): Promise<CCQueryResponse<FromCliResponse>> => {
  const config = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : undefined;
  const { data } = await axios.get(
    `${API_URL.AWS.ACCOUNTS}/from_cli/${userId}`,
    config,
  );
  return data;
};

const createAWSAccount = async (
  name: string,
  arn: string,
  isPayer: boolean,
  companyId: string,
  externalId: string,
  token?: string,
): Promise<CCQueryResponse<AWSAccount>> => {
  const config = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : undefined;
  const { data } = await axios.post(
    `${API_URL.AWS.ACCOUNTS}`,
    {
      uid: generateHash(),
      name,
      arn,
      isPayer,
      companyId,
      externalId,
      dataType: 'GZIP',
      options: { CloudWatch: true, CostExplorer: true },
    },
    config,
  );
  return data;
};

const getLinkedOrganization = async (
  uid: string,
): Promise<
  CCQueryResponse<{
    AccountId: string;
    Email: string;
    Name: string;
    Status: string;
    IsMaster: boolean;
    Connected: boolean;
  }> & { UID: string }
> => {
  const { data } = await axios.get(
    `${API_URL.AWS.ACCOUNTS}/organization/linked/${uid}`,
  );
  return { ...data, UID: uid };
};

const getMasterOrganization = async (
  uid: string,
): Promise<
  CCQueryResponse<{
    AccountId: string;
    Email: string;
  }>
> => {
  const { data } = await axios.get(
    `${API_URL.AWS.ACCOUNTS}/organization/master/${uid}`,
  );
  return data;
};

const sendEmail = async (
  template: string,
  destination: string,
  params: Record<string, string>,
): Promise<any> => {
  const newParams: Record<string, string> = {};
  Object.entries(params).forEach(([key, value]) => {
    newParams[`{{${key.toUpperCase()}}}`] = value;
  });
  const { data } = await axios.post(`${API_URL.AWS.ACCOUNTS}/send_email`, {
    Template: template,
    Destination: destination,
    Params: newParams,
  });
  return data;
};

const createCUR = async (
  ReportName: string,
  S3Bucket: string,
  S3Region: string,
  AWSUID: string,
  token?: string,
): Promise<CCQueryResponse<any>> => {
  const config = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : undefined;
  const { data } = await axios.post(
    `${API_URL.AWS.CUR}/create`,
    {
      ReportName,
      S3Bucket,
      S3Region,
      AWSUID,
      ReportPrefix: 'costclipper',
      CreateBucket: true,
    },
    config,
  );
  return data;
};

const assignAWSAccount = async (
  UserUID: string,
  AWSAccountID: string,
  ReadOnly: boolean,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.put(`${API_URL.USERS}/assigned_account`, {
    UserUID,
    AWSAccountID,
    ReadOnly,
    isDelete: false,
  });
  return data;
};

const releaseAWSAccount = async (
  UserUID: string,
  AWSAccountID: string,
  ReadOnly: boolean,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.put(`${API_URL.USERS}/assigned_account`, {
    UserUID,
    AWSAccountID,
    ReadOnly,
    isDelete: true,
  });
  return data;
};

const authorizeExternal = async (
  fr: string,
  to: string,
  hash: string,
): Promise<
  CCQueryResponse<{
    HasCUR: boolean;
    IsCreated: boolean;
    Token: {
      access_token: string;
      access_token_expired: number;
      uid: string;
    };
    CompanyId: string;
  }>
> => {
  const { data } = await axios.post(
    `${API_URL.AWS.ACCOUNTS}/external/authorize`,
    undefined,
    {
      params: {
        fr,
        to,
        hash,
      },
    },
  );
  return data;
};

const sendEmailExternal = async (
  template: string,
  destination: string,
  params: Record<string, string>,
  token: string,
): Promise<any> => {
  const newParams: Record<string, string> = {};
  Object.entries(params).forEach(([key, value]) => {
    newParams[`{{${key.toUpperCase()}}}`] = value;
  });
  const { data } = await axios.post(
    `${API_URL.AWS.ACCOUNTS}/send_email`,
    {
      Template: template,
      Destination: destination,
      Params: newParams,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
};

export default {
  getByCompanyId,
  getAccountDetails,
  getUserAutocomplete,
  getExternalId,
  verifyARN,
  getArnFromCli,
  createAWSAccount,
  getLinkedOrganization,
  getMasterOrganization,
  sendEmail,
  createCUR,
  assignAWSAccount,
  releaseAWSAccount,
  authorizeExternal,
  sendEmailExternal,
};
