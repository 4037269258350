import { useQuery } from 'react-query';
import { CompanyService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default (companyId: string) =>
  useQuery(
    [QUERY_KEY.ADMIN.COMPANY.GET_COMPANY, companyId],
    ({ queryKey }) => CompanyService.getById(queryKey[1]),
    { suspense: true, useErrorBoundary: true },
  );
