/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';
// import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetPaymentHistory } from '@hooks/api/cmp';
import { GrmtTableColumnInfo, SimplePaginationTable } from '@components/table';
import { DetailLinkIcon } from '@components/table/table.styled';
import { ROUTES } from '@routes';
import { ValueFormatter } from '@utils';

const PaymentHistory: React.FC = () => {
  const { t } = useTranslation('report');
  // const { t: commonT } = useTranslation('common');
  const { data: history } = useGetPaymentHistory();
  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('table.invoice_no'),
        accessor: 'InvoiceId' as const,
        Cell: ({ value }) => value,
      },
      {
        Header: t('table.billing_period'),
        accessor: 'Date' as const,
        Cell: ({ value }) => ValueFormatter.toDateString(value, 'LL'),
      },
      {
        Header: t('table.cloud_charge'),
        accessor: 'CloudCost.USD' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value),
      },
      {
        Header: t('table.additional_service'),
        accessor: 'AdditionalCost.KRW' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value, 'KRW', 0),
      },
      {
        Header: t('table.discount_krw'),
        accessor: 'DiscountCost.KRW' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value, 'KRW', 0),
      },
      {
        Header: t('table.total_charge_krw'),
        accessor: 'TotalCost.KRW' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value, 'KRW', 0),
      },
      {
        Header: t('table.status'),
        accessor: 'Status' as const,
        Cell: ({ value }) => {
          switch (value) {
            case 'Invoiced':
            case 'Paid':
            case 'Overdue':
              // @ts-ignore
              return t(`status.${value}`);
            default:
              return '-';
          }
        },
      },
      {
        Header: t('table.details'),
        align: 'right',
        width: 84,
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailLinkIcon
            to={`${ROUTES.BILLING.ROOT}/${row.original.InvoiceId}`}
          />
        ),
      },
    ],
    [],
  );
  return (
    <SimplePaginationTable
      columnInfo={columnInfo}
      data={history?.data}
      // ExtraElement={
      //   <Box display="flex" alignItems="center" minHeight="3rem" py={1} mr={2}>
      //     <Typography variant="body2">{commonT('label.lastUpdate')}</Typography>
      //     <Typography variant="body2" ml={2}>
      //       {ValueFormatter.toDateString(
      //         history?.data.UpdatedAt,
      //         'YYYY/MM/DD hh:mm',
      //       )}
      //     </Typography>
      //   </Box>
      // }
      enableFilter
    />
  );
};

export default PaymentHistory;
