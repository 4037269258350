import React from 'react';
import { TabPanel } from '@mui/lab';
import { WithRoot } from '@hooks/hoc';
import { HeaderTabContext } from '@components/styled';
import { SuspensePaper } from '@components/styled/suspense';
import { SubscriptionTable, PromotionCodeTable, CouponTable } from './table';

const AdminSubscriptionPage: React.FC = () => (
  <HeaderTabContext
    defaultValue="subscription"
    items={[
      {
        label: 'Subscriptions',
        value: 'subscription',
      },
      {
        label: 'Promotions',
        value: 'promotion',
      },
      {
        label: 'Coupons',
        value: 'coupon',
      },
    ]}
  >
    <SuspensePaper sx={{ px: 7, py: 5 }}>
      <TabPanel value="subscription">
        <SubscriptionTable />
      </TabPanel>
      <TabPanel value="promotion">
        <PromotionCodeTable />
      </TabPanel>
      <TabPanel value="coupon">
        <CouponTable />
      </TabPanel>
    </SuspensePaper>
  </HeaderTabContext>
);

export default WithRoot(AdminSubscriptionPage);
