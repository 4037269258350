import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Grid,
  MenuItem,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCurrentInfo } from '@hooks/api/common';
import { useSnackbar, useToggle } from '@hooks/common';
import { useUpdateUser } from '@hooks/api/settings/user';
import { ConfirmDialog } from '@components/modal';
import ChangePasswordModal from './ChangePasswordModal';
import { useProfileForm } from './SettingsProfilePage.form';

const SettingsProfilePage: React.FC = () => {
  const [openConfirm, toggleConfirm] = useToggle();
  const [openChangePassword, toggleChangePassword] = useToggle();
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const { t: formT } = useTranslation('form');
  const { t: toastT } = useTranslation('toast');
  const { t: dialogT } = useTranslation('dialog');
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { initialValues, validationSchema } = useProfileForm();
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: () => toggleConfirm(),
  });
  const { currentCompany, currentUser } = useCurrentInfo();
  const { isLoading: isFetching, mutateAsync } = useUpdateUser();
  const submitChanges = useCallback(async () => {
    if (currentUser) {
      try {
        await mutateAsync({
          original: currentUser,
          changes: {
            firstname: values.firstName,
            lastname: values.lastName,
            language: values.language,
          },
        });
        toggleConfirm();
        showSuccessSnackbar(toastT('success.settings.profile.user'));
      } catch (e) {
        toggleConfirm();
        showErrorSnackbar(toastT('error.general'));
      }
    }
  }, [values, currentUser]);
  const isChanged = useMemo(() => {
    if (currentUser) {
      return !(
        values.firstName === currentUser.firstname &&
        values.lastName === currentUser.lastname &&
        values.language === currentUser.language
      );
    }
    return false;
  }, [currentUser, values]);
  useEffect(() => {
    if (currentUser) {
      setFieldValue('firstName', currentUser.firstname || '');
      setFieldValue('lastName', currentUser.lastname || '');
      setFieldValue('email', currentUser.email || '');
      setFieldValue('language', currentUser.language || 'en');
    }
  }, [currentUser]);
  return (
    <Paper square>
      <form onSubmit={handleSubmit}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={7}
          py={5}
        >
          <Box display="flex" alignItems="center">
            <AccountCircleIcon
              sx={{ color: '#EDEEFA', width: '48px', height: '48px' }}
            />
            <Typography variant="h3" component="span" ml={3}>
              {currentCompany?.name}
            </Typography>
          </Box>
          <Box>
            <Button
              type="button"
              variant="outlined"
              onClick={toggleChangePassword}
            >
              {t('user.label.changePassword')}
            </Button>
            <Button
              type="submit"
              disabled={!isValid || !isChanged}
              sx={{ ml: 2 }}
            >
              {commonT('button.saveChanges')}
            </Button>
          </Box>
        </Box>
        <Divider />
        <Grid container columnSpacing={15} rowSpacing={3} px={7} pt={6} pb={12}>
          <Grid item md={6} xs={12}>
            <TextField
              variant="standard"
              name="firstName"
              type="text"
              inputMode="text"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              label={formT('holder.firstName')}
              autoCapitalize="off"
              error={Boolean(errors.firstName && touched.firstName)}
              helperText={
                Boolean(errors.firstName && touched.firstName) &&
                String(errors.firstName)
              }
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              variant="standard"
              name="lastName"
              type="text"
              inputMode="text"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              label={formT('holder.lastName')}
              autoCapitalize="off"
              error={Boolean(errors.lastName && touched.lastName)}
              helperText={
                Boolean(errors.lastName && touched.lastName) &&
                String(errors.lastName)
              }
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              variant="standard"
              name="email"
              type="email"
              inputMode="email"
              value={values.email}
              label={formT('holder.email')}
              autoCapitalize="off"
              disabled
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              variant="standard"
              name="language"
              value={values.language}
              onChange={handleChange}
              onBlur={handleBlur}
              label={formT('holder.language')}
              InputProps={{ style: { color: 'inherit' } }}
              select
              fullWidth
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="ko">한국어</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </form>
      {openConfirm && (
        <ConfirmDialog
          open={openConfirm}
          onClose={toggleConfirm}
          title={dialogT('common.title.update')}
          content={dialogT('settings.profile.user.content')}
          Actions={[
            {
              color: 'inherit',
              onClick: toggleConfirm,
              label: commonT('button.cancel'),
            },
            {
              onClick: submitChanges,
              loading: isFetching,
              label: commonT('button.okay'),
            },
          ]}
        />
      )}
      {openChangePassword && (
        <ChangePasswordModal
          open={openChangePassword}
          onClose={toggleChangePassword}
        />
      )}
    </Paper>
  );
};

export default SettingsProfilePage;
