import React from 'react';
import { Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useResendVerification } from '@hooks/api/auth';
import { ROUTES } from '@routes';
import { useSnackbar } from '@hooks/common';
import { useResendVerificationForm } from './ResendVerificationPage.form';
import { PureAuthCompleted, PureAuthContent } from '../AuthPage.styled';

const ResendVerificationPage: React.FC = () => {
  const { t } = useTranslation('auth');
  const { t: formT } = useTranslation('form');
  const { t: toastT } = useTranslation('toast');
  const { showErrorSnackbar } = useSnackbar();
  const { mutateAsync, isSuccess } = useResendVerification();
  const { initialValues, validationSchema } = useResendVerificationForm();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      validateOnChange: true,
      onSubmit: async (v) => {
        try {
          await mutateAsync(v);
        } catch (e) {
          showErrorSnackbar(toastT('error.auth.resend.error'));
        }
      },
    });

  return (
    <>
      {isSuccess ? (
        <PureAuthCompleted
          title={t('cta.resendVerify.completeTitle')}
          description={t('cta.resendVerify.completeDesc')}
          label={t('cta.resendVerify.completeButton')}
          link={ROUTES.AUTH.SIGNIN}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <PureAuthContent
            title={t('cta.resendVerify.title')}
            description={t('cta.resendVerify.desc')}
          >
            <TextField
              name="email"
              type="email"
              inputMode="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              label={formT('holder.email')}
              autoComplete="username"
              autoCapitalize="off"
              error={Boolean(errors.email && touched.email)}
              helperText={
                Boolean(errors.email && touched.email) && String(errors.email)
              }
              fullWidth
            />
            <Button type="submit" size="large" fullWidth sx={{ mt: 7 }}>
              {t('cta.resendVerify.button')}
            </Button>
          </PureAuthContent>
        </form>
      )}
    </>
  );
};

export default ResendVerificationPage;
