import axios from '@utils/axios';
import { API_URL } from '@constants';

const getLastMonthBillingReport = async (): Promise<
  PPMultipleResponse<BillingInvoice>
> => {
  const { data } = await axios.get(`${API_URL.CMP}/last_month`);
  return data;
};

const getPaymentHistory = async (): Promise<
  PPMultipleResponse<BillingInvoiceList>
> => {
  const { data } = await axios.get(`${API_URL.CMP}/payment_history`);
  return data;
};

const getDepositHistory = async (): Promise<
  PPMultipleResponse<DepositHistory>
> => {
  const { data } = await axios.get(`${API_URL.CMP}/deposit_history`);
  return data;
};

const getInvoiceDetail = async (
  InvoiceId: string,
): Promise<PPMultipleResponse<BillingInvoice>> => {
  const { data } = await axios.get(
    `${API_URL.CMP}/invoice_detail/${InvoiceId}`,
  );
  return data;
};

const downloadInvoice = async (InvoiceId: string) => {
  const { data } = await axios.get(`${API_URL.CMP}/download/${InvoiceId}`, {
    responseType: 'blob',
  });
  return data;
};

export default {
  getLastMonthBillingReport,
  getPaymentHistory,
  getDepositHistory,
  getInvoiceDetail,
  downloadInvoice,
};
