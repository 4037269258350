import React, { useEffect, useState } from 'react';
import {
  Box,
  experimental_sx as sx,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CCModalProps, DialogModal } from '@components/modal';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import {
  usePublicCompany,
  usePublicTechSupport,
  usePublicUser,
} from '@hooks/api/external';
import { useRecoilValue } from 'recoil';
import { externalTokenState } from '@pages/private/onboard/external/ExternalAccount.atoms';

type Category = 'general' | 'bug' | 'interface' | 'payment' | 'data' | 'other';

const SupportRadio = styled(Radio)(
  sx({
    p: 1,
  }),
);

type TechSupportForm = {
  category: Category;
  messages: string;
  companyName: string;
  email: string;
  pageUrl: string;
  token: string;
};

const useTechSupportForm = (): UseValidation<TechSupportForm> => ({
  initialValues: {
    category: 'general',
    messages: '',
    companyName: '',
    email: '',
    pageUrl: '',
    token: '',
  },
  validationSchema: Yup.object({
    category: Yup.string().defined(),
    messages: Yup.string().defined(),
    companyName: Yup.string().defined(),
    email: Yup.string().email().defined(),
    pageUrl: Yup.string().defined(),
    token: Yup.string().defined(),
  }),
});

const ExternalSupportModal: React.FC<CCModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation('dialog');
  const { t: commonT } = useTranslation('common');
  const { pathname } = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const activeToken = useRecoilValue(externalTokenState);
  const { data: userData } = usePublicUser();
  const { data: companyData } = usePublicCompany();
  const { mutateAsync, isLoading } = usePublicTechSupport();
  const { initialValues, validationSchema } = useTechSupportForm();
  const {
    values,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        await mutateAsync(v);
        setSubmitted(true);
      } catch (e) {
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (pathname) {
      setFieldValue('pageUrl', pathname);
    }
  }, [pathname]);
  useEffect(() => {
    if (activeToken) {
      setFieldValue('token', activeToken);
    }
  }, [activeToken]);
  useEffect(() => {
    if (
      companyData?.items?.length &&
      companyData.items[0].email &&
      companyData.items[0].email.length
    ) {
      setFieldValue('email', companyData.items[0].email);
    }
  }, [companyData?.items]);
  useEffect(() => {
    if (
      companyData?.items?.length &&
      companyData.items[0].name &&
      companyData.items[0].name.length
    ) {
      setFieldValue('companyName', companyData.items[0].name);
    }
  }, [companyData?.items]);
  if (!userData?.items?.length || !companyData?.items?.length) {
    return null;
  }
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="lg"
      Actions={
        submitted
          ? [
              {
                label: t('general.support.button'),
                onClick: onClose,
              },
            ]
          : [
              {
                label: commonT('button.cancel'),
                color: 'inherit',
                onClick: onClose,
              },
              {
                type: 'submit',
                label: commonT('button.submit'),
                disabled: !isValid,
                loading: isLoading,
              },
            ]
      }
    >
      {submitted ? (
        <>
          <Typography variant="h3">{t('general.support.submitted')}</Typography>
          <Typography
            mt={3}
            lineHeight="2rem"
            dangerouslySetInnerHTML={{
              __html: t('general.support.submittedSub_anonymous'),
            }}
          />
        </>
      ) : (
        <>
          <Typography variant="h3">{t('general.support.title')}</Typography>
          <Typography
            variant="body2"
            mt={3}
            dangerouslySetInnerHTML={{ __html: t('general.support.subtitle') }}
          />
          <Box display="flex" mt={5}>
            <Typography variant="subtitle2" width="160px">
              Category
            </Typography>
            <Box flexGrow={1}>
              <RadioGroup
                value={values.category}
                onChange={(e, v) => setFieldValue('category', v as Category)}
              >
                <Grid container>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={<SupportRadio size="small" />}
                      label="General Feedback"
                      value="general"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={<SupportRadio size="small" />}
                      label="Bug Report"
                      value="bug"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={<SupportRadio size="small" />}
                      label="Interface Issue"
                      value="interface"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={<SupportRadio size="small" />}
                      label="Payment"
                      value="payment"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={<SupportRadio size="small" />}
                      label="Incorrect Data"
                      value="data"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={<SupportRadio size="small" />}
                      label="Other"
                      value="other"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </Box>
          </Box>
          <Box display="flex" mt={3}>
            <Typography variant="subtitle2" width="160px">
              Message
            </Typography>
            <Box flexGrow={1}>
              <TextField
                name="messages"
                value={values.messages}
                onChange={handleChange}
                onBlur={handleBlur}
                rows={10}
                multiline
                fullWidth
              />
            </Box>
          </Box>
        </>
      )}
    </DialogModal>
  );
};

export default ExternalSupportModal;
