import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import { useGetPromotions } from '@hooks/api/admin/promotion';
import { useToggle } from '@hooks/common';
import { GrmtTableColumnInfo, SimplePaginationTable } from '@components/table';
import CreatePromotionModal from './CreatePromotionModal';

const PromotionCodeTable: React.FC = () => {
  const [openCreate, toggleCreate] = useToggle();
  const { data } = useGetPromotions();

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      { Header: 'UID', accessor: 'UID' as const },
      { Header: 'Promotion code', accessor: 'Code' as const },
      { Header: 'Coupon Name', accessor: 'CouponName' as const },
      {
        Header: 'Discount',
        accessor: 'PercentOff' as const,
        Cell: ({ row }: any) => `${row.original.PercentOff}%`,
      },
      {
        Header: 'Active',
        accessor: 'Active' as const,
        Cell: ({ row }: any) => (row.original.Active ? 'Active' : 'Inactive'),
      },
      {
        Header: 'Max Redemptions',
        accessor: 'MaxRedemptions' as const,
        Cell: ({ row }: any) =>
          row.original.MaxRedemptions > 0 ? row.original.MaxRedemptions : 0,
      },
      { Header: 'Times Redeemed', accessor: 'TimesRedeemed' as const },
    ],
    [],
  );

  return (
    <>
      <SimplePaginationTable
        data={data?.items}
        columnInfo={columnInfo}
        ExtraElement={
          <Button variant="outlined" onClick={toggleCreate}>
            Add Promotion Code
          </Button>
        }
      />
      {openCreate && (
        <CreatePromotionModal open={openCreate} onClose={toggleCreate} />
      )}
    </>
  );
};

export default PromotionCodeTable;
