import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import _ from 'lodash';
import {
  ChartDefaultGradient,
  GrmtAxisDefaultProps,
  GrmtChartInfo,
  GrmtChartTooltipProps,
  GrmtChartXAxisProps,
  GrmtDefaultChartProps,
  GrmtGridDefaultProps,
} from '@components/chart';
import { DotLegendItem, DotLegendWrapper } from '@components/styled';
import { useTranslation } from 'react-i18next';
import { ValueFormatter } from '@utils';

type CoverageChartProps = GrmtDefaultChartProps &
  GrmtChartXAxisProps &
  GrmtChartTooltipProps & {
    chartInfo:
      | [GrmtChartInfo]
      | [GrmtChartInfo, GrmtChartInfo]
      | [GrmtChartInfo, GrmtChartInfo, GrmtChartInfo];
  };

type PathRadius = 'NONE' | 'BOTH' | 'TOP' | 'BOTTOM';

const makePath = (
  type: PathRadius,
  x: number,
  y: number,
  width: number,
  height: number,
) => {
  switch (type) {
    case 'BOTH':
      return `M${x},${y + 4}A 4,4,0,0,1,${x + 4},${y}L ${
        x + width - 4
      },${y}A 4,4,0,0,1,${x + width},${y + 4}L ${x + width},${
        y + height - 4
      }A 4,4,0,0,1,${x + width - 4},${y + height}L ${x + 4},${
        y + height
      }A 4,4,0,0,1,${x},${y + height - 4}Z`;
    case 'TOP':
      return `M${x},${y + 4}A 4,4,0,0,1,${x + 4},${y}L ${
        x + width - 4
      },${y}A 4,4,0,0,1,${x + width},${y + 4}L ${x + width},${
        y + height
      }L ${x},${y + height}Z`;
    case 'BOTTOM':
      return `M${x},${y}L ${x + width},${y}L ${x + width},${
        y + height - 4
      }A 4,4,0,0,1,${x + width - 4},${y + height}L ${x + 4},${
        y + height
      }A 4,4,0,0,1,${x},${y + height - 4}Z`;
    default:
      return `M ${x},${y} h ${width} v ${height} h -${width} Z`;
  }
};

const CoverageChart: React.FC<CoverageChartProps> = ({
  data,
  xAxis,
  tooltipContent,
  chartInfo,
}) => {
  const { t } = useTranslation('optimization');
  const [focused, setFocused] = useState<number | null>(null);
  const [mouseLeave, setMouseLeave] = useState(true);
  const handleMouseMove = useCallback((state: any) => {
    if (state) {
      if (state?.isTooltipActive) {
        setFocused(state?.activeTooltipIndex);
        setMouseLeave(false);
      } else {
        setMouseLeave(true);
        setFocused(null);
      }
    }
  }, []);
  return (
    <Box>
      <DotLegendWrapper>
        <DotLegendItem color="#FF7976" label={chartInfo[0].name} />
        {(chartInfo.length === 2 || chartInfo.length === 3) && (
          <DotLegendItem color="#57CAEB" label={chartInfo[1].name} />
        )}
        {chartInfo.length === 3 && (
          <DotLegendItem color="#9594FF" label={chartInfo[2].name} />
        )}
      </DotLegendWrapper>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          barSize={24}
          style={{ stroke: '#fff', strokeWidth: 1 }}
          onMouseMove={handleMouseMove}
          margin={{ left: 36, bottom: 8 }}
        >
          {ChartDefaultGradient}
          <XAxis {...xAxis} {...GrmtAxisDefaultProps} />
          <YAxis
            scale="linear"
            {...GrmtAxisDefaultProps}
            tickFormatter={(value) => ValueFormatter.toLocaleString(value, 0)}
            label={{
              value: t('label.chart.common.hour'),
              position: 'insideLeft',
              angle: -90,
              offset: -24,
              stroke: 'none',
              fill: '#A0A1B2',
              fontSize: '12px',
              fontWeight: 400,
            }}
          />
          <CartesianGrid {...GrmtGridDefaultProps} />
          {tooltipContent && (
            <Tooltip cursor={false} content={tooltipContent} />
          )}
          {chartInfo.length === 3 && (
            <Bar
              dataKey={chartInfo[2].dataKey}
              stackId="coverageStack"
              shape={({ width, height, fill, x, y, payload }) => {
                let type: PathRadius = 'BOTTOM';
                if (
                  _.sumBy(chartInfo, (v) => payload[v.dataKey]) ===
                  payload[chartInfo[2].dataKey]
                ) {
                  type = 'BOTH';
                }
                return (
                  <path fill={fill} d={makePath(type, x, y, width, height)} />
                );
              }}
            >
              {data.map((entry, idx) => (
                <Cell
                  key={`cell_cv_3_${idx.toString()}`}
                  fill={focused === idx || mouseLeave ? '#9594FF' : '#9594FF66'}
                />
              ))}
            </Bar>
          )}
          {(chartInfo.length === 2 || chartInfo.length === 3) && (
            <Bar
              dataKey={chartInfo[1].dataKey}
              stackId="coverageStack"
              shape={({ width, height, fill, x, y, payload }) => {
                let type: PathRadius = 'NONE';
                if (
                  _.sumBy(chartInfo, (v) => payload[v.dataKey]) ===
                  payload[chartInfo[1].dataKey]
                ) {
                  type = 'BOTH';
                } else if (!payload[chartInfo[0].dataKey]) {
                  type = 'TOP';
                } else if (
                  chartInfo.length === 2 ||
                  (chartInfo.length === 3 && !payload[chartInfo[2].dataKey])
                ) {
                  type = 'BOTTOM';
                }
                return (
                  <path fill={fill} d={makePath(type, x, y, width, height)} />
                );
              }}
            >
              {data.map((entry, idx) => (
                <Cell
                  key={`cell_cv_2_${idx.toString()}`}
                  fill={focused === idx || mouseLeave ? '#57CAEB' : '#57CAEB66'}
                />
              ))}
            </Bar>
          )}
          <Bar
            dataKey={chartInfo[0].dataKey}
            stackId="coverageStack"
            shape={({ width, height, fill, x, y, payload }) => {
              let type: PathRadius = 'TOP';
              if (
                chartInfo.length === 1 ||
                _.sumBy(chartInfo, (v) => payload[v.dataKey]) ===
                  payload[chartInfo[0].dataKey]
              ) {
                type = 'BOTH';
              }
              return (
                <path fill={fill} d={makePath(type, x, y, width, height)} />
              );
            }}
          >
            {data.map((entry, idx) => (
              <Cell
                key={`cell_cv_1_${idx.toString()}`}
                fill={focused === idx || mouseLeave ? '#FF7976' : '#FF797666'}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default React.memo(CoverageChart);
