import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteUser } from '@hooks/api/settings/user';
import { CCModalProps, ConfirmDialog } from '@components/modal';
import { useSnackbar } from '@hooks/common';

const DeleteUserModal: React.FC<CCModalProps & { uid?: string }> = ({
  open,
  onClose,
  uid,
}) => {
  const { t } = useTranslation('dialog');
  const { t: commonT } = useTranslation('common');
  const { t: toastT } = useTranslation('toast');
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutateAsync } = useDeleteUser();

  const deleteUser = useCallback(async () => {
    try {
      await mutateAsync({ uid });
      showSuccessSnackbar(toastT('success.settings.user.delete'));
      onClose();
    } catch (e) {
      showErrorSnackbar(toastT('error.general'));
    }
  }, [uid]);

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={t('settings.user.delete.title')}
      content={t('settings.user.delete.content')}
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: commonT('button.cancel'),
        },
        {
          onClick: deleteUser,
          label: commonT('button.confirm'),
        },
      ]}
    />
  );
};
DeleteUserModal.defaultProps = {
  uid: undefined,
};

export default DeleteUserModal;
