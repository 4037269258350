import React, { useCallback, useMemo } from 'react';
import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useAnomalyRecipientForm } from '@pages/private/anomaly/AnomalyModal.form';
import { GrmtTableColumnInfo, SimpleTable } from '@components/table';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { bluegrey } from '@theme/colors';

export const AnomalyConditionGrid: React.FC<{
  conditions: Array<{ label: string; Element: React.ReactElement }>;
}> = ({ conditions }) => (
  <Grid container columnSpacing={12} rowSpacing={3}>
    {conditions.map(({ label, Element }, idx) => {
      if (idx === 0) {
        return (
          <Grid key={label} item display="flex" alignItems="center" xs={12}>
            <Box width="100px" minWidth="100px" maxWidth="100px">
              <Typography variant="body2">{label}</Typography>
            </Box>
            <Box flexGrow={1} ml={3}>
              {Element}
            </Box>
          </Grid>
        );
      }
      return (
        <Grid
          key={label}
          item
          display="flex"
          alignItems="center"
          lg={6}
          xs={12}
        >
          <Box width="100px" minWidth="100px" maxWidth="100px">
            <Typography variant="body2">{label}</Typography>
          </Box>
          <Box flexGrow={1} ml={3}>
            {Element}
          </Box>
        </Grid>
      );
    })}
  </Grid>
);

export const AnomalyRecipientList: React.FC<{
  recipients: Array<{ Name: string; Email: string }>;
  onAdd?: ({ Name, Email }: { Name: string; Email: string }) => void;
  onDelete?: (idx: number) => void;
  readOnly?: boolean;
}> = ({ recipients, onAdd, onDelete, readOnly }) => {
  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');
  const { t: formT } = useTranslation('form');
  const { initialValues, validationSchema } = useAnomalyRecipientForm();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isValid,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
    validateOnMount: true,
    validateOnChange: true,
  });
  const handleEmail = useCallback(() => {
    if (onAdd) {
      onAdd(values);
      resetForm();
    }
  }, [values, onAdd, resetForm]);
  const columns = useMemo<Array<GrmtTableColumnInfo>>(() => {
    const items: Array<GrmtTableColumnInfo> = [
      {
        Header: 'No.',
        Cell: ({ row }) => Number(row.id) + 1,
        width: 70,
        backgroundColor: 'rgba(240, 240, 247, 0.2)',
      },
      {
        Header: t('label.common.name'),
        accessor: 'Name' as const,
        width: 220,
        backgroundColor: 'rgba(240, 240, 247, 0.2)',
      },
      {
        Header: formT('holder.email'),
        accessor: 'Email' as const,
        width: 250,
        backgroundColor: 'rgba(240, 240, 247, 0.2)',
      },
    ];
    if (!readOnly) {
      items.push({
        Header: commonT('button.delete'),
        align: 'center',
        maxWidth: 60,
        backgroundColor: 'rgba(240, 240, 247, 0.2)',
        Cell: ({ row }) => (
          <IconButton
            onClick={() => {
              if (onDelete) {
                onDelete(Number(row.id));
              }
            }}
            sx={{ p: 0, color: bluegrey[800] }}
          >
            <DeleteIcon
              sx={{
                width: '20px',
                height: '20px',
              }}
            />
          </IconButton>
        ),
      });
    }
    return items;
  }, [onDelete, readOnly]);
  return (
    <>
      <Typography
        variant="subtitle2"
        mt={7}
        sx={(theme) => ({
          '> span': {
            color: theme.palette.text.secondary,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            fontWeight: theme.typography.body2.fontWeight,
          },
        })}
      >
        {t('label.anomaly.notifications')}{' '}
        <span>({t('label.anomaly.recipientList')})</span>
      </Typography>
      <Box border="1px solid #F0F0F7" mt={2} mb={3}>
        <SimpleTable
          data={recipients}
          maxHeight={240}
          columnInfo={columns}
          noDataText={t('label.anomaly.noRecipient')}
        />
      </Box>
      {!readOnly && (
        <Grid container alignItems="center" spacing={3}>
          <Grid item>
            <IconButton
              color="primary"
              onClick={handleEmail}
              disabled={!isValid}
            >
              <AddCircleIcon />
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="Name"
              value={values.Name}
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('label.common.name')}
              autoCapitalize="off"
              error={Boolean(errors.Name && touched.Name)}
              helperText={
                Boolean(errors.Name && touched.Name) && String(errors.Name)
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="Email"
              type="email"
              inputMode="email"
              value={values.Email}
              onChange={handleChange}
              onBlur={handleBlur}
              label={formT('holder.email')}
              autoCapitalize="off"
              error={Boolean(errors.Email && touched.Email)}
              helperText={
                Boolean(errors.Email && touched.Email) && String(errors.Email)
              }
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
AnomalyRecipientList.defaultProps = {
  onAdd: undefined,
  onDelete: undefined,
  readOnly: false,
};
