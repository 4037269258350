import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import a11yDark from 'react-syntax-highlighter/dist/esm/styles/prism/a11y-dark';
import codeLang from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { usePublicExternalId } from '@hooks/api/external';
import { useToggle } from '@hooks/common';
import { LightTooltip } from '@components/styled/tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  externalStepState,
  externalUserIdState,
} from '../ExternalAccount.atoms';

SyntaxHighlighter.registerLanguage('bash', codeLang);

const ExternalRunScriptStep: React.FC = () => {
  const { t } = useTranslation('onboard');
  const { t: commonT } = useTranslation('common');
  const [openButton, , setButton] = useToggle();
  const [openScript, , setScript] = useToggle();
  const [clicked, setClicked] = useState(false);
  const setActive = useSetRecoilState(externalStepState);
  const [scriptText, setScriptText] = useState('');
  const shellFileName =
    process.env.REACT_APP_HOST_ENV === 'production'
      ? 'cost-clipper-aws-script.sh'
      : 'cost-clipper-aws-script-dev.sh';
  const userId = useRecoilValue(externalUserIdState);
  const { externalId } = usePublicExternalId();
  useEffect(() => {
    if (userId && externalId) {
      setScriptText(
        `rm -f ./${shellFileName} && wget https://cdn.grumatic.com/scripts/${shellFileName} && sh ./${shellFileName} ${externalId} ${userId}`,
      );
    }
  }, [userId, externalId]);
  return (
    <Box>
      <Typography variant="h3">{t('header.runScript')}</Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        {t('description.runScript')}
      </Typography>
      <Box position="relative" mt={5}>
        <SyntaxHighlighter language="bash" style={a11yDark} wrapLongLines>
          {scriptText}
        </SyntaxHighlighter>
        <Box position="absolute" top={8} right={8}>
          <CopyToClipboard
            onCopy={(text, result) => {
              if (result) {
                setClicked(true);
                setScript(true);
                setTimeout(() => {
                  setScript(false);
                }, 2000);
              }
            }}
            text={scriptText}
          >
            <LightTooltip
              open={openScript}
              title={t('label.copied')}
              placement="top-end"
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <ContentCopyIcon
                sx={{
                  color: '#A0A1B2',
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                }}
              />
            </LightTooltip>
          </CopyToClipboard>
        </Box>
      </Box>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 4 }}>
        * {t('description.copyScript')}
      </Typography>
      <Box mt={3}>
        <CopyToClipboard
          onCopy={(text, result) => {
            if (result) {
              setClicked(true);
              setButton(true);
              setTimeout(() => {
                setButton(false);
              }, 2000);
            }
          }}
          text={scriptText}
        >
          <LightTooltip
            open={openButton}
            title={t('label.copied')}
            placement="right"
            PopperProps={{ disablePortal: true }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Button
              size="large"
              color="secondary"
              endIcon={<ContentCopyIcon />}
            >
              {t('button.copyScript')}
            </Button>
          </LightTooltip>
        </CopyToClipboard>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={7}>
        <Button
          size="large"
          color="secondary"
          disabled={!clicked}
          onClick={() => setActive((v) => v + 1)}
        >
          {commonT('button.next')}
        </Button>
      </Box>
    </Box>
  );
};

export default ExternalRunScriptStep;
