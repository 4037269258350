import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import { AWSAccountService } from '@api/service';
import { QUERY_KEY } from '@constants';
import { externalTokenState } from '@pages/private/onboard/external/ExternalAccount.atoms';

export default () => {
  const token = useRecoilValue(externalTokenState);
  return useMutation({
    mutationKey: QUERY_KEY.ONBOARD.VERIFY_ARN,
    mutationFn: (variables: { arn: string; externalId: string }) =>
      AWSAccountService.verifyARN(variables.arn, variables.externalId, token),
  });
};
