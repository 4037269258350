import { createTheme } from '@mui/material';
import palette from '@theme/palette';
import typography from '@theme/typography';
import shadows from '@theme/shadows';
import breakpoints from '@theme/breakpoints';
import generateCostClipperTheme from '@theme/components';

const initialTheme = createTheme({
  palette,
  typography,
  shadows,
  breakpoints,
  spacing: 8,
});

const costClipperTheme = generateCostClipperTheme(initialTheme);

export default costClipperTheme;
