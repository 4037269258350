import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import { AWSCEService } from '@api/service';
import { ceTagKeysState } from '@atoms/aws';
import { QUERY_KEY } from '@constants';

export default function useGetUsageTagAutoComplete() {
  const keys = useRecoilValue(ceTagKeysState);
  return useQuery(
    [QUERY_KEY.AWS.CE.GET_USAGE_TAG_AC, ...keys],
    ({ queryKey }) => {
      const params = _.cloneDeep(queryKey);
      params.shift();
      return AWSCEService.getTagAutoComplete(params);
    },
    {
      suspense: true,
      useErrorBoundary: true,
      enabled: !_.isEmpty(keys),
    },
  );
}
