import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useGetUsage, useGetUsageAutoComplete } from '@hooks/api/aws/ce';
import { ceUsageState } from '@atoms/aws';
import { ValueFormatter } from '@utils';

export default function ServiceCount() {
  const { t } = useTranslation('usage');
  // const { data: usage } = useGetUsage();
  const { data: ac } = useGetUsageAutoComplete();
  const filterValue = useRecoilValue(ceUsageState);
  const value = useMemo(() => {
    // const included = filterValue.Services?.length ?? 0;
    // const excluded = filterValue.ServicesExclude?.length ?? 0;
    // if (usage && usage.items[0].GroupBy === 'SERVICE') {
    //   return ValueFormatter.toFixed(
    //     Math.max(_.toLength(usage.items[0].TotalCostByItems) - 1, 0),
    //     0,
    //   );
    // }
    // if (included) {
    //   return ValueFormatter.toFixed(included, 0);
    // }
    if (ac) {
      // if (excluded) {
      //   return ValueFormatter.toFixed(
      //     ac.items[0].NumberOfServices - excluded,
      //     0,
      //   );
      // }
      return ValueFormatter.toFixed(ac.items[0].NumberOfServices, 0);
    }
    return '-';
  }, [ac]);
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary" align="center">
        {t('text.service_count')}
      </Typography>
      <Typography variant="h3" color="tertiary" align="center" mt="8px">
        {value}
      </Typography>
    </>
  );
}
