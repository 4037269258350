import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useGetUsage } from '@hooks/api/aws/ce';
import { ceUsageState } from '@atoms/aws';
import { CEUsageTable, GrmtTableColumnInfo } from '@components/table';
import { ValueFormatter } from '@utils';

export default function UsageTable() {
  const { t } = useTranslation('usage');
  const filter = useRecoilValue(ceUsageState);
  const { data } = useGetUsage();
  const dateLabels = useMemo(() => {
    if (data?.items) {
      const { Table } = data.items[0];
      return Table.map((v) => v.Date);
    }
    return [];
  }, [data]);
  const serviceLabels = useMemo(() => {
    if (data?.items && data.items[0].GroupBy !== 'Cost') {
      const { TotalCostByItems } = data.items[0];
      return Object.keys(
        _.fromPairs(_.sortBy(_.toPairs(TotalCostByItems), 1).reverse()),
      ).filter((v) => v !== 'TotalCost');
    }
    return [];
  }, [data]);
  const tableData = useMemo(() => {
    const items: Array<Record<string, any>> = [];
    if (data?.items) {
      const { Table, GroupBy, TotalCost } = data.items[0];
      const tableSummary = {
        Name: t('text.total_costs'),
        TotalCost,
      };
      dateLabels.forEach((v) => {
        const item = Table.find((i) => i.Date === v);
        if (item) {
          Object.assign(tableSummary, { [item.Date.toString()]: item.Cost });
        }
      });
      items.push(tableSummary);
      if (GroupBy !== 'Cost') {
        const { TotalCostByItems } = data.items[0];
        serviceLabels.forEach((service) => {
          let Name = service;
          if (data.items[0].GroupBy === 'TAG' && _.isEmpty(service)) {
            Name = t('text.empty_tag_value');
          }
          const item = {
            Name,
            TotalCost: _.get(TotalCostByItems, service) ?? 0,
          };
          dateLabels.forEach((v) => {
            const byDate = Table.find((i) => i.Date === v);
            if (byDate) {
              const byItem = byDate.Items.find((j) => j.Name === service);
              if (byItem) {
                Object.assign(item, { [byDate.Date.toString()]: byItem.Cost });
              }
            }
          });
          items.push(item);
        });
      }
    }
    return items;
  }, [data?.items, dateLabels, serviceLabels, t]);
  const nameHeader = useMemo(() => {
    switch (data?.items[0].GroupBy) {
      case 'SERVICE':
        return t('text.service');
      case 'TAG':
        return t('text.tag');
      case 'LINKED_ACCOUNT':
        return t('text.linked_account');
      case 'PURCHASE_TYPE':
        return t('text.purchase_option');
      default:
        return ' ';
    }
  }, [data?.items, t]);
  const totalHeader = useMemo(() => {
    switch (data?.items[0].GroupBy) {
      case 'SERVICE':
        return t('text.table_total', { name: t('text.service') });
      case 'TAG':
        return t('text.table_total', { name: t('text.tag') });
      case 'LINKED_ACCOUNT':
        return t('text.table_total', { name: t('text.linked_account') });
      case 'PURCHASE_TYPE':
        return t('text.table_total', { name: t('text.purchase_option') });
      default:
        return t('text.table_total_cost');
    }
  }, [data?.items, t]);
  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(() => {
    const items: Array<GrmtTableColumnInfo> = [
      {
        Header: nameHeader,
        accessor: 'Name' as const,
        minWidth: 284,
        sticky: 'left',
      },
      {
        Header: totalHeader,
        accessor: 'TotalCost' as const,
        align: 'right',
        minWidth: 156,
        sticky: 'left',
        Cell: ({ value }) => ValueFormatter.toCurrency(value) ?? '-',
      },
    ];
    dateLabels.forEach((v) => {
      items.push({
        Header: ValueFormatter.toDateString(
          v,
          filter.Granuality === 'MONTHLY' ? 'MMM YYYY' : 'MMM-DD',
        ),
        accessor: v.toString(),
        align: 'right',
        minWidth: 96,
        Cell: ({ value }) => ValueFormatter.toCurrency(value) ?? '-',
      });
    });
    return items;
  }, [dateLabels, nameHeader, totalHeader, filter.Granuality]);
  if (!data?.items) {
    return null;
  }
  return <CEUsageTable columnInfo={columnInfo} data={tableData} />;
}
