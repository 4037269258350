import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRDSCoverage } from '@hooks/api/optimization/rds';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { DetailLinkIcon } from '@components/table/table.styled';
import { ROUTES } from '@routes';
import { ValueFormatter } from '@utils';
import { useAWSHelper } from '@hooks/helper';
import DatabaseCoverageFilter from './DatabaseCoverageFilter';

const DatabaseCoverageTable: React.FC = () => {
  const { data, onPageChange, pageSize } = useRDSCoverage();
  const { t } = useTranslation('optimization');
  const { getOptimizationAWSRegion } = useAWSHelper();

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        minWidth: 140,
        maxWidth: 140,
        disableSortBy: true,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.db.databaseId'),
        accessor: 'InstanceId' as const,
        maxWidth: 300,
        disableSortBy: true,
      },
      {
        Header: t('label.db.databaseType'),
        accessor: 'InstanceType' as const,
        minWidth: 150,
        maxWidth: 150,
        disableSortBy: true,
      },
      {
        Header: t('label.db.databaseEngine'),
        accessor: 'DatabaseEngine' as const,
        minWidth: 130,
        maxWidth: 150,
        disableSortBy: true,
      },
      {
        Header: t('label.common.od'),
        accessor: 'OnDemandHours' as const,
        minWidth: 136,
        Cell: ({ value, row }) => (
          <>
            {t('label.common.h', {
              value: ValueFormatter.toLocaleString(value),
            })}
            <br />
            {`${t('label.common.cost')}: ${
              ValueFormatter.toCurrency(row.original.OnDemandCost) || '-'
            }`}
          </>
        ),
      },
      {
        Header: t('label.db.ri'),
        accessor: 'Coverage' as const,
        minWidth: 190,
        maxWidth: 190,
        Cell: ({ value, row }) => (
          <>
            {t('label.common.h', {
              value: ValueFormatter.toLocaleString(row.original.ReservedHours),
            })}
            <br />
            {`${t('label.header.coverage')}: ${
              ValueFormatter.toPercent(value) || '-'
            }`}
          </>
        ),
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailLinkIcon
            to={`${ROUTES.DETAILS.DATABASE}/${row.original.InstanceId}?tab=purchasing`}
            requireCUR
          />
        ),
      },
    ],
    [getOptimizationAWSRegion],
  );

  return (
    <DataPaginationTable
      columnInfo={columnInfo}
      data={data?.body?.items}
      totalCount={data?.body?.totalCount}
      onPageChange={onPageChange}
      pageSize={pageSize}
      FilterElement={DatabaseCoverageFilter}
      CSVUrl="rdsri/coverage"
      enableFilter
      enableSort
      LastUpdate={data.body.extras.LatestUpdate}
    />
  );
};

export default DatabaseCoverageTable;
