import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';
import {
  Paper,
  Stack,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { secondary } from '@theme/colors';

const NotificationCta = React.memo(
  ({
    onClick,
    label,
  }: Pick<TypographyProps, 'onClick'> & { label: string }) => {
    const theme = useTheme();
    return (
      <Typography
        variant="body2"
        onClick={onClick}
        dangerouslySetInnerHTML={{ __html: label }}
        sx={{
          color: secondary[400],
          cursor: 'pointer',
          '> span': {
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            fontWeight: 500,
            textDecoration: 'underline',
          },
        }}
      />
    );
  },
);

export const NotificationPaper: React.FC<
  { label: string; cta: string; atom: RecoilState<boolean> } & Pick<
    TypographyProps,
    'onClick'
  >
> = ({ label, cta, atom, onClick }) => {
  const [state, setState] = useRecoilState(atom);
  if (!state) {
    return null;
  }
  return (
    <Paper
      variant="elevation"
      elevation={2}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 4,
        py: 2,
      }}
    >
      <Typography>{label}</Typography>
      <Stack direction="row" alignItems="center" spacing={3}>
        <NotificationCta label={cta} onClick={onClick} />
        <CloseIcon
          onClick={() => setState((v) => !v)}
          sx={{ cursor: 'pointer' }}
        />
      </Stack>
    </Paper>
  );
};

export const NotificationPermanentPaper: React.FC<
  { label: string; cta: string } & Pick<TypographyProps, 'onClick'>
> = ({ label, cta, onClick }) => (
  <Paper
    variant="elevation"
    elevation={2}
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      px: 4,
      py: 2,
    }}
  >
    <Typography>{label}</Typography>
    <NotificationCta label={cta} onClick={onClick} />
  </Paper>
);
