import { useState } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';
import { CMPService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default (InvoiceId?: string) => {
  const [Id, setId] = useState(InvoiceId ?? '');
  const query = useQuery(
    [QUERY_KEY.CMP.INVOICE_DETAIL, Id],
    ({ queryKey }) => CMPService.getInvoiceDetail(queryKey[1]),
    { suspense: true, useErrorBoundary: true, enabled: !_.isEmpty(Id) },
  );
  return {
    ...query,
    Id,
    setId,
  };
};
