import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WithAdmin, WithCMP } from '@hooks/hoc';
import { SuspenseBox, SuspensePaper } from '@components/styled/suspense';
import { BillingSummary, DepositHistory, PaymentHistory } from './components';

const BillingReportPage: React.FC = () => {
  const { t } = useTranslation('report');
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary" mb={2}>
        {t('title.billing_summary')}
      </Typography>
      <SuspenseBox>
        <BillingSummary />
      </SuspenseBox>
      <Typography variant="subtitle2" color="textSecondary" mt={6} mb={2}>
        {t('title.payment_history')}
      </Typography>
      <SuspensePaper sx={{ px: 7, py: 5 }}>
        <PaymentHistory />
      </SuspensePaper>
      <Typography variant="subtitle2" color="textSecondary" mt={6} mb={2}>
        {t('title.deposit_history')}
      </Typography>
      <SuspensePaper sx={{ px: 7, py: 5 }}>
        <DepositHistory />
      </SuspensePaper>
    </>
  );
};

export default WithCMP(WithAdmin(BillingReportPage));
