import React from 'react';
import { Paper } from '@mui/material';
import { SuspenseBox } from '@components/styled/suspense';
import { PlannerChart } from '../components';
import { CommittedTable } from './table';

const PlannerCommittedPage: React.FC = () => (
  <Paper square sx={{ px: 7, py: 5 }}>
    <SuspenseBox>
      <PlannerChart type="SP" />
    </SuspenseBox>
    <SuspenseBox mt={15}>
      <CommittedTable />
    </SuspenseBox>
  </Paper>
);

export default PlannerCommittedPage;
