import { useMutation } from 'react-query';
import { UserService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useMutation({
    mutationKey: QUERY_KEY.SETTINGS.USER.LOGIN_MASQUERADE,
    mutationFn: (variables: { uid: string }) =>
      UserService.loginAsMasquerade(variables.uid),
  });
