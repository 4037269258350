import { atom } from 'recoil';
import { localStorageEffect } from '@utils/recoil';
import { ATOM_KEY } from '@constants';

export default atom<RecommendCategory>({
  key: ATOM_KEY.OPTIMIZATION.RECOMMEND_CATEGORY,
  default: 'SavingsFirst',
  effects_UNSTABLE: [
    localStorageEffect<RecommendCategory>(
      ATOM_KEY.OPTIMIZATION.RECOMMEND_CATEGORY,
    ),
  ],
});
