import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleTypoTable } from '@components/styled';
import { byteToGib, ValueFormatter } from '@utils';
import { useAWSHelper } from '@hooks/helper';
import { useS3DetailsGeneral } from '@hooks/api/optimization/s3';

const DetailsStorageGeneral: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');
  const { getAWSRegionName } = useAWSHelper();
  const { data } = useS3DetailsGeneral();
  const generalInfo = useMemo(() => {
    if (data?.items?.length) {
      return [
        {
          label: t('label.storage.bucket_name'),
          value: data.items[0].BucketName,
        },
        {
          label: t('label.common.accountId'),
          value: data.items[0].AccountId,
        },
        {
          label: t('label.storage.objects'),
          value: ValueFormatter.toLocaleString(data.items[0].Objects, 0),
        },
        {
          label: `${t('label.storage.bucket_size')} (GiB)`,
          value: ValueFormatter.toLocaleString(byteToGib(data.items[0].Size)),
        },
        {
          label: t('label.common.modified_last'),
          value: ValueFormatter.toDateString(data.items[0].LastModified),
        },
        {
          label: `${t('label.common.cost')} (${commonT('label.days', {
            count: 30,
          })})`,
          value: ValueFormatter.toCurrency(data.items[0].Cost),
        },
      ];
    }
    return [];
  }, [data?.items, t, getAWSRegionName]);
  if (!data?.items?.length) {
    return null;
  }
  return <SimpleTypoTable data={generalInfo} disableOutline />;
};

export default DetailsStorageGeneral;
