/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { GrmtDefaultChartProps } from '@components/chart';
import { ValueFormatter } from '@utils';

type ReportBestPracticeChartProps = GrmtDefaultChartProps<{
  name: string;
  cost: number | string;
}> & {
  colors: Array<string>;
  dataMax?: string | number | Function | 'auto' | 'dataMin' | 'dataMax';
};

const ReportBestPracticeChart: React.FC<ReportBestPracticeChartProps> = ({
  data,
  colors,
  dataMax = 'auto',
}) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart data={data} margin={{ top: 24, bottom: 24, left: 8, right: 8 }}>
      <XAxis
        dataKey="name"
        stroke="none"
        tick={{ fill: '#A0A1B2', fontSize: '14px' }}
        tickLine={false}
        tickSize={0}
        tickMargin={24}
      />
      <YAxis domain={[0, dataMax]} hide />
      <Bar
        dataKey="cost"
        barSize={24}
        isAnimationActive={false}
        label={{
          position: 'top',
          stroke: 'none',
          fill: '#A0A1B2',
          fontSize: 14,
          offset: 16,
          formatter: (value: any) => {
            if (typeof value === 'number') {
              return ValueFormatter.toCurrency(value);
            }
            return value;
          },
        }}
        shape={({ width, height, fill, x, y }) => (
          <rect
            width={width}
            height={!Number.isNaN(Number(height)) ? height : 0}
            x={x}
            y={y}
            rx={12}
            fill={fill}
          />
        )}
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell_${entry.name}_${entry.cost}`}
            fill={colors[index % colors.length]}
          />
        ))}
      </Bar>
    </BarChart>
  </ResponsiveContainer>
);
ReportBestPracticeChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      cost: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]).isRequired,
    }).isRequired,
  ).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  dataMax: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
  ]),
};
ReportBestPracticeChart.defaultProps = {
  dataMax: 'auto',
};

export default React.memo(ReportBestPracticeChart);
