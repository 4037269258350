import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlannerRI } from '@hooks/api/planner';
import { useAWSHelper } from '@hooks/helper';
import { useToggleWithValue } from '@hooks/common';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { DetailButtonIcon } from '@components/table/table.styled';
import { ValueFormatter } from '@utils';
import ComputeRIUtilFilter from '@pages/private/optimization/compute/purchasing/table/ri/ComputeRIUtilFilter';
import ReservedDetailModal from './ReservedDetailModal';

const ReservedTable: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: plannerT } = useTranslation('planner');
  const { getOptimizationAWSRegion } = useAWSHelper();
  const { data, onPageChange, pageSize } = usePlannerRI();
  const [selected, openDetail, toggleDetail] =
    useToggleWithValue<EC2RIUtilization>();

  const columns = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        disableSortBy: true,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.common.instanceType'),
        accessor: 'InstanceType' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.common.ri', { context: 'number' }),
        accessor: 'NumberOfRI' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.header.utilization'),
        accessor: 'Utilization' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toPercent(value),
      },
      // TODO: Recommendation will be updated later
      // {
      //   Header: plannerT('label.recommendation'),
      //   accessor: 'RecommendPricingPlan' as const,
      //   align: 'center',
      //   disableSortBy: true,
      //   Cell: ({ value, row }) => {
      //     if (_.isEmpty(value)) {
      //       return '-';
      //     }
      //     return (
      //       <LinkTypography
      //         color="primary"
      //         onClick={() =>
      //           navigate(
      //             `${ROUTES.PLANNER.SIMULATION}/${
      //               row.original.InstanceType.split('.')[0]
      //             }`,
      //           )
      //         }
      //       >
      //         {plannerT(
      //           `recommendButton.${value.Aggressive.Type as PurchasingType}`,
      //         )}
      //       </LinkTypography>
      //     );
      //   },
      // },
      {
        Header: t('label.common.details'),
        align: 'center',
        Cell: ({ row }) => (
          <DetailButtonIcon onClick={() => toggleDetail(row.original)} />
        ),
      },
    ],
    [getOptimizationAWSRegion],
  );

  return (
    <>
      <DataPaginationTable
        TitleElement={plannerT('header.reserved')}
        columnInfo={columns}
        data={data?.body?.items}
        totalCount={data?.body?.totalCount}
        onPageChange={onPageChange}
        pageSize={pageSize}
        FilterElement={ComputeRIUtilFilter}
        enableSort
      />
      {openDetail && selected && (
        <ReservedDetailModal
          open={openDetail}
          onClose={toggleDetail}
          Item={selected}
        />
      )}
    </>
  );
};

export default ReservedTable;
