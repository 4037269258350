import React from 'react';
import moment from 'moment';
import { useEC2DataTransferChart } from '@hooks/api/optimization/ec2';
import { DataTransferChart } from '@components/chart/optimization';
import { ChartCoverBox } from '@components/styled/noData';

const DUMMY_DATA = [
  { Date: moment().toDate(), Cost: 1 },
  { Date: moment().toDate(), Cost: 3 },
  { Date: moment().toDate(), Cost: 2 },
  { Date: moment().toDate(), Cost: 5 },
  { Date: moment().toDate(), Cost: 15 },
  { Date: moment().toDate(), Cost: 13 },
  { Date: moment().toDate(), Cost: 5 },
  { Date: moment().toDate(), Cost: 8 },
  { Date: moment().toDate(), Cost: 11 },
  { Date: moment().toDate(), Cost: 13 },
  { Date: moment().toDate(), Cost: 6 },
  { Date: moment().toDate(), Cost: 8 },
  { Date: moment().toDate(), Cost: 17 },
  { Date: moment().toDate(), Cost: 14 },
];

const ComputeTransferChart: React.FC = () => {
  const { data } = useEC2DataTransferChart();
  return (
    <ChartCoverBox isEmpty={Boolean(!data?.items?.length)}>
      <DataTransferChart data={data?.items?.length ? data.items : DUMMY_DATA} />
    </ChartCoverBox>
  );
};

export default ComputeTransferChart;
