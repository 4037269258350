import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WithAdmin, WithCMP } from '@hooks/hoc';
import { SuspenseBox, SuspensePaper } from '@components/styled/suspense';
import {
  AdditionalServices,
  BillingSummary,
  CloudServiceDetails,
  DepositDetails,
  DiscountDetails,
} from './components';

const BillingReportDetailPage: React.FC = () => {
  const { t } = useTranslation('report');
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary" mb={2}>
        {t('title.bill_detail')}
      </Typography>
      <SuspenseBox>
        <BillingSummary />
      </SuspenseBox>
      <SuspensePaper sx={{ px: 7, py: 5, mt: 6 }}>
        <Typography variant="subtitle2" color="textSecondary" mb={2}>
          {t('title.cloud_service_details')}
        </Typography>
        <CloudServiceDetails />
      </SuspensePaper>
      <SuspensePaper sx={{ px: 7, py: 5, mt: 6 }}>
        <Typography variant="subtitle2" color="textSecondary" mb={2}>
          {t('title.additional_services')}
        </Typography>
        <AdditionalServices />
      </SuspensePaper>
      <SuspensePaper sx={{ px: 7, py: 5, mt: 6 }}>
        <Typography variant="subtitle2" color="textSecondary" mb={2}>
          {t('title.discount_details')}
        </Typography>
        <DiscountDetails />
      </SuspensePaper>
      <SuspensePaper sx={{ px: 7, py: 5, mt: 6 }}>
        <Typography variant="subtitle2" color="textSecondary" mb={2}>
          {t('title.deposit_details')}
        </Typography>
        <DepositDetails />
      </SuspensePaper>
    </>
  );
};

export default WithCMP(WithAdmin(BillingReportDetailPage));
