import { useCallback, useMemo } from 'react';
import {
  useCreateDatadog,
  useDeleteDatadog,
  useGetDatadog,
  useUpdateDatadog,
  useVerifyDatadog,
} from '@hooks/api/settings/integration';
import { useCurrentInfo } from '@hooks/api/common';

export default () => {
  const { currentCompany } = useCurrentInfo();
  const { data } = useGetDatadog();
  const {
    isSuccess: isVerified,
    isLoading: isVerifying,
    mutateAsync: verifyDatadog,
  } = useVerifyDatadog();
  const { isLoading: isCreating, mutateAsync: createDatadog } =
    useCreateDatadog();
  const { isLoading: isUpdating, mutateAsync: updateDatadog } =
    useUpdateDatadog();
  const { isLoading: isDeleting, mutateAsync: fetchDelete } =
    useDeleteDatadog();

  const hasDatadog = useMemo<boolean>(
    () => Boolean(data?.items?.length),
    [data?.items],
  );

  const datadogItem = useMemo<any>(() => {
    if (hasDatadog) {
      return data.items[0];
    }
    return undefined;
  }, [hasDatadog, data?.items]);

  const deleteDatadog = useCallback(async () => {
    if (currentCompany?.id) {
      try {
        await fetchDelete({ companyId: currentCompany.id });
      } catch (e) {
        console.error(e);
      }
    }
  }, [currentCompany?.id]);

  return {
    hasDatadog,
    datadogItem,
    verifyDatadog,
    createDatadog,
    updateDatadog,
    deleteDatadog,
    isVerified,
    isVerifying,
    isCreating,
    isUpdating,
    isDeleting,
  };
};
