import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export type SignUpForm = {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  password: string;
  confirm: string;
  accept: boolean;
};

export const useSignUpForm = (): UseValidation<SignUpForm> => {
  const { t } = useTranslation('validation');
  const { t: formT } = useTranslation('form');
  return {
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      password: '',
      confirm: '',
      accept: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .trim()
        .defined(
          t('custom.required', {
            context: 'post1',
            name: formT('holder.firstName'),
          }),
        ),
      lastName: Yup.string()
        .trim()
        .defined(
          t('custom.required', {
            context: 'post1',
            name: formT('holder.lastName'),
          }),
        ),
      companyName: Yup.string()
        .trim()
        .defined(
          t('custom.required', {
            context: 'post1',
            name: formT('holder.company'),
          }),
        ),
      email: Yup.string()
        .trim()
        .email(t('auth.email'))
        .defined(
          t('custom.required', {
            context: 'post1',
            name: formT('holder.email'),
          }),
        ),
      password: Yup.string()
        .trim()
        .matches(/^.*[a-z]+.*$/, t('auth.password.lower'))
        .matches(/^.*[0-9]+.*$/, t('auth.password.digit'))
        .matches(/^.*[A-Z]+.*$/, t('auth.password.upper'))
        .min(8, t('auth.password.length'))
        .defined(
          t('custom.required', {
            context: 'post2',
            name: formT('holder.password'),
          }),
        ),
      confirm: Yup.string()
        .trim()
        .oneOf([Yup.ref('password')], t('auth.confirm'))
        .defined(
          t('custom.required', {
            context: 'post1',
            name: formT('holder.confirm'),
          }),
        ),
      accept: Yup.bool().oneOf([true]).defined(t('common.required')),
    }).defined(),
  };
};
