import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useCurrentInfo } from '@hooks/api/common';
import { ROUTES } from '@routes';

export default () => {
  const navigate = useNavigate();
  const { currentAWSAccounts } = useCurrentInfo(false);
  const getAWSAccountNickname = useCallback(
    (id: string, nameFirst = false) => {
      if (currentAWSAccounts?.length) {
        const acc = currentAWSAccounts.find((v) => v.id === id);
        if (acc) {
          if (nameFirst) {
            return `${acc.name} (${id})`;
          }
          return `${id} (${acc.name})`;
        }
      }
      return id;
    },
    [currentAWSAccounts?.length],
  );
  const getAccountIdByUid = useCallback(
    (uid: string) => {
      if (uid && currentAWSAccounts?.length) {
        const acc = currentAWSAccounts.find((v) => v.uid === uid);
        if (acc) {
          return acc.id;
        }
      }
      return uid;
    },
    [currentAWSAccounts?.length],
  );
  const getAccountNameById = useCallback(
    (id: string): string => {
      if (currentAWSAccounts?.length) {
        const acc = currentAWSAccounts.find((v) => v.id === id);
        if (acc) {
          return acc.name || '';
        }
      }
      return '';
    },
    [currentAWSAccounts],
  );
  const findAWSAccountById = useCallback(
    (id: string): AWSAccount | undefined =>
      useMemo<AWSAccount | undefined>(() => {
        if (currentAWSAccounts?.length) {
          const acc = currentAWSAccounts.find((v) => v.id === id);
          if (acc) {
            return acc;
          }
        }
        return undefined;
      }, [currentAWSAccounts, id]),
    [currentAWSAccounts],
  );
  const findAWSAccountByUid = useCallback(
    (uid: string): AWSAccount | undefined =>
      useMemo<AWSAccount | undefined>(() => {
        if (currentAWSAccounts?.length) {
          const acc = currentAWSAccounts.find((v) => v.uid === uid);
          if (acc) {
            return acc;
          }
        }
        return undefined;
      }, [currentAWSAccounts, uid]),
    [currentAWSAccounts],
  );
  const findHasCUR = useCallback(
    (uid: string) => {
      if (currentAWSAccounts?.length) {
        const acc = currentAWSAccounts.find((v) => v.uid === uid);
        if (acc) {
          if (acc.isPayer) {
            return Boolean(acc.reportName?.length);
          }
          const payer = currentAWSAccounts.find(
            (v) => Boolean(acc.parentAccount) && v.uid === acc.parentAccount,
          );
          if (payer) {
            return Boolean(payer.reportName?.length);
          }
        }
      }
      return false;
    },
    [currentAWSAccounts],
  );
  const hasPayer = useMemo<boolean>(() => {
    if (currentAWSAccounts?.length) {
      const payers = currentAWSAccounts.filter((v) => v.isPayer);
      return Boolean(payers.length);
    }
    return false;
  }, [currentAWSAccounts]);
  const hasCUR = useMemo<boolean>(() => {
    if (currentAWSAccounts?.length) {
      const payers = currentAWSAccounts.filter(
        (v) => v.isPayer && v.reportName && v.reportName.length,
      );
      return Boolean(payers.length);
    }
    return false;
  }, [currentAWSAccounts]);
  const navigateToCur = useCallback(() => {
    if (currentAWSAccounts?.length) {
      if (currentAWSAccounts.length === 1) {
        navigate(`${ROUTES.ONBOARD.CUR}/${currentAWSAccounts[0].uid}`);
      } else {
        const accounts = _.cloneDeep(currentAWSAccounts);
        const payers = _.cloneDeep(accounts.filter((v) => v.isPayer));
        payers.forEach((payer) => {
          payer.linkedAccounts.forEach((uid) => {
            const idx = accounts.findIndex((v) => v.uid === uid);
            if (idx > -1) {
              accounts.splice(idx, 1);
            }
          });
          if (payer.reportName && payer.reportName.length > 0) {
            const payerIdx = accounts.findIndex((v) => v.uid === payer.uid);
            if (payerIdx > -1) {
              accounts.splice(payerIdx, 1);
            }
          }
        });
        if (accounts.length > 1) {
          navigate(ROUTES.SETTINGS.ACCOUNTS);
        } else if (accounts.length) {
          navigate(`${ROUTES.ONBOARD.CUR}/${accounts[0].uid}`);
        }
      }
    }
  }, [currentAWSAccounts]);

  return {
    getAWSAccountNickname,
    getAccountIdByUid,
    getAccountNameById,
    findAWSAccountById,
    findAWSAccountByUid,
    findHasCUR,
    hasPayer,
    hasCUR,
    navigateToCur,
  };
};
