import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

type DatadogForm = {
  companyId: string;
  APIKey: string;
  AppKey: string;
};

export const useDatadogForm = (): UseValidation<DatadogForm> => {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      companyId: '',
      APIKey: '',
      AppKey: '',
    },
    validationSchema: Yup.object({
      companyId: Yup.string().trim().defined(t('common.required')),
      APIKey: Yup.string().trim().defined(t('common.required')),
      AppKey: Yup.string().trim().defined(t('common.required')),
    }).defined(),
  };
};

type NewrelicForm = {
  companyId: string;
  APIKey: string;
  AccountId: string;
  QueryKey: string;
};

export const useNewrelicForm = (): UseValidation<NewrelicForm> => {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      companyId: '',
      APIKey: '',
      AccountId: '',
      QueryKey: '',
    },
    validationSchema: Yup.object({
      companyId: Yup.string().trim().defined(t('common.required')),
      APIKey: Yup.string().trim().defined(t('common.required')),
      AccountId: Yup.string().trim().defined(t('common.required')),
      QueryKey: Yup.string().trim().defined(t('common.required')),
    }).defined(),
  };
};
