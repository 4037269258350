/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCurrentInfo } from '@hooks/api/common';
import { useOverviewSummary } from '@hooks/api/overview';
import { useAWSAccountHelper } from '@hooks/helper';
import { SuspenseBox } from '@components/styled/suspense';
import { SavingsSummaryChart } from '@components/chart/overview';
import { QuestionTooltip } from '@components/styled/tooltip';
import { ValueFormatter } from '@utils';

const SavingsSummary: React.FC = () => {
  const { navigateToCur } = useAWSAccountHelper();
  const { curExists, curLoading, needDiagnostic, currentRole } =
    useCurrentInfo();
  const { t } = useTranslation('overview');
  const { data } = useOverviewSummary();
  return (
    <>
      <Box display="flex" justifyContent="space-between" px={7} py={6}>
        <Box display="flex">
          <Box>
            <Typography variant="subtitle2" mb={2}>
              {t('label.savingsSummary.totalSavings')}
            </Typography>
            <Typography variant="h1" color="primary">
              {data?.items?.length
                ? ValueFormatter.toCurrency(
                    data.items[0].TotalPossiblSavingCost,
                  ) || '-'
                : '-'}
            </Typography>
          </Box>
          <Box ml={9}>
            <Typography variant="subtitle2" mb={2}>
              {t('label.savingsSummary.monthlySavings')}
            </Typography>
            <Typography variant="h1" color="primary">
              {data?.items?.length
                ? ValueFormatter.toCurrency(
                    data.items[0].MonthlyPossibleSavingCost,
                  ) || '-'
                : '-'}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box>
            <Typography variant="subtitle2" mb={2}>
              {t('label.savingsSummary.actionItems')}
            </Typography>
            <Typography variant="h1" color="tertiary">
              {data?.items?.length
                ? ValueFormatter.toLocaleString(
                    data.items[0].TotalPossibleSavingActions,
                    0,
                  ) || '-'
                : '-'}
            </Typography>
          </Box>
          {curExists || (!curLoading && currentRole === 3) ? (
            <>
              {Boolean(data?.items?.length) && (
                <Box ml={9}>
                  <Button
                    variant="outlined"
                    size="large"
                    component="a"
                    href="#savings"
                  >
                    {t('label.savingsSummary.startSaving')}
                  </Button>
                </Box>
              )}
            </>
          ) : curLoading ? (
            <Box textAlign="center" ml={9}>
              <Stack direction="row" spacing={1}>
                <Typography variant="body2" color="textSecondary">
                  {t('label.savingsSummary.curLoading')}
                </Typography>
                {!needDiagnostic && (
                  <QuestionTooltip
                    title={t('label.savingsSummary.curLoadingTooltip')}
                    placement="top-end"
                  />
                )}
              </Stack>
            </Box>
          ) : (
            <Box textAlign="center" ml={9}>
              <Stack direction="row" spacing={1} mb={1}>
                <Typography variant="body2" color="textSecondary">
                  {t('label.savingsSummary.viewReport')}
                </Typography>
                <QuestionTooltip
                  title={t('label.savingsSummary.curTooltip')}
                  placement="top-end"
                />
              </Stack>
              <Button variant="outlined" size="large" onClick={navigateToCur}>
                {t('label.savingsSummary.configure')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {curExists && (
        <>
          <Divider />
          <SuspenseBox px={7} py={6}>
            <SavingsSummaryChart />
          </SuspenseBox>
        </>
      )}
    </>
  );
};

export default SavingsSummary;
