import * as Yup from 'yup';

export type SubscribeForm = {
  ProductId: string;
  PaymentMethod: string;
  PromotionCode: string;
};

export const useSubscribeForm = (): UseValidation<SubscribeForm> => ({
  initialValues: {
    ProductId: '',
    PaymentMethod: '',
    PromotionCode: '',
  },
  validationSchema: Yup.object({
    ProductId: Yup.string().trim().defined(),
    PaymentMethod: Yup.string().trim().defined(),
    PromotionCode: Yup.string().trim(),
  }).defined(),
});
