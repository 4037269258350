import React from 'react';
import { Backdrop, Box } from '@mui/material';
import { ReactComponent as CCLogo } from '@assets/images/cc_logo_inverse.svg';

const Splash: React.FC = () => (
  <Backdrop
    open
    sx={{
      backgroundColor: '#00000080',
      zIndex: (theme) => theme.zIndex.drawer + 1,
    }}
  >
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <CCLogo width={450} />
    </Box>
  </Backdrop>
);

export default React.memo(Splash);
