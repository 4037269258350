/* eslint-disable default-case,jsx-a11y/role-supports-aria-props */
import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Radio,
  RadioGroup,
  ListItemText,
  ClickAwayListener,
  InputAdornment,
  Typography,
} from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import {
  ArrowDropDown,
  ArrowDropUp,
  InfoOutlined,
  Search,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useGetUsageAutoComplete } from '@hooks/api/aws/ce';
import {
  FieldLabel,
  UsageFilterInput,
  UsagePopper,
  UsagePopperComponent,
  UsageTooltip,
} from './styled';

type UsageAutocompleteProps = {
  basis:
    | 'SERVICE'
    | 'LINKED_ACCOUNT'
    | 'LINKED_COMPANIES'
    | 'INSTANCE_TYPE'
    | 'USAGE_TYPE'
    | 'USAGE_TYPE_GROUP';
  filter: CEUsageRequest;
  setFilter: React.Dispatch<React.SetStateAction<CEUsageRequest>>;
};
export default function UsageAutocomplete({
  basis,
  filter,
  setFilter,
}: UsageAutocompleteProps) {
  const { t } = useTranslation('usage');
  const { t: commonT } = useTranslation('common');
  const [isExclude, setIsExclude] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const popperId = open ? `usage-popper-${basis}` : undefined;
  const { data: response } = useGetUsageAutoComplete();
  const nameTranslationKey = useMemo(() => {
    switch (basis) {
      case 'LINKED_ACCOUNT':
        return 'filter.linked_account';
      case 'INSTANCE_TYPE':
        return 'filter.instance_type';
      case 'USAGE_TYPE':
        return 'filter.usage_type';
      case 'USAGE_TYPE_GROUP':
        return 'filter.usage_type_group';
      default:
        return 'filter.service';
    }
  }, [basis]);
  const textLabel = useMemo(() => {
    switch (basis) {
      case 'LINKED_ACCOUNT':
        return (
          <Box
            sx={{ display: 'inline-flex', alignItems: 'center', gap: '8px' }}
          >
            {t('filter.linked')}
            <UsageTooltip
              title={t('text.linked_tooltip')}
              placement="right"
              arrow
            >
              <InfoOutlined
                sx={{ width: '12px', height: '12px', color: '#737480' }}
              />
            </UsageTooltip>
          </Box>
        );
      case 'INSTANCE_TYPE':
        return t('filter.instance_type');
      case 'USAGE_TYPE':
        return t('filter.usage_type');
      case 'USAGE_TYPE_GROUP':
        return t('filter.usage_type_group');
      default:
        return t('filter.label', { name: t('filter.service') });
    }
  }, [basis, t]);
  const handleOpen = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      switch (basis) {
        case 'SERVICE':
          setIsExclude(typeof filter.ServicesExclude !== 'undefined');
          break;
        case 'INSTANCE_TYPE':
          setIsExclude(typeof filter.InstanceTypeExclude !== 'undefined');
          break;
        case 'USAGE_TYPE':
          setIsExclude(typeof filter.UsageTypeExclude !== 'undefined');
          break;
        case 'USAGE_TYPE_GROUP':
          setIsExclude(typeof filter.UsageTypeGroupExclude !== 'undefined');
          break;
      }
      setAnchorEl(e.currentTarget);
    },
    [basis, filter],
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const values = useMemo(() => {
    switch (basis) {
      case 'SERVICE':
        return filter.ServicesExclude ?? filter.Services ?? [];
      case 'LINKED_ACCOUNT':
        return filter.AccountIdList ?? [];
      case 'INSTANCE_TYPE':
        return filter.InstanceTypeExclude ?? filter.InstanceType ?? [];
      case 'USAGE_TYPE':
        return filter.UsageTypeExclude ?? filter.UsageType ?? [];
      case 'USAGE_TYPE_GROUP':
        return filter.UsageTypeGroupExclude ?? filter.UsageTypeGroup ?? [];
      default:
        return [];
    }
  }, [basis, filter]);
  const optionsOrigin = useMemo(() => {
    if (response?.items?.length) {
      switch (basis) {
        case 'SERVICE':
          return _.uniq(response.items[0].Services ?? []);
        case 'LINKED_ACCOUNT':
          return _.uniq(response.items[0].Accounts ?? []);
        case 'INSTANCE_TYPE':
          return _.uniq(response.items[0].InstanceTypes ?? []);
        case 'USAGE_TYPE':
          return _.uniq(response.items[0].UsageTypes ?? []);
        case 'USAGE_TYPE_GROUP':
          return _.uniq(response.items[0].UsageTypeGroups ?? []);
      }
    }
    return [];
  }, [basis, response]);
  const options = useMemo(() => {
    return _.concat(['CC_SELECT_ALL'], optionsOrigin);
  }, [optionsOrigin]);
  const setValues = useCallback(
    (newValue: string[], manualExclude?: boolean) => {
      const exclusion = manualExclude ?? isExclude;
      if (basis === 'SERVICE') {
        if (exclusion) {
          setFilter((v) => ({
            ...v,
            ServicesExclude: newValue,
            Services: undefined,
          }));
        } else {
          setFilter((v) => ({
            ...v,
            Services: newValue,
            ServicesExclude: undefined,
          }));
        }
        return;
      }
      if (basis === 'INSTANCE_TYPE') {
        if (exclusion) {
          setFilter((v) => ({
            ...v,
            InstanceTypeExclude: newValue,
            InstanceType: undefined,
          }));
        } else {
          setFilter((v) => ({
            ...v,
            InstanceType: newValue,
            InstanceTypeExclude: undefined,
          }));
        }
        return;
      }
      if (basis === 'USAGE_TYPE') {
        if (exclusion) {
          setFilter((v) => ({
            ...v,
            UsageTypeExclude: newValue,
            UsageType: undefined,
          }));
        } else {
          setFilter((v) => ({
            ...v,
            UsageType: newValue,
            UsageTypeExclude: undefined,
          }));
        }
        return;
      }
      if (basis === 'USAGE_TYPE_GROUP') {
        if (exclusion) {
          setFilter((v) => ({
            ...v,
            UsageTypeGroupExclude: newValue,
            UsageTypeGroup: undefined,
          }));
        } else {
          setFilter((v) => ({
            ...v,
            UsageTypeGroup: newValue,
            UsageTypeGroupExclude: undefined,
          }));
        }
        return;
      }
      if (basis === 'LINKED_ACCOUNT') {
        setFilter((v) => ({
          ...v,
          AccountIdList: newValue,
          LinkedCompanies: undefined,
          LinkedCompaniesExclude: undefined,
        }));
      }
    },
    [basis, setFilter, isExclude],
  );
  const isAllSelected = useMemo(() => {
    return optionsOrigin.every((e) => values.includes(e)) ?? false;
  }, [optionsOrigin, values]);
  const isPartialSelected = useMemo(() => {
    return optionsOrigin.some((e) => values.includes(e)) ?? false;
  }, [optionsOrigin, values]);
  const toggleAll = useCallback(() => {
    if (isAllSelected) {
      setValues([]);
    } else {
      setValues(optionsOrigin ?? []);
    }
  }, [optionsOrigin, isAllSelected, setValues]);
  const getChecked = useCallback(
    (option: string, selected: boolean) => {
      if (option === 'CC_SELECT_ALL') {
        return isAllSelected;
      }
      return selected;
    },
    [isAllSelected],
  );
  const getIndeterminate = useCallback(
    (option: string) => {
      if (option === 'CC_SELECT_ALL') {
        return isPartialSelected && !isAllSelected;
      }
      return undefined;
    },
    [isAllSelected, isPartialSelected],
  );
  const getOptionLabel = useCallback(
    (option: string) => {
      if (option === 'CC_SELECT_ALL') {
        return t('text.select_all', {
          count: Math.max(optionsOrigin.length, 0),
        });
      }
      if (_.isEmpty(option)) {
        return t('text.none_option', { name: t(nameTranslationKey) });
      }
      if (basis === 'LINKED_COMPANIES' && response?.items?.length) {
        return response.items[0].LinkedCompanies?.find((v) => v.UID === option)
          ?.Name;
      }
      return option;
    },
    [basis, optionsOrigin.length, t, nameTranslationKey],
  );
  const placeholder = useMemo(() => {
    if (!optionsOrigin.length) {
      return t('filter.disabled', { name: t(nameTranslationKey) });
    }
    if (values.length) {
      return t(`filter.${isExclude ? 'excluded' : 'included'}`, {
        count: values.length,
      });
    }
    return t('filter.selected_all', { name: t(nameTranslationKey) });
  }, [values, optionsOrigin, isExclude, nameTranslationKey, t]);
  const handleChangeExclusion = useCallback(
    (v: boolean) => {
      setIsExclude(v);
      setValues(values, v);
    },
    [values, setValues],
  );
  return (
    <>
      <FieldLabel>{textLabel}</FieldLabel>
      <TextField
        placeholder={placeholder}
        InputProps={{
          onClick: handleOpen,
          endAdornment: (
            <InputAdornment position="end">
              {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </InputAdornment>
          ),
        }}
        fullWidth
        sx={(theme) => ({
          [`> .${outlinedInputClasses.root}`]: {
            cursor: 'pointer',
            pr: '8px',
            [`> .${outlinedInputClasses.input}`]: {
              cursor: 'pointer',
              userSelect: 'none',
              pr: 0,
              ...(Boolean(values.length) && {
                '&::placeholder': {
                  color: theme.palette.text.primary,
                },
                '&::-webkit-input-placeholder': {
                  color: theme.palette.text.primary,
                },
                '&::-ms-input-placeholder': {
                  color: theme.palette.text.primary,
                },
              }),
            },
            [`> .${inputAdornmentClasses.root}`]: {
              '> svg': {
                width: '20px',
                height: '20px',
                color: '#a0a1b2',
              },
            },
          },
        })}
      />
      <UsagePopper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            {basis !== 'LINKED_ACCOUNT' && (
              <Box sx={{ p: '16px', borderBottom: '1px solid #f0f0f7' }}>
                <RadioGroup
                  value={isExclude}
                  onChange={(e, v) => handleChangeExclusion(v === 'true')}
                  row
                >
                  <FormControlLabel
                    control={<Radio sx={{ p: 0, pr: '8px' }} />}
                    value={false}
                    label={t('filter.include')}
                    componentsProps={{
                      typography: {
                        variant: 'body2',
                      },
                    }}
                    sx={{ ml: 0 }}
                  />
                  <FormControlLabel
                    control={<Radio sx={{ p: 0, pr: '8px' }} />}
                    value
                    label={t('filter.exclude')}
                    componentsProps={{
                      typography: {
                        variant: 'body2',
                      },
                    }}
                    sx={{ ml: 0 }}
                  />
                </RadioGroup>
              </Box>
            )}
            <Autocomplete
              open
              multiple
              disableCloseOnSelect
              disableClearable
              PopperComponent={UsagePopperComponent}
              value={values}
              onClose={(e, reason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              onChange={(event, value, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                if (value.find((v) => v === 'CC_SELECT_ALL')) {
                  toggleAll();
                  return;
                }
                setValues(value);
              }}
              options={options}
              getOptionLabel={(option) => option}
              noOptionsText={t('text.no_tag_keys')}
              renderTags={() => null}
              renderOption={(props, option, { selected }) => (
                <li {...props} aria-selected={false}>
                  <Checkbox
                    checked={getChecked(option, selected)}
                    indeterminate={getIndeterminate(option)}
                    sx={{ p: 0, pr: '8px' }}
                  />
                  <ListItemText sx={{ my: 0, wordWrap: 'break-word' }}>
                    {getOptionLabel(option)}
                  </ListItemText>
                </li>
              )}
              renderInput={(params) => (
                <UsageFilterInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                  autoFocus
                  placeholder={t('filter.filtering', {
                    name: t(nameTranslationKey),
                  })}
                  fullWidth
                />
              )}
              disabled={!optionsOrigin.length}
            />
          </div>
        </ClickAwayListener>
      </UsagePopper>
    </>
  );
}
