import { atom } from 'recoil';
import { ATOM_KEY } from '@constants';

export const externalStepState = atom<number>({
  key: ATOM_KEY.ONBOARD.EXTERNAL_STEP,
  default: 0,
});

export const externalPageState = atom<boolean>({
  key: ATOM_KEY.ONBOARD.EXTERNAL_PAGE,
  default: true,
});

export const externalUserIdState = atom<string | undefined>({
  key: ATOM_KEY.ONBOARD.EXTERNAL_USER_ID,
  default: undefined,
});

export const externalCompanyIdState = atom<string | undefined>({
  key: ATOM_KEY.ONBOARD.EXTERNAL_COMPANY_ID,
  default: undefined,
});

export const externalUidState = atom<string | undefined>({
  key: ATOM_KEY.ONBOARD.EXTERNAL_CREATED_UID,
  default: undefined,
});

export const externalTokenState = atom<string | undefined>({
  key: ATOM_KEY.ONBOARD.EXTERNAL_TOKEN,
  default: undefined,
});
