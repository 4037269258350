import React, { useEffect } from 'react';
import { DialogModal, DialogModalProps } from '@components/modal';
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCurrentInfo } from '@hooks/api/common';
import { useUpdateUser } from '@hooks/api/settings/user';
import { hashPassword } from '@utils';
import { useSnackbar } from '@hooks/common';
import { useChangePasswordForm } from './SettingsProfilePage.form';

const ChangePasswordModal: React.FC<DialogModalProps> = ({ open, onClose }) => {
  const { currentUser } = useCurrentInfo();
  const { isLoading: isFetching, mutateAsync } = useUpdateUser();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { t: commonT } = useTranslation('common');
  const { t: formT } = useTranslation('form');
  const { t: toastT } = useTranslation('toast');
  const { t: dialogT } = useTranslation('dialog');
  const { initialValues, validationSchema } = useChangePasswordForm();
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (v) => {
      if (currentUser) {
        try {
          await mutateAsync({
            original: currentUser,
            changes: {
              password: hashPassword(v.password),
            },
          });
          showSuccessSnackbar(toastT('success.settings.profile.password'));
          if (onClose) {
            onClose();
          }
        } catch (e) {
          showErrorSnackbar(toastT('error.general'));
        }
      }
    },
  });

  useEffect(() => {
    if (currentUser?.password) {
      setFieldValue('original', currentUser.password);
    }
  }, [currentUser?.password]);

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="sm"
      HeaderProps={{
        icon: 'Settings',
        label: dialogT('settings.profile.password.title'),
      }}
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: commonT('button.cancel'),
        },
        {
          loading: isFetching,
          disabled: !isValid,
          type: 'submit',
          label: commonT('button.change'),
        },
      ]}
    >
      <TextField
        variant="standard"
        name="current"
        type="password"
        inputMode="text"
        value={values.current}
        onChange={handleChange}
        onBlur={handleBlur}
        label={formT('holder.currentPassword')}
        autoComplete="current-password"
        autoCapitalize="off"
        error={Boolean(errors.current && touched.current)}
        helperText={
          Boolean(errors.current && touched.current) && String(errors.current)
        }
        sx={{ mb: 3 }}
        fullWidth
      />
      <TextField
        variant="standard"
        name="password"
        type="password"
        inputMode="text"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        label={formT('holder.password')}
        autoComplete="new-password"
        autoCapitalize="off"
        error={Boolean(errors.password && touched.password)}
        helperText={
          Boolean(errors.password && touched.password) &&
          String(errors.password)
        }
        sx={{ mb: 3 }}
        fullWidth
      />
      <TextField
        variant="standard"
        name="confirm"
        type="password"
        inputMode="text"
        value={values.confirm}
        onChange={handleChange}
        onBlur={handleBlur}
        label={formT('holder.confirm')}
        autoComplete="new-password"
        autoCapitalize="off"
        error={Boolean(errors.confirm && touched.confirm)}
        helperText={
          Boolean(errors.confirm && touched.confirm) && String(errors.confirm)
        }
        fullWidth
      />
    </DialogModal>
  );
};

export default ChangePasswordModal;
