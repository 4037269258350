import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WithCUR, WithTrial } from '@hooks/hoc';
import { detailsS3State } from '@atoms/details';
import { SuspensePaper } from '@components/styled/suspense';
import DetailsStorageInformation from './DetailsStorageInformation';
import DetailsStorageGeneral from './DetailsStorageGeneral';

const DetailsStoragePage: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { bucketName } = useParams();
  const setBucketName = useSetRecoilState(detailsS3State);
  useEffect(() => {
    if (bucketName) {
      setBucketName(bucketName);
    }
  }, [bucketName]);
  useEffect(() => () => setBucketName(undefined), []);
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary" my={2}>
        {t('label.common.general')}
      </Typography>
      <SuspensePaper>
        <DetailsStorageGeneral />
      </SuspensePaper>
      <SuspensePaper sx={{ px: 7, py: 5, mt: 6 }}>
        <DetailsStorageInformation />
      </SuspensePaper>
    </>
  );
};
export default WithTrial(WithCUR(DetailsStoragePage));
