import React, { useCallback, useEffect, useState } from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { RegionMap } from '@components/ui';
import { useCurrentInfo } from '@hooks/api/common';
import { useRequestDiagnostic } from '@hooks/api/onboard';
import { useNavigate, useParams } from 'react-router-dom';
import { useAWSHelper } from '@hooks/helper';
import { ROUTES } from '@routes';
import { useTagManager } from '@hooks/common';
import { diagnosticStepState } from '../RunDiagnostic.atoms';

const SelectRegionStep: React.FC<{ isPayer: boolean }> = ({ isPayer }) => {
  const { t } = useTranslation('onboard');
  const { t: commonT } = useTranslation('common');
  const { uid } = useParams();
  const navigate = useNavigate();
  const [selectedRegions, setSelectedRegions] = useState<
    Set<SupportedAWSRegion>
  >(new Set<SupportedAWSRegion>());
  const handleSelectedRegion = useCallback(
    (code: SupportedAWSRegion) => {
      if (selectedRegions.has(code)) {
        setSelectedRegions((prev) => {
          prev.delete(code);
          return new Set<SupportedAWSRegion>(prev);
        });
      } else {
        setSelectedRegions((prev) => {
          prev.add(code);
          return new Set<SupportedAWSRegion>(prev);
        });
      }
    },
    [selectedRegions],
  );
  const { getAWSRegionName } = useAWSHelper();
  const setActive = useSetRecoilState(diagnosticStepState);
  const { sendDataLayer } = useTagManager();
  const { mutateAsync, isLoading } = useRequestDiagnostic();
  const { currentAWSAccounts, isDiagnosticRunning } = useCurrentInfo();
  const doRequest = useCallback(async () => {
    if (currentAWSAccounts?.length) {
      try {
        await mutateAsync({
          id: (uid || currentAWSAccounts[0].uid) ?? '',
          regions: Array.from(selectedRegions),
        });
        sendDataLayer(
          isPayer
            ? 'cc-onboard-diagnostic-payer-run'
            : 'cc-onboard-diagnostic-linked-run',
        );
        setActive((v) => v + 1);
      } catch (e) {
        console.error(e);
      }
    }
  }, [currentAWSAccounts?.length, selectedRegions, uid, isPayer]);
  useEffect(() => {
    if (isDiagnosticRunning) {
      navigate(ROUTES.ONBOARD.REPORT);
    }
  }, [isDiagnosticRunning]);
  return (
    <Box>
      <Typography variant="h3">{t('header.selectRegion')}</Typography>
      <Typography
        variant="body2"
        mt={2}
        dangerouslySetInnerHTML={{
          __html: t('description.selectRegion'),
        }}
      />
      <Box width="inherit" height="inherit" mt={6}>
        <RegionMap
          onClick={handleSelectedRegion}
          selectedRegions={Array.from(selectedRegions)}
        />
      </Box>
      <Grid container spacing={1} mt={3}>
        {Array.from(selectedRegions).map((code) => (
          <Grid key={`chip-${code}`} item>
            <Chip
              size="small"
              variant="outlined"
              color="secondary"
              label={`${getAWSRegionName(code)} / ${code}`}
              onDelete={() => handleSelectedRegion(code)}
            />
          </Grid>
        ))}
      </Grid>
      <Box textAlign="right" mt={7}>
        <LoadingButton
          variant="contained"
          size="large"
          color="secondary"
          onClick={doRequest}
          loading={isLoading}
        >
          {commonT('button.next')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default SelectRegionStep;
