import { useQuery } from 'react-query';
import { AWSCEService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default function useGetUsageAutoComplete() {
  return useQuery(
    QUERY_KEY.AWS.CE.GET_USAGE_AC,
    () => AWSCEService.getAutoComplete(),
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
}
