import { atom, useResetRecoilState } from 'recoil';
import { ATOM_KEY } from '@constants';
import { useEffect } from 'react';

export const simulationStepState = atom({
  key: ATOM_KEY.PLANNER.STEP,
  default: 0,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet(() => window.scrollTo(0, 0));
    },
  ],
});

export const selectedRecommendState = atom<'None' | 'Aggressive' | 'Passive'>({
  key: ATOM_KEY.PLANNER.RECOMMEND,
  default: 'None',
});

export const discountProgramState = atom<'reserved' | 'savingsplan'>({
  key: ATOM_KEY.PLANNER.DISCOUNT_PROGRAM,
  default: 'reserved',
});

export const riSimulationState = atom<{
  OfferingClass: 'standard' | 'convertible';
  PaymentOption: PaymentOption;
  Duration: 1 | 3;
  InstanceType: string;
  NumberOfRI: number;
}>({
  key: ATOM_KEY.PLANNER.RI_SIMULATION,
  default: {
    OfferingClass: 'standard',
    PaymentOption: 'No Upfront',
    Duration: 1,
    InstanceType: '',
    NumberOfRI: 0,
  },
});

export const spSimulationState = atom<{
  PlanType: 'EC2Instance' | 'Compute';
  PaymentOption: PaymentOption;
  Duration: 1 | 3;
  Commitment: number;
}>({
  key: ATOM_KEY.PLANNER.SP_SIMULATION,
  default: {
    PlanType: 'EC2Instance',
    PaymentOption: 'No Upfront',
    Duration: 1,
    Commitment: 0,
  },
});

export const simulateRequestState = atom<SimulationRequest | undefined>({
  key: ATOM_KEY.PLANNER.SIMULATION_REQUEST,
  default: undefined,
});

export const simulateResponseState = atom<SimulationResult | undefined>({
  key: ATOM_KEY.PLANNER.SIMULATION_RESPONSE,
  default: undefined,
});

export const useResetSimulationStates = () => {
  const resetStep = useResetRecoilState(simulationStepState);
  const resetRecommend = useResetRecoilState(selectedRecommendState);
  const resetDiscount = useResetRecoilState(discountProgramState);
  const resetRI = useResetRecoilState(riSimulationState);
  const resetSP = useResetRecoilState(spSimulationState);
  const resetRequest = useResetRecoilState(simulateRequestState);
  const resetResponse = useResetRecoilState(simulateResponseState);
  useEffect(() => {
    resetStep();
    resetRecommend();
    resetDiscount();
    resetRI();
    resetSP();
    resetRequest();
    resetResponse();
  }, []);
};
