import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { GrmtAccordion } from '@components/styled/accordion';
import { ValueFormatter } from '@utils';

const BillingServiceDetails: React.FC = () => {
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary">
        Cloud Service Details
      </Typography>
      <Stack spacing={2} px={3} mt={2}>
        <GrmtAccordion
          Title={
            <Box display="flex" flexGrow={1} justifyContent="space-between">
              <Typography>AWS Account 123456789012</Typography>
              <Typography>{ValueFormatter.toCurrency(5463.88)}</Typography>
            </Box>
          }
          openInitial
        >
          <Box>
            <Grid container justifyContent="space-between">
              <Grid item py={1} xs={1}>
                <Typography variant="body2" color="textSecondary">
                  NO.
                </Typography>
              </Grid>
              <Grid item py={1} xs>
                <Typography variant="body2" color="textSecondary">
                  Service
                </Typography>
              </Grid>
              <Grid item py={1} xs={2}>
                <Typography variant="body2" color="textSecondary" align="right">
                  Amount
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item py={1} xs={1}>
                <Typography variant="body2">1</Typography>
              </Grid>
              <Grid item py={1} xs>
                <Typography variant="body2">Amazon CloudFront</Typography>
              </Grid>
              <Grid item py={1} xs={2}>
                <Typography variant="body2" align="right">
                  {ValueFormatter.toCurrency(1234)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item py={1} xs={1}>
                <Typography variant="body2">2</Typography>
              </Grid>
              <Grid item py={1} xs>
                <Typography variant="body2">
                  Amazon DocumentDB (with MongoDB compatibility)
                </Typography>
              </Grid>
              <Grid item py={1} xs={2}>
                <Typography variant="body2" align="right">
                  {ValueFormatter.toCurrency(1234)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </GrmtAccordion>
        <GrmtAccordion
          Title={
            <Box display="flex" flexGrow={1} justifyContent="space-between">
              <Typography>AWS Account 123456789012</Typography>
              <Typography>{ValueFormatter.toCurrency(5463.88)}</Typography>
            </Box>
          }
        >
          <Box>
            <Grid container justifyContent="space-between">
              <Grid item py={1} xs={1}>
                <Typography variant="body2" color="textSecondary">
                  NO.
                </Typography>
              </Grid>
              <Grid item py={1} xs>
                <Typography variant="body2" color="textSecondary">
                  Service
                </Typography>
              </Grid>
              <Grid item py={1} xs={2}>
                <Typography variant="body2" color="textSecondary" align="right">
                  Amount
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item py={1} xs={1}>
                <Typography variant="body2">1</Typography>
              </Grid>
              <Grid item py={1} xs>
                <Typography variant="body2">Amazon CloudFront</Typography>
              </Grid>
              <Grid item py={1} xs={2}>
                <Typography variant="body2" align="right">
                  {ValueFormatter.toCurrency(1234)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </GrmtAccordion>
      </Stack>
    </>
  );
};

export default BillingServiceDetails;
