import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useRDSInformation,
  useRDSUtilizationChart,
} from '@hooks/api/optimization/rds';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import {
  DetailLinkIcon,
  TableUtilizationRecommendation,
} from '@components/table/table.styled';
import { ROUTES } from '@routes';
import { ValueFormatter } from '@utils';
import { useAWSHelper } from '@hooks/helper';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  rdsCPUState,
  rdsMemState,
  recommendCategoryState,
  recommendPeriodState,
} from '@atoms/optimization';
import _ from 'lodash';
import DatabaseUtilFilter from './DatabaseUtilFilter';

const DatabaseUtilTable: React.FC = () => {
  const { t } = useTranslation('optimization');
  const period = useRecoilValue(recommendPeriodState);
  const category = useRecoilValue(recommendCategoryState);
  const { data, onPageChange, pageSize } = useRDSInformation();
  const { data: chartData } = useRDSUtilizationChart();
  const { getOptimizationAWSRegion } = useAWSHelper();

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        minWidth: 140,
        maxWidth: 140,
        disableSortBy: true,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.db.clusterId'),
        accessor: 'ClusterId' as const,
        maxWidth: 104,
        disableSortBy: true,
      },
      {
        Header: t('label.db.databaseId'),
        accessor: 'InstanceId' as const,
        maxWidth: 150,
        disableSortBy: true,
      },
      {
        Header: t('label.db.databaseType'),
        accessor: 'InstanceType' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.db.databaseEngine'),
        accessor: 'DatabaseEngine' as const,
        maxWidth: 114,
        disableSortBy: true,
      },
      {
        Header: t('label.util.cpu_max'),
        accessor: 'CPUMax' as const,
        minWidth: 128,
        maxWidth: 128,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toPercent(value) || '-',
      },
      {
        Header: t('label.util.mem_max'),
        accessor: 'MemMax' as const,
        minWidth: 128,
        maxWidth: 128,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toPercent(value) || '-',
      },
      {
        Header: t('label.common.savings'),
        accessor: 'MonthlySaving' as const,
        align: 'right',
        Cell: ({ value, row }) => {
          if (
            value &&
            _.has(
              row.original,
              `RecommendType.${period}.${category}.RecommendType`,
            )
          ) {
            return (
              <>
                {ValueFormatter.toCurrency(value)}
                <br />(
                {_.get(
                  row.original,
                  `RecommendType.${period}.${category}.RecommendType`,
                )}
                )
              </>
            );
          }
          return '-';
        },
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailLinkIcon
            to={`${ROUTES.DETAILS.DATABASE}/${row.original.InstanceId}?tab=utilization`}
            requireCUR
          />
        ),
      },
    ],
    [getOptimizationAWSRegion, period, category],
  );
  const setRDSCPU = useSetRecoilState(rdsCPUState);
  const setRDSMem = useSetRecoilState(rdsMemState);
  useEffect(
    () => () => {
      setRDSCPU(undefined);
      setRDSMem(undefined);
    },
    [],
  );
  return (
    <DataPaginationTable
      data={data?.body?.items}
      columnInfo={columnInfo}
      totalCount={data?.body?.totalCount}
      pageSize={pageSize}
      onPageChange={onPageChange}
      TitleElement={<TableUtilizationRecommendation />}
      FilterElement={DatabaseUtilFilter}
      CSVUrl="rds"
      enableFilter
      enableSort
      LastUpdate={chartData.extras.LatestUpdate}
    />
  );
};

export default DatabaseUtilTable;
