import { atom } from 'recoil';
import { localStorageEffect } from '@utils/recoil';
import { ATOM_KEY } from '@constants';

export default atom<RecommendPeriod>({
  key: ATOM_KEY.OPTIMIZATION.RECOMMEND_PERIOD,
  default: 'Days14',
  effects_UNSTABLE: [
    localStorageEffect<RecommendPeriod>(ATOM_KEY.OPTIMIZATION.RECOMMEND_PERIOD),
  ],
});
