import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  experimental_sx as sx,
  Stack,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WelcomeImg from '@assets/images/onboard_welcome_image.png';
import { ROUTES } from '@routes';
import { useCurrentInfo } from '@hooks/api/common';
import { useTagManager } from '@hooks/common';

const StepOptionText = styled(Typography)(
  sx({
    color: 'textSecondary',
    mt: 1,
  }),
);

const OnboardInstructionTemplate: React.FC = ({ children }) => (
  <Box display="flex">
    <Box flexGrow={1}>{children}</Box>
    <Box display={{ md: 'block', xs: 'none' }} ml={3}>
      <img src={WelcomeImg} alt="welcome" />
    </Box>
  </Box>
);

const OnboardInstruction: React.FC<{
  hasAccount: boolean;
  isRunning: boolean;
}> = ({ hasAccount, isRunning }) => {
  const { t } = useTranslation('onboard');
  const { t: overviewT } = useTranslation('overview');
  const navigate = useNavigate();
  const connectSub = t('overview.subTitle.connect', {
    returnObjects: true,
  });
  const diagnosticSub = t('overview.subTitle.diagnostic', {
    returnObjects: true,
  });
  const { sendDataLayer } = useTagManager();
  const buttonText = useMemo(() => {
    if (hasAccount) {
      if (isRunning) {
        return t('button.report');
      }
      return t('button.diagnostic');
    }
    return t('button.connect');
  }, [hasAccount, isRunning]);
  const handleNavigate = useCallback(() => {
    if (hasAccount) {
      if (isRunning) {
        sendDataLayer('cc-overview-report');
        navigate(ROUTES.ONBOARD.REPORT);
      } else {
        sendDataLayer('cc-overview-diagnostic');
        navigate(ROUTES.ONBOARD.DIAGNOSTIC);
      }
    } else {
      sendDataLayer('cc-overview-connect-account');
      navigate(ROUTES.ONBOARD.CONNECT);
    }
  }, [hasAccount, isRunning, sendDataLayer]);
  const { currentRole, currentCompany } = useCurrentInfo();
  if (currentRole === 3) {
    return (
      <OnboardInstructionTemplate>
        <Typography
          dangerouslySetInnerHTML={{
            __html: overviewT('label.emptyUser', {
              COMPANY: currentCompany?.name,
            }),
          }}
        />
      </OnboardInstructionTemplate>
    );
  }
  return (
    <OnboardInstructionTemplate>
      <Typography variant="h1" mt={{ md: 5, xs: 0 }}>
        {hasAccount ? t('title.diagnostic') : t('title.connect')}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2 }}>
        {hasAccount ? diagnosticSub[0] : connectSub[0]}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 1 }}>
        {hasAccount ? diagnosticSub[1] : connectSub[1]}
      </Typography>
      <Stack direction="row" spacing={2} mt={4}>
        <Button size="large" onClick={handleNavigate}>
          {buttonText}
        </Button>
        {hasAccount && (
          <Button
            variant="outlined"
            size="large"
            onClick={() => navigate(ROUTES.SETTINGS.ACCOUNTS)}
          >
            {t('button.cur')}
          </Button>
        )}
      </Stack>
      <Stepper
        activeStep={Number(hasAccount)}
        orientation="vertical"
        sx={{ mt: { md: 20, xs: 6 } }}
      >
        <Step>
          <StepLabel
            StepIconProps={{ variant: 'default' }}
            sx={{ alignItems: 'flex-start' }}
            optional={
              <StepOptionText>
                {t('overview.stepDescription.connect')}
              </StepOptionText>
            }
          >
            {t('overview.step.connect')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            StepIconProps={{ variant: 'default' }}
            sx={{ alignItems: 'flex-start' }}
            optional={
              <StepOptionText>
                {t('overview.stepDescription.diagnostic')}
              </StepOptionText>
            }
          >
            {t('overview.step.diagnostic')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            StepIconProps={{ variant: 'default' }}
            sx={{ alignItems: 'flex-start' }}
            optional={
              <StepOptionText>
                {t('overview.stepDescription.cur')}
              </StepOptionText>
            }
          >
            {t('overview.step.cur')}
          </StepLabel>
        </Step>
      </Stepper>
    </OnboardInstructionTemplate>
  );
};

export default OnboardInstruction;
