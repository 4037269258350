import React, { useCallback, useMemo, useState } from 'react';
import { Grid, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { EmailOutlined } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { useCurrentInfo } from '@hooks/api/common';
import { useRequestPayerEmail } from '@hooks/api/onboard';
import { useSnackbar, useTagManager, useToggle } from '@hooks/common';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { QUERY_KEY } from '@constants';
import { AWSAccountService } from '@api/service';
import { DialogModal } from '@components/modal';
import { ReactComponent as CheckCircleIcon } from '@assets/icons/common/CheckCircle.svg';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes';
import { linkedViewStepState } from './LinkedView.atoms';

const LinkedRequestView: React.FC<{
  uid: string;
}> = ({ uid }) => {
  const { t } = useTranslation('onboard');
  const { t: commonT } = useTranslation('common');
  const { t: toastT } = useTranslation('toast');
  const navigate = useNavigate();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [email, setEmail] = useState('');
  const active = useRecoilValue(linkedViewStepState);
  const [showConfirm, toggleConfirm] = useToggle();
  const { currentCompany, currentUser, currentAWSAccounts } = useCurrentInfo();
  const {
    isSuccess: isSent,
    isLoading: isSending,
    mutateAsync,
  } = useRequestPayerEmail();
  const { isSuccess, data } = useQuery(
    [QUERY_KEY.ONBOARD.GET_MASTER, uid],
    () => AWSAccountService.getMasterOrganization(uid),
  );
  const payerInfo = useMemo(() => {
    if (data?.items?.length) {
      setEmail(data.items[0].Email);
      return data.items[0];
    }
    return undefined;
  }, [data?.items?.length]);
  const { sendDataLayer } = useTagManager();
  const sendEmail = useCallback(async () => {
    const currentAccount = currentAWSAccounts?.find((v) => v.uid === uid);
    if (
      currentCompany &&
      currentCompany.name &&
      currentCompany.id &&
      currentUser &&
      currentUser.email &&
      (currentUser.lastname || currentUser.fullname) &&
      email &&
      payerInfo &&
      currentAccount &&
      currentAccount.id
    ) {
      try {
        await mutateAsync({
          destination: email,
          params: {
            senderEmail: currentUser.email,
            senderFirst: currentUser.lastname || currentUser.fullname || '',
            receiverName: email,
            companyName: currentCompany.name,
            fromAwsAccountId: currentAccount.id,
            toAccountId: payerInfo.AccountId,
            hashSix: currentCompany.id.slice(0, 6),
          },
        });
        showSuccessSnackbar(toastT('success.onboard.cur.requested'));
        const eventName =
          active === 'INTERNAL'
            ? 'cc-onboard-cur-request-internal'
            : 'cc-onboard-cur-request-msp';
        sendDataLayer(eventName);
        navigate(ROUTES.OVERVIEW.ROOT);
      } catch (e) {
        showErrorSnackbar(toastT('error.onboard.cur.request'));
      }
    }
  }, [
    mutateAsync,
    currentCompany,
    currentUser,
    currentAWSAccounts,
    email,
    payerInfo,
    active,
    sendDataLayer,
  ]);

  return (
    <>
      {active === 'INTERNAL' ? (
        <>
          <Typography variant="h3">{t('header.requestInternal')}</Typography>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: t('description.requestInternal'),
            }}
            sx={{ mt: 2 }}
          />
          {payerInfo?.Email && !_.isEmpty(payerInfo.Email) && (
            <>
              <Typography variant="h3" sx={{ mt: 5 }}>
                {t('header.managementInfo')}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                {t('description.managementInfo')}
              </Typography>
            </>
          )}
        </>
      ) : (
        <>
          <Typography variant="h3">{t('header.requestMsp')}</Typography>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: t('description.requestMsp') }}
            sx={{ mt: 2 }}
          />
        </>
      )}
      <Typography variant="body2" sx={{ mt: 7 }}>
        {t('description.managementEmail')}
      </Typography>
      <Grid container item columnSpacing={3} mt={1}>
        <Grid item md={5} xs>
          <TextField
            color="secondary"
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="jdoe@company.com"
            disabled={!isSuccess}
            fullWidth
          />
        </Grid>
        <Grid item>
          <LoadingButton
            variant="contained"
            color="secondary"
            size="large"
            onClick={toggleConfirm}
            loading={!isSuccess}
            endIcon={<EmailOutlined />}
          >
            {t('button.requestCUR')}
          </LoadingButton>
        </Grid>
      </Grid>
      <Typography variant="caption" color="textSecondary">
        {t('description.typeEmail')}
      </Typography>
      {showConfirm && (
        <DialogModal
          open={showConfirm}
          onClose={toggleConfirm}
          Actions={
            isSent
              ? [
                  {
                    label: commonT('button.okay'),
                    onClick: toggleConfirm,
                  },
                ]
              : [
                  {
                    label: commonT('button.cancel'),
                    color: 'inherit',
                    onClick: toggleConfirm,
                  },
                  {
                    label: t('modal.button.send'),
                    onClick: sendEmail,
                    loading: isSending,
                  },
                ]
          }
        >
          {isSent ? (
            <>
              <Stack direction="row" spacing={3}>
                <SvgIcon component={CheckCircleIcon} />
                <Typography variant="h3">{t('modal.title.sent')}</Typography>
              </Stack>
              <Typography
                variant="body2"
                color="textSecondary"
                mt={5}
                dangerouslySetInnerHTML={{
                  __html: t('modal.description.sent_cur', { email }),
                }}
              />
            </>
          ) : (
            <>
              <Typography variant="h3">{t('modal.title.confirm')}</Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                mt={5}
                dangerouslySetInnerHTML={{
                  __html: t('modal.description.confirm_cur', { email }),
                }}
              />
            </>
          )}
        </DialogModal>
      )}
    </>
  );
};

export default LinkedRequestView;
