import React, { useCallback } from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  usePaymentMethod,
  usePurchaseStatus,
} from '@hooks/api/settings/payment';
import { useToggle } from '@hooks/common';
import { QuestionTooltip } from '@components/styled/tooltip';
import { TooltipTypography } from '@components/styled/typography';
import { ValueFormatter } from '@utils';
import ManageCard from '../card/ManageCard';

const UpcomingInvoice: React.FC = () => {
  const { t } = useTranslation('settings');
  const { data } = usePurchaseStatus();
  const { data: cardData } = usePaymentMethod();
  const [openCardList, toggleCardList] = useToggle();

  const getCardInfo = useCallback(
    (pid: string) => {
      if (cardData?.items?.length) {
        const card = cardData.items.find((c) => c.UID === pid);
        if (card) {
          return `${card.Brand.toUpperCase()} - ${card.Last4Digits}`;
        }
      }
      return '-';
    },
    [cardData?.items],
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h3" mb={1}>
            {t('payment.label.amount', { context: 'due' })}
          </Typography>
          <Typography variant="h1" color="primary">
            {ValueFormatter.toCurrency(data.items[0].Amount) || '-'}
          </Typography>
        </Box>
        <Box display="flex">
          <Box mr={10}>
            <Box display="flex" alignItems="center" mb={3}>
              <Typography variant="subtitle2" mr={1}>
                {t('payment.label.tier')}: {data.items[0].PlanName}
              </Typography>
              <QuestionTooltip
                title={
                  <TooltipTypography
                    variant="body2"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: `${t('payment.tier.title')}<br/><br/>${t(
                        `payment.tier.${data.items[0].PlanName as PricingTier}`,
                      )}`,
                    }}
                  />
                }
              />
            </Box>
            <Typography variant="subtitle2">
              {t('payment.label.payment', { context: 'due' })}:{' '}
              {ValueFormatter.toDateString(
                data.items[0].NextInvoiceDate,
                'YYYY/MM/DD',
              ) || '-'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="flex-end">
            <Typography variant="subtitle2">
              {t('payment.label.payment', { context: 'method' })}:{' '}
              {getCardInfo(data.items[0].PaymentMethod)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            onClick={toggleCardList}
            fullWidth
          >
            {t('payment.label.manageCard')}
          </Button>
        </Box>
      </Box>
      <ManageCard open={openCardList} />
    </>
  );
};

export default UpcomingInvoice;
