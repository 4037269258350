import React, { MouseEventHandler } from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as PropTypes from 'prop-types';
import { ServiceIconName } from '@components/common';
import { DialogIconHeader } from './modal.styled';
import { DialogModalProps } from './modal.types';

const DialogModal: React.FC<DialogModalProps> = ({
  open,
  onClose,
  onSubmit,
  maxWidth,
  HeaderProps,
  Actions,
  children,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth={maxWidth}
    scroll="body"
    disablePortal
    fullWidth
  >
    {onSubmit ? (
      <form onSubmit={onSubmit}>
        <DialogContent>
          {HeaderProps && (
            <DialogIconHeader
              icon={HeaderProps.icon}
              label={HeaderProps.label}
              extra={HeaderProps.extra}
            />
          )}
          {children}
        </DialogContent>
        {Actions?.length && (
          <DialogActions>
            {Actions.map(
              ({
                type = 'button',
                color,
                onClick,
                disabled,
                loading,
                label,
                startIcon,
                endIcon,
              }) => (
                <LoadingButton
                  key={`cc_dialog_action_${label}`}
                  variant="contained"
                  type={type}
                  size="large"
                  color={color}
                  onClick={onClick as MouseEventHandler<HTMLButtonElement>}
                  loading={loading}
                  disabled={disabled}
                  startIcon={startIcon}
                  endIcon={endIcon}
                >
                  {label}
                </LoadingButton>
              ),
            )}
          </DialogActions>
        )}
      </form>
    ) : (
      <>
        <DialogContent>
          {HeaderProps && (
            <DialogIconHeader
              icon={HeaderProps.icon}
              label={HeaderProps.label}
              extra={HeaderProps.extra}
            />
          )}
          {children}
        </DialogContent>
        {Actions?.length && (
          <DialogActions>
            {Actions.map(
              ({
                type = 'button',
                color,
                onClick,
                disabled,
                loading,
                label,
                startIcon,
                endIcon,
              }) => (
                <LoadingButton
                  key={`cc_dialog_action_${label}`}
                  variant="contained"
                  type={type}
                  size="large"
                  color={color}
                  onClick={onClick as MouseEventHandler<HTMLButtonElement>}
                  loading={loading}
                  disabled={disabled}
                  startIcon={startIcon}
                  endIcon={endIcon}
                >
                  {label}
                </LoadingButton>
              ),
            )}
          </DialogActions>
        )}
      </>
    )}
  </Dialog>
);
DialogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  HeaderProps: PropTypes.exact({
    icon: PropTypes.oneOf<ServiceIconName>([
      'Overview',
      'Optimization',
      'Anomaly Detection',
      'Smart Planner',
      'Settings',
      'Admin',
      'Onboarding',
      'Notification',
      'Details',
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }),
  Actions: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf<'button' | 'submit' | 'reset'>([
        'button',
        'submit',
        'reset',
      ]),
      color: PropTypes.oneOf<'inherit' | 'primary' | 'secondary' | 'tertiary'>([
        'inherit',
        'primary',
        'secondary',
        'tertiary',
      ]),
      loading: PropTypes.bool,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
    }).isRequired,
  ),
};
DialogModal.defaultProps = {
  onClose: undefined,
  onSubmit: undefined,
  HeaderProps: undefined,
  Actions: undefined,
};

export default DialogModal;
