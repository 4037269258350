import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEC2DataTransferAutoComplete } from '@hooks/api/optimization/ec2';
import { useFilterFormik } from '@hooks/common';
import { useAWSHelper } from '@hooks/helper';
import {
  FilterGrid,
  FilterGridItem,
  FilterProps,
  FilterSelect,
  FilterWrapper,
} from '@components/filter';

type ComputeTransferFilterForm = {
  TransferType: Array<string>;
  FromLocation: Array<string>;
  ToLocation: Array<string>;
};

const useComputeTransferFilterForm =
  (): UseValidation<ComputeTransferFilterForm> => ({
    initialValues: {
      TransferType: [],
      FromLocation: [],
      ToLocation: [],
    },
    validationSchema: Yup.object({
      TransferType: Yup.array().of(Yup.string()),
      FromLocation: Yup.array().of(Yup.string()),
      ToLocation: Yup.array().of(Yup.string()),
    }).defined(),
  });

const ComputeTransferFilter: React.FC<FilterProps> = ({
  onClose,
  onFilterChange,
  previousValues,
}) => {
  const { t } = useTranslation('optimization');
  const { getDataTransferRegion, getDataTransferType } = useAWSHelper();
  const { data } = useEC2DataTransferAutoComplete();
  const { initialValues, validationSchema } = useComputeTransferFilterForm();
  const { values, handleArrayChange, handleBlur, handleSubmit } =
    useFilterFormik(
      {
        initialValues: previousValues || initialValues,
        validationSchema,
        onSubmit: (v) => {
          onFilterChange(v);
          if (onClose) {
            onClose();
          }
        },
      },
      data?.items,
    );
  if (!data?.items?.length) {
    return null;
  }
  const { TransferType, ToLocation, FromLocation } = data.items[0];
  return (
    <FilterWrapper onClose={onClose} onSubmit={handleSubmit}>
      <FilterGrid>
        <FilterGridItem label={t('label.transfer.type')}>
          <FilterSelect
            name="TransferType"
            value={values.TransferType}
            onChange={(e) => handleArrayChange('TransferType', e.target.value)}
            onBlur={handleBlur}
            renderValue={() =>
              values.TransferType.map((v: string) =>
                getDataTransferType(v),
              ).join(', ')
            }
            selected={values.TransferType}
            candidate={TransferType}
            formatter={getDataTransferType}
          />
        </FilterGridItem>
        <FilterGridItem
          label={t('label.transfer.location', { context: 'from' })}
        >
          <FilterSelect
            name="FromLocation"
            value={values.FromLocation}
            onChange={(e) => handleArrayChange('FromLocation', e.target.value)}
            onBlur={handleBlur}
            renderValue={() =>
              values.FromLocation.map((v: string) =>
                getDataTransferRegion(v),
              ).join(', ')
            }
            selected={values.FromLocation}
            candidate={FromLocation}
            formatter={getDataTransferRegion}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.transfer.location', { context: 'to' })}>
          <FilterSelect
            name="ToLocation"
            value={values.ToLocation}
            onChange={(e) => handleArrayChange('ToLocation', e.target.value)}
            onBlur={handleBlur}
            renderValue={() =>
              values.ToLocation.map((v: string) =>
                getDataTransferRegion(v),
              ).join(', ')
            }
            selected={values.ToLocation}
            candidate={ToLocation}
            formatter={getDataTransferRegion}
          />
        </FilterGridItem>
      </FilterGrid>
    </FilterWrapper>
  );
};

export default ComputeTransferFilter;
