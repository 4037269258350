import { useMutation, useQueryClient } from 'react-query';
import { OverviewService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const client = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.OVERVIEW.IGNORE_NOTIFICATION,
    mutationFn: (variables: { uid: string }) =>
      OverviewService.ignoreNotification(variables.uid),
    onSuccess: () => client.invalidateQueries(QUERY_KEY.OVERVIEW.NOTIFICATION),
  });
};
