/* eslint-disable react/display-name */
import React from 'react';
import * as PropTypes from 'prop-types';
import { TooltipProps, XAxisProps, YAxisProps } from 'recharts';
import { Box, Divider, styled, Typography } from '@mui/material';
import shadows from '@theme/shadows';
import { ColorDot } from '@components/styled';

export const TooltipDot = styled('div', {
  shouldForwardProp: (propName) =>
    propName !== 'dotColor' && propName !== 'marginRight',
})<{
  dotColor: string;
  marginRight?: string;
}>(({ dotColor, marginRight }) => ({
  display: 'inline-block',
  width: '8px',
  height: '8px',
  borderRadius: '10px',
  marginRight: marginRight || '16px',
  backgroundColor: dotColor,
}));

export const GrmtTooltipTextItem = React.memo(
  ({
    name = '',
    value = 0,
  }: {
    name: string | number | undefined;
    value: string | number | Array<string | number> | undefined;
  }) => (
    <Box display="flex" alignItems="center" mb={1}>
      {name && (
        <Box flexBasis="50%">
          <Typography variant="body2">{name}</Typography>
        </Box>
      )}
      {value && (
        <Box flexBasis="50%">
          <Typography variant="body2">{value}</Typography>
        </Box>
      )}
    </Box>
  ),
);

export const GrmtTooltipItem = React.memo(
  ({
    dotColor = 'white',
    name = '',
    value = 0,
  }: {
    dotColor: string | undefined;
    name: string | number | undefined;
    value: string | number | Array<string | number> | undefined;
  }) => (
    <Box display="flex" alignItems="center" mt={1} mb={1}>
      <Box mr={2}>
        <ColorDot color={dotColor} />
      </Box>
      <Box>
        <Typography variant="body2">{name}</Typography>
      </Box>
      <Box flexGrow={1}>
        <Typography variant="body2" align="right">
          {value}
        </Typography>
      </Box>
    </Box>
  ),
);

export const GrmtTooltipDivider = React.memo(() => (
  <Divider
    sx={{
      mt: 2,
      mb: 2,
      border: 'none',
      borderBottom: '1px solid #f0f0f7',
    }}
  />
));

export const GrmtTooltipWrapper: React.FC<
  TooltipProps<any, any> & { width?: string | number }
> = ({ active, payload, label, width, children }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        p={3}
        bgcolor="white"
        borderRadius="10px"
        width={width || 320}
        boxShadow={shadows[3]}
      >
        {label && (
          <Typography variant="subtitle2" mb={3}>
            {label}
          </Typography>
        )}
        {children}
      </Box>
    );
  }
  return null;
};
GrmtTooltipWrapper.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
GrmtTooltipWrapper.defaultProps = {
  width: undefined,
};

export const ChartDefaultGradient: React.ReactElement = (
  <defs>
    <linearGradient
      id="chartDefaultGradient"
      x1="0"
      y1="0"
      x2="0"
      y2="100%"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#FFF" offset="16.5%" />
      <stop stopColor="#FBFBFC" offset="100%" />
    </linearGradient>
  </defs>
);

export const GrmtAxisDefaultProps: XAxisProps & YAxisProps = {
  stroke: '#F0F0F7',
  strokeWidth: 2,
  tick: { fill: '#A0A1B2', fontSize: '12px', fontWeight: 400 },
  tickLine: false,
  tickSize: 0,
  tickMargin: 24,
};

export const GrmtGridDefaultProps = {
  vertical: false,
  stroke: '#F0F0F7',
  fill: 'url(#chartDefaultGradient)',
};
