import React, { useCallback } from 'react';
import { useDeleteCoupon } from '@hooks/api/admin/coupon';
import { CCModalProps, ConfirmDialog } from '@components/modal';
import { useSnackbar } from '@hooks/common';

const DeleteCouponModal: React.FC<CCModalProps & { CouponId: string }> = ({
  open,
  onClose,
  CouponId,
}) => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutateAsync } = useDeleteCoupon();
  const deleteCoupon = useCallback(async () => {
    try {
      await mutateAsync({ CouponId });
      showSuccessSnackbar('Coupon has been deleted');
      onClose();
    } catch (e) {
      showErrorSnackbar('Error occurred during delete coupon');
    }
  }, []);

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title="Delete Coupon"
      content="Are you sure to delete coupon?"
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: 'Cancel',
        },
        {
          onClick: deleteCoupon,
          label: 'Confirm',
        },
      ]}
    />
  );
};

export default DeleteCouponModal;
