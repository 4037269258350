/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  GrmtAxisDefaultProps,
  GrmtDefaultChartProps,
  GrmtGridDefaultProps,
  GrmtTooltipTextItem,
  GrmtTooltipWrapper,
} from '@components/chart';
import { bluegrey, secondary } from '@theme/colors';
import { Box } from '@mui/material';
import { DotLegendItem, DotLegendWrapper } from '@components/styled';
import moment from 'moment';
import { ValueFormatter } from '@utils';
import { useTranslation } from 'react-i18next';

const PredictionCursor = (props: any) => {
  const { x, y, height, top } = props;
  return (
    <>
      <Rectangle
        fill={secondary[100]}
        stroke="none"
        x={x - 5}
        y={top}
        width={10}
        height={height}
      />
      <Rectangle
        fill={secondary[100]}
        stroke="none"
        x={x - 9}
        y={y - 9}
        width={18}
        height={18}
        radius={50}
      />
    </>
  );
};

type PredictionChartsProps = GrmtDefaultChartProps;

const PredictionChart: React.FC<PredictionChartsProps> = ({ data }) => {
  const { t } = useTranslation('optimization', { useSuspense: false });
  return (
    <Box>
      <DotLegendWrapper>
        <DotLegendItem
          color="#9594FF66"
          label={t('label.anomaly.aiPrediction')}
        />
        <DotLegendItem
          color={bluegrey[600]}
          label={t('label.anomaly.data')}
          diamond
        />
      </DotLegendWrapper>
      <ResponsiveContainer width="100%" height={400}>
        <ScatterChart
          data={data}
          margin={{ top: 24, bottom: 24, left: 36, right: 36 }}
        >
          <XAxis
            dataKey="timestamp"
            interval={9}
            tickFormatter={(value, index) => String(index * 10)}
            {...GrmtAxisDefaultProps}
          />
          <YAxis
            scale="linear"
            domain={['dataMin', 'dataMax']}
            padding={{ top: 24, bottom: 24 }}
            {...GrmtAxisDefaultProps}
          />
          <CartesianGrid {...GrmtGridDefaultProps} />
          <Tooltip
            cursor={<PredictionCursor />}
            content={({ active, payload, label }) => (
              <GrmtTooltipWrapper active={active} payload={payload}>
                {Boolean(payload?.length) && (
                  <GrmtTooltipTextItem
                    name={moment(label).format('YY-MM-DDTHH:mm')}
                    value={ValueFormatter.toCurrency(
                      payload[0].payload.value,
                      undefined,
                      12,
                    )}
                  />
                )}
              </GrmtTooltipWrapper>
            )}
          />
          <Scatter
            dataKey="value"
            fill={bluegrey[600]}
            shape={({ x, y, ...props }) => (
              <svg
                width="12px"
                height="12px"
                viewBox="0 0 12 12"
                fill="none"
                x={x}
                y={y}
                {...props}
              >
                <rect
                  x="6"
                  width="8.48528"
                  height="8.48528"
                  transform="rotate(45 6 0)"
                />
              </svg>
            )}
          />
          <Scatter dataKey="expected_lower" fill="#9594FF66" />
          <Scatter dataKey="expected_upper" fill="#9594FF66" />
        </ScatterChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default React.memo(PredictionChart);
