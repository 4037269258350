import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const NoChartData: React.FC = () => {
  const { t } = useTranslation('overview');
  const { t: commonT } = useTranslation('common');
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="400px"
      p={5}
    >
      <Typography variant="h3">{commonT('label.noAvailable')}</Typography>
      <Typography variant="subtitle2" mt={5}>
        {t('label.monthlyYet')}
      </Typography>
    </Box>
  );
};

export default NoChartData;
