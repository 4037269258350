import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useRDSUtilizationChart } from '@hooks/api/optimization/rds';
import { RightSizingChart } from '@components/chart/optimization';
import {
  GrmtTooltipDivider,
  GrmtTooltipTextItem,
  GrmtTooltipWrapper,
} from '@components/chart';
import { ValueFormatter } from '@utils';
import { ChartCoverBox } from '@components/styled/noData';
import {
  RDS_RIGHTSIZING_DUMMY,
  RIGHTSIZING_DUMMY_EXTRA,
} from '@components/chart/chart.dummy';
import { useRecoilValue } from 'recoil';
import { rdsCPUState, rdsMemState } from '@atoms/optimization';

const DatabaseUtilChart: React.FC = () => {
  const { t } = useTranslation('optimization');
  const rdsCPU = useRecoilValue(rdsCPUState);
  const rdsMem = useRecoilValue(rdsMemState);
  const { data } = useRDSUtilizationChart();
  return (
    <ChartCoverBox isEmpty={Boolean(!data?.items?.length)}>
      <RightSizingChart
        data={data?.items?.length ? data.items : RDS_RIGHTSIZING_DUMMY}
        cpuThreshold={rdsCPU ? Number(rdsCPU) : undefined}
        memThreshold={rdsMem ? Number(rdsMem) : undefined}
        xAxis={[
          { dataKey: 'CPUAverage', name: 'Avg' },
          { dataKey: 'CPUMax', name: 'Max' },
        ]}
        yAxis={[
          { dataKey: 'MemAverage', name: 'Avg' },
          { dataKey: 'MemMax', name: 'Max' },
        ]}
        extraInfo={[
          {
            label: t('label.chart.db.totalRunning'),
            value: ValueFormatter.toLocaleString(
              data?.items?.length
                ? data?.extras?.TotalNumberOfRDS || 0
                : RIGHTSIZING_DUMMY_EXTRA.Total,
              0,
            ),
          },
          {
            label: t('label.chart.db.totalRightsize'),
            value: ValueFormatter.toLocaleString(
              data?.items?.length
                ? data?.extras?.TotalNumberOfRightSizing || 0
                : RIGHTSIZING_DUMMY_EXTRA.Right,
              0,
            ),
          },
          {
            label: t('label.chart.db.rightsizingCost'),
            value: ValueFormatter.toCurrency(
              data?.items?.length
                ? data?.extras?.TotalPossibleSaving || 0
                : RIGHTSIZING_DUMMY_EXTRA.Cost,
            ),
          },
        ]}
        tooltipContent={({ active, payload }) => {
          if (payload?.length) {
            const { payload: item, name } = payload[0];
            return (
              <GrmtTooltipWrapper active={active} payload={payload}>
                <GrmtTooltipTextItem
                  name={t('label.db.clusterId')}
                  value={item.ClusterId}
                />
                <GrmtTooltipTextItem
                  name={t('label.db.databaseId')}
                  value={item.InstanceId}
                />
                <GrmtTooltipTextItem
                  name={t('label.db.databaseType')}
                  value={item.InstanceType}
                />
                <GrmtTooltipDivider />
                <GrmtTooltipTextItem
                  name={t('label.util.cpu_short')}
                  value={ValueFormatter.toFixed(
                    _.get(item, name === 'Avg' ? 'CPUAverage' : 'CPUMax') ||
                      '-',
                  )}
                />
                <GrmtTooltipTextItem
                  name={t('label.util.mem_short')}
                  value={ValueFormatter.toPercent(
                    _.get(item, name === 'Avg' ? 'MemAverage' : 'MemMax') ||
                      '-',
                  )}
                />
              </GrmtTooltipWrapper>
            );
          }
          return null;
        }}
      />
    </ChartCoverBox>
  );
};

export default DatabaseUtilChart;
