import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useGetInvoiceDetail } from '@hooks/api/cmp';
import { ValueFormatter } from '@utils';
import {
  AccordionIndexColumn,
  AccordionItemColumn,
  AccordionTable,
  AccordionTableItem,
  AccordionValueColumn,
  InvoiceAccordion,
} from './styled';

const AdditionalServices: React.FC = () => {
  const { t } = useTranslation('report');
  const { id } = useParams();
  const { data: invoices } = useGetInvoiceDetail(id);
  const hasServices = useMemo(() => {
    const accList: Array<string> = [];
    invoices?.data.forEach((invoice) => {
      const services = _.get(
        invoice,
        'AdditionalData.AdditionalService.No region.AdditionalService.AdditionalService',
      );
      if (!services || Object.keys(services).length < 1) {
        accList.push(invoice.UID);
      }
    });
    return accList.length !== (invoices?.data.length ?? 0);
  }, [invoices?.data]);
  if (!invoices?.data.length) {
    return null;
  }
  return (
    <Stack spacing="16px" mt="16px">
      {hasServices ? (
        invoices.data.map((invoice, idx) => {
          const addServices = _.get(
            invoice,
            'AdditionalData.AdditionalService.No region.AdditionalService.AdditionalService',
          );
          if (!addServices || Object.keys(addServices).length < 1) {
            return null;
          }
          return (
            <InvoiceAccordion
              key={`additional_service_${invoice.UID}`}
              accountId={invoice.AccountId}
              totalCost={invoice.AdditionalCost.KRW}
              index={idx}
              currency="KRW"
              divider
            >
              <AccordionTable>
                <AccordionTableItem header dummyPadding>
                  <AccordionIndexColumn>No.</AccordionIndexColumn>
                  <AccordionItemColumn>
                    {t('text.invoice_item')}
                  </AccordionItemColumn>
                  <AccordionValueColumn>
                    {t('text.invoice_count')}
                  </AccordionValueColumn>
                  <AccordionValueColumn>
                    {t('text.invoice_amount')}
                  </AccordionValueColumn>
                </AccordionTableItem>
                {Object.keys(addServices).map((key, service_idx) => (
                  <AccordionTableItem
                    key={`additional_service_${invoice.UID}_${key}`}
                    underline
                    dummyPadding
                  >
                    <AccordionIndexColumn>
                      {ValueFormatter.toIndex(service_idx + 1, 1)}
                    </AccordionIndexColumn>
                    <AccordionItemColumn>{key}</AccordionItemColumn>
                    <AccordionValueColumn>
                      {ValueFormatter.toLocaleString(
                        addServices[key].UsageAmount,
                        0,
                      )}
                    </AccordionValueColumn>
                    <AccordionValueColumn>
                      {ValueFormatter.toCurrency(
                        addServices[key].Cost,
                        'KRW',
                        0,
                      )}
                    </AccordionValueColumn>
                  </AccordionTableItem>
                ))}
                <AccordionTableItem summary>
                  <AccordionItemColumn>
                    {t('text.invoice_total')}
                  </AccordionItemColumn>
                  <AccordionValueColumn />
                  <AccordionValueColumn>
                    {ValueFormatter.toCurrency(
                      invoice.AdditionalCost.KRW ?? 0,
                      'KRW',
                      0,
                    )}
                  </AccordionValueColumn>
                </AccordionTableItem>
              </AccordionTable>
            </InvoiceAccordion>
          );
        })
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            my: '24px',
          }}
        >
          <Typography>{t('text.additional_service_empty')}</Typography>
        </Box>
      )}
    </Stack>
  );
};

export default AdditionalServices;
