/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { TabPanelUnstyled } from '@mui/base';
import { useTranslation } from 'react-i18next';
import {
  DotLegendItem,
  DotLegendWrapper,
  LegendWithPercent,
  RoundTab,
  RoundTabs,
  RoundTabsList,
} from '@components/styled';
import { bluegrey, primary, secondary, tertiary } from '@theme/colors';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  GrmtAxisDefaultProps,
  GrmtTooltipItem,
  GrmtTooltipWrapper,
} from '@components/chart';
import { ValueFormatter } from '@utils';
import { useAWSAccountHelper } from '@hooks/helper';
import { useCurrentInfo } from '@hooks/api/common';

const DUMMY = [
  {
    Last: 93.28953016814724,
    This: 142.66426968152624,
    Forecast: null,
  },
  {
    Last: 187.21782957472755,
    This: 215.8998895673488,
    Forecast: null,
  },
  {
    Last: 281.03971348372767,
    This: 292.89579215351364,
    Forecast: null,
  },
  {
    Last: 374.89670927712353,
    This: 366.646196200002,
    Forecast: null,
  },
  {
    Last: 468.8310615408723,
    This: 440.3014680944678,
    Forecast: null,
  },
  {
    Last: 562.6459683883493,
    This: 514.5156720280014,
    Forecast: null,
  },
  {
    Last: 656.6324816778567,
    This: 588.1564788494314,
    Forecast: null,
  },
  {
    Last: 750.4448302037206,
    This: 661.9626668030523,
    Forecast: null,
  },
  {
    Last: 844.7633697213752,
    This: 735.565268715199,
    Forecast: null,
  },
  {
    Last: 939.8984272963202,
    This: 809.347122395269,
    Forecast: null,
  },
  {
    Last: 1020.9095986454272,
    This: 883.4159705444024,
    Forecast: null,
  },
  {
    Last: 1097.2326332505631,
    This: 957.2756729066416,
    Forecast: null,
  },
  {
    Last: 1173.4725592373547,
    This: 1031.6387913459314,
    Forecast: null,
  },
  {
    Last: 1249.812971639239,
    This: 1105.5796598409936,
    Forecast: null,
  },
  {
    Last: 1326.0721423709006,
    This: 1179.6190813551761,
    Forecast: null,
  },
  {
    Last: 1402.7891224289685,
    This: 1253.830485831015,
    Forecast: null,
  },
  {
    Last: 1479.249674797551,
    This: 1328.0970734431387,
    Forecast: null,
  },
  {
    Last: 1555.5872501676893,
    This: 1402.0707783267853,
    Forecast: null,
  },
  {
    Last: 1631.8670532323154,
    This: 1476.0329634471866,
    Forecast: null,
  },
  {
    Last: 1708.185916940352,
    This: 1549.178700122083,
    Forecast: null,
  },
  {
    Last: 1784.4652139540349,
    This: 1594.6610264959666,
    Forecast: null,
  },
  {
    Last: 1860.7265614833605,
    This: 1602.141026231918,
    Forecast: 1602.141026231918,
  },
  {
    Last: 1937.4959998723652,
    This: null,
    Forecast: 1678.9104646209228,
  },
  {
    Last: 2030.9021610331238,
    This: null,
    Forecast: 1772.3166257816813,
  },
  {
    Last: 2104.153046099184,
    This: null,
    Forecast: 1845.5675108477417,
  },
  {
    Last: 2177.7549394244293,
    This: null,
    Forecast: 1919.169404172987,
  },
  {
    Last: 2252.059035981009,
    This: null,
    Forecast: 1993.4735007295667,
  },
  {
    Last: 2325.480939904458,
    This: null,
    Forecast: 2066.8954046530157,
  },
  {
    Last: 2397.976599565618,
    This: null,
    Forecast: null,
  },
  {
    Last: 2471.242805585717,
    This: null,
    Forecast: null,
  },
  {
    Last: 2542.4694964597243,
    This: null,
    Forecast: null,
  },
];

const DUMMY_EXTRA = {
  Last: 2542.4694964597243,
  This: 1602.141026231918,
  Forecast: 2066.8954046530157,
  Percent: -18.705203443696153,
};

const MonthlyBillingDummy: React.FC = () => {
  const { t } = useTranslation('overview');
  const { navigateToCur } = useAWSAccountHelper();
  const { curLoading, currentRole } = useCurrentInfo();
  return (
    <Box position="relative">
      <Box px={7} py={5}>
        <RoundTabs value={0}>
          <RoundTabsList>
            <RoundTab value={0}>{t('tab.monthlyBilling.upToDate')}</RoundTab>
            <RoundTab value={1}>{t('tab.monthlyBilling.thisMonth')}</RoundTab>
            <RoundTab value={2}>{t('tab.monthlyBilling.lastMonth')}</RoundTab>
            <RoundTab value={3}>{t('tab.monthlyBilling.annualTrend')}</RoundTab>
          </RoundTabsList>
          <TabPanelUnstyled value={0}>
            <Box>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                columns={20}
              >
                <Grid item md={15} xs={20}>
                  <DotLegendWrapper>
                    <DotLegendItem
                      color={bluegrey[300]}
                      label={t('label.monthlyBilling.lastMonth')}
                    />
                    <DotLegendItem
                      color={primary[600]}
                      label={t('label.monthlyBilling.thisMonth')}
                    />
                    <DotLegendItem
                      color={secondary[500]}
                      label={t('label.monthlyBilling.prediction')}
                    />
                  </DotLegendWrapper>
                  <ResponsiveContainer width="100%" height={360}>
                    <LineChart
                      data={DUMMY}
                      margin={{ top: 8, bottom: 8, left: 8, right: 8 }}
                    >
                      <XAxis
                        interval={1}
                        tickFormatter={(v, idx) => String(idx * 2 + 1)}
                        {...GrmtAxisDefaultProps}
                      />
                      <YAxis scale="sequential" {...GrmtAxisDefaultProps} />
                      <CartesianGrid vertical={false} stroke="#F0F0F7" />
                      <Tooltip
                        content={({ active, payload, label }) => (
                          <GrmtTooltipWrapper
                            active={active}
                            payload={payload}
                            label={label + 1}
                          >
                            {payload?.length &&
                              payload.map((item) => (
                                <GrmtTooltipItem
                                  key={`tooltip_${item.name}_${label}`}
                                  dotColor={item.color}
                                  name={item.name}
                                  value={ValueFormatter.toCurrency(item.value)}
                                />
                              ))}
                          </GrmtTooltipWrapper>
                        )}
                      />
                      <Line
                        type="monotone"
                        name={t('label.monthlyBilling.lastMonth')}
                        dataKey="Last"
                        stroke={bluegrey[300]}
                        strokeWidth={3}
                        dot={false}
                        connectNulls
                      />
                      <Line
                        type="monotone"
                        name={t('label.monthlyBilling.prediction')}
                        dataKey="Forecast"
                        stroke={secondary[500]}
                        strokeWidth={3}
                        dot={false}
                        connectNulls
                      />
                      <Line
                        type="monotone"
                        name={t('label.monthlyBilling.thisMonth')}
                        dataKey="This"
                        stroke={primary[600]}
                        strokeWidth={3}
                        dot={false}
                        connectNulls
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Grid>
                <Grid
                  item
                  display={{ md: 'block', xs: 'flex' }}
                  justifyContent="space-between"
                  md={4}
                  xs={20}
                  mt={{ md: 0, xs: 4 }}
                >
                  <Box borderLeft="2px solid #5C5C66" pl={2}>
                    <Typography variant="subtitle2">
                      {ValueFormatter.toCurrency(DUMMY_EXTRA.Last)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {t('label.monthlyBilling.lastMonthCost')}
                    </Typography>
                  </Box>
                  <Box
                    borderLeft="2px solid #5C5C66"
                    mt={{ md: 7, xs: 0 }}
                    pl={2}
                  >
                    <Typography variant="subtitle2">
                      {ValueFormatter.toCurrency(DUMMY_EXTRA.This)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {t('label.monthlyBilling.monthToDate')}
                    </Typography>
                  </Box>
                  <Box
                    borderLeft={`2px solid ${
                      DUMMY_EXTRA.Percent >= 0.0
                        ? secondary[500]
                        : tertiary[300]
                    }`}
                    color={
                      DUMMY_EXTRA.Percent >= 0.0
                        ? secondary[500]
                        : tertiary[300]
                    }
                    mt={{ md: 7, xs: 0 }}
                    pl={2}
                  >
                    <LegendWithPercent
                      label={ValueFormatter.toCurrency(DUMMY_EXTRA.Forecast)}
                      percent={DUMMY_EXTRA.Percent}
                    />
                    <Typography variant="caption" color="textSecondary">
                      {t('label.monthlyBilling.estimated')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </TabPanelUnstyled>
        </RoundTabs>
      </Box>
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={!curLoading && currentRole < 3 ? navigateToCur : undefined}
        sx={{
          background: 'rgba(255, 255, 255, 0.45)',
          backdropFilter: 'blur(4px)',
          cursor: !curLoading && currentRole < 3 ? 'pointer' : 'default',
        }}
      >
        <Typography variant="subtitle2">
          {curLoading
            ? t('label.savingsSummary.curLoading')
            : currentRole < 3
            ? t('label.savingsSummary.configure')
            : 'No Permission'}
        </Typography>
      </Box>
    </Box>
  );
};

export default MonthlyBillingDummy;
