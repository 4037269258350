import axios from '@utils/axios';
import { API_URL } from '@constants';

const login = async (
  email: string,
  password: string,
  keepLoggedIn: boolean,
): Promise<CCQueryResponse<LoginResponse>> => {
  const { data } = await axios.post(`${API_URL.USERS}/login`, {
    email,
    password,
    keepLoggedIn,
  });
  return data;
};

const loginWithToken = async (
  token: string,
): Promise<CCQueryResponse<LoginResponse>> => {
  const { data } = await axios.post(`${API_URL.USERS}/tokenlogin`, undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

const resetPassword = async (email: string) => {
  const { data } = await axios.post(`${API_URL.USERS}/resetpw/${email}`);
  return data;
};

const updatePassword = async (token: string, password: string) => {
  const { data } = await axios.post(`${API_URL.USERS}/updatepw/${token}`, {
    password,
  });
  return data;
};

const resendVerification = async (email: string) => {
  const { data } = await axios.post(
    `${API_URL.USERS}/send_verification/${email}`,
  );
  return data;
};

export default {
  login,
  loginWithToken,
  resetPassword,
  updatePassword,
  resendVerification,
};
