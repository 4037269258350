import { SubscriptionService } from '@api/service';
import { QUERY_KEY } from '@constants';
import { useMutation, useQueryClient } from 'react-query';

export default () => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: QUERY_KEY.ADMIN.SUBSCRIPTION.DELETE_SUBSCRIPTION,
    mutationFn: (variables: { subscriptionId: string }) =>
      SubscriptionService.deleteSubscription(variables.subscriptionId),
    onSuccess: () =>
      client.invalidateQueries(QUERY_KEY.ADMIN.SUBSCRIPTION.GET_SUBSCRIPTIONS),
  });
};
