import { useMemo } from 'react';
import { QueryFunctionContext, useQueries } from 'react-query';
import { AWSAccountService } from '@api/service';
import { useCurrentInfo } from '@hooks/api/common';
import { QUERY_KEY } from '@constants';

export default () => {
  const { currentAWSAccounts } = useCurrentInfo();
  const selected = useMemo(() => {
    if (currentAWSAccounts?.length) {
      return currentAWSAccounts.filter((v) => v.isPayer);
    }
    return [];
  }, [currentAWSAccounts]);
  return useQueries(
    selected.map((v) => ({
      queryKey: [QUERY_KEY.ONBOARD.GET_LINKED, v.uid],
      queryFn: ({ queryKey }: QueryFunctionContext<any>) =>
        AWSAccountService.getLinkedOrganization(queryKey[1]),
    })),
  );
};
