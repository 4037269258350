import {
  Chip,
  chipClasses,
  experimental_sx as sx,
  styled,
} from '@mui/material';

export const TagChip = styled(Chip)(
  sx({
    height: '32px',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
    borderRadius: '4px',
    [`> .${chipClasses.label}`]: {
      px: '4px',
    },
  }),
);
