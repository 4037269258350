import React, { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import {
  useChangePaymentMethod,
  useDeletePaymentMethod,
  usePaymentMethod,
} from '@hooks/api/settings/payment';
import { useSnackbar, useToggle } from '@hooks/common';
import { SimpleTable } from '@components/table';
import { ConfirmDialog } from '@components/modal';
import AddNewCard from './AddNewCard';

const CardList: React.FC = () => {
  const { t } = useTranslation('settings');
  const { t: dialogT } = useTranslation('dialog');
  const { t: commonT } = useTranslation('common');
  const { t: toastT } = useTranslation('toast');
  const [openDefault, toggleDefault] = useToggle();
  const [openDelete, toggleDelete] = useToggle();
  const [openAdd, toggleAdd] = useToggle();
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const { showSnackbar } = useSnackbar();
  const { isLoading: isChangeFetching, mutateAsync: fetchChange } =
    useChangePaymentMethod();
  const { isLoading: isDeleteFetching, mutateAsync: fetchDelete } =
    useDeletePaymentMethod();
  const openDefaultModal = useCallback((uid: string) => {
    setSelected(uid);
    toggleDefault();
  }, []);
  const openDeleteModal = useCallback((uid: string) => {
    setSelected(uid);
    toggleDelete();
  }, []);
  const { data } = usePaymentMethod();
  const confirmDefault = useCallback(async () => {
    if (selected) {
      try {
        await fetchChange({ PaymentMethodId: selected });
        setSelected(undefined);
        toggleDefault();
        showSnackbar(
          toastT('success.settings.payment.card.primary'),
          'success',
        );
      } catch (e) {
        showSnackbar(toastT('error.general'), 'error');
      }
    }
  }, [selected]);
  const confirmDelete = useCallback(async () => {
    if (selected) {
      try {
        await fetchDelete({ PaymentMethodId: selected });
        setSelected(undefined);
        toggleDelete();
        showSnackbar(toastT('success.settings.payment.card.delete'), 'success');
      } catch (e) {
        console.error(e);
        showSnackbar(toastT('error.general'), 'error');
      }
    }
  }, [selected]);
  return (
    <>
      {Boolean(data?.items?.length) && (
        <SimpleTable
          data={data.items}
          columnInfo={[
            {
              Header: 'No.',
              accessor: () => null,
              Cell: ({ row }) => row.index + 1,
            },
            {
              Header: t('payment.label.card', { context: 'type' }),
              accessor: 'Brand' as const,
              Cell: ({ value }) => value.toUpperCase(),
            },
            {
              Header: t('payment.label.card', { context: 'digit' }),
              accessor: 'Last4Digits' as const,
            },
            {
              Header: t('payment.label.card', { context: 'valid' }),
              accessor: 'ExpireMonth' as const,
              Cell: ({ value, row }) => {
                const month = `0${value}`.slice(-2);
                return `${month}/${row.original.ExpireYear}`;
              },
            },
            {
              Header: t('payment.label.card', { context: 'name' }),
              accessor: 'Name' as const,
              Cell: ({ value }) => value || '-',
            },
            {
              Header: commonT('label.action'),
              accessor: 'IsDefault' as const,
              Cell: ({ value, row }) => {
                if (value) {
                  return (
                    <Button
                      variant="text"
                      color="inherit"
                      size="small"
                      disabled
                    >
                      {t('payment.label.primary')}
                    </Button>
                  );
                }
                return (
                  <>
                    <Button
                      variant="text"
                      color="inherit"
                      size="small"
                      onClick={() => openDefaultModal(row.original.UID)}
                      sx={{ mr: 2 }}
                    >
                      {t('payment.label.primary', { context: 'make' })}
                    </Button>
                    <Button
                      variant="text"
                      color="inherit"
                      size="small"
                      onClick={() => openDeleteModal(row.original.UID)}
                      endIcon={<DeleteIcon />}
                    >
                      {commonT('button.delete')}
                    </Button>
                  </>
                );
              },
            },
          ]}
        />
      )}
      <Button
        variant="text"
        size="large"
        onClick={toggleAdd}
        startIcon={<AddIcon />}
        sx={{
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          fontWeight: 400,
          height: '3.875rem',
        }}
        fullWidth
      >
        {t('payment.label.card', { context: 'add' })}
      </Button>
      {openDefault && selected && (
        <ConfirmDialog
          open={openDefault}
          onClose={toggleDefault}
          title={dialogT('settings.payment.default.title')}
          content={dialogT('settings.payment.default.content')}
          Actions={[
            {
              color: 'inherit',
              onClick: () => {
                setSelected(undefined);
                toggleDefault();
              },
              label: commonT('button.cancel'),
            },
            {
              onClick: confirmDefault,
              loading: isChangeFetching,
              label: commonT('button.okay'),
            },
          ]}
        />
      )}
      {openDelete && selected && (
        <ConfirmDialog
          open={openDelete}
          onClose={toggleDelete}
          title={dialogT('settings.payment.delete.title')}
          content={dialogT('settings.payment.delete.content')}
          Actions={[
            {
              color: 'inherit',
              onClick: () => {
                setSelected(undefined);
                toggleDelete();
              },
              label: commonT('button.cancel'),
            },
            {
              onClick: confirmDelete,
              loading: isDeleteFetching,
              label: commonT('button.okay'),
            },
          ]}
        />
      )}
      {openAdd && <AddNewCard open={openAdd} onClose={toggleAdd} />}
    </>
  );
};

export default CardList;
