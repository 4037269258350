import React from 'react';
import { TextField } from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEBSAutoComplete } from '@hooks/api/optimization/ebs';
import { useFilterFormik } from '@hooks/common';
import { useAWSHelper } from '@hooks/helper';
import {
  FilterGrid,
  FilterGridItem,
  FilterProps,
  FilterSelect,
  FilterWrapper,
} from '@components/filter';

type VolumeFilterForm = {
  Region: Array<string>;
  VolumeId: string;
  CurrentType: Array<string>;
  State: Array<string>;
  Tag: string;
};

const useVolumeFilterForm = (): UseValidation<VolumeFilterForm> => ({
  initialValues: {
    Region: [],
    VolumeId: '',
    CurrentType: [],
    State: [],
    Tag: '',
  },
  validationSchema: Yup.object({
    Region: Yup.array().of(Yup.string()),
    VolumeId: Yup.string(),
    CurrentType: Yup.array().of(Yup.string()),
    State: Yup.array().of(Yup.string()),
    Tag: Yup.string(),
  }).defined(),
});

const VolumeFilter: React.FC<FilterProps> = ({
  onClose,
  onFilterChange,
  previousValues,
}) => {
  const { t } = useTranslation('optimization');
  const { getAWSRegionName } = useAWSHelper();
  const { data } = useEBSAutoComplete();
  const { initialValues, validationSchema } = useVolumeFilterForm();
  const { values, handleChange, handleArrayChange, handleBlur, handleSubmit } =
    useFilterFormik(
      {
        initialValues: previousValues || initialValues,
        validationSchema,
        onSubmit: (v) => {
          onFilterChange(v);
          onClose();
        },
      },
      data?.items,
    );
  if (!data.items?.length) {
    return null;
  }
  const { Region, State, CurrentType } = data.items[0];
  return (
    <FilterWrapper onClose={onClose} onSubmit={handleSubmit}>
      <FilterGrid>
        <FilterGridItem label={t('label.common.region')}>
          <FilterSelect
            name="Region"
            value={values.Region}
            onChange={(e) => handleArrayChange('Region', e.target.value)}
            onBlur={handleBlur}
            renderValue={() =>
              values.Region.map((v: string) => getAWSRegionName(v)).join(', ')
            }
            selected={values.Region}
            candidate={Region}
            formatter={getAWSRegionName}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.volume.id')}>
          <TextField
            name="VolumeId"
            value={values.VolumeId}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.volume.type')}>
          <FilterSelect
            name="CurrentType"
            value={values.CurrentType}
            onChange={(e) => handleArrayChange('CurrentType', e.target.value)}
            onBlur={handleBlur}
            renderValue={() => values.CurrentType.join(', ')}
            selected={values.CurrentType}
            candidate={CurrentType}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.volume.state')}>
          <FilterSelect
            name="State"
            value={values.State}
            onChange={(e) => handleArrayChange('State', e.target.value)}
            onBlur={handleBlur}
            renderValue={() => values.State.join(', ')}
            selected={values.State}
            candidate={State}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.tags')}>
          <TextField
            name="Tag"
            value={values.Tag}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </FilterGridItem>
      </FilterGrid>
    </FilterWrapper>
  );
};

export default VolumeFilter;
