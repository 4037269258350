import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Paper } from '@mui/material';
import _ from 'lodash';
import { useGetInvoiceDetail } from '@hooks/api/cmp';
import BillingSummaryTotal from './BillingSummaryTotal';
import BillingSummaryTable from './BillingSummaryTable';

const BillingSummary: React.FC = () => {
  const { id } = useParams();
  const { data: invoices } = useGetInvoiceDetail(id);
  const orgTotalCost = useMemo(() => {
    if (invoices?.data.length) {
      return _.sumBy(invoices.data, 'TotalCost.KRW');
    }
    return 0;
  }, [invoices?.data]);
  if (!invoices?.data.length) {
    return null;
  }
  return (
    <Box>
      <Paper sx={{ px: 7, py: 5 }}>
        <BillingSummaryTotal
          invoice={invoices.data[0]}
          totalCost={orgTotalCost}
        />
      </Paper>
      <Paper sx={{ px: 7, py: 5, mt: 6 }}>
        <BillingSummaryTable
          invoices={invoices.data}
          lastUpdated={_.get(invoices?.extras, 'LastUpdated')}
        />
      </Paper>
    </Box>
  );
};

export default BillingSummary;
