/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState } from 'react';
import { Box, Grid, Paper, PaperProps, Stack, Typography } from '@mui/material';
import { WithDiagnostic, WithTrial } from '@hooks/hoc';
import _ from 'lodash';
import { useEC2Miscellaneous } from '@hooks/api/optimization/ec2';
import { NoAvailableData } from '@components/styled/noData';
import { MiscellaneousAccordion } from '@components/styled/intelligence';
import { bluegrey, primary } from '@theme/colors';
import { ValueFormatter } from '@utils';
import { useBestPracticeHelper } from '@hooks/helper';

type MiscellaneousList = Record<
  MiscellaneousCaseId,
  Record<string, Array<EC2Miscellaneous>>
>;

const MiscellaneousSelector: React.FC<
  {
    CaseId: MiscellaneousCaseId;
    count?: number;
    selected?: boolean;
  } & Pick<PaperProps, 'onClick'>
> = ({ CaseId, count, selected, onClick }) => {
  const { getTitle } = useBestPracticeHelper();
  return (
    <Paper
      onClick={count ? onClick : undefined}
      sx={{
        width: 360,
        cursor: count ? 'pointer' : 'default',
        borderColor: selected ? '#d8c0f7' : bluegrey[300],
        backgroundColor: selected ? primary[50] : undefined,
        px: 3,
        py: 2,
        '&:hover': {
          backgroundColor: count
            ? selected
              ? primary[100]
              : bluegrey[100]
            : undefined,
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="body2"
          color={selected ? 'textPrimary' : 'textSecondary'}
        >
          {getTitle({ CaseId })}
        </Typography>
        <Typography
          variant="caption"
          fontWeight={700}
          color={selected ? 'primary' : 'textSecondary'}
        >
          {ValueFormatter.toLocaleString(count, 0) || '-'}
        </Typography>
      </Box>
    </Paper>
  );
};
MiscellaneousSelector.defaultProps = {
  count: 0,
  selected: false,
};

const OptimizationMiscellaneousPage: React.FC = () => {
  const [selected, setSelected] = useState<Array<MiscellaneousCaseId>>([]);
  const { data } = useEC2Miscellaneous();
  const notifications = useMemo<MiscellaneousList>(() => {
    const items: MiscellaneousList = {
      'AWS-EC2-0006': {},
      'AWS-EC2-0007': {},
      'AWS-EC2-0008': {},
      'AWS-EC2-0016': {},
      'AWS-EC2-0018': {},
      'AWS-EC2-EBS-0007': {},
      'AWS-EC2-EBS-0008': {},
      'AWS-EC2-EBS-0009': {},
      'AWS-GENERAL-0002': {},
    };
    if (data?.items?.length) {
      _.orderBy(data.items, 'Created', 'desc').forEach((item) => {
        if (
          item.CaseId === 'AWS-EC2-0018' ||
          item.CaseId === 'AWS-GENERAL-0002'
        ) {
          if (items[item.CaseId].global?.length) {
            items[item.CaseId].global.push(item);
          } else {
            items[item.CaseId].global = [item];
          }
        } else {
          const region = item.DetailsValue?.REGION;
          if (typeof region === 'string') {
            if (items[item.CaseId][region]?.length) {
              items[item.CaseId][region].push(item);
            } else {
              items[item.CaseId][region] = [item];
            }
          }
        }
      });
    }
    return items;
  }, [data?.items]);
  const isMiscellaneousEmpty = useCallback(
    (CaseId: MiscellaneousCaseId) =>
      !(
        !_.isEmpty(notifications[CaseId]) &&
        _.flatten(Object.values(notifications[CaseId])).length
      ),
    [notifications],
  );
  const handleChange = useCallback(
    (CaseId: MiscellaneousCaseId) => () => {
      const set = new Set(selected);
      if (set.has(CaseId)) {
        set.delete(CaseId);
      } else {
        set.add(CaseId);
      }
      setSelected(Array.from(set));
    },
    [selected],
  );
  return (
    <Paper sx={{ px: 7, py: 5 }}>
      <Grid container spacing={2}>
        <Grid item>
          <MiscellaneousSelector
            CaseId="AWS-EC2-0006"
            count={
              _.flatten(Object.values(notifications['AWS-EC2-0006'])).length
            }
            onClick={handleChange('AWS-EC2-0006')}
            selected={selected.includes('AWS-EC2-0006')}
          />
        </Grid>
        <Grid item>
          <MiscellaneousSelector
            CaseId="AWS-EC2-0007"
            count={
              _.flatten(Object.values(notifications['AWS-EC2-0007'])).length
            }
            onClick={handleChange('AWS-EC2-0007')}
            selected={selected.includes('AWS-EC2-0007')}
          />
        </Grid>
        <Grid item>
          <MiscellaneousSelector
            CaseId="AWS-EC2-0008"
            count={
              _.flatten(Object.values(notifications['AWS-EC2-0008'])).length
            }
            onClick={handleChange('AWS-EC2-0008')}
            selected={selected.includes('AWS-EC2-0008')}
          />
        </Grid>
        <Grid item>
          <MiscellaneousSelector
            CaseId="AWS-EC2-0016"
            count={
              _.flatten(Object.values(notifications['AWS-EC2-0016'])).length
            }
            onClick={handleChange('AWS-EC2-0016')}
            selected={selected.includes('AWS-EC2-0016')}
          />
        </Grid>
        <Grid item>
          <MiscellaneousSelector
            CaseId="AWS-EC2-0018"
            count={
              _.flatten(Object.values(notifications['AWS-EC2-0018'])).length
            }
            onClick={handleChange('AWS-EC2-0018')}
            selected={selected.includes('AWS-EC2-0018')}
          />
        </Grid>
        <Grid item>
          <MiscellaneousSelector
            CaseId="AWS-EC2-EBS-0007"
            count={
              _.flatten(Object.values(notifications['AWS-EC2-EBS-0007'])).length
            }
            onClick={handleChange('AWS-EC2-EBS-0007')}
            selected={selected.includes('AWS-EC2-EBS-0007')}
          />
        </Grid>
        <Grid item>
          <MiscellaneousSelector
            CaseId="AWS-EC2-EBS-0008"
            count={
              _.flatten(Object.values(notifications['AWS-EC2-EBS-0008'])).length
            }
            onClick={handleChange('AWS-EC2-EBS-0008')}
            selected={selected.includes('AWS-EC2-EBS-0008')}
          />
        </Grid>
        <Grid item>
          <MiscellaneousSelector
            CaseId="AWS-EC2-EBS-0009"
            count={
              _.flatten(Object.values(notifications['AWS-EC2-EBS-0009'])).length
            }
            onClick={handleChange('AWS-EC2-EBS-0009')}
            selected={selected.includes('AWS-EC2-EBS-0009')}
          />
        </Grid>
        <Grid item>
          <MiscellaneousSelector
            CaseId="AWS-GENERAL-0002"
            count={
              _.flatten(Object.values(notifications['AWS-GENERAL-0002'])).length
            }
            onClick={handleChange('AWS-GENERAL-0002')}
            selected={selected.includes('AWS-GENERAL-0002')}
          />
        </Grid>
      </Grid>
      <Box mt={10}>
        {!_.isEmpty(notifications) ? (
          <Stack spacing={4} maxWidth="md">
            {Object.keys(notifications).map((key) => {
              if (
                !isMiscellaneousEmpty(key as MiscellaneousCaseId) &&
                (!selected.length ||
                  selected.includes(key as MiscellaneousCaseId))
              ) {
                return (
                  <MiscellaneousAccordion
                    key={key}
                    CaseId={key as MiscellaneousCaseId}
                    items={notifications[key as MiscellaneousCaseId]}
                  />
                );
              }
              return null;
            })}
          </Stack>
        ) : (
          <NoAvailableData />
        )}
      </Box>
    </Paper>
  );
};

export default WithTrial(WithDiagnostic(OptimizationMiscellaneousPage));
