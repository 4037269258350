import axios from '@utils/axios';
import { API_URL } from '@constants';
import { parseContentRange } from '@utils';

const getPlannerList = async (): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.get(`${API_URL.SMARTPLANNER}/get_ec2_list`);
  return data;
};

const getPlannerChart = async (
  AccountList: string | undefined = undefined,
): Promise<CCQueryResponse<PlannerChart>> => {
  const { data } = await axios.get(`${API_URL.SMARTPLANNER}/graph/`, {
    params: {
      AccountList,
    },
  });
  return data;
};

const getPlannerRIList: PaginatedAxios<
  WithContentRange<CCQueryResponse<any>>
> = async (skip, limit, filter, sort) => {
  const { headers, data } = await axios.get(`${API_URL.OPTIMIZATION}/ec2/ri/`, {
    params: {
      skip,
      limit,
      filter,
      sort,
    },
  });
  return { headers: parseContentRange(headers), body: data };
};

const getPlannerUncovered: PaginatedAxios<
  WithContentRange<CCQueryResponse<EC2Coverage>>
> = async (skip, limit, filter, sort) => {
  const filterElem = filter
    ? { ...JSON.parse(filter), RecommendPricingPlan: { $ne: {} } }
    : { RecommendPricingPlan: { $ne: {} } };
  const { headers, data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/dc_coverage/`,
    {
      params: {
        skip,
        limit,
        filter: JSON.stringify(filterElem),
        sort,
      },
    },
  );
  return { headers: parseContentRange(headers), body: data };
};

const getPlannerUncoveredDetail = async (
  instanceId: string,
): Promise<CCQueryResponse<EC2Coverage>> => {
  const { data } = await axios.get(`${API_URL.OPTIMIZATION}/ec2/dc_coverage/`, {
    params: {
      skip: 0,
      limit: 1,
      filter: { InstanceId: [instanceId] },
    },
  });
  return data;
};

const getPlannerRIDetail = async (
  item: EC2RIUtilization,
): Promise<CCQueryResponse<EC2RIUtilization>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/ri_utilization/`,
    {
      params: {
        skip: 0,
        limit: 1,
        filter: {
          AccountId: [item.AccountId],
          InstanceType: [item.InstanceType],
          Region: [item.Region],
          OperatingSystem: [item.OperatingSystem],
          Tenancy: [item.Tenancy],
          Class: [item.Class],
          OfferingType: [item.OfferingType],
          Terms: [item.Terms],
        },
      },
    },
  );
  return data;
};

const getPlannerSPDetail = async (
  UID: string,
): Promise<CCQueryResponse<EC2SPUtilization>> => {
  const { data } = await axios.get(`${API_URL.OPTIMIZATION}/savings_plan/`, {
    params: {
      skip: 0,
      limit: 1,
      filter: { UID: [UID] },
    },
  });
  return data;
};

const getSimulationGeneral = async (
  instanceId: string,
): Promise<CCQueryResponse<SimulationGeneral>> => {
  const { data } = await axios.get(
    `${API_URL.SMARTPLANNER}/general/${instanceId}`,
  );
  return data;
};

const getSimulationRecommendation = async (
  instanceId: string,
): Promise<CCQueryResponse<SimulationRecommend>> => {
  const { data } = await axios.get(
    `${API_URL.SMARTPLANNER}/recommend/${instanceId}`,
  );
  return data;
};

const getSimulationAutoComplete = async (
  instanceFamily: string,
): Promise<CCQueryResponse<string>> => {
  const { data } = await axios.get(
    `${API_URL.SMARTPLANNER}/ec2_acdata/${instanceFamily}`,
  );
  return data;
};

const simulatePlanner = async (
  InstanceId: string,
  DiscountProgram: RIDiscountProgram | SPDiscountProgram,
): Promise<CCQueryResponse<SimulationResult>> => {
  const { data } = await axios.post(`${API_URL.SMARTPLANNER}/simulation`, {
    InstanceId,
    DiscountProgram,
  });
  return data;
};

const purchaseReserved = async (
  body: PurchaseRIRequest,
): Promise<CCQueryResponse<PurchaseRIResponse>> => {
  const { data } = await axios.post(
    `${API_URL.SMARTPLANNER}/ec2_ri_purchase`,
    body,
  );
  return data;
};

const purchaseSavingsPlan = async (
  body: PurchaseSPRequest,
): Promise<CCQueryResponse<PurchaseSPResponse>> => {
  const { data } = await axios.post(
    `${API_URL.SMARTPLANNER}/savings_plan_purchase`,
    body,
  );
  return data;
};

export default {
  getPlannerList,
  getPlannerChart,
  getPlannerRIList,
  getPlannerUncovered,
  getPlannerUncoveredDetail,
  getPlannerRIDetail,
  getPlannerSPDetail,
  getSimulationGeneral,
  getSimulationRecommendation,
  getSimulationAutoComplete,
  simulatePlanner,
  purchaseReserved,
  purchaseSavingsPlan,
};
