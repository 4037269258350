import { useMutation, useQueryClient } from 'react-query';
import { IntegrationService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.SETTINGS.INTEGRATION.CREATE_DATADOG,
    mutationFn: (variables: {
      companyId: string;
      APIKey: string;
      AppKey: string;
    }) =>
      IntegrationService.createDatadog(
        variables.companyId,
        variables.APIKey,
        variables.AppKey,
      ),
    onSuccess: () =>
      queryClient.invalidateQueries(QUERY_KEY.SETTINGS.INTEGRATION.GET_DATADOG),
  });
};
