import { PromotionService } from '@api/service';
import { QUERY_KEY } from '@constants';
import { useMutation, useQueryClient } from 'react-query';

export default () => {
  const client = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.ADMIN.PROMOTION.CREATE_PROMOTION,
    mutationFn: (variables: { CouponId: string; Code: string }) =>
      PromotionService.createPromotion(variables.CouponId, variables.Code),
    onSuccess: () =>
      client.invalidateQueries(QUERY_KEY.ADMIN.PROMOTION.GET_PROMOTIONS),
  });
};
