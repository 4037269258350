import React, { useCallback, useMemo } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  InputAdornment,
  ListItemText,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useGetUsageAutoComplete } from '@hooks/api/aws/ce';
import _ from 'lodash';
import {
  UsageDivider,
  UsageFilterButton,
  UsageFilterInput,
  UsagePopperComponent,
} from './styled';

interface TagKeySelectorProps {
  onClose: () => void;
  onConfirm: () => void;
  values: string[];
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function TagKeySelector({
  onClose,
  onConfirm,
  values,
  setValues,
}: TagKeySelectorProps) {
  const { t } = useTranslation('usage');
  const { t: commonT } = useTranslation('common');
  const { data } = useGetUsageAutoComplete();
  const options = useMemo(() => {
    const items = [];
    if (data?.items[0].TagKeys.length) {
      items.push(' ');
      items.push(...data.items[0].TagKeys);
    }
    return items;
  }, [data?.items]);
  const getOptionLabel = useCallback(
    (option: string) => {
      if (_.isEmpty(option.trim())) {
        return t('text.select_all', {
          count: Math.max(options.length - 1, 0),
        });
      }
      return option;
    },
    [options.length, t],
  );
  const isAllSelected = useMemo(() => {
    return data?.items[0].TagKeys.every((e) => values.includes(e)) ?? false;
  }, [data?.items, values]);
  const isPartialSelected = useMemo(() => {
    return data?.items[0].TagKeys.some((e) => values.includes(e)) ?? false;
  }, [data?.items, values]);
  const toggleAll = useCallback(() => {
    if (isAllSelected) {
      setValues([]);
    } else if (data?.items) {
      setValues(data.items[0].TagKeys);
    }
  }, [data?.items, isAllSelected, setValues]);
  const getChecked = useCallback(
    (option: string, selected: boolean) => {
      if (_.isEmpty(option.trim())) {
        return isAllSelected;
      }
      return selected;
    },
    [isAllSelected],
  );
  const getIndeterminate = useCallback(
    (option: string) => {
      if (_.isEmpty(option.trim())) {
        return isPartialSelected && !isAllSelected;
      }
      return undefined;
    },
    [isAllSelected, isPartialSelected],
  );
  return (
    <Box>
      <Autocomplete
        open
        multiple
        disableCloseOnSelect
        value={values}
        onClose={(e, reason) => {
          if (reason === 'escape') {
            onClose();
          }
        }}
        PopperComponent={UsagePopperComponent}
        renderTags={() => null}
        noOptionsText={t('text.no_tag_keys')}
        onChange={(event, value, reason) => {
          if (
            event.type === 'keydown' &&
            (event as React.KeyboardEvent).key === 'Backspace' &&
            reason === 'removeOption'
          ) {
            return;
          }
          if (value.find((v) => _.isEmpty(v.trim()))) {
            toggleAll();
            return;
          }
          setValues(value);
        }}
        options={options}
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              color="tertiary"
              checked={getChecked(option, selected)}
              indeterminate={getIndeterminate(option)}
              sx={{ p: 0, pr: '8px' }}
            />
            <ListItemText sx={{ my: 0, wordWrap: 'break-word' }}>
              {getOptionLabel(option)}
            </ListItemText>
          </li>
        )}
        renderInput={(params) => (
          <UsageFilterInput
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            autoFocus
            placeholder={t('filter.filter_tag_keys')}
          />
        )}
      />
      <UsageDivider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '8px',
          p: '10px 16px',
        }}
      >
        <UsageFilterButton color="inherit" onClick={onClose}>
          {commonT('button.cancel')}
        </UsageFilterButton>
        <UsageFilterButton color="tertiary" onClick={onConfirm}>
          {t('filter.search_key')}
        </UsageFilterButton>
      </Box>
    </Box>
  );
}
