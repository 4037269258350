import React, { useCallback, useRef } from 'react';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { Circle } from '@visx/shape';
import { ValueFormatter } from '@utils';
import { useAWSHelper } from '@hooks/helper';

const COLOR_BAR_POS = 800;
const COLOR_SET = [
  '#6666CC',
  '#8D8DD9',
  '#B8B8E5',
  '#F0F0F7',
  '#FA7DC899',
  '#EF7AC1',
  '#E52197',
];
const TEXT_COLOR_SET = [
  '#FFFFFF',
  '#FFFFFF',
  '#FFFFFF',
  '#737480',
  '#FFFFFF',
  '#FFFFFF',
  '#FFFFFF',
];

const BubbleChart: React.FC<{
  data: Array<{
    Service: string;
    Cost: number;
    Percent: number;
  }>;
}> = ({ data }) => {
  const { convertAWSProductCode } = useAWSHelper();
  const svgRef = useRef<SVGSVGElement>(null);
  const getIndexByPercent = useCallback((percent: number): number => {
    switch (true) {
      case percent < -30:
        return 0;
      case percent < -20:
        return 1;
      case percent < -10:
        return 2;
      case percent < 10:
        return 3;
      case percent < 20:
        return 4;
      case percent < 30:
        return 5;
      default:
        return 6;
    }
  }, []);
  return (
    <svg width={880} height={400} viewBox="0 0 880 400" ref={svgRef}>
      <rect width="100%" height="100%" fill="#fff" />
      <Group>
        {COLOR_SET.slice(0)
          .reverse()
          .map((color, i) => (
            <rect
              key={`color_axis_${color}`}
              x="90%"
              y={`${i * 12.5 + 6}%`}
              width="1.3%"
              height="12.5%"
              fill={color}
            />
          ))}
        {['40', '30', '20', '10', '-10', '-20', '-30', '-40'].map(
          (percent, i) => (
            <Text
              key={`color_axis_text_${percent}`}
              dx={COLOR_BAR_POS + 18}
              dy={`${i * 12.5 + 6.5}%`}
              fontSize="10px"
              lineHeight="16px"
              textAnchor="start"
              fill="#A0A1B2"
            >
              {`${percent}%`}
            </Text>
          ),
        )}
      </Group>
      {data.length > 0 && (
        <Group pointerEvents="none">
          <Circle
            cx="17%"
            cy="50%"
            r="20%"
            fill={COLOR_SET[getIndexByPercent(data[0].Percent)]}
          />
          <Text
            x="17%"
            y="50%"
            dy="-17%"
            width={220}
            textAnchor="middle"
            verticalAnchor="middle"
            dominantBaseline="middle"
            fontSize="16px"
            fontWeight="500"
            fill={TEXT_COLOR_SET[getIndexByPercent(data[0].Percent)]}
          >
            {convertAWSProductCode(data[0].Service)}
          </Text>
          <Text
            x="17%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="24px"
            fontWeight="700"
            fill={TEXT_COLOR_SET[getIndexByPercent(data[0].Percent)]}
          >
            {ValueFormatter.toCurrency(data[0].Cost)}
          </Text>
          <rect
            width="9%"
            height="7%"
            x="12.5%"
            y="60%"
            fill={TEXT_COLOR_SET[getIndexByPercent(data[0].Percent)]}
            rx="1.5%"
          />
          <svg
            width="10"
            height="8"
            x="13.4%"
            y="62.4%"
            viewBox="0 0 10 8"
            fill="none"
          >
            <path
              d={
                data[0].Percent < 0
                  ? 'M10 0.5L5 7.5L0 0.5L10 0.5Z'
                  : 'M0 7.5L5 0.5L10 7.5H0Z'
              }
              fill={COLOR_SET[getIndexByPercent(data[0].Percent)]}
            />
          </svg>
          <Text
            dx="17.7%"
            dy="63.8%"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="14px"
            fill={COLOR_SET[getIndexByPercent(data[0].Percent)]}
          >
            {ValueFormatter.toPercent(data[0].Percent)}
          </Text>
        </Group>
      )}
      {data.length > 1 && (
        <svg x="33%" y="2%">
          <Group pointerEvents="none">
            <Circle
              cx="13%"
              cy="28%"
              r="16%"
              fill={COLOR_SET[getIndexByPercent(data[1].Percent)]}
            />
            <Text
              x="13%"
              y="28%"
              dy="-12%"
              width={165}
              textAnchor="middle"
              verticalAnchor="middle"
              dominantBaseline="middle"
              fontSize="16px"
              fill={TEXT_COLOR_SET[getIndexByPercent(data[1].Percent)]}
            >
              {convertAWSProductCode(data[1].Service)}
            </Text>
            <Text
              x="13%"
              y="28%"
              dy="5%"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize="24px"
              fontWeight="700"
              fill={TEXT_COLOR_SET[getIndexByPercent(data[1].Percent)]}
            >
              {ValueFormatter.toCurrency(data[1].Cost)}
            </Text>
          </Group>
        </svg>
      )}
      {data.length > 2 && (
        <svg x="57%" y="38%">
          <Group pointerEvents="none">
            <Circle
              cx="10.5%"
              cy="23.25%"
              r="13%"
              fill={COLOR_SET[getIndexByPercent(data[2].Percent)]}
            />
            <Text
              x="10.5%"
              y="23.25%"
              dy="-10%"
              width={120}
              textAnchor="middle"
              verticalAnchor="middle"
              dominantBaseline="middle"
              fontSize="16px"
              fill={TEXT_COLOR_SET[getIndexByPercent(data[2].Percent)]}
            >
              {convertAWSProductCode(data[2].Service)}
            </Text>
            <Text
              x="10.5%"
              y="23.25%"
              dy="5%"
              textAnchor="middle"
              verticalAnchor="middle"
              fontSize="24px"
              fontWeight="700"
              fill={TEXT_COLOR_SET[getIndexByPercent(data[2].Percent)]}
            >
              {ValueFormatter.toCurrency(data[2].Cost)}
            </Text>
          </Group>
        </svg>
      )}
      {data.length > 3 && (
        <svg x="35%" y="60%">
          <Group pointerEvents="none">
            <Circle
              cx="9%"
              cy="19%"
              r="11%"
              fill={COLOR_SET[getIndexByPercent(data[3].Percent)]}
            />
            <Text
              x="9%"
              y="19%"
              dy="-8%"
              widths={120}
              textAnchor="middle"
              verticalAnchor="middle"
              dominantBaseline="middle"
              fontSize="14px"
              fill={TEXT_COLOR_SET[getIndexByPercent(data[3].Percent)]}
              width={120}
            >
              {convertAWSProductCode(data[3].Service)}
            </Text>
            <Text
              x="9%"
              y="19%"
              dy="5%"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize="16px"
              fontWeight="700"
              fill={TEXT_COLOR_SET[getIndexByPercent(data[3].Percent)]}
            >
              {ValueFormatter.toCurrency(data[3].Cost)}
            </Text>
          </Group>
        </svg>
      )}
    </svg>
  );
};

export default React.memo(BubbleChart);
