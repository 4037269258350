import React from 'react';
import { Stack } from '@mui/material';
import { useEC2DetailsOthers } from '@hooks/api/optimization/ec2';
import { IntelligenceCard } from '@components/styled/notification';
import { NoAvailableData } from '@components/styled/noData';

const DetailsComputeOtherPage: React.FC = () => {
  const { data } = useEC2DetailsOthers();
  return (
    <>
      {data?.items?.length ? (
        <Stack spacing={3}>
          {data.items.map((item) => (
            <IntelligenceCard key={item.UID} item={item} />
          ))}
        </Stack>
      ) : (
        <NoAvailableData />
      )}
    </>
  );
};

export default DetailsComputeOtherPage;
