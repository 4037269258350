import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { TabPanelUnstyled } from '@mui/base';
import { useTranslation } from 'react-i18next';
import {
  AnnualTrendChart,
  CostComparisonChart,
  MonthToDateChart,
} from '@components/chart/overview';
import { RoundTab, RoundTabs, RoundTabsList } from '@components/styled';
import { SuspenseBox } from '@components/styled/suspense';

const MonthlyBilling: React.FC = () => {
  const { t } = useTranslation('overview');
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Box px={7} py={5}>
      <RoundTabs value={activeTab} onChange={(e, v) => setActiveTab(Number(v))}>
        <RoundTabsList>
          <RoundTab value={0}>{t('tab.monthlyBilling.upToDate')}</RoundTab>
          <RoundTab value={1}>{t('tab.monthlyBilling.thisMonth')}</RoundTab>
          <RoundTab value={2}>{t('tab.monthlyBilling.lastMonth')}</RoundTab>
          <RoundTab value={3}>{t('tab.monthlyBilling.annualTrend')}</RoundTab>
        </RoundTabsList>
        <TabPanelUnstyled value={0}>
          <SuspenseBox>
            <MonthToDateChart />
          </SuspenseBox>
        </TabPanelUnstyled>
        <TabPanelUnstyled value={1}>
          <SuspenseBox>
            <CostComparisonChart current />
          </SuspenseBox>
        </TabPanelUnstyled>
        <TabPanelUnstyled value={2}>
          <SuspenseBox>
            <CostComparisonChart />
          </SuspenseBox>
        </TabPanelUnstyled>
        <TabPanelUnstyled value={3}>
          <SuspenseBox>
            <AnnualTrendChart />
          </SuspenseBox>
        </TabPanelUnstyled>
      </RoundTabs>
    </Box>
  );
};

export default MonthlyBilling;
