import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';
import moment from 'moment';
import { useS3DetailsSummary } from '@hooks/api/optimization/s3';
import { StorageChart } from '@components/chart/optimization';
import { RecommendActionDivider } from '@components/styled';
import { IntelligenceCard } from '@components/styled/notification';
import { bluegrey } from '@theme/colors';
import { ValueFormatter } from '@utils';
import { useTranslation } from 'react-i18next';
import { ChartCoverBox, NoAvailableData } from '@components/styled/noData';
import { STORAGE_DUMMY } from '@components/chart/chart.dummy';

type TableInfo = {
  label: string;
} & Pick<TypographyProps, 'variant'>;

const StorageSimpleTable = React.memo(
  ({
    headers,
    contents,
  }: {
    headers: Array<TableInfo>;
    contents: Array<TableInfo>;
  }) => (
    <Box>
      <Grid container>
        {headers.map((header, idx) => (
          <Grid
            key={`storage_header_${header.label}_${idx.toString()}`}
            item
            p={2}
            minHeight="3.5rem"
            xs
          >
            <Typography variant={header.variant || 'body2'}>
              {header.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container bgcolor={bluegrey[50]} borderTop="1px solid #F0F0F7">
        {contents.map((content, idx) => (
          <Grid
            key={`storage_content_${content.label}_${idx.toString()}`}
            item
            p={2}
            minHeight="3.5rem"
            xs
          >
            <Typography variant={content.variant || 'body2'}>
              {content.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  ),
);

const DetailsStorageInformation: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');
  const { data } = useS3DetailsSummary();
  if (!data?.items || !data?.extras) {
    return <NoAvailableData />;
  }
  const { AnnualCost, StandardAges, RecommendActions } = data.items;
  const { ThisMonth } = data.extras;
  return (
    <>
      <ChartCoverBox isEmpty={Boolean(!AnnualCost?.length)}>
        <StorageChart
          data={AnnualCost?.length ? AnnualCost : STORAGE_DUMMY}
          xAxis={{
            dataKey: 'Date',
            interval: 0,
            tickFormatter: (v) => moment(v).format('YYYY MMM'),
          }}
          chartInfo={{
            line: {
              dataKey: 'Storage',
              name: `${t('label.common.storage')} ${t('label.common.cost')}`,
            },
            area: {
              dataKey: 'Data Transfer',
              name: `${t('label.header.transfer')} ${t('label.common.cost')}`,
            },
          }}
        />
      </ChartCoverBox>
      <Grid container spacing={3} mt={12}>
        <Grid item md={3} xs={6}>
          <Typography variant="body2" mb={2}>
            {t('label.storage.stdByAge')}
          </Typography>
          <Paper>
            <StorageSimpleTable
              headers={[
                { label: commonT('label.days', { count: 30 }) },
                { label: commonT('label.days', { count: 90 }) },
              ]}
              contents={[
                {
                  label: ValueFormatter.toLocaleString(
                    StandardAges[0]['30days'].count,
                    0,
                  ),
                },
                {
                  label: ValueFormatter.toLocaleString(
                    StandardAges[0]['90days'].count,
                    0,
                  ),
                },
              ]}
            />
          </Paper>
        </Grid>
        <Grid item md={9} xs={12}>
          <Typography variant="body2" mb={2}>
            {t('label.common.cost')} ({commonT('label.days', { count: 30 })})
          </Typography>
          <Paper>
            <StorageSimpleTable
              headers={[
                { label: t('label.common.storage') },
                { label: t('label.header.transfer') },
                { label: t('label.storage.request') },
                { label: t('label.storage.management') },
                { label: t('label.storage.replication') },
                { label: 'S3 Lambda' },
                { label: t('label.common.total'), variant: 'subtitle2' },
              ]}
              contents={[
                {
                  label: ValueFormatter.toCurrency(ThisMonth.Storage) || '-',
                },
                {
                  label:
                    ValueFormatter.toCurrency(ThisMonth['Data Transfer']) ||
                    '-',
                },
                {
                  label:
                    ValueFormatter.toLocaleString(
                      ThisMonth['API Request'],
                      0,
                    ) || '-',
                },
                {
                  label:
                    ValueFormatter.toLocaleString(ThisMonth.Management, 0) ||
                    '-',
                },
                {
                  label:
                    ValueFormatter.toLocaleString(ThisMonth.Replication, 0) ||
                    '-',
                },
                {
                  label:
                    ValueFormatter.toLocaleString(ThisMonth['S3 Lambda'], 0) ||
                    '-',
                },
                {
                  label: ValueFormatter.toCurrency(ThisMonth.Storage) || '-',
                  variant: 'subtitle2',
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
      {Boolean(RecommendActions?.length) && (
        <Box mt={12}>
          <RecommendActionDivider />
          <Stack spacing={3} mt={3}>
            {RecommendActions.map((item) => (
              <IntelligenceCard
                key={item.UID}
                item={item}
                showSavings={item.CaseId === 'AWS-S3-0001'}
              />
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default DetailsStorageInformation;
