import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEC2AutoComplete } from '@hooks/api/optimization/ec2';
import { useFilterFormik } from '@hooks/common';
import { useAWSHelper } from '@hooks/helper';
import {
  FilterGrid,
  FilterGridItem,
  FilterProps,
  FilterSelect,
  FilterWrapper,
} from '@components/filter';
import { useSetRecoilState } from 'recoil';
import { ec2CPUState, ec2MemState } from '@atoms/optimization';

type ComputeUtilFilterForm = {
  Region: Array<string>;
  InstanceId: string;
  CurrentType: Array<string>;
  Tag: string;
  CPUMax: string;
  MemMax: string;
};

const useComputeUtilFilterForm = (): UseValidation<ComputeUtilFilterForm> => {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      Region: [],
      InstanceId: '',
      CurrentType: [],
      Tag: '',
      CPUMax: '',
      MemMax: '',
    },
    validationSchema: Yup.object({
      Region: Yup.array().of(Yup.string()),
      InstanceId: Yup.string(),
      CurrentType: Yup.array().of(Yup.string()),
      Tag: Yup.string(),
      CPUMax: Yup.string().matches(
        /^[0-9]+([.][0-9]+)?$/,
        t('optimization.decimal'),
      ),
      MemMax: Yup.string().matches(
        /^[0-9]+([.][0-9]+)?$/,
        t('optimization.decimal'),
      ),
    }).defined(),
  };
};

const ComputeUtilFilter: React.FC<FilterProps> = ({
  onClose,
  onFilterChange,
  previousValues,
}) => {
  const { t } = useTranslation('optimization');
  const { getAWSRegionName } = useAWSHelper();
  const { data } = useEC2AutoComplete();
  const setEC2CPU = useSetRecoilState(ec2CPUState);
  const setEC2Mem = useSetRecoilState(ec2MemState);
  const { initialValues, validationSchema } = useComputeUtilFilterForm();
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleArrayChange,
    handleBlur,
    handleSubmit,
  } = useFilterFormik(
    {
      initialValues: previousValues || initialValues,
      validationSchema,
      onSubmit: (v) => {
        if (v.CPUMax.length) {
          setEC2CPU(v.CPUMax);
        } else {
          setEC2CPU(undefined);
        }
        if (v.MemMax.length) {
          setEC2Mem(v.MemMax);
        } else {
          setEC2Mem(undefined);
        }
        onFilterChange(v);
        onClose();
      },
    },
    data?.items,
  );
  if (!data?.items?.length) {
    return null;
  }
  const { Region, CurrentType } = data.items[0];
  return (
    <FilterWrapper onClose={onClose} onSubmit={handleSubmit} isValid={isValid}>
      <FilterGrid>
        <FilterGridItem label={t('label.common.region')}>
          <FilterSelect
            name="Region"
            value={values.Region}
            onChange={(e) => handleArrayChange('Region', e.target.value)}
            onBlur={handleBlur}
            renderValue={() =>
              values.Region.map((v: string) => getAWSRegionName(v)).join(', ')
            }
            selected={values.Region}
            candidate={Region}
            formatter={getAWSRegionName}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.instanceId')}>
          <TextField
            name="InstanceId"
            value={values.InstanceId}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.instanceType')}>
          <FilterSelect
            name="CurrentType"
            value={values.CurrentType}
            onChange={(e) => handleArrayChange('CurrentType', e.target.value)}
            onBlur={handleBlur}
            renderValue={() => values.CurrentType.join(', ')}
            selected={values.CurrentType}
            candidate={CurrentType}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.tags')}>
          <TextField
            name="Tag"
            value={values.Tag}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.util.cpu_long')}>
          <TextField
            name="CPUMax"
            value={values.CPUMax}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{
              inputProps: {
                style: { textAlign: 'right' },
              },
              startAdornment: t('label.filter.under')?.length ? (
                <InputAdornment position="start">
                  {t('label.filter.under')}
                </InputAdornment>
              ) : undefined,
              endAdornment: (
                <InputAdornment position="end">
                  {t('label.filter.percent')}
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.CPUMax && errors.CPUMax)}
            helperText={
              Boolean(touched.CPUMax && errors.CPUMax) && String(errors.CPUMax)
            }
            fullWidth
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.util.mem_long')}>
          <TextField
            name="MemMax"
            value={values.MemMax}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{
              inputProps: {
                style: { textAlign: 'right' },
              },
              startAdornment: t('label.filter.under')?.length ? (
                <InputAdornment position="start">
                  {t('label.filter.under')}
                </InputAdornment>
              ) : undefined,
              endAdornment: (
                <InputAdornment position="end">
                  {t('label.filter.percent')}
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.MemMax && errors.MemMax)}
            helperText={
              Boolean(touched.MemMax && errors.MemMax) && String(errors.MemMax)
            }
            fullWidth
          />
        </FilterGridItem>
      </FilterGrid>
    </FilterWrapper>
  );
};

export default ComputeUtilFilter;
