import React from 'react';
import * as PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';
import { ReactComponent as OverviewIcon } from '@assets/icons/service/Overview.svg';
import { ReactComponent as OptimizationIcon } from '@assets/icons/service/Optimization.svg';
import { ReactComponent as AnomalyDetectionIcon } from '@assets/icons/service/AnomalyDetection.svg';
import { ReactComponent as SmartPlannerIcon } from '@assets/icons/service/SmartPlanner.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/service/Settings.svg';
import { ReactComponent as AdminIcon } from '@assets/icons/service/Admin.svg';
import { ReactComponent as OnboardIcon } from '@assets/icons/service/Onboard.svg';
import { ReactComponent as NotificationIcon } from '@assets/icons/service/Notification.svg';
import { ReactComponent as DetailsIcon } from '@assets/icons/service/Details.svg';
import { ReactComponent as BillingIcon } from '@assets/icons/service/Billing.svg';

const ServiceIcons = {
  Overview: OverviewIcon,
  Optimization: OptimizationIcon,
  'Anomaly Detection': AnomalyDetectionIcon,
  'Smart Planner': SmartPlannerIcon,
  Settings: SettingsIcon,
  Admin: AdminIcon,
  Onboarding: OnboardIcon,
  Notification: NotificationIcon,
  Details: DetailsIcon,
  'Billing Report': BillingIcon,
  'Cloud Cost Report': BillingIcon,
} as const;
export type ServiceIconName = keyof typeof ServiceIcons;
const ServiceIcon: React.FC<{ name: ServiceIconName; size?: number }> = ({
  name,
  size = 64,
}) => (
  <SvgIcon
    component={ServiceIcons[name]}
    sx={{ width: `${size}px`, height: `${size}px` }}
  />
);
ServiceIcon.propTypes = {
  name: PropTypes.oneOf<ServiceIconName>([
    'Overview',
    'Optimization',
    'Anomaly Detection',
    'Smart Planner',
    'Settings',
    'Admin',
    'Onboarding',
    'Notification',
    'Details',
    'Billing Report',
    'Cloud Cost Report',
  ]).isRequired,
  size: PropTypes.number,
};
ServiceIcon.defaultProps = {
  size: undefined,
};
export default React.memo(ServiceIcon);
