import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import { AWSAccountService } from '@api/service';
import { QUERY_KEY } from '@constants';
import { externalTokenState } from '@pages/private/onboard/external/ExternalAccount.atoms';

export default () => {
  const token = useRecoilValue(externalTokenState);
  return useMutation({
    mutationKey: QUERY_KEY.ONBOARD.CREATE_ACCOUNT,
    mutationFn: (
      variables: Pick<
        AWSAccount,
        'name' | 'arn' | 'isPayer' | 'companyId' | 'externalId'
      >,
    ) =>
      AWSAccountService.createAWSAccount(
        variables.name,
        variables.arn,
        variables.isPayer,
        variables.companyId,
        variables.externalId,
        token,
      ),
  });
};
