import React, { useCallback } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelectedAWSAccounts } from '@hooks/api/common';

const AccountSelector: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');
  const {
    temporary,
    selectedAccounts,
    currentAccounts,
    handleAccountChange,
    onOpen,
    saveChanges,
  } = useSelectedAWSAccounts();
  const renderLabel = useCallback(
    (length: number | undefined) => {
      if (currentAccounts.length) {
        if (currentAccounts.length === length) {
          return commonT('label.accountAll');
        }
        return commonT('label.accountSelected', { count: length });
      }
      return commonT('label.accountNone');
    },
    [currentAccounts, commonT],
  );
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="subtitle2" color="textSecondary" mr={2}>
        {t('label.common.accountId')}
      </Typography>
      <Box width={200}>
        <FormControl fullWidth>
          <Select
            size="small"
            value={temporary}
            onChange={(e) => handleAccountChange(e.target.value)}
            renderValue={(v) =>
              renderLabel(v?.length || selectedAccounts.length)
            }
            onOpen={() => onOpen()}
            onClose={() => saveChanges()}
            input={<OutlinedInput />}
            disabled={!currentAccounts?.length}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 350,
                  overflowY: 'auto',
                },
              },
              MenuListProps: {
                disablePadding: true,
              },
            }}
            sx={{
              '> .MuiOutlinedInput-input': {
                paddingTop: '0.622rem',
                paddingBottom: '0.622rem',
              },
            }}
            displayEmpty
            multiple
          >
            {currentAccounts.length &&
              currentAccounts.length !== temporary.length && (
                <MenuItem value={null}>
                  <Checkbox
                    size="medium"
                    checked={
                      temporary.length === Number(currentAccounts?.length)
                    }
                    indeterminate={
                      temporary.length !== 0 &&
                      temporary.length !== Number(currentAccounts?.length)
                    }
                  />
                  <ListItemText
                    primary={`${
                      temporary.length === Number(currentAccounts?.length)
                        ? t('label.common.unselectAll')
                        : t('label.common.selectAll')
                    } (${Number(currentAccounts?.length)})`}
                  />
                </MenuItem>
              )}
            {currentAccounts?.map((item) => (
              <MenuItem
                key={`account_selector_${item.id}`}
                value={item.id}
                disabled={currentAccounts?.length === 1}
              >
                <Checkbox
                  size="medium"
                  checked={temporary.indexOf(item.id) > -1}
                />
                <ListItemText primary={`${item.id} (${item.name})`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default AccountSelector;
