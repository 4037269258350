import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEC2RIUtilization } from '@hooks/api/optimization/ec2';
import { useAWSHelper } from '@hooks/helper';
import { useToggleWithValue } from '@hooks/common';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { ReservedDetailModal } from '@pages/private/planner/reserved/table';
import { DetailButtonIcon } from '@components/table/table.styled';
import { ValueFormatter } from '@utils';
import ComputeRIUtilFilter from './ComputeRIUtilFilter';

const ComputeRIUtilTable: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { getOptimizationAWSRegion } = useAWSHelper();
  const { data, onPageChange, pageSize } = useEC2RIUtilization();
  const [selected, openDetail, toggleDetail] =
    useToggleWithValue<EC2RIUtilization>();

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        minWidth: 140,
        maxWidth: 140,
        disableSortBy: true,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.common.instanceType'),
        accessor: 'InstanceType' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.common.ri', { context: 'number' }),
        accessor: 'NumberOfRI' as const,
        minWidth: 140,
        maxWidth: 140,
        disableSortBy: true,
      },
      {
        Header: t('label.header.utilization'),
        accessor: 'Utilization' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toPercent(value) || '-',
      },
      {
        Header: t('label.common.cost', { context: 'monthly' }),
        accessor: 'MonthlyCost' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value) || '-',
      },
      {
        Header: t('label.common.expiresOn'),
        accessor: 'ExpiredOn' as const,
        minWidth: 120,
        maxWidth: 120,
        disableSortBy: true,
        Cell: ({ value }) =>
          ValueFormatter.toDateString(value, 'YYYY-MM-DD') || '-',
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailButtonIcon onClick={() => toggleDetail(row.original)} />
        ),
      },
    ],
    [getOptimizationAWSRegion],
  );

  return (
    <>
      <DataPaginationTable
        data={data?.body?.items}
        columnInfo={columnInfo}
        totalCount={data?.body?.totalCount}
        onPageChange={onPageChange}
        pageSize={pageSize}
        FilterElement={ComputeRIUtilFilter}
        CSVUrl="ec2/ri_utilization"
        enableFilter
        enableSort
        LastUpdate={data.body.extras.LatestUpdate}
      />
      {openDetail && selected && (
        <ReservedDetailModal
          open={openDetail}
          onClose={toggleDetail}
          Item={selected}
        />
      )}
    </>
  );
};

export default ComputeRIUtilTable;
