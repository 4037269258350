import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useS3AutoComplete } from '@hooks/api/optimization/s3';
import { useFilterFormik } from '@hooks/common';
import {
  FilterGrid,
  FilterGridItem,
  FilterProps,
  FilterWrapper,
} from '@components/filter';
import { TextField } from '@mui/material';

type StorageFilterForm = {
  BucketName: string;
  Status: Array<string>;
};

const useStorageFilterForm = (): UseValidation<StorageFilterForm> => ({
  initialValues: {
    BucketName: '',
    Status: [],
  },
  validationSchema: Yup.object({
    BucketName: Yup.string(),
    Status: Yup.array().of(Yup.string()),
  }).defined(),
});

const StorageFilter: React.FC<FilterProps> = ({
  onClose,
  onFilterChange,
  previousValues,
}) => {
  const { t } = useTranslation('optimization');
  const { data } = useS3AutoComplete();
  const { initialValues, validationSchema } = useStorageFilterForm();
  const { values, handleChange, handleBlur, handleSubmit } = useFilterFormik(
    {
      initialValues: previousValues || initialValues,
      validationSchema,
      onSubmit: (v) => {
        onFilterChange(v);
        onClose();
      },
    },
    data?.items,
  );
  return (
    <FilterWrapper onClose={onClose} onSubmit={handleSubmit}>
      <FilterGrid>
        <FilterGridItem label={t('label.storage.bucket', { context: 'name' })}>
          <TextField
            name="BucketName"
            value={values.BucketName}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </FilterGridItem>
      </FilterGrid>
    </FilterWrapper>
  );
};

export default StorageFilter;
