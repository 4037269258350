import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRDSStorage, useRDSStorageChart } from '@hooks/api/optimization/rds';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { DetailLinkIcon } from '@components/table/table.styled';
import { ROUTES } from '@routes';
import { ValueFormatter } from '@utils';
import { useAWSHelper } from '@hooks/helper';
import DatabaseStorageFilter from './DatabaseStorageFilter';

const DatabaseStorageTable: React.FC = () => {
  const { data, onPageChange, pageSize } = useRDSStorage();
  const { data: chartData } = useRDSStorageChart();

  const { t } = useTranslation('optimization');
  const { getOptimizationAWSRegion } = useAWSHelper();

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        minWidth: 140,
        maxWidth: 140,
        disableSortBy: true,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.db.clusterId'),
        accessor: 'ClusterId' as const,
        maxWidth: 104,
        disableSortBy: true,
      },
      {
        Header: t('label.db.databaseId'),
        accessor: 'InstanceId' as const,
        maxWidth: 140,
        disableSortBy: true,
      },
      {
        Header: t('label.db.databaseType'),
        accessor: 'InstanceType' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.db.databaseEngine'),
        accessor: 'DatabaseEngine' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.common.storage', { context: 'type' }),
        accessor: 'StorageType' as const,
        minWidth: 120,
        disableSortBy: true,
      },
      {
        Header: `${t('label.common.storage')} (GiB)`,
        accessor: 'StorageSpaceMax' as const,
        align: 'right',
        minWidth: 140,
        Cell: ({ value }) => {
          if (value.length) {
            return ValueFormatter.toFixed(value[0].cloudwatch);
          }
          return '-';
        },
      },
      {
        Header: 'IOPS',
        accessor: 'IOPS' as const,
        align: 'right',
      },
      {
        Header: t('label.common.savings'),
        accessor: 'CostSaving' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value) || '-',
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailLinkIcon
            to={`${ROUTES.DETAILS.DATABASE}/${row.original.InstanceId}?tab=storage`}
            requireCUR
          />
        ),
      },
    ],
    [getOptimizationAWSRegion],
  );

  return (
    <DataPaginationTable
      columnInfo={columnInfo}
      data={data?.body?.items}
      totalCount={data?.body?.totalCount}
      onPageChange={onPageChange}
      pageSize={pageSize}
      FilterElement={DatabaseStorageFilter}
      CSVUrl="rds/storage"
      enableFilter
      enableSort
      LastUpdate={chartData.extras.LatestUpdate}
    />
  );
};

export default DatabaseStorageTable;
