import React from 'react';
import {
  Box,
  BoxProps,
  Chip,
  Divider,
  Grid,
  Paper,
  PaperProps,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt';
import { ColorDot, DotLegendItem } from '@components/styled';
import { bluegrey, primary, secondary, tertiary } from '@theme/colors';
import { ReactComponent as ArrowUp } from '@assets/icons/common/ArrowUp.svg';
import { ReactComponent as ArrowDown } from '@assets/icons/common/ArrowDown.svg';
import { useTranslation } from 'react-i18next';
import { getPercentageProportion, ValueFormatter } from '@utils';

const SimulationHeaderComponent: React.FC<
  {
    Title: string | React.ReactNode;
    optional?: string | React.ReactElement;
  } & BoxProps
> = ({ Title, optional, ...props }) => (
  <Box {...props}>
    <Box display="flex" alignItems="center" mb={2}>
      {typeof Title === 'string' && (
        <Typography variant="subtitle2">{Title}</Typography>
      )}
      {typeof Title !== 'string' && <>{Title}</>}
      {optional && typeof optional === 'string' ? (
        <Typography variant="subtitle2" ml={3} sx={{ color: '#A0A1B2' }}>
          {optional}
        </Typography>
      ) : (
        optional
      )}
    </Box>
    <Divider sx={{ borderBottomWidth: '2px' }} />
  </Box>
);
SimulationHeaderComponent.defaultProps = {
  optional: undefined,
};
export const SimulationHeader = React.memo(SimulationHeaderComponent);

const SimulationTableComponent: React.FC<{
  title: string;
  header: Array<string>;
  body: Array<{
    label: string | React.ReactElement;
    items: Array<string | number | React.ReactElement>;
  }>;
  bodyAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}> = ({ title, header, body, bodyAlign }) => {
  const columns = header.length + 1;
  return (
    <Grid container direction="column">
      <Grid container item columns={columns} borderBottom="2px solid #F0F0F7">
        <Grid item p={2} xs borderRight="2px solid #F0F0F7">
          <Typography variant="body2">{title}</Typography>
        </Grid>
        {header.map((item) => (
          <Grid key={item} item p={2} xs>
            <Typography variant="body2" align={bodyAlign}>
              {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {body.map((v, i) => (
        <Grid
          key={`${v.label.toString()}_${v.items.length}_${i.toString()}`}
          container
          item
          columns={columns}
        >
          <Grid item p={2} xs borderRight="2px solid #F0F0F7">
            <Typography variant="body2">{v.label}</Typography>
          </Grid>
          {v.items.map((text, idx) => (
            <Grid
              key={`${text.toString()}_${idx.toString()}`}
              item
              p={2}
              xs
              bgcolor={bluegrey[50]}
            >
              <Typography variant="body2" align={bodyAlign}>
                {text}
              </Typography>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};
SimulationTableComponent.defaultProps = {
  bodyAlign: undefined,
};
export const SimulationTable = React.memo(SimulationTableComponent);

const SimulationFlatTableComponent: React.FC<{
  header: Array<string>;
  body: Array<{
    items: Array<string | number | React.ReactElement>;
  }>;
  bodyAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  maxHeight?: number;
}> = ({ header, body, bodyAlign, maxHeight }) => {
  const columns = header.length + 1;
  return (
    <Grid container direction="column">
      <Grid container item columns={columns} borderBottom="2px solid #F0F0F7">
        {header.map((item) => (
          <Grid key={item} item p={2} xs>
            <Typography variant="body2" align={bodyAlign}>
              {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container item direction="column" maxHeight={maxHeight}>
        {body.map((v, i) => (
          <Grid
            key={`${v.items[0].toString()}_${v.items.length}_${i.toString()}`}
            container
            item
            columns={columns}
          >
            {v.items.map((text, idx) => (
              <Grid
                key={`${text.toString()}_${idx.toString()}`}
                item
                p={2}
                xs
                bgcolor={bluegrey[50]}
              >
                <Typography variant="body2" align={bodyAlign}>
                  {text}
                </Typography>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
SimulationFlatTableComponent.defaultProps = {
  bodyAlign: undefined,
  maxHeight: undefined,
};
export const SimulationFlatTable = React.memo(SimulationFlatTableComponent);

type PreviewLegendItem = {
  label: string;
  color: string;
  before: string;
  after: string;
  change: string;
  increased: boolean;
};
export const PreviewCoverageLegend = React.memo(
  ({ items }: { items: Array<PreviewLegendItem> }) => (
    <Box mt={5}>
      {items.map((item, idx) => (
        <Box
          key={`pr_cv_lg_${item.label}`}
          display="flex"
          justifyContent="space-between"
          mt={idx !== 0 ? 2 : 0}
        >
          <Box display="flex" alignItems="center">
            <ColorDot color={item.color} />
            <Typography variant="body2" color="textSecondary" ml={1}>
              {item.label}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Typography
              variant="body2"
              color="textSecondary"
              align="right"
              minWidth="4rem"
              mr={2}
            >
              {item.before}
            </Typography>
            <ArrowRightIcon
              sx={{ width: '20px', height: '20px', color: bluegrey[600] }}
            />
            <Typography
              variant="body2"
              color="textSecondary"
              align="right"
              minWidth="4rem"
              mx={2}
            >
              {item.after}
            </Typography>
            <Box display="flex" alignItems="center" minWidth="4.5rem">
              <SvgIcon
                component={item.increased ? ArrowUp : ArrowDown}
                sx={{
                  width: '0.5rem',
                  height: '0.5rem',
                  mr: 1,
                  color: item.increased ? secondary[500] : tertiary[300],
                }}
              />
              <Typography
                variant="footer"
                sx={{ color: item.increased ? secondary[500] : tertiary[300] }}
              >
                {item.change}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  ),
);

export const PurchaseChangePaper: React.FC<{
  RICoverage: number | string;
  SPCoverage: number | string;
  OnDemand: number;
  RI: number;
  SP: number;
  Total: number;
  isPast?: boolean;
}> = ({ RICoverage, SPCoverage, OnDemand, RI, SP, Total, isPast }) => {
  const { t } = useTranslation('optimization');
  const { t: plannerT } = useTranslation('planner');
  return (
    <Box
      position="relative"
      border="2px solid #F0F0F7"
      borderRadius="4px"
      mt={5}
    >
      <Box
        position="absolute"
        left="50%"
        bgcolor="#fff"
        px={3}
        sx={{ transform: 'translate(-50%, -50%)' }}
      >
        <Typography color="textSecondary" align="center">
          {isPast ? plannerT('label.past') : plannerT('label.next')}
        </Typography>
        <Typography variant="h3" align="center" mt={1}>
          {ValueFormatter.toCurrency(Total)}
        </Typography>
      </Box>
      <Box px={9} pt={11} pb={5}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <DotLegendItem
            color="#57CAEB"
            label={t('label.common.ri_coverage')}
          />
          <Typography variant="body2" color="textSecondary">
            {ValueFormatter.toPercent(RICoverage)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <DotLegendItem
            color="#9594FF"
            label={t('label.common.sp_coverage')}
          />
          <Typography variant="body2" color="textSecondary">
            {ValueFormatter.toPercent(SPCoverage)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={5}>
          <Typography variant="body2" color="textSecondary">
            {t('label.common.od')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {ValueFormatter.toCurrency(OnDemand)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography variant="body2" color="textSecondary">
            {t('label.common.ri')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {ValueFormatter.toCurrency(RI)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography variant="body2" color="textSecondary">
            {t('label.common.sp')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {ValueFormatter.toCurrency(SP)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography variant="body2" color="textSecondary">
            {plannerT('label.total')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {ValueFormatter.toCurrency(Total)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
PurchaseChangePaper.defaultProps = {
  isPast: false,
};

export const GuideHeader = React.memo(({ label }: { label: string }) => (
  <Box
    bgcolor={bluegrey[100]}
    borderBottom={1}
    borderColor={bluegrey[300]}
    mb={2}
    p={1}
  >
    <Typography variant="subtitle2" color="textSecondary">
      {label}
    </Typography>
  </Box>
));

const GuideItemComponent: React.FC<
  {
    ChangedMonthlyCost: number;
    CostSaving: number;
    Duration: 1 | 3;
    PaymentOption: PaymentOption;
    selected: boolean;
    Cost?: number;
    Commitment?: number;
  } & Pick<PaperProps, 'onClick'>
> = ({
  ChangedMonthlyCost,
  CostSaving,
  Duration,
  PaymentOption,
  selected,
  onClick,
  Cost,
  Commitment,
}) => {
  const { t } = useTranslation('planner');
  return (
    <Grid item xs={6}>
      <Paper
        variant="elevation"
        onClick={onClick}
        sx={{
          p: 2,
          cursor: 'pointer',
          backgroundColor: selected ? primary[100] : '#fff',
        }}
      >
        <Stack direction="row" spacing={1}>
          <Typography
            variant="subtitle2"
            color={selected ? 'textPrimary' : 'textSecondary'}
          >
            {t('label.discountRate')}
          </Typography>
          <Typography
            variant="subtitle2"
            color={selected ? 'primary' : 'textSecondary'}
          >
            {ValueFormatter.toPercent(
              getPercentageProportion(
                CostSaving,
                ChangedMonthlyCost + CostSaving,
              ),
            )}
          </Typography>
        </Stack>
        <Typography
          variant="caption"
          component="p"
          color="textSecondary"
          my={1}
        >
          {Cost &&
            t('label.perHour', {
              value: ValueFormatter.toCurrency(Cost, undefined, 3),
            })}
          {Commitment &&
            t('label.perHour', {
              value: ValueFormatter.toCurrency(Commitment, undefined, 3),
            })}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Chip
            variant={selected ? 'outlined' : 'filled'}
            color={selected ? 'primary' : 'default'}
            label={Duration === 1 ? t('Term.1-yr') : t('Term.3-yr')}
          />
          <Chip
            variant={selected ? 'outlined' : 'filled'}
            color={selected ? 'primary' : 'default'}
            label={t(`PaymentOption.${PaymentOption}`)}
          />
        </Stack>
      </Paper>
    </Grid>
  );
};
GuideItemComponent.defaultProps = {
  Cost: undefined,
  Commitment: undefined,
};
export const GuideItem = React.memo(GuideItemComponent);
