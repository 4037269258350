import React from 'react';
import { Paper, Typography } from '@mui/material';
import { SuspenseBox, SuspensePaper } from '@components/styled/suspense';
import { useTranslation } from 'react-i18next';
import { ComputeTransferChart } from './chart';
import { ComputeTransferSummaryTable, ComputeTransferTable } from './table';

const ComputeTransferPage: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary" mb={2}>
        {t('label.common.summary')} ({commonT('label.days', { count: 30 })})
      </Typography>
      <SuspensePaper sx={{ px: 7, py: 5 }}>
        <ComputeTransferSummaryTable />
      </SuspensePaper>
      <Typography variant="subtitle2" color="textSecondary" mt={6} mb={2}>
        {t('label.transfer.dailyCost')}
      </Typography>
      <Paper square sx={{ px: 7, py: 5 }}>
        <SuspenseBox>
          <ComputeTransferChart />
        </SuspenseBox>
        <SuspenseBox mt={12}>
          <ComputeTransferTable />
        </SuspenseBox>
      </Paper>
    </>
  );
};

export default ComputeTransferPage;
