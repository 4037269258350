export default {
  OVERVIEW: '/overview',
  USERS: '/users',
  COMPANIES: '/companies',
  ROLES: '/roles',
  PERMISSIONS: '/permissions',
  ES_CLIENT: '/es/client',
  OPTIMIZER: '/aws/optimizer',
  OPTIMIZATION: '/optimization',
  GROUPS: '/groups',
  SMARTPLANNER: '/smartplanner',
  PAYMENT: '/payments',
  INTEGRATION: '/integrations',
  SLACK: '/integrations/slack',
  DATADOG: '/integrations/datadog',
  NEWRELIC: '/integrations/newrelic',
  DYNATRACE: '/integrations/dynatrace',
  BILLING: '/billing',
  DIAGNOSTIC: '/diagnostic',
  CMP: '/cmp',
  AWS: {
    ACCOUNTS: '/aws/accounts',
    CLIENT: '/aws/client',
    CLIENT_WITH_REGION: '/aws/client_with_region',
    EC2: '/aws/ec2',
    CUR: '/aws/cur',
    REPORTS: '/aws/reports',
    CE: '/aws/ce',
  },
} as const;
