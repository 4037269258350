import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useGetUsageAutoComplete } from '@hooks/api/aws/ce';
import { ValueFormatter } from '@utils';
import { ceUsageState } from '@atoms/aws';

export default function AccountCount() {
  const { t } = useTranslation('usage');
  const { data } = useGetUsageAutoComplete();
  const filterValue = useRecoilValue(ceUsageState);
  const value = useMemo(() => {
    if (data) {
      // if (
      //   filterValue.AccountIdList &&
      //   data.items[0].NumberOfAccounts !== filterValue.AccountIdList.length
      // ) {
      //   return ValueFormatter.toFixed(filterValue.AccountIdList.length, 0);
      // }
      return ValueFormatter.toFixed(data.items[0].NumberOfAccounts, 0);
    }
    return '-';
  }, [data, filterValue]);
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary" align="center">
        {t('text.member_accounts')}
      </Typography>
      <Typography variant="h3" color="tertiary" align="center" mt="8px">
        {value}
      </Typography>
    </>
  );
}
