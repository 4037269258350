/* eslint-disable react/jsx-key */
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import * as PropTypes from 'prop-types';
import { useFlexLayout, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDownloadCEUsage } from '@hooks/api/aws/ce';
import { ceUsageState } from '@atoms/aws';
import { bluegrey, tertiary } from '@theme/colors';
import { SimpleTableProps } from '../table.types';

function CEUsageTable({ columnInfo, data }: SimpleTableProps) {
  const { t } = useTranslation('usage');
  const filterValue = useRecoilValue(ceUsageState);
  const { mutateAsync, isLoading: isCSVDownloading } = useDownloadCEUsage();
  const downloadCSV = useCallback(async () => {
    try {
      const { blob, filename } = await mutateAsync({
        ...filterValue,
        FromDate: moment(filterValue.FromDate).format('YYYY-MM-DDT00:00:00'),
        ToDate: moment(filterValue.ToDate).format('YYYY-MM-DDT23:59:59'),
      });
      const url = window.URL.createObjectURL(
        new Blob([blob], { type: 'text/csv' }),
      );
      const link = document.createElement('a');
      link.id = 'cc_usage_download';
      link.href = url;
      link.download = filename ?? `CloudCostUsage_${new Date().getTime()}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error(e);
    }
  }, [filterValue, mutateAsync]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns: columnInfo,
        defaultCanSort: false,
        data,
      },
      useFlexLayout,
      useSticky,
    );

  return (
    <Box sx={{ mt: '56px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '16px',
        }}
      >
        <Typography variant="subtitle2" color="textSecondary">
          {t('title.usage_table')}
        </Typography>
        <IconButton
          onClick={downloadCSV}
          disabled={isCSVDownloading}
          sx={{
            width: '2.5rem',
            height: '2.5rem',
          }}
        >
          {isCSVDownloading ? (
            <CircularProgress
              color="inherit"
              size="1rem"
              sx={{
                color: 'action.disabled',
              }}
            />
          ) : (
            <DownloadIcon
              sx={{
                color: isCSVDownloading ? bluegrey[200] : tertiary[600],
              }}
            />
          )}
        </IconButton>
      </Box>
      <Box sx={{ overflowX: 'auto' }}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                sx={{ borderBottom: '1px solid #f0f0f7' }}
              >
                {headerGroup.headers.map((column, idx) => (
                  <TableCell
                    {...column.getHeaderProps()}
                    align={column.align}
                    sx={{
                      padding: '8px',
                      ...((idx === 0 || idx === 1) && {
                        background: '#f8f8fb',
                      }),
                      ...(idx === 1 && {
                        marginTop: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        borderRight: '1px solid #cecfde',
                        boxShadow: '2px 0 4px 0 #0000001a',
                        clipPath: 'inset(0px -4px 0 0)',
                      }),
                    }}
                  >
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, idx) => (
                    <TableCell
                      {...cell.getCellProps()}
                      align={cell.column.align}
                      title={
                        idx > 0 &&
                        (typeof cell.value === 'string' ||
                          typeof cell.value === 'number')
                          ? cell.value.toString()
                          : undefined
                      }
                      sx={{
                        padding: '8px',
                        ...((idx === 0 || idx === 1) && {
                          background: '#f8f8fb',
                        }),
                        ...(idx === 1 && {
                          position: 'relative',
                          marginTop: 0,
                          marginBottom: 0,
                          marginLeft: 0,
                          borderRight: '1px solid #cecfde',
                          boxShadow: '2px 0 4px 0 #0000001a',
                          clipPath: 'inset(0px -4px 0 0)',
                        }),
                      }}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}
CEUsageTable.propTypes = {
  columnInfo: PropTypes.arrayOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.any),
};
CEUsageTable.defaultProps = {
  data: [],
};

export default CEUsageTable;
