import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Link, SvgIcon, Typography } from '@mui/material';
import { LanguageSelectorPublic, Splash } from '@components/common';
import { ReactComponent as CCLogo } from '@assets/images/cc_logo.svg';
import { useTranslation } from 'react-i18next';

const PureAuthLayout: React.FC = () => {
  const { t } = useTranslation('common');
  return (
    <Suspense fallback={<Splash />}>
      <Box
        display="flex"
        position="relative"
        flexDirection="column"
        height="100vh"
      >
        <Box position="absolute" top={0} left={0} ml={3} mt={3}>
          <SvgIcon
            component={CCLogo}
            viewBox="0 0 460 120"
            sx={{ width: '180px', height: '46.96px' }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
        >
          <Box width={408} maxWidth={408}>
            <Outlet />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          bgcolor="white"
          px={4}
          py={2}
        >
          <Box>
            <Typography variant="footer" color="textSecondary">
              © {new Date().getFullYear()}, Grumatic, Inc. All rights reserved.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <LanguageSelectorPublic />
            <Link
              href="https://www.grumatic.com/privacy-policy"
              target="_blank"
              variant="footer"
              color="textSecondary"
              underline="none"
              ml={2}
            >
              {t('label.privacyPolicy')}
            </Link>
          </Box>
        </Box>
      </Box>
    </Suspense>
  );
};

export default PureAuthLayout;
