/* eslint-disable default-case,jsx-a11y/role-supports-aria-props,@typescript-eslint/ban-ts-comment */
import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Radio,
  RadioGroup,
  ListItemText,
  ClickAwayListener,
  InputAdornment,
} from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import { ArrowDropDown, ArrowDropUp, Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useGetUsageAutoComplete } from '@hooks/api/aws/ce';
import { UsageFilterInput, UsagePopper, UsagePopperComponent } from './styled';

type ACItem = {
  Name: string;
  UID: string;
};
type UsageLinkedCompaniesAutocompleteProps = {
  filter: CEUsageRequest;
  setFilter: React.Dispatch<React.SetStateAction<CEUsageRequest>>;
};
export default function UsageLinkedCompaniesAutocomplete({
  filter,
  setFilter,
}: UsageLinkedCompaniesAutocompleteProps) {
  const { t } = useTranslation('usage');
  const { t: commonT } = useTranslation('common');
  const [isExclude, setIsExclude] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const popperId = open ? 'usage-popper-LINKED_COMPANY' : undefined;
  const { data: response } = useGetUsageAutoComplete();
  const handleOpen = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setIsExclude(typeof filter.LinkedCompaniesExclude !== 'undefined');
      setAnchorEl(e.currentTarget);
    },
    [filter],
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const values = useMemo(() => {
    if (response?.items?.length) {
      if (filter.LinkedCompaniesExclude) {
        return response.items[0].LinkedCompanies.filter((v) =>
          filter.LinkedCompaniesExclude?.includes(v.UID),
        );
      }
      if (filter.LinkedCompanies) {
        return response.items[0].LinkedCompanies.filter((v) =>
          filter.LinkedCompanies?.includes(v.UID),
        );
      }
    }
    return [];
  }, [response?.items, filter]);
  const optionsOrigin = useMemo(() => {
    if (response?.items?.length) {
      return _.uniq(response.items[0].LinkedCompanies ?? []);
    }
    return [];
  }, [response]);
  const options = useMemo(() => {
    return _.concat([{ Name: ' ', UID: ' ' }], optionsOrigin);
  }, [optionsOrigin]);
  const setValues = useCallback(
    (newValue: ACItem[], manualExclude?: boolean) => {
      const exclusion = manualExclude ?? isExclude;
      if (exclusion) {
        setFilter((v) => ({
          ...v,
          LinkedCompaniesExclude: newValue.map((i) => i.UID),
          LinkedCompanies: undefined,
          AccountIdList: undefined,
        }));
      } else {
        setFilter((v) => ({
          ...v,
          LinkedCompanies: newValue.map((i) => i.UID),
          LinkedCompaniesExclude: undefined,
          AccountIdList: undefined,
        }));
      }
    },
    [setFilter, isExclude],
  );
  const isAllSelected = useMemo(() => {
    return (
      optionsOrigin
        .map((v) => v.UID)
        .every((e) => values.map((v) => v.UID).includes(e)) ?? false
    );
  }, [optionsOrigin, values]);
  const isPartialSelected = useMemo(() => {
    return (
      optionsOrigin
        .map((v) => v.UID)
        .some((e) => values.map((v) => v.UID).includes(e)) ?? false
    );
  }, [optionsOrigin, values]);
  const toggleAll = useCallback(() => {
    if (isAllSelected) {
      setValues([]);
    } else {
      setValues(optionsOrigin ?? []);
    }
  }, [optionsOrigin, isAllSelected, setValues]);
  const getChecked = useCallback(
    (option: ACItem, selected: boolean) => {
      if (_.isEmpty(option.UID.trim())) {
        return isAllSelected;
      }
      return selected;
    },
    [isAllSelected],
  );
  const getIndeterminate = useCallback(
    (option: ACItem) => {
      if (_.isEmpty(option.UID.trim())) {
        return isPartialSelected && !isAllSelected;
      }
      return undefined;
    },
    [isAllSelected, isPartialSelected],
  );
  const getOptionLabel = useCallback(
    (option: ACItem) => {
      if (_.isEmpty(option.UID.trim())) {
        return t('text.select_all', {
          count: Math.max(optionsOrigin.length, 0),
        });
      }
      return option.Name;
    },
    [optionsOrigin.length, t],
  );
  const placeholder = useMemo(() => {
    if (!optionsOrigin.length) {
      return t('filter.disabled', { name: t('filter.linked_companies') });
    }
    if (values.length) {
      return t(`filter.${isExclude ? 'excluded' : 'included'}`, {
        count: values.length,
      });
    }
    return t('filter.selected_all', { name: t('filter.linked_companies') });
  }, [values, optionsOrigin, isExclude, t]);
  const handleChangeExclusion = useCallback(
    (v: boolean) => {
      setIsExclude(v);
      setValues(values, v);
    },
    [values, setValues],
  );
  return (
    <>
      <TextField
        placeholder={placeholder}
        InputProps={{
          onClick: handleOpen,
          endAdornment: (
            <InputAdornment position="end">
              {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </InputAdornment>
          ),
        }}
        fullWidth
        sx={(theme) => ({
          [`> .${outlinedInputClasses.root}`]: {
            cursor: 'pointer',
            pr: '8px',
            [`> .${outlinedInputClasses.input}`]: {
              cursor: 'pointer',
              userSelect: 'none',
              pr: 0,
              ...(Boolean(values.length) && {
                '&::placeholder': {
                  color: theme.palette.text.primary,
                },
                '&::-webkit-input-placeholder': {
                  color: theme.palette.text.primary,
                },
                '&::-ms-input-placeholder': {
                  color: theme.palette.text.primary,
                },
              }),
            },
            [`> .${inputAdornmentClasses.root}`]: {
              '> svg': {
                width: '20px',
                height: '20px',
                color: '#a0a1b2',
              },
            },
          },
        })}
      />
      <UsagePopper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box sx={{ p: '16px', borderBottom: '1px solid #f0f0f7' }}>
              <RadioGroup
                value={isExclude}
                onChange={(e, v) => handleChangeExclusion(v === 'true')}
                row
              >
                <FormControlLabel
                  control={<Radio sx={{ p: 0, pr: '8px' }} />}
                  value={false}
                  label={t('filter.include')}
                  sx={{ ml: 0 }}
                />
                <FormControlLabel
                  control={<Radio sx={{ p: 0, pr: '8px' }} />}
                  value
                  label={t('filter.exclude')}
                  sx={{ ml: 0 }}
                />
              </RadioGroup>
            </Box>
            <Autocomplete
              open
              multiple
              disableCloseOnSelect
              disableClearable
              PopperComponent={UsagePopperComponent}
              value={values}
              onClose={(e, reason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              // @ts-ignore
              onChange={(
                event,
                value: { Name: string; UID: string }[],
                reason,
              ) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                // if (value.every((v) => typeof v !== 'string')) {
                if (value.find((v) => _.isEmpty(v.UID.trim()))) {
                  toggleAll();
                  return;
                }
                setValues(value);
                // }
              }}
              options={options}
              getOptionLabel={(option) => option.Name}
              noOptionsText={t('text.no_tag_keys')}
              renderTags={() => null}
              renderOption={(props, option, { selected }) => (
                <li {...props} aria-selected={false}>
                  <Checkbox
                    checked={getChecked(option, selected)}
                    indeterminate={getIndeterminate(option)}
                    sx={{ p: 0, pr: '8px' }}
                  />
                  <ListItemText sx={{ my: 0, wordWrap: 'break-word' }}>
                    {getOptionLabel(option)}
                  </ListItemText>
                </li>
              )}
              renderInput={(params) => (
                <UsageFilterInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                  autoFocus
                  placeholder={t('filter.filtering', {
                    name: t('filter.linked_companies'),
                  })}
                  fullWidth
                />
              )}
              disabled={!optionsOrigin.length}
            />
          </div>
        </ClickAwayListener>
      </UsagePopper>
    </>
  );
}
