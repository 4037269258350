import React from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Grid, Typography } from '@mui/material';
import {
  ChartDefaultGradient,
  GrmtAxisDefaultProps,
  GrmtChartXAxisProps,
  GrmtDefaultChartProps,
  GrmtGridDefaultProps,
  GrmtTooltipItem,
  GrmtTooltipWrapper,
} from '@components/chart';
import { DotLegendItem, DotLegendWrapper } from '@components/styled';
import { primary } from '@theme/colors';
import moment from 'moment';
import { ValueFormatter } from '@utils';

type StorageChartExtra = {
  value: string | number;
  label: string;
};

type StorageChartProps = GrmtDefaultChartProps &
  GrmtChartXAxisProps & {
    chartInfo: {
      line?: {
        dataKey: string;
        name: string;
      };
      area?: {
        dataKey: string;
        name: string;
      };
    };
    extraInfo?: [StorageChartExtra, StorageChartExtra, StorageChartExtra];
  };

const StorageChart: React.FC<StorageChartProps> = ({
  data,
  xAxis,
  chartInfo,
  extraInfo,
}) => (
  <Grid
    container
    justifyContent="space-between"
    alignItems="center"
    columns={20}
  >
    <Grid item md={extraInfo ? 15 : 20} xs={20}>
      <DotLegendWrapper>
        {chartInfo.line && (
          <DotLegendItem color={primary[500]} label={chartInfo.line.name} />
        )}
        {chartInfo.area && (
          <DotLegendItem color="#9594FF" label={chartInfo.area.name} />
        )}
      </DotLegendWrapper>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={data}
          margin={{ top: 24, bottom: 24, left: 24, right: 24 }}
        >
          {ChartDefaultGradient}
          <defs>
            <linearGradient
              id="storageGradient"
              x1="0"
              y1="0"
              x2="0"
              y2="100%"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9594FF" stopOpacity="0.56" offset="0%" />
              <stop stopColor="#9594FF" stopOpacity="0.08" offset="100%" />
            </linearGradient>
          </defs>
          <XAxis {...xAxis} {...GrmtAxisDefaultProps} />
          <YAxis
            scale="linear"
            label={{
              value: 'US $',
              position: 'insideLeft',
              angle: -90,
              offset: 0,
              fill: '#A0A1B2',
              fontSize: '12px',
              fontWeight: 400,
            }}
            domain={[0, 'dataMax']}
            {...GrmtAxisDefaultProps}
          />
          <CartesianGrid {...GrmtGridDefaultProps} />
          <Tooltip
            cursor={false}
            content={({ active, payload, label }) => (
              <GrmtTooltipWrapper
                active={active}
                payload={payload}
                label={moment(label).format('YYYY MMM')}
              >
                {Boolean(payload?.length) &&
                  payload.map((item) => (
                    <GrmtTooltipItem
                      key={item.dataKey}
                      dotColor={item.color}
                      name={item.name}
                      value={ValueFormatter.toCurrency(item.value)}
                    />
                  ))}
              </GrmtTooltipWrapper>
            )}
          />
          {chartInfo.line && (
            <Line
              dataKey={chartInfo.line.dataKey}
              name={chartInfo.line.name}
              stroke={primary[500]}
              strokeWidth={2}
              dot={{ stroke: primary[500], fill: primary[500], r: 3 }}
              activeDot={{ stroke: primary[500], fill: primary[500], r: 3 }}
            />
          )}
          {chartInfo.area && (
            <Area
              dataKey={chartInfo.area.dataKey}
              name={chartInfo.area.name}
              stroke="#9594FF"
              strokeWidth={2}
              fillOpacity={2}
              fill="url(#storageGradient)"
              dot={false}
              activeDot={{ stroke: '#9594FF', fill: '#9594FF', r: 3 }}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Grid>
    {extraInfo && (
      <Grid
        item
        display={{ md: 'block', xs: 'flex' }}
        justifyContent="space-between"
        md={4}
        xs={20}
        mt={{ md: 0, xs: 4 }}
      >
        <Box borderLeft="2px solid #5C5C66" pl={2}>
          <Typography variant="subtitle2">{extraInfo[0].value}</Typography>
          <Typography variant="caption" color="textSecondary">
            {extraInfo[0].label}
          </Typography>
        </Box>
        <Box borderLeft="2px solid #5C5C66" mt={{ md: 7, xs: 0 }} pl={2}>
          <Typography variant="subtitle2">{extraInfo[1].value}</Typography>
          <Typography variant="caption" color="textSecondary">
            {extraInfo[1].label}
          </Typography>
        </Box>
        <Box
          borderLeft={`2px solid ${primary[600]}`}
          color={primary[600]}
          mt={{ md: 7, xs: 0 }}
          pl={2}
        >
          <Typography variant="subtitle2">{extraInfo[2].value}</Typography>
          <Typography variant="caption" color="textSecondary">
            {extraInfo[2].label}
          </Typography>
        </Box>
      </Grid>
    )}
  </Grid>
);
StorageChart.defaultProps = {
  extraInfo: undefined,
};

export default React.memo(StorageChart);
