import React from 'react';
import { OptimizationPaper } from '@components/styled/paper';
import { SuspenseBox } from '@components/styled/suspense';
import { ComputeUtilChart } from './chart';
import { ComputeUtilTable } from './table';

const ComputeUtilizationPage: React.FC = () => (
  <OptimizationPaper showAPM>
    <SuspenseBox>
      <ComputeUtilChart />
    </SuspenseBox>
    <SuspenseBox mt={12}>
      <ComputeUtilTable />
    </SuspenseBox>
  </OptimizationPaper>
);

export default ComputeUtilizationPage;
