import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import JsPdf from 'jspdf';
import { useCurrentInfo } from '@hooks/api/common';
import { HighlightedTypography, TypoWithLink } from '@components/styled';
import { RegionMap } from '@components/ui';
import {
  ReportBestPracticeChart,
  ReportSummaryChart,
} from '@components/chart/onboard';
import { ValueFormatter } from '@utils';
import { bluegrey } from '@theme/colors';
import { ReactComponent as GrmtBulb } from '@assets/icons/common/GrmtBulb.svg';
import CostClipperLogo from '@assets/images/cc_logo_report.png';
import { useAWSAccountHelper } from '@hooks/helper';
import { ChartCoverBox } from '@components/styled/noData';
import { useTagManager } from '@hooks/common';
import useDiagnosticData from './DiagnosticReport.hooks';
import {
  CheckedServices,
  ServiceDetails,
  SummaryTitle,
  ThickDivider,
  TipBox,
} from './DiagnosticReport.styled';

const DiagnosticReport: React.FC = () => {
  const { t } = useTranslation('onboard');
  const { t: awsT } = useTranslation('aws');
  const { navigateToCur } = useAWSAccountHelper();
  const { currentCompany } = useCurrentInfo();
  const regions: Record<SupportedAWSRegion, string> = awsT('region', {
    returnObjects: true,
  });
  const {
    getCostByCategory,
    getItemByService,
    getCheckedService,
    totalSavings,
    totalActions,
    totalResources,
    totalSavingsByResult,
    totalActionsByResult,
    getCostByKey,
    getItemByKey,
    accounts,
    selectedRegion,
    graphMax,
    isFinished,
  } = useDiagnosticData();
  const { sendDataLayer } = useTagManager();
  const navigateCurConfigure = useCallback(() => {
    sendDataLayer('cc-onboard-diagnostic-configure-cur');
    navigateToCur();
  }, [navigateToCur, sendDataLayer]);
  const generatePDF = useCallback(async () => {
    sendDataLayer('cc-onboard-diagnostic-download-pdf');
    const section1 = document.getElementById('diagnostic_report_section_1');
    const section2 = document.getElementById('diagnostic_report_section_2');
    const section3 = document.getElementById('diagnostic_report_section_3');
    const canvas1 = await html2canvas(section1, {
      onclone: (cloneDoc) => {
        const svg = cloneDoc.querySelector('svg');
        if (svg) {
          svg.outerHTML = svg.outerHTML.replace(/svg:svg/g, 'svg');
        }
      },
    });
    const canvas2 = await html2canvas(section2);
    const canvas3 = await html2canvas(section3);
    const image1 = canvas1.toDataURL('image/png');
    const image2 = canvas2.toDataURL('image/png');
    const image3 = canvas3.toDataURL('image/png');
    const pdf = new JsPdf();
    const height1 = (canvas1.height * 210.0) / canvas1.width;
    const height2 = (canvas2.height * 210.0) / canvas2.width;
    const height3 = (canvas3.height * 210.0) / canvas3.width;
    pdf.addImage(CostClipperLogo, 'JPEG', 7, 7, 38, 10, 'Logo', 'FAST');
    pdf.addImage(image1, 'JPEG', 0, 22, 210, height1, 'Page1', 'FAST');
    pdf.addPage();
    pdf.addImage(image2, 'JPEG', 0, 0, 210, height2, 'Page2', 'FAST');
    pdf.addPage();
    pdf.addImage(image3, 'JPEG', 0, 0, 210, height3, 'Page3', 'FAST');
    pdf.save(`${currentCompany?.name}_diagnostic_report.pdf`);
  }, [sendDataLayer]);

  return (
    <Box width="1390px" minWidth="1390px" maxWidth="1390px">
      {isFinished ? (
        <Box display="flex" alignItems="center" p={7} pb={0}>
          <Typography variant="h3">
            {t('report.header.complete.title')}
          </Typography>
          <Stack direction="row" spacing={2} ml={3}>
            <Button type="button" color="secondary" onClick={generatePDF}>
              {t('report.label.download')}
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={navigateCurConfigure}
            >
              {t('title.configureCUR')}
            </Button>
          </Stack>
        </Box>
      ) : (
        <Box px={7} mt={7} mb={5}>
          <Typography variant="h3">
            {t('report.header.progress.title')}
          </Typography>
          <TypoWithLink
            variant="body2"
            sx={{ mt: 2 }}
            dangerouslySetInnerHTML={{
              __html: t('report.header.progress.desc', {
                URL: '/docs/getting-started/diagnostic/report',
              }),
            }}
          />
        </Box>
      )}
      <Box id="diagnostic_report_section_1" px={7} pt={7}>
        <Box>
          <HighlightedTypography variant="subtitle2">
            {t('report.title.account')} <span>{accounts.join(', ')}</span>
          </HighlightedTypography>
          <ThickDivider />
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: t('report.desc.account') }}
          />
        </Box>
        <Box mt={12} mb={5}>
          <Typography variant="subtitle2">
            {t('report.title.scanResult')}
          </Typography>
          <ThickDivider />
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: t('report.desc.scanResult', {
                INSTANCES: ValueFormatter.toLocaleString(totalResources, 0),
                ACTIONS: ValueFormatter.toLocaleString(totalActions, 0),
                SAVINGS: ValueFormatter.toCurrency(totalSavings),
              }),
            }}
          />
        </Box>
        <Box mb={3} data-html2canvas-ignore="true">
          <RegionMap visibleRegions={selectedRegion} />
        </Box>
        <Paper square sx={{ px: 4, py: 3 }}>
          <Typography variant="subtitle2">
            {t('report.title.regions')}
          </Typography>
          <Grid container columns={10} mt={3}>
            {selectedRegion.map((region) => (
              <Grid key={region} item md={2} xs={5}>
                <Typography variant="body2" component="li">
                  {regions[region]}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Paper>
        <Paper square sx={{ mt: 3 }}>
          <Box p={2} bgcolor={bluegrey[50]}>
            <Typography variant="body2">{t('report.title.checked')}</Typography>
          </Box>
          <Divider />
          <CheckedServices
            services={[
              {
                service: 'EC2',
                instances: getCheckedService('EC2', 'Instances'),
                items: getCheckedService('EC2', 'Items'),
              },
              {
                service: 'RDS',
                instances: getCheckedService('RDS', 'Instances'),
                items: getCheckedService('RDS', 'Items'),
              },
              {
                service: 'EBS',
                instances: getCheckedService('EBS', 'Instances'),
                items: getCheckedService('EBS', 'Items'),
              },
              {
                service: 'Other',
                instances: getCheckedService('Other', 'Instances'),
                items: getCheckedService('Other', 'Items'),
              },
            ]}
          />
        </Paper>
        <Box mt={11}>
          <Typography variant="subtitle2">
            {t('report.title.summary')}
          </Typography>
          <ThickDivider />
        </Box>
        <Grid container item spacing={3}>
          <Grid item xs={6}>
            <Box mb={2}>
              <SummaryTitle
                dangerouslySetInnerHTML={{
                  __html: t('report.title.byCategory'),
                }}
              />
            </Box>
            <Paper square>
              <ReportSummaryChart
                total={ValueFormatter.toCurrency(totalSavingsByResult)}
                data={[
                  {
                    name: t('report.label.utilization'),
                    value: getCostByCategory('RightSizing'),
                  },
                  {
                    name: t('report.label.coverage'),
                    value: getCostByCategory('PricingPlan'),
                  },
                  {
                    name: t('report.label.generation'),
                    value: getCostByCategory('Generation'),
                  },
                  {
                    name: t('report.label.storage'),
                    value: getCostByCategory('Storage'),
                  },
                  {
                    name: t('report.label.misc'),
                    value: getCostByCategory('Misc'),
                  },
                ]}
                valueFormatter={ValueFormatter.toCurrency}
                noDataText={t('report.label.noSavings')}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Box mb={2}>
              <SummaryTitle
                dangerouslySetInnerHTML={{
                  __html: t('report.title.byService'),
                }}
              />
            </Box>
            <Paper square>
              <ReportSummaryChart
                total={ValueFormatter.toLocaleString(totalActionsByResult, 0)}
                data={[
                  { name: 'EC2', value: getItemByService('EC2') },
                  { name: 'RDS', value: getItemByService('RDS') },
                  { name: 'EBS', value: getItemByService('EBS') },
                  { name: 'Other', value: getItemByService('Other') },
                ]}
                valueFormatter={(value: any) =>
                  ValueFormatter.toLocaleString(value, 0)
                }
                noDataText={t('report.label.noActions')}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box mt={5} />
      <Box id="diagnostic_report_section_2" pt={7} px={7}>
        <Box>
          <Typography variant="subtitle2">
            {t('report.title.details')}
          </Typography>
          <ThickDivider />
        </Box>
        <Paper square sx={{ my: 3, px: 3, py: 5 }}>
          <ChartCoverBox
            isEmpty={isFinished && !totalSavings && !totalActions}
            text={t('report.label.noSavings')}
          >
            <Grid container columnSpacing={4} columns={10}>
              <Grid item xs={3}>
                <ReportBestPracticeChart
                  data={[
                    {
                      name: t('report.label.utilization'),
                      cost: getCostByKey('EC2RightSizing'),
                    },
                    {
                      name: t('report.label.coverage'),
                      cost: getCostByKey('EC2PricingPlan'),
                    },
                    {
                      name: t('report.label.generation'),
                      cost: getCostByKey('EC2Generation'),
                    },
                  ]}
                  colors={['#9594FF', '#9594FF99', '#9594FF66']}
                  dataMax={graphMax}
                />
                <Box p={1} sx={{ backgroundColor: '#9594FF1A' }}>
                  <Typography variant="subtitle2" align="center">
                    EC2
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <ReportBestPracticeChart
                  data={[
                    {
                      name: t('report.label.utilization'),
                      cost: getCostByKey('RDSRightSizing'),
                    },
                    {
                      name: t('report.label.coverage'),
                      cost: getCostByKey('RDSPricingPlan'),
                    },
                    {
                      name: t('report.label.generation'),
                      cost: getCostByKey('RDSGeneration'),
                    },
                    {
                      name: t('report.label.storage'),
                      cost: getCostByKey('RDSStorage'),
                    },
                  ]}
                  colors={['#57CAEB', '#57CAEB99', '#57CAEB66', '#57CAEB66']}
                  dataMax={graphMax}
                />
                <Box p={1} sx={{ backgroundColor: '#57CAEB1A' }}>
                  <Typography variant="subtitle2" align="center">
                    RDS
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <ReportBestPracticeChart
                  data={[
                    {
                      name: t('report.label.unusedVolumeChart'),
                      cost: getCostByKey('EBSUnusedVolume'),
                    },
                    {
                      name: t('report.label.volumeTypeChart'),
                      cost: getCostByKey('EBSVolumeType'),
                    },
                  ]}
                  colors={['#5BD9B3', '#5BD9B399']}
                  dataMax={graphMax}
                />
                <Box p={1} sx={{ backgroundColor: '#5BD9B31A' }}>
                  <Typography variant="subtitle2" align="center">
                    EBS
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <ReportBestPracticeChart
                  data={[
                    {
                      name: t('report.label.unusedResourceChart'),
                      cost: getCostByKey('MiscUnusedResource'),
                    },
                  ]}
                  colors={['#CECFDE']}
                  dataMax={graphMax}
                />
                <Box p={1} sx={{ backgroundColor: '#F0F0F780' }}>
                  <Typography variant="subtitle2" align="center">
                    Other
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </ChartCoverBox>
        </Paper>
        <ServiceDetails
          title="Amazon EC2"
          categories={[
            {
              category: t('report.label.utilization'),
              items: getItemByKey('EC2RightSizing'),
              savings: getCostByKey('EC2RightSizing'),
            },
            {
              category: t('report.label.coverage'),
              items: getItemByKey('EC2PricingPlan'),
              savings: getCostByKey('EC2PricingPlan'),
            },
            {
              category: t('report.label.generation'),
              items: getItemByKey('EC2Generation'),
              savings: getCostByKey('EC2Generation'),
            },
          ]}
        />
        <ServiceDetails
          title="Amazon RDS"
          categories={[
            {
              category: t('report.label.utilization'),
              items: getItemByKey('RDSRightSizing'),
              savings: getCostByKey('RDSRightSizing'),
            },
            {
              category: t('report.label.coverage'),
              items: getItemByKey('RDSPricingPlan'),
              savings: getCostByKey('RDSPricingPlan'),
            },
            {
              category: t('report.label.generation'),
              items: getItemByKey('RDSGeneration'),
              savings: getCostByKey('RDSGeneration'),
            },
            {
              category: t('report.label.storage'),
              items: getItemByKey('RDSStorage'),
              savings: getCostByKey('RDSStorage'),
            },
          ]}
        />
        <ServiceDetails
          title="Amazon EBS"
          categories={[
            {
              category: t('report.label.volumeType'),
              items: getItemByKey('EBSVolumeType'),
              savings: getCostByKey('EBSVolumeType'),
            },
            {
              category: t('report.label.unusedVolume'),
              items: getItemByKey('EBSUnusedVolume'),
              savings: getCostByKey('EBSUnusedVolume'),
            },
          ]}
        />
        <ServiceDetails
          title={t('report.label.otherServices')}
          categories={[
            {
              category: t('report.label.unusedResource'),
              items: getItemByKey('MiscUnusedResource'),
              savings: getCostByKey('MiscUnusedResource'),
            },
          ]}
        />
      </Box>
      <Box id="diagnostic_report_section_3" px={7} pt={6} mb={7}>
        <Paper square>
          <Box display="flex" alignItems="center" p={4}>
            <SvgIcon
              component={GrmtBulb}
              sx={{ width: '1.375rem', height: '1.375rem', mr: 2 }}
            />
            <Typography variant="subtitle2">{t('report.title.tip')}</Typography>
          </Box>
          <Divider />
          <TipBox
            title={t('report.label.utilization')}
            content={t('report.tips.utilization')}
          />
          <TipBox
            title={t('report.label.coverage')}
            content={t('report.tips.coverage')}
          />
          <TipBox
            title={t('report.label.generation')}
            content={t('report.tips.generation')}
          />
          <TipBox
            title={t('report.label.storage')}
            content={t('report.tips.storage')}
          />
        </Paper>
      </Box>
    </Box>
  );
};

export default DiagnosticReport;
