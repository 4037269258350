import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MainLayout } from '@layouts';
import { PageHeader } from '@components/common';
import { useCurrentInfo } from '@hooks/api/common';
import { SuspensePaper } from '@components/styled/suspense';
import {
  NotificationPaper,
  NotificationPermanentPaper,
} from '@pages/private/overview/Overview.styled';
import { organizationState } from '@atoms/notification';
import { ROUTES } from '@routes';
import { useAWSAccountHelper } from '@hooks/helper';
import {
  MonthlyBilling,
  MonthlyBillingDummy,
  OnboardInstruction,
  PossibleSavings,
  SavingsSummary,
} from './components';

const OverviewPage: React.FC = () => {
  const { t } = useTranslation('overview');
  const navigate = useNavigate();
  const {
    hasAWSAccount,
    curExists,
    isDiagnosticRunning,
    needMoreLinked,
    currentRole,
    showOverview,
    isTrial,
    isTrialExpired,
    trialExpiry,
    trialRemainingDays,
  } = useCurrentInfo();
  const { hasPayer } = useAWSAccountHelper();
  return (
    <MainLayout>
      <PageHeader
        title={showOverview ? 'Overview' : 'Onboarding'}
        needAccount={showOverview}
      />
      {showOverview ? (
        <>
          {currentRole < 3 &&
            ((hasPayer && needMoreLinked) || (isTrial && trialExpiry)) && (
              <Stack spacing={3} mb={6}>
                {isTrial && trialExpiry && (
                  <>
                    {isTrialExpired ? (
                      <NotificationPermanentPaper
                        label={t('label.notification.trialExpired.label')}
                        cta={t('label.notification.trialExpired.cta')}
                        onClick={() => navigate(ROUTES.SETTINGS.PAYMENT)}
                      />
                    ) : (
                      <NotificationPermanentPaper
                        label={t('label.notification.trial.label', {
                          count: trialRemainingDays,
                          date: trialExpiry,
                        })}
                        cta={t('label.notification.trial.cta')}
                        onClick={() => navigate(ROUTES.SETTINGS.PAYMENT)}
                      />
                    )}
                  </>
                )}
                {hasPayer && needMoreLinked && (
                  <NotificationPaper
                    label={t('label.notification.organization.label')}
                    cta={t('label.notification.organization.cta')}
                    onClick={() =>
                      navigate(ROUTES.SETTINGS.ACCOUNTS_ORGANIZATION)
                    }
                    atom={organizationState}
                  />
                )}
              </Stack>
            )}
          <SuspensePaper>
            <SavingsSummary />
          </SuspensePaper>
          {curExists ? (
            <>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                mt={6}
                mb={1}
              >
                {t('title.monthlyBilling')}
              </Typography>
              <SuspensePaper>
                <MonthlyBilling />
              </SuspensePaper>
            </>
          ) : (
            <>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                mt={6}
                mb={1}
              >
                {t('title.monthlyBilling')}
              </Typography>
              <Paper square>
                <MonthlyBillingDummy />
              </Paper>
            </>
          )}
          <Typography
            id="savings"
            variant="subtitle2"
            color="textSecondary"
            mt={6}
            mb={1}
          >
            {t('title.possibleSavings')}
          </Typography>
          <SuspensePaper>
            <PossibleSavings />
          </SuspensePaper>
        </>
      ) : (
        <SuspensePaper sx={{ px: 10, py: 8 }}>
          <OnboardInstruction
            hasAccount={hasAWSAccount}
            isRunning={isDiagnosticRunning}
          />
        </SuspensePaper>
      )}
    </MainLayout>
  );
};

export default OverviewPage;
