import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetUsage } from '@hooks/api/aws/ce';
import { ValueFormatter } from '@utils';

export default function DailyAverageCost() {
  const { t } = useTranslation('usage');
  const { data } = useGetUsage();
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary" align="center">
        {t('text.average_cost')}
      </Typography>
      <Typography variant="h3" color="tertiary" align="center" mt="8px">
        {data ? ValueFormatter.toCurrency(data.items[0].AverageCost) : '-'}
      </Typography>
    </>
  );
}
