import React from 'react';
import * as PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';
import { ReactComponent as Overview } from '@assets/icons/lnb/Overview.svg';
import { ReactComponent as Optimization } from '@assets/icons/lnb/Optimization.svg';
import { ReactComponent as AnomalyDetection } from '@assets/icons/lnb/AnomalyDetection.svg';
import { ReactComponent as SmartPlanner } from '@assets/icons/lnb/SmartPlanner.svg';
import { ReactComponent as Settings } from '@assets/icons/lnb/Settings.svg';
import { ReactComponent as Admin } from '@assets/icons/lnb/Admin.svg';
import { ReactComponent as Notification } from '@assets/icons/lnb/Notification.svg';
import { ReactComponent as Billing } from '@assets/icons/lnb/Billing.svg';

const NavIcons = {
  Overview,
  Optimization,
  AnomalyDetection,
  SmartPlanner,
  Settings,
  Admin,
  Notification,
  Billing,
} as const;
export type NavIconName = keyof typeof NavIcons;
const NavIcon: React.FC<{ name: NavIconName }> = ({ name }) => (
  <SvgIcon component={NavIcons[name]} viewBox="0 0 18 18" />
);
NavIcon.propTypes = {
  name: PropTypes.oneOf<NavIconName>([
    'Overview',
    'Optimization',
    'AnomalyDetection',
    'SmartPlanner',
    'Settings',
    'Admin',
    'Notification',
    'Billing',
  ]).isRequired,
};
export default React.memo(NavIcon);
