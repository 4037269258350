/* eslint-disable react/prop-types,react/destructuring-assignment */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MailIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  useLinkedOrganizations,
  useRequestLinkedEmail,
} from '@hooks/api/onboard';
import { useCurrentInfo } from '@hooks/api/common';
import { useSnackbar, useToggle } from '@hooks/common';
import { useAWSAccountHelper } from '@hooks/helper';
import { SimpleTable } from '@components/table';
import { HighlightedTypography } from '@components/styled';
import { QuestionTooltip } from '@components/styled/tooltip';
import { ROUTES } from '@routes';
import hierarchyImage from '@assets/images/aws_account_hierarchy.png';
import { OnboardEmailDialog } from '../../Onboard.styled';

const HierarchyImage = styled('img')`
  max-width: 100%;
`;

const LinkedAccountStep: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('onboard');
  const { t: commonT } = useTranslation('common');
  const { t: toastT } = useTranslation('toast');
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { getAccountIdByUid } = useAWSAccountHelper();
  const {
    currentCompany,
    currentUser,
    currentAWSAccounts,
    isDiagnosticRunning,
  } = useCurrentInfo();
  const payers = useLinkedOrganizations();
  const [openDialog, toggleDialog] = useToggle();
  const [emailSent, setEmailSent] = useState(false);
  const [selectedPayer, setSelectedPayer] = useState('');
  const [selectedInfo, setSelectedInfo] = useState<{
    AccountId: string;
    Name: string;
    Email: string;
  }>({ AccountId: '', Name: '', Email: '' });
  const { mutateAsync } = useRequestLinkedEmail();
  const { showSnackbar } = useSnackbar();
  useEffect(() => {
    if (isDiagnosticRunning) {
      navigate(ROUTES.ONBOARD.REPORT);
    }
  }, [isDiagnosticRunning]);
  const sendDialogConfirm = useCallback(
    ({ AccountId, Email, Name }, UID) => {
      setEmailSent(false);
      setSelectedPayer(UID);
      setSelectedInfo({ AccountId, Email, Name });
      toggleDialog();
    },
    [toggleDialog],
  );
  const fromAccount = useMemo(() => {
    if (currentAWSAccounts && selectedPayer?.length) {
      const acc = currentAWSAccounts.find((v) => v.uid === selectedPayer);
      if (acc) {
        return acc;
      }
    }
    return undefined;
  }, [selectedPayer, currentAWSAccounts]);
  const toAccount = useMemo(() => {
    if (payers?.length) {
      const account = payers
        .flatMap(({ data }) => data?.items)
        .find((v) => {
          if (v) {
            return v.Email === selectedInfo.Email;
          }
          return false;
        });
      if (account) {
        return account;
      }
    }
    return undefined;
  }, [payers, selectedInfo.Email]);
  const sendEmail = useCallback(async () => {
    if (
      currentCompany &&
      currentCompany.name &&
      currentCompany.id &&
      currentUser &&
      currentUser.email &&
      (currentUser.lastname || currentUser.fullname) &&
      selectedInfo &&
      fromAccount &&
      toAccount
    ) {
      try {
        await mutateAsync({
          destination: selectedInfo.Email,
          params: {
            senderEmail: currentUser.email,
            senderFirst: currentUser.lastname || currentUser.fullname || '',
            receiverName: selectedInfo.Name,
            companyName: currentCompany.name,
            fromAwsAccountId: fromAccount.id,
            toAccountId: toAccount.AccountId,
            hashSix: currentCompany.id.slice(0, 6),
          },
        });
        setEmailSent(true);
      } catch (e) {
        showSnackbar(toastT('error.onboard.email.sent'), 'error');
      }
    }
  }, [
    mutateAsync,
    currentCompany,
    currentUser,
    currentAWSAccounts,
    selectedInfo,
    fromAccount,
    toAccount,
  ]);
  return (
    <>
      <Grid container item spacing={4} direction={isSmall ? 'column' : 'row'}>
        <Grid item md={6} xs={12}>
          <HighlightedTypography
            variant="h3"
            dangerouslySetInnerHTML={{
              __html: t('header.payerAccount'),
            }}
          />
          <Typography
            variant="body2"
            sx={{ mt: 5 }}
            dangerouslySetInnerHTML={{
              __html: t('description.payerAccount', {
                URL: 'https://docs.aws.amazon.com/cur/latest/userguide/what-is-cur.html',
                GUIDE_URL: '/docs/getting-started',
              }),
            }}
          />
        </Grid>
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          display={{ md: 'flex', xs: 'none' }}
          md={6}
          xs={12}
        >
          <Grid item>
            <HierarchyImage src={hierarchyImage} alt="Hierarchy" />
          </Grid>
          <Grid item mt={3}>
            <Typography variant="caption" color="textSecondary">
              {t('description.accountDiagram')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {Boolean(payers?.length) && (
        <Box mt={5}>
          <Stack spacing={10}>
            {payers.map(({ isLoading, isSuccess, data }, idx) => {
              if (isLoading) {
                return (
                  <Box
                    key={`linked_loader_${idx.toString()}`}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress size={64} />
                  </Box>
                );
              }
              if (!isSuccess) {
                return null;
              }
              return (
                <Box key={data.UID}>
                  {Boolean(data?.UID) && (
                    <Typography variant="body2" mb={2}>
                      {t('label.payerInfo', {
                        ACCOUNT_ID: getAccountIdByUid(data.UID),
                      })}
                    </Typography>
                  )}
                  <Paper square>
                    <SimpleTable
                      data={_.filter(data?.items, (v) => !v.IsMaster)}
                      maxHeight="19.01125rem"
                      columnInfo={[
                        {
                          Header: t('label.type'),
                          accessor: 'IsMaster' as const,
                          Cell: ({ value }) =>
                            value ? t('label.management') : t('label.member'),
                        },
                        {
                          Header: t('label.accountId'),
                          accessor: 'AccountId' as const,
                        },
                        {
                          Header: t('label.status'),
                          accessor: 'Status' as const,
                        },
                        {
                          Header: t('label.owner'),
                          accessor: 'Name' as const,
                        },
                        {
                          Header: t('label.email'),
                          accessor: 'Email' as const,
                        },
                        {
                          Header: () => (
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography
                                component="span"
                                variant="inherit"
                                color="secondary"
                                mr={1}
                              >
                                {t('label.notify')}
                              </Typography>
                              <QuestionTooltip
                                title={t('description.linkedTooltip')}
                                placement="top-end"
                              />
                            </Box>
                          ),
                          accessor: ' ' as const,
                          align: 'center',
                          backgroundColor: '#fef2fa',
                          Cell: ({ row }) => {
                            if (
                              row.original.IsMaster ||
                              row.original.Connected
                            ) {
                              return <MailIcon color="disabled" />;
                            }
                            return (
                              <IconButton
                                color="inherit"
                                onClick={() =>
                                  sendDialogConfirm(row.original, data.UID)
                                }
                                sx={{ p: 0 }}
                              >
                                <MailIcon />
                              </IconButton>
                            );
                          },
                        },
                        {
                          Header: t('label.connected'),
                          accessor: 'Connected' as const,
                          align: 'center',
                          Cell: ({ value }) => {
                            if (value) {
                              return <CheckCircleIcon color="secondary" />;
                            }
                            return <CheckCircleIcon color="disabled" />;
                          },
                        },
                      ]}
                    />
                  </Paper>
                </Box>
              );
            })}
          </Stack>
        </Box>
      )}
      {openDialog && (
        <OnboardEmailDialog open={openDialog} onClose={toggleDialog}>
          <DialogTitle>
            {emailSent ? t('modal.title.sent') : t('modal.title.confirm')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              dangerouslySetInnerHTML={{
                __html: emailSent
                  ? t('modal.description.sent', selectedInfo)
                  : t('modal.description.confirm', selectedInfo),
              }}
            />
          </DialogContent>
          <DialogActions>
            {emailSent ? (
              <Button size="large" onClick={toggleDialog}>
                {commonT('button.okay')}
              </Button>
            ) : (
              <>
                <Button color="inherit" size="large" onClick={toggleDialog}>
                  {commonT('button.cancel')}
                </Button>
                <Button
                  size="large"
                  onClick={() => {
                    navigate(ROUTES.ONBOARD.CONNECT);
                    toggleDialog();
                  }}
                >
                  {t('modal.button.connect')}
                </Button>
                <Button size="large" onClick={sendEmail}>
                  {t('modal.button.send')}
                </Button>
              </>
            )}
          </DialogActions>
        </OnboardEmailDialog>
      )}
    </>
  );
};

export default React.memo(LinkedAccountStep);
