import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  useEC2Information,
  useEC2UtilizationChart,
} from '@hooks/api/optimization/ec2';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import {
  DetailLinkIcon,
  TableUtilizationRecommendation,
} from '@components/table/table.styled';
import { ROUTES } from '@routes';
import { ValueFormatter } from '@utils';
import { useAWSHelper } from '@hooks/helper';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ec2CPUState,
  ec2MemState,
  recommendCategoryState,
  recommendPeriodState,
} from '@atoms/optimization';
import { apmSourceState } from '@atoms/global';
import ComputeUtilFilter from './ComputeUtilFilter';

const ComputeUtilTable: React.FC = () => {
  const { t } = useTranslation('optimization');
  const apmSource = useRecoilValue(apmSourceState);
  const period = useRecoilValue(recommendPeriodState);
  const category = useRecoilValue(recommendCategoryState);
  const { getOptimizationAWSRegion } = useAWSHelper();
  const { data: chartData } = useEC2UtilizationChart();
  const { data, onPageChange } = useEC2Information();

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        minWidth: 140,
        maxWidth: 140,
        disableSortBy: true,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.common.instanceId'),
        accessor: 'InstanceId' as const,
        minWidth: 180,
        maxWidth: 180,
        disableSortBy: true,
      },
      {
        Header: t('label.common.instanceType'),
        accessor: 'CurrentType' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.common.tag'),
        accessor: 'Tag' as const,
        minWidth: 192,
        maxWidth: 192,
        disableSortBy: true,
        Cell: ({ value }) => (
          <>
            {value?.length
              ? Object.keys(value[0]).map((v) => {
                  const key = v.split(':');
                  return (
                    <React.Fragment key={v}>
                      {`${key[key.length - 1]}: ${value[0][v]}`}
                    </React.Fragment>
                  );
                })
              : '-'}
          </>
        ),
      },
      {
        Header: t('label.util.cpu_max'),
        accessor: 'CPUMax' as const,
        minWidth: 128,
        maxWidth: 128,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toPercent(value) || '-',
      },
      {
        Header: t('label.util.mem_max'),
        accessor: 'MemMax' as const,
        minWidth: 128,
        maxWidth: 128,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toPercent(value) || '-',
      },
      {
        Header: t('label.common.savings'),
        accessor: 'MonthlySaving' as const,
        align: 'right',
        Cell: ({ value, row }) => {
          if (
            value &&
            _.has(
              row.original,
              `RecommendType.${period}.${apmSource}.${category}.RecommendType`,
            )
          ) {
            return (
              <>
                {ValueFormatter.toCurrency(value)}
                <br />(
                {_.get(
                  row.original,
                  `RecommendType.${period}.${apmSource}.${category}.RecommendType`,
                )}
                )
              </>
            );
          }
          return '-';
        },
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailLinkIcon
            to={`${ROUTES.DETAILS.COMPUTE}/${row.original.InstanceId}?tab=utilization`}
            requireCUR
          />
        ),
      },
    ],
    [getOptimizationAWSRegion, period, apmSource, category],
  );
  const setEC2CPU = useSetRecoilState(ec2CPUState);
  const setEC2Mem = useSetRecoilState(ec2MemState);
  useEffect(
    () => () => {
      setEC2CPU(undefined);
      setEC2Mem(undefined);
    },
    [],
  );
  return (
    <DataPaginationTable
      data={data?.body?.items}
      columnInfo={columnInfo}
      totalCount={data?.body?.totalCount}
      onPageChange={onPageChange}
      TitleElement={<TableUtilizationRecommendation />}
      FilterElement={ComputeUtilFilter}
      LastUpdate={chartData.extras.LatestUpdate}
      CSVUrl="ec2"
      enableSort
      enableFilter
    />
  );
};

export default ComputeUtilTable;
