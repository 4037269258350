import React, { useMemo } from 'react';
import { Paper, Typography, Stack, Box, Grid } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';
import {
  useRDSDetailsGeneral,
  useRDSDetailsStorage,
} from '@hooks/api/optimization/rds';
import { StorageChart } from '@components/chart/optimization';
import { bluegrey } from '@theme/colors';
import { RecommendActionDivider, SingleTypeTable } from '@components/styled';
import { byteToGib, ValueFormatter } from '@utils';
import { useTranslation } from 'react-i18next';
import { IntelligenceCard } from '@components/styled/notification';
import { ChartCoverBox, NoAvailableData } from '@components/styled/noData';
import { STORAGE_DUMMY } from '@components/chart/chart.dummy';

const DetailsDatabaseStoragePage: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');
  const { data } = useRDSDetailsStorage();
  const { data: general } = useRDSDetailsGeneral();
  const region = useMemo<string | undefined>(() => {
    if (general?.items?.length) {
      return general.items[0].Region;
    }
    return undefined;
  }, [general?.items]);
  if (!data?.items?.length) {
    return <NoAvailableData />;
  }
  const { Summary, UsageDetails, RecommendActions } = data.items[0];
  if (_.isEmpty(Summary) && _.isEmpty(UsageDetails)) {
    return <NoAvailableData />;
  }
  return (
    <>
      <Typography color="textSecondary" mb={2}>
        {t('label.common.summary')}
      </Typography>
      <ChartCoverBox isEmpty={_.isEmpty(Summary)}>
        <StorageChart
          data={!_.isEmpty(Summary) ? Summary : STORAGE_DUMMY}
          xAxis={{
            dataKey: 'Date',
            interval: 0,
            tickFormatter: (v) => moment(v).format('YYYY MMM'),
          }}
          chartInfo={{
            line: {
              dataKey: 'GeneralPurpose',
              name: t('label.volume.general'),
            },
            area: {
              dataKey: 'ProvisionedIOPS',
              name: t('label.volume.provisioned'),
            },
          }}
        />
      </ChartCoverBox>
      <Stack direction="row" spacing={2} alignItems="center" mt={7}>
        <Typography>
          {t('label.common.storage_details')} (
          {commonT('label.lastDays', { count: 30 })})
        </Typography>
        <Typography
          p={1}
          sx={{ bgcolor: bluegrey[100], borderRadius: '1.5rem' }}
        >
          {t('label.common.storage_type')}: {UsageDetails.VolumeType}
        </Typography>
        <Typography
          p={1}
          sx={{ bgcolor: bluegrey[100], borderRadius: '1.5rem' }}
        >
          {t('label.common.cost_storage')}:{' '}
          {ValueFormatter.toCurrency(UsageDetails.Cost)}
        </Typography>
      </Stack>
      <Grid container spacing={3} mt={2}>
        <Grid item md={6} xs={12}>
          <Typography color="textSecondary" mb={2}>
            {t('label.common.storage')}
          </Typography>
          <Paper>
            <SingleTypeTable
              data={[
                {
                  label: `${t('label.volume.allocatedStorage')} (GiB)`,
                  value: ValueFormatter.toLocaleString(
                    byteToGib(UsageDetails.AllocateStorage),
                  ),
                },
                {
                  label: `${t('label.util.max_long')} (GiB)`,
                  value: ValueFormatter.toLocaleString(
                    UsageDetails.MaximumUtilization,
                  ),
                },
                {
                  label: `${t('label.util.avg_long')} (GiB)`,
                  value: ValueFormatter.toLocaleString(
                    UsageDetails.AverageUtilization,
                  ),
                },
              ]}
            />
          </Paper>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography color="textSecondary" mb={2}>
            IOPS
          </Typography>
          <Paper>
            <SingleTypeTable
              data={[
                {
                  label: t('label.volume.iops_provisioned'),
                  value: ValueFormatter.toLocaleString(
                    UsageDetails.ProvisionedIOPS,
                    0,
                  ),
                },
                {
                  label: t('label.volume.iops_max'),
                  value: ValueFormatter.toLocaleString(
                    UsageDetails.MaximumIOPS,
                    0,
                  ),
                },
                {
                  label: t('label.volume.iops_avg'),
                  value: ValueFormatter.toLocaleString(
                    UsageDetails.AverageIOPS,
                    0,
                  ),
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
      {Boolean(region) && Boolean(RecommendActions?.length) && (
        <Box mt={8}>
          <RecommendActionDivider />
          <Stack spacing={3} mt={3}>
            {RecommendActions.map((item) => (
              <IntelligenceCard
                key={item.UID}
                item={item}
                region={region}
                showSavings
              />
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default DetailsDatabaseStoragePage;
