import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRDSUtilization } from '@hooks/api/optimization/rds';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { ValueFormatter } from '@utils';
import { useAWSHelper } from '@hooks/helper';
import DatabaseUtilFilter from './DatabaseUtilFilter';

const DatabaseUtilTable: React.FC = () => {
  const { data, onPageChange, pageSize } = useRDSUtilization();
  const { t } = useTranslation('optimization');
  const { getOptimizationAWSRegion } = useAWSHelper();

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        minWidth: 140,
        maxWidth: 140,
        disableSortBy: true,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.db.databaseType'),
        accessor: 'InstanceType' as const,
        minWidth: 150,
        maxWidth: 150,
        disableSortBy: true,
      },
      {
        Header: t('label.common.ri', { context: 'number' }),
        accessor: 'PurchasedHours' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.header.utilization'),
        accessor: 'Utilization' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toPercent(value) || '-',
      },
      {
        Header: t('label.common.expiresOn'),
        accessor: 'ExpiredOn' as const,
        minWidth: 120,
        maxWidth: 120,
        Cell: ({ value }) => ValueFormatter.toDateString(value, 'YYYY-MM-DD'),
      },
      // {
      //   Header: t('label.common.details'),
      //   accessor: () => null,
      //   align: 'center',
      //   disableSortBy: true,
      //   Cell: ({ row }) => (
      //     <DetailLinkIcon
      //       to={`${ROUTES.DETAILS.DATABASE}/${row.original.InstanceId}?tab=purchasing`}
      //     />
      //   ),
      // },
    ],
    [getOptimizationAWSRegion],
  );

  return (
    <DataPaginationTable
      columnInfo={columnInfo}
      data={data?.body?.items}
      totalCount={data?.body?.totalCount}
      onPageChange={onPageChange}
      pageSize={pageSize}
      FilterElement={DatabaseUtilFilter}
      CSVUrl="rdsri/utilization"
      enableFilter
      enableSort
      LastUpdate={data.body.extras.LatestUpdate}
    />
  );
};

export default DatabaseUtilTable;
