/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { Button, Grid, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as CloudWatchLogo } from '@assets/images/cloudwatch_logo.svg';
import { useTranslation } from 'react-i18next';
import { LightTooltip } from '@components/styled/tooltip';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useToggle } from '@hooks/common';

const windowsScript = {
  metrics: {
    aggregation_dimensions: [['InstanceId']],
    append_dimensions: {
      AutoScalingGroupName: '${aws:AutoScalingGroupName}',
      ImageId: '${aws:ImageId}',
      InstanceId: '${aws:InstanceId}',
      InstanceType: '${aws:InstanceType}',
    },
    metrics_collected: {
      LogicalDisk: {
        measurement: ['% Free Space'],
        metrics_collection_interval: 60,
        resources: ['*'],
      },
      Memory: {
        measurement: ['% Committed Bytes In Use'],
        metrics_collection_interval: 60,
      },
      statsd: {
        metrics_aggregation_interval: 60,
        metrics_collection_interval: 10,
        service_address: ':8125',
      },
    },
  },
};

const linuxScript = {
  agent: {
    metrics_collection_interval: 60,
    run_as_user: 'root',
  },
  metrics: {
    append_dimensions: {
      InstanceId: '${aws:InstanceId}',
      InstanceType: '${aws:InstanceType}',
    },
    aggregation_dimensions: [['InstanceId', 'InstanceType'], ['InstanceId']],
    metrics_collected: {
      disk: {
        measurement: ['used_percent'],
        metrics_collection_interval: 60,
        resources: ['*'],
      },
      mem: {
        measurement: ['mem_used_percent', 'mem_free'],
        metrics_collection_interval: 60,
      },
      statsd: {
        metrics_aggregation_interval: 60,
        metrics_collection_interval: 10,
        service_address: ':8125',
      },
    },
  },
};

const CloudWatchGuide: React.FC = () => {
  const { t } = useTranslation('settings');

  const [openWindowsButton, setWindowsButton] = useToggle();
  const [openLinuxButton, setLinuxButton] = useToggle();

  return (
    <Grid
      container
      direction={{ lg: 'row', xs: 'column' }}
      justifyContent="space-between"
      rowSpacing={3}
    >
      <Grid
        container
        item
        direction={{ lg: 'column', xs: 'row' }}
        justifyContent={{ lg: 'center', xs: 'flex-start' }}
        alignItems="center"
        rowSpacing={2}
        columnSpacing={2}
        lg={1.5}
        xs={12}
      >
        <Grid item>
          <SvgIcon
            component={CloudWatchLogo}
            sx={{ width: '62px', height: '62px' }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h3" align="center">
            CloudWatch Agent
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        lg={5}
        xs={12}
      >
        <Typography variant="body2" color="textSecondary">
          {t('integration.label.cwAgentDesc')}
        </Typography>
      </Grid>
      <Grid
        container
        item
        direction={{ lg: 'column-reverse', xs: 'row' }}
        justifyContent={{ lg: 'center', xs: 'flex-end' }}
        rowSpacing={3}
        columnSpacing={2}
        lg={1.3}
        xs={12}
      >
        <Grid item>
          <CopyToClipboard
            onCopy={(text, result) => {
              if (result) {
                setWindowsButton();
                setTimeout(() => {
                  setWindowsButton();
                }, 2000);
              }
            }}
            text={JSON.stringify(windowsScript)}
          >
            <LightTooltip
              open={openWindowsButton}
              title={t('integration.label.configCopied')}
              placement="right"
              PopperProps={{ disablePortal: true }}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Button type="button" variant="outlined" size="small" fullWidth>
                {t('integration.button.copyWindowsConfig')}
              </Button>
            </LightTooltip>
          </CopyToClipboard>
        </Grid>
        <Grid item>
          <CopyToClipboard
            onCopy={(text, result) => {
              if (result) {
                setLinuxButton();
                setTimeout(() => {
                  setLinuxButton();
                }, 2000);
              }
            }}
            text={JSON.stringify(linuxScript)}
          >
            <LightTooltip
              open={openLinuxButton}
              title={t('integration.label.configCopied')}
              placement="right"
              PopperProps={{ disablePortal: true }}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Button type="button" variant="outlined" size="small" fullWidth>
                {t('integration.button.copyLinuxConfig')}
              </Button>
            </LightTooltip>
          </CopyToClipboard>
        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="outlined"
            size="small"
            component="a"
            target="_blank"
            href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/install-CloudWatch-Agent-on-EC2-Instance.html"
            fullWidth
          >
            {t('integration.button.goToGuide')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(CloudWatchGuide);
