import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRDSDetailsGeneral } from '@hooks/api/optimization/rds';
import { useAWSHelper } from '@hooks/helper';
import { SimpleTypoTable } from '@components/styled';
import { ValueFormatter } from '@utils';
import { QuestionTooltip } from '@components/styled/tooltip';
import _ from 'lodash';

const DetailsDatabaseGeneral: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');
  const { getAWSRegionName } = useAWSHelper();
  const { data } = useRDSDetailsGeneral();
  const multiAZ = useMemo<string>(() => {
    const value: string | undefined = _.get(data, 'items.0.MultiAZ');
    if (typeof value === 'string') {
      return value.toLowerCase().includes('multi')
        ? commonT('label.yes')
        : commonT('label.no');
    }
    return '-';
  }, [data?.items, commonT]);
  const generalInfo = useMemo(() => {
    if (data?.items?.length && !_.isEmpty(data.items[0])) {
      return [
        {
          label: t('label.common.accountId'),
          value: _.get(data, 'items.0.AccountId') || '-',
        },
        {
          label: t('label.db.clusterId'),
          value: _.get(data, 'items.0.ClusterId') || '-',
        },
        {
          label: t('label.db.databaseId'),
          value: _.get(data, 'items.0.InstanceId') || '-',
        },
        {
          label: t('label.common.region'),
          value: getAWSRegionName(_.get(data, 'items.0.Region') || '-'),
        },
        {
          label: t('label.db.engineType'),
          value: _.get(data, 'items.0.DatabaseEngine') || '-',
        },
        {
          label: t('label.common.instanceType'),
          value: _.get(data, 'items.0.InstanceType') || '-',
        },
        {
          label: t('label.db.multiAZ'),
          value: multiAZ,
        },
        {
          label: (
            <Stack direction="row" alignItems="center" spacing={1}>
              <span>
                {t('label.common.cost')} (
                {commonT('label.days', {
                  count: 30,
                })}
                )
              </span>
              <QuestionTooltip
                title={t('label.tooltip.rdsCost')}
                color="bluegrey"
                size="0.875rem"
              />
            </Stack>
          ),
          value: ValueFormatter.toCurrency(
            _.get(data, 'items.0.MonthlyCost') || 0,
          ),
        },
      ];
    }
    return [];
  }, [data?.items, getAWSRegionName, multiAZ]);
  if (!data?.items?.length) {
    return null;
  }
  return <SimpleTypoTable data={generalInfo} disableOutline />;
};

export default DetailsDatabaseGeneral;
