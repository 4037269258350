import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DotLegendItem, DotLegendWrapper } from '@components/styled';
import {
  ChartDefaultGradient,
  GrmtAxisDefaultProps,
  GrmtDefaultChartProps,
  GrmtGridDefaultProps,
  GrmtTooltipTextItem,
  GrmtTooltipWrapper,
} from '@components/chart';
import { primary, secondary } from '@theme/colors';
import { ValueFormatter } from '@utils';
import { Box } from '@mui/material';

type InstanceUtilChartProps = GrmtDefaultChartProps;

const DailyInstanceUtilChart: React.FC<InstanceUtilChartProps> = ({ data }) => {
  const { t } = useTranslation('optimization');
  return (
    <>
      <DotLegendWrapper>
        <DotLegendItem
          color={primary[600]}
          label={`${t('label.util.cpu_short')} (%)`}
        />
        <DotLegendItem
          color={secondary[500]}
          label={`${t('label.util.memory_short')} (%)`}
        />
      </DotLegendWrapper>
      <ResponsiveContainer width="100%" aspect={2}>
        <LineChart
          data={data}
          margin={{ top: 8, bottom: 24, left: 24, right: 24 }}
        >
          {ChartDefaultGradient}
          <XAxis
            dataKey="Timestamps"
            interval={5}
            tickFormatter={(v) => moment(v).format('HH:mm:ss')}
            angle={-45}
            textAnchor="end"
            {...GrmtAxisDefaultProps}
            height={72}
            label={{
              value: `${t('label.util.timestamp')} (UTC)`,
              position: 'insideBottom',
              offset: -16,
              stroke: 'none',
              fill: '#A0A1B2',
              fontSize: '12px',
              fontWeight: 400,
            }}
          />
          <YAxis
            scale="linear"
            domain={[0, 100]}
            {...GrmtAxisDefaultProps}
            label={{
              value: `${t('label.header.utilization')} (%)`,
              position: 'insideLeft',
              angle: -90,
              offset: -8,
              stroke: 'none',
              fill: '#A0A1B2',
              fontSize: '12px',
              fontWeight: 400,
            }}
          />
          <CartesianGrid {...GrmtGridDefaultProps} />
          <Tooltip
            cursor={false}
            content={({ active, payload, label }) => {
              if (payload?.length && payload[0].payload) {
                const { CPU, Mem } = payload[0].payload;
                return (
                  <Box px={2}>
                    <GrmtTooltipWrapper
                      active={active}
                      payload={payload}
                      label={moment(label).format('HH:mm:ss')}
                      width={200}
                    >
                      <GrmtTooltipTextItem
                        name={`${t('label.util.cpu')}: `}
                        value={CPU ? ValueFormatter.toPercent(CPU) : '-'}
                      />
                      <GrmtTooltipTextItem
                        name={`${t('label.util.memory')}: `}
                        value={Mem ? ValueFormatter.toPercent(Mem) : '-'}
                      />
                    </GrmtTooltipWrapper>
                  </Box>
                );
              }
              return null;
            }}
          />
          <Line
            dataKey="CPU"
            type="monotone"
            fill={primary[600]}
            stroke={primary[600]}
            strokeWidth={2}
            r={5}
            activeDot={{
              r: 5,
            }}
            dot={false}
            connectNulls
          />
          <Line
            dataKey="Mem"
            type="monotone"
            fill={secondary[500]}
            stroke={secondary[500]}
            strokeWidth={2}
            activeDot={{
              r: 5,
            }}
            dot={false}
            connectNulls
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default React.memo(DailyInstanceUtilChart);
