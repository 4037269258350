import React, { useCallback, useState } from 'react';
import { experimental_sx, styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiAccordion, {
  AccordionProps as MuiAccordionProps,
} from '@mui/material/Accordion';
import MuiAccordionSummary, {
  accordionSummaryClasses,
  AccordionSummaryProps as MuiAccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails, {
  AccordionDetailsProps as MuiAccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import { ValueFormatter } from '@utils';

export const AccordionTable = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

type AccordionTableItemProps = BoxProps & {
  header?: boolean;
  underline?: boolean;
  summary?: boolean;
  dummyPadding?: boolean;
};
export const AccordionTableItem = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'header' &&
    prop !== 'underline' &&
    prop !== 'summary' &&
    prop !== 'dummyPadding',
})<AccordionTableItemProps>(
  ({ theme, header, underline, summary, dummyPadding }) => ({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
    ...theme.typography.body2,
    ...(header && {
      color: theme.palette.text.secondary,
      paddingTop: '14px',
      paddingBottom: '14px',
    }),
    ...(underline && {
      borderBottom: '1px solid #f0f0f7',
    }),
    ...(summary && {
      color: '#7800ef',
      backgroundColor: '#cca8f01a',
      paddingLeft: '104px',
      paddingTop: '14px',
      paddingBottom: '14px',
    }),
    ...(dummyPadding && {
      paddingLeft: '56px',
    }),
  }),
);

export const AccordionDummyColumn = styled(Box)({
  flex: '0 0 48px',
  paddingLeft: '8px',
  paddingRight: '8px',
});

export const AccordionIndexColumn = styled(Box)({
  flex: '0 0 40px',
  paddingLeft: '8px',
  paddingRight: '8px',
});

export const AccordionItemColumn = styled(Box)({
  flex: '1 1 auto',
  paddingLeft: '8px',
  paddingRight: '8px',
});

export const AccordionValueColumn = styled(Box)({
  flex: '0 0 240px',
  textAlign: 'right',
  paddingLeft: '8px',
  paddingRight: '8px',
});

export const AccordionUnitColumn = styled(Box)({
  flex: '0 0 80px',
  paddingLeft: '8px',
  paddingRight: '8px',
});

export const AccordionCountColumn = styled(Box)({
  flex: '0 0 96px',
  textAlign: 'right',
  paddingLeft: '8px',
  paddingRight: '8px',
});

export const AccordionAmountColumn = styled(Box)({
  flex: '0 0 144px',
  textAlign: 'right',
  paddingLeft: '8px',
  paddingRight: '8px',
});

export const AccordionActionColumn = styled(Box)({
  flex: '0 0 111px',
  textAlign: 'center',
  paddingLeft: '8px',
  paddingRight: '8px',
});

type InvoiceAccordionWrapperProps = Omit<
  MuiAccordionProps,
  'elevation' | 'disableGutters'
>;
const InvoiceAccordionWrapper = styled(
  (props: InvoiceAccordionWrapperProps) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
  ),
)<InvoiceAccordionWrapperProps>(({ expanded }) =>
  experimental_sx({
    '&::before': {
      height: 0,
    },
    border: 'none',
    ...(expanded && {
      border: '.5px solid #cecfde',
      borderRadius: '8px',
      boxShadow: '0px 0px 3px 1px #cecfde40',
    }),
  }),
);

type InvoiceAccordionSummaryProps = Omit<
  MuiAccordionSummaryProps,
  'expandIcon'
> & {
  divider?: boolean;
};
const InvoiceAccordionSummary = styled(
  ({ divider, ...props }: InvoiceAccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={
        <ExpandMoreIcon
          sx={{ width: '24px', height: '24px', color: 'text.primary' }}
        />
      }
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'divider',
  },
)<InvoiceAccordionSummaryProps>(({ divider }) =>
  experimental_sx({
    flexDirection: 'row-reverse',
    gap: '40px',
    p: 0,
    mx: '24px',
    px: '16px',
    py: '16px',
    [`> .${accordionSummaryClasses.content}`]: {
      justifyContent: 'space-between',
      gap: '170px',
    },
    ...(divider && {
      [`&.${accordionSummaryClasses.expanded}`]: {
        borderBottom: '1px solid #f0f0f7',
      },
    }),
  }),
);

type InvoiceAccordionDetailsProps = MuiAccordionDetailsProps;
const InvoiceAccordionDetails = styled(
  ({ ...props }: InvoiceAccordionDetailsProps) => (
    <MuiAccordionDetails {...props} />
  ),
)<InvoiceAccordionDetailsProps>(() =>
  experimental_sx({
    p: 0,
    px: '24px',
    py: '16px',
  }),
);

type InvoiceAccordionProps = {
  index?: number;
  accountId?: string;
  totalCost?: number;
  divider?: boolean;
  currency?: 'USD' | 'KRW';
};
export function InvoiceAccordion({
  index,
  accountId,
  totalCost,
  divider,
  currency,
  children,
}: React.PropsWithChildren<InvoiceAccordionProps>) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = useCallback(
    (e: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded);
    },
    [],
  );
  return (
    <InvoiceAccordionWrapper expanded={expanded} onChange={handleChange}>
      <InvoiceAccordionSummary divider={divider}>
        <Box sx={{ display: 'inline-flex', gap: '40px' }}>
          {_.isNumber(index) && (
            <Typography variant="subtitle2">
              {ValueFormatter.toIndex(Number(index) + 1, 2)}
            </Typography>
          )}
          <Typography variant="subtitle2">{accountId}</Typography>
        </Box>
        <Typography variant="subtitle2">
          {currency === 'KRW'
            ? ValueFormatter.toCurrency(totalCost ?? 0, 'KRW', 0)
            : ValueFormatter.toCurrency(totalCost ?? 0)}
        </Typography>
      </InvoiceAccordionSummary>
      <InvoiceAccordionDetails>{children}</InvoiceAccordionDetails>
    </InvoiceAccordionWrapper>
  );
}
InvoiceAccordion.defaultProps = {
  index: undefined,
  accountId: undefined,
  totalCost: undefined,
  divider: false,
  currency: 'USD',
};

type ServiceAccordionItemProps = {
  item: string;
  amount?: number;
  value?: number;
  indent?: 1 | 2 | 3;
};
export function ServiceAccordionItem({
  item,
  amount,
  value,
  indent,
}: ServiceAccordionItemProps) {
  return (
    <AccordionTableItem
      sx={(theme) => ({
        backgroundColor: '#f0f0f733',
        borderBottom: '1px dashed #f0f0f7',
        pl: '104px',
        ...(indent === 2 && {
          pl: '128px',
          '&:hover': {
            backgroundColor: '#5c7bcc0d',
          },
        }),
        ...(indent === 3 && {
          pl: '168px',
          color: theme.palette.text.secondary,
          '&:hover': {
            backgroundColor: '#5c7bcc0d',
          },
        }),
        ...(indent === 1 && {
          backgroundColor: '#ffffff',
          borderBottom: '1px solid #f0f0f7',
        }),
      })}
    >
      <AccordionItemColumn>{item}</AccordionItemColumn>
      <AccordionValueColumn>
        {ValueFormatter.toLocaleString(amount)}
      </AccordionValueColumn>
      <AccordionValueColumn>
        {ValueFormatter.toCurrency(value)}
      </AccordionValueColumn>
    </AccordionTableItem>
  );
}
ServiceAccordionItem.defaultProps = {
  amount: undefined,
  value: undefined,
  indent: 1,
};

type ServiceAccordionWrapperProps = Omit<
  MuiAccordionProps,
  'elevation' | 'disableGutters'
>;
const ServiceAccordionWrapper = styled(
  (props: ServiceAccordionWrapperProps) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
  ),
)<ServiceAccordionWrapperProps>(() =>
  experimental_sx({
    // mr: '24px',
    border: 'none',
    '&::before': {
      height: 0,
    },
  }),
);
type ServiceAccordionSummaryProps = Omit<
  MuiAccordionSummaryProps,
  'expandIcon'
>;
const ServiceAccordionSummary = styled(
  ({ ...props }: ServiceAccordionSummaryProps) => (
    <MuiAccordionSummary
      {...props}
      expandIcon={
        <ExpandMoreIcon
          sx={{ width: '16px', height: '16px', color: 'inherit' }}
        />
      }
    />
  ),
)<ServiceAccordionSummaryProps>(({ theme }) =>
  experimental_sx({
    flexDirection: 'row-reverse',
    gap: '8px',
    p: 0,
    m: 0,
    border: 'none',
    borderBottom: '1px solid #f0f0f7',
    [`> .${accordionSummaryClasses.content}`]: {
      ...theme.typography.body2,
      gap: '8px',
      m: 0,
      py: '14px',
    },
    [`> .${accordionSummaryClasses.expandIconWrapper}`]: {
      p: '16px',
    },
    [`&.${accordionSummaryClasses.expanded}`]: {
      color: '#7800ef',
    },
  }),
);
type ServiceAccordionDetailsProps = MuiAccordionDetailsProps;
const ServiceAccordionDetails = styled(
  ({ ...props }: ServiceAccordionDetailsProps) => (
    <MuiAccordionDetails {...props} />
  ),
)<ServiceAccordionDetailsProps>(() =>
  experimental_sx({
    p: 0,
  }),
);
type ServiceAccordionProps = {
  name: string;
  index: number;
  cost?: any;
};
export function ServiceAccordion({
  name,
  index,
  cost,
  children,
}: React.PropsWithChildren<ServiceAccordionProps>) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = useCallback(
    (e: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded);
    },
    [],
  );
  return (
    <ServiceAccordionWrapper expanded={expanded} onChange={handleChange}>
      <ServiceAccordionSummary>
        <AccordionIndexColumn>{index + 1}</AccordionIndexColumn>
        <AccordionItemColumn>{name}</AccordionItemColumn>
        <AccordionValueColumn>
          {ValueFormatter.toCurrency(cost)}
        </AccordionValueColumn>
      </ServiceAccordionSummary>
      <ServiceAccordionDetails>{children}</ServiceAccordionDetails>
    </ServiceAccordionWrapper>
  );
}
ServiceAccordion.defaultProps = {
  cost: undefined,
};

type AdditionalServiceAccordionWrapperProps = Omit<
  MuiAccordionProps,
  'elevation' | 'disableGutters'
>;
const AdditionalServiceAccordionWrapper = styled(
  (props: AdditionalServiceAccordionWrapperProps) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
  ),
)<AdditionalServiceAccordionWrapperProps>(() =>
  experimental_sx({
    border: '.5px solid #cecfde',
    borderRadius: '8px',
    boxShadow: '0px 0px 3px 1px #cecfde40',
  }),
);

type AdditionalServiceAccordionSummaryProps = Omit<
  MuiAccordionSummaryProps,
  'expandIcon'
>;
const AdditionalServiceAccordionSummary = styled(
  (props: AdditionalServiceAccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={
        <ExpandMoreIcon
          sx={{ width: '16px', height: '16px', color: 'text.secondary' }}
        />
      }
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'divider',
  },
)<AdditionalServiceAccordionSummaryProps>(() =>
  experimental_sx({
    flexDirection: 'row-reverse',
    gap: '26px',
    mx: '16px',
    p: 0,
    pl: '12px',
    pr: '8px',
    py: '8px',
    [`> .${accordionSummaryClasses.content}`]: {
      justifyContent: 'space-between',
      gap: '170px',
    },
    [`&.${accordionSummaryClasses.expanded}`]: {
      borderBottom: '1px solid #f0f0f7',
    },
  }),
);

type AdditionalServiceAccordionDetailsProps = MuiAccordionDetailsProps;
const AdditionalServiceAccordionDetails = styled(
  ({ ...props }: AdditionalServiceAccordionDetailsProps) => (
    <MuiAccordionDetails {...props} />
  ),
)<AdditionalServiceAccordionDetailsProps>(() =>
  experimental_sx({
    p: 0,
  }),
);

type AdditionalServiceAccordionProps = {
  index?: number;
  accountId?: string;
  totalCost?: number;
};
export function AdditionalServiceAccordion({
  index,
  accountId,
  totalCost,
  children,
}: React.PropsWithChildren<AdditionalServiceAccordionProps>) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = useCallback(
    (e: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded);
    },
    [],
  );
  return (
    <AdditionalServiceAccordionWrapper
      expanded={expanded}
      onChange={handleChange}
    >
      <AdditionalServiceAccordionSummary>
        <Box sx={{ display: 'inline-flex', gap: '40px' }}>
          <Typography variant={expanded ? 'subtitle2' : 'body2'}>
            {ValueFormatter.toIndex(Number(index) + 1, 2)}
          </Typography>
          <Typography variant={expanded ? 'subtitle2' : 'body2'}>
            {accountId}
          </Typography>
        </Box>
        <Typography variant={expanded ? 'subtitle2' : 'body2'}>
          {ValueFormatter.toCurrency(totalCost ?? 0, 'KRW', 0)}
        </Typography>
      </AdditionalServiceAccordionSummary>
      <AdditionalServiceAccordionDetails>
        {children}
      </AdditionalServiceAccordionDetails>
    </AdditionalServiceAccordionWrapper>
  );
}
AdditionalServiceAccordion.defaultProps = {
  index: undefined,
  accountId: undefined,
  totalCost: undefined,
};
