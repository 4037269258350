import { useQuery } from 'react-query';
import { AWSAccountService } from '@api/service';
import { QUERY_KEY } from '@constants';
import { useCurrentInfo } from '../../common';

export default () => {
  const { currentCompany, currentUser } = useCurrentInfo();
  const { data } = useQuery(QUERY_KEY.ONBOARD.EXTERNAL_ID, () =>
    AWSAccountService.getExternalId(),
  );
  return {
    companyId: currentCompany?.id,
    userId: currentUser?.uid,
    externalId:
      data?.items && data.items.length ? data.items[0].ExternalId : undefined,
  };
};
