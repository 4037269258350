import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlannerSP } from '@hooks/api/planner';
import { useAWSHelper } from '@hooks/helper';
import { useToggleWithValue } from '@hooks/common';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { DetailButtonIcon } from '@components/table/table.styled';
import { ValueFormatter } from '@utils';
import ComputeSPUtilFilter from '@pages/private/optimization/compute/purchasing/table/sp/ComputeSPUtilFilter';
import CommittedDetailModal from './CommittedDetailModal';

const CommittedTable: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: plannerT } = useTranslation('planner');
  const { getOptimizationAWSRegion } = useAWSHelper();
  const { data, onPageChange, pageSize } = usePlannerSP();
  const [selected, openDetail, toggleDetail] = useToggleWithValue();

  const columns = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        disableSortBy: true,
        width: 140,
        maxWidth: 140,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.common.instanceFamily'),
        accessor: 'InstanceFamily' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.common.cost', { context: 'committed' }),
        accessor: 'CommitmentCost' as const,
        align: 'right',
        disableSortBy: true,
        Cell: ({ value }) => ValueFormatter.toCurrency(value),
      },
      {
        Header: t('label.header.utilization'),
        accessor: 'Utilization' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toPercent(value),
      },
      // TODO: Recommendation will be updated later
      // {
      //   Header: plannerT('label.recommendation'),
      //   accessor: 'RecommendPricingPlan' as const,
      //   align: 'center',
      //   disableSortBy: true,
      //   Cell: ({ value, row }) => {
      //     if (_.isEmpty(value)) {
      //       return '-';
      //     }
      //     return (
      //       <LinkTypography
      //         color="primary"
      //         onClick={() =>
      //           navigate(
      //             `${ROUTES.PLANNER.SIMULATION}/${row.original.InstanceFamily}`,
      //           )
      //         }
      //       >
      //         {plannerT(
      //           `recommendButton.${value.Aggressive.Type as PurchasingType}`,
      //         )}
      //       </LinkTypography>
      //     );
      //   },
      // },
      {
        Header: t('label.common.details'),
        align: 'center',
        Cell: ({ row }) => (
          <DetailButtonIcon onClick={() => toggleDetail(row.original.UID)} />
        ),
      },
    ],
    [getOptimizationAWSRegion],
  );

  return (
    <>
      <DataPaginationTable
        TitleElement={plannerT('header.savings')}
        columnInfo={columns}
        data={data?.body?.items}
        totalCount={data?.body?.totalCount}
        onPageChange={onPageChange}
        pageSize={pageSize}
        FilterElement={ComputeSPUtilFilter}
        enableSort
      />
      {openDetail && selected && (
        <CommittedDetailModal
          open={openDetail}
          onClose={toggleDetail}
          UID={selected}
        />
      )}
    </>
  );
};

export default CommittedTable;
