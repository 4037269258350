import React from 'react';
import { Paper } from '@mui/material';
import { TabPanelUnstyled } from '@mui/base';
import { useTranslation } from 'react-i18next';
import { SuspenseBox } from '@components/styled/suspense';
import { RoundTab, RoundTabs, RoundTabsList } from '@components/styled';
import { DatabaseCoverageChart, DatabaseUtilChart } from './chart';
import { DatabaseCoverageTable, DatabaseUtilTable } from './table';

const DatabasePurchasingPage: React.FC = () => {
  const { t } = useTranslation('optimization');
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <RoundTabs value={activeTab} onChange={(e, v) => setActiveTab(Number(v))}>
      <RoundTabsList sx={{ textAlign: 'right' }}>
        <RoundTab value={0}>{t('label.header.coverage')}</RoundTab>
        <RoundTab value={1}>{t('label.header.utilization')}</RoundTab>
      </RoundTabsList>
      <Paper square sx={{ px: 7, py: 5 }}>
        <TabPanelUnstyled value={0}>
          <SuspenseBox>
            <DatabaseCoverageChart />
          </SuspenseBox>
          <SuspenseBox mt={12}>
            <DatabaseCoverageTable />
          </SuspenseBox>
        </TabPanelUnstyled>
        <TabPanelUnstyled value={1}>
          <SuspenseBox>
            <DatabaseUtilChart />
          </SuspenseBox>
          <SuspenseBox mt={12}>
            <DatabaseUtilTable />
          </SuspenseBox>
        </TabPanelUnstyled>
      </Paper>
    </RoundTabs>
  );
};

export default DatabasePurchasingPage;
