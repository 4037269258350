import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import { AuthService } from '@api/service';
import { userIdState } from '@atoms/global';
import { GLOBAL, QUERY_KEY } from '@constants';
import useToken from './useToken';

export default (suspense = true) => {
  const { pathname } = useLocation();
  const { data: activeToken } = useToken();
  const [userId, setUserId] = useRecoilState(userIdState);
  return useQuery(
    [QUERY_KEY.AUTH.LOGIN_WITH_TOKEN, activeToken],
    ({ queryKey }) => {
      if (queryKey[1]) {
        return AuthService.loginWithToken(queryKey[1]);
      }
      return undefined;
    },
    {
      cacheTime: 500,
      enabled: Boolean(activeToken),
      suspense,
      refetchInterval: 300000,
      refetchIntervalInBackground: true,
      onSuccess: (data) => {
        if (data?.items?.length && userId !== data.items[0].uid) {
          setUserId(data.items[0].uid);
        }
      },
    },
  );
};
