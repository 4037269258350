import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { usePlannerRIDetail } from '@hooks/api/planner';
import { useAWSHelper } from '@hooks/helper';
import { CCModalProps, DialogModal } from '@components/modal';
import { SimpleTypoTable } from '@components/styled';
import { bluegrey } from '@theme/colors';
import { getPercentageProportion, ValueFormatter } from '@utils';
import { PlannerComparisonPaper } from '../../Planner.styled';
import { SimulationHeader } from '../../simulation/Simulation.styled';

const ReservedDetailModal: React.FC<
  CCModalProps & { Item: EC2RIUtilization }
> = ({ open, onClose, Item }) => {
  const { t } = useTranslation('planner');
  const { t: commonT } = useTranslation('common');
  const { t: optT } = useTranslation('optimization');
  const { getAWSRegionName } = useAWSHelper();
  const { data } = usePlannerRIDetail(Item);
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      maxWidth="lg"
      HeaderProps={{
        icon: 'Smart Planner',
        label: t('header.detail', { context: 'ri' }),
      }}
      Actions={[
        {
          label: commonT('button.close'),
          color: 'inherit',
          onClick: onClose,
        },
        // TODO: Reserved Instance Simulation Feature is needed
        // {
        //   label: t('label.purchase', { context: 'plan' }),
        //   endIcon: <ArrowForwardIcon sx={{ width: '20px', height: '20px' }} />,
        //   onClick: () => {
        //     if (data?.items?.length) {
        //       navigate(
        //         `${ROUTES.PLANNER.SIMULATION}/${data.items[0].InstanceFamily}`,
        //       );
        //       onClose();
        //     }
        //   },
        // },
      ]}
    >
      {data?.items?.length && (
        <>
          <Paper>
            <SimpleTypoTable
              data={[
                {
                  label: optT('label.common.accountId'),
                  value: data.items[0].AccountId,
                },
                {
                  label: optT('label.common.region'),
                  value: getAWSRegionName(data.items[0].Region),
                },
                {
                  label: optT('label.common.instanceType'),
                  value: data.items[0].InstanceType,
                },
                {
                  label: t('aws.platform'),
                  value: data.items[0].OperatingSystem,
                },
                {
                  label: t('aws.tenancy'),
                  value: data.items[0].Tenancy,
                },
                {
                  label: t('aws.offeringClass'),
                  value: t(`RIClass.${data.items[0].Class}`),
                },
                {
                  label: t('aws.term'),
                  value: t(`Term.${data.items[0].Terms}`),
                },
                {
                  label: t('aws.paymentOption'),
                  value: t(`PaymentOption.${data.items[0].OfferingType}`),
                },
                {
                  label: t('aws.expires'),
                  value: ValueFormatter.toDateString(
                    data.items[0].ExpiredOn,
                    'YYYY-MM-DD',
                  ),
                },
              ]}
              background={bluegrey[50]}
              disableBorder
            />
          </Paper>
          <Typography variant="body2" color="textSecondary" mt={5} mb={2}>
            {t('label.monthlyUsage')}
          </Typography>
          <Paper>
            <SimpleTypoTable
              data={[
                {
                  label: optT('label.common.cost'),
                  value: ValueFormatter.toCurrency(data.items[0].MonthlyCost),
                },
                {
                  label: t('label.familyTotal', {
                    label: optT('label.common.od'),
                  }),
                  value: `${optT('label.common.hours', {
                    value: ValueFormatter.toLocaleString(
                      data.items[0].InstanceFamilyUsage?.Ondemand || 0,
                      0,
                    ),
                  })} (${ValueFormatter.toCurrency(
                    data.items[0].InstanceFamilyUsage?.OndemandCost || 0,
                  )})`,
                },
                {
                  label: `${optT('label.common.ri_short')} ${t(
                    'label.usageHours',
                  )}`,
                  value: `${optT('label.common.hours', {
                    value: ValueFormatter.toLocaleString(
                      data.items[0].UsedHours,
                      0,
                    ),
                  })}`,
                },
                {
                  label: t('label.familyTotal', {
                    label: optT('label.common.ri_coverage'),
                  }),
                  value: `${optT('label.common.hours', {
                    value: ValueFormatter.toLocaleString(
                      data.items[0].InstanceFamilyUsage?.Reserved || 0,
                      0,
                    ),
                  })} (${ValueFormatter.toPercent(
                    data.items[0].InstanceFamilyUsage?.ReservedCoverage || 0,
                  )})`,
                },
                {
                  label: optT('label.common.ri_util'),
                  value: t('label.percentOf', {
                    value: getPercentageProportion(
                      data.items[0].UsedHours,
                      data.items[0].PurchaseHours,
                    ),
                    usage: optT('label.common.hours', {
                      value: ValueFormatter.toLocaleString(
                        data.items[0].PurchaseHours,
                        0,
                      ),
                    }),
                  }),
                },
                {
                  label: t('label.familyTotal', {
                    label: optT('label.common.sp_coverage'),
                  }),
                  value: `${optT('label.common.hours', {
                    value: ValueFormatter.toLocaleString(
                      data.items[0].InstanceFamilyUsage?.SavingsPlan || 0,
                      0,
                    ),
                  })} (${ValueFormatter.toPercent(
                    data.items[0].InstanceFamilyUsage?.SavingsPlanCoverage || 0,
                  )})`,
                },
              ]}
              background={bluegrey[50]}
              disableBorder
            />
          </Paper>
          {_.isEmpty(data.items[0].RecommendPricingPlan) &&
            !_.isEmpty(data.items[0].RecommendPricingPlan) && (
              <>
                <SimulationHeader
                  Title={t('label.recommendation')}
                  mt={7}
                  mb={3}
                />
                <Grid container columnSpacing={3}>
                  <Grid item md={6} xs={12}>
                    <PlannerComparisonPaper
                      type="Aggressive"
                      item={data.items[0].RecommendPricingPlan.Aggressive}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <PlannerComparisonPaper
                      type="Passive"
                      item={data.items[0].RecommendPricingPlan.Passive}
                    />
                  </Grid>
                </Grid>
              </>
            )}
        </>
      )}
    </DialogModal>
  );
};

export default ReservedDetailModal;
