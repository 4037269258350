import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { RecoilRoot } from 'recoil';
import { useTranslation } from 'react-i18next';
import { WithCMP } from '@hooks/hoc';
import { SuspenseBox, SuspensePaper } from '@components/styled/suspense';
import { useToggle } from '@hooks/common';
import {
  AccountCount,
  CloudTotalCost,
  DailyAverageCost,
  ReportFilterModal,
  ReportFilterView,
  ServiceCount,
  UsageChart,
  UsageTable,
} from './components';

function UsageReportPage() {
  const { t } = useTranslation('usage');
  const [openFilter, toggleFilter] = useToggle();
  return (
    <RecoilRoot>
      <Box>
        <Typography variant="subtitle2" color="textSecondary">
          {t('title.cloud_usage_report')}
        </Typography>
        <Box mt="32px">
          <Grid container spacing="24px">
            <Grid item md={3} xs={6}>
              <SuspensePaper
                suspenseProps={{ p: '16px', size: 32 }}
                sx={{ p: '24px' }}
              >
                <CloudTotalCost />
              </SuspensePaper>
            </Grid>
            <Grid item md={3} xs={6}>
              <SuspensePaper
                suspenseProps={{ p: '16px', size: 32 }}
                sx={{ p: '24px' }}
              >
                <DailyAverageCost />
              </SuspensePaper>
            </Grid>
            <Grid item md={3} xs={6}>
              <SuspensePaper
                suspenseProps={{ p: '16px', size: 32 }}
                sx={{ p: '24px' }}
              >
                <ServiceCount />
              </SuspensePaper>
            </Grid>
            <Grid item md={3} xs={6}>
              <SuspensePaper
                suspenseProps={{ p: '16px', size: 32 }}
                sx={{ p: '24px' }}
              >
                <AccountCount />
              </SuspensePaper>
            </Grid>
          </Grid>
        </Box>
        <SuspensePaper sx={{ px: '56px', py: '40px', mt: '24px' }}>
          <Typography variant="subtitle2" color="textSecondary" mb="16px">
            {t('title.report_filter')}
          </Typography>
          <ReportFilterView onClick={toggleFilter} />
          <ReportFilterModal open={openFilter} onClose={toggleFilter} />
          <Divider sx={{ mt: '24px', mb: '56px' }} />
          <SuspenseBox>
            <Typography variant="subtitle2" color="textSecondary">
              {t('title.usage_chart')}
            </Typography>
            <UsageChart />
            <UsageTable />
          </SuspenseBox>
        </SuspensePaper>
      </Box>
    </RecoilRoot>
  );
}

export default WithCMP(UsageReportPage);
