import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  svgIconClasses,
  Typography,
} from '@mui/material';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useCurrentInfo } from '@hooks/api/common';
import { useNotifications } from '@hooks/api/overview';
import { SuspenseBox } from '@components/styled/suspense';
import { actionRequiredState, generalAlertState } from '@atoms/practice';
import { ROUTES } from '@routes';
import { GLOBAL } from '@constants';
import { bluegrey } from '@theme/colors';
import { ValueFormatter } from '@utils';
import { NotificationMenuItem } from '@components/styled/notification';

const ProfileMenuComponent: React.FC = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const close = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const signOut = useCallback(() => {
    localStorage.removeItem(GLOBAL.ACCESS_TOKEN);
    localStorage.removeItem(GLOBAL.REFRESH_TOKEN);
    localStorage.removeItem(GLOBAL.REFRESH_TOKEN_EXPIRED);
    navigate(ROUTES.AUTH.SIGNIN);
  }, []);
  const { currentUser } = useCurrentInfo();
  return (
    <>
      <Typography
        variant="subtitle2"
        color="#fff"
        onClick={open}
        sx={{ cursor: 'pointer' }}
      >
        {currentUser?.firstname || currentUser?.fullname || 'User'}
      </Typography>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          variant: 'elevation',
          elevation: 1,
          sx: {
            mt: 2,
          },
        }}
        keepMounted
        disablePortal
        disableScrollLock
      >
        <MenuItem onClick={() => navigate(ROUTES.SETTINGS.PROFILE)}>
          Profile
        </MenuItem>
        <MenuItem onClick={signOut}>Sign Out</MenuItem>
      </Menu>
    </>
  );
};
export const ProfileMenu = React.memo(ProfileMenuComponent);

const NotificationMenuCounter = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '1.5rem',
  height: '1.5rem',
  borderRadius: '50%',
  fontSize: '0.75rem',
  fontWeight: 400,
  padding: '0.25rem',
  color: 'text.primary',
});
const NotificationMenuItems: React.FC<{ handleOpen: () => void }> = ({
  handleOpen,
}) => {
  const { t } = useTranslation('common');
  const [actionCount, setActionCount] = useRecoilState(actionRequiredState);
  const [generalCount, setGeneralCount] = useRecoilState(generalAlertState);
  const { data } = useNotifications();
  const notifications = useMemo<Array<NotificationItem>>(() => {
    const items: Array<NotificationItem> = [];
    if (data?.items?.length) {
      if (data.items[0].ActionRequired && data.items[0].ActionRequired.length) {
        items.push(...data.items[0].ActionRequired);
      }
      if (data.items[0].GeneralAlert && data.items[0].GeneralAlert.length) {
        items.push(...data.items[0].GeneralAlert);
      }
    }
    return _.slice(_.orderBy(items, 'Created', 'desc'), 0, 3);
  }, [data?.items]);
  useEffect(() => {
    if (data?.items?.length) {
      const { ActionRequired, GeneralAlert } = data.items[0];
      if (typeof ActionRequired?.length === 'number') {
        setActionCount(ActionRequired.length);
      }
      if (typeof GeneralAlert?.length === 'number') {
        setGeneralCount(GeneralAlert.length);
      }
    }
  }, [data?.items, setActionCount, setGeneralCount]);
  if (!data?.items?.length || !data.items[0]) {
    return null;
  }
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid #f0f0f7"
        px={2}
        py={1.5}
      >
        <Typography variant="subtitle2">{t('label.notifications')}</Typography>
        <Typography variant="subtitle2">
          {t('label.newNotification', { count: actionCount + generalCount })}
        </Typography>
      </Box>
      <Box px={2} py={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" color="textSecondary">
            {t('label.actionRequired')}
          </Typography>
          <NotificationMenuCounter bgcolor="#f1e5fc">
            {ValueFormatter.toLocaleString(actionCount, 0)}
          </NotificationMenuCounter>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={1}
        >
          <Typography variant="body2" color="textSecondary">
            {t('label.generalAlerts')}
          </Typography>
          <NotificationMenuCounter bgcolor={bluegrey[200]}>
            {ValueFormatter.toLocaleString(generalCount, 0)}
          </NotificationMenuCounter>
        </Box>
      </Box>
      {Boolean(notifications.length) && (
        <>
          <Stack borderTop="1px solid #f0f0f7">
            {notifications.map((item) => (
              <NotificationMenuItem
                key={item.UID}
                item={item}
                onClick={handleOpen}
              />
            ))}
          </Stack>
          <Box
            display="flex"
            alignItems="center"
            onClick={handleOpen}
            color="primary.main"
            fontSize="0.875rem"
            px={2}
            py={1}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
                bgcolor: bluegrey[100],
                [`> .${svgIconClasses.root}`]: {
                  display: 'inline-block',
                },
              },
            }}
          >
            {t('label.seeMore')}
            <ArrowForwardRoundedIcon
              sx={{
                display: 'none',
                width: '0.875rem',
                height: '0.875rem',
                ml: 1,
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export const NotificationMenu: React.FC = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const close = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleOpen = useCallback(() => {
    navigate(ROUTES.NOTIFICATION.ROOT);
    close();
  }, [navigate, close]);
  const { data } = useNotifications();
  return (
    <>
      <IconButton
        type="button"
        color="inherit"
        onClick={open}
        aria-describedby="appbar-notification"
      >
        <Badge
          variant="dot"
          color="primary"
          invisible={
            !data?.items?.length ||
            (!data.items[0]?.ActionRequired?.length &&
              !data.items[0]?.GeneralAlert?.length)
          }
        >
          <NotificationsIcon sx={{ width: '20px', height: '20px' }} />
        </Badge>
      </IconButton>
      <Menu
        id="appbar-notification"
        open={Boolean(anchorEl)}
        onClose={close}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          variant: 'elevation',
          elevation: 1,
          sx: {
            mt: 2,
          },
        }}
        keepMounted
        disablePortal
        disableScrollLock
      >
        <SuspenseBox maxWidth={328} minWidth={328}>
          <NotificationMenuItems handleOpen={handleOpen} />
        </SuspenseBox>
      </Menu>
    </>
  );
};
