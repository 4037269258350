import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEC2Others } from '@hooks/api/optimization/ec2';
import { useBestPracticeHelper } from '@hooks/helper';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { DetailLinkIcon } from '@components/table/table.styled';
import { ROUTES } from '@routes';
import { ValueFormatter } from '@utils';
import ComputeOtherFilter from './ComputeOtherFilter';

const ComputeOtherTable: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { getTitle } = useBestPracticeHelper();
  const { data, onPageChange, pageSize } = useEC2Others();

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AWSAccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.common.instanceId'),
        accessor: 'ResourceId' as const,
        minWidth: 180,
        maxWidth: 180,
        disableSortBy: true,
      },
      {
        Header: t('label.common.item'),
        accessor: 'CaseId' as const,
        disableSortBy: true,
        Cell: ({ value }) => getTitle({ CaseId: value }),
      },
      {
        Header: t('label.common.savings'),
        accessor: 'MonthlyCostSaving' as const,
        align: 'right',
        minWidth: 180,
        maxWidth: 180,
        Cell: ({ row }) =>
          ValueFormatter.toCurrency(row.original.MonthlyCostSaving),
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailLinkIcon
            to={`${ROUTES.DETAILS.COMPUTE}/${row.original.ResourceId}?tab=other`}
            requireCUR
          />
        ),
      },
    ],
    [getTitle],
  );

  return (
    <DataPaginationTable
      data={data?.body?.items}
      columnInfo={columnInfo}
      onPageChange={onPageChange}
      totalCount={data?.body?.totalCount}
      pageSize={pageSize}
      FilterElement={ComputeOtherFilter}
      CSVUrl="ec2/others"
      enableSort
      LastUpdate={data.body.extras.LatestUpdate}
    />
  );
};

export default ComputeOtherTable;
