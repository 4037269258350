import React, { useCallback } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useCurrentInfo } from '@hooks/api/common';
import { useUpdateUser } from '@hooks/api/settings/user';
import i18n from '@utils/i18n';

const LanguageSelector: React.FC = () => {
  const { currentUser } = useCurrentInfo();
  const { mutateAsync } = useUpdateUser();
  const handleChange = useCallback(
    async (evt) => {
      const targetLang = evt.target.value;
      if (currentUser && targetLang !== currentUser.language) {
        await mutateAsync({
          original: currentUser,
          changes: { language: targetLang },
        });
        window.location.reload();
      }
    },
    [currentUser],
  );
  return (
    <TextField
      variant="standard"
      value={i18n.language}
      onChange={handleChange}
      InputProps={{ style: { color: 'inherit' } }}
      select
    >
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="ko">한국어</MenuItem>
    </TextField>
  );
};

export default LanguageSelector;
