import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCreatePromotion } from '@hooks/api/admin/promotion';
import { useGetCoupons } from '@hooks/api/admin/coupon';
import { useSnackbar } from '@hooks/common';
import { CCModalProps, DialogModal } from '@components/modal';

type CreatePromotionForm = {
  CouponId: string;
  Code: string;
};

const useCreatePromotionForm = (): UseValidation<CreatePromotionForm> => ({
  initialValues: {
    CouponId: '',
    Code: '',
  },
  validationSchema: Yup.object({
    CouponId: Yup.string().trim().defined('CouponId is required'),
    Code: Yup.string().trim().defined('Code is required'),
  }).defined(),
});

const CreatePromotionModal: React.FC<CCModalProps> = ({ open, onClose }) => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { data } = useGetCoupons();
  const { mutateAsync: createPromotion } = useCreatePromotion();
  const { initialValues, validationSchema } = useCreatePromotionForm();
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (v) => {
      try {
        await createPromotion(v);
        showSuccessSnackbar('Promotion has been created');
        if (onClose) {
          onClose();
        }
      } catch (e) {
        showErrorSnackbar('Error occurred during create promotion');
      }
    },
  });

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="md"
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: 'Cancel',
        },
        {
          type: 'submit',
          label: 'Create',
          disabled: !isValid,
        },
      ]}
      HeaderProps={{
        icon: 'Admin',
        label: 'Add New Promotion Code',
      }}
    >
      <Box mb={4}>
        <TextField
          name="Code"
          value={values.Code}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          inputMode="text"
          label="Promotion Code"
          error={Boolean(touched.Code && errors.Code)}
          helperText={
            Boolean(touched.Code && errors.Code) && String(errors.Code)
          }
          fullWidth
        />
      </Box>
      <Box>
        <FormControl fullWidth>
          <InputLabel id="standard-select-label">Coupon</InputLabel>
          <Select
            name="CouponId"
            labelId="standard-select-label"
            variant="standard"
            onChange={handleChange}
          >
            {data?.items?.length &&
              data.items.map((r: any) => (
                <MenuItem key={r.UID} value={r.UID}>
                  {r.Name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </DialogModal>
  );
};

export default CreatePromotionModal;
