import React, { useCallback, useMemo, useRef } from 'react';
import { Group } from '@visx/group';
import { Circle } from '@visx/shape';
import { localPoint } from '@visx/event';
import { Tooltip, withTooltip } from '@visx/tooltip';
import { useTranslation } from 'react-i18next';
import { bluegrey, secondary } from '@theme/colors';
import MapBackground from '@assets/images/region_map.svg';

interface RegionMapProps {
  onClick?: (region: SupportedAWSRegion) => void;
  selectedRegions?: Array<SupportedAWSRegion>;
  visibleRegions?: Array<SupportedAWSRegion>;
}
type RegionPoint = {
  code: SupportedAWSRegion;
  cx: number;
  cy: number;
};
const REGION_POINTS: ReadonlyArray<RegionPoint> = [
  { code: 'us-east-1', cx: 120, cy: 87 },
  { code: 'us-east-2', cx: 105, cy: 80 },
  { code: 'us-west-1', cx: 5, cy: 81 },
  { code: 'us-west-2', cx: 10, cy: 71 },
  { code: 'af-south-1', cx: 385, cy: 287 },
  { code: 'ap-east-1', cx: 638, cy: 130 },
  { code: 'ap-southeast-3', cx: 623, cy: 209 },
  { code: 'ap-south-1', cx: 536, cy: 141 },
  { code: 'ap-northeast-3', cx: 693, cy: 97 },
  { code: 'ap-northeast-2', cx: 670, cy: 89 },
  { code: 'ap-southeast-1', cx: 607, cy: 183 },
  { code: 'ap-southeast-2', cx: 730, cy: 282 },
  { code: 'ap-northeast-1', cx: 708, cy: 89 },
  { code: 'ca-central-1', cx: 138, cy: 53 },
  { code: 'eu-central-1', cx: 365, cy: 45 },
  { code: 'eu-west-1', cx: 310, cy: 35 },
  { code: 'eu-west-2', cx: 336, cy: 38 },
  { code: 'eu-south-1', cx: 361, cy: 63 },
  { code: 'eu-west-3', cx: 348, cy: 53 },
  { code: 'eu-north-1', cx: 379, cy: 5 },
  { code: 'me-south-1', cx: 473, cy: 119 },
  { code: 'sa-east-1', cx: 211, cy: 252 },
] as const;

let tooltipTimeout: number;

export default withTooltip<RegionMapProps, RegionPoint>(
  ({
    onClick,
    selectedRegions,
    visibleRegions,
    hideTooltip,
    showTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  }) => {
    const isSelection = useMemo<boolean>(
      () => !!(onClick && selectedRegions),
      [],
    );
    const { t } = useTranslation('aws');
    const regions: Record<SupportedAWSRegion, string> = t('region', {
      returnObjects: true,
    });
    const svgRef = useRef<SVGSVGElement>(null);
    const handleMouseMove = useCallback(
      (event: React.MouseEvent | React.TouchEvent, region: RegionPoint) => {
        if (tooltipTimeout) clearTimeout(tooltipTimeout);
        if (!svgRef.current) return;
        const point = localPoint(svgRef.current, event);
        if (!point) return;
        showTooltip({
          tooltipLeft: point.x,
          tooltipTop: point.y,
          tooltipData: region,
        });
        if (isSelection && selectedRegions) {
          const group = document.querySelector('#region_bg_group');
          const bg = document.querySelector('#region_bg_hover');
          if (group && !bg) {
            const groupNS = group.namespaceURI;
            const circle = document.createElementNS(groupNS, 'circle');
            circle.setAttribute('cx', `${region.cx}`);
            circle.setAttribute('cy', `${region.cy}`);
            circle.setAttribute('r', `${10}`);
            circle.setAttribute('pointerEvents', 'none');
            circle.setAttribute('id', 'region_bg_hover');
            circle.setAttribute(
              'fill',
              selectedRegions.includes(region.code) ? '#FA7DC8' : bluegrey[200],
            );
            circle.setAttribute(
              'fill-opacity',
              selectedRegions.includes(region.code) ? '50%' : '100%',
            );
            group.appendChild(circle);
          }
        }
      },
      [showTooltip, selectedRegions, visibleRegions],
    );
    const handleMouseLeave = useCallback(() => {
      tooltipTimeout = window.setTimeout(() => {
        hideTooltip();
      }, 300);
      if (isSelection) {
        const bg = document.querySelector('#region_bg_hover');
        if (bg) {
          bg.remove();
        }
      }
    }, [hideTooltip, isSelection]);
    const handleClick = useCallback(
      (code: SupportedAWSRegion) => {
        if (onClick) {
          onClick(code);
        }
      },
      [onClick],
    );
    return (
      <>
        <svg
          width="100%"
          viewBox="0 0 1136 512"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          ref={svgRef}
        >
          <image x="0" y="0" xlinkHref={MapBackground} />
          <Group
            id="region_bg_group"
            transform="translate(189.000000, 132.000000)"
            pointerEvents="none"
          />
          <Group
            transform="translate(189.000000, 132.000000)"
            fillRule="nonzero"
            pointerEvents="none"
          >
            {REGION_POINTS.map((point) => {
              if (!isSelection) {
                if (visibleRegions && visibleRegions.includes(point.code)) {
                  return (
                    <React.Fragment key={point.code}>
                      <Circle
                        cx={point.cx}
                        cy={point.cy}
                        r="10"
                        fill="#FA7DC8"
                        fillOpacity="50%"
                        pointerEvents="none"
                      />
                      <Circle
                        cx={point.cx}
                        cy={point.cy}
                        r="4"
                        fill={secondary[500]}
                        pointerEvents="bounding-box"
                        onMouseOver={(e) => handleMouseMove(e, point)}
                        onTouchStart={(e) => handleMouseMove(e, point)}
                        onMouseLeave={handleMouseLeave}
                        onTouchEnd={handleMouseLeave}
                      />
                    </React.Fragment>
                  );
                }
                return null;
              }
              return (
                <Circle
                  key={point.code}
                  cx={point.cx}
                  cy={point.cy}
                  r="4"
                  fill={
                    selectedRegions && selectedRegions.includes(point.code)
                      ? secondary[500]
                      : bluegrey[400]
                  }
                  pointerEvents="bounding-box"
                  style={onClick ? { cursor: 'pointer' } : undefined}
                  onMouseOver={(e) => handleMouseMove(e, point)}
                  onTouchStart={(e) => handleMouseMove(e, point)}
                  onMouseLeave={handleMouseLeave}
                  onTouchEnd={handleMouseLeave}
                  onClick={() => handleClick(point.code)}
                />
              );
            })}
          </Group>
        </svg>
        {tooltipOpen &&
          tooltipData &&
          tooltipLeft != null &&
          tooltipTop != null && (
            <Tooltip left={tooltipLeft} top={tooltipTop}>
              <div>
                <strong>
                  {regions[tooltipData.code]} / {tooltipData.code}
                </strong>
              </div>
            </Tooltip>
          )}
      </>
    );
  },
);
