/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useGetInvoiceDetail } from '@hooks/api/cmp';
import { ValueFormatter } from '@utils';
import {
  AccordionIndexColumn,
  AccordionItemColumn,
  AccordionTable,
  AccordionTableItem,
  AccordionValueColumn,
  InvoiceAccordion,
  ServiceAccordion,
  ServiceAccordionItem,
} from './styled';

const CloudServiceDetails: React.FC = () => {
  const { t } = useTranslation('report');
  const { t: awsT } = useTranslation('aws');
  const { id } = useParams();
  const { data: invoices } = useGetInvoiceDetail(id);
  if (!invoices?.data.length) {
    return null;
  }
  return (
    <Stack spacing="16px" mt="16px">
      {invoices.data.map((invoice, idx) => (
        <InvoiceAccordion
          key={`invoice_service_${invoice.UID}`}
          accountId={invoice.AccountId}
          totalCost={invoice.CloudCost.USD}
          index={idx}
        >
          <AccordionTable>
            <AccordionTableItem header sx={{ pl: '56px' }}>
              <AccordionIndexColumn>No.</AccordionIndexColumn>
              <AccordionItemColumn>
                {t('text.invoice_item')}
              </AccordionItemColumn>
              <AccordionValueColumn>
                {t('text.invoice_used')}
              </AccordionValueColumn>
              <AccordionValueColumn>
                {t('text.invoice_amount')}
              </AccordionValueColumn>
            </AccordionTableItem>
            {Object.keys(invoice.Data)
              .filter((v) => v !== 'AccountTotalCost')
              .map((service, serviceIdx) => (
                <ServiceAccordion
                  key={`service_accordion_${invoice.UID}_${service}`}
                  name={service}
                  index={serviceIdx}
                  cost={_.get(invoice.Data, `${service}.ServiceTotalCost`)}
                >
                  {Object.keys(invoice.Data[service])
                    .filter((v) => v !== 'ServiceTotalCost')
                    .map((region) => (
                      <React.Fragment
                        key={`service_accordion_${invoice.UID}_${service}_${region}`}
                      >
                        <ServiceAccordionItem
                          // @ts-ignore
                          item={awsT(`region.${region}`, region)}
                          value={invoice.Data[service][region].RegionTotalCost}
                        />
                        {Object.keys(invoice.Data[service][region])
                          .filter((v) => v !== 'RegionTotalCost')
                          .map((product) =>
                            Object.keys(invoice.Data[service][region][product])
                              .filter((v) => v !== 'ProductTotalCost')
                              .map((family) => (
                                <React.Fragment
                                  key={`service_accordion_${invoice.UID}_${service}_${region}_${product}_${family}`}
                                >
                                  <ServiceAccordionItem
                                    indent={2}
                                    item={family}
                                  />
                                  {Object.keys(
                                    invoice.Data[service][region][product][
                                      family
                                    ],
                                  )
                                    .filter(
                                      (v) => v !== 'ProductFamilyTotalCost',
                                    )
                                    .map((usage) => (
                                      <ServiceAccordionItem
                                        key={`service_accordion_${invoice.UID}_${service}_${region}_${product}_${family}_${usage}`}
                                        indent={3}
                                        item={usage}
                                        amount={
                                          invoice.Data[service][region][
                                            product
                                          ][family][usage].UsageAmount
                                        }
                                        value={
                                          invoice.Data[service][region][
                                            product
                                          ][family][usage].Cost
                                        }
                                      />
                                    ))}
                                </React.Fragment>
                              )),
                          )}
                      </React.Fragment>
                    ))}
                </ServiceAccordion>
              ))}
            <AccordionTableItem summary>
              <AccordionItemColumn>
                {t('text.invoice_total')}
              </AccordionItemColumn>
              <AccordionValueColumn />
              <AccordionValueColumn>
                {ValueFormatter.toCurrency(invoice.CloudCost.USD)}
              </AccordionValueColumn>
            </AccordionTableItem>
          </AccordionTable>
        </InvoiceAccordion>
      ))}
    </Stack>
  );
};

export default CloudServiceDetails;
