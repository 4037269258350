import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePurchaseStatus } from '@hooks/api/settings/payment';
import { SuspensePaper } from '@components/styled/suspense';
import UpcomingInvoice from '../invoice/UpcomingInvoice';
import Subscribe from '../subscribe/Subscribe';

const PaymentArea: React.FC = () => {
  const { data } = usePurchaseStatus();
  const subscribed = Boolean(data?.items?.length);
  const { t } = useTranslation('settings');

  return (
    <>
      <Typography variant="subtitle2" color="textSecondary" mb={1}>
        {subscribed
          ? t('payment.title.upcoming')
          : t('payment.title.subscribe')}
      </Typography>
      <SuspensePaper sx={{ p: 10 }}>
        {subscribed ? <UpcomingInvoice /> : <Subscribe />}
      </SuspensePaper>
    </>
  );
};

export default PaymentArea;
