import {
  Components,
  createTheme,
  outlinedInputClasses,
  Theme,
} from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';
import { darkShadows } from '@theme/shadows';
import bluegrey from '@theme/colors/bluegrey';
import { disabled, primary, secondary, tertiary } from '@theme/colors';

const generateCostClipperTheme = (theme: Theme): Theme => {
  const costClipperComponents: Components = {
    MuiTypography: {
      styleOverrides: {
        root: {
          '> a': {
            textDecoration: 'none',
            color: theme.palette.primary.main,
          },
        },
      },
      variants: [
        {
          props: { color: 'tertiary' },
          style: {
            color: theme.palette.tertiary.main,
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        contained: {
          '&.Mui-disabled': {
            color: disabled[300],
            backgroundColor: disabled[100],
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            borderRadius: '5px',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: darkShadows[1],
            },
          },
        },
        {
          props: { color: 'primary', variant: 'contained' },
          style: {
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
        {
          props: { color: 'secondary', variant: 'contained' },
          style: {
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
          },
        },
        {
          props: { color: 'tertiary', variant: 'contained' },
          style: {
            color: theme.palette.common.white,
            '&:hover': {
              backgroundColor: theme.palette.tertiary.main,
            },
          },
        },
        {
          props: { color: 'inherit', variant: 'contained' },
          style: {
            color: bluegrey[600],
            backgroundColor: bluegrey[200],
            '&:hover': {
              color: bluegrey[600],
              backgroundColor: bluegrey[200],
            },
          },
        },
        {
          props: { color: 'primary', variant: 'outlined' },
          style: {
            '&:hover': {
              backgroundColor: '#CCA8F033',
            },
          },
        },
        {
          props: { color: 'secondary', variant: 'outlined' },
          style: {
            '&:hover': {
              backgroundColor: '#FA7DC833',
            },
          },
        },
        {
          props: { color: 'tertiary', variant: 'outlined' },
          style: {
            '&:hover': {
              backgroundColor: '#6666CC33',
            },
          },
        },
        {
          props: { color: 'inherit', variant: 'outlined' },
          style: {
            color: bluegrey[600],
            borderColor: bluegrey[400],
            '&:hover': {
              backgroundColor: bluegrey[200],
              borderColor: bluegrey[300],
            },
          },
        },
        {
          props: { color: 'primary', variant: 'text' },
          style: {
            '&:hover': {
              boxShadow: '0px 2px 3px rgba(204, 168, 240, 0.4)',
            },
          },
        },
        {
          props: { color: 'secondary', variant: 'text' },
          style: {
            '&:hover': {
              boxShadow: '0px 2px 3px rgba(250, 125, 200, 0.4)',
            },
          },
        },
        {
          props: { color: 'tertiary', variant: 'text' },
          style: {
            color: theme.palette.tertiary.main,
            '&:hover': {
              boxShadow: '0px 2px 3px #6666CC66',
            },
          },
        },
        {
          props: { color: 'inherit', variant: 'text' },
          style: {
            '&:hover': {
              boxShadow: '0px 2px 3px rgba(115, 116, 128, 0.4)',
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            height: '30px',
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '14.06px',
            borderRadius: '4px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            height: '36px',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '16.41px',
          },
        },
        {
          props: { size: 'large' },
          style: {
            height: '45px',
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: '18.75px',
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.body2.fontSize,
          fontWeight: theme.typography.body2.fontWeight,
          lineHeight: theme.typography.body2.lineHeight,
        },
        input: {
          '&:focus': {
            background: 'none',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`&:hover:not(.Mui-focused) .${outlinedInputClasses.notchedOutline}`]:
            {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
        },
        input: {
          padding: '0.997rem 1rem',
        },
        inputSizeSmall: {
          padding: '0.872rem 1rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '0.25rem 0 0 0',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '> .MuiTypography-root': {
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.body2.fontWeight,
            lineHeight: theme.typography.body2.lineHeight,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.body2.fontSize,
          fontWeight: theme.typography.body2.fontWeight,
          lineHeight: theme.typography.body2.lineHeight,
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: 'body2',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          MenuListProps: {
            disablePadding: true,
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        MenuListProps: {
          disablePadding: true,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: disabled[300],
          },
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            padding: '0.875rem',
          },
        },
        {
          props: { color: 'primary' },
          style: {
            '&:hover': {
              bgColor: 'rgba(204, 168, 240, 0.2)',
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            '&:hover': {
              bgColor: 'rgba(250, 125, 200, 0.2)',
            },
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'none',
          },
        },
      },
      variants: [
        {
          props: { size: 'medium' },
          style: {
            svg: {
              width: '16px',
              height: '16px',
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            svg: {
              width: '14px',
              height: '14px',
            },
          },
        },
      ],
    },
    MuiClockPicker: {
      styleOverrides: {
        arrowSwitcher: {
          display: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '1.5rem',
        },
        deleteIcon: {
          color: 'rgba(255, 255, 255, 0.6)',
        },
      },
      variants: [
        {
          props: { size: 'medium' },
          style: {
            height: '2rem',
            fontSize: '0.875rem',
            lineHeight: '1rem',
            fontWeight: 500,
          },
        },
        {
          props: { size: 'small' },
          style: {
            height: '1.5rem',
            fontSize: '0.875rem',
            lineHeight: '1rem',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'filled', color: 'default' },
          style: {
            backgroundColor: bluegrey[200],
          },
        },
        {
          props: { variant: 'outlined', color: 'default' },
          style: {
            borderColor: bluegrey[500],
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderColor: theme.palette.primary.main,
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            borderColor: theme.palette.secondary.main,
          },
        },
        {
          props: { variant: 'outlined', color: 'tertiary' },
          style: {
            borderColor: theme.palette.tertiary.main,
          },
        },
        {
          props: { color: 'default' },
          style: {
            '> .MuiChip-deleteIcon': {
              color: bluegrey[400],
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            '> .MuiChip-deleteIcon': {
              color: primary[500],
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            '> .MuiChip-deleteIcon': {
              color: secondary[400],
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'tertiary' },
          style: {
            '> .MuiChip-deleteIcon': {
              color: tertiary[300],
            },
          },
        },
      ],
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        rounded: {
          borderRadius: '0.25rem',
        },
        outlined: {
          border: '1px solid #F0F0F7',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active': {
            fontSize: '1.5rem',
            fontWeight: 700,
            lineHeight: '2rem',
          },
          '&.Mui-completed': {
            fontSize: '1.5rem',
            fontWeight: 700,
            lineHeight: '2rem',
          },
        },
        labelContainer: {
          '& .MuiStepLabel-label': {
            fontSize: '1.5rem',
            fontWeight: 700,
            lineHeight: '2rem',
            color: theme.palette.text.primary,
          },
        },
        iconContainer: {
          paddingRight: '1.5rem',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          '&.MuiStepConnector-vertical': {
            marginTop: '0.75rem',
            marginBottom: '0.75rem',
            marginLeft: '1.25rem',
          },
        },
        line: {
          borderColor: '#D5D6E6',
          borderTopWidth: '2px',
          borderLeftWidth: '2px',
        },
        lineHorizontal: {
          marginLeft: '15%',
          marginRight: '15%',
        },
        alternativeLabel: {
          top: 60,
          left: 'calc(-20%)',
          right: 'calc(80%)',
        },
      },
    },
    MuiStepIcon: {
      defaultProps: {
        variant: 'primary',
      },
      styleOverrides: {
        root: {
          fontSize: '2.5rem',
          color: bluegrey[200],
          ':not(&.Mui-active)': {
            text: {
              fill: theme.palette.text.primary,
            },
          },
        },
        text: {
          fontWeight: 500,
        },
      },
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            '&.Mui-active': {
              color: theme.palette.primary.main,
            },
            '&.Mui-completed': {
              color: theme.palette.primary.main,
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            '&.Mui-active': {
              color: theme.palette.secondary.main,
            },
            '&.Mui-completed': {
              color: theme.palette.secondary.main,
            },
          },
        },
        {
          props: { variant: 'tertiary' },
          style: {
            '&.Mui-active': {
              color: tertiary[600],
            },
            '&.Mui-completed': {
              color: tertiary[600],
            },
          },
        },
        {
          props: { variant: 'default' },
          style: {
            '&.Mui-active': {
              color: bluegrey[200],
            },
            '&.Mui-completed': {
              color: bluegrey[400],
            },
            text: {
              fill: theme.palette.text.primary,
            },
          },
        },
      ],
    },
    MuiAppBar: {
      defaultProps: {
        color: 'tertiary',
      },
      variants: [
        {
          props: { color: 'tertiary' },
          style: {
            backgroundColor: tertiary[600],
            color: theme.palette.common.white,
          },
        },
      ],
    },
    MuiToolbar: {
      defaultProps: {
        variant: 'dense',
      },
      variants: [
        {
          props: { variant: 'dense' },
          style: {
            height: '48px',
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #F0F0F7',
          '&.Mui-selected': {
            backgroundColor: '#F9F5FF',
          },
          '&.Mui-selected.MuiTableRow-hover:hover': {
            backgroundColor: '#F4EDFF',
          },
          '&.MuiTableRow-hover:hover:not(.Mui-selected)': {
            backgroundColor: '#F0F0F799',
          },
        },
        head: {
          borderBottom: '2px solid #F0F0F7',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        head: {
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          fontWeight: 500,
        },
        body: {
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          fontWeight: 400,
          paddingTop: '1.5rem',
          paddingBottom: '1.5rem',
        },
      },
    },
    MuiPagination: {
      defaultProps: {
        boundaryCount: 2,
      },
      styleOverrides: {
        ul: {
          justifyContent: 'center',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '2.5rem 3.5rem 1.5rem',
          fontSize: '1.5rem',
          lineHeight: '2rem',
          fontWeight: 700,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '2.5rem 3.5rem 2.5rem',
        },
      },
    },
    MuiDialogContentText: {
      defaultProps: {
        variant: 'body2',
        color: 'textSecondary',
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '1.5rem 3.5rem 2.5rem',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '1rem',
        },
      },
      variants: [
        {
          props: { color: 'tertiary' },
          style: {
            color: tertiary[400],
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottomWidth: '2px',
          borderColor: '#F0F0F7',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.MuiDateRangePickerDay-day.MuiDateRangePickerDay-notSelectedDate': {
            color: bluegrey[900],
          },
        },
      },
    },
    MuiDateRangePickerDay: {
      styleOverrides: {
        root: {
          color: bluegrey[900],
          '&.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
            backgroundColor: primary[100],
          },
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: disabled[300],
          },
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        outlined: {
          borderWidth: '1px',
          padding: '5px',
        },
        outlinedGrey: {
          borderColor: bluegrey[500],
        },
        filledGrey: {
          backgroundColor: '#BE96F2',
          boxShadow: 'none',
        },
      },
    },
  };
  return createTheme(theme, { components: costClipperComponents });
};

export default generateCostClipperTheme;
