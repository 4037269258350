import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useEC2UtilizationChart } from '@hooks/api/optimization/ec2';
import { ec2CPUState, ec2MemState } from '@atoms/optimization';
import { RightSizingChart } from '@components/chart/optimization';
import {
  GrmtTooltipDivider,
  GrmtTooltipTextItem,
  GrmtTooltipWrapper,
} from '@components/chart';
import { ChartCoverBox } from '@components/styled/noData';
import { ValueFormatter } from '@utils';
import {
  RIGHTSIZING_DUMMY,
  RIGHTSIZING_DUMMY_EXTRA,
} from '@components/chart/chart.dummy';

const ComputeUtilChart: React.FC = () => {
  const { t } = useTranslation('optimization');
  const ec2CPU = useRecoilValue(ec2CPUState);
  const ec2Mem = useRecoilValue(ec2MemState);
  const { data } = useEC2UtilizationChart();
  return (
    <ChartCoverBox isEmpty={Boolean(!data?.items?.length)}>
      <RightSizingChart
        data={data?.items?.length ? data.items : RIGHTSIZING_DUMMY}
        cpuThreshold={ec2CPU ? Number(ec2CPU) : undefined}
        memThreshold={ec2Mem ? Number(ec2Mem) : undefined}
        xAxis={[
          { dataKey: 'CPUAverage', name: 'Avg' },
          { dataKey: 'CPUMax', name: 'Max' },
        ]}
        yAxis={[
          { dataKey: 'MemAverage', name: 'Avg' },
          { dataKey: 'MemMax', name: 'Max' },
        ]}
        extraInfo={[
          {
            label: t('label.chart.compute.totalRunning'),
            value: ValueFormatter.toLocaleString(
              data?.items?.length || RIGHTSIZING_DUMMY_EXTRA.Total,
              0,
            ),
          },
          {
            label: t('label.chart.compute.totalRightsize'),
            value: ValueFormatter.toLocaleString(
              data?.items?.length
                ? data?.extras?.TotalNumberOfRightSizing || 0
                : RIGHTSIZING_DUMMY_EXTRA.Right,
              0,
            ),
          },
          {
            label: t('label.chart.compute.rightsizingCost'),
            value: ValueFormatter.toCurrency(
              data?.items?.length
                ? data?.extras?.TotalPossibleSaving || 0
                : RIGHTSIZING_DUMMY_EXTRA.Cost,
            ),
          },
        ]}
        tooltipContent={({ active, payload }) => {
          if (payload?.length) {
            const { payload: item, name } = payload[0];
            return (
              <GrmtTooltipWrapper active={active} payload={payload} width={340}>
                <GrmtTooltipTextItem
                  name={t('label.common.instanceId')}
                  value={item.InstanceId}
                />
                <GrmtTooltipTextItem
                  name={t('label.common.instanceType')}
                  value={item.InstanceType}
                />
                {item.Tag?.length ? (
                  <GrmtTooltipTextItem
                    name={t('label.common.tags')}
                    value={Object.values(item.Tag[0]).join(', ')}
                  />
                ) : null}
                <GrmtTooltipDivider />
                <GrmtTooltipTextItem
                  name={t('label.util.cpu', { context: 'short' })}
                  value={ValueFormatter.toPercent(
                    _.get(item, name === 'Avg' ? 'CPUAverage' : 'CPUMax') ||
                      '-',
                  )}
                />
                <GrmtTooltipTextItem
                  name={t('label.util.mem', { context: 'short' })}
                  value={ValueFormatter.toPercent(
                    _.get(item, name === 'Avg' ? 'MemAverage' : 'MemMax') ||
                      '-',
                  )}
                />
              </GrmtTooltipWrapper>
            );
          }
          return null;
        }}
      />
    </ChartCoverBox>
  );
};

export default ComputeUtilChart;
