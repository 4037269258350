import React from 'react';
import {
  experimental_sx as sx,
  Paper,
  Step,
  StepLabel,
  Stepper,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { WithCUR } from '@hooks/hoc';
import { SuspenseBox } from '@components/styled/suspense';
import { useTranslation } from 'react-i18next';
import {
  simulationStepState,
  useResetSimulationStates,
} from './Simulation.atoms';
import { PreviewStep, PurchaseStep, UsageStep } from './steps';

const StepOptionText = styled(Typography)(
  sx({
    color: 'textSecondary',
    mt: 1,
  }),
);

const PlannerSimulationPage: React.FC = () => {
  const { t } = useTranslation('planner');
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const active = useRecoilValue(simulationStepState);
  useResetSimulationStates();
  return (
    <Paper sx={{ px: 7, py: 5 }}>
      <Stepper
        activeStep={active}
        orientation={isSmall ? 'vertical' : 'horizontal'}
      >
        <Step>
          <StepLabel
            sx={{ alignItems: 'flex-start' }}
            optional={
              <StepOptionText>{t('step.optional.usage')}</StepOptionText>
            }
          >
            {t('step.label.usage')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            sx={{ alignItems: 'flex-start' }}
            optional={
              <StepOptionText>{t('step.optional.preview')}</StepOptionText>
            }
          >
            {t('step.label.preview')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            sx={{ alignItems: 'flex-start' }}
            optional={
              <StepOptionText>{t('step.optional.purchase')}</StepOptionText>
            }
          >
            {t('step.label.purchase')}
          </StepLabel>
        </Step>
      </Stepper>
      <SuspenseBox mt={10}>
        {active === 0 && <UsageStep />}
        {active === 1 && <PreviewStep />}
        {active === 2 && <PurchaseStep />}
      </SuspenseBox>
    </Paper>
  );
};

export default WithCUR(PlannerSimulationPage);
