import { useQuery } from 'react-query';
import _ from 'lodash';
import { QUERY_KEY } from '@constants';
import { DiagnosticService } from '@api/service';
import { useCurrentInfo } from '../../common';

const INTERVAL = 10000 as const;

export default () => {
  const { isLoading, currentCompany } = useCurrentInfo();
  return useQuery(
    QUERY_KEY.ONBOARD.GET_DIAGNOSTIC,
    () => DiagnosticService.getDiagnostic(currentCompany?.id ?? ''),
    {
      refetchInterval: (data) => {
        const isFinished = data?.items?.length
          ? _.every(data.items[0], 'IsDone')
          : false;
        return isFinished ? false : INTERVAL;
      },
      enabled: !isLoading && Boolean(currentCompany?.id),
    },
  );
};
