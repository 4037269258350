import React, { useCallback } from 'react';
import { useDeleteSubscription } from '@hooks/api/admin/subscription';
import { CCModalProps, ConfirmDialog } from '@components/modal';
import { useSnackbar } from '@hooks/common';

const CancelSubscriptionModal: React.FC<
  CCModalProps & { subscriptionId: string }
> = ({ open, onClose, subscriptionId }) => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutateAsync } = useDeleteSubscription();
  const cancelSubscription = useCallback(async () => {
    try {
      await mutateAsync({ subscriptionId });
      showSuccessSnackbar('Subscription has been cancelled');
      onClose();
    } catch (e) {
      showErrorSnackbar('Error occurred during cancel subscription');
    }
  }, []);

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title="Cancel Subscription"
      content="Are you sure to cancel subscription?"
      Actions={[
        {
          color: 'inherit',
          label: 'Cancel',
          onClick: onClose,
        },
        {
          label: 'Confirm',
          onClick: cancelSubscription,
        },
      ]}
    />
  );
};

export default CancelSubscriptionModal;
