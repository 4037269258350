import React, { useMemo } from 'react';
import {
  styled,
  SvgIcon,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { ReactComponent as QuestionCircleIcon } from '@assets/icons/common/QuestionCircle.svg';
import { bluegrey, secondary } from '@theme/colors';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    fontWeight: theme.typography.body2.fontWeight,
    boxShadow: theme.shadows[1],
    maxWidth: 315,
    borderRadius: '4px',
    padding: '0.5rem',
  },
}));

export const QuestionTooltip = styled(
  ({
    title,
    placement = 'top-start',
    className,
    color,
    size,
    sx,
  }: Pick<TooltipProps, 'placement' | 'sx'> & {
    title: NonNullable<React.ReactNode>;
    color?: 'secondary' | 'bluegrey';
    size?: string | number;
    className?: string;
  }) => {
    const iconColor = useMemo<string>(() => {
      switch (color) {
        case 'bluegrey':
          return bluegrey[500];
        default:
          return secondary[500];
      }
    }, [color]);
    const iconBackground = useMemo<string | undefined>(() => {
      switch (color) {
        case 'bluegrey':
          return bluegrey[200];
        default:
          return undefined;
      }
    }, [color]);
    return (
      <Tooltip
        title={title}
        classes={{ popper: className }}
        placement={placement}
        sx={sx}
      >
        <SvgIcon
          component={QuestionCircleIcon}
          shapeRendering="geometricPrecision"
          sx={{
            width: size || '1.125rem',
            height: size || '1.125rem',
            color: iconColor,
            cursor: 'pointer',
            '> circle': {
              fill: iconBackground,
            },
          }}
        />
      </Tooltip>
    );
  },
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    fontWeight: theme.typography.body2.fontWeight,
    boxShadow: theme.shadows[1],
    maxWidth: 315,
    borderRadius: '4px',
    padding: '1rem',
  },
}));
