import React from 'react';
import {
  autocompleteClasses,
  Button,
  Divider,
  FormLabel,
  inputAdornmentClasses,
  InputBase,
  inputBaseClasses,
  inputClasses,
  Popper,
  selectClasses,
  styled,
  svgIconClasses,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';

export const UsagePopper = styled(Popper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: '4px',
  boxShadow: theme.shadows[4],
  minWidth: '300px',
  zIndex: theme.zIndex.modal,
  backgroundColor: '#fff',
  marginTop: '8px !important',
}));

export const UsageFilterInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  borderRadius: '4px',
  backgroundColor: '#fff',
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  border: '1px solid #a0a1b2',
  [`> .${inputBaseClasses.input}`]: {
    ...theme.typography.body2,
    padding: '8px',
  },
  [`&:has(> .${inputBaseClasses.input}:focus)`]: {
    borderColor: '#3d3d99',
    boxShadow: '0 0 0 1px inset #3d3d99',
  },
  [`> .${inputAdornmentClasses.root}`]: {
    color: theme.palette.tertiary.main,
    height: '16px',
    maxHeight: '16px',
    marginLeft: '16px',
    marginRight: 0,
    [`> .${svgIconClasses.root}`]: {
      width: '16px',
      height: '16px',
    },
  },
}));

export const UsageSimpleSelect = styled(TextField)(({ theme }) => ({
  [`> .${inputClasses.root}`]: {
    ...theme.typography.body2,
    height: '20px',
    minHeight: '20px',
    padding: 0,
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    [`> .${selectClasses.select}`]: {
      padding: '4px 8px',
      paddingRight: '26px',
    },
    [`> .${inputClasses.input}`]: {
      '&:focus': {
        background: 'none',
      },
    },
    [`> .${svgIconClasses.root}`]: {
      width: '16px',
      height: '16px',
      top: 'calc(50% - 8px)',
      right: '6px',
      color: theme.palette.text.primary,
    },
  },
}));

export const UsageFilterButton = styled(Button)({
  minWidth: 0,
  padding: '6px 12px',
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 500,
});

export const UsageDivider = styled(Divider)({
  borderBottomWidth: '1px',
  margin: '0 !important',
});

const AutocompletePopper = styled('div')(({ theme }) => ({
  width: '100% !important',
  [`& .${autocompleteClasses.paper}`]: {
    ...theme.typography.body2,
    boxShadow: 'none',
    border: 'none',
    borderRadius: 0,
    margin: 0,
    color: 'inherit',
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: '#fff',
    padding: 0,
    maxWidth: '560px',
    maxHeight: '400px',
    overflowY: 'auto',
    [`& .${autocompleteClasses.option}`]: {
      display: 'flex',
      minHeight: 'auto',
      alignItems: 'center',
      padding: '16px',
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
      '&.Mui-focused': {
        background: 'none',
      },
      '&:not(:last-of-type)': {
        borderBottom: '1px solid #f0f0f7',
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

interface UsagePopperComponentProps {
  open: boolean;
  anchorEl?: any;
  disablePortal?: boolean;
}

export function UsagePopperComponent({
  open,
  anchorEl,
  disablePortal,
  ...props
}: UsagePopperComponentProps) {
  return <AutocompletePopper {...props} />;
}
UsagePopperComponent.defaultProps = {
  anchorEl: undefined,
  disablePortal: undefined,
};

export const UsageTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    ...theme.typography.body2,
    maxWidth: '211px',
    backgroundColor: '#17171a',
    padding: '4px 8px',
    borderRadius: '2px',
    boxShadow: '0 1px 2px 0 #8A8B9966',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#17171a',
  },
}));

export const FieldLabel = styled(FormLabel)({
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 400,
  marginLeft: '4px',
  marginBottom: '4px',
});
