import React from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { useResetStorage } from '@hooks/common';
import { ROUTES } from '@routes';
import { ReactComponent as CCLogo } from '@assets/images/cc_logo.svg';
import { ReactComponent as CCLogoAuth } from '@assets/images/cc_logo_auth.svg';
import { ReactComponent as AuthBackground } from '@assets/images/bg_auth_bar.svg';
import { LanguageSelectorPublic } from '@components/common';
import { primary } from '@theme/colors';

const FeatureTypography = React.memo(({ label }: { label: string }) => (
  <Stack direction="row" spacing={2}>
    <CheckIcon sx={{ color: '#fff' }} />
    <Typography variant="subtitle1">{label}</Typography>
  </Stack>
));

const SignInContent = React.memo(() => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const title = t('banner.signIn.title', { returnObjects: true });
  const featureList = t('banner.features', {
    returnObjects: true,
  });
  return (
    <>
      <Typography
        variant="h3"
        sx={{
          '> svg': {
            verticalAlign: 'middle',
          },
        }}
      >
        {Boolean(title[0].length) && <span>{title[0]} </span>}
        <CCLogoAuth />
        {Boolean(title[1].length) && <span> {title[1]}</span>}
      </Typography>
      <Typography variant="subtitle1" mt={2} mb={6}>
        {t('banner.signIn.desc')}
      </Typography>
      <Button
        size="large"
        onClick={() => navigate(ROUTES.AUTH.SIGNUP, { replace: true })}
        sx={{
          bgcolor: '#fff',
          color: primary[600],
          '&:hover': {
            backgroundColor: '#eee',
          },
        }}
      >
        {t('banner.signIn.cta')}
      </Button>
      <Stack spacing={2} mt={6}>
        {featureList.map((item: string) => (
          <FeatureTypography key={item} label={item} />
        ))}
      </Stack>
    </>
  );
});

const SignUpContent = React.memo(() => {
  const { t } = useTranslation('auth');
  const title = t('banner.signUp.title', { returnObjects: true });
  const featureList = t('banner.features', {
    returnObjects: true,
  });
  return (
    <>
      <Typography
        variant="h3"
        sx={{
          '> svg': {
            verticalAlign: 'middle',
          },
        }}
      >
        {Boolean(title[0].length) && <span>{title[0]} </span>}
        <CCLogoAuth />
        {Boolean(title[1].length) && <span> {title[1]}</span>}
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 3 }}>
        {t('banner.signUp.desc')}
      </Typography>
      <Box mt={6}>
        {featureList.map((item: string) => (
          <FeatureTypography key={item} label={item} />
        ))}
      </Box>
    </>
  );
});

const AuthLayout: React.FC = () => {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  useResetStorage();
  return (
    <Box display="flex">
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        height="100vh"
        bgcolor="#fff"
        flex="1 0 60%"
        zIndex={1}
      >
        <Box position="absolute" top={0} left={0} ml={3} mt={3}>
          <SvgIcon
            component={CCLogo}
            viewBox="0 0 460 120"
            sx={{ width: '180px', height: '46.96px' }}
          />
        </Box>
        <Box
          display="flex"
          flexGrow="1"
          justifyContent="center"
          alignItems="center"
        >
          <Container maxWidth="xs">
            <Outlet />
          </Container>
        </Box>
        <Box display="flex" justifyContent="space-between" px={4} py={2}>
          <Box>
            <Typography variant="footer" color="textSecondary">
              © {new Date().getFullYear()}, Grumatic, Inc. All rights reserved.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <LanguageSelectorPublic />
            <Link
              href="https://www.grumatic.com/privacy-policy"
              target="_blank"
              variant="footer"
              color="textSecondary"
              underline="none"
              ml={2}
            >
              {t('label.privacyPolicy')}
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        display={{ lg: 'flex', xs: 'none' }}
        flex="1 0 40%"
        alignItems="center"
        bgcolor="#43008C"
      >
        <Box color="white" zIndex={1} pl={13}>
          {pathname === '/signin' ? <SignInContent /> : <SignUpContent />}
        </Box>
        <Box position="absolute" height="100vh" top={0} right={0}>
          <SvgIcon
            component={AuthBackground}
            viewBox="0 0 790 1080"
            sx={{ width: 'auto', height: '100%', zIndex: 50 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLayout;
