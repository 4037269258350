import { useMutation } from 'react-query';
import { IntegrationService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useMutation({
    mutationKey: QUERY_KEY.SETTINGS.INTEGRATION.VERIFY_DATADOG,
    mutationFn: (variables: { APIKey: string; AppKey: string }) =>
      IntegrationService.verifyDatadog(variables.APIKey, variables.AppKey),
  });
