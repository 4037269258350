import React, { ReactNode } from 'react';
import {
  Box,
  experimental_sx as sx,
  Grid,
  Stack,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import _ from 'lodash';
import { bluegrey } from '@theme/colors';
import { useTranslation } from 'react-i18next';

export const HighlightedTypography = styled(Typography)`
  > span {
    background-color: #f0f0f7;
    padding: 4px;
  }
`;

export const TypoWithLink = styled(Typography)(({ theme }) => ({
  '> a': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}));

export const TooltipTypography = styled(Typography)(({ theme }) => ({
  '> span': {
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: theme.typography.subtitle2.lineHeight,
    fontWeight: theme.typography.subtitle2.fontWeight,
  },
}));

export const LinkTypography = styled(Typography)(() => ({
  fontSize: 'inherit',
  lineHeight: 'inherit',
  fontWeight: 500,
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const CursorTypography = styled(Typography)(
  sx({
    cursor: 'pointer',
  }),
);

const Table = styled('table')`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`;
const TRow = styled('tr')`
  border-collapse: collapse;
  > td:not(:first-of-type) {
    border-left: 2px solid #f0f0f7;
  }
`;
const TLabel = styled('td')(
  sx({
    width: '200px',
    borderRight: '2px solid #f0f0f7',
    color: 'text.primary',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    p: 2,
  }),
);
const TValue = styled('td')(
  sx({
    color: 'text.primary',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    p: 2,
  }),
);
const SimpleTypoTableComponent: React.FC<{
  data: Array<{ label: NonNullable<ReactNode>; value: any }>;
  background?: string;
  disableBorder?: boolean;
  disableOutline?: boolean;
}> = ({ data, background, disableBorder, disableOutline }) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Table
      sx={{
        border: disableBorder || disableOutline ? 'none' : '1px solid #f0f0f7',
      }}
    >
      <tbody>
        {isSmall ? (
          <>
            {data.map((chunk, idx) => (
              <TRow
                key={`simple_typo_tr_xs_${idx.toString()}`}
                sx={{
                  borderTop:
                    disableBorder || (disableOutline && idx === 0)
                      ? 'none'
                      : '1px solid #f0f0f7',
                  borderBottom:
                    disableBorder || (disableOutline && idx === data.length - 1)
                      ? 'none'
                      : '1px solid #f0f0f7',
                }}
              >
                <TLabel>{chunk.label}</TLabel>
                <TValue sx={{ backgroundColor: background }}>
                  {chunk.value}
                </TValue>
              </TRow>
            ))}
          </>
        ) : (
          <>
            {_.chunk(data, 2).map((chunk, idx) => (
              <TRow
                key={`simple_typo_tr_md_${idx.toString()}`}
                sx={{
                  borderTop:
                    disableBorder || (disableOutline && idx === 0)
                      ? 'none'
                      : '1px solid #f0f0f7',
                  borderBottom:
                    disableBorder ||
                    (disableOutline && idx === _.chunk(data, 2).length - 1)
                      ? 'none'
                      : '1px solid #f0f0f7',
                }}
              >
                {chunk.map((item, cnt) => (
                  <React.Fragment
                    key={`simple_typo_td_${item.label}_${cnt.toString()}`}
                  >
                    <TLabel>{item.label}</TLabel>
                    <TValue
                      colSpan={chunk.length === 1 ? 3 : undefined}
                      sx={{ backgroundColor: background }}
                    >
                      {item.value}
                    </TValue>
                  </React.Fragment>
                ))}
              </TRow>
            ))}
          </>
        )}
      </tbody>
    </Table>
  );
};
SimpleTypoTableComponent.defaultProps = {
  background: undefined,
  disableBorder: false,
  disableOutline: false,
};
export const SimpleTypoTable = React.memo(SimpleTypoTableComponent);

export const SingleTypeTable = React.memo(
  ({ data }: { data: Array<{ label: string; value: any }> }) => (
    <Grid container>
      {data.map((chunk) => (
        <React.Fragment key={`${chunk.label}_${chunk.value.toString()}`}>
          <Grid item p={2} xs={4} borderRight="1px solid #F0F0F7">
            <Typography variant="body2">{chunk.label}</Typography>
          </Grid>
          <Grid
            item
            p={2}
            xs={8}
            borderLeft="1px solid #F0F0F7"
            bgcolor={bluegrey[50]}
          >
            <Typography variant="body2">{chunk.value}</Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  ),
);

const RecommendActionDividerComponent: React.FC<{
  TitleExtra?: React.ReactElement;
}> = ({ TitleExtra }) => {
  const { t } = useTranslation('optimization');
  return (
    <Box position="relative" py={1}>
      <Box
        position="absolute"
        left="50%"
        bgcolor="#fff"
        px={3}
        sx={{ transform: 'translate(-50%, -50%)' }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="subtitle2" color="#A0A1B2">
            {t('label.common.recommendedAction')}
          </Typography>
          {TitleExtra}
        </Stack>
      </Box>
      <Box border="1px solid #F0F0F7" />
    </Box>
  );
};
RecommendActionDividerComponent.defaultProps = {
  TitleExtra: undefined,
};
export const RecommendActionDivider = React.memo(
  RecommendActionDividerComponent,
);
