import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { bluegrey } from '@theme/colors';
import { experimental_sx as sx } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<AddRoundedIcon />} {...props} />
))(({ theme }) =>
  sx({
    flexDirection: 'row-reverse',
    border: `1px solid ${bluegrey[200]}`,
    borderRadius: '2px',
    px: 3,
    py: 2,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      marginLeft: theme.spacing(3),
    },
  }),
);

const AccordionDetails = styled(MuiAccordionDetails)(
  sx({
    border: `1px solid ${bluegrey[200]}`,
    borderRadius: '2px',
    backgroundColor: bluegrey[50],
    px: 6,
    py: 3,
  }),
);

export const GrmtAccordion: React.FC<{
  Title: string | React.ReactNode;
  openInitial?: boolean;
}> = ({ Title, children, openInitial }) => {
  const [expanded, setExpanded] = useState<boolean>(openInitial);
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded);
    },
    [],
  );
  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary>
        {typeof Title === 'string' && <Typography>{Title}</Typography>}
        {typeof Title !== 'string' && <>{Title}</>}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
GrmtAccordion.defaultProps = {
  openInitial: false,
};
