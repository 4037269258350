export default {
  GLOBAL: {
    APM_SOURCE: 'GLOBAL_APM_SOURCE',
    USER_ID: 'GLOBAL_USER_ID',
    COMPANY_ID: 'GLOBAL_COMPANY_ID',
    CALLBACK_URL: 'GLOBAL_CALLBACK_URL',
    API_START_DATE: 'API_START_DATE',
    API_END_DATE: 'API_END_DATE',
    SELECTED_ACCOUNT: 'GLOBAL_SELECTED_ACCOUNT',
    SELECTED_ACCOUNT_TMP: 'GLOBAL_SELECTED_ACCOUNT_TMP',
    HAS_CUR: 'GLOBAL_HAS_CUR',
  },
  ONBOARD: {
    CONNECT_STEP: 'CONNECT_AWS_STEP',
    DIAGNOSTIC_STEP: 'DIAGNOSTIC_STEP',
    LINKED_VIEW_STEP: 'LINKED_VIEW_STEP',
    EXTERNAL_STEP: 'ONBOARD_EXTERNAL_STEP',
    EXTERNAL_PAGE: 'ONBOARD_EXTERNAL_PAGE',
    EXTERNAL_TOKEN: 'ONBOARD_EXTERNAL_TOKEN',
    EXTERNAL_USER_ID: 'ONBOARD_EXTERNAL_USER_ID',
    EXTERNAL_COMPANY_ID: 'ONBOARD_EXTERNAL_COMPANY_ID',
    EXTERNAL_CREATED_UID: 'ONBOARD_EXTERNAL_CREATED_UID',
  },
  PLANNER: {
    STEP: 'PLANNER_SIMULATION_STEP',
    RECOMMEND: 'PLANNER_SIMULATION_RECOMMEND',
    DISCOUNT_PROGRAM: 'PLANNER_SIMULATION_DISCOUNT_PROGRAM',
    RI_SIMULATION: 'PLANNER_SIMULATION_RI_SIMULATION',
    SP_SIMULATION: 'PLANNER_SIMULATION_SP_SIMULATION',
    RI_FETCHED: 'PLANNER_SIMULATION_RI_FETCHED',
    SP_FETCHED: 'PLANNER_SIMULATION_SP_FETCHED',
    RI_TYPE: 'PLANNER_SIMULATION_RI_TYPE',
    RI_NUMBER: 'PLANNER_SIMULATION_RI_NUMBER',
    SP_COMMITMENT: 'PLANNER_SIMULATION_SP_COMMITMENT',
    SIMULATED: 'PLANNER_SIMULATION_SIMULATED',
    SIMULATION_REQUEST: 'PLANNER_SIMULATION_REQUEST',
    SIMULATION_RESPONSE: 'PLANNER_SIMULATION_RESPONSE',
  },
  PRACTICE: {
    ACTION: 'PRACTICE_ACTION',
    GENERAL: 'PRACTICE_GENERAL',
  },
  NOTIFICATION: {
    ORGANIZATION: 'NOTIFICATION_ORGANIZATION',
  },
  DETAILS: {
    EC2: 'DETAILS_EC2',
    RDS: 'DETAILS_RDS',
    S3: 'DETAILS_S3',
    EBS: 'DETAILS_EBS',
    EC2_TAB: 'DETAILS_EC2_TAB',
    EC2_DATE: 'DETAILS_EC2_DATE',
    RDS_TAB: 'DETAILS_RDS_TAB',
    RDS_DATE: 'DETAILS_RDS_DATE',
  },
  OPTIMIZATION: {
    EC2_CPU: 'OPTIMIZATION_EC2_CPU',
    EC2_MEM: 'OPTIMIZATION_EC2_MEM',
    RDS_CPU: 'OPTIMIZATION_RDS_CPU',
    RDS_MEM: 'OPTIMIZATION_RDS_MEM',
    TABLE_FILTER: 'OPTIMIZATION_TABLE_FILTER',
    RECOMMEND_PERIOD: 'OPTIMIZATION_RECOMMEND_PERIOD',
    RECOMMEND_CATEGORY: 'OPTIMIZATION_RECOMMEND_CATEGORY',
    EC2: {
      RECOMMEND: 'OPTIMIZATION_EC2_RECOMMEND',
    },
  },
  AWS: {
    CE_USAGE_FILTER: 'AWS_CE_USAGE_FILTER',
    CE_TAG_KEYS: 'AWS_CE_TAG_KEYS',
  },
} as const;
