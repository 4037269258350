import axios from '@utils/axios';
import { API_URL } from '@constants';

const getSubscriptions = async (): Promise<
  CCQueryResponse<SubscriptionType>
> => {
  const { data } = await axios.get(`${API_URL.PAYMENT}/get_all_subscriptions`);
  return data;
};

const deleteSubscription = async (
  subscriptionId: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.delete(
    `${API_URL.PAYMENT}/cancel_subscription/${subscriptionId}`,
  );
  return data;
};

export default {
  getSubscriptions,
  deleteSubscription,
};
