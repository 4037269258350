import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, SvgIcon, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCurrentInfo } from '@hooks/api/common';
import { ReactComponent as DatadogLogo } from '@assets/images/datadog_logo.svg';
import { useSnackbar } from '@hooks/common';
import { useDatadogForm } from '../SettingsIntegration.form';
import { useDatadog } from '../hooks';

const DatadogForm: React.FC = () => {
  const { currentCompany } = useCurrentInfo();
  const {
    hasDatadog,
    datadogItem,
    verifyDatadog,
    updateDatadog,
    createDatadog,
    deleteDatadog,
    isVerified,
    isVerifying,
    isCreating,
    isUpdating,
    isDeleting,
  } = useDatadog();
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const { showErrorSnackbar } = useSnackbar();
  const [verifiedAPIKey, setVerifiedAPIKey] = useState('');
  const [verifiedAppKey, setVerifiedAppKey] = useState('');
  const { initialValues, validationSchema } = useDatadogForm();
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (v) => {
      try {
        await verifyDatadog(v);
        setVerifiedAPIKey(v.APIKey);
        setVerifiedAppKey(v.AppKey);
      } catch (e) {
        showErrorSnackbar(t('integration.label.verifyFailed'));
      }
    },
  });

  const verifiedNotMatched = useMemo(
    () => values.APIKey !== verifiedAPIKey || values.AppKey !== verifiedAppKey,
    [values, verifiedAPIKey, verifiedAppKey],
  );

  const handleDatadog = useCallback(async () => {
    if (isValid && !verifiedNotMatched) {
      try {
        if (hasDatadog && datadogItem?.uid) {
          await updateDatadog({ ...values, uid: datadogItem.uid });
        } else {
          await createDatadog(values);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [values, isValid, verifiedNotMatched, hasDatadog, datadogItem?.uid]);

  useEffect(() => {
    if (hasDatadog && datadogItem) {
      setFieldValue('APIKey', datadogItem.APIKey);
      setFieldValue('AppKey', datadogItem.AppKey);
    } else {
      setFieldValue('APIKey', '');
      setFieldValue('AppKey', '');
    }
  }, [hasDatadog, datadogItem]);

  useEffect(() => {
    if (currentCompany?.id) {
      setFieldValue('companyId', currentCompany.id);
    }
  }, [currentCompany?.id]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="space-between" rowSpacing={3}>
        <Grid
          container
          item
          direction={{ lg: 'column', xs: 'row' }}
          justifyContent={{ lg: 'center', xs: 'flex-start' }}
          alignItems="center"
          rowSpacing={2}
          columnSpacing={2}
          lg={1.5}
          xs={12}
        >
          <Grid item>
            <SvgIcon
              component={DatadogLogo}
              sx={{ width: '62px', height: '62px' }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h3">Datadog</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          rowSpacing={3}
          lg={5}
          xs={12}
        >
          <Grid container item alignItems="center">
            <Grid item xs={2}>
              <Typography variant="caption">API Key</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                name="APIKey"
                type="text"
                inputMode="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.APIKey}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                disabled={isVerifying}
                // error={Boolean(touched.APIKey && errors.APIKey)}
                // helperText={
                //   Boolean(touched.APIKey && errors.APIKey) &&
                //   String(errors.APIKey)
                // }
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="center">
            <Grid item xs={2}>
              <Typography variant="caption">App Key</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                name="AppKey"
                type="text"
                inputMode="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.AppKey}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                disabled={isVerifying}
                error={Boolean(touched.AppKey && errors.AppKey)}
                helperText={
                  Boolean(touched.AppKey && errors.AppKey) &&
                  String(errors.AppKey)
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction={{ lg: 'column-reverse', xs: 'row' }}
          justifyContent={{ lg: 'center', xs: 'flex-end' }}
          rowSpacing={3}
          columnSpacing={2}
          lg={1.3}
          xs={12}
        >
          <Grid item>
            <LoadingButton
              type="button"
              variant="outlined"
              size="small"
              color="inherit"
              onClick={deleteDatadog}
              loading={isDeleting}
              disabled={!hasDatadog}
              fullWidth
            >
              {t('integration.button.disconnect')}
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              variant="outlined"
              size="small"
              loading={isVerifying}
              disabled={!isValid}
              fullWidth
            >
              {commonT('button.verify')}
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              type="button"
              variant="outlined"
              size="small"
              onClick={handleDatadog}
              loading={isCreating || isUpdating}
              disabled={!isValid || !isVerified || verifiedNotMatched}
              fullWidth
            >
              {t('integration.button.save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default React.memo(DatadogForm);
