/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { LegendWithPercent } from '@components/styled';
import { useCostComparison } from '@hooks/api/overview';
import { tertiary } from '@theme/colors';
import { ValueFormatter } from '@utils';
import NoChartData from '@components/chart/overview/NoChartData';
import { useAWSHelper } from '@hooks/helper';
import { QuestionTooltip } from '@components/styled/tooltip';
import BubbleChart from './BubbleChart';

// const SERVICE_KEYS: Array<OverviewSupportedService> = [
//   'Amazon Elastic Compute Cloud',
//   'Amazon Relational Database Service',
//   'Amazon Simple Storage Service',
//   'Others',
// ];

const CostComparisonChart: React.FC<{ current?: boolean }> = ({
  current = false,
}) => {
  const { t } = useTranslation('overview');
  const { t: commonT } = useTranslation('common');
  const { convertAWSProductCode } = useAWSHelper();
  const [chartData, setChartData] = useState<
    Array<{ Service: string; Cost: number; Percent: number }>
  >([]);
  const { data: response } = useCostComparison(current);
  useEffect(() => {
    if (response?.items?.length) {
      const result = response.items[0];
      const percents: Array<{
        Service: string;
        Cost: number;
        Percent: number;
      }> = [];
      if (!_.isEmpty(result.To)) {
        Object.keys(result.To).forEach((key) => {
          const from = Number(_.get(result.From, key));
          const to = Number(_.get(result.To, key));
          let percent = 0.0;
          if (from) {
            percent = ((to - from) / from) * 100.0;
          }
          percents.push({ Service: key, Cost: to, Percent: percent });
        });
      }
      setChartData(_.sortBy(percents, 'Cost').reverse());
    }
  }, [response?.items]);
  if (!response?.items?.length || !chartData.length) {
    return <NoChartData />;
  }
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      columns={20}
    >
      <Grid
        item
        display="flex"
        justifyContent="center"
        alignItems="center"
        xs={15}
      >
        <BubbleChart data={chartData} />
      </Grid>
      <Grid item xs={5}>
        <Box>
          <Typography
            variant="body2"
            color="secondary"
            borderLeft="2px solid #E52197"
            pl={2}
            mb={2}
          >
            {t('label.monthlyBilling.increased')}
          </Typography>
          <Box borderLeft="1px solid #E52197" ml={2} pl={2}>
            {chartData.filter((v) => v.Percent > 0.0).length ? (
              chartData
                .filter((v) => v.Percent > 0.0)
                .map((item) => (
                  <Box key={`cost_comparison_increased_${item.Service}`} mb={1}>
                    {item.Service === 'Others' ? (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mb={1}
                      >
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          {convertAWSProductCode(item.Service)}
                        </Typography>
                        <QuestionTooltip
                          title={
                            <Stack>
                              {response?.extras?.Services.map((v) => (
                                <span key={v}>{convertAWSProductCode(v)}</span>
                              ))}
                            </Stack>
                          }
                          placement="right"
                          color="bluegrey"
                          size="0.875rem"
                        />
                      </Stack>
                    ) : item.Service === 'Marketplace' ? (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mb={1}
                      >
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          {convertAWSProductCode(item.Service)}
                        </Typography>
                        <QuestionTooltip
                          title={
                            <Stack>
                              {response?.extras?.MarketPlaceItems.map((v) => (
                                <span key={v}>{convertAWSProductCode(v)}</span>
                              ))}
                            </Stack>
                          }
                          placement="right"
                          color="bluegrey"
                          size="0.875rem"
                        />
                      </Stack>
                    ) : (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="p"
                        mb={1}
                      >
                        {convertAWSProductCode(item.Service)}
                      </Typography>
                    )}
                    <LegendWithPercent
                      label={ValueFormatter.toCurrency(item.Cost)}
                      percent={item.Percent}
                    />
                  </Box>
                ))
            ) : (
              <Typography variant="caption" color="textSecondary">
                {commonT('label.none')}
              </Typography>
            )}
          </Box>
        </Box>
        <Box mt={5}>
          <Typography
            variant="body2"
            borderLeft="2px solid #6666CC"
            pl={2}
            mb={2}
            sx={{ color: tertiary[300] }}
          >
            {t('label.monthlyBilling.decreased')}
          </Typography>
          <Box borderLeft="1px solid #6666CC" ml={2} pl={2}>
            {chartData.filter((v) => v.Percent < 0.0).length ? (
              chartData
                .filter((v) => v.Percent < 0.0)
                .map((item) => (
                  <Box key={`cost_comparison_decreased_${item.Service}`} mb={1}>
                    {item.Service === 'Others' ? (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mb={1}
                      >
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          {convertAWSProductCode(item.Service)}
                        </Typography>
                        <QuestionTooltip
                          title={
                            <Stack>
                              {response?.extras?.Services.map((v) => (
                                <span key={v}>{convertAWSProductCode(v)}</span>
                              ))}
                            </Stack>
                          }
                          placement="right"
                          color="bluegrey"
                          size="0.875rem"
                        />
                      </Stack>
                    ) : item.Service === 'Marketplace' ? (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mb={1}
                      >
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          {convertAWSProductCode(item.Service)}
                        </Typography>
                        <QuestionTooltip
                          title={
                            <Stack>
                              {response?.extras?.MarketPlaceItems.map((v) => (
                                <span key={v}>{convertAWSProductCode(v)}</span>
                              ))}
                            </Stack>
                          }
                          placement="right"
                          color="bluegrey"
                          size="0.875rem"
                        />
                      </Stack>
                    ) : (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="p"
                        mb={1}
                      >
                        {convertAWSProductCode(item.Service)}
                      </Typography>
                    )}
                    <LegendWithPercent
                      label={ValueFormatter.toCurrency(item.Cost)}
                      percent={item.Percent}
                    />
                  </Box>
                ))
            ) : (
              <Typography variant="caption" color="textSecondary">
                {commonT('label.none')}
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
CostComparisonChart.propTypes = {
  current: PropTypes.bool,
};
CostComparisonChart.defaultProps = {
  current: false,
};

export default React.memo(CostComparisonChart);
