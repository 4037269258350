import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { StorageChart } from '@components/chart/optimization';
import moment from 'moment';
import { RecommendActionDivider } from '@components/styled';
import { IntelligenceCard } from '@components/styled/notification';
import { useTranslation } from 'react-i18next';
import {
  useEBSDetailsGeneral,
  useEBSDetailsSummary,
} from '@hooks/api/optimization/ebs';
import { bluegrey } from '@theme/colors';
import { byteToGib, ValueFormatter } from '@utils';
import { ChartCoverBox, NoAvailableData } from '@components/styled/noData';
import { STORAGE_DUMMY } from '@components/chart/chart.dummy';

const VolumeTableItem = React.memo(
  ({
    label,
    first,
    second,
  }: {
    label: string;
    first: string | number;
    second: string | number;
  }) => (
    <Grid container item md={4} xs={12} border="1px solid #F0F0F7">
      <Grid
        item
        display="flex"
        alignItems="center"
        xs={6}
        borderRight="1px solid #F0F0F7"
        p={2}
      >
        <Typography variant="body2">{label}</Typography>
      </Grid>
      <Grid item xs={6} bgcolor={bluegrey[50]}>
        <Typography variant="body2" p={2} borderBottom="1px solid #F0F0F7">
          {first}
        </Typography>
        <Typography variant="body2" p={2}>
          {second}
        </Typography>
      </Grid>
    </Grid>
  ),
);

const DetailsVolumeInformation: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');
  const { data: general } = useEBSDetailsGeneral();
  const { data } = useEBSDetailsSummary();
  if (!data?.items?.length || !general?.items?.length) {
    return <NoAvailableData />;
  }
  const { AnnualUsage, UsageDetails, RecommendActions } = data.items[0];
  const { Region } = general.items[0];
  return (
    <>
      <Typography color="textSecondary" mb={1}>
        EBS {t('label.common.storage')} {t('label.common.cost')}
      </Typography>
      <ChartCoverBox isEmpty={Boolean(!AnnualUsage?.length)}>
        <StorageChart
          data={AnnualUsage?.length ? AnnualUsage : STORAGE_DUMMY}
          xAxis={{
            dataKey: 'Date',
            interval: 0,
            textAnchor: AnnualUsage.length > 15 ? 'end' : 'middle',
            angle: AnnualUsage.length > 15 ? -45 : undefined,
            height: AnnualUsage.length > 15 ? 45 : undefined,
            tickFormatter: (v) => moment(v).format('YYYY MMM'),
          }}
          chartInfo={{
            area: {
              dataKey: 'Cost',
              name: `${t('label.common.storage')} ${t('label.common.cost')}`,
            },
          }}
        />
      </ChartCoverBox>
      <Typography color="textSecondary" mt={8} mb={2}>
        {t('label.volume.usageDetail')} (
        {commonT('label.lastDays', { count: 30 })})
      </Typography>
      <Grid container>
        <VolumeTableItem
          label="IOPS"
          first={`${t('label.common.avg')}: ${ValueFormatter.toFixed(
            UsageDetails.IOPSAverage,
          )}`}
          second={`${t('label.common.max')}: ${ValueFormatter.toFixed(
            UsageDetails.IOPSMax,
          )}`}
        />
        <VolumeTableItem
          label={`${t('label.header.utilization')} (GiB)`}
          first={`${t('label.common.avg')}: ${ValueFormatter.toLocaleString(
            byteToGib(UsageDetails.UtilizationAverage),
          )}`}
          second={`${t('label.common.max')}: ${ValueFormatter.toLocaleString(
            byteToGib(UsageDetails.UtilizationMax),
          )}`}
        />
        <VolumeTableItem
          label={`${t('label.volume.throughput')} (MiB/s)`}
          first={`${t('label.common.avg')}: ${ValueFormatter.toLocaleString(
            UsageDetails.BandWidthAverage,
          )}`}
          second={`${t('label.common.max')}: ${ValueFormatter.toLocaleString(
            UsageDetails.BandWidthMax,
          )}`}
        />
      </Grid>
      {Boolean(RecommendActions?.length) && (
        <Box mt={12}>
          <RecommendActionDivider />
          <Stack spacing={3} mt={5}>
            {RecommendActions.map((item) => (
              <IntelligenceCard key={item.UID} item={item} region={Region} />
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default DetailsVolumeInformation;
