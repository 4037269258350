import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, Paper, RadioGroup } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useSimulationGeneral } from '@hooks/api/planner';
import { useAWSHelper } from '@hooks/helper';
import {
  SimulationFlatTable,
  SimulationHeader,
  SimulationTable,
} from '@pages/private/planner/simulation/Simulation.styled';
import { CoverageChart } from '@components/chart/optimization';
import { SingleTypeTable } from '@components/styled';
import { getPercentageProportion, ValueFormatter } from '@utils';
import { PlannerComparisonPaper } from '@pages/private/planner/Planner.styled';
import {
  selectedRecommendState,
  simulationStepState,
} from '../Simulation.atoms';

const CoverageHourText: React.FC<{ Cost: number; Unit: number }> = ({
  Cost,
  Unit,
}) => {
  const { t } = useTranslation('planner');
  return (
    <>
      {t('label.perHour', { value: ValueFormatter.toCurrency(Cost) })}
      <br />(
      {t('label.unitPerHour', { value: ValueFormatter.toLocaleString(Unit) })})
    </>
  );
};

const UsageStep: React.FC = () => {
  const { instanceId } = useParams();
  const { t } = useTranslation('optimization');
  const { t: plannerT } = useTranslation('planner');
  const { t: commonT } = useTranslation('common');
  const navigate = useNavigate();
  const setActive = useSetRecoilState(simulationStepState);
  const goToNextStep = useCallback(() => {
    setActive(1);
  }, [setActive]);
  const { getAWSRegionName } = useAWSHelper();
  const [option, setOption] = useRecoilState(selectedRecommendState);
  useEffect(() => {
    setOption('None');
  }, []);
  const { data } = useSimulationGeneral(instanceId ?? '');
  if (!data?.items?.length) {
    return null;
  }
  const FamilyCoverage = data.items[0].FamilyCoverage.slice(-6);
  return (
    <>
      <SimulationHeader Title={plannerT('label.selectedInstance')} mb={3} />
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Paper>
            <SingleTypeTable
              data={[
                {
                  label: t('label.common.instanceFamily'),
                  value: data.items[0].SelectedInstance.InstanceFamily,
                },
                {
                  label: plannerT('label.totalInstance'),
                  value: data.items[0].SelectedInstance.TotalInstanceCount,
                },
                {
                  label: plannerT('label.totalCompute'),
                  value: ValueFormatter.toLocaleString(
                    data.items[0].SelectedInstance.TotalComputeHours,
                  ),
                },
              ]}
            />
          </Paper>
        </Grid>
        <Grid item md={6} xs={12}>
          <Paper>
            <SingleTypeTable
              data={[
                {
                  label: t('label.common.region'),
                  value: getAWSRegionName(
                    data.items[0].SelectedInstance.Region,
                  ),
                },
                {
                  label: plannerT('aws.platform'),
                  value: data.items[0].SelectedInstance.Platform,
                },
                {
                  label: plannerT('aws.tenancy'),
                  value: data.items[0].SelectedInstance.Tenancy,
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
      <SimulationHeader
        Title={plannerT('label.instanceCoverage')}
        optional={`(${commonT('label.days', { count: 30 })})`}
        mt={12}
        mb={3}
      />
      <Paper>
        <SimulationTable
          title={t('label.header.purchasing')}
          header={[
            t('label.common.od'),
            t('label.common.ri'),
            t('label.common.sp'),
          ]}
          body={[
            {
              label: commonT('label.hourly'),
              items: [
                <CoverageHourText
                  key={`od_hourly_cost_${instanceId}`}
                  Cost={data.items[0].InstanceCoverage.OndemandCostPerHour}
                  Unit={data.items[0].InstanceCoverage.OndemandUnitPerHour}
                />,
                <CoverageHourText
                  key={`ri_hourly_cost_${instanceId}`}
                  Cost={data.items[0].InstanceCoverage.ReservedCostPerHour}
                  Unit={data.items[0].InstanceCoverage.ReservedUnitPerHour}
                />,
                <CoverageHourText
                  key={`sp_hourly_cost_${instanceId}`}
                  Cost={data.items[0].InstanceCoverage.SavingsPlanCostPerHour}
                  Unit={data.items[0].InstanceCoverage.SavingsPlanUnitPerHour}
                />,
              ],
            },
            {
              label: commonT('label.monthly'),
              items: [
                <CoverageHourText
                  key={`od_monthly_cost_${instanceId}`}
                  Cost={
                    data.items[0].InstanceCoverage.OndemandCostPerHour * 720
                  }
                  Unit={
                    data.items[0].InstanceCoverage.OndemandUnitPerHour * 720
                  }
                />,
                <CoverageHourText
                  key={`ri_monthly_cost_${instanceId}`}
                  Cost={
                    data.items[0].InstanceCoverage.ReservedCostPerHour * 720
                  }
                  Unit={
                    data.items[0].InstanceCoverage.ReservedUnitPerHour * 720
                  }
                />,
                <CoverageHourText
                  key={`sp_monthly_cost_${instanceId}`}
                  Cost={
                    data.items[0].InstanceCoverage.SavingsPlanCostPerHour * 720
                  }
                  Unit={
                    data.items[0].InstanceCoverage.SavingsPlanUnitPerHour * 720
                  }
                />,
              ],
            },
            {
              label: t('label.common.cost'),
              items: [
                ValueFormatter.toCurrency(
                  data.items[0].InstanceCoverage.OndemandCost,
                ) ?? '',
                ValueFormatter.toCurrency(
                  data.items[0].InstanceCoverage.ReservedCost,
                ) ?? '',
                ValueFormatter.toCurrency(
                  data.items[0].InstanceCoverage.SavingsPlanCost,
                ) ?? '',
              ],
            },
          ]}
        />
      </Paper>
      <SimulationHeader
        Title={plannerT('label.familyCoverage')}
        optional={`(${commonT('label.months', { count: 6 })})`}
        mt={12}
        mb={3}
      />
      <Box mb={5}>
        <CoverageChart
          data={FamilyCoverage}
          xAxis={{
            dataKey: 'Date',
            tickFormatter: (value: any) => moment(value).format('YYYY MMM'),
          }}
          chartInfo={[
            {
              dataKey: 'Ondemand',
              name: t('label.common.od'),
            },
            {
              dataKey: 'Reserved',
              name: t('label.common.ri'),
            },
            {
              dataKey: 'SavingsPlan',
              name: t('label.common.sp'),
            },
          ]}
        />
      </Box>
      <Paper>
        <SimulationTable
          title={plannerT('label.coveragePerMonth')}
          header={FamilyCoverage.map((v) => moment(v.Date).format('YYYY MMM'))}
          body={[
            {
              label: t('label.common.od'),
              items: FamilyCoverage.map((v) =>
                t('label.common.h', {
                  value: ValueFormatter.toLocaleString(v.Ondemand),
                }),
              ),
            },
            {
              label: t('label.common.ri_coverage'),
              items: FamilyCoverage.map((v) => (
                <>
                  {t('label.common.h', {
                    value: ValueFormatter.toLocaleString(v.Reserved),
                  })}
                  <br />
                  {t('label.header.coverage')}:{' '}
                  {ValueFormatter.toPercent(
                    getPercentageProportion(v.Reserved, v.TotalUsage),
                  )}
                </>
              )),
            },
            {
              label: t('label.common.sp_coverage'),
              items: FamilyCoverage.map((v) => (
                <>
                  {t('label.common.h', {
                    value: ValueFormatter.toLocaleString(v.SavingsPlan),
                  })}
                  <br />
                  {t('label.header.coverage')}:{' '}
                  {ValueFormatter.toPercent(
                    getPercentageProportion(v.SavingsPlan, v.TotalUsage),
                  )}
                </>
              )),
            },
          ]}
        />
      </Paper>
      <SimulationHeader
        Title={plannerT('label.uncoveredInstances')}
        mt={12}
        mb={3}
      />
      <Paper>
        <SimulationFlatTable
          header={[
            t('label.common.instanceType'),
            commonT('label.count'),
            t('label.common.od_cost'),
            plannerT('label.od_hours'),
            t('label.header.utilization'),
          ]}
          body={Object.keys(data.items[0].UncoveredInstances).map((key) => ({
            items: [
              key,
              ValueFormatter.toLocaleString(
                data.items[0].UncoveredInstances[key].Count,
                0,
              ) ?? '',
              plannerT('label.perHour', {
                value: ValueFormatter.toCurrency(
                  data.items[0].UncoveredInstances[key].CostPerHour,
                ),
              }),
              commonT('label.hours', {
                count: data.items[0].UncoveredInstances[key].UsageAmount,
              }),
              ValueFormatter.toPercent(
                data.items[0].UncoveredInstances[key].Utilization,
              ) ?? '',
            ],
          }))}
        />
      </Paper>
      {!_.isEmpty(data.items[0].Recommendation) && (
        <>
          <SimulationHeader
            Title={plannerT('label.recommendation')}
            mt={12}
            mb={3}
          />
          <RadioGroup
            value={option}
            onChange={(e, v) => setOption(v as 'Aggressive' | 'Passive')}
          >
            <Grid container columnSpacing={3}>
              <Grid item md={6} xs={12}>
                <PlannerComparisonPaper
                  type="Aggressive"
                  item={data.items[0].Recommendation.Aggressive}
                  selected={option}
                  enableRadio
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <PlannerComparisonPaper
                  type="Passive"
                  item={data.items[0].Recommendation.Passive}
                  selected={option}
                  enableRadio
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </>
      )}
      <Box display="flex" justifyContent="flex-end" mt={5}>
        <Button color="inherit" size="large" onClick={() => navigate(-1)}>
          {plannerT('button.backInstance')}
        </Button>
        <Button
          size="large"
          onClick={goToNextStep}
          disabled={
            !_.isEmpty(data.items[0].Recommendation) && option === 'None'
          }
          sx={{ ml: 3 }}
        >
          {plannerT('button.run')}
        </Button>
      </Box>
    </>
  );
};

export default UsageStep;
