import React, { useMemo, useState } from 'react';
import { Box, experimental_sx as sx, styled, Typography } from '@mui/material';
import {
  TabsListUnstyled,
  TabsUnstyled,
  TabUnstyled,
  tabUnstyledClasses,
} from '@mui/base';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { usePossibleSavings } from '@hooks/api/overview';
import { useBestPracticeHelper } from '@hooks/helper';
import { GrmtTableColumnInfo, SimpleTable } from '@components/table';
import { DetailLinkIcon } from '@components/table/table.styled';
import { NoAvailableData } from '@components/styled/noData';
import { tertiary } from '@theme/colors';
import { ValueFormatter } from '@utils';

const SavingsTabs = styled(TabsUnstyled)(
  sx({
    display: 'flex',
  }),
);

const SavingsTabsList = styled(TabsListUnstyled)(
  sx({
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '240px',
  }),
);

const SavingsTab = styled(TabUnstyled)(
  sx({
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 4,
    py: 5,
    background: '#fff',
    border: 'none',
    cursor: 'pointer',
    borderRight: '1px solid #F0F0F7',
    ':not(:last-child)': {
      borderBottom: '1px solid #F0F0F7',
    },
    '& .costSummary': {
      color: tertiary[500],
    },
    [`&.${tabUnstyledClasses.selected}`]: {
      borderRight: '1px solid transparent',
      '& .costSummary': {
        color: 'primary.main',
      },
    },
  }),
);

const PossibleSavings: React.FC = () => {
  const [activeTab, setActiveTab] = useState<PossibleSavingsKey>('Total');
  const { t } = useTranslation('overview');
  const { t: optimizationT } = useTranslation('optimization');
  const { getCategoryName, getDetailsRoute } = useBestPracticeHelper();
  const { data } = usePossibleSavings();
  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: optimizationT('label.intelligence.category'),
        accessor: 'Category' as const,
        width: 200,
        maxWidth: 200,
        Cell: ({ value }) => getCategoryName(value),
      },
      {
        Header: optimizationT('label.common.resourceId'),
        accessor: 'ResourceId' as const,
        width: 230,
        maxWidth: 230,
      },
      {
        Header: optimizationT('label.common.service'),
        accessor: 'ServiceName' as const,
        width: 170,
        maxWidth: 170,
      },
      {
        Header: optimizationT('label.common.savings'),
        accessor: 'MonthlyCostSaving' as const,
        align: 'right',
        width: 185,
        maxWidth: 185,
        Cell: ({ value }) => ValueFormatter.toCurrency(value),
      },
      {
        Header: optimizationT('label.common.details'),
        align: 'center',
        width: 90,
        maxWidth: 90,
        Cell: ({ row }) => {
          const path = getDetailsRoute(row.original);
          if (path) {
            return <DetailLinkIcon to={path} requireCUR />;
          }
          return null;
        },
      },
    ],
    [optimizationT, getCategoryName, getDetailsRoute],
  );
  return (
    <SavingsTabs
      value={activeTab}
      onChange={(e, v) => setActiveTab(String(v) as PossibleSavingsKey)}
      orientation="vertical"
    >
      <SavingsTabsList aria-orientation="vertical">
        <SavingsTab value="Total">
          <Typography variant="body2" align="left">
            {t('tab.possibleSavings.total')}
          </Typography>
          <Typography className="costSummary" variant="subtitle2">
            {ValueFormatter.toCurrency(data?.extras?.Total)}
          </Typography>
        </SavingsTab>
        <SavingsTab value="Utilization">
          <Typography variant="body2" align="left">
            {t('tab.possibleSavings.utilization')}
          </Typography>
          <Typography className="costSummary" variant="subtitle2">
            {ValueFormatter.toCurrency(data?.extras?.Utilization)}
          </Typography>
        </SavingsTab>
        <SavingsTab value="PurchasingOptions">
          <Typography
            variant="body2"
            align="left"
            dangerouslySetInnerHTML={{
              __html: t('tab.possibleSavings.purchasing'),
            }}
          />
          <Typography className="costSummary" variant="subtitle2">
            {ValueFormatter.toCurrency(data?.extras?.PurchasingOptions)}
          </Typography>
        </SavingsTab>
        <SavingsTab value="UnusedResources">
          <Typography
            variant="body2"
            align="left"
            dangerouslySetInnerHTML={{
              __html: t('tab.possibleSavings.unused'),
            }}
          />
          <Typography className="costSummary" variant="subtitle2">
            {ValueFormatter.toCurrency(data?.extras?.UnusedResources)}
          </Typography>
        </SavingsTab>
        <SavingsTab value="Others">
          <Typography variant="body2" align="left">
            {t('tab.possibleSavings.others')}
          </Typography>
          <Typography className="costSummary" variant="subtitle2">
            {ValueFormatter.toCurrency(data?.extras?.Others)}
          </Typography>
        </SavingsTab>
      </SavingsTabsList>
      <Box flexGrow={1} p={7} minHeight={624} role="tabpanel">
        {data?.items && _.get(data.items, activeTab)?.length ? (
          <SimpleTable
            columnInfo={columnInfo}
            data={_.get(data.items, activeTab)}
            LastUpdate={data.extras.LatestUpdate}
          />
        ) : (
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <NoAvailableData variant="h3" />
          </Box>
        )}
      </Box>
    </SavingsTabs>
  );
};

export default React.memo(PossibleSavings);
