import axios from '@utils/axios';
import { API_URL, AWS_REGION } from '@constants';

const requestDiagnostic = async (id: string, regions: string[]) => {
  const { data } = await axios.post(API_URL.DIAGNOSTIC, {
    AWSAccountUID: id,
    Region: regions.length ? regions : AWS_REGION,
  });
  return data;
};

const getDiagnostic = async (
  companyId: string,
): Promise<
  CCQueryResponse<
    DiagnosticResponse,
    {
      Accounts: Array<string>;
      Region: Array<SupportedAWSRegion>;
      TotalPossibleSavings: number;
    }
  >
> => {
  const { data } = await axios.get(`${API_URL.DIAGNOSTIC}/${companyId}`);
  return data;
};

export default {
  requestDiagnostic,
  getDiagnostic,
};
