/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQueryClient } from 'react-query';
import {
  decryptLocalStorage,
  encryptLocalStorage,
  isAxiosError,
  isExpired,
} from '@utils';
import { ROUTES } from '@routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { callbackUrlState } from '@atoms/global';
import { useSnackbar } from '@hooks/common/index';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { API_URL, GLOBAL } from '@constants';
import moment from 'moment';
import axios from '@utils/axios';

export default () => {
  const { t } = useTranslation('toast');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const setCallbackUrl = useSetRecoilState(callbackUrlState);
  const { showErrorSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const retryHandler = useCallback(
    (failureCount: number, error: any) => {
      if (isExpired(error)) {
        localStorage.removeItem(GLOBAL.REFRESH_TOKEN_EXPIRED);
        localStorage.removeItem(GLOBAL.REFRESH_TOKEN);
        localStorage.removeItem(GLOBAL.ACCESS_TOKEN_EXPIRED);
        localStorage.removeItem(GLOBAL.ACCESS_TOKEN);
        setCallbackUrl(pathname);
        queryClient.cancelQueries();
        queryClient.cancelMutations();
        queryClient.clear();
        showErrorSnackbar(
          error.response.status === 401
            ? t('error.token')
            : t('error.token_user'),
        );
        navigate(ROUTES.AUTH.SIGNIN);
      }
      return false;
    },
    [queryClient, navigate, pathname, t],
  );
  const refreshTokenHandler = useCallback(async (data: any, error: any) => {
    if (!error) {
      const accessToken = localStorage.getItem(GLOBAL.ACCESS_TOKEN);
      const accessExpired = localStorage.getItem(GLOBAL.ACCESS_TOKEN_EXPIRED);
      const refreshToken = decryptLocalStorage(GLOBAL.REFRESH_TOKEN);
      const refreshExpired = decryptLocalStorage(GLOBAL.REFRESH_TOKEN_EXPIRED);
      if (accessToken && accessExpired && refreshToken && refreshExpired) {
        const exp = moment(Number(accessExpired) * 1000);
        if (exp.diff(moment(), 'milliseconds') < 345600000) {
          try {
            const { data: response } = await axios.post<
              CCQueryResponse<LoginResponse>
            >(
              `${API_URL.USERS}/token_refresh`,
              {
                refresh_token: refreshToken,
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              },
            );
            if (response?.items?.length) {
              localStorage.setItem(
                GLOBAL.ACCESS_TOKEN,
                response.items[0].access_token,
              );
              localStorage.setItem(
                GLOBAL.ACCESS_TOKEN_EXPIRED,
                response.items[0].access_token_expired.toString(),
              );
              encryptLocalStorage(
                GLOBAL.REFRESH_TOKEN,
                response.items[0].refresh_token,
              );
              encryptLocalStorage(
                GLOBAL.REFRESH_TOKEN_EXPIRED,
                response.items[0].refresh_token_expired.toString(),
              );
            }
          } catch (e) {
            if (isAxiosError(e) && e.response?.data?.message) {
              // eslint-disable-next-line default-case
              switch (e.response.data.message) {
                case 'Refresh token had expired.':
                  console.log('Refresh token expired');
              }
            }
          }
        }
      }
    }
  }, []);
  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        refetchOnWindowFocus: false,
        cacheTime: 60000,
        // retry: retryHandler,
        // onSettled: refreshTokenHandler,
      },
      mutations: {
        // retry: retryHandler,
        // onSettled: refreshTokenHandler,
      },
    });
  }, []);
};
