import React from 'react';
import { useRecoilValue } from 'recoil';
import { Box } from '@mui/material';
import { useGetUsage } from '@hooks/api/aws/ce';
import { MultipleUsageChart, SimpleUsageChart } from '@components/chart/report';
import { ceUsageState } from '@atoms/aws';

export default function UsageChart() {
  const filter = useRecoilValue(ceUsageState);
  const { data } = useGetUsage();
  if (!data?.items) {
    return null;
  }
  return (
    <Box mt="16px">
      {data.items[0].GroupBy === 'Cost' ? (
        <SimpleUsageChart
          data={data.items[0].Graph}
          Granularity={filter.Granuality}
        />
      ) : (
        <MultipleUsageChart
          data={data.items[0].Table}
          Granularity={filter.Granuality}
          GroupBy={filter.GroupBy}
          TotalCostByItems={data.items[0].TotalCostByItems}
        />
      )}
    </Box>
  );
}
