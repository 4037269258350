import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEC2RIUtilAutoComplete } from '@hooks/api/optimization/ec2';
import { useFilterFormik } from '@hooks/common';
import { useAWSHelper } from '@hooks/helper';
import {
  FilterGrid,
  FilterGridItem,
  FilterProps,
  FilterSelect,
  FilterWrapper,
} from '@components/filter';

type ComputeRIUtilFilterForm = {
  Region: Array<string>;
  InstanceType: Array<string>;
};

const useComputeRIUtilFilterForm =
  (): UseValidation<ComputeRIUtilFilterForm> => ({
    initialValues: {
      Region: [],
      InstanceType: [],
    },
    validationSchema: Yup.object({
      Region: Yup.array().of(Yup.string()),
      InstanceType: Yup.array().of(Yup.string()),
    }).defined(),
  });

const ComputeRIUtilFilter: React.FC<FilterProps> = ({
  onClose,
  onFilterChange,
  previousValues,
}) => {
  const { t } = useTranslation('optimization');
  const { getAWSRegionName } = useAWSHelper();
  const { data } = useEC2RIUtilAutoComplete();
  const { initialValues, validationSchema } = useComputeRIUtilFilterForm();
  const { values, handleArrayChange, handleBlur, handleSubmit } =
    useFilterFormik(
      {
        initialValues: previousValues || initialValues,
        validationSchema,
        onSubmit: (v) => {
          onFilterChange(v);
          if (onClose) {
            onClose();
          }
        },
      },
      data?.items,
    );
  if (!data?.items?.length) {
    return null;
  }
  const { Region, InstanceType } = data.items[0];
  return (
    <FilterWrapper onClose={onClose} onSubmit={handleSubmit}>
      <FilterGrid>
        <FilterGridItem label={t('label.common.region')}>
          <FilterSelect
            name="Region"
            value={values.Region}
            onChange={(e) => handleArrayChange('Region', e.target.value)}
            onBlur={handleBlur}
            renderValue={() =>
              values.Region.map((v: string) => getAWSRegionName(v)).join(', ')
            }
            selected={values.Region}
            candidate={Region}
            formatter={getAWSRegionName}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.instanceType')}>
          <FilterSelect
            name="InstanceType"
            value={values.InstanceType}
            onChange={(e) => handleArrayChange('InstanceType', e.target.value)}
            onBlur={handleBlur}
            renderValue={() => values.InstanceType.join(', ')}
            selected={values.InstanceType}
            candidate={InstanceType}
          />
        </FilterGridItem>
      </FilterGrid>
    </FilterWrapper>
  );
};

export default ComputeRIUtilFilter;
