import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Container, Link, Typography } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { SuspensePaper } from '@components/styled/suspense';
import { GuideBox, LanguageSelectorPublic } from '@components/common';
import { ROUTES } from '@routes';
import ExternalWelcome from './ExternalWelcome';
import ExternalSteps from './ExternalSteps';
import {
  externalCompanyIdState,
  externalPageState,
  externalStepState,
  externalTokenState,
  externalUidState,
  externalUserIdState,
} from './ExternalAccount.atoms';

const ExternalAccountPage: React.FC = () => {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const isPayer = pathname === ROUTES.EXTERNAL.MASTER;
  const [hasNext, setHasNext] = useRecoilState(externalPageState);
  const setCompanyId = useSetRecoilState(externalCompanyIdState);
  const setUserId = useSetRecoilState(externalUserIdState);
  const setToken = useSetRecoilState(externalTokenState);
  const setActive = useSetRecoilState(externalStepState);
  const setUid = useSetRecoilState(externalUidState);
  useEffect(() => {
    setHasNext(true);
    setActive(0);
    setCompanyId(undefined);
    setUserId(undefined);
    setToken(undefined);
    setUid(undefined);
    return () => {
      setActive(0);
      setCompanyId(undefined);
      setUserId(undefined);
      setToken(undefined);
      setUid(undefined);
    };
  }, []);
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Box component="main" flexGrow={1}>
        <Container maxWidth="xl">
          <SuspensePaper sx={{ mt: 11 }}>
            {hasNext && <ExternalWelcome isPayer={isPayer} />}
            {!hasNext && <ExternalSteps isPayer={isPayer} />}
          </SuspensePaper>
          <Box mt={6} mb={20}>
            <GuideBox external />
          </Box>
        </Container>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        bgcolor="white"
        px={4}
        py={2}
      >
        <Box>
          <Typography variant="footer" color="textSecondary">
            © {new Date().getFullYear()}, Grumatic, Inc. All rights reserved.
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <LanguageSelectorPublic />
          <Link
            href="https://www.grumatic.com/privacy-policy"
            target="_blank"
            variant="footer"
            color="textSecondary"
            underline="none"
            ml={2}
          >
            {t('label.privacyPolicy')}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ExternalAccountPage;
