import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { ServiceIcon, ServiceIconName } from '@components/common';

export const DialogIconHeader = React.memo(
  ({
    icon,
    label,
    extra,
  }: {
    icon: ServiceIconName;
    label: string;
    extra: string | undefined;
  }) => (
    <Box mb={3}>
      <Box display="flex" alignItems="center" mb={3}>
        <ServiceIcon name={icon} />
        <Typography variant="h2" ml={3}>
          {label}
        </Typography>
        {Boolean(extra) && (
          <Typography variant="subtitle2" color="primary" ml={3}>
            {extra}
          </Typography>
        )}
      </Box>
      <Divider />
    </Box>
  ),
);
DialogIconHeader.displayName = 'DialogIconHeader';
