import { useMutation } from 'react-query';
import { QUERY_KEY } from '@constants';
import { AuthService } from '@api/service';

export default () =>
  useMutation({
    mutationKey: QUERY_KEY.AUTH.UPDATE_PASSWORD,
    mutationFn: (variables: { token: string; password: string }) =>
      AuthService.updatePassword(variables.token, variables.password),
  });
