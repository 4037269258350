import { useQuery } from 'react-query';
import { QUERY_KEY } from '@constants';
import { PaymentService } from '@api/service';

export default () =>
  useQuery(
    QUERY_KEY.SETTINGS.PAYMENT.GET_RECOMMENDED_TIER,
    () => PaymentService.getRecommendedTier(),
    { suspense: true, useErrorBoundary: true },
  );
