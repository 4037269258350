import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useEC2DetailsUtilization } from '@hooks/api/optimization/ec2';
import { useToggle } from '@hooks/common';
import { detailsEC2DateState } from '@atoms/details';
import { MonthlyInstanceUtilChart } from '@components/chart/optimization';
import { QuestionTooltip } from '@components/styled/tooltip';
import { bluegrey } from '@theme/colors';
import { filterPositive, ValueFormatter } from '@utils';
import DetailsComputeDailyModal from './DetailsComputeDailyModal';

const DetailsComputeUtilChart: React.FC = () => {
  const { t } = useTranslation('optimization');
  const setSelectedDate = useSetRecoilState(detailsEC2DateState);
  const [openPeriodic, togglePeriodic] = useToggle();
  const [chartData, setChartData] = useState<
    Array<{ Timestamps: Date; CPU: number; Mem: number }>
  >([]);
  const { data } = useEC2DetailsUtilization();
  const cpuUtilMax = useMemo<number>(() => {
    if (
      data?.Utilization?.length &&
      data.Utilization[0].CPUUtilization?.length
    ) {
      return (
        _.maxBy(data.Utilization[0].CPUUtilization, 'Value')?.Value || undefined
      );
    }
    return undefined;
  }, [data?.Utilization]);
  const cpuUtilMean = useMemo(() => {
    if (
      data?.Utilization?.length &&
      data.Utilization[0].CPUUtilization?.length
    ) {
      return _.meanBy(data.Utilization[0].CPUUtilization, (v) =>
        Number(v.Value),
      );
    }
    return undefined;
  }, [data?.Utilization]);
  const memUtilMax = useMemo<number>(() => {
    if (
      data?.Utilization?.length &&
      data.Utilization[0].MemUtilization?.length
    ) {
      return (
        _.maxBy(data.Utilization[0].MemUtilization, 'Value')?.Value || undefined
      );
    }
    return undefined;
  }, [data?.Utilization]);
  const memUtilMean = useMemo(() => {
    if (
      data?.Utilization?.length &&
      data.Utilization[0].MemUtilization?.length
    ) {
      return _.meanBy(data.Utilization[0].MemUtilization, (v) =>
        Number(v.Value),
      );
    }
    return undefined;
  }, [data?.Utilization]);
  useEffect(() => {
    if (data?.Utilization?.length) {
      const keys = _.union(
        data.Utilization[0]?.CPUUtilization?.map((v) =>
          moment(v.Timestamps).format('YYYY-MM-DD'),
        ),
        data.Utilization[0]?.MemUtilization?.map((v) =>
          moment(v.Timestamps).format('YYYY-MM-DD'),
        ),
      );
      keys.sort();
      setChartData(
        keys.map((date) => {
          const cpu = data.Utilization[0]?.CPUUtilization?.find((v) =>
            moment(v.Timestamps).isSame(moment(date), 'date'),
          );
          const mem = data.Utilization[0]?.MemUtilization?.find((v) =>
            moment(v.Timestamps).isSame(moment(date), 'date'),
          );
          return {
            Timestamps: moment(date).toDate(),
            CPU: filterPositive(cpu?.Value),
            Mem: filterPositive(mem?.Value),
          };
        }),
      );
    }
  }, [data?.Utilization]);
  if (!data?.Utilization?.length) {
    return null;
  }
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        columns={20}
      >
        <Grid item md={15} xs={20}>
          <MonthlyInstanceUtilChart
            data={chartData}
            onDotClick={(v) => {
              if (v) {
                setSelectedDate(moment(v).format('YYYY-MM-DD'));
                togglePeriodic();
              }
            }}
          />
          <Box
            bgcolor={bluegrey[50]}
            borderLeft="2px solid #9594ff"
            mt={5}
            pl={3}
            pr={2}
            py={1}
          >
            <Typography variant="body2" color="textSecondary">
              {t('label.tooltip.ec2ChartDetail')}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          display={{ md: 'block', xs: 'flex' }}
          justifyContent="center"
          md={3}
          xs={20}
          mt={{ md: 0, xs: 4 }}
          mr={{ md: 4, xs: 0 }}
        >
          <Box mr={{ md: 0, xs: 10 }}>
            <Typography
              variant="body2"
              borderLeft="2px solid #5C5C66"
              pl={2}
              mb={2}
            >
              {t('label.util.cpu_long')}
            </Typography>
            <Box borderLeft="1px solid #5C5C66" ml={2} pl={2}>
              <Stack spacing={1}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="caption" color="textSecondary">
                    {t('label.util.max_short')}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {ValueFormatter.toPercent(cpuUtilMax) || '-'}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="caption" color="textSecondary">
                    {t('label.util.avg_short')}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {ValueFormatter.toPercent(cpuUtilMean) || '-'}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
          <Box mt={{ md: 5, xs: 0 }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              borderLeft="2px solid #5C5C66"
              pl={2}
              mb={2}
            >
              <Typography variant="body2">
                {t('label.util.mem_long')}
              </Typography>
              {(memUtilMax === undefined || memUtilMean === undefined) && (
                <QuestionTooltip
                  title={t('label.tooltip.ec2UtilApm')}
                  color="bluegrey"
                  size="0.875rem"
                />
              )}
            </Stack>
            <Box borderLeft="1px solid #5C5C66" ml={2} pl={2}>
              <Stack spacing={1}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="caption" color="textSecondary">
                    {t('label.util.max_short')}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {ValueFormatter.toPercent(memUtilMax) || '-'}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="caption" color="textSecondary">
                    {t('label.util.avg_short')}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {ValueFormatter.toPercent(memUtilMean) || '-'}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {openPeriodic && (
        <DetailsComputeDailyModal
          open={openPeriodic}
          onClose={togglePeriodic}
        />
      )}
    </>
  );
};

export default DetailsComputeUtilChart;
