import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEY } from '@constants';
import { AWSAccountService } from '@api/service';

export default () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.ONBOARD.CREATE_CUR,
    mutationFn: (variables: {
      reportName: string;
      bucketName: string;
      region: string;
      uid: string;
    }) =>
      AWSAccountService.createCUR(
        variables.reportName,
        variables.bucketName,
        variables.region,
        variables.uid,
      ),
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEY.CURRENT.AWS),
  });
};
