import React from 'react';
import { Button, Grid, styled, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import hierarchyImage from '@assets/images/aws_account_hierarchy.png';
import { useSetRecoilState } from 'recoil';
import { linkedViewStepState } from './LinkedView.atoms';

const HierarchyImage = styled('img')`
  max-width: 100%;
`;

const LinkedSelectionView: React.FC = () => {
  const setView = useSetRecoilState(linkedViewStepState);
  const { t } = useTranslation('onboard');
  return (
    <>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Typography variant="h3">{t('header.configureCUR')}</Typography>
          <Typography
            variant="body2"
            mt={2}
            dangerouslySetInnerHTML={{
              __html: `${t('description.configureCUR', {
                URL: 'https://docs.aws.amazon.com/cur/latest/userguide/what-is-cur.html',
              })}<br/><br/>${t('description.requestCUR', {
                URL: 'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_getting-started_concepts.html',
              })}`,
            }}
          />
        </Grid>
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          display={{ md: 'flex', xs: 'none' }}
          md={6}
        >
          <Grid item>
            <HierarchyImage src={hierarchyImage} alt="Hierarchy" />
          </Grid>
          <Grid item mt={3}>
            <Typography variant="caption" color="textSecondary">
              {t('description.accountDiagram')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        columnSpacing={4}
        rowSpacing={2}
        mt={20}
      >
        <Grid item md={3} xs={12}>
          <Tooltip title={t('tooltip.aws')} placement="top-end">
            <Button
              type="button"
              color="secondary"
              size="large"
              onClick={() => setView('INTERNAL')}
              fullWidth
            >
              {t('button.dealAWS')}
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Typography variant="body2">OR</Typography>
        </Grid>
        <Grid item md={3} xs={12}>
          <Tooltip title={t('tooltip.msp')} placement="top-end">
            <Button
              type="button"
              color="secondary"
              size="large"
              onClick={() => setView('MSP')}
              fullWidth
            >
              {t('button.dealMSP')}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

export default LinkedSelectionView;
