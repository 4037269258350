import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DiagnosticReport } from '@components/ui';
import useDiagnosticData from '@components/ui/onboard/DiagnosticReport/DiagnosticReport.hooks';
import { ROUTES } from '@routes';
import { Box } from '@mui/material';

const InitialDiagnosticStep: React.FC = () => {
  const navigate = useNavigate();
  const { isFinished } = useDiagnosticData();
  useEffect(() => {
    if (isFinished) {
      navigate(ROUTES.ONBOARD.REPORT);
    }
  }, [isFinished]);
  return (
    <Box>
      <DiagnosticReport />
    </Box>
  );
};

export default InitialDiagnosticStep;
