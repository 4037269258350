import React, { useMemo } from 'react';
import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCurrentInfo } from '@hooks/api/common';
import { GrmtTableColumnInfo, SimplePaginationTable } from '@components/table';
import {
  DetailLinkIcon,
  TableActionButton,
} from '@components/table/table.styled';
import { ROUTES } from '@routes';
import { LinkTypography } from '@components/styled';
import { useNavigate } from 'react-router-dom';
import { useAWSAccountHelper } from '@hooks/helper';

const SettingsAccountPage: React.FC = () => {
  const { t } = useTranslation('settings');
  const { t: optT } = useTranslation('optimization');
  const navigate = useNavigate();
  const { currentAWSAccounts, currentUser, currentRole } = useCurrentInfo();
  const { findHasCUR, hasPayer } = useAWSAccountHelper();

  const columns = useMemo<Array<GrmtTableColumnInfo>>(() => {
    const items: Array<GrmtTableColumnInfo> = [
      {
        Header: t('account.label.account', { context: 'type' }),
        accessor: 'isPayer' as const,
        minWidth: 120,
        maxWidth: 120,
        Cell: ({ value }) =>
          value
            ? t('account.accountType.management')
            : t('account.accountType.member'),
      },
      {
        Header: optT('label.common.accountId'),
        accessor: 'id' as const,
        minWidth: 130,
        maxWidth: 130,
      },
      {
        Header: t('account.label.account', { context: 'nickname' }),
        accessor: 'name' as const,
      },
      {
        Header: t('account.label.role', { context: 'arn' }),
        accessor: 'arn' as const,
        minWidth: 400,
        maxWidth: 400,
        Cell: ({ value }) => value || '-',
      },
      {
        Header: t('account.label.permission', { context: 'my' }),
        minWidth: 130,
        maxWidth: 130,
        Cell: ({ row }) => {
          if (currentRole < 3) {
            return t('account.roleType.manager');
          }
          if (currentUser?.assignedAWSAccount?.length && row?.original?.uid) {
            const matched = currentUser.assignedAWSAccount.find(
              (v) => v.id === row.original.uid,
            );
            if (matched) {
              return matched.readOnly
                ? t('account.roleType.user')
                : t('account.roleType.manager');
            }
          }
          return t('account.roleType.user');
        },
      },
    ];
    if (currentRole < 3) {
      items.push({
        Header: 'AWS CUR',
        accessor: 'reportName' as const,
        align: 'center',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ value, row }) => {
          if (row.original.isPayer) {
            if (typeof value === 'string' && value.length) {
              return t('account.label.configured');
            }
            return (
              <LinkTypography
                color="primary"
                onClick={() =>
                  navigate(`${ROUTES.ONBOARD.CUR}/${row.original.uid}`)
                }
              >
                {t('account.label.configure')}
              </LinkTypography>
            );
          }
          if (findHasCUR(row.original.uid)) {
            return row.original.isPayer ? t('account.label.configured') : '-';
          }
          return (
            <LinkTypography
              color="primary"
              onClick={() =>
                navigate(`${ROUTES.ONBOARD.CUR}/${row.original.uid}`)
              }
            >
              {t('account.label.request')}
            </LinkTypography>
          );
        },
      });
    }
    items.push({
      Header: optT('label.common.details'),
      accessor: 'uid' as const,
      align: 'center',
      minWidth: 84,
      maxWidth: 84,
      Cell: ({ value }) => (
        <DetailLinkIcon to={`${ROUTES.SETTINGS.ACCOUNTS_DETAIL}/${value}`} />
      ),
    });
    return items;
  }, [currentRole, currentUser?.assignedAWSAccount, findHasCUR]);

  return (
    <>
      <Typography variant="subtitle2" color="textSecondary" mb={2}>
        {t('account.header.accounts')}
      </Typography>
      <Paper square sx={{ px: 7, py: 5 }}>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: t('account.description', {
              url: '/docs/getting-started/connect/verify-arn',
            }),
          }}
          mb={7}
        />
        <SimplePaginationTable
          data={currentAWSAccounts}
          columnInfo={columns}
          ExtraElement={
            currentRole < 3 ? (
              <>
                {hasPayer && (
                  <TableActionButton
                    onClick={() =>
                      navigate(ROUTES.SETTINGS.ACCOUNTS_ORGANIZATION)
                    }
                  >
                    {t('account.label.manageOrganization')}
                  </TableActionButton>
                )}
                <TableActionButton
                  onClick={() => navigate(ROUTES.ONBOARD.CONNECT)}
                >
                  {t('account.label.connect')}
                </TableActionButton>
              </>
            ) : undefined
          }
        />
      </Paper>
    </>
  );
};

export default SettingsAccountPage;
