import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import { CompanyService } from '@api/service';
import { companyIdState } from '@atoms/global';
import { GLOBAL, QUERY_KEY } from '@constants';

export default (suspense = true) => {
  const { pathname } = useLocation();
  const activeToken = useMemo<string | null>(
    () => localStorage.getItem(GLOBAL.ACCESS_TOKEN),
    [pathname],
  );
  const companyId = useRecoilValue(companyIdState);
  return useQuery(
    [QUERY_KEY.CURRENT.COMPANIES, companyId],
    ({ queryKey }) => {
      if (queryKey[1]) {
        return CompanyService.getById(queryKey[1]);
      }
      return undefined;
    },
    {
      cacheTime: 15000,
      suspense,
      enabled: Boolean(activeToken) && Boolean(companyId),
    },
  );
};
