import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetDepositHistory, useGetInvoiceDetail } from '@hooks/api/cmp';
import { ValueFormatter } from '@utils';
import moment from 'moment';

const DepositDetails: React.FC = () => {
  const { t } = useTranslation('report');
  const { id } = useParams();
  const { data: invoices } = useGetInvoiceDetail(id);
  const { data: history } = useGetDepositHistory();
  const historyData = useMemo<Array<UsedDeposit>>(() => {
    if (history?.data.length) {
      return history.data.filter(
        (v) => v.ItemType === 'Used',
      ) as Array<UsedDeposit>;
    }
    return [];
  }, [history?.data]);
  if (!invoices?.data.length) {
    return null;
  }
  return (
    <Box mt="16px">
      {historyData.length ? (
        <Box
          sx={{
            border: '.5px solid #cecfde',
            borderRadius: '8px',
            px: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              px: '24px',
              py: '16px',
            }}
          >
            <Typography variant="subtitle2">
              {t('text.deposit_deducted')}
            </Typography>
            <Typography variant="subtitle2">
              {ValueFormatter.toCurrency(
                invoices.data[0].DepositUsed,
                'KRW',
                0,
              )}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ px: '24px', py: '40px' }}>
            <Typography color="textSecondary" mb={2}>
              {t('text.balance_history')}
            </Typography>
            <Box sx={{ overflowX: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('table.billing_month')}</TableCell>
                    {historyData.map((item) => (
                      <TableCell
                        key={`deposit_history_month_${item.InvoiceId}`}
                        align="right"
                      >
                        {moment(item.InvoiceDate).format('YYYY/MM')}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{t('table.deducted')}</TableCell>
                    {historyData.map((item) => (
                      <TableCell
                        key={`deposit_history_deducted_${item.InvoiceId}`}
                        align="right"
                      >
                        {ValueFormatter.toCurrency(item.Amount, 'KRW', 0)}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('table.balance')}</TableCell>
                    {historyData.map((item) => (
                      <TableCell
                        key={`deposit_history_balance_${item.InvoiceId}`}
                        align="right"
                      >
                        {ValueFormatter.toCurrency(item.Remaining, 'KRW', 0)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            my: '24px',
          }}
        >
          <Typography>{t('text.deposit_empty')}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default DepositDetails;
