import { useMutation, useQueryClient } from 'react-query';
import { CompanyService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const client = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.ADMIN.COMPANY.CREATE_COMPANY,
    mutationFn: (variables: { name: string; email: string }) =>
      CompanyService.createCompany(variables.name, variables.email),
    onSuccess: () =>
      client.invalidateQueries(QUERY_KEY.ADMIN.COMPANY.GET_COMPANIES),
  });
};
