import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { encryptLocalStorage, hashPassword } from '@utils/crypto';
import { GLOBAL } from '@constants';
import { ROUTES } from '@routes';
import { AuthService } from '@api/service';
import { useSnackbar, useTagManager } from '@hooks/common';
import { useRecoilValue } from 'recoil';
import { callbackUrlState } from '@atoms/global';
import { useSignInForm } from './SignInPage.form';

const SignInPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('auth');
  const { t: formT } = useTranslation('form');
  const { t: toastT } = useTranslation('toast');
  const internalCallback = useRecoilValue(callbackUrlState);
  const externalCallback = searchParams.get('callbackUrl');
  const { showErrorSnackbar } = useSnackbar();
  const { sendDataLayer } = useTagManager();
  const { initialValues, validationSchema } = useSignInForm();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      validateOnMount: true,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: async (v) => {
        try {
          const response = await AuthService.login(
            v.email,
            hashPassword(v.password),
            v.keepLoggedIn,
          );
          if (response.items?.length && response.items[0]) {
            const body = response.items[0];
            if (body.refresh_token && body.refresh_token_expired) {
              encryptLocalStorage(GLOBAL.REFRESH_TOKEN, body.refresh_token);
              encryptLocalStorage(
                GLOBAL.REFRESH_TOKEN_EXPIRED,
                body.refresh_token_expired.toString(),
              );
            }
            if (body.access_token && body.access_token_expired) {
              sendDataLayer('cc-signin');
              localStorage.setItem(GLOBAL.ACCESS_TOKEN, body.access_token);
              localStorage.setItem(
                GLOBAL.ACCESS_TOKEN_EXPIRED,
                body.access_token_expired.toString(),
              );
              if (externalCallback) {
                window.location.href = `${window.location.origin}${externalCallback}`;
              } else if (
                internalCallback &&
                internalCallback === ROUTES.ONBOARD.REPORT
              ) {
                navigate(internalCallback);
              } else {
                navigate(ROUTES.OVERVIEW.ROOT);
              }
            }
          }
        } catch (e: any) {
          if (e.response?.data?.message) {
            switch (e.response.data.message) {
              case 'Login failed, You need to verify the email first':
                navigate(ROUTES.AUTH.RESEND);
                return;
              case 'Login failed, incorrect e-mail or password':
                showErrorSnackbar(toastT('error.auth.signIn.incorrect'));
                return;
              default:
                showErrorSnackbar(toastT('error.auth.signIn.common'));
                return;
            }
          }
          if (e.response?.data?.msg) {
            switch (e.response.data.msg) {
              case 'User Not found':
                showErrorSnackbar(toastT('error.auth.signIn.incorrect'));
                return;
              default:
                showErrorSnackbar(toastT('error.auth.signIn.common'));
            }
          }
        }
      },
    });
  return (
    <form id="cc_signin" onSubmit={handleSubmit}>
      <Typography variant="h1" align="center" mb={7}>
        {t('title.signIn')}
      </Typography>
      <Box mb={2}>
        <TextField
          name="email"
          type="email"
          inputMode="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          label={formT('holder.email')}
          placeholder="jdoe@company.com"
          autoComplete="username"
          autoCapitalize="off"
          error={Boolean(errors.email && touched.email)}
          helperText={
            Boolean(errors.email && touched.email) && String(errors.email)
          }
          fullWidth
        />
      </Box>
      <Box mb={2}>
        <TextField
          name="password"
          type="password"
          inputMode="text"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          label={formT('holder.password')}
          autoComplete="current-password"
          autoCapitalize="off"
          error={Boolean(errors.password && touched.password)}
          helperText={
            Boolean(errors.password && touched.password) &&
            String(errors.password)
          }
          fullWidth
        />
      </Box>
      <Box display="flex" alignItems="center">
        <FormControlLabel
          name="keepLoggedIn"
          value={values.keepLoggedIn}
          onChange={handleChange}
          onBlur={handleBlur}
          control={<Checkbox size="small" />}
          label={t('label.keep')}
          sx={{
            flexGrow: 1,
            '.MuiFormControlLabel-label': {
              fontSize: '12px',
              lineHeight: '18px',
            },
          }}
        />
        <Typography
          variant="caption"
          color="primary"
          onClick={() => navigate(ROUTES.AUTH.FORGOT, { replace: true })}
          sx={{ cursor: 'pointer' }}
        >
          {t('label.forgot')}
        </Typography>
      </Box>
      <Box mt={11}>
        <Button type="submit" size="large" fullWidth>
          {t('button.signIn')}
        </Button>
      </Box>
      <Box display="flex" justifyContent="center" mt={3}>
        <Typography variant="caption" mr={2}>
          {t('cta.signUp.title')}
        </Typography>
        <Typography
          variant="caption"
          color="primary"
          onClick={() => navigate(ROUTES.AUTH.SIGNUP, { replace: true })}
          sx={{ cursor: 'pointer' }}
        >
          {t('cta.signUp.label')}
        </Typography>
      </Box>
    </form>
  );
};

export default SignInPage;
