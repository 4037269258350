import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuItem, TextField } from '@mui/material';
import i18n from '@utils/i18n';

const LanguageSelectorPublic: React.FC = () => {
  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();
  const handleChange = useCallback(
    async (evt) => {
      const targetLang = evt.target.value;
      const targetSearch = search.length
        ? `${search}&lang=${targetLang}`
        : `?lang=${targetLang}`;
      navigate(`${pathname}${targetSearch}${hash}`, { replace: true });
      window.location.reload();
    },
    [pathname, search, hash],
  );
  return (
    <TextField
      variant="standard"
      value={i18n.language}
      onChange={handleChange}
      InputProps={{ style: { color: 'inherit' } }}
      select
    >
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="ko">한국어</MenuItem>
    </TextField>
  );
};

export default LanguageSelectorPublic;
