import { useMutation, useQueryClient } from 'react-query';
import { IntegrationService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.SETTINGS.INTEGRATION.CREATE_NEWRELIC,
    mutationFn: (variables: {
      companyId: string;
      APIKey: string;
      AccountId: string;
      QueryKey: string;
    }) =>
      IntegrationService.createNewrelic(
        variables.companyId,
        variables.APIKey,
        variables.AccountId,
        variables.QueryKey,
      ),
    onSuccess: () =>
      queryClient.invalidateQueries(
        QUERY_KEY.SETTINGS.INTEGRATION.GET_NEWRELIC,
      ),
  });
};
