import React, { useMemo } from 'react';
import { Chip, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEC2DetailsGeneral } from '@hooks/api/optimization/ec2';
import { useAWSHelper } from '@hooks/helper';
import { SimpleTypoTable } from '@components/styled';
import { ValueFormatter } from '@utils';
import { QuestionTooltip } from '@components/styled/tooltip';
import _ from 'lodash';

const DetailsComputeGeneral: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');
  const { getAWSRegionName } = useAWSHelper();
  const { data } = useEC2DetailsGeneral();
  const generalInfo = useMemo(() => {
    if (data?.items?.length && !_.isEmpty(data.items[0])) {
      return [
        {
          label: t('label.common.accountId'),
          value: _.get(data, 'items.0.AccountId') || '-',
        },
        {
          label: t('label.common.instanceId'),
          value: _.get(data, 'items.0.InstanceId') || '-',
        },
        {
          label: t('label.common.region'),
          value: getAWSRegionName(_.get(data, 'items.0.Region') || '-'),
        },
        {
          label: t('label.common.instanceType'),
          value: _.get(data, 'items.0.CurrentType') || '-',
        },
        {
          label: t('label.common.storage'),
          value: _.get(data, 'items.0.storage') || '-',
        },
        {
          label: (
            <Stack direction="row" alignItems="center" spacing={1}>
              <span>
                {t('label.common.cost')} (
                {commonT('label.days', {
                  count: 30,
                })}
                )
              </span>
              <QuestionTooltip
                title={t('label.tooltip.ec2Cost')}
                color="bluegrey"
                size="1rem"
              />
            </Stack>
          ),
          value: ValueFormatter.toCurrency(
            _.get(data, 'items.0.MonthlyCost') || 0,
          ),
        },
        {
          label: `${t('label.common.tags')} (${data.items[0].Tag.length})`,
          value: (
            <>
              {!_.isEmpty(data.items[0].Tag) ? (
                <Grid container spacing={1}>
                  {data.items[0].Tag.map((item) =>
                    Object.keys(item).map((tag, idx) => {
                      const key = tag.split(':');
                      return (
                        <Grid
                          key={`${tag}_${item[tag]}_${idx.toString()}`}
                          item
                        >
                          <Chip
                            label={`${key[key.length - 1]}: ${item[tag]}`}
                          />
                        </Grid>
                      );
                    }),
                  )}
                </Grid>
              ) : (
                data.items[0].Tag?.length === 0 && <Chip label="No tag" />
              )}
            </>
          ),
        },
      ];
    }
    return [];
  }, [data?.items, t, getAWSRegionName]);
  if (!data?.items?.length) {
    return null;
  }
  return <SimpleTypoTable data={generalInfo} disableOutline />;
};

export default DetailsComputeGeneral;
