import React, { useMemo } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Link,
  Button,
  Stack,
  Chip,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import {
  useRDSDetailsGeneral,
  useRDSDetailsPurchasing,
} from '@hooks/api/optimization/rds';
import { CoverageChart } from '@components/chart/optimization';
import { PlannerCircularChart } from '@components/chart/planner';
import { SimulationTable } from '@pages/private/planner/simulation/Simulation.styled';
import { RecommendActionDivider } from '@components/styled';
import { getPercentageProportion, ValueFormatter } from '@utils';
import { ChartCoverBox, NoAvailableData } from '@components/styled/noData';
import { COVERAGE_DUMMY } from '@components/chart/chart.dummy';

const DetailsDatabasePurchasingPage: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: plannerT } = useTranslation('planner');
  const { t: commonT } = useTranslation('common');
  const { data: general } = useRDSDetailsGeneral();
  const { data } = useRDSDetailsPurchasing();
  const usageTableBody = useMemo(() => {
    if (data?.items?.length) {
      return [
        {
          label: t('label.common.od'),
          items: data.items[0].InstanceUsage.slice(-3).map((v) =>
            ValueFormatter.toLocaleString(v.Ondemand, 0),
          ),
        },
        {
          label: (
            <>
              {t('label.common.ri')}
              <br />({t('label.header.coverage')})
            </>
          ),
          items: data.items[0].InstanceUsage.slice(-3).map((v) => (
            <>
              {ValueFormatter.toLocaleString(v.Reserved, 0)}
              <br />(
              {ValueFormatter.toPercent(
                getPercentageProportion(v.Reserved, v.TotalUsage),
              )}
              )
            </>
          )),
        },
      ];
    }
    return [];
  }, [data?.items]);
  const coverageTableBody = useMemo(() => {
    if (data?.items?.length) {
      return [
        {
          label: t('label.common.od'),
          items: data.items[0].FamilyCoverage.slice(-6).map((v) =>
            ValueFormatter.toLocaleString(v.Ondemand, 0),
          ),
        },
        {
          label: (
            <>
              {t('label.common.ri')}
              <br />({t('label.header.coverage')})
            </>
          ),
          items: data.items[0].FamilyCoverage.slice(-6).map((v) => (
            <>
              {ValueFormatter.toLocaleString(v.Reserved, 0)}
              <br />(
              {ValueFormatter.toPercent(
                getPercentageProportion(v.Reserved, v.TotalUsage),
              )}
              )
            </>
          )),
        },
      ];
    }
    return [];
  }, [data?.items]);
  if (!data?.items?.length) {
    return <NoAvailableData />;
  }
  const { ThisMonthUsage, InstanceUsage, FamilyCoverage, NeedMoreRI } =
    data.items[0];
  if (
    _.isEmpty(ThisMonthUsage) &&
    _.isEmpty(InstanceUsage) &&
    _.isEmpty(FamilyCoverage)
  ) {
    return <NoAvailableData />;
  }
  return (
    <>
      <Grid container columnSpacing={11} rowSpacing={5}>
        <Grid item xs={4}>
          <Typography color="textSecondary" mb={1}>
            {plannerT('label.usage')} ({commonT('label.days', { count: 30 })})
          </Typography>
          <ChartCoverBox isEmpty={_.isEmpty(ThisMonthUsage)}>
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <PlannerCircularChart
                data={
                  !_.isEmpty(ThisMonthUsage)
                    ? [
                        { cost: ThisMonthUsage[0].Ondemand },
                        { cost: 0 },
                        { cost: ThisMonthUsage[0].Reserved },
                      ]
                    : [{ cost: 6 }, { cost: 0 }, { cost: 4 }]
                }
                size="medium"
                showPercent
              />
              <Typography variant="body2" mt={5} mb={2}>
                {plannerT('label.totalUsage')} (
                {commonT('label.days', { count: 30 })})
              </Typography>
              <Typography variant="subtitle2">
                {ValueFormatter.toLocaleString(
                  ThisMonthUsage[0].TotalUsage || 0,
                  0,
                )}
                h
              </Typography>
            </Box>
          </ChartCoverBox>
        </Grid>
        <Grid item xs={8}>
          <Typography color="textSecondary" mb={1}>
            {plannerT('label.instanceCoverage')} (
            {commonT('label.months', { count: 3 })})
          </Typography>
          <ChartCoverBox isEmpty={_.isEmpty(InstanceUsage)}>
            <CoverageChart
              data={
                !_.isEmpty(InstanceUsage)
                  ? InstanceUsage.slice(-3)
                  : COVERAGE_DUMMY.slice(-3)
              }
              xAxis={{
                dataKey: 'Date',
                tickFormatter: (value: any) => moment(value).format('YYYY MMM'),
              }}
              chartInfo={[
                {
                  name: t('label.common.od'),
                  dataKey: 'Ondemand',
                },
                {
                  name: t('label.common.ri'),
                  dataKey: 'Reserved',
                },
              ]}
            />
          </ChartCoverBox>
        </Grid>
      </Grid>
      <Box
        mt={5}
        borderTop="2px solid #f0f0f7"
        borderBottom="2px solid #f0f0f7"
      >
        <SimulationTable
          title={t('label.header.purchasing')}
          bodyAlign="right"
          header={data.items[0].InstanceUsage.slice(-3).map((v) =>
            moment(v.Date).format('YYYY MMM'),
          )}
          body={usageTableBody}
        />
      </Box>
      <Typography color="textSecondary" mt={20}>
        {plannerT('label.familyCoverage')} (
        {commonT('label.months', { count: 6 })})
      </Typography>
      <ChartCoverBox isEmpty={_.isEmpty(FamilyCoverage)}>
        <CoverageChart
          data={
            !_.isEmpty(FamilyCoverage)
              ? FamilyCoverage.slice(-6)
              : COVERAGE_DUMMY
          }
          xAxis={{
            dataKey: 'Date',
            tickFormatter: (value: any) => moment(value).format('YYYY MMM'),
          }}
          chartInfo={[
            {
              name: t('label.common.od'),
              dataKey: 'Ondemand',
            },
            {
              name: t('label.common.ri'),
              dataKey: 'Reserved',
            },
          ]}
        />
      </ChartCoverBox>
      <Box
        mt={5}
        borderTop="2px solid #f0f0f7"
        borderBottom="2px solid #f0f0f7"
      >
        <SimulationTable
          title={plannerT('label.coveragePerMonth')}
          bodyAlign="right"
          header={data.items[0].FamilyCoverage.slice(-6).map((v) =>
            moment(v.Date).format('YYYY MMM'),
          )}
          body={coverageTableBody}
        />
      </Box>
      {Boolean(NeedMoreRI) && Boolean(general?.items?.length) && (
        <Box mt={12}>
          <RecommendActionDivider />
          <Paper variant="elevation" sx={{ p: 5, mt: 5, mb: 6 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3">{plannerT('buyMore.rds.ri')}</Typography>
              <Stack direction="row" spacing={2}>
                <Chip label={general.items[0].DatabaseEngine} />
                <Chip label={general.items[0].InstanceType} />
                <Chip
                  label={t(
                    `label.db.${
                      general.items[0].MultiAZ as 'Multi-AZ' | 'Single-AZ'
                    }`,
                  )}
                />
              </Stack>
            </Box>
          </Paper>
          <Box textAlign="right">
            <Button
              size="large"
              component={Link}
              href={`https://console.aws.amazon.com/rds/home?region=${general.items[0].Region}#reserved-instance-list:`}
              target="_blank"
              endIcon={
                <ArrowForwardIcon sx={{ width: '19px', height: '19px' }} />
              }
            >
              {t('button.purchase.rds')}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DetailsDatabasePurchasingPage;
