import React from 'react';
import { Box, Divider, Grid, Paper, styled, Typography } from '@mui/material';
import { bluegrey } from '@theme/colors';
import { ValueFormatter } from '@utils';
import { useTranslation } from 'react-i18next';

export const ThickDivider = styled(Divider)`
  border-bottom-width: 3px;
  border-color: #f0f0f7;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const SummaryTitle = styled(Typography)`
  > span {
    font-size: 0.875rem;
  }
`;

const ServiceItem = React.memo(
  ({
    index,
    service,
    numOfInstances,
    actionItems,
  }: {
    index: string | number;
    service: string | number;
    numOfInstances: string | number;
    actionItems: string | number;
  }) => (
    <>
      <Grid item xs={1}>
        <Typography variant="body2">{index}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2">{service}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2">{numOfInstances}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2">{actionItems}</Typography>
      </Grid>
    </>
  ),
);

const DetailItem = React.memo(
  ({
    index,
    category,
    actionItems,
    possibleSavings,
  }: {
    index: string | number;
    category: string | number;
    actionItems: string | number;
    possibleSavings: string | number;
  }) => (
    <>
      <Grid item xs={1}>
        <Typography variant="body2">{index}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2">{category}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" align="right">
          {actionItems}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" align="right">
          {possibleSavings}
        </Typography>
      </Grid>
    </>
  ),
);

export const CheckedServices = React.memo(
  ({
    services,
  }: {
    services: Array<{
      service: string;
      instances: string | number;
      items: string | number;
    }>;
  }) => {
    const { t } = useTranslation('onboard');
    return (
      <Grid container columns={10} p={2} rowSpacing={4}>
        <ServiceItem
          index="No."
          service={t('report.label.service')}
          numOfInstances={t('report.label.numOfInstance')}
          actionItems={t('report.label.action')}
        />
        {services.map((item, idx) => (
          <ServiceItem
            key={`${item.service}_${item.items}_${item.instances}`}
            index={idx + 1}
            service={item.service}
            numOfInstances={ValueFormatter.toLocaleString(item.instances, 0)}
            actionItems={ValueFormatter.toLocaleString(item.items, 0)}
          />
        ))}
      </Grid>
    );
  },
);

export const ServiceDetails = React.memo(
  ({
    title,
    categories,
  }: {
    title: string;
    categories: Array<{
      category: string;
      items: string | number;
      savings: string | number;
    }>;
  }) => {
    const { t } = useTranslation('onboard');
    return (
      <Paper sx={{ mt: 3 }}>
        <Box p={2} bgcolor={bluegrey[50]}>
          <Typography variant="body2">{title}</Typography>
        </Box>
        <Divider />
        <Grid container columns={10} p={2} rowSpacing={4}>
          <DetailItem
            index="No."
            category={t('report.label.category')}
            actionItems={t('report.label.action')}
            possibleSavings={t('report.label.savings')}
          />
          {categories.map((item, idx) => (
            <DetailItem
              key={`${item.category}_${item.items}_${item.savings}`}
              index={idx + 1}
              category={item.category}
              actionItems={ValueFormatter.toLocaleString(item.items, 0)}
              possibleSavings={ValueFormatter.toCurrency(item.savings)}
            />
          ))}
        </Grid>
      </Paper>
    );
  },
);

export const TipBox = React.memo(
  ({ title, content }: { title: string; content: string }) => (
    <Box p={4}>
      <Typography variant="subtitle2" color="textSecondary">
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 3 }}>
        {content}
      </Typography>
    </Box>
  ),
);
