import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from '@components/common/PageHeader';
import { ServiceIconName } from '@components/common/ServiceIcon';
import { includesPath, ROUTES } from '@routes';

const MainHeader: React.FC = () => {
  const { pathname } = useLocation();
  const title = useMemo<ServiceIconName | undefined>(() => {
    switch (true) {
      case includesPath(ROUTES.OVERVIEW, pathname):
        return 'Overview';
      case includesPath(ROUTES.ONBOARD, pathname):
        return 'Onboarding';
      case includesPath(ROUTES.COMPUTE, pathname):
      case includesPath(ROUTES.DATABASE, pathname):
      case includesPath(ROUTES.STORAGE, pathname):
      case includesPath(ROUTES.VOLUME, pathname):
      case includesPath(ROUTES.MISCELLANEOUS, pathname):
        return 'Optimization';
      case includesPath(ROUTES.ANOMALY, pathname):
        return 'Anomaly Detection';
      case includesPath(ROUTES.PLANNER, pathname):
        return 'Smart Planner';
      case includesPath(ROUTES.SETTINGS, pathname):
        return 'Settings';
      case includesPath(ROUTES.ADMIN, pathname):
        return 'Admin';
      case includesPath(ROUTES.DETAILS, pathname):
        return 'Details';
      case includesPath(ROUTES.NOTIFICATION, pathname):
        return 'Notification';
      case includesPath(ROUTES.BILLING, pathname):
        return 'Billing Report';
      case includesPath(ROUTES.USAGE, pathname):
        return 'Cloud Cost Report';
      default:
        return undefined;
    }
  }, [pathname]);

  const subTitle = useMemo<Array<string> | string | undefined>(() => {
    switch (true) {
      case includesPath(ROUTES.COMPUTE, pathname):
        return 'EC2';
      case includesPath(ROUTES.DATABASE, pathname):
        return 'RDS';
      case includesPath(ROUTES.STORAGE, pathname):
        return 'S3';
      case includesPath(ROUTES.VOLUME, pathname):
        return 'EBS';
      case includesPath(ROUTES.MISCELLANEOUS, pathname):
        return 'Miscellaneous';
      case pathname.startsWith(ROUTES.DETAILS.COMPUTE):
        return 'EC2';
      case pathname.startsWith(ROUTES.DETAILS.DATABASE):
        return 'RDS';
      case pathname.startsWith(ROUTES.DETAILS.STORAGE):
        return 'S3';
      case pathname.startsWith(ROUTES.DETAILS.VOLUME):
        return 'EBS';
      case pathname === ROUTES.ANOMALY.SUMMARY:
        return 'Summary';
      case pathname === ROUTES.ANOMALY.SETTINGS:
        return 'Settings';
      case pathname === ROUTES.SETTINGS.PROFILE:
        return 'Profile';
      case pathname === ROUTES.SETTINGS.ACCOUNTS:
        return 'Accounts';
      case pathname === ROUTES.SETTINGS.ACCOUNTS_ORGANIZATION:
        return ['Accounts', 'Organizations'];
      case pathname.startsWith(ROUTES.SETTINGS.ACCOUNTS_DETAIL):
        return ['Accounts', 'Details'];
      case pathname === ROUTES.SETTINGS.USERS:
        return 'Users';
      case pathname === ROUTES.SETTINGS.PAYMENT:
        return 'Payment';
      case pathname === ROUTES.SETTINGS.INTEGRATION:
        return 'Integration';
      case pathname === ROUTES.ADMIN.COMPANIES:
        return 'Companies';
      case pathname === ROUTES.ADMIN.SUBSCRIPTIONS:
        return 'Subscriptions';
      case includesPath(ROUTES.BILLING, pathname) &&
        pathname !== ROUTES.BILLING.ROOT:
        return 'Bill Detail';
      default:
        return undefined;
    }
  }, [pathname]);

  const withTab = useMemo<boolean>(() => {
    switch (true) {
      case includesPath(ROUTES.COMPUTE, pathname):
      case includesPath(ROUTES.DATABASE, pathname):
      case pathname.startsWith(ROUTES.DETAILS.COMPUTE):
      case pathname.startsWith(ROUTES.DETAILS.DATABASE):
      case includesPath(ROUTES.PLANNER, pathname) &&
        !pathname.startsWith(ROUTES.PLANNER.SIMULATION):
      case pathname === ROUTES.ADMIN.SUBSCRIPTIONS:
        return true;
      default:
        return false;
    }
  }, [pathname]);

  const canGoBack = useMemo<boolean>(() => {
    switch (true) {
      case includesPath(ROUTES.DETAILS, pathname):
      case includesPath(ROUTES.BILLING, pathname):
      case pathname.startsWith(ROUTES.SETTINGS.ACCOUNTS_DETAIL):
      case pathname === ROUTES.SETTINGS.ACCOUNTS_ORGANIZATION:
        return true;
      default:
        return false;
    }
  }, [pathname]);

  const needAccount = useMemo<boolean>(() => {
    switch (true) {
      case includesPath(ROUTES.COMPUTE, pathname):
      case includesPath(ROUTES.DATABASE, pathname):
      case includesPath(ROUTES.STORAGE, pathname):
      case includesPath(ROUTES.VOLUME, pathname):
      case includesPath(ROUTES.MISCELLANEOUS, pathname):
      case includesPath(ROUTES.PLANNER, pathname) &&
        !pathname.startsWith(ROUTES.PLANNER.SIMULATION_UNCOVERED):
        return true;
      default:
        return false;
    }
  }, [pathname]);

  return (
    <>
      {title && (
        <PageHeader
          title={title}
          subTitle={subTitle}
          withTab={withTab}
          canGoBack={canGoBack}
          needAccount={needAccount}
        />
      )}
    </>
  );
};

export default MainHeader;
