import { useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import moment from 'moment';
import _ from 'lodash';
import { hasCURState } from '@atoms/global';
import useTokenLogin from './useTokenLogin';
import useCurrentUser from './useCurrentUser';
import useCurrentCompany from './useCurrentCompany';
import useCurrentAccounts from './useCurrentAccounts';

export default (suspense = true) => {
  const { isLoading: isTokenLoading } = useTokenLogin(suspense);
  const { isLoading: isUserLoading, data: userData } = useCurrentUser(suspense);
  const { isLoading: isCompanyLoading, data: companyData } =
    useCurrentCompany(suspense);
  const { isLoading: isAWSLoading, data: awsData } =
    useCurrentAccounts(suspense);
  const isLoading = useMemo<boolean>(
    () => isTokenLoading && isUserLoading && isCompanyLoading && isAWSLoading,
    [isTokenLoading, isUserLoading, isCompanyLoading, isAWSLoading],
  );
  const needDiagnostic = useMemo<boolean>(() => {
    if (companyData?.items?.length) {
      const run = Boolean(companyData.items[0].runDiagnostic);
      return !run;
    }
    return true;
  }, [companyData?.items]);
  const curExists = useMemo<boolean>(() => {
    if (companyData?.items?.length) {
      return Boolean(companyData.items[0].curExists);
    }
    return false;
  }, [companyData?.items]);
  const curLoading = useMemo<boolean>(() => {
    if (!curExists && awsData?.items?.length) {
      const filtered = awsData.items.filter(
        (v) => v.isPayer && v.reportName && v.reportName.length > 0,
      );
      return filtered.length > 0;
    }
    return false;
  }, [curExists, awsData?.items]);
  const isDiagnosticRunning = useMemo<boolean>(() => {
    if (companyData?.items?.length) {
      return Boolean(companyData.items[0].isDiagnosticRunning);
    }
    return false;
  }, [companyData?.items]);
  const currentRole = useMemo(() => {
    if (userData?.items?.length && userData.items[0].roles?.length) {
      return Math.min(...userData.items[0].roles);
    }
    return 3;
  }, [userData?.items]);
  const hasAWSAccount = useMemo<boolean>(
    () => Boolean(awsData?.items?.length),
    [awsData?.items?.length],
  );
  const needMoreLinked = useMemo<boolean>(
    () => Boolean(awsData?.extras?.NeedMoreLinked),
    [awsData?.extras],
  );
  const showOverview = useMemo<boolean>(
    () => curExists || curLoading || !needDiagnostic,
    [curExists, curLoading, needDiagnostic],
  );
  const isTrial = useMemo<boolean>(() => {
    if (curExists && companyData?.items?.length) {
      return Boolean(companyData.items[0].isTrial);
    }
    return false;
  }, [curExists && companyData?.items]);
  const isTrialExpired = useMemo<boolean>(() => {
    if (companyData?.items?.length) {
      if (
        companyData.items[0].isTrial &&
        companyData.items[0].trialStartDate &&
        moment().diff(moment(companyData.items[0].trialStartDate)) > 1209600000
      ) {
        return true;
      }
    }
    return false;
  }, [curExists, companyData?.items]);
  const trialExpiry = useMemo<string | undefined>(() => {
    if (companyData?.items?.length && companyData.items[0].trialStartDate) {
      const expired = moment(companyData.items[0].trialStartDate)
        .utc(true)
        .add(1209600, 'seconds');
      return expired.format('YYYY/MM/DD');
    }
    return undefined;
  }, [companyData?.items]);
  const trialRemainingDays = useMemo<number>(() => {
    if (companyData?.items?.length && companyData.items[0].trialStartDate) {
      const expired = moment(companyData.items[0].trialStartDate)
        .utc(true)
        .add(1209600, 'seconds');
      return expired.diff(moment(), 'days');
    }
    return 0;
  }, [companyData?.items]);
  const cmpIntegrated = useMemo<boolean>(() => {
    if (companyData?.items?.length) {
      return !_.isEmpty(companyData.items[0].cmp_id);
    }
    return false;
  }, [companyData?.items]);
  const setHasCur = useSetRecoilState(hasCURState);
  useEffect(() => {
    setHasCur(curExists);
  }, [curExists]);
  return {
    isLoading,
    currentUser:
      userData?.items && userData.items.length ? userData.items[0] : undefined,
    currentCompany:
      companyData?.items && companyData.items.length
        ? companyData.items[0]
        : undefined,
    currentAWSAccounts:
      awsData?.items && awsData.items.length ? awsData.items : undefined,
    needDiagnostic,
    curExists,
    curLoading,
    hasAWSAccount,
    isDiagnosticRunning,
    currentRole,
    needMoreLinked,
    showOverview,
    isTrial,
    isTrialExpired,
    trialExpiry,
    trialRemainingDays,
    cmpIntegrated,
  };
};
