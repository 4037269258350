import axios from '@utils/axios';
import { API_URL } from '@constants';
import { parseContentRange } from '@utils';

const getS3InformationList: PaginatedAxios<
  WithContentRange<CCQueryResponse<S3Information>>
> = async (skip, limit, filter, sort) => {
  const { headers, data } = await axios.get(`${API_URL.OPTIMIZATION}/s3/`, {
    params: {
      skip,
      limit,
      filter,
      sort,
    },
  });
  return { headers: parseContentRange(headers), body: data };
};

const getS3UsageChart = async (
  AccountList: string | undefined = undefined,
): Promise<
  CCQueryResponse<
    S3Chart,
    {
      TotalCost: number;
      TotalStorageCost: number;
      TotalTransferCost: number;
      LatestUpdate: Date;
    }
  >
> => {
  const { data } = await axios.get(`${API_URL.OPTIMIZATION}/s3/graph/usage/`, {
    params: {
      AccountList,
    },
  });
  return data;
};

const getS3AutoComplete = async (): Promise<
  CCQueryResponse<S3AutoComplete>
> => {
  const { data } = await axios.get(`${API_URL.OPTIMIZATION}/s3/acdata`);
  return data;
};

const getS3DetailsGeneral = async (
  bucketName: string,
): Promise<CCQueryResponse<S3DetailsGeneral>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/s3/details/general/${bucketName}`,
  );
  return data;
};

const getS3DetailsSummary = async (
  bucketName: string,
): Promise<CCQuerySingleResponse<S3DetailsSummary, S3DetailsSummaryExtras>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/s3/details/summary/${bucketName}`,
  );
  return data;
};

export default {
  getS3InformationList,
  getS3UsageChart,
  getS3AutoComplete,
  getS3DetailsGeneral,
  getS3DetailsSummary,
};
