import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { Cancel, CheckCircleRounded } from '@mui/icons-material';
import { PermissionStatusProps } from './ConnectAWSPage.types';

const PermissionStatusItemComponent: React.FC<PermissionStatusProps> = ({
  label,
  status,
}) => {
  const statusColor = useMemo(() => {
    switch (status) {
      case true:
        return 'success';
      case false:
        return 'error';
      default:
        return 'disabled';
    }
  }, [status]);
  return (
    <Grid
      container
      item
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={2} textAlign="center">
        {status === false ? (
          <Cancel color="error" />
        ) : (
          <CheckCircleRounded color={statusColor} />
        )}
      </Grid>
      <Grid item xs={9}>
        <Typography
          color={status === false ? 'error' : 'textSecondary'}
          variant="body2"
          sx={{ my: 'auto' }}
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};
PermissionStatusItemComponent.propTypes = {
  label: PropTypes.string.isRequired,
  status: PropTypes.bool,
};
PermissionStatusItemComponent.defaultProps = {
  status: undefined,
};

export const PermissionStatusItem = React.memo(PermissionStatusItemComponent);

export const a = {};
