import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WithCUR, WithTrial } from '@hooks/hoc';
import { HeaderLinkTabs } from '@components/styled';

const PlannerPage: React.FC = () => {
  const { t } = useTranslation('planner');
  return (
    <>
      <HeaderLinkTabs
        paths={[
          { label: t('header.uncovered'), to: 'uncovered' },
          { label: t('header.reserved'), to: 'reserved' },
          { label: t('header.savings'), to: 'committed' },
        ]}
      />
      <Outlet />
    </>
  );
};

export default WithTrial(WithCUR(PlannerPage));
