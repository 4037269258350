import React, { useEffect } from 'react';
import { ReactComponent as CloudShell } from '@assets/images/cloudshell_screen.svg';

const CloudShellTutorial: React.FC = () => {
  useEffect(() => {
    const blinker = document.querySelector('#aws_cloud_shell_cursor');
    const interval = setInterval(() => {
      if (blinker) {
        const width = Number(blinker.getAttribute('width'));
        blinker.setAttribute('width', width === 0 ? '2' : '0');
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);
  return <CloudShell style={{ maxWidth: '100%' }} />;
};

export default CloudShellTutorial;
