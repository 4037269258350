import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export type UpdatePasswordForm = {
  password: string;
  confirm: string;
};

export const useUpdatePasswordForm = (): UseValidation<UpdatePasswordForm> => {
  const { t } = useTranslation('validation');
  const { t: formT } = useTranslation('form');
  return {
    initialValues: {
      password: '',
      confirm: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .trim()
        .matches(/^.*[a-z]+.*$/, t('auth.password.lower'))
        .matches(/^.*[0-9]+.*$/, t('auth.password.digit'))
        .matches(/^.*[A-Z]+.*$/, t('auth.password.upper'))
        .min(8, t('auth.password.length'))
        .defined(
          t('custom.required', {
            context: 'post2',
            name: formT('holder.password'),
          }),
        ),
      confirm: Yup.string()
        .trim()
        .oneOf([Yup.ref('password')], t('auth.confirm'))
        .defined(
          t('custom.required', {
            context: 'post1',
            name: formT('holder.confirm'),
          }),
        ),
    }),
  };
};
