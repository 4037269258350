export default {
  50: 'rgba(240, 240, 247, 0.2)',
  100: 'rgba(240, 240, 247, 0.5)',
  200: '#F0F0F7',
  300: '#CECFDE',
  400: '#A0A1B2',
  500: '#8A8B99',
  600: '#737480',
  700: '#5C5C66',
  800: '#45454D',
  900: '#17171A',
};
