import { useMutation, useQueryClient } from 'react-query';
import { CouponService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: QUERY_KEY.ADMIN.COUPON.DELETE_COUPON,
    mutationFn: (variables: { CouponId: string }) =>
      CouponService.deleteCoupon(variables.CouponId),
    onSuccess: () =>
      client.invalidateQueries(QUERY_KEY.ADMIN.COUPON.GET_COUPONS),
  });
};
