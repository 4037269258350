import { AtomEffect } from 'recoil';
import _ from 'lodash';
import { decryptString, encryptString } from '@utils/crypto';
import { GLOBAL } from '@constants';

const getDecryptedState = (): Record<string, any> | null => {
  const globalState = localStorage.getItem(GLOBAL.RECOIL_STATE);
  if (globalState != null) {
    return JSON.parse(decryptString(globalState));
  }
  return null;
};

export const localStorageEffect =
  <T = any>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const state = getDecryptedState();
    if (state != null && _.has(state, key)) {
      setSelf(_.get(state, key));
    }
    onSet((newValue, __, isReset) => {
      const clone = _.cloneDeep(getDecryptedState());
      if (clone != null && isReset) {
        delete clone[key];
        localStorage.setItem(
          GLOBAL.RECOIL_STATE,
          encryptString(JSON.stringify(clone)),
        );
        return;
      }
      if (clone != null) {
        clone[key] = newValue;
        localStorage.setItem(
          GLOBAL.RECOIL_STATE,
          encryptString(JSON.stringify(clone)),
        );
      } else {
        const s = {
          [key]: newValue,
        };
        localStorage.setItem(
          GLOBAL.RECOIL_STATE,
          encryptString(JSON.stringify(s)),
        );
      }
    });
  };
