import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useAnomalyOverview } from '@hooks/api/optimization/anomaly';
import { CostSummaryChart } from '@components/chart/optimization';
import { bluegrey } from '@theme/colors';
import { ValueFormatter } from '@utils';

const AnomalySummaryChart: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { data } = useAnomalyOverview();
  const ServiceList = useMemo<
    Array<{ Service: string; Cost: number; Duration: number }>
  >(() => {
    if (
      data?.items?.length &&
      data.items[0] &&
      !_.isEmpty(data.items[0].Services)
    ) {
      return _.orderBy(
        Object.keys(data.items[0].Services).map((key) => ({
          Service: key,
          ...data.items[0].Services[key],
        })),
        'Cost',
        'desc',
      );
    }
    return [];
  }, [data?.items]);
  if (!data?.items?.length || !data.items[0]) {
    return null;
  }
  const { AnomaliesSpend, DetectedCount } = data.items[0];
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        border="2px solid #f0f0f7"
        px={6}
        py={3}
      >
        <Typography
          variant="subtitle1"
          dangerouslySetInnerHTML={{ __html: t('label.anomaly.totalExcess') }}
        />
        <Grid width="50%" container>
          <Grid item xs={6}>
            <Typography variant="h2" color={bluegrey[400]} align="right">
              {t('label.common.case', {
                count: DetectedCount,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h2" color="primary" align="right">
              {ValueFormatter.toCurrency(AnomaliesSpend)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {Boolean(ServiceList.length) && (
        <Box
          position="relative"
          bgcolor={bluegrey[50]}
          border="2px solid #f0f0f7"
          mt={3}
          px={6}
          py={4}
        >
          <CostSummaryChart
            data={ServiceList}
            chartInfo={{
              header: {
                label: t('label.common.service'),
                accessor: 'Service',
              },
              data: {
                label: t('label.anomaly.excessCost'),
                accessor: 'Cost',
              },
            }}
          />
        </Box>
      )}
    </>
  );
};

export default AnomalySummaryChart;
