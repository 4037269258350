import { useMutation } from 'react-query';
import { AWSAccountService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useMutation({
    mutationKey: QUERY_KEY.ETC.TECH_SUPPORT,
    mutationFn: (variables: {
      category: string;
      messages: string;
      companyName: string;
      email: string;
      token: string;
    }) =>
      AWSAccountService.sendEmailExternal(
        'techSupportEmail',
        'support@grumatic.com',
        {
          ...variables,
          token: undefined,
        },
        variables.token,
      ),
  });
