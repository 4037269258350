import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WithCUR, WithTrial } from '@hooks/hoc';
import { SuspensePaper } from '@components/styled/suspense';
import { HeaderTabContextWithRecoil } from '@components/styled';
import { detailsEC2State, detailsEC2TabState } from '@atoms/details';
import { TabPanel } from '@mui/lab';
import { OptimizationPaper } from '@components/styled/paper';
import DetailsComputeGeneral from './DetailsComputeGeneral';
import DetailsComputeUtilizationPage from './utilization';
import DetailsComputePurchasingPage from './purchasing';
import DetailsComputeTransferPage from './transfer';
import DetailsComputeOtherPage from './other';

const DetailsComputePage: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { instanceId } = useParams();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const setInstanceId = useSetRecoilState(detailsEC2State);
  const [selectedTab, setSelectedTab] = useRecoilState(detailsEC2TabState);
  useEffect(() => {
    if (
      tab === 'utilization' ||
      tab === 'purchasing' ||
      tab === 'transfer' ||
      tab === 'other'
    ) {
      setSelectedTab(tab);
    }
  }, [tab]);
  useEffect(() => {
    if (instanceId) {
      setInstanceId(instanceId);
    }
  }, [instanceId]);
  useEffect(() => () => setInstanceId(undefined), []);
  return (
    <HeaderTabContextWithRecoil
      items={[
        {
          label: t('label.header.utilization'),
          value: 'utilization',
        },
        {
          label: t('label.header.purchasing'),
          value: 'purchasing',
        },
        {
          label: t('label.header.transfer'),
          value: 'transfer',
        },
        {
          label: t('label.header.other'),
          value: 'other',
        },
      ]}
      state={detailsEC2TabState}
    >
      <Typography variant="subtitle2" color="textSecondary" mb={2}>
        {t('label.common.general')}
      </Typography>
      <SuspensePaper sx={{ mb: 6 }}>
        <DetailsComputeGeneral />
      </SuspensePaper>
      <OptimizationPaper showAPM={selectedTab === 'utilization'}>
        <TabPanel value="utilization">
          <DetailsComputeUtilizationPage />
        </TabPanel>
        <TabPanel value="purchasing">
          <DetailsComputePurchasingPage />
        </TabPanel>
        <TabPanel value="transfer">
          <DetailsComputeTransferPage />
        </TabPanel>
        <TabPanel value="other">
          <DetailsComputeOtherPage />
        </TabPanel>
      </OptimizationPaper>
    </HeaderTabContextWithRecoil>
  );
};

export default WithTrial(WithCUR(DetailsComputePage));
