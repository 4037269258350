import React, { Suspense } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogProps,
  Divider,
  Paper,
  Step,
  StepLabel,
  Stepper,
  StepperProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { GuideBox } from '@components/common';
import { OnboardPageWrapperProps, OnboardStepperProps } from './Onboard.types';

const OnboardSuspenseBlock = React.memo(() => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="200px"
  >
    <CircularProgress color="secondary" />
  </Box>
));

export const OnboardPageWrapper: React.FC<OnboardPageWrapperProps> = ({
  title,
  direction = 'column',
  disablePadding = false,
  children,
}) => (
  <>
    <Paper square>
      <Box>
        <Typography variant="subtitle2" mx={8} my={5}>
          {title}
        </Typography>
        <Divider />
        <Box
          display="flex"
          flexDirection={direction}
          p={disablePadding ? 0 : 7}
        >
          <Suspense fallback={<OnboardSuspenseBlock />}>{children}</Suspense>
        </Box>
      </Box>
    </Paper>
    <GuideBox />
  </>
);

const OnboardStepperComponent: React.FC<
  OnboardStepperProps & Pick<StepperProps, 'sx'>
> = ({ activeStep, stepLabels, shrink, sx }) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Stepper
      activeStep={activeStep}
      orientation={isSmall ? 'vertical' : 'horizontal'}
      sx={sx}
    >
      {stepLabels.map((label, idx) => (
        <Step key={`step-${label}_${idx.toString()}`}>
          <StepLabel
            StepIconProps={{ variant: 'default' }}
            sx={{
              '> .MuiStepLabel-iconContainer': {
                paddingRight:
                  shrink && !isSmall && activeStep !== idx ? 0 : '1.5rem',
              },
            }}
          >
            {shrink && !isSmall && activeStep === idx && label}
            {shrink && isSmall && label}
            {!shrink && label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
export const OnboardStepper = React.memo(OnboardStepperComponent);

export const OnboardEmailDialog: React.FC<
  Pick<DialogProps, 'open' | 'onClose'>
> = ({ open, onClose, children }) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm">
    {children}
  </Dialog>
);
