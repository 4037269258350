import { useQuery } from 'react-query';
import { PlannerService } from '@api/service';
import { QUERY_KEY } from '@constants';

export const usePlannerUncoveredDetail = (instanceId: string) =>
  useQuery([QUERY_KEY.PLANNER.OD_DETAIL, instanceId], ({ queryKey }) =>
    PlannerService.getPlannerUncoveredDetail(queryKey[1]),
  );

export const usePlannerRIDetail = (item: EC2RIUtilization) =>
  useQuery([QUERY_KEY.PLANNER.RI_DETAIL, item.UID], () =>
    PlannerService.getPlannerRIDetail(item),
  );

export const usePlannerSPDetail = (UID: string) =>
  useQuery([QUERY_KEY.PLANNER.SP_DETAIL, UID], ({ queryKey }) =>
    PlannerService.getPlannerSPDetail(queryKey[1]),
  );
