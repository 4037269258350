import React, { useCallback, useEffect, useState } from 'react';
import { useGetUsers, useUpdateUser } from '@hooks/api/settings/user';
import { CCModalProps, ConfirmDialog } from '@components/modal';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@hooks/common';
import { isAxiosError } from '@utils';

const ActivateUserModal: React.FC<CCModalProps & { uid?: string }> = ({
  open,
  onClose,
  uid,
}) => {
  const { t } = useTranslation('dialog');
  const { t: commonT } = useTranslation('common');
  const { t: toastT } = useTranslation('toast');
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { data } = useGetUsers();
  const { mutateAsync } = useUpdateUser();
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const needActivate =
    typeof selectedUser?.deactivation === 'boolean'
      ? selectedUser.deactivation
      : false;

  const updateUser = useCallback(async () => {
    try {
      await mutateAsync({
        original: selectedUser,
        changes: { deactivation: !selectedUser.deactivation },
      });
      showSuccessSnackbar(
        selectedUser.deactivation
          ? toastT('success.settings.user.activate')
          : toastT('success.settings.user.deactivate'),
      );
      onClose();
    } catch (e) {
      if (isAxiosError(e) && e.response?.data?.msg) {
        switch (e.response.data.msg) {
          case 'This user is last admin':
            showErrorSnackbar("You can't deactivate the last Admin account");
            break;
          default:
            showErrorSnackbar(toastT('error.general'));
            break;
        }
      }
    }
  }, [selectedUser]);

  useEffect(() => {
    if (data?.items?.length && uid) {
      const user = data.items.find((v) => v.uid === uid);
      if (user) {
        setSelectedUser(user);
      } else {
        onClose();
      }
    }
  }, [data?.items, uid]);

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={t(
        `settings.user.${needActivate ? 'activate' : 'deactivate'}.title`,
      )}
      content={t(
        `settings.user.${needActivate ? 'activate' : 'deactivate'}.content`,
      )}
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: commonT('button.cancel'),
        },
        {
          onClick: updateUser,
          label: commonT('button.confirm'),
          disabled: !selectedUser,
        },
      ]}
    />
  );
};
ActivateUserModal.defaultProps = {
  uid: undefined,
};

export default ActivateUserModal;
