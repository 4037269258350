import { useQuery } from 'react-query';
import { usePaginatedQuery } from '@hooks/api/base';
import { OptimizationS3Service } from '@api/service';
import { QUERY_KEY } from '@constants';
import { useRecoilValue } from 'recoil';
import { detailsS3State } from '@atoms/details';
import { useSelectedAWSAccounts } from '@hooks/api/common';

export const useS3Information = () =>
  usePaginatedQuery(
    QUERY_KEY.OPTIMIZATION.S3.INFORMATION,
    OptimizationS3Service.getS3InformationList,
    { suspense: true, useErrorBoundary: true },
  );

export const useS3UsageChart = () => {
  const { apiQueryString } = useSelectedAWSAccounts();
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.S3.USAGE_CHART, apiQueryString],
    ({ queryKey }) => OptimizationS3Service.getS3UsageChart(queryKey[1]),
    { suspense: true, useErrorBoundary: true },
  );
};

export const useS3AutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.S3.AC_DATA,
    () => OptimizationS3Service.getS3AutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useS3DetailsGeneral = () => {
  const bucketName = useRecoilValue(detailsS3State);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.S3.DETAILS_GENERAL, bucketName],
    ({ queryKey }) => OptimizationS3Service.getS3DetailsGeneral(queryKey[1]),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(bucketName) },
  );
};

export const useS3DetailsSummary = () => {
  const bucketName = useRecoilValue(detailsS3State);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.S3.DETAILS_SUMMARY, bucketName],
    ({ queryKey }) => OptimizationS3Service.getS3DetailsSummary(queryKey[1]),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(bucketName) },
  );
};
