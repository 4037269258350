import React, { useMemo } from 'react';
// import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useGetDepositHistory } from '@hooks/api/cmp';
import { GrmtTableColumnInfo, SimplePaginationTable } from '@components/table';
import { ValueFormatter } from '@utils';

const DepositHistory: React.FC = () => {
  const { t } = useTranslation('report');
  // const { t: commonT } = useTranslation('common');
  const { data: history } = useGetDepositHistory();
  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('table.billing_month'),
        accessor: 'InvoiceDate' as const,
        Cell: ({ value }) =>
          ValueFormatter.toDateString(value, 'YYYY/MM') ?? '',
        disableGlobalFilter: true,
      },
      {
        Header: t('table.usage_period'),
        Cell: ({ row }) => {
          if (row.original.ItemType === 'Used') {
            return `${ValueFormatter.toDateString(
              moment(row.original.InvoiceDate).startOf('month').toDate(),
              'YYYY/MM/DD',
            )} ~ ${ValueFormatter.toDateString(
              moment(row.original.InvoiceDate).endOf('month').toDate(),
              'YYYY/MM/DD',
            )}`;
          }
          return '';
        },
        disableGlobalFilter: true,
      },
      {
        Header: t('table.deposit'),
        align: 'right',
        Cell: ({ row }) => {
          if (row.original.ItemType === 'Deposit') {
            return (
              ValueFormatter.toCurrency(row.original.Amount, 'KRW', 0) ?? '-'
            );
          }
          return '';
        },
      },
      {
        Header: t('table.deposit_date'),
        align: 'right',
        Cell: ({ row }) => {
          if (row.original.ItemType === 'Deposit') {
            return ValueFormatter.toDateString(
              row.original.UsageDate,
              'YYYY/MM/DD',
            );
          }
          return '';
        },
        disableGlobalFilter: true,
      },
      {
        Header: t('table.deducted'),
        align: 'right',
        Cell: ({ row }) => {
          if (row.original.ItemType === 'Used') {
            return (
              ValueFormatter.toCurrency(row.original.Amount, 'KRW', 0) ?? '-'
            );
          }
          return '';
        },
      },
      {
        Header: t('table.invoiced_date'),
        align: 'right',
        Cell: ({ row }) => {
          if (row.original.ItemType === 'Used') {
            return ValueFormatter.toDateString(
              row.original.UsageDate,
              'YYYY/MM/DD',
            );
          }
          return '';
        },
        disableGlobalFilter: true,
      },
      {
        Header: t('table.balance'),
        accessor: 'Remaining' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value, 'KRW', 0),
      },
    ],
    [],
  );
  return (
    <SimplePaginationTable
      columnInfo={columnInfo}
      data={_.reverse(_.cloneDeep(history?.data ?? []))}
      // ExtraElement={
      //   <Box display="flex" alignItems="center" minHeight="3rem" py={1} mr={2}>
      //     <Typography variant="body2">{commonT('label.lastUpdate')}</Typography>
      //     <Typography variant="body2" ml={2}>
      //       {ValueFormatter.toDateString(new Date(), 'YYYY/MM/DD hh:mm')}
      //     </Typography>
      //   </Box>
      // }
      enableFilter
    />
  );
};

export default DepositHistory;
