import React, { useCallback } from 'react';
import { CCModalProps, ConfirmDialog } from '@components/modal';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  simulateRequestState,
  simulateResponseState,
} from '@pages/private/planner/simulation/Simulation.atoms';
import {
  isReservedRequest,
  isReservedResponse,
} from '@pages/private/planner/simulation/Simulation.utils';
import {
  usePurchaseRI,
  usePurchaseSP,
  useSimulationGeneral,
} from '@hooks/api/planner';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from '@hooks/common';
import { ROUTES } from '@routes';

const PurchaseConfirmModal: React.FC<CCModalProps> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { instanceId } = useParams();
  const { t } = useTranslation('dialog');
  const { t: toastT } = useTranslation('toast');
  const { t: commonT } = useTranslation('common');
  const { t: optT } = useTranslation('optimization');
  const { showSnackbar } = useSnackbar();
  const request = useRecoilValue(simulateRequestState);
  const response = useRecoilValue(simulateResponseState);
  const { data } = useSimulationGeneral(instanceId, false);
  const { mutateAsync: fetchRI } = usePurchaseRI();
  const { mutateAsync: fetchSP } = usePurchaseSP();
  const makePurchase = useCallback(async () => {
    if (data?.items?.length && request && response) {
      const { SelectedInstance } = data.items[0];
      const { DiscountProgram } = request;
      const { SimulatedUsage } = response;
      if (
        isReservedRequest(DiscountProgram) &&
        isReservedResponse(SimulatedUsage)
      ) {
        try {
          await fetchRI({
            InstanceType: SimulatedUsage.SimulatedInstanceType,
            OperatingSystem: SelectedInstance.Platform,
            Term: DiscountProgram.Duration,
            Tenancy: SelectedInstance.Tenancy,
            OfferingClass: DiscountProgram.Type,
            PaymentOption: DiscountProgram.PaymentOption,
            NumberOfRI: DiscountProgram.NumberValue,
          });
          showSnackbar(
            toastT('success.planner.simulation.purchased.ri'),
            'success',
          );
          navigate(ROUTES.PLANNER.UNCOVERED);
          onClose();
        } catch (e) {
          showSnackbar(toastT('error.general'), 'error');
          onClose();
          console.error(e);
        }
      }
      if (
        !isReservedRequest(DiscountProgram) &&
        !isReservedResponse(SimulatedUsage)
      ) {
        try {
          await fetchSP({
            SPType: DiscountProgram.Type,
            Term: DiscountProgram.Duration,
            Region: SelectedInstance.Region,
            Tenancy: SelectedInstance.Tenancy,
            PaymentOption: DiscountProgram.PaymentOption,
            OperatingSystem: SelectedInstance.Platform,
            InstanceFamily: SelectedInstance.InstanceFamily,
            Commitment: SimulatedUsage.SimulatedCommitment,
            UpfrontFee: SimulatedUsage.UpfrontFee,
          });
          showSnackbar(
            toastT('success.planner.simulation.purchased.sp'),
            'success',
          );
          navigate(ROUTES.PLANNER.UNCOVERED);
          onClose();
        } catch (e) {
          showSnackbar(toastT('error.general'), 'error');
          onClose();
          console.error(e);
        }
      }
    }
  }, [data?.items, request, response]);
  if (!data?.items?.length || !request || !response) {
    return null;
  }
  const { DiscountProgram } = request;
  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={t('planner.simulation.purchase.title', {
        label: isReservedRequest(DiscountProgram)
          ? optT('label.common.ri')
          : optT('label.common.sp'),
      })}
      content={t('planner.simulation.purchase.content', {
        label: isReservedRequest(DiscountProgram)
          ? optT('label.common.ri')
          : optT('label.common.sp'),
      })}
      Actions={[
        {
          label: commonT('button.cancel'),
          onClick: onClose,
          color: 'inherit',
        },
        {
          label: t('planner.simulation.purchase.button'),
          onClick: makePurchase,
        },
      ]}
    />
  );
};

export default PurchaseConfirmModal;
