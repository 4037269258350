import React, { Suspense } from 'react';
import { QueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import {
  Box,
  BoxProps,
  CircularProgress,
  IconButton,
  Paper,
  PaperProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';

type ErrorTextProp = {
  errorText?: string;
};

const ErrorBoundaryText: React.FC<
  Omit<TypographyProps, 'variant' | 'color'> & ErrorTextProp
> = ({ errorText, ...props }) => {
  const { t } = useTranslation('common', { useSuspense: false });
  return (
    <Typography variant="subtitle2" color="textSecondary" {...props}>
      {errorText || t('label.errorOccurred')}
    </Typography>
  );
};
ErrorBoundaryText.defaultProps = {
  errorText: undefined,
};

const ErrorBoundaryFallback = (errorText?: string): React.FC<FallbackProps> =>
  function ({ resetErrorBoundary }) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        p={10}
      >
        <ErrorBoundaryText errorText={errorText} mb={1} />
        <IconButton
          aria-label="Retry"
          size="large"
          onClick={() => resetErrorBoundary()}
        >
          <RefreshIcon fontSize="inherit" />
        </IconButton>
      </Box>
    );
  };

function SuspenseFallback({
  size = 64,
  ...props
}: BoxProps & { size?: number }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      p={10}
      {...props}
    >
      <CircularProgress size={size} />
    </Box>
  );
}
SuspenseFallback.defaultProps = {
  size: 64,
};

export function SuspensePaper({
  children,
  errorText,
  suspenseProps,
  ...props
}: PaperProps &
  ErrorTextProp & { suspenseProps?: BoxProps & { size?: number } }) {
  return (
    <Paper square {...props}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={ErrorBoundaryFallback(errorText)}
          >
            <Suspense fallback={<SuspenseFallback {...suspenseProps} />}>
              {children}
            </Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </Paper>
  );
}
SuspensePaper.defaultProps = {
  errorText: undefined,
  suspenseProps: undefined,
};

export const SuspenseBox: React.FC<BoxProps & ErrorTextProp> = ({
  children,
  errorText,
  ...props
}) => (
  <Box {...props}>
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={ErrorBoundaryFallback(errorText)}
        >
          <Suspense fallback={<SuspenseFallback />}>{children}</Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  </Box>
);
SuspenseBox.defaultProps = {
  errorText: undefined,
};
