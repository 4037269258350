import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useRDSRIUtilAutoComplete } from '@hooks/api/optimization/rds';
import { useFilterFormik } from '@hooks/common';
import { useAWSHelper } from '@hooks/helper';
import {
  FilterGrid,
  FilterGridItem,
  FilterProps,
  FilterSelect,
  FilterWrapper,
} from '@components/filter';
import { InputAdornment, TextField } from '@mui/material';

type DatabaseUtilFilterForm = {
  Region: Array<string>;
  ClusterId: string;
  InstanceId: string;
  InstanceType: Array<string>;
  DatabaseEngine: Array<string>;
  Utilization: string;
};

const useDatabaseUtilFilterForm =
  (): UseValidation<DatabaseUtilFilterForm> => ({
    initialValues: {
      Region: [],
      ClusterId: '',
      InstanceId: '',
      InstanceType: [],
      DatabaseEngine: [],
      Utilization: '',
    },
    validationSchema: Yup.object({
      Region: Yup.array().of(Yup.string()),
      ClusterId: Yup.string(),
      InstanceId: Yup.string(),
      InstanceType: Yup.array().of(Yup.string()),
      DatabaseEngine: Yup.array().of(Yup.string()),
      Utilization: Yup.string(),
    }).defined(),
  });

const DatabaseUtilFilter: React.FC<FilterProps> = ({
  onClose,
  onFilterChange,
  previousValues,
}) => {
  const { t } = useTranslation('optimization');
  const { getAWSRegionName } = useAWSHelper();
  const { data } = useRDSRIUtilAutoComplete();
  const { initialValues, validationSchema } = useDatabaseUtilFilterForm();
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    handleArrayChange,
    handleBlur,
    handleSubmit,
  } = useFilterFormik(
    {
      initialValues: previousValues || initialValues,
      validationSchema,
      onSubmit: (v) => {
        onFilterChange(v);
        onClose();
      },
    },
    data?.items,
  );
  if (!data?.items?.length) {
    return null;
  }
  const { DatabaseEngine, Region, InstanceType } = data.items[0];
  return (
    <FilterWrapper onClose={onClose} onSubmit={handleSubmit} isValid={isValid}>
      <FilterGrid>
        <FilterGridItem label={t('label.common.region')}>
          <FilterSelect
            name="Region"
            value={values.Region}
            onChange={(e) => handleArrayChange('Region', e.target.value)}
            onBlur={handleBlur}
            renderValue={() =>
              values.Region.map((v: string) => getAWSRegionName(v)).join(', ')
            }
            selected={values.Region}
            candidate={Region}
            formatter={getAWSRegionName}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.db.clusterId')}>
          <TextField
            name="ClusterId"
            value={values.ClusterId}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.db.databaseId')}>
          <TextField
            name="InstanceId"
            value={values.InstanceId}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.db.databaseType')}>
          <FilterSelect
            name="InstanceType"
            value={values.InstanceType}
            onChange={(e) => handleArrayChange('InstanceType', e.target.value)}
            onBlur={handleBlur}
            renderValue={() => values.InstanceType.join(', ')}
            selected={values.InstanceType}
            candidate={InstanceType}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.db.databaseEngine')}>
          <FilterSelect
            name="DatabaseEngine"
            value={values.DatabaseEngine}
            onChange={(e) =>
              handleArrayChange('DatabaseEngine', e.target.value)
            }
            onBlur={handleBlur}
            renderValue={() => values.DatabaseEngine.join(', ')}
            selected={values.DatabaseEngine}
            candidate={DatabaseEngine}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.ri_util')}>
          <TextField
            name="Utilization"
            value={values.Utilization}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{
              inputProps: {
                style: { textAlign: 'right' },
              },
              startAdornment: t('label.filter.under')?.length ? (
                <InputAdornment position="start">
                  {t('label.filter.under')}
                </InputAdornment>
              ) : undefined,
              endAdornment: (
                <InputAdornment position="end">
                  {t('label.filter.percent')}
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.Utilization && errors.Utilization)}
            helperText={
              Boolean(touched.Utilization && errors.Utilization) &&
              String(errors.Utilization)
            }
            fullWidth
          />
        </FilterGridItem>
      </FilterGrid>
    </FilterWrapper>
  );
};

export default DatabaseUtilFilter;
