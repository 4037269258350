import React, { useMemo } from 'react';
import { ReactComponent as FilterSortIcon } from '@assets/icons/common/FilterSort.svg';
import { ReactComponent as FilterSortDownIcon } from '@assets/icons/common/FilterSortDown.svg';
import { ReactComponent as FilterSortUpIcon } from '@assets/icons/common/FilterSortUp.svg';
import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  MenuItem,
  outlinedInputClasses,
  Select,
  selectClasses,
  SelectProps,
  Stack,
  styled,
  SvgIcon,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import { bluegrey, primary } from '@theme/colors';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  recommendCategoryState,
  recommendPeriodState,
} from '@atoms/optimization';
import i18n from '@utils/i18n';
import { QuestionTooltip } from '@components/styled/tooltip';
import { useSnackbar } from '@hooks/common';
import { hasCURState } from '@atoms/global';

export const TableSortIcon = React.memo(
  ({
    isSorted = false,
    isDesc = false,
  }: {
    isSorted: boolean | undefined;
    isDesc: boolean | undefined;
  }) => {
    const IconElement = useMemo(() => {
      if (!isSorted) {
        return FilterSortIcon;
      }
      if (isDesc) {
        return FilterSortDownIcon;
      }
      return FilterSortUpIcon;
    }, [isSorted, isDesc]);

    return (
      <SvgIcon
        component={IconElement}
        viewBox="0 0 20 24"
        sx={{
          width: '0.625rem',
          height: '0.75rem',
          verticalAlign: 'middle',
          ml: 1,
        }}
      />
    );
  },
);

export const DetailButtonIcon = React.memo(
  ({ onClick }: Pick<IconButtonProps, 'onClick'>) => (
    <IconButton
      color="primary"
      onClick={onClick}
      sx={{
        position: 'inherit',
        width: '2rem',
        height: '2rem',
        backgroundColor: 'rgba(120, 0, 239, 0.04)',
      }}
    >
      <ArrowForwardIosIcon sx={{ width: '1rem', height: '1rem' }} />
    </IconButton>
  ),
);

const DetailLinkIconBaseComponent = React.memo(({ to }: { to: string }) => (
  <IconButton
    color="primary"
    component={Link}
    to={to}
    sx={{
      position: 'inherit',
      width: '2rem',
      height: '2rem',
      backgroundColor: 'rgba(120, 0, 239, 0.04)',
    }}
  >
    <ArrowForwardIosIcon sx={{ width: '1rem', height: '1rem' }} />
  </IconButton>
));
const DetailLinkIconCURComponent = React.memo(({ to }: { to: string }) => {
  const { t } = useTranslation('toast');
  const { showInfoSnackbar } = useSnackbar();
  const hasCUR = useRecoilValue(hasCURState);
  if (!hasCUR) {
    return (
      <DetailButtonIcon
        onClick={() => showInfoSnackbar(t('info.optimization.cur'))}
      />
    );
  }
  return <DetailLinkIconBaseComponent to={to} />;
});
const DetailLinkIconComponent: React.FC<{
  to: string;
  requireCUR?: boolean;
}> = ({ to, requireCUR }) => {
  if (requireCUR) {
    return <DetailLinkIconCURComponent to={to} />;
  }
  return <DetailLinkIconBaseComponent to={to} />;
};
DetailLinkIconComponent.defaultProps = {
  requireCUR: false,
};
export const DetailLinkIcon = React.memo(DetailLinkIconComponent);

export const TableActionButton = styled(
  (props: Pick<ButtonProps, 'onClick' | 'children' | 'endIcon'>) => (
    <Button {...props} variant="text" />
  ),
)(({ theme }) => ({
  height: '100%',
  backgroundColor: primary[50],
  fontSize: theme.typography.body2.fontSize,
  lineHeight: theme.typography.body2.lineHeight,
  fontWeight: theme.typography.body2.fontWeight,
  padding: '0.622rem 1.5rem',
  borderRadius: '0.25rem',
  '&:hover': {
    backgroundColor: primary[100],
    boxShadow: 'none',
  },
}));

// export const TableActionSelectStandard = styled(
//   (props: Pick<SelectProps, 'value' | 'onChange' | 'children'>) => (
//     <Select
//       {...props}
//       variant="standard"
//       size="small"
//       IconComponent={KeyboardArrowDownIcon}
//       disableInjectingGlobalStyles
//       disableUnderline
//     />
//   ),
// )(({ theme }) => ({
//   fontSize: theme.typography.body2.fontSize,
//   lineHeight: theme.typography.body2.lineHeight,
//   fontWeight: theme.typography.body2.fontWeight,
//   [`> .${inputClasses.input}`]: {
//     padding: '0.625rem 1rem',
//   },
//   [`> .${selectClasses.icon}`]: {
//     width: '1.25rem',
//     height: '1.25rem',
//     color: '#323232',
//     top: '0.625rem',
//   },
// }));

export const TableActionSelect = styled(
  (props: Pick<SelectProps, 'value' | 'onChange' | 'children' | 'sx'>) => (
    <Select
      {...props}
      variant="outlined"
      size="small"
      IconComponent={KeyboardArrowDownIcon}
    />
  ),
)({
  '&:hover': {
    borderColor: bluegrey[300],
  },
  [`> .${outlinedInputClasses.inputSizeSmall}`]: {
    padding: '0.622rem 0.625rem',
    backgroundColor: bluegrey[50],
  },
  [`> .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: bluegrey[200],
  },
  [`&.Mui-focused > .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: bluegrey[300],
    borderWidth: '1px',
  },
  '&.Mui-active': {
    backgroundColor: bluegrey[100],
  },
  [`> .${selectClasses.icon}`]: {
    width: '1.25rem',
    height: '1.25rem',
    color: '#323232',
    top: '0.625rem',
  },
});

const TableRecommendationPeriodComponent: React.FC = () => {
  const lang = i18n.language;
  const { t: commonT } = useTranslation('common');
  const [period, setPeriod] = useRecoilState(recommendPeriodState);
  return (
    <TableActionSelect
      value={period}
      onChange={(e) => setPeriod(e.target.value as RecommendPeriod)}
      sx={{ width: lang === 'ko' ? '4.5rem' : '6rem' }}
    >
      <MenuItem value="Days14">{commonT('label.days', { count: 14 })}</MenuItem>
      <MenuItem value="Days30">{commonT('label.days', { count: 30 })}</MenuItem>
      <MenuItem value="Days60">{commonT('label.days', { count: 60 })}</MenuItem>
    </TableActionSelect>
  );
};
export const TableRecommendationPeriod = React.memo(
  TableRecommendationPeriodComponent,
);

export const TableUtilizationRecommendation = React.memo(() => {
  const { t } = useTranslation('optimization');
  const [option, setOption] = useRecoilState(recommendCategoryState);
  return (
    <Stack direction="row" alignItems="center" spacing={2} mr={2}>
      <TableActionSelect
        value={option}
        onChange={(e) => setOption(e.target.value as EC2RecommendOption)}
        sx={{ width: '11rem' }}
      >
        <MenuItem value="SavingsFirst">
          {t('label.common.savingsFirst')}
        </MenuItem>
        <MenuItem value="StabilityFirst">
          {t('label.common.stabilityFirst')}
        </MenuItem>
        <MenuItem value="Chipset">
          {t('label.common.chipsetCompatibility')}
        </MenuItem>
      </TableActionSelect>
      <TableRecommendationPeriod />
      <QuestionTooltip
        title={t('label.tooltip.ec2RecommendOption')}
        size="1rem"
        color="bluegrey"
      />
    </Stack>
  );
});
