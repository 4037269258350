import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WithDiagnostic, WithTrial } from '@hooks/hoc';
import { HeaderLinkTabs } from '@components/styled';

const OptimizationDatabasePage: React.FC = () => {
  const { t } = useTranslation('optimization');
  return (
    <>
      <HeaderLinkTabs
        paths={[
          { label: t('label.header.utilization'), to: 'utilization' },
          { label: t('label.header.purchasing'), to: 'purchasing' },
          { label: t('label.header.storage'), to: 'storage' },
        ]}
      />
      <Outlet />
    </>
  );
};

export default WithTrial(WithDiagnostic(OptimizationDatabasePage));
