import React from 'react';
import { DetailsComputeUtilChart } from './chart';
import DetailsComputeUtilRecommend from './recommendation/DetailsComputeUtilRecommend';

const DetailsComputeUtilizationPage: React.FC = () => (
  <>
    <DetailsComputeUtilChart />
    <DetailsComputeUtilRecommend />
  </>
);

export default DetailsComputeUtilizationPage;
