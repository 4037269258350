import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import i18n from '@utils/i18n';
import { UserService } from '@api/service';
import { companyIdState, userIdState } from '@atoms/global';
import { GLOBAL, QUERY_KEY } from '@constants';

export default (suspense = true) => {
  const { pathname } = useLocation();
  const activeToken = useMemo<string | null>(
    () => localStorage.getItem(GLOBAL.ACCESS_TOKEN),
    [pathname],
  );
  const userId = useRecoilValue(userIdState);
  const [companyId, setCompanyId] = useRecoilState(companyIdState);
  return useQuery(
    [QUERY_KEY.CURRENT.USERS, userId],
    ({ queryKey }) => {
      if (queryKey[1]) {
        return UserService.getById(queryKey[1]);
      }
      return undefined;
    },
    {
      cacheTime: 15000,
      suspense,
      enabled: Boolean(activeToken) && Boolean(userId),
      onSuccess: (data) => {
        if (data?.items?.length) {
          if (companyId !== data.items[0].companyId) {
            setCompanyId(data.items[0].companyId);
          }
          if (
            data.items[0].language &&
            i18n.language !== data.items[0].language
          ) {
            i18n.changeLanguage(data.items[0].language);
          }
        }
      },
    },
  );
};
