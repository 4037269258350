import { useQuery } from 'react-query';
import { CMPService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useQuery(
    [QUERY_KEY.CMP.LAST_MONTH],
    () => CMPService.getLastMonthBillingReport(),
    { suspense: true, useErrorBoundary: true },
  );
