import React from 'react';
import { WithAdmin } from '@hooks/hoc';
import { LinkedAccountStep } from '@pages/private/onboard/diagnostic/steps';
import { SuspensePaper } from '@components/styled/suspense';

const SettingsOrganizationPage: React.FC = () => (
  <SuspensePaper sx={{ px: 7, py: 5 }}>
    <LinkedAccountStep />
  </SuspensePaper>
);

export default WithAdmin(SettingsOrganizationPage);
