import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Link, Stack, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import {
  useEC2DetailsGeneral,
  useEC2DetailsTransferSummary,
} from '@hooks/api/optimization/ec2';
import { ValueFormatter } from '@utils';
import { ReactComponent as WarningCircle } from '@assets/icons/common/WarningCircle.svg';
import { ROUTES } from '@routes';
import { bluegrey } from '@theme/colors';
import { CostSummaryChart } from '@components/chart/optimization';

const DetailsComputeTransferSummary: React.FC = () => {
  const { instanceId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('optimization');
  const { data: general } = useEC2DetailsGeneral();
  const { data } = useEC2DetailsTransferSummary();
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        border="2px solid #f0f0f7"
        px={6}
        py={3}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">
            {t('label.transfer.totalCost')}
          </Typography>
          <Typography variant="h2" color="primary" align="right">
            {ValueFormatter.toCurrency(data?.extras?.TotalCost) || '-'}
          </Typography>
        </Box>
        {Boolean(data?.extras?.WasteCost) && (
          <>
            <Box borderBottom="1px solid #f0f0f7" my={3} />
            <Stack
              display="flex"
              direction={{ md: 'row', xs: 'column' }}
              justifyContent={{ md: 'space-between', xs: undefined }}
              alignItems={{ md: 'center', xs: undefined }}
              spacing={1}
            >
              <Box display="flex" alignItems="center">
                <WarningCircle />
                <Typography variant="body2" ml={1}>
                  {t('label.transfer.wasted')}:
                </Typography>
                <Typography variant="body2" ml={3}>
                  {ValueFormatter.toCurrency(data?.extras?.WasteCost) || '-'}
                </Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                {t('label.transfer.changeIP')}
              </Typography>
              {general?.items?.length && general.items[0] && instanceId && (
                <Link
                  display="flex"
                  alignItems="center"
                  variant="body2"
                  color="textSecondary"
                  fontWeight={500}
                  target="_blank"
                  underline="none"
                  href={`https://console.aws.amazon.com/ec2/home?region=${general.items[0].Region}#InstanceDetails:instanceId=${instanceId}`}
                >
                  <span>{t('button.common.goToEC2')}</span>
                  <KeyboardArrowRightIcon sx={{ fontSize: '1.25rem' }} />
                </Link>
              )}
            </Stack>
          </>
        )}
      </Box>
      {data?.items?.length && (
        <Box
          bgcolor={bluegrey[50]}
          border="2px solid #f0f0f7"
          mt={3}
          px={6}
          py={4}
        >
          <CostSummaryChart
            data={data.items.map((item) => ({
              ...item,
              Type: t(`label.transfer.${item.Type as EC2TransferKeys}`),
            }))}
            chartInfo={{
              header: {
                label: t('label.transfer.type'),
                accessor: 'Type',
              },
              data: {
                label: t('label.common.cost'),
                accessor: 'Cost',
              },
              extra: {
                label: `${t('label.transfer.traffic')} (GB)`,
                accessor: 'Traffic',
                formatter: ValueFormatter.toLocaleString,
              },
            }}
          />
        </Box>
      )}
      <Box display="flex" mt={3}>
        <Typography variant="body2" color="textSecondary" mr={3}>
          {t('label.transfer.viewTable')}
        </Typography>
        <Link
          href="##"
          onClick={(e: any) => {
            e.preventDefault();
            navigate(ROUTES.COMPUTE.TRANSFER);
          }}
          underline="none"
          variant="body2"
          color="textSecondary"
          fontWeight={500}
          display="flex"
          alignItems="center"
        >
          <span>{t('label.transfer.overallTransfer')}</span>
          <KeyboardArrowRightIcon sx={{ fontSize: '1.25rem' }} />
        </Link>
      </Box>
    </>
  );
};

export default DetailsComputeTransferSummary;
