import React, { useCallback } from 'react';
import { useDeleteCompany } from '@hooks/api/admin/company';
import { CCModalProps, ConfirmDialog } from '@components/modal';
import { useSnackbar } from '@hooks/common';

const DeleteCompanyModal: React.FC<CCModalProps & { companyId: string }> = ({
  open,
  onClose,
  companyId,
}) => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutateAsync } = useDeleteCompany();
  const deleteCompany = useCallback(async () => {
    try {
      await mutateAsync({ companyId });
      showSuccessSnackbar('Company has been deleted');
      onClose();
    } catch (e) {
      showErrorSnackbar('Error occurred while delete company');
    }
  }, [companyId]);
  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title="Delete Company"
      content="Are you sure to delete company?"
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: deleteCompany,
        },
      ]}
    />
  );
};

export default DeleteCompanyModal;
