import React from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ValueFormatter } from '@utils';
import { bluegrey, tertiary } from '@theme/colors';
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt';
import { LegendWithPercent } from '@components/styled';
import { PlannerLinearChart } from '@components/chart/planner';

export const PurchasingOptionRadioGroup = React.memo(
  ({
    value,
    onChange,
    Aggressive,
    Passive,
  }: Pick<RadioGroupProps, 'value' | 'onChange'> & {
    Aggressive: number;
    Passive: number;
  }) => {
    const { t } = useTranslation('optimization');
    const { t: plannerT } = useTranslation('planner');
    return (
      <RadioGroup value={value} onChange={onChange}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Paper variant="elevation" sx={{ px: 5, py: 4 }}>
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  value="Aggressive"
                  control={<Radio size="small" />}
                  label={plannerT('PlanType.Aggressive')}
                  componentsProps={{
                    typography: {
                      color:
                        value === 'Aggressive'
                          ? 'textPrimary'
                          : 'textSecondary',
                    },
                  }}
                  sx={{ flexGrow: 1 }}
                />
                <Typography
                  variant="body2"
                  color={
                    value === 'Aggressive' ? 'textPrimary' : 'textSecondary'
                  }
                >
                  {t('label.common.savings_monthly')}
                </Typography>
                <Typography
                  variant="h3"
                  color={value === 'Aggressive' ? 'primary' : 'textSecondary'}
                  ml={2}
                >
                  {ValueFormatter.toCurrency(Aggressive)}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper variant="elevation" sx={{ px: 5, py: 4 }}>
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  value="Passive"
                  control={<Radio size="small" />}
                  label={plannerT('PlanType.Passive')}
                  componentsProps={{
                    typography: {
                      color:
                        value === 'Passive' ? 'textPrimary' : 'textSecondary',
                    },
                  }}
                  sx={{ flexGrow: 1 }}
                />
                <Typography
                  variant="body2"
                  color={value === 'Passive' ? 'textPrimary' : 'textSecondary'}
                >
                  {t('label.common.savings_monthly')}
                </Typography>
                <Typography
                  variant="h3"
                  color={value === 'Passive' ? 'primary' : 'textSecondary'}
                  ml={2}
                >
                  {ValueFormatter.toCurrency(Passive)}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </RadioGroup>
    );
  },
);

type PurchasingComparisonProps = {
  Family: string;
  CostBefore: number;
  CostAfter: number;
  CostPercent: number;
  OnDemandBefore: number;
  OnDemandAfter: number;
  RIBefore: number;
  RIAfter: number;
  SPBefore: number;
  SPAfter: number;
  enableCostWill?: boolean;
};
const PurchasingComparisonComponent: React.FC<PurchasingComparisonProps> = ({
  Family,
  CostBefore,
  CostAfter,
  CostPercent,
  OnDemandBefore,
  OnDemandAfter,
  RIBefore,
  RIAfter,
  SPBefore,
  SPAfter,
  enableCostWill,
}) => (
  <Box>
    <Typography
      variant="subtitle2"
      color="textSecondary"
      mb={enableCostWill ? 0 : 5}
    >
      Change in Costs ({Family} Family)
    </Typography>
    {enableCostWill && (
      <Typography
        variant="body2"
        color="textSecondary"
        mb={1}
        sx={{ marginTop: '12px' }}
      >
        Total Cost wil ...
      </Typography>
    )}
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography
        variant="body2"
        color="textSecondary"
        bgcolor={bluegrey[100]}
        borderRadius="24px"
        p={1}
      >
        {ValueFormatter.toCurrency(CostBefore)}
      </Typography>
      <ArrowRightIcon sx={{ width: '16px', height: '16px' }} />
      <Box p={1} bgcolor={tertiary[50]} borderRadius="24px">
        <LegendWithPercent
          label={ValueFormatter.toCurrency(CostAfter)}
          percent={CostPercent}
        />
      </Box>
    </Stack>
    <Typography variant="body2" color="textSecondary" mt={3} mb={1}>
      Details
    </Typography>
    <PlannerLinearChart
      data={[
        {
          type: 'OD',
          before: OnDemandBefore,
          after: OnDemandAfter,
        },
        {
          type: 'RI',
          before: RIBefore,
          after: RIAfter,
        },
        {
          type: 'SP',
          before: SPBefore,
          after: SPAfter,
        },
      ]}
    />
  </Box>
);
PurchasingComparisonComponent.defaultProps = {
  enableCostWill: false,
};
export const PurchasingComparison = React.memo(PurchasingComparisonComponent);
