/* eslint-disable react/jsx-key */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  PluginHook,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  Pagination,
  Popper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import { FormikValues } from 'formik';
import * as PropTypes from 'prop-types';
import { SuspensePaper } from '@components/styled/suspense';
import { NoAvailableData } from '@components/styled/noData';
import { bluegrey, tertiary } from '@theme/colors';
import { parseFilterValues, ValueFormatter } from '@utils';
import { useCurrentInfo, useSelectedAWSAccounts } from '@hooks/api/common';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { apmSourceState } from '@atoms/global';
import { LightTooltip } from '@components/styled/tooltip';
import { useSnackbar } from '@hooks/common';
import { recommendPeriodState, tableFilterState } from '@atoms/optimization';
import { useOptimizationDownloadCSV } from '@hooks/api/optimization/common';
import { DataTableProps } from '../table.types';
import { TableSortIcon } from '../table.styled';
import { PAGE_LIMIT } from '../table.constants';

const DataPaginationTable: React.FC<DataTableProps> = ({
  columnInfo,
  data,
  onPageChange,
  pageSize,
  totalCount,
  titleColor,
  noDataText,
  enableSort,
  enableSelect,
  CSVUrl,
  TitleElement,
  FilterElement,
  ExtraElement,
  LastUpdate,
}) => {
  const { t } = useTranslation('common');
  const { t: toastT } = useTranslation('toast');
  const { showErrorSnackbar } = useSnackbar();
  const filterContainerRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openFilter = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const closeFilter = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const toggleFilter = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (anchorEl) {
        closeFilter();
      } else {
        openFilter(e);
      }
    },
    [anchorEl],
  );
  const totalPage = useMemo<number>(() => {
    if (typeof totalCount === 'number' && typeof pageSize === 'number') {
      return Math.ceil(totalCount / pageSize);
    }
    return 0;
  }, [totalCount, pageSize]);
  const tablePlugins = useMemo<Array<PluginHook<any>>>(() => {
    const plugins: Array<PluginHook<any>> = [];
    if (enableSort) {
      plugins.push(useSortBy);
    }
    plugins.push(usePagination);
    if (enableSelect) {
      plugins.push(useRowSelect);
    }
    return plugins;
  }, [enableSort, enableSelect]);

  const {
    getTableProps,
    getTableBodyProps,
    getToggleAllPageRowsSelectedProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state,
  } = useTable(
    {
      columns: columnInfo,
      data: data ?? [],
      initialState: { pageIndex: 0, pageSize },
      pageCount: totalPage,
      autoResetPage: true,
      defaultCanSort: false,
      manualPagination: true,
      manualSortBy: true,
    },
    ...tablePlugins,
  );
  const { apiAccounts } = useSelectedAWSAccounts();
  const [rawFilterValue, setRawFilterValue] = useState<
    FormikValues | undefined
  >(undefined);
  const [filterValue, setFilterValue] = useState<string | undefined>(
    parseFilterValues({ AccountId: apiAccounts }),
  );
  const setGlobalFilterValue = useSetRecoilState(tableFilterState);
  const onFilterChange = useCallback(
    (filter: FormikValues) => {
      setFilterValue(parseFilterValues({ ...filter, AccountId: apiAccounts }));
      setRawFilterValue(filter);
    },
    [apiAccounts, setFilterValue, setRawFilterValue],
  );
  useEffect(() => {
    setFilterValue((v) => {
      if (v) {
        return parseFilterValues({
          ...JSON.parse(v),
          AccountId: apiAccounts,
        });
      }
      return parseFilterValues({
        AccountId: apiAccounts,
      });
    });
  }, [apiAccounts]);
  useEffect(() => {
    setGlobalFilterValue(filterValue);
  }, [filterValue]);
  const { currentUser } = useCurrentInfo();
  const apmSource = useRecoilValue(apmSourceState);
  const period = useRecoilValue(recommendPeriodState);
  const { mutateAsync: fetchDownloadCSV, isLoading: isCSVDownloading } =
    useOptimizationDownloadCSV();
  const downloadCSV = useCallback(async () => {
    if (CSVUrl) {
      const sort =
        state.sortBy && state.sortBy.length
          ? JSON.stringify({
              [state.sortBy[0].id]: state.sortBy[0].desc ? 'desc' : 'asc',
            })
          : undefined;
      try {
        const { blob, filename } = await fetchDownloadCSV({
          url: CSVUrl,
          filter: filterValue,
          sort,
          apm: apmSource,
          period,
        });
        const url = window.URL.createObjectURL(
          new Blob([blob], { type: 'text/csv' }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.download =
          filename ||
          `${currentUser?.uid}_${url.replace('/', '-')}_${moment().format(
            'YYYY-MM-DD_hh_mm_ss',
          )}.csv`;
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      } catch (e) {
        showErrorSnackbar(toastT('error.common.csv'));
      }
    }
  }, [CSVUrl, apmSource, period, state.sortBy, filterValue]);
  useEffect(() => {
    const sort =
      state.sortBy && state.sortBy.length
        ? JSON.stringify({
            [state.sortBy[0].id]: state.sortBy[0].desc ? 'desc' : 'asc',
          })
        : undefined;
    onPageChange(
      state.pageIndex * state.pageSize,
      state.pageSize,
      filterValue,
      sort,
    );
  }, [
    state.pageIndex,
    state.pageSize,
    state.sortBy,
    filterValue,
    onPageChange,
  ]);
  return (
    <>
      {(Boolean(FilterElement) ||
        Boolean(LastUpdate) ||
        Boolean(CSVUrl) ||
        Boolean(TitleElement) ||
        Boolean(ExtraElement)) && (
        <Box display="flex" alignItems="center" minHeight="3rem" pl={2} mb={2}>
          {Boolean(TitleElement) && (
            <>
              {typeof TitleElement === 'string' ? (
                <Typography color={titleColor}>{TitleElement}</Typography>
              ) : (
                TitleElement
              )}
            </>
          )}
          {(Boolean(FilterElement) ||
            Boolean(CSVUrl) ||
            Boolean(LastUpdate) ||
            Boolean(ExtraElement)) && (
            <Box
              display="flex"
              flexGrow={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              {Boolean(LastUpdate) && (
                <Box
                  display="flex"
                  alignItems="center"
                  minHeight="3rem"
                  py={1}
                  mr={2}
                >
                  <Typography variant="body2">
                    {t('label.lastUpdate')}
                  </Typography>
                  <Typography variant="body2" ml={2}>
                    {ValueFormatter.toDateString(
                      LastUpdate,
                      'YYYY/MM/DD hh:mm',
                    )}
                  </Typography>
                  {(Boolean(FilterElement) ||
                    Boolean(CSVUrl) ||
                    Boolean(ExtraElement)) && (
                    <Divider orientation="vertical" flexItem sx={{ ml: 2 }} />
                  )}
                </Box>
              )}
              {(Boolean(FilterElement) || Boolean(CSVUrl)) && (
                <Stack direction="row" alignItems="center" spacing={1} mr={2}>
                  {Boolean(CSVUrl) && (
                    <LightTooltip
                      title={t('label.csvTooltip')}
                      placement="top-start"
                    >
                      <IconButton
                        onClick={downloadCSV}
                        disabled={totalCount === 0 || isCSVDownloading}
                        sx={{
                          width: '2.5rem',
                          height: '2.5rem',
                        }}
                      >
                        {isCSVDownloading ? (
                          <CircularProgress
                            color="inherit"
                            size="1rem"
                            sx={{
                              color: 'action.disabled',
                            }}
                          />
                        ) : (
                          <DownloadIcon
                            sx={{
                              color:
                                totalCount === 0 || isCSVDownloading
                                  ? bluegrey[200]
                                  : tertiary[600],
                            }}
                          />
                        )}
                      </IconButton>
                    </LightTooltip>
                  )}
                  {Boolean(FilterElement) && (
                    <Box ref={filterContainerRef} position="relative">
                      <Backdrop
                        open={Boolean(anchorEl)}
                        onClick={closeFilter}
                        sx={{ bgcolor: 'transparent' }}
                      />
                      <IconButton
                        onClick={toggleFilter}
                        disabled={!data?.length && !rawFilterValue}
                        sx={{
                          width: '2.5rem',
                          height: '2.5rem',
                        }}
                      >
                        <FilterAltIcon
                          sx={{
                            color:
                              !data?.length && !rawFilterValue
                                ? bluegrey[200]
                                : tertiary[600],
                          }}
                        />
                      </IconButton>
                      <Popper
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        placement="bottom-end"
                        container={filterContainerRef.current}
                        disablePortal
                      >
                        <SuspensePaper
                          variant="elevation"
                          square={false}
                          sx={{
                            width: 400,
                            maxWidth: 400,
                            boxShadow: 6,
                            p: 3,
                          }}
                        >
                          <FilterElement
                            onClose={closeFilter}
                            onFilterChange={onFilterChange}
                            previousValues={rawFilterValue}
                          />
                        </SuspensePaper>
                      </Popper>
                    </Box>
                  )}
                </Stack>
              )}
              {Boolean(ExtraElement) && (
                <Box display="flex" alignItems="center">
                  {ExtraElement}
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, idx) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {enableSelect && idx === headerGroups.length - 1 && (
                <TableCell padding="checkbox">
                  <Checkbox {...getToggleAllPageRowsSelectedProps()} />
                </TableCell>
              )}
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(
                    enableSort && column.canSort && Boolean(data?.length)
                      ? column.getSortByToggleProps()
                      : undefined,
                  )}
                  width={column.width}
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {column.render('Header')}
                  {enableSort && column.canSort && Boolean(data?.length) && (
                    <TableSortIcon
                      isSorted={column.isSorted}
                      isDesc={column.isSortedDesc}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()} selected={row.isSelected} hover>
                {enableSelect && (
                  <TableCell padding="checkbox">
                    <Checkbox {...row.getToggleRowSelectedProps()} />
                  </TableCell>
                )}
                {row.cells.map((cell) => (
                  <TableCell
                    {...cell.getCellProps()}
                    width={cell.column.width}
                    align={cell.column.align}
                    title={
                      typeof cell.value === 'string' ||
                      typeof cell.value === 'number'
                        ? cell.value.toString()
                        : undefined
                    }
                    sx={{
                      color: cell.column.color,
                      backgroundColor: cell.column.backgroundColor,
                      minWidth: cell.column.minWidth,
                      maxWidth: cell.column.maxWidth,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {Boolean(!data?.length) && (
        <Box p={3}>
          <NoAvailableData text={noDataText} />
        </Box>
      )}
      {pageCount > 0 && (
        <Box mt={6}>
          <Pagination
            page={state.pageIndex + 1}
            count={pageCount}
            onChange={(e, newPage) => gotoPage(newPage - 1)}
          />
        </Box>
      )}
    </>
  );
};
DataPaginationTable.propTypes = {
  columnInfo: PropTypes.arrayOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.any),
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  titleColor: PropTypes.string,
  noDataText: PropTypes.string,
  enableSort: PropTypes.bool,
  enableSelect: PropTypes.bool,
  CSVUrl: PropTypes.string,
  TitleElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  FilterElement: PropTypes.func,
  ExtraElement: PropTypes.element,
  LastUpdate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
};
DataPaginationTable.defaultProps = {
  data: [],
  pageSize: PAGE_LIMIT,
  totalCount: 0,
  titleColor: undefined,
  noDataText: undefined,
  enableSort: false,
  enableSelect: false,
  CSVUrl: undefined,
  TitleElement: undefined,
  FilterElement: undefined,
  ExtraElement: undefined,
  LastUpdate: undefined,
};

export default React.memo(DataPaginationTable);
