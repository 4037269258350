import React, { useMemo, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useOptimizationAnomalyThreshold } from '@hooks/api/optimization/anomaly';
import { useToggle, useToggleWithValue } from '@hooks/common';
import { useAWSHelper } from '@hooks/helper';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { DetailButtonIcon } from '@components/table/table.styled';
import { ValueFormatter } from '@utils';
import { bluegrey } from '@theme/colors';
import { ConditionDetailsModal } from '@pages/private/anomaly/settings/modal';
import DeleteConditionModal from '../modal/DeleteConditionModal';
import AnomalySettingsFilter from './AnomalySettingsFilter';

const AnomalySettingsTable: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: commonT } = useTranslation('common');
  const { getOptimizationAWSRegion } = useAWSHelper();
  const [openCreate, toggleCreate] = useToggle();
  const [openDelete, toggleDelete] = useToggle();
  const [selectedCondition, openDetail, toggleDetail] =
    useToggleWithValue<OptimizationAnomalyThreshold>();
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const { data, onPageChange } = useOptimizationAnomalyThreshold();
  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        minWidth: 140,
        maxWidth: 140,
        disableSortBy: true,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.common.service'),
        accessor: 'Service' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.anomaly.duration'),
        accessor: 'Duration' as const,
        disableSortBy: true,
        Cell: ({ value }) => commonT('label.hours', { count: value }),
      },
      {
        Header: t('label.anomaly.excessCost'),
        accessor: 'Threshold' as const,
        align: 'right',
        Cell: ({ row }: any) =>
          `${ValueFormatter.toCurrency(row.original.Threshold)}`,
      },
      {
        Header: commonT('button.delete'),
        align: 'center',
        Cell: ({ row }) => (
          <IconButton
            onClick={() => {
              setSelectedId(row.original.UID);
              toggleDelete();
            }}
            sx={{ position: 'inherit' }}
          >
            <DeleteIcon sx={{ color: bluegrey[800] }} />
          </IconButton>
        ),
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailButtonIcon
            onClick={() => {
              toggleDetail(row.original);
            }}
          />
        ),
      },
    ],
    [],
  );
  return (
    <>
      <DataPaginationTable
        data={data?.body?.items}
        columnInfo={columnInfo}
        onPageChange={onPageChange}
        totalCount={data?.body?.totalCount}
        FilterElement={AnomalySettingsFilter}
        ExtraElement={
          <Button variant="outlined" onClick={toggleCreate}>
            {t('label.anomaly.addCondition')}
          </Button>
        }
        enableSort
      />
      {openCreate && (
        <ConditionDetailsModal open={openCreate} onClose={toggleCreate} />
      )}
      {openDetail && selectedCondition && (
        <ConditionDetailsModal
          open={openDetail}
          onClose={toggleDetail}
          condition={selectedCondition}
        />
      )}
      {openDelete && selectedId && (
        <DeleteConditionModal
          open={openDelete}
          onClose={toggleDelete}
          anomalyId={selectedId}
        />
      )}
    </>
  );
};

export default AnomalySettingsTable;
