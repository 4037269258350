import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { UtilizationChart } from '@components/chart/optimization';
import { useRDSPurchasingUtilizationChart } from '@hooks/api/optimization/rds';
import { GrmtTooltipItem, GrmtTooltipWrapper } from '@components/chart';
import { ValueFormatter } from '@utils';
import { Box } from '@mui/material';
import { ChartCoverBox } from '@components/styled/noData';
import { UTILIZATION_DUMMY } from '@components/chart/chart.dummy';

const DatabaseUtilChart: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { data } = useRDSPurchasingUtilizationChart();
  return (
    <ChartCoverBox isEmpty={Boolean(!data?.items?.length)}>
      <UtilizationChart
        data={data?.items?.length ? data.items : UTILIZATION_DUMMY}
        chartInfo={[
          {
            dataKey: 'ReservedPurchase',
            percentDataKey: 'ReservedUtilization',
            name: t('label.common.ri', { context: 'purchased' }),
            percentName: t('label.common.ri', { context: 'util' }),
          },
        ]}
        xAxis={{
          dataKey: 'Date',
          interval: 0,
          tickFormatter: (v) => moment(v).format('YYYY MMM'),
        }}
        tooltipContent={({ active, payload, label }) => {
          if (payload?.length) {
            const { payload: item } = payload[0];
            return (
              <Box mx={2}>
                <GrmtTooltipWrapper
                  active={active}
                  payload={payload}
                  label={moment(label).format('YYYY MMM')}
                >
                  <GrmtTooltipItem
                    dotColor="#57CAEB"
                    name={t('label.common.ri')}
                    value={t('label.common.hours', {
                      value: ValueFormatter.toLocaleString(
                        item.ReservedPurchase,
                        0,
                      ),
                    })}
                  />
                  <GrmtTooltipItem
                    dotColor="#57CAEB"
                    name={t('label.common.ri', { context: 'util' })}
                    value={`${ValueFormatter.toPercent(
                      item.ReservedUtilization,
                    )}`}
                  />
                </GrmtTooltipWrapper>
              </Box>
            );
          }
          return null;
        }}
      />
    </ChartCoverBox>
  );
};

export default DatabaseUtilChart;
