import React, { useCallback, useEffect, useState } from 'react';
import { useGetUsers, useResendInvitation } from '@hooks/api/settings/user';
import { CCModalProps, ConfirmDialog } from '@components/modal';
import { useTranslation } from 'react-i18next';

const ResendInvitationModal: React.FC<CCModalProps & { uid?: string }> = ({
  open,
  onClose,
  uid,
}) => {
  const { t } = useTranslation('dialog');
  const { t: commonT } = useTranslation('common');
  const { data } = useGetUsers();
  const { mutateAsync } = useResendInvitation();
  const [email, setEmail] = useState<string | undefined>(undefined);

  const resendInvitation = useCallback(async () => {
    try {
      await mutateAsync({ email });
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [email]);

  useEffect(() => {
    if (data?.items?.length && uid) {
      const user = data.items.find((v) => v.uid === uid);
      if (user) {
        setEmail(user.email);
      }
    }
  }, [data?.items, uid]);

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={t('settings.user.resend.title')}
      content={t('settings.user.resend.content', { email })}
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: commonT('button.cancel'),
        },
        {
          onClick: resendInvitation,
          label: commonT('button.confirm'),
          disabled: !email,
        },
      ]}
    />
  );
};
ResendInvitationModal.defaultProps = {
  uid: undefined,
};

export default ResendInvitationModal;
