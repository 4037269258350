import React from 'react';
import { Paper } from '@mui/material';
import { WithDiagnostic, WithTrial } from '@hooks/hoc';
import { SuspenseBox } from '@components/styled/suspense';
import { StorageCostChart } from './chart';
import { StorageTable } from './table';

const OptimizationStoragePage: React.FC = () => (
  <Paper square sx={{ px: 7, py: 5 }}>
    <SuspenseBox>
      <StorageCostChart />
    </SuspenseBox>
    <SuspenseBox mt={12}>
      <StorageTable />
    </SuspenseBox>
  </Paper>
);

export default WithTrial(WithDiagnostic(OptimizationStoragePage));
