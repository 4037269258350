import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

type AnomalyRecipientForm = {
  Name: string;
  Email: string;
};

export const useAnomalyRecipientForm =
  (): UseValidation<AnomalyRecipientForm> => {
    const { t } = useTranslation('validation');
    return {
      initialValues: {
        Name: '',
        Email: '',
      },
      validationSchema: Yup.object({
        Name: Yup.string().trim().defined(t('common.required')),
        Email: Yup.string()
          .email(t('auth.email'))
          .defined(t('common.required')),
      }).defined(),
    };
  };
