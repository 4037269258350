import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useEC2PurchasingChart } from '@hooks/api/optimization/ec2';
import { UtilizationChart } from '@components/chart/optimization';
import {
  GrmtTooltipDivider,
  GrmtTooltipItem,
  GrmtTooltipWrapper,
} from '@components/chart';
import { ValueFormatter } from '@utils';
import { ChartCoverBox } from '@components/styled/noData';
import { UTILIZATION_DUMMY } from '@components/chart/chart.dummy';

const ComputeUtilChart: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { data } = useEC2PurchasingChart();
  return (
    <ChartCoverBox isEmpty={Boolean(!data?.items?.length)}>
      <UtilizationChart
        data={data?.items?.length ? data.items : UTILIZATION_DUMMY}
        chartInfo={[
          {
            dataKey: 'Reserved',
            percentDataKey: 'ReservedUtilization',
            name: t('label.common.ri', { context: 'purchased' }),
            percentName: t('label.common.ri', { context: 'util' }),
          },
          {
            dataKey: 'SavingsPlan',
            percentDataKey: 'SavingsPlanUtilization',
            name: t('label.common.sp', { context: 'committed' }),
            percentName: t('label.common.sp', { context: 'util' }),
          },
        ]}
        xAxis={{
          dataKey: 'Date',
          interval: 0,
          tickFormatter: (v) => moment(v).format('YYYY MMM'),
        }}
        tooltipContent={({ active, payload, label }) => {
          if (payload?.length) {
            const { payload: item } = payload[0];
            return (
              <Box mx={2}>
                <GrmtTooltipWrapper
                  active={active}
                  payload={payload}
                  label={moment(label).format('YYYY MMM')}
                >
                  <GrmtTooltipItem
                    dotColor="#57CAEB4D"
                    name={t('label.common.ri')}
                    value={t('label.common.hours', {
                      value: ValueFormatter.toLocaleString(item.Reserved, 0),
                    })}
                  />
                  <GrmtTooltipItem
                    dotColor="#57CAEB"
                    name={t('label.common.ri', { context: 'util' })}
                    value={ValueFormatter.toPercent(item.ReservedUtilization)}
                  />
                  <GrmtTooltipDivider />
                  <GrmtTooltipItem
                    dotColor="#9594FF4D"
                    name={t('label.common.sp')}
                    value={t('label.common.hours', {
                      value: ValueFormatter.toLocaleString(item.SavingsPlan, 0),
                    })}
                  />
                  <GrmtTooltipItem
                    dotColor="#9594FF"
                    name={t('label.common.sp', { context: 'util' })}
                    value={ValueFormatter.toPercent(
                      item.SavingsPlanUtilization,
                    )}
                  />
                </GrmtTooltipWrapper>
              </Box>
            );
          }
          return null;
        }}
      />
    </ChartCoverBox>
  );
};

export default ComputeUtilChart;
