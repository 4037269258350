import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SuspensePaper } from '@components/styled/suspense';
import { TypoWithLink } from '@components/styled';
import NotificationTimeline from './timeline/NotificationTimeline';

const NotificationPage: React.FC = () => {
  const { t } = useTranslation('overview');
  return (
    <SuspensePaper sx={{ px: 7, py: 5 }}>
      <Box mb={5}>
        <TypoWithLink
          mb={1}
          dangerouslySetInnerHTML={{ __html: t('label.actionRequired') }}
        />
        <Typography>{t('label.generalAlerts')}</Typography>
      </Box>
      <NotificationTimeline />
    </SuspensePaper>
  );
};

export default NotificationPage;
