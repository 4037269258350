import React from 'react';
import { useTranslation } from 'react-i18next';
import { WithAdmin } from '@hooks/hoc';
import { DiagnosticReport } from '@components/ui';
import { OnboardPageWrapper } from '../Onboard.styled';

const DiagnosticReportPage: React.FC = () => {
  const { t } = useTranslation('onboard');
  return (
    <OnboardPageWrapper title={t('title.report')} disablePadding>
      <DiagnosticReport />
    </OnboardPageWrapper>
  );
};

export default WithAdmin(DiagnosticReportPage);
