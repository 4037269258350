import React, { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { Button, Link, MenuItem, Stack, Typography } from '@mui/material';
import { useCurrentInfo } from '@hooks/api/common';
import { apmSourceState } from '@atoms/global';
import { TableActionSelect } from '@components/table/table.styled';
import AddIcon from '@mui/icons-material/Add';
import bluegrey from '@theme/colors/bluegrey';
import { ROUTES } from '@routes';
import { useNavigate } from 'react-router-dom';

const APMSelector: React.FC = () => {
  const navigate = useNavigate();
  const { currentCompany, currentRole } = useCurrentInfo();
  const [apm, setApm] = useRecoilState(apmSourceState);
  useEffect(() => {
    if (
      apm !== 'cloudwatch' &&
      currentCompany?.apm &&
      !currentCompany.apm.includes(apm)
    ) {
      setApm('cloudwatch');
    }
  }, [currentCompany?.apm, apm]);
  const visible = useMemo<boolean>(() => {
    if (
      currentRole < 3 &&
      currentCompany?.apm &&
      currentCompany.apm.length < 2
    ) {
      return true;
    }
    return false;
  }, [currentRole, currentCompany?.apm]);
  const options = useMemo<Array<{ label: string; value: APMSource }>>(() => {
    const items: Array<{ label: string; value: APMSource }> = [
      { label: 'CloudWatch', value: 'cloudwatch' },
    ];
    if (currentCompany?.apm?.length) {
      currentCompany.apm.forEach((v) => {
        items.push({
          label: v === 'newrelic' ? 'NewRelic' : 'Datadog',
          value: v,
        });
      });
    }
    return items;
  }, []);
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="body2">APM</Typography>
      <TableActionSelect
        value={apm}
        onChange={(e) => setApm(e.target.value as APMSource)}
        sx={{ width: '8rem' }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TableActionSelect>
      {visible && (
        <Button
          component={Link}
          href={`/console${ROUTES.SETTINGS.INTEGRATION}`}
          onClick={(e: React.MouseEvent<any>) => {
            e.preventDefault();
            navigate(ROUTES.SETTINGS.INTEGRATION);
          }}
          size="small"
          variant="outlined"
          color="inherit"
          endIcon={<AddIcon />}
          sx={(theme) => ({
            height: '2.5rem',
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.body2.fontWeight,
            lineHeight: theme.typography.body2.lineHeight,
            color: theme.palette.text.primary,
            borderColor: bluegrey[200],
            '&:hover': {
              backgroundColor: bluegrey[50],
            },
          })}
        >
          Add APM
        </Button>
      )}
    </Stack>
  );
};

export default APMSelector;
