import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  experimental_sx as sx,
  Grid,
  Link,
  styled,
  SvgIcon,
  Typography,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { ValueFormatter } from '@utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAWSHelper, useBestPracticeHelper } from '@hooks/helper';
import { ReactComponent as CheckCircleIcon } from '@assets/icons/common/CheckCircle.svg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { bluegrey } from '@theme/colors';

export const IntelligenceItem = {
  a: 'a',
};

export const IntelligenceConnector = styled(TimelineConnector)({
  width: 0,
  background: 'none',
  borderLeft: '2px dashed #f0f0f7',
});

export const IntelligenceCategory = React.memo(
  ({ type, category, date }: { type: string; category: string; date: any }) => (
    <TimelineOppositeContent>
      <Box>
        <Typography variant="subtitle2" mb={0.5}>
          {type}
        </Typography>
        <Typography variant="body2" color="#a0a1b2" mb={1}>
          {category}
        </Typography>
        <Typography variant="body2" color="#a0a1b2">
          {ValueFormatter.toDateString(date, 'YYYY-MM-DD')}
        </Typography>
      </Box>
    </TimelineOppositeContent>
  ),
);

export const IntelligenceTimelineItem = React.memo(
  ({ type, category, date }: { type: string; category: string; date: any }) => (
    <TimelineItem>
      <TimelineOppositeContent sx={{ flex: 0.1, px: 2 }}>
        <Box>
          <Typography variant="subtitle2" mb={0.5}>
            {type}
          </Typography>
          <Typography variant="body2" color="#a0a1b2" mb={1}>
            {category}
          </Typography>
          <Typography variant="body2" color="#a0a1b2">
            {ValueFormatter.toDateString(date, 'YYYY-MM-DD')}
          </Typography>
        </Box>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <IntelligenceConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ px: 2, pb: 10 }}>aaa</TimelineContent>
    </TimelineItem>
  ),
);

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
  border: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(() =>
  sx({
    flexDirection: 'row-reverse',
    pl: 0,
    py: 2,
    '& .MuiAccordionSummary-content': {
      m: 0,
      ml: 2,
    },
  }),
);
const AccordionDetails = styled(MuiAccordionDetails)(
  sx({
    pl: 6,
    px: 6,
    py: 3,
  }),
);
const REGION_COLOR = [
  '#d5d4ff',
  '#9adff3',
  '#bdf0e1',
  '#ffafad',
  '#c0dff9',
  '#f1e5fc',
  '#f0b8dc',
  '#c7c9e7',
  '#b7e0a9',
  '#ffeeb3',
] as const;
const AccordionContent: React.FC<{
  region: string;
  index: number;
  items: Array<EC2Miscellaneous>;
}> = ({ region, index, items }) => {
  const { getMiscellaneousAWSRegion } = useAWSHelper();
  const { getCTAText, getExternalLink } = useBestPracticeHelper();
  const getResourceValue = useCallback((item: IntelligenceItem) => {
    switch (item.CaseId) {
      case 'AWS-GENERAL-0002':
        return ValueFormatter.toPercent(item.DetailsValue?.MONTHLYCOST);
      case 'AWS-EC2-EBS-0008':
      case 'AWS-EC2-EBS-0009':
        return item.AWSAccountId;
      default:
        return item.ResourceId;
    }
  }, []);
  return (
    <Box mt={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="body2"
          bgcolor={
            region === 'global'
              ? bluegrey[200]
              : REGION_COLOR[index % REGION_COLOR.length]
          }
          borderRadius="2px"
          px={0.5}
          py={0.25}
        >
          {getMiscellaneousAWSRegion(region)}
        </Typography>
        <Button
          variant="text"
          component={Link}
          href={getExternalLink(
            {
              ...items[0],
              ResourcesList: items.map((item) => item.ResourceId),
            },
            { region },
          )}
          target="_blank"
          endIcon={
            <ArrowForwardIosIcon
              sx={{ width: '1.125rem', height: '1.125rem' }}
            />
          }
          sx={{ color: 'text.secondary' }}
        >
          {getCTAText(items[0])}
        </Button>
      </Box>
      <Box border="1px solid #f0f0f7" bgcolor={bluegrey[50]} mt={2}>
        {items.map((item, idx) => (
          <Grid
            key={item.UID}
            container
            columnSpacing={{ sm: 7, xs: 3 }}
            px={3}
            py={1.25}
          >
            <Grid item>
              <Typography variant="body2" align="right" minWidth={20}>
                {ValueFormatter.toLocaleString(idx + 1, 0)}
              </Typography>
            </Grid>
            <Grid
              container
              item
              direction={{ md: 'row', xs: 'column' }}
              justifyContent="space-between"
              spacing={1}
              xs
            >
              <Grid item xs>
                <Typography variant="body2">
                  {ValueFormatter.toDateString(item.Created, 'YYYY-MM-DD')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {getResourceValue(item)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};
export const MiscellaneousAccordion: React.FC<{
  CaseId: MiscellaneousCaseId;
  items: Record<string, Array<EC2Miscellaneous>>;
}> = ({ CaseId, items }) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded);
    },
    [],
  );
  const { getTitle, getDescription, getRecommendation } =
    useBestPracticeHelper();
  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary>
        <Typography variant="subtitle2" fontSize="1.125rem" lineHeight="1.5rem">
          {getTitle({ CaseId })}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2">
          {getDescription({ CaseId, DetailsValue: {} })}
        </Typography>
        <Box mt={3}>
          <Box display="flex" mb={1}>
            <SvgIcon
              component={CheckCircleIcon}
              sx={{ width: '1.25rem', height: '1.25rem', mr: 1 }}
            />
            <Typography variant="body2">
              {getRecommendation({ CaseId, ResourceId: '', DetailsValue: {} })}
            </Typography>
          </Box>
          {Object.keys(items).map((key, idx) => (
            <AccordionContent
              key={`${CaseId}_${key}_${idx.toString()}`}
              region={key}
              index={idx}
              items={items[key as MiscellaneousCaseId]}
            />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
