import React, { useMemo, useState } from 'react';
import { DetailMenu } from '@components/common';
import { DetailMenuItem } from '@components/common/DetailMenu';
import { GrmtTableColumnInfo, SimplePaginationTable } from '@components/table';
import { useGetUsers } from '@hooks/api/settings/user';
import { useToggle } from '@hooks/common';
import { ValueFormatter } from '@utils';
import { useTranslation } from 'react-i18next';
import { useAWSAccountHelper } from '@hooks/helper';
import { useCurrentInfo } from '@hooks/api/common';
import { TableActionButton } from '@components/table/table.styled';
import {
  ActivateUserModal,
  CreateUserModal,
  DeleteUserModal,
  MasqueradeModal,
  ResendInvitationModal,
  ResetPasswordModal,
  UserDetailModal,
} from '../modal';

const SettingsUserTable: React.FC = () => {
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const [openCreate, toggleCreate] = useToggle();
  const [openDelete, toggleDelete] = useToggle();
  const [openActivate, toggleActivate] = useToggle();
  const [openReset, toggleReset] = useToggle();
  const [openResend, toggleResend] = useToggle();
  const [openDetail, toggleDetail] = useToggle();
  const [openMasquerade, toggleMasquerade] = useToggle();
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const { findAWSAccountByUid } = useAWSAccountHelper();
  const { currentUser, currentRole } = useCurrentInfo();
  const { data } = useGetUsers();
  const columns = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('user.label.user', { context: 'name' }),
        accessor: 'firstname' as const,
        width: 150,
        maxWidth: 150,
        Cell: ({ value, row }) => {
          if (!value && !row.original.lastname && row.original.fullname) {
            return row.original.fullname;
          }
          if (value || row.original.lastname) {
            if (!row.original.lastname) {
              return value;
            }
            if (!value) {
              return row.original.lastname;
            }
            return `${value} ${row.original.lastname}`;
          }
          return '-';
        },
      },
      {
        Header: t('account.label.email'),
        accessor: 'email' as const,
        maxWidth: 180,
      },
      {
        Header: t('account.label.role'),
        accessor: 'roles' as const,
        width: 110,
        maxWidth: 110,
        Cell: ({ value }) => {
          if (value?.length) {
            switch (Math.min(...value)) {
              case 1:
                return t('user.role.root');
              case 2:
                return t('user.role.admin');
              default:
                return t('user.role.user');
            }
          }
          return '-';
        },
      },
      {
        Header: t('user.label.accounts'),
        accessor: 'assignedAWSAccount' as const,
        width: 400,
        maxWidth: 400,
        Cell: ({ value }: { value: Array<{ id: string }> }) => {
          if (value?.length) {
            const filtered = value.filter((v) =>
              Boolean(findAWSAccountByUid(v.id)?.id),
            );
            if (filtered.length < 4) {
              return filtered
                .map((v) => findAWSAccountByUid(v.id)?.id)
                .join(', ');
            }
            return `${filtered
              .slice(0, 3)
              .map((v) => findAWSAccountByUid(v.id)?.id)
              .join(', ')}, +${value.length - 3}`;
          }
          return '-';
        },
      },
      {
        Header: t('user.label.lastLogin'),
        accessor: 'lastLogin' as const,
        width: 120,
        maxWidth: 120,
        Cell: ({ value }) =>
          ValueFormatter.toDateString(value, 'YYYY-MM-DD') || '-',
      },
      {
        Header: commonT('label.action'),
        accessor: () => null,
        align: 'center',
        width: 100,
        maxWidth: 100,
        Cell: ({ row }) => {
          const menu: Array<DetailMenuItem> = [
            {
              label: t('user.label.seeDetails'),
              onClick: () => {
                setSelected(row.original.uid);
                toggleDetail();
              },
            },
          ];
          if (currentRole === 1 && currentUser?.uid !== row.original.uid) {
            menu.push({
              label: t('user.label.masquerade'),
              onClick: () => {
                setSelected(row.original.uid);
                toggleMasquerade();
              },
            });
          }
          if (currentUser?.uid !== row.original.uid) {
            if (!row.original.lastLogin) {
              menu.push({
                label: t('user.label.resendInvitation'),
                onClick: () => {
                  setSelected(row.original.uid);
                  toggleResend();
                },
              });
            }
            menu.push({
              label: t('user.label.resetPassword'),
              onClick: () => {
                setSelected(row.original.uid);
                toggleReset();
              },
            });
            if (row.original.deactivation) {
              menu.push({
                label: t('user.label.activateUser'),
                onClick: () => {
                  setSelected(row.original.uid);
                  toggleActivate();
                },
              });
            } else {
              menu.push({
                label: t('user.label.deactivateUser'),
                onClick: () => {
                  setSelected(row.original.uid);
                  toggleActivate();
                },
              });
            }
            if (currentRole < Math.min(...row.original.roles)) {
              menu.push({
                label: t('user.label.deleteUser'),
                color: 'error.main',
                onClick: () => {
                  setSelected(row.original.uid);
                  toggleDelete();
                },
              });
            }
          }
          return <DetailMenu items={menu} />;
        },
      },
    ],
    [findAWSAccountByUid, currentRole, currentUser],
  );
  return (
    <>
      <SimplePaginationTable
        data={data?.items}
        columnInfo={columns}
        ExtraElement={
          <TableActionButton onClick={toggleCreate}>
            {t('user.button.invite')}
          </TableActionButton>
        }
        enableFilter
      />
      {openCreate && (
        <CreateUserModal open={openCreate} onClose={toggleCreate} />
      )}
      {openDetail && selected && (
        <UserDetailModal
          open={openDetail}
          onClose={toggleDetail}
          uid={selected}
        />
      )}
      {openDelete && selected && (
        <DeleteUserModal
          open={openDelete}
          onClose={toggleDelete}
          uid={selected}
        />
      )}
      {openActivate && selected && (
        <ActivateUserModal
          open={openActivate}
          onClose={toggleActivate}
          uid={selected}
        />
      )}
      {openReset && selected && (
        <ResetPasswordModal
          open={openReset}
          onClose={toggleReset}
          uid={selected}
        />
      )}
      {openResend && selected && (
        <ResendInvitationModal
          open={openResend}
          onClose={toggleResend}
          uid={selected}
        />
      )}
      {currentRole === 1 && openMasquerade && selected && (
        <MasqueradeModal
          open={openMasquerade}
          onClose={toggleMasquerade}
          uid={selected}
        />
      )}
    </>
  );
};

export default SettingsUserTable;
