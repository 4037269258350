import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt';
import { PlannerCircularChart } from '@components/chart/planner';
import { SingleTypeTable } from '@components/styled';
import { bluegrey, primary } from '@theme/colors';
import shadows from '@theme/shadows';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  simulateRequestState,
  simulateResponseState,
  simulationStepState,
} from '@pages/private/planner/simulation/Simulation.atoms';
import { useSimulationGeneral } from '@hooks/api/planner';
import { useParams } from 'react-router-dom';
import { useAWSHelper } from '@hooks/helper';
import {
  isReservedRequest,
  isReservedResponse,
} from '@pages/private/planner/simulation/Simulation.utils';
import { getPercentageProportion, ValueFormatter } from '@utils';
import { useTranslation } from 'react-i18next';
import PurchaseConfirmModal from '@pages/private/planner/simulation/components/PurchaseConfirmModal';
import { useToggle } from '@hooks/common';
import { QuestionTooltip } from '@components/styled/tooltip';
import { PurchaseChangePaper, SimulationHeader } from '../Simulation.styled';

const PurchaseStep: React.FC = () => {
  const { instanceId } = useParams();
  const { t } = useTranslation('planner');
  const { t: optT } = useTranslation('optimization');
  const [open, toggleDialog] = useToggle();
  const setActive = useSetRecoilState(simulationStepState);
  const handlePrev = useCallback(() => {
    setActive((v) => v - 1);
  }, [setActive]);
  const { getAWSRegionName } = useAWSHelper();
  const { data } = useSimulationGeneral(instanceId);
  const request = useRecoilValue(simulateRequestState);
  const response = useRecoilValue(simulateResponseState);
  if (!data?.items?.length || !request || !response) {
    return null;
  }
  const MonthlyRecurring = useMemo<number>(() => {
    if (request && request.DiscountProgram.PaymentOption !== 'All Upfront') {
      if (isReservedResponse(response.SimulatedUsage)) {
        return response.SimulatedUsage.SimulatedRate * 720.0;
      }
      return response.SimulatedUsage.SimulatedCommitment * 720.0;
    }
    return 0;
  }, [response.SimulatedUsage, request]);
  const TotalCost = useMemo<number>(() => {
    if (request?.DiscountProgram?.Duration) {
      return (
        request.DiscountProgram.Duration * 12 * MonthlyRecurring +
        response.SimulatedUsage.UpfrontFee
      );
    }
    return 0;
  }, [request, response.SimulatedUsage, MonthlyRecurring]);
  const { AccountId, SelectedInstance } = data.items[0];
  return (
    <>
      <SimulationHeader
        Title={`${
          isReservedResponse(response.SimulatedUsage)
            ? optT('label.common.ri')
            : optT('label.common.sp')
        } ${t('label.detail')}`}
        mb={3}
      />
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Paper>
            {isReservedRequest(request.DiscountProgram) &&
              isReservedResponse(response.SimulatedUsage) && (
                <SingleTypeTable
                  data={[
                    { label: optT('label.common.accountId'), value: AccountId },
                    {
                      label: optT('label.common.instanceType'),
                      value: request.DiscountProgram.SimulateInstanceType,
                    },
                    {
                      label: t('aws.offeringClass'),
                      value: t(`RIClass.${request.DiscountProgram.Type}`),
                    },
                    {
                      label: t('aws.term'),
                      value: t(`Term.${request.DiscountProgram.Duration}`),
                    },
                    {
                      label: t('label.quantity'),
                      value: request.DiscountProgram.NumberValue,
                    },
                  ]}
                />
              )}
            {!isReservedRequest(request.DiscountProgram) &&
              !isReservedResponse(response.SimulatedUsage) && (
                <SingleTypeTable
                  data={[
                    { label: optT('label.common.accountId'), value: AccountId },
                    {
                      label: optT('label.common.instanceFamily'),
                      value: SelectedInstance.InstanceFamily,
                    },
                    {
                      label: t('aws.spType'),
                      value: t(`SPType.${request.DiscountProgram.Type}`),
                    },
                    {
                      label: t('aws.term'),
                      value: t(`Term.${request.DiscountProgram.Duration}`),
                    },
                    {
                      label: t('aws.commitment'),
                      value: ValueFormatter.toCurrency(
                        request.DiscountProgram.PaymentOption !== 'All Upfront'
                          ? response.SimulatedUsage.SimulatedCommitment * 720.0
                          : 0.0,
                      ),
                    },
                  ]}
                />
              )}
          </Paper>
        </Grid>
        <Grid item md={6} xs={12}>
          <Paper>
            <SingleTypeTable
              data={[
                {
                  label: optT('label.common.region'),
                  value: getAWSRegionName(SelectedInstance.Region),
                },
                {
                  label: t('aws.platform'),
                  value: SelectedInstance.Platform,
                },
                { label: t('aws.tenancy'), value: SelectedInstance.Tenancy },
                {
                  label: t('aws.paymentOption'),
                  value: t(
                    `PaymentOption.${request.DiscountProgram.PaymentOption}`,
                  ),
                },
                {
                  label: isReservedResponse(response.SimulatedUsage)
                    ? t('label.normalizedUnits')
                    : t('label.upfrontCost'),
                  value: isReservedResponse(response.SimulatedUsage)
                    ? ValueFormatter.toLocaleString(
                        response.SimulatedUsage.SimulatedUnits,
                        0,
                      )
                    : ValueFormatter.toCurrency(
                        response.SimulatedUsage.UpfrontFee,
                      ),
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
      <SimulationHeader
        Title={
          <Stack direction="row" alignItems="center" spacing={1}>
            <span>{t('label.instanceChanges')}</span>
            <QuestionTooltip
              title={optT('label.tooltip.plannerCostChanges')}
              color="bluegrey"
              size="0.875rem"
            />
          </Stack>
        }
        optional={
          <Box ml="auto">
            <Chip
              size="small"
              label={t('label.family', {
                label: SelectedInstance.InstanceFamily,
              })}
            />
          </Box>
        }
        mt={12}
        mb={6}
      />
      <Grid container direction="column" rowSpacing={9} px={6}>
        <Grid
          container
          item
          columnSpacing={5}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item md={5}>
            <PlannerCircularChart
              data={[
                { cost: response.CurrentUsage.OndemandCost },
                { cost: response.CurrentUsage.SavingsPlanCost },
                { cost: response.CurrentUsage.ReservedCost },
              ]}
            />
          </Grid>
          <Grid
            item
            display="flex"
            flexDirection={{ md: 'column', xs: 'row' }}
            alignItems="center"
            md={2}
          >
            <ArrowRightIcon
              sx={{ width: '100px', height: '100px', color: bluegrey[600] }}
            />
            <Box>
              <Typography color="textSecondary" align="center">
                {t('label.estimatedSavings')}
              </Typography>
              <Typography variant="h2" align="center" mt={2}>
                {ValueFormatter.toCurrency(
                  response.CurrentUsage.TotalCost -
                    response.SimulatedUsage.TotalCost,
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={5}>
            <PlannerCircularChart
              data={[
                { cost: response.SimulatedUsage.OndemandCost },
                { cost: response.SimulatedUsage.SavingsPlanCost },
                { cost: response.SimulatedUsage.ReservedCost },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container item columnSpacing={5} justifyContent="space-between">
          <Grid item md={5}>
            <PurchaseChangePaper
              RICoverage={getPercentageProportion(
                response.CurrentUsage.Reserved,
                response.CurrentUsage.TotalUsage,
              )}
              SPCoverage={getPercentageProportion(
                response.CurrentUsage.SavingsPlan,
                response.CurrentUsage.TotalUsage,
              )}
              OnDemand={response.CurrentUsage.OndemandCost}
              RI={response.CurrentUsage.ReservedCost}
              SP={response.CurrentUsage.SavingsPlanCost}
              Total={response.CurrentUsage.TotalCost}
              isPast
            />
          </Grid>
          <Grid item md={5}>
            <PurchaseChangePaper
              RICoverage={getPercentageProportion(
                response.SimulatedUsage.NormalizedReservedUsage,
                response.SimulatedUsage.TotalNormalizedUsage,
              )}
              SPCoverage={getPercentageProportion(
                response.SimulatedUsage.NormalizedSavingsPlanUsage,
                response.SimulatedUsage.TotalNormalizedUsage,
              )}
              OnDemand={response.SimulatedUsage.OndemandCost}
              RI={response.SimulatedUsage.ReservedCost}
              SP={response.SimulatedUsage.SavingsPlanCost}
              Total={response.SimulatedUsage.TotalCost}
            />
          </Grid>
        </Grid>
      </Grid>
      <SimulationHeader
        Title={`${
          isReservedResponse(response.SimulatedUsage)
            ? optT('label.common.ri')
            : optT('label.common.sp')
        } ${t('label.purchaseSummary')}`}
        mt={12}
        mb={3}
      />
      <Box borderRadius="4px" boxShadow={shadows[1]}>
        {request &&
          response.SimulatedUsage &&
          (isReservedResponse(response.SimulatedUsage) ? (
            <>
              <Box display="flex" justifyContent="space-between" p={2}>
                <Typography variant="body2">{t('label.totalDue')}</Typography>
                <Typography variant="body2">
                  {ValueFormatter.toCurrency(
                    response.SimulatedUsage.UpfrontFee,
                  )}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" p={2}>
                <Typography variant="body2">
                  {t('label.monthlyRecurring')}
                </Typography>
                <Typography variant="body2">
                  {ValueFormatter.toCurrency(MonthlyRecurring)}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                p={2}
                bgcolor={primary[50]}
              >
                <Typography variant="subtitle2">
                  {t('label.totalCost')}
                </Typography>
                <Typography variant="subtitle2" color="primary">
                  {ValueFormatter.toCurrency(TotalCost)}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box display="flex" justifyContent="space-between" p={2}>
                <Typography variant="body2">
                  {t('label.upfrontCost')}
                </Typography>
                <Typography variant="body2">
                  {ValueFormatter.toCurrency(
                    response.SimulatedUsage.UpfrontFee,
                  )}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" p={2}>
                <Typography variant="body2">
                  {t('label.monthlyPayment')}
                </Typography>
                <Typography variant="body2">
                  {ValueFormatter.toCurrency(MonthlyRecurring)}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                p={2}
                bgcolor={primary[50]}
              >
                <Typography variant="subtitle2">
                  {t('label.totalCost')}
                </Typography>
                <Typography variant="subtitle2" color="primary">
                  {ValueFormatter.toCurrency(TotalCost)}
                </Typography>
              </Box>
            </>
          ))}
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={12}>
        <Button color="inherit" size="large" onClick={handlePrev}>
          {t('button.rerun')}
        </Button>
        <Button size="large" onClick={toggleDialog} sx={{ ml: 3 }}>
          {t('button.purchase')}
        </Button>
      </Box>
      {open && <PurchaseConfirmModal open={open} onClose={toggleDialog} />}
    </>
  );
};

export default PurchaseStep;
