import { UserService } from '@api/service';
import { QUERY_KEY } from '@constants';
import { useMutation, useQueryClient } from 'react-query';

export default () => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: QUERY_KEY.SETTINGS.USER.DELETE_USER,
    mutationFn: (variables: { uid: string }) =>
      UserService.deleteUser(variables.uid),
    onSuccess: () => client.invalidateQueries(QUERY_KEY.SETTINGS.USER.GET_USER),
  });
};
