import React, { useCallback, useState, MouseEvent } from 'react';
import * as PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVIcon from '@mui/icons-material/MoreVert';
import MoreHIcon from '@mui/icons-material/MoreHoriz';

export type DetailMenuItem = {
  label: string;
  onClick?: (event: MouseEvent<HTMLLIElement>) => void;
  color?: string;
};

type DetailMenuProps = {
  items: Array<DetailMenuItem>;
  horizontal?: boolean;
};

const DetailMenu: React.FC<DetailMenuProps> = ({
  items,
  horizontal = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleOpen = useCallback((e: MouseEvent<any>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      {horizontal ? (
        <IconButton onClick={handleOpen}>
          <MoreHIcon />
        </IconButton>
      ) : (
        <IconButton onClick={handleOpen}>
          <MoreVIcon />
        </IconButton>
      )}
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        {items.map((item) => {
          const sxProps = item.color
            ? {
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                fontWeight: 400,
                p: 2,
                color: item.color,
              }
            : {
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                fontWeight: 400,
                p: 2,
              };
          return (
            <MenuItem
              key={`detail_item_${item.label}`}
              onClick={(e) => {
                if (item.onClick) {
                  item.onClick(e);
                  handleClose();
                }
              }}
              divider
              sx={sxProps}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
DetailMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.oneOfType([
        PropTypes.func.isRequired,
        PropTypes.oneOf([undefined]).isRequired,
      ]).isRequired,
      color: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.oneOf([undefined]).isRequired,
      ]).isRequired,
    }).isRequired,
  ).isRequired,
  horizontal: PropTypes.bool,
};
DetailMenu.defaultProps = {
  horizontal: false,
};

export default React.memo(DetailMenu);
