import { useMutation, useQueryClient } from 'react-query';
import { CouponService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const client = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.ADMIN.COUPON.CREATE_COUPON,
    mutationFn: (variables: {
      Name: string;
      Duration: string;
      PercentOff: number;
      DurationInMonths: number;
    }) =>
      CouponService.createCoupon(
        variables.Name,
        variables.Duration,
        variables.PercentOff,
        variables.DurationInMonths,
      ),
    onSuccess: () =>
      client.invalidateQueries(QUERY_KEY.ADMIN.COUPON.GET_COUPONS),
  });
};
