import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@routes';
import image from '@assets/images/img_404.png';

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  return (
    <Box id="cc_not_found_page" display="flex" alignItems="center">
      <Box flexGrow={1} ml={17}>
        <Typography variant="h3" color="textSecondary">
          {t('label.pageNotFound')}
        </Typography>
        <Typography
          mt={2}
          sx={{ fontSize: '9rem', lineHeight: '10.5rem', fontWeight: 700 }}
        >
          404
        </Typography>
        <Stack direction="row" spacing={2} mt={5}>
          <Button size="large" onClick={() => navigate(-1)}>
            {t('label.goBack')}
          </Button>
          <Button
            variant="text"
            size="large"
            onClick={() => navigate(ROUTES.OVERVIEW.ROOT)}
          >
            {t('label.goToOverview')}
          </Button>
        </Stack>
      </Box>
      <img src={image} alt="PageNotFound" />
    </Box>
  );
};

export default React.memo(PageNotFound);
