import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEC2SPUtilAutoComplete } from '@hooks/api/optimization/ec2';
import { useFilterFormik } from '@hooks/common';
import { useAWSHelper } from '@hooks/helper';
import {
  FilterGrid,
  FilterGridItem,
  FilterProps,
  FilterSelect,
  FilterWrapper,
} from '@components/filter';

type ComputeSPUtilFilterForm = {
  Region: Array<string>;
  InstanceFamily: Array<string>;
};

const useComputeSPFilterForm = (): UseValidation<ComputeSPUtilFilterForm> => ({
  initialValues: {
    Region: [],
    InstanceFamily: [],
  },
  validationSchema: Yup.object({
    Region: Yup.array().of(Yup.string()),
    InstanceFamily: Yup.array().of(Yup.string()),
  }).defined(),
});

const ComputeSPUtilFilter: React.FC<FilterProps> = ({
  onClose,
  onFilterChange,
  previousValues,
}) => {
  const { t } = useTranslation('optimization');
  const { getAWSRegionName } = useAWSHelper();
  const { data } = useEC2SPUtilAutoComplete();
  const { initialValues, validationSchema } = useComputeSPFilterForm();
  const { values, handleArrayChange, handleBlur, handleSubmit } =
    useFilterFormik(
      {
        initialValues: previousValues || initialValues,
        validationSchema,
        onSubmit: (v) => {
          onFilterChange(v);
          onClose();
        },
      },
      data?.items,
    );
  if (!data?.items?.length) {
    return null;
  }
  const { Region, InstanceFamily } = data.items[0];
  return (
    <FilterWrapper onClose={onClose} onSubmit={handleSubmit}>
      <FilterGrid>
        <FilterGridItem label={t('label.common.region')}>
          <FilterSelect
            name="Region"
            value={values.Region}
            onChange={(e) => handleArrayChange('Region', e.target.value)}
            onBlur={handleBlur}
            renderValue={() =>
              values.Region.map((v: string) => getAWSRegionName(v)).join(', ')
            }
            selected={values.Region}
            candidate={Region}
            formatter={getAWSRegionName}
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.common.instanceFamily')}>
          <FilterSelect
            name="InstanceFamily"
            value={values.InstanceFamily}
            onChange={(e) =>
              handleArrayChange('InstanceFamily', e.target.value)
            }
            onBlur={handleBlur}
            renderValue={() => values.InstanceFamily.join(', ')}
            selected={values.InstanceFamily}
            candidate={InstanceFamily}
          />
        </FilterGridItem>
      </FilterGrid>
    </FilterWrapper>
  );
};

export default ComputeSPUtilFilter;
