import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useGetDiagnostic } from '@hooks/api/onboard';
import { AWS_REGION } from '@constants';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation('onboard');
  const { data: response } = useGetDiagnostic();
  const data = response?.items?.length ? response.items[0] : undefined;
  const extras = response?.extras;

  const getItemByKey = useCallback(
    (key: DiagnosticResultKey): string | number => {
      if (response?.items?.length && response.items[0][key].IsDone) {
        return response.items[0][key].ActionItems;
      }
      return t('report.label.scanning');
    },
    [response],
  );
  const getCostByKey = useCallback(
    (key: DiagnosticResultKey): string | number => {
      if (response?.items?.length && response.items[0][key].IsDone) {
        return response.items[0][key].PossibleSavings;
      }
      return t('report.label.scanning');
    },
    [response],
  );
  const diagnosticProgress = useMemo(() => {
    const progress = {
      EC2: true,
      RDS: true,
      EBS: true,
      Other: true,
      RightSizing: true,
      PricingPlan: true,
      Generation: true,
      Storage: true,
      Misc: true,
    };
    if (response?.items?.length) {
      Object.entries(response.items[0]).forEach(([key, value]) => {
        switch (true) {
          case key.includes('EC2'):
            progress.EC2 = progress.EC2 && value.IsDone;
            break;
          case key.includes('RDS'):
            progress.RDS = progress.RDS && value.IsDone;
            break;
          case key.includes('EBS'):
            progress.EBS = progress.EBS && value.IsDone;
            break;
          default:
            progress.Other = progress.Other && value.IsDone;
        }
        switch (true) {
          case key.includes('RightSizing'):
            progress.RightSizing = progress.RightSizing && value.IsDone;
            break;
          case key.includes('PricingPlan'):
            progress.PricingPlan = progress.PricingPlan && value.IsDone;
            break;
          case key.includes('Generation'):
            progress.Generation = progress.Generation && value.IsDone;
            break;
          case key.includes('Storage'):
            progress.Storage = progress.Storage && value.IsDone;
            break;
          default:
            progress.Misc = progress.Misc && value.IsDone;
        }
      });
    }
    return progress;
  }, [response]);
  const costByCategory = useMemo<{
    RightSizing: number;
    PricingPlan: number;
    Generation: number;
    Storage: number;
    Misc: number;
  }>(() => {
    const category = {
      RightSizing: 0,
      PricingPlan: 0,
      Generation: 0,
      Storage: 0,
      Misc: 0,
    };
    if (response?.items?.length) {
      Object.entries(response.items[0]).forEach(([key, value]) => {
        switch (true) {
          case key.includes('RightSizing'):
            category.RightSizing += value.PossibleSavings;
            break;
          case key.includes('PricingPlan'):
            category.PricingPlan += value.PossibleSavings;
            break;
          case key.includes('Generation'):
            category.Generation += value.PossibleSavings;
            break;
          case key.includes('Storage'):
            category.Storage += value.PossibleSavings;
            break;
          default:
            category.Misc += value.PossibleSavings;
        }
      });
    }
    return category;
  }, [response]);
  const getCostByCategory = useCallback(
    (
      action: 'RightSizing' | 'PricingPlan' | 'Generation' | 'Storage' | 'Misc',
    ) =>
      diagnosticProgress[action]
        ? costByCategory[action]
        : t('report.label.scanning'),
    [diagnosticProgress, costByCategory],
  );
  const itemByService = useMemo(() => {
    const service = {
      EC2: 0,
      RDS: 0,
      EBS: 0,
      Other: 0,
    };
    if (response?.items?.length) {
      Object.entries(response.items[0]).forEach(([key, value]) => {
        switch (true) {
          case key.includes('EC2'):
            service.EC2 += value.ActionItems;
            break;
          case key.includes('RDS'):
            service.RDS += value.ActionItems;
            break;
          case key.includes('EBS'):
            service.EBS += value.ActionItems;
            break;
          default:
            service.Other += value.ActionItems;
        }
      });
    }
    return service;
  }, [response]);
  const getItemByService = useCallback(
    (service: 'EC2' | 'RDS' | 'EBS' | 'Other') =>
      diagnosticProgress[service]
        ? itemByService[service]
        : t('report.label.scanning'),
    [diagnosticProgress, itemByService],
  );
  const serviceChecked = useMemo(() => {
    const service = {
      EC2: { Instances: 0, Items: 0 },
      RDS: { Instances: 0, Items: 0 },
      EBS: { Instances: 0, Items: 0 },
      Other: { Instances: 0, Items: 0 },
    };
    if (response?.items?.length) {
      Object.entries(response.items[0]).forEach(([key, value]) => {
        switch (true) {
          case key.includes('EC2'):
            service.EC2.Items += value.ActionItems;
            service.EC2.Instances += value.ScannedResources;
            break;
          case key.includes('RDS'):
            service.RDS.Items += value.ActionItems;
            service.RDS.Instances += value.ScannedResources;
            break;
          case key.includes('EBS'):
            service.EBS.Items += value.ActionItems;
            service.EBS.Instances += value.ScannedResources;
            break;
          default:
            service.Other.Items += value.ActionItems;
            service.Other.Instances += value.ScannedResources;
        }
      });
    }
    return service;
  }, [response]);
  const getCheckedService = useCallback(
    (service: 'EC2' | 'RDS' | 'EBS' | 'Other', key: 'Instances' | 'Items') =>
      diagnosticProgress[service]
        ? serviceChecked[service][key]
        : t('report.label.scanning'),
    [diagnosticProgress, serviceChecked],
  );
  const totalSavingsByResult = _.sum(
    Object.keys(costByCategory).map((v) => {
      if (
        !Number.isNaN(
          Number(
            getCostByCategory(
              v as
                | 'RightSizing'
                | 'PricingPlan'
                | 'Generation'
                | 'Storage'
                | 'Misc',
            ),
          ),
        )
      ) {
        return Number(
          getCostByCategory(
            v as
              | 'RightSizing'
              | 'PricingPlan'
              | 'Generation'
              | 'Storage'
              | 'Misc',
          ),
        );
      }
      return 0;
    }),
  );
  const totalActionsByResult = _.sum(
    Object.keys(itemByService).map((v) => {
      if (
        !Number.isNaN(
          Number(getItemByService(v as 'EC2' | 'RDS' | 'EBS' | 'Other')),
        )
      ) {
        return Number(getItemByService(v as 'EC2' | 'RDS' | 'EBS' | 'Other'));
      }
      return 0;
    }),
  );
  const totalSavings = extras?.TotalPossibleSavings || 0;
  const totalActions = _.sum(Object.values(itemByService));
  const totalResources = data
    ? _.sumBy(Object.values(data), (v) => v.ScannedResources)
    : 0;
  const selectedRegion = extras?.Region?.length ? extras.Region : AWS_REGION;
  const accounts = extras?.Accounts || [];
  const isFinished = response?.items?.length
    ? _.every(response.items[0], 'IsDone')
    : false;
  const graphMax = useMemo<number>(() => {
    if (response?.items?.length) {
      const max = _.maxBy(
        Object.values(response.items[0]),
        (v) => v.PossibleSavings,
      );
      if (max) {
        return Math.round(max.PossibleSavings / 100 + 1) * 100;
      }
    }
    return 0;
  }, [response]);
  return {
    data,
    extras,
    costByCategory,
    getCostByCategory,
    itemByService,
    getItemByService,
    serviceChecked,
    getCheckedService,
    totalSavingsByResult,
    totalActionsByResult,
    totalSavings,
    totalActions,
    totalResources,
    selectedRegion,
    accounts,
    isFinished,
    getCostByKey,
    getItemByKey,
    graphMax,
  };
};
