import { PaletteOptions } from '@mui/material';
import {
  primary,
  secondary,
  tertiary,
  info,
  warning,
  error,
  bluegrey,
  disabled,
} from '@theme/colors';

const costClipperPalette: PaletteOptions = {
  primary: {
    main: primary[600],
    light: primary[600],
    dark: primary[600],
  },
  secondary: {
    main: secondary[500],
    light: secondary[500],
    dark: secondary[500],
  },
  tertiary: {
    main: tertiary[400],
    light: tertiary[400],
    dark: tertiary[400],
    contrastText: '#fff',
  },
  info: {
    main: info[500],
    light: info[500],
    dark: tertiary[500],
  },
  warning: {
    main: warning[500],
    light: warning[500],
    dark: warning[500],
  },
  error: {
    main: error[300],
    light: error[300],
    dark: error[300],
  },
  success: {
    main: '#70C154',
    light: '#70C154',
    dark: '#70C154',
  },
  text: {
    primary: bluegrey[900],
    secondary: bluegrey[600],
    disabled: disabled[300],
  },
  background: {
    default: '#fcfcfc',
    paper: '#fff',
  },
};

export default costClipperPalette;
