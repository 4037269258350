import { useMutation, useQueryClient } from 'react-query';
import { IntegrationService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.SETTINGS.INTEGRATION.DELETE_NEWRELIC,
    mutationFn: (variables: { companyId: string }) =>
      IntegrationService.deleteDatadog(variables.companyId),
    onSuccess: () =>
      queryClient.invalidateQueries(
        QUERY_KEY.SETTINGS.INTEGRATION.GET_NEWRELIC,
      ),
  });
};
