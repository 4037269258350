import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CoverageChart } from '@components/chart/optimization';
import { useEC2PurchasingChart } from '@hooks/api/optimization/ec2';
import {
  GrmtTooltipDivider,
  GrmtTooltipItem,
  GrmtTooltipWrapper,
} from '@components/chart';
import { ValueFormatter } from '@utils';
import { Box } from '@mui/material';
import { ChartCoverBox } from '@components/styled/noData';
import { COVERAGE_DUMMY } from '@components/chart/chart.dummy';

const ComputeCoverageChart: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { data } = useEC2PurchasingChart();
  return (
    <ChartCoverBox isEmpty={!data?.items?.length}>
      <CoverageChart
        data={data?.items?.length ? data.items : COVERAGE_DUMMY}
        chartInfo={[
          {
            name: t('label.common.od'),
            dataKey: 'Ondemand',
          },
          {
            name: t('label.common.ri'),
            dataKey: 'Reserved',
          },
          {
            name: t('label.common.sp'),
            dataKey: 'SavingsPlan',
          },
        ]}
        xAxis={{
          dataKey: 'Date',
          interval: 0,
          tickFormatter: (v) => moment(v).format('YYYY MMM'),
        }}
        tooltipContent={({ active, payload, label }) => {
          if (payload?.length) {
            const { payload: item } = payload[0];
            return (
              <Box mx={2}>
                <GrmtTooltipWrapper
                  active={active}
                  payload={payload}
                  label={moment(label).format('YYYY MMM')}
                >
                  <GrmtTooltipItem
                    dotColor="#FF7976"
                    name={t('label.common.od')}
                    value={t('label.common.hours', {
                      value: ValueFormatter.toLocaleString(item.Ondemand, 0),
                    })}
                  />
                  <GrmtTooltipItem
                    dotColor="#FF7976"
                    name={t('label.common.od', { context: 'cost' })}
                    value={ValueFormatter.toCurrency(item.Ondemand)}
                  />
                  <GrmtTooltipDivider />
                  <GrmtTooltipItem
                    dotColor="#57CAEB"
                    name={t('label.common.ri')}
                    value={t('label.common.hours', {
                      value: ValueFormatter.toLocaleString(item.Reserved, 0),
                    })}
                  />
                  <GrmtTooltipItem
                    dotColor="#57CAEB"
                    name={t('label.common.ri', { context: 'coverage' })}
                    value={ValueFormatter.toPercent(item.ReservedCoverage)}
                  />
                  <GrmtTooltipDivider />
                  <GrmtTooltipItem
                    dotColor="#9594FF"
                    name={t('label.common.sp')}
                    value={t('label.common.hours', {
                      value: ValueFormatter.toLocaleString(item.SavingsPlan, 0),
                    })}
                  />
                  <GrmtTooltipItem
                    dotColor="#9594FF"
                    name={t('label.common.sp', { context: 'coverage' })}
                    value={ValueFormatter.toPercent(item.SavingsPlanCoverage)}
                  />
                </GrmtTooltipWrapper>
              </Box>
            );
          }
          return null;
        }}
      />
    </ChartCoverBox>
  );
};

export default ComputeCoverageChart;
