import React from 'react';
import { useRecoilValue } from 'recoil';
import { OnboardPageWrapper } from '@pages/private/onboard/Onboard.styled';
import { useTranslation } from 'react-i18next';
import { SuspenseBox } from '@components/styled/suspense';
import { linkedViewStepState } from './LinkedView.atoms';
import LinkedSelectionView from './LinkedSelectionView';
import LinkedRequestView from './LinkedRequestView';

const LinkedView: React.FC<{ uid: string }> = ({ uid }) => {
  const { t } = useTranslation('onboard');
  const active = useRecoilValue(linkedViewStepState);
  return (
    <OnboardPageWrapper title={t('title.requestCUR')}>
      <SuspenseBox>
        {active === 'DEFAULT' && <LinkedSelectionView />}
        {active !== 'DEFAULT' && <LinkedRequestView uid={uid} />}
      </SuspenseBox>
    </OnboardPageWrapper>
  );
};

export default React.memo(LinkedView);
