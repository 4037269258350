import { atom } from 'recoil';
import { localStorageEffect } from '@utils/recoil';
import { ATOM_KEY } from '@constants';

export const selectedAccountState = atom<string[]>({
  key: ATOM_KEY.GLOBAL.SELECTED_ACCOUNT,
  default: [],
  effects_UNSTABLE: [
    localStorageEffect<string[]>(ATOM_KEY.GLOBAL.SELECTED_ACCOUNT),
  ],
});

export const selectedAccountTemporaryState = atom<string[]>({
  key: ATOM_KEY.GLOBAL.SELECTED_ACCOUNT_TMP,
  default: [],
});
