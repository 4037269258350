import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEY } from '@constants';
import { AWSAccountService } from '@api/service';

export default () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.ONBOARD.CREATE_ACCOUNT,
    mutationFn: (
      variables: Pick<
        AWSAccount,
        'name' | 'arn' | 'isPayer' | 'companyId' | 'externalId'
      >,
    ) =>
      AWSAccountService.createAWSAccount(
        variables.name,
        variables.arn,
        variables.isPayer,
        variables.companyId,
        variables.externalId,
      ),
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEY.CURRENT.AWS),
  });
};
