import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import _ from 'lodash';
import { usePlannerUncoveredDetail } from '@hooks/api/planner';
import { CCModalProps, DialogModal } from '@components/modal';
import { SimpleTypoTable } from '@components/styled';
import { TagChip } from '@components/styled/chip';
import { bluegrey } from '@theme/colors';
import { ROUTES } from '@routes';
import { ValueFormatter } from '@utils';
import { useTranslation } from 'react-i18next';
import { useAWSHelper } from '@hooks/helper';
import { PlannerComparisonPaper } from '../../Planner.styled';
import { SimulationHeader } from '../../simulation/Simulation.styled';

const UncoveredDetailModal: React.FC<CCModalProps & { instanceId: string }> = ({
  open,
  onClose,
  instanceId,
}) => {
  const { t } = useTranslation('planner', { useSuspense: false });
  const { t: commonT } = useTranslation('common', { useSuspense: false });
  const { t: optT } = useTranslation('optimization', { useSuspense: false });
  const { getAWSRegionName } = useAWSHelper();
  const navigate = useNavigate();
  const { data } = usePlannerUncoveredDetail(instanceId);
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      maxWidth="lg"
      HeaderProps={{
        icon: 'Smart Planner',
        label: t('header.detail', { context: 'od' }),
      }}
      Actions={[
        {
          label: commonT('button.cancel'),
          color: 'inherit',
          onClick: onClose,
        },
        {
          label: t('label.purchase', { context: 'plan' }),
          endIcon: <ArrowForwardIcon sx={{ width: '20px', height: '20px' }} />,
          onClick: () => {
            if (data?.items?.length) {
              navigate(
                `${ROUTES.PLANNER.SIMULATION_UNCOVERED}/${data.items[0].InstanceId}`,
              );
              onClose();
            }
          },
        },
      ]}
    >
      {data?.items?.length && (
        <>
          <Paper>
            <SimpleTypoTable
              data={[
                {
                  label: optT('label.common.accountId'),
                  value: data.items[0].AccountId,
                },
                {
                  label: optT('label.common.instanceId'),
                  value: data.items[0].InstanceId,
                },
                {
                  label: optT('label.common.region'),
                  value: getAWSRegionName(data.items[0].Region),
                },
                {
                  label: optT('label.common.instanceType'),
                  value: data.items[0].InstanceType,
                },
                {
                  label: optT('label.common.tags'),
                  value: (
                    <Stack direction="row" spacing={1}>
                      {data.items[0].Tag.length &&
                        Object.keys(data.items[0].Tag[0]).map((key) => (
                          <TagChip
                            key={key}
                            label={`${key}: ${data.items[0].Tag[0][key]}`}
                          />
                        ))}
                    </Stack>
                  ),
                },
              ]}
              background={bluegrey[50]}
              disableBorder
            />
          </Paper>
          <Typography variant="body2" color="textSecondary" mt={5} mb={2}>
            {t('label.monthlyUsage')}
          </Typography>
          <Paper>
            <SimpleTypoTable
              data={[
                {
                  label: optT('label.common.od'),
                  value: `${optT('label.common.hours', {
                    value: ValueFormatter.toLocaleString(
                      data.items[0].OnDemandHours,
                      0,
                    ),
                  })} (${ValueFormatter.toCurrency(
                    data.items[0].OnDemandCost,
                  )})`,
                },
                {
                  label: t('label.familyTotal', {
                    label: optT('label.common.od'),
                  }),
                  value: `${optT('label.common.hours', {
                    value: ValueFormatter.toLocaleString(
                      data.items[0].InstanceFamilyUsage?.Ondemand || 0,
                      0,
                    ),
                  })} (${ValueFormatter.toCurrency(
                    data.items[0].InstanceFamilyUsage?.OndemandCost || 0,
                  )})`,
                },
                {
                  label: optT('label.common.ri_coverage'),
                  value: `${optT('label.common.hours', {
                    value: ValueFormatter.toLocaleString(
                      data.items[0].ReservedHours,
                      0,
                    ),
                  })} (${ValueFormatter.toPercent(data.items[0].RICoverage)})`,
                },
                {
                  label: t('label.familyTotal', {
                    label: optT('label.common.ri_coverage'),
                  }),
                  value: `${optT('label.common.hours', {
                    value: ValueFormatter.toLocaleString(
                      data.items[0].InstanceFamilyUsage?.Reserved || 0,
                      0,
                    ),
                  })} (${ValueFormatter.toPercent(
                    data.items[0].InstanceFamilyUsage?.ReservedCoverage || 0,
                  )})`,
                },
                {
                  label: optT('label.common.sp_coverage'),
                  value: `${optT('label.common.hours', {
                    value: ValueFormatter.toLocaleString(
                      data.items[0].SavingsPlanHours,
                      0,
                    ),
                  })} (${ValueFormatter.toPercent(
                    data.items[0].SavingsPlanCoverage,
                  )})`,
                },
                {
                  label: t('label.familyTotal', {
                    label: optT('label.common.sp_coverage'),
                  }),
                  value: `${optT('label.common.hours', {
                    value: ValueFormatter.toLocaleString(
                      data.items[0].InstanceFamilyUsage?.SavingsPlan || 0,
                      0,
                    ),
                  })} (${ValueFormatter.toPercent(
                    data.items[0].InstanceFamilyUsage?.SavingsPlanCoverage || 0,
                  )})`,
                },
              ]}
              background={bluegrey[50]}
              disableBorder
            />
          </Paper>
          {!_.isEmpty(data.items[0].RecommendPricingPlan) && (
            <>
              <SimulationHeader
                Title={t('label.recommendation')}
                mt={7}
                mb={3}
              />
              <Grid container columnSpacing={3}>
                <Grid item md={6} xs={12}>
                  <PlannerComparisonPaper
                    type="Aggressive"
                    item={data.items[0].RecommendPricingPlan.Aggressive}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <PlannerComparisonPaper
                    type="Passive"
                    item={data.items[0].RecommendPricingPlan.Passive}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </DialogModal>
  );
};

export default UncoveredDetailModal;
