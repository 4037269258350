import React from 'react';
import { WithDiagnostic, WithTrial } from '@hooks/hoc';
import { SuspenseBox } from '@components/styled/suspense';
import { OptimizationPaper } from '@components/styled/paper';
import { VolumeUsageChart } from './chart';
import { VolumeTable } from './table';

const OptimizationVolumePage: React.FC = () => (
  <OptimizationPaper showAPM>
    <SuspenseBox>
      <VolumeUsageChart />
    </SuspenseBox>
    <SuspenseBox mt={12}>
      <VolumeTable />
    </SuspenseBox>
  </OptimizationPaper>
);

export default WithTrial(WithDiagnostic(OptimizationVolumePage));
