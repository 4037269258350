import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WithCUR, WithTrial } from '@hooks/hoc';
import { HeaderTabContextWithRecoil } from '@components/styled';
import { SuspensePaper } from '@components/styled/suspense';
import { useSetRecoilState } from 'recoil';
import { detailsRDSState, detailsRDSTabState } from '@atoms/details';
import { Typography } from '@mui/material';
import { TabPanel } from '@mui/lab';
import DetailsDatabaseGeneral from './DetailsDatabaseGeneral';
import DetailsDatabaseUtilizationPage from './utilization';
import DetailsDatabasePurchasingPage from './purchasing';
import DetailsDatabaseStoragePage from './storage';

const DetailsDatabasePage: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { databaseId } = useParams();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const setDatabaseId = useSetRecoilState(detailsRDSState);
  const setSelectedTab = useSetRecoilState(detailsRDSTabState);
  useEffect(() => {
    if (tab === 'utilization' || tab === 'purchasing' || tab === 'storage') {
      setSelectedTab(tab);
    }
  }, [tab]);
  useEffect(() => {
    if (databaseId) {
      setDatabaseId(databaseId);
    }
  }, [databaseId]);
  useEffect(() => () => setDatabaseId(undefined), []);
  return (
    <HeaderTabContextWithRecoil
      items={[
        {
          label: t('label.header.utilization'),
          value: 'utilization',
        },
        {
          label: t('label.header.purchasing'),
          value: 'purchasing',
        },
        {
          label: t('label.header.storage'),
          value: 'storage',
        },
      ]}
      state={detailsRDSTabState}
    >
      <Typography variant="subtitle2" color="textSecondary" mb={2}>
        {t('label.common.general')}
      </Typography>
      <SuspensePaper>
        <DetailsDatabaseGeneral />
      </SuspensePaper>
      <SuspensePaper sx={{ px: 7, py: 5, mt: 6 }}>
        <TabPanel value="utilization">
          <DetailsDatabaseUtilizationPage />
        </TabPanel>
        <TabPanel value="purchasing">
          <DetailsDatabasePurchasingPage />
        </TabPanel>
        <TabPanel value="storage">
          <DetailsDatabaseStoragePage />
        </TabPanel>
      </SuspensePaper>
    </HeaderTabContextWithRecoil>
  );
};

export default WithTrial(WithCUR(DetailsDatabasePage));
