import axios from '@utils/axios';
import { API_URL } from '@constants';
import { parseContentRange } from '@utils';
import moment from 'moment';
import { parseStartOfUTC } from '@utils/date';

const getRDSInformationList: PaginatedAxios<
  WithContentRange<CCQueryResponse<RDSInformation>>
> = async (skip, limit, filter, sort, apm, period, category) => {
  const { headers, data } = await axios.get(`${API_URL.OPTIMIZATION}/rds/`, {
    params: {
      skip,
      limit,
      period,
      category,
      filter,
      sort,
    },
  });
  return { headers: parseContentRange(headers), body: data };
};

const getRDSCoverageList: PaginatedAxios<
  WithContentRange<CCQueryResponse<RDSCoverage, { LatestUpdate: Date }>>
> = async (skip, limit, filter, sort) => {
  const elem: Record<string, any> | undefined = filter ? {} : undefined;
  if (filter) {
    const parsed = JSON.parse(filter);
    Object.keys(parsed).forEach((key) => {
      if (key === 'Coverage') {
        elem[key] = { $lte: Number(parsed[key]) };
      } else {
        elem[key] = parsed[key];
      }
    });
  }
  const newFilter = filter ? JSON.stringify(elem) : undefined;
  const { headers, data } = await axios.get(
    `${API_URL.OPTIMIZATION}/rdsri/coverage/`,
    {
      params: {
        skip,
        limit,
        filter: newFilter,
        sort,
      },
    },
  );
  return { headers: parseContentRange(headers), body: data };
};

const getRDSUtilizationList: PaginatedAxios<
  WithContentRange<CCQueryResponse<RDSRIUtilization, { LatestUpdate: Date }>>
> = async (skip, limit, filter, sort) => {
  const elem: Record<string, any> | undefined = filter ? {} : undefined;
  if (filter) {
    const parsed = JSON.parse(filter);
    Object.keys(parsed).forEach((key) => {
      if (key === 'Utilization') {
        elem[key] = { $lte: Number(parsed[key]) };
      } else {
        elem[key] = parsed[key];
      }
    });
  }
  const newFilter = filter ? JSON.stringify(elem) : undefined;
  const { headers, data } = await axios.get(
    `${API_URL.OPTIMIZATION}/rdsri/utilization/`,
    {
      params: {
        skip,
        limit,
        filter: newFilter,
        sort,
      },
    },
  );
  return { headers: parseContentRange(headers), body: data };
};

const getRDSStorageList: PaginatedAxios<
  WithContentRange<CCQueryResponse<RDSStorage, { LatestUpdate: Date }>>
> = async (skip, limit, filter, sort) => {
  const { headers, data } = await axios.get(
    `${API_URL.OPTIMIZATION}/rds/storage/`,
    {
      params: {
        skip,
        limit,
        filter,
        sort,
      },
    },
  );
  return { headers: parseContentRange(headers), body: data };
};

const getRDSUtilizationChart = async (
  period: RecommendPeriod,
  category: RecommendCategory,
  AccountList: string | undefined = undefined,
  filter: string | undefined = undefined,
): Promise<
  CCQueryResponse<
    RDSUtilizationChart,
    {
      TotalNumberOfRDS: number;
      TotalNumberOfRightSizing: number;
      TotalPossibleSaving: number;
      LatestUpdate: Date;
    }
  >
> => {
  const elem: Record<string, any> | undefined = filter ? {} : undefined;
  if (filter) {
    const parsed = JSON.parse(filter);
    Object.keys(parsed).forEach((key) => {
      if (key === 'CPUMax' || key === 'MemMax') {
        elem[key] = undefined;
      } else {
        elem[key] = parsed[key];
      }
    });
  }
  const newFilter = filter ? JSON.stringify(elem) : undefined;
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/rds/graph/utilization/`,
    {
      params: {
        period,
        category,
        AccountList,
        filter: newFilter,
      },
    },
  );
  return data;
};

const getRDSStorageChart = async (
  AccountList: string | undefined = undefined,
): Promise<
  CCQueryResponse<
    RDSStorageChart,
    {
      TotalCost: number;
      TotalGeneralPurpose: number;
      TotalNumberOfSavings: number;
      TotalNumberOfStorages: number;
      TotalPossibleSavings: number;
      TotalProvisioned: number;
      LatestUpdate: Date;
    }
  >
> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/rds/graph/storage/`,
    {
      params: {
        AccountList,
      },
    },
  );
  return data;
};

const getRDSPurchasingCoverageChart = async (
  AccountList: string | undefined = undefined,
): Promise<CCQueryResponse<RDSPurchasingCoverageChart>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/rds/graph/pricingplan/coverage/`,
    {
      params: {
        AccountList,
      },
    },
  );
  return data;
};

const getRDSPurchasingUtilizationChart = async (
  AccountList: string | undefined = undefined,
): Promise<CCQueryResponse<RDSPurchasingUtilizationChart>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/rds/graph/pricingplan/utilization/`,
    {
      params: {
        AccountList,
      },
    },
  );
  return data;
};

const getRDSAutoComplete = async (): Promise<
  CCQueryResponse<RDSAutoComplete>
> => {
  const { data } = await axios.get(`${API_URL.OPTIMIZATION}/rds/acdata`);
  return data;
};

const getRDSCoverageAutoComplete = async (): Promise<
  CCQueryResponse<RDSCoverageAutoComplete>
> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/rdsri/coverage/acdata`,
  );
  return data;
};

const getRDSRIUtilizationAutoComplete = async (): Promise<
  CCQueryResponse<RDSRIUtilizationAutoComplete>
> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/rdsri/utilization/acdata`,
  );
  return data;
};

const getRDSStorageAutoComplete = async (): Promise<
  CCQueryResponse<RDSStorageAutoComplete>
> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/rds/storage/acdata`,
  );
  return data;
};

const getRDSDetailsGeneral = async (
  instanceId: string,
): Promise<CCQueryResponse<RDSDetailsGeneral>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/rds/details/general/${instanceId}`,
  );
  return data;
};

const getRDSDetailsPricingPlan = async (
  instanceId: string,
): Promise<CCQueryResponse<RDSDetailsPricingPlan>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/rds/details/pricingplan/${instanceId}`,
  );
  return data;
};

const getRDSDetailsStorage = async (
  instanceId: string,
): Promise<CCQueryResponse<RDSDetailsStorage>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/rds/details/storage/${instanceId}`,
  );
  return data;
};

const getRDSDetailsUtilization = async (
  instanceId: string,
): Promise<
  CCQueryResponse<RDSDetailsUtilization, RDSDetailsUtilizationExtras>
> => {
  const { data } = await axios.post(
    `${API_URL.OPTIMIZATION}/rds/details/utilization/${instanceId}`,
    {
      StartTime: moment()
        .utc()
        .subtract(59, 'days')
        .startOf('day')
        .toISOString(true),
      EndTime: moment().utc().endOf('day').toISOString(true),
      Period: 86400,
    },
  );
  return data;
};

const getRDSDetailsDailyUtilization = async (
  instanceId: string,
  selectedDate: string,
): Promise<
  CCQueryResponse<RDSDetailsUtilization, RDSDetailsUtilizationExtras>
> => {
  const started = parseStartOfUTC(selectedDate);
  const { data } = await axios.post(
    `${API_URL.OPTIMIZATION}/rds/details/utilization/${instanceId}`,
    {
      StartTime: started.toISOString(true),
      EndTime: started.add(1, 'days').toISOString(true),
      Period: 300,
    },
  );
  return data;
};

const getRDSDetailsRecommend = async (
  instanceId: string,
): Promise<CCQueryResponse<RDSInformation>> => {
  const { data } = await axios.get(`${API_URL.OPTIMIZATION}/rds/`, {
    params: {
      skip: 0,
      limit: 1,
      period: 'Days14',
      category: 'SavingsFirst',
      filter: JSON.stringify({ InstanceId: instanceId }),
    },
  });
  return data;
};

export default {
  getRDSInformationList,
  getRDSCoverageList,
  getRDSUtilizationList,
  getRDSStorageList,
  getRDSUtilizationChart,
  getRDSStorageChart,
  getRDSPurchasingCoverageChart,
  getRDSPurchasingUtilizationChart,
  getRDSAutoComplete,
  getRDSCoverageAutoComplete,
  getRDSRIUtilizationAutoComplete,
  getRDSStorageAutoComplete,
  getRDSDetailsGeneral,
  getRDSDetailsPricingPlan,
  getRDSDetailsStorage,
  getRDSDetailsUtilization,
  getRDSDetailsDailyUtilization,
  getRDSDetailsRecommend,
};
