import { useMutation } from 'react-query';
import { UserService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useMutation({
    mutationKey: QUERY_KEY.SETTINGS.USER.INVITE_USER,
    mutationFn: (variables: {
      inviterName: string;
      companyName: string;
      userEmail: string;
      password: string;
    }) =>
      UserService.inviteUser(
        variables.inviterName,
        variables.companyName,
        variables.userEmail,
        variables.password,
      ),
  });
