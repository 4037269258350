import React from 'react';
import { Paper } from '@mui/material';
import { SuspenseBox } from '@components/styled/suspense';
import { ComputeOtherChart } from './chart';
import { ComputeOtherTable } from './table';

const ComputeOtherPage: React.FC = () => (
  <Paper sx={{ px: 7, py: 5 }}>
    <SuspenseBox>
      <ComputeOtherChart />
    </SuspenseBox>
    <SuspenseBox mt={12}>
      <ComputeOtherTable />
    </SuspenseBox>
  </Paper>
);

export default ComputeOtherPage;
