import React, { useCallback, useMemo } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  InputAdornment,
  ListItemText,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useGetUsageTagAutoComplete } from '@hooks/api/aws/ce';
import {
  UsageDivider,
  UsageFilterButton,
  UsageFilterInput,
  UsagePopperComponent,
} from './styled';

interface TagValueSelectorProps {
  onClose: () => void;
  onConfirm: () => void;
  values: Array<CEUsageTagItemInternal>;
  setValues: React.Dispatch<
    React.SetStateAction<Array<CEUsageTagItemInternal>>
  >;
}

export default function TagValueSelector({
  onClose,
  onConfirm,
  values,
  setValues,
}: TagValueSelectorProps) {
  const { t } = useTranslation('usage');
  const { t: commonT } = useTranslation('common');
  const { data } = useGetUsageTagAutoComplete();
  const options = useMemo(() => {
    const items: Array<CEUsageTagItemInternal> = [];
    if (data?.items.length ?? 0 > 1) {
      items.push({ TagKey: '', Value: '' });
      Object.keys(data.items[0])
        .filter((v) => v !== 'NumberOfTags')
        .forEach((k) => {
          data.items[0][k].forEach((v) => {
            items.push({ TagKey: k, Value: v });
          });
        });
    }
    return items;
  }, [data?.items]);
  const getOptionLabel = useCallback(
    (option: CEUsageTagItemInternal) => {
      if (_.isEmpty(option.TagKey) && _.isEmpty(option.Value)) {
        return t('text.select_all', {
          count: Math.max(options.length - 1, 0),
        });
      }
      if (_.isEmpty(option.Value)) {
        return `${t('text.empty_tag_value')}: ${option.TagKey}`;
      }
      return `${option.TagKey}: ${option.Value}`;
    },
    [options.length, t],
  );
  const isAllSelected = useMemo(() => {
    if (options.length && values.length) {
      return options.length - values.length === 1;
    }
    return false;
  }, [options.length, values.length]);
  const isPartialSelected = useMemo(() => {
    if (options.length && values.length) {
      return options.length - 1 > values.length;
    }
    return false;
  }, [options.length, values.length]);
  const toggleAll = useCallback(() => {
    if (isAllSelected) {
      setValues([]);
    } else if (data?.items) {
      setValues(
        _.flattenDeep(
          Object.keys(data.items[0])
            .filter((v) => v !== 'NumberOfTags')
            .map((k) => data.items[0][k].map((v) => ({ TagKey: k, Value: v }))),
        ),
      );
    }
  }, [data?.items, isAllSelected, setValues]);
  const getChecked = useCallback(
    (option: CEUsageTagItemInternal, selected: boolean) => {
      if (_.isEmpty(option.TagKey) && _.isEmpty(option.Value)) {
        return isAllSelected;
      }
      return selected;
    },
    [isAllSelected],
  );
  const getIndeterminate = useCallback(
    (option: CEUsageTagItemInternal) => {
      if (_.isEmpty(option.TagKey) && _.isEmpty(option.Value)) {
        return isPartialSelected && !isAllSelected;
      }
      return undefined;
    },
    [isAllSelected, isPartialSelected],
  );
  return (
    <Box>
      <Autocomplete
        open
        multiple
        disableCloseOnSelect
        value={values}
        onClose={(e, reason) => {
          if (reason === 'escape') {
            onClose();
          }
        }}
        PopperComponent={UsagePopperComponent}
        renderTags={() => null}
        noOptionsText={t('text.no_tag_values')}
        onChange={(event, value, reason) => {
          if (
            event.type === 'keydown' &&
            (event as React.KeyboardEvent).key === 'Backspace' &&
            reason === 'removeOption'
          ) {
            return;
          }
          if (
            (value as CEUsageTagItemInternal[]).find(
              (v) => _.isEmpty(v.TagKey) && _.isEmpty(v.Value),
            )
          ) {
            toggleAll();
            return;
          }
          setValues(value as CEUsageTagItemInternal[]);
        }}
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) =>
          option.TagKey === value.TagKey && option.Value === value.Value
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              color="tertiary"
              checked={getChecked(option, selected)}
              indeterminate={getIndeterminate(option)}
              sx={{ p: 0, pr: '8px' }}
            />
            <ListItemText sx={{ my: 0, wordWrap: 'break-word' }}>
              {getOptionLabel(option)}
            </ListItemText>
          </li>
        )}
        renderInput={(params) => (
          <UsageFilterInput
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            autoFocus
            placeholder={t('filter.filter_tag_values')}
          />
        )}
      />
      <UsageDivider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '8px',
          p: '10px 16px',
        }}
      >
        <UsageFilterButton color="inherit" onClick={onClose}>
          {commonT('button.cancel')}
        </UsageFilterButton>
        <UsageFilterButton color="tertiary" onClick={onConfirm}>
          {commonT('button.apply')}
        </UsageFilterButton>
      </Box>
    </Box>
  );
}
