import { useQuery } from 'react-query';
import { IntegrationService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useQuery(
    QUERY_KEY.SETTINGS.INTEGRATION.GET_NEWRELIC,
    () => IntegrationService.getNewrelic(),
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
