import React, { MouseEvent, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import {
  useEC2DataTransferAutoComplete,
  useEC2DataTransferSummary,
} from '@hooks/api/optimization/ec2';
import { ValueFormatter } from '@utils';
import { useAWSHelper } from '@hooks/helper';
import { bluegrey } from '@theme/colors';
import { CostSummaryChart } from '@components/chart/optimization';

const ComputeTransferSummaryTable: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { getDataTransferRegion } = useAWSHelper();
  const { data, setItemList } = useEC2DataTransferSummary();
  const { data: acData } = useEC2DataTransferAutoComplete();
  const [filter, setFilter] = useState<Array<string>>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = useCallback((e: MouseEvent<any>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setItemList(filter);
  }, [filter, setItemList]);
  const handleArrayChange = useCallback(
    (v: string | null) => {
      if (v) {
        const current = filter;
        const set = new Set(current);
        if (set.has(v)) {
          set.delete(v);
        } else {
          set.add(v);
        }
        setFilter(Array.from(set));
      } else if (acData?.items?.length) {
        if (filter.length === acData.items[0].FromLocation.length) {
          setFilter([]);
        } else {
          setFilter(Array.from(acData.items[0].FromLocation));
        }
      }
    },
    [filter, setFilter, acData?.items?.length],
  );
  return (
    <>
      {data?.items?.length && (
        <>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button
              variant="outlined"
              startIcon={<FilterAltIcon />}
              onClick={handleOpen}
              disabled={Boolean(
                !acData?.items?.length || !acData.items[0].FromLocation?.length,
              )}
            >
              {t('label.transfer.filter')}
            </Button>
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              disablePortal
            >
              <MenuItem onClick={() => handleArrayChange(null)}>
                <Checkbox
                  size="medium"
                  checked={
                    filter.length ===
                    Number(acData.items[0].FromLocation.length)
                  }
                  indeterminate={
                    filter.length !== 0 &&
                    filter.length !==
                      Number(acData.items[0].FromLocation.length)
                  }
                />
                <ListItemText
                  primary={
                    filter.length ===
                    Number(acData.items[0].FromLocation.length)
                      ? t('label.common.unselectAll')
                      : t('label.common.selectAll')
                  }
                />
              </MenuItem>
              {acData?.items?.length &&
                acData.items[0].FromLocation.map((item) => (
                  <MenuItem key={item} onClick={() => handleArrayChange(item)}>
                    <Checkbox
                      size="medium"
                      checked={filter.indexOf(item) > -1}
                    />
                    <ListItemText primary={getDataTransferRegion(item)} />
                  </MenuItem>
                ))}
            </Menu>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            border="2px solid #f0f0f7"
            px={6}
            py={3}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="subtitle1">
                {t('label.transfer.totalCost')}
              </Typography>
              <Typography variant="h2" color="primary" align="right">
                {ValueFormatter.toCurrency(data.items[0].TotalCost)}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1">
                {t('label.transfer.totalWasted')}
              </Typography>
              <Typography variant="h2" color={bluegrey[400]} align="right">
                {ValueFormatter.toCurrency(data.items[0].CostsWaste)}
              </Typography>
            </Box>
          </Box>
          {data.items[0].TransferTypeSummary && (
            <Box
              bgcolor={bluegrey[50]}
              border="2px solid #f0f0f7"
              mt={3}
              px={6}
              py={4}
            >
              <CostSummaryChart
                data={Object.keys(data.items[0].TransferTypeSummary).map(
                  (key) => ({
                    TransferType: t(`label.transfer.${key as EC2TransferKeys}`),
                    ...data.items[0].TransferTypeSummary[key],
                  }),
                )}
                chartInfo={{
                  header: {
                    label: t('label.transfer.type'),
                    accessor: 'TransferType',
                  },
                  data: {
                    label: t('label.common.cost'),
                    accessor: 'Cost',
                  },
                  extra: {
                    label: `${t('label.transfer.traffic')} (GB)`,
                    accessor: 'Traffic',
                    formatter: ValueFormatter.toLocaleString,
                  },
                }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ComputeTransferSummaryTable;
