import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEC2OtherAutoComplete } from '@hooks/api/optimization/ec2';
import { useFilterFormik } from '@hooks/common';
import { useBestPracticeHelper } from '@hooks/helper';
import {
  FilterGrid,
  FilterGridItem,
  FilterProps,
  FilterSelect,
  FilterWrapper,
} from '@components/filter';
import { TextField } from '@mui/material';

type ComputeTransferFilterForm = {
  InstanceId: string;
  Category: Array<string>;
};

const useComputeTransferFilterForm =
  (): UseValidation<ComputeTransferFilterForm> => ({
    initialValues: {
      InstanceId: '',
      Category: [],
    },
    validationSchema: Yup.object({
      InstanceId: Yup.string(),
      Category: Yup.array().of(Yup.string()),
    }).defined(),
  });

const ComputeOtherFilter: React.FC<FilterProps> = ({
  onClose,
  onFilterChange,
  previousValues,
}) => {
  const { t } = useTranslation('optimization');
  const { getCategoryName } = useBestPracticeHelper();
  const { data } = useEC2OtherAutoComplete();
  const { initialValues, validationSchema } = useComputeTransferFilterForm();
  const { values, handleArrayChange, handleChange, handleBlur, handleSubmit } =
    useFilterFormik(
      {
        initialValues: previousValues || initialValues,
        validationSchema,
        onSubmit: (v) => {
          onFilterChange(v);
          if (onClose) {
            onClose();
          }
        },
      },
      data?.items,
    );
  if (!data?.items?.length) {
    return null;
  }
  const { Category } = data.items[0];
  return (
    <FilterWrapper onClose={onClose} onSubmit={handleSubmit}>
      <FilterGrid>
        <FilterGridItem label={t('label.common.instanceId')}>
          <TextField
            name="InstanceId"
            value={values.InstanceId}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </FilterGridItem>
        <FilterGridItem label={t('label.intelligence.category')}>
          <FilterSelect
            name="Category"
            value={values.Category}
            onChange={(e) => handleArrayChange('Category', e.target.value)}
            onBlur={handleBlur}
            renderValue={() =>
              values.Category.map((v: string) => getCategoryName(v)).join(', ')
            }
            selected={values.Category}
            candidate={Category}
            formatter={getCategoryName}
          />
        </FilterGridItem>
      </FilterGrid>
    </FilterWrapper>
  );
};

export default ComputeOtherFilter;
