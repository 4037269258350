import axios from '@utils/axios';
import { API_URL } from '@constants';
import { parseContentRange } from '@utils';
import moment from 'moment';
import { parseStartOfUTC } from '@utils/date';

const getEC2InformationList: PaginatedAxios<
  WithContentRange<CCQueryResponse<EC2Information>>
> = async (skip, limit, filter, sort, apm, period, category) => {
  const { headers, data } = await axios.get(`${API_URL.OPTIMIZATION}/ec2/`, {
    params: {
      skip,
      limit,
      filter,
      sort,
      apm,
      period,
      category,
    },
  });
  return { headers: parseContentRange(headers), body: data };
};

const getEC2CoverageList: PaginatedAxios<
  WithContentRange<CCQueryResponse<EC2Coverage, { LatestUpdate: Date }>>
> = async (skip, limit, filter, sort) => {
  const elem: Record<string, any> | undefined = filter ? {} : undefined;
  if (filter) {
    const parsed = JSON.parse(filter);
    Object.keys(parsed).forEach((key) => {
      if (key === 'RICoverage' || key === 'SavingsPlanCoverage') {
        elem[key] = { $lte: Number(parsed[key]) };
      } else {
        elem[key] = parsed[key];
      }
    });
  }
  const newFilter = filter ? JSON.stringify(elem) : undefined;
  const { headers, data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/dc_coverage/`,
    {
      params: {
        skip,
        limit,
        filter: newFilter,
        sort,
      },
    },
  );
  return { headers: parseContentRange(headers), body: data };
};

const getEC2RIUtilizationList: PaginatedAxios<
  WithContentRange<CCQueryResponse<EC2RIUtilization, { LatestUpdate: Date }>>
> = async (skip, limit, filter, sort) => {
  const { headers, data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/ri_utilization/`,
    {
      params: {
        skip,
        limit,
        filter,
        sort,
      },
    },
  );
  return { headers: parseContentRange(headers), body: data };
};

const getEC2DetailsGeneral = async (
  instanceId: string,
): Promise<CCQueryResponse<EC2DetailsGeneral>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/details/general/${instanceId}`,
  );
  return data;
};

const getEC2DetailsUtilization = async (
  instanceId: string,
  apm: APMSource = 'cloudwatch',
): Promise<EC2DetailsUtilization> => {
  const { data } = await axios.post(
    `${API_URL.AWS.EC2}/get_instance_utilization/${instanceId}`,
    {
      StartTime: moment()
        .utc()
        .subtract(59, 'days')
        .startOf('day')
        .toISOString(true),
      EndTime: moment().utc().endOf('day').toISOString(true),
      Apm: apm,
    },
  );
  return data;
};

const getEC2DetailsDailyUtilization = async (
  instanceId: string,
  apm: APMSource = 'cloudwatch',
  selectedDate: string,
): Promise<EC2DetailsUtilization> => {
  const started = parseStartOfUTC(selectedDate);
  const { data } = await axios.post(
    `${API_URL.AWS.EC2}/get_instance_utilization/${instanceId}`,
    {
      StartTime: started.toISOString(true),
      EndTime: started.add(1, 'days').toISOString(true),
      Apm: apm,
      Period: 300,
    },
  );
  return data;
};

const getEC2DetailsRecommend = async (
  instanceId: string,
): Promise<CCQueryResponse<EC2Information>> => {
  const { data } = await axios.get(`${API_URL.OPTIMIZATION}/ec2/`, {
    params: {
      skip: 0,
      limit: 1,
      filter: JSON.stringify({ InstanceId: [instanceId] }),
      apm: 'cloudwatch',
      period: 'Days14',
      category: 'SavingsFirst',
    },
  });
  return data;
};

const getEC2DetailsPurchasing = async (
  instanceId: string,
): Promise<
  CCQueryResponse<EC2DetailsPurchasing, { InstanceFamily: string }>
> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/details/pricingplan/${instanceId}`,
  );
  return data;
};

const getEC2DetailsTransferSummary = async (
  instanceId: string,
): Promise<
  CCQueryResponse<EC2DetailsTransferSummary, EC2DetailsTransferExtras>
> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/details/transfer/summary/${instanceId}`,
  );
  return data;
};

const getEC2DetailsTransferDetails: PaginatedAxiosWithId<
  WithContentRange<CCQueryResponse<EC2DetailsTransferDetails>>
> = async (skip, limit, filter, sort, id) => {
  const originalFilter = filter ? JSON.parse(filter) : {};
  const filterElem = {
    ...originalFilter,
    InstanceId: [id],
  };
  const { headers, data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/details/transfer/details/${id}/`,
    {
      params: {
        skip,
        limit,
        filter: JSON.stringify(filterElem),
        sort,
      },
    },
  );
  return { headers: parseContentRange(headers), body: data };
};

const getEC2DetailsOthers = async (
  instanceId: string,
): Promise<CCQueryResponse<EC2DetailsOthers>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/details/others/${instanceId}`,
  );
  return data;
};

const getEC2SPUtilizationList: PaginatedAxios<
  WithContentRange<CCQueryResponse<EC2SPUtilization, { LatestUpdate: Date }>>
> = async (skip, limit, filter, sort) => {
  const { headers, data } = await axios.get(
    `${API_URL.OPTIMIZATION}/savings_plan/`,
    {
      params: {
        skip,
        limit,
        filter,
        sort,
      },
    },
  );
  return { headers: parseContentRange(headers), body: data };
};

const getEC2DataTransferList: PaginatedAxios<
  WithContentRange<CCQueryResponse<EC2DataTransfer>>
> = async (skip, limit, filter, sort) => {
  const { headers, data } = await axios.get(
    `${API_URL.OPTIMIZATION}/datatransferec2/`,
    {
      params: {
        skip,
        limit,
        filter,
        sort,
      },
    },
  );
  return { headers: parseContentRange(headers), body: data };
};

const getEC2DataTransferSummary = async (
  ItemList: string[],
  AccountList: string | undefined = undefined,
): Promise<CCQueryResponse<EC2DataTransferSummary, { LatestUpdate: Date }>> => {
  const { data } = await axios.post(
    `${API_URL.OPTIMIZATION}/datatransferec2/summary/`,
    {
      ItemList,
      AccountList,
    },
  );
  return data;
};

const getEC2OtherList: PaginatedAxios<
  WithContentRange<CCQueryResponse<EC2Others, EC2OthersExtra>>
> = async (skip, limit, filter, sort) => {
  const { headers, data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/others/`,
    {
      params: {
        skip,
        limit,
        filter,
        sort,
      },
    },
  );
  return { headers: parseContentRange(headers), body: data };
};

const getEC2Miscellaneous = async (
  AccountList: string | undefined = undefined,
): Promise<CCQueryResponse<EC2Miscellaneous>> => {
  const { data } = await axios.get('/miscellaneous/', {
    params: {
      AccountList,
    },
  });
  return data;
};

const getEC2UtilizationChart = async (
  apm: APMSource,
  period: RecommendPeriod,
  category: RecommendCategory,
  AccountList: string | undefined = undefined,
  filter: string | undefined = undefined,
): Promise<
  CCQueryResponse<
    EC2UtilizationChart,
    {
      LatestUpdate: Date;
      TotalNumberOfRightSizing: number;
      TotalPossibleSaving: number;
    }
  >
> => {
  const elem: Record<string, any> | undefined = filter ? {} : undefined;
  if (filter) {
    const parsed = JSON.parse(filter);
    Object.keys(parsed).forEach((key) => {
      if (key === 'CPUMax' || key === 'MemMax') {
        elem[key] = undefined;
      } else {
        elem[key] = parsed[key];
      }
    });
  }
  const newFilter = filter ? JSON.stringify(elem) : undefined;
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/graph/utilization/`,
    {
      params: {
        apm,
        period,
        category,
        AccountList,
        filter: newFilter,
      },
    },
  );
  return data;
};

const getEC2PurchasingChart = async (
  AccountList: string | undefined = undefined,
): Promise<CCQueryResponse<EC2PurchasingChart>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/graph/pricingplan/`,
    {
      params: {
        AccountList,
      },
    },
  );
  return data;
};

const getEC2DataTransferChart = async (
  AccountList: string | undefined = undefined,
): Promise<CCQueryResponse<EC2DataTransferChart>> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/datatransferec2/graph/`,
    {
      params: {
        AccountList,
      },
    },
  );
  return data;
};

const getEC2AutoComplete = async (): Promise<
  CCQueryResponse<EC2AutoComplete>
> => {
  const { data } = await axios.get(`${API_URL.OPTIMIZATION}/ec2/acdata`);
  return data;
};

const getEC2CoverageAutoComplete = async (): Promise<
  CCQueryResponse<EC2CoverageAutoComplete>
> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/dc_coverage/acdata`,
  );
  return data;
};

const getEC2RIUtilizationAutoComplete = async (): Promise<
  CCQueryResponse<EC2RIUtilizationAutoComplete>
> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/ec2/ri_utilization/acdata`,
  );
  return data;
};

const getEC2SPUtilizationAutoComplete = async (): Promise<
  CCQueryResponse<EC2SPUtilizationAutoComplete>
> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/savings_plan/acdata`,
  );
  return data;
};

const getEC2DataTransferAutoComplete = async (): Promise<
  CCQueryResponse<EC2DataTransferAutoComplete>
> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/datatransferec2/acdata`,
  );
  return data;
};

const getEC2OtherAutoComplete = async (): Promise<
  CCQueryResponse<EC2OtherAutoComplete>
> => {
  const { data } = await axios.get(`${API_URL.OPTIMIZATION}/ec2/others/acdata`);
  return data;
};

export default {
  getEC2InformationList,
  getEC2CoverageList,
  getEC2DataTransferList,
  getEC2RIUtilizationList,
  getEC2SPUtilizationList,
  getEC2UtilizationChart,
  getEC2PurchasingChart,
  getEC2DataTransferChart,
  getEC2DataTransferSummary,
  getEC2AutoComplete,
  getEC2CoverageAutoComplete,
  getEC2RIUtilizationAutoComplete,
  getEC2SPUtilizationAutoComplete,
  getEC2DataTransferAutoComplete,
  getEC2DetailsGeneral,
  getEC2DetailsUtilization,
  getEC2DetailsDailyUtilization,
  getEC2DetailsRecommend,
  getEC2DetailsPurchasing,
  getEC2DetailsTransferSummary,
  getEC2DetailsOthers,
  getEC2OtherList,
  getEC2DetailsTransferDetails,
  getEC2Miscellaneous,
  getEC2OtherAutoComplete,
};
