import React, { useCallback } from 'react';
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  FilterGrid,
  FilterGridItem,
  FilterProps,
  FilterWrapper,
} from '@components/filter';
import { useEC2DataTransferAutoComplete } from '@hooks/api/optimization/ec2';
import { useAWSHelper } from '@hooks/helper';

type DetailsComputeTransferFilterForm = {
  TransferType: Array<string>;
  FromLocation: Array<string>;
  ToLocation: Array<string>;
};

const useDetailsComputeTransferFilterForm =
  (): UseValidation<DetailsComputeTransferFilterForm> => ({
    initialValues: {
      TransferType: [],
      FromLocation: [],
      ToLocation: [],
    },
    validationSchema: Yup.object({
      TransferType: Yup.array().of(Yup.string()),
      FromLocation: Yup.array().of(Yup.string()),
      ToLocation: Yup.array().of(Yup.string()),
    }).defined(),
  });

const DetailsComputeTransferFilter: React.FC<FilterProps> = ({
  onClose,
  onFilterChange,
  previousValues,
}) => {
  const { data } = useEC2DataTransferAutoComplete();
  const { t } = useTranslation('optimization');
  const { getDataTransferRegion } = useAWSHelper();
  const { initialValues, validationSchema } =
    useDetailsComputeTransferFilterForm();
  const { values, handleBlur, handleSubmit, setFieldValue } = useFormik({
    initialValues: previousValues || initialValues,
    validationSchema,
    onSubmit: (v) => {
      onFilterChange(v);
      if (onClose) {
        onClose();
      }
    },
  });
  const handleArrayChange = useCallback(
    (key: 'TransferType' | 'FromLocation' | 'ToLocation', v: string | null) => {
      if (v) {
        const current = values[key];
        const set = new Set(current);
        if (set.has(v)) {
          set.delete(v);
        } else {
          set.add(v);
        }
        setFieldValue(key, Array.from(set));
      } else if (data?.items?.length) {
        if (values[key].length === data.items[0][key].length) {
          setFieldValue(key, []);
        } else {
          setFieldValue(key, data.items[0][key]);
        }
      }
    },
    [values, setFieldValue, data?.items?.length],
  );

  return (
    <FilterWrapper onClose={onClose} onSubmit={handleSubmit}>
      {data?.items?.length && (
        <FilterGrid>
          <FilterGridItem label={t('label.transfer.type')}>
            <FormControl fullWidth>
              <Select
                name="TransferType"
                value={values.TransferType}
                onChange={(e) =>
                  handleArrayChange('TransferType', e.target.value)
                }
                onBlur={handleBlur}
                input={<OutlinedInput />}
                renderValue={() => values.TransferType.join(', ')}
                MenuProps={{ disableScrollLock: true }}
              >
                <MenuItem value={null}>
                  <Checkbox
                    size="medium"
                    checked={
                      values.TransferType.length ===
                      Number(data.items[0].TransferType.length)
                    }
                    indeterminate={
                      values.TransferType.length !== 0 &&
                      values.TransferType.length !==
                        Number(data.items[0].TransferType.length)
                    }
                  />
                  <ListItemText
                    primary={
                      values.TransferType.length ===
                      Number(data.items[0].TransferType.length)
                        ? t('label.common.unselectAll')
                        : t('label.common.selectAll')
                    }
                  />
                </MenuItem>
                {data?.items?.length &&
                  data.items[0].TransferType.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        size="medium"
                        checked={values.TransferType.indexOf(item) > -1}
                      />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </FilterGridItem>
          <FilterGridItem
            label={t('label.transfer.location', { context: 'from' })}
          >
            <FormControl fullWidth>
              <Select
                name="FromLocation"
                value={values.FromLocation}
                onChange={(e) =>
                  handleArrayChange('FromLocation', e.target.value)
                }
                onBlur={handleBlur}
                input={<OutlinedInput />}
                renderValue={() => values.FromLocation.join(', ')}
                MenuProps={{ disableScrollLock: true }}
              >
                <MenuItem value={null}>
                  <Checkbox
                    size="medium"
                    checked={
                      values.FromLocation.length ===
                      Number(data.items[0].FromLocation.length)
                    }
                    indeterminate={
                      values.FromLocation.length !== 0 &&
                      values.FromLocation.length !==
                        Number(data.items[0].FromLocation.length)
                    }
                  />
                  <ListItemText
                    primary={
                      values.FromLocation.length ===
                      Number(data.items[0].FromLocation.length)
                        ? t('label.common.unselectAll')
                        : t('label.common.selectAll')
                    }
                  />
                </MenuItem>
                {data?.items?.length &&
                  data.items[0].FromLocation.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        size="medium"
                        checked={values.FromLocation.indexOf(item) > -1}
                      />
                      <ListItemText primary={getDataTransferRegion(item)} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </FilterGridItem>
          <FilterGridItem
            label={t('label.transfer.location', { context: 'to' })}
          >
            <FormControl fullWidth>
              <Select
                name="ToLocation"
                value={values.ToLocation}
                onChange={(e) =>
                  handleArrayChange('ToLocation', e.target.value)
                }
                onBlur={handleBlur}
                input={<OutlinedInput />}
                renderValue={() => values.ToLocation.join(', ')}
                MenuProps={{ disableScrollLock: true }}
              >
                <MenuItem value={null}>
                  <Checkbox
                    size="medium"
                    checked={
                      values.ToLocation.length ===
                      Number(data.items[0].ToLocation.length)
                    }
                    indeterminate={
                      values.ToLocation.length !== 0 &&
                      values.ToLocation.length !==
                        Number(data.items[0].ToLocation.length)
                    }
                  />
                  <ListItemText
                    primary={
                      values.ToLocation.length ===
                      Number(data.items[0].ToLocation.length)
                        ? t('label.common.unselectAll')
                        : t('label.common.selectAll')
                    }
                  />
                </MenuItem>
                {data?.items?.length &&
                  data.items[0].ToLocation.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        size="medium"
                        checked={values.ToLocation.indexOf(item) > -1}
                      />
                      <ListItemText primary={getDataTransferRegion(item)} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </FilterGridItem>
        </FilterGrid>
      )}
    </FilterWrapper>
  );
};

export default DetailsComputeTransferFilter;
