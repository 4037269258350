import axios from '@utils/axios';
import { API_URL } from '@constants';
import _ from 'lodash';

const getUsage = async ({
  FromDate,
  ToDate,
  Granuality,
  Metric,
  AccountIdList,
  GroupBy,
  Services,
  ServicesExclude,
  Tags,
  TagsExclude,
  TagKey,
}: CEUsageRequestInternal): Promise<CCQuerySingleResponse<[CEUsage]>> => {
  const { data } = await axios.post(`${API_URL.AWS.CE}/usage`, {
    FromDate,
    ToDate,
    Granuality,
    Metric,
    AccountIdList,
    GroupBy: GroupBy !== 'Cost' ? GroupBy : undefined,
    Services,
    ServicesExclude,
    Tags,
    TagsExclude,
    TagKey: !_.isEmpty(TagKey?.trim()) ? TagKey : undefined,
  });
  return data;
};

const getAutoComplete = async (): Promise<
  CCQuerySingleResponse<[CEUsageAutoComplete]>
> => {
  const { data } = await axios.get(`${API_URL.AWS.CE}/acdata`);
  return data;
};

const getTagAutoComplete = async (
  tagKey: string[],
): Promise<CCQuerySingleResponse<[CEUsageTagAutoComplete]>> => {
  const { data } = await axios.get(`${API_URL.AWS.CE}/acdata/tag`, {
    params: {
      tag_key: JSON.stringify(tagKey),
    },
  });
  return data;
};

const downloadCSV = async ({
  FromDate,
  ToDate,
  Granuality,
  Metric,
  AccountIdList,
  GroupBy,
  Services,
  ServicesExclude,
  Tags,
  TagsExclude,
  TagKey,
}: CEUsageRequestInternal): Promise<{ blob: Blob; filename?: string }> => {
  const { data, headers } = await axios.post(
    `${API_URL.AWS.CE}/csv`,
    {
      FromDate,
      ToDate,
      Granuality,
      Metric,
      AccountIdList,
      GroupBy: GroupBy !== 'Cost' ? GroupBy : undefined,
      Services,
      ServicesExclude,
      Tags,
      TagsExclude,
      TagKey: !_.isEmpty(TagKey?.trim()) ? TagKey : undefined,
    },
    {
      responseType: 'blob',
    },
  );
  let filename: string | undefined;
  const disposition = headers['content-disposition'];
  if (disposition && disposition.indexOf('attachment !== -1')) {
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return {
    blob: data,
    filename,
  };
};

export default {
  getUsage,
  getAutoComplete,
  getTagAutoComplete,
  downloadCSV,
};
