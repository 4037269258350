import React, { useMemo, useState } from 'react';
import { useGetSubscriptions } from '@hooks/api/admin/subscription';
import { useToggle } from '@hooks/common';
import { GrmtTableColumnInfo, SimplePaginationTable } from '@components/table';
import { DetailButtonIcon } from '@components/table/table.styled';
import { ValueFormatter } from '@utils';
import CancelSubscriptionModal from './CancelSubscriptionModal';

const SubscriptionTable: React.FC = () => {
  const [openCancel, toggleCancel] = useToggle();
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const { data } = useGetSubscriptions();
  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      { Header: 'UID', accessor: 'UID' as const },
      { Header: 'Company name', accessor: 'CompanyName' as const },
      { Header: 'Tier', accessor: 'PlanName' as const },
      {
        Header: 'Started on',
        accessor: 'Created' as const,
        Cell: ({ value }) => ValueFormatter.toDateString(value, 'YYYY-MM-DD'),
      },
      {
        Header: 'Last Payment',
        accessor: 'CurrentPeriodStart' as const,
        Cell: ({ value }) => ValueFormatter.toDateString(value, 'YYYY-MM-DD'),
      },
      {
        Header: 'Next Payment',
        accessor: 'NextInvoiceDate' as const,
        Cell: ({ value }) => ValueFormatter.toDateString(value, 'YYYY-MM-DD'),
      },
      {
        Header: 'Amount',
        accessor: 'Amount',
      },
      {
        Header: 'Cancel',
        align: 'center',
        width: 80,
        maxWidth: 80,
        minWidth: 80,
        Cell: ({ row }) => (
          <DetailButtonIcon
            onClick={() => {
              setSelected(row.original.UID);
              toggleCancel();
            }}
          />
        ),
      },
    ],
    [],
  );
  return (
    <>
      <SimplePaginationTable columnInfo={columnInfo} data={data?.items} />
      {openCancel && selected && (
        <CancelSubscriptionModal
          open={openCancel}
          onClose={toggleCancel}
          subscriptionId={selected}
        />
      )}
    </>
  );
};

export default SubscriptionTable;
