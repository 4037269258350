import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import _ from 'lodash';
import {
  useAddAnomalyThresholdNotification,
  useAnomalyNotificationAutoComplete,
  useUpdateAnomalyThresholdNotification,
} from '@hooks/api/optimization/anomaly';
import { useAWSAccountHelper, useAWSHelper } from '@hooks/helper';
import { useSnackbar } from '@hooks/common';
import { CCModalProps, DialogModal } from '@components/modal';
import { isAxiosError } from '@utils';
import { useCurrentInfo } from '@hooks/api/common';
import {
  AnomalyConditionGrid,
  AnomalyRecipientList,
} from '../../AnomalyModal.styled';

type AnomalyConditionForm = {
  AccountId: string;
  Region: string;
  Service: string;
  Threshold: number;
  Duration: number;
};

const useAnomalyConditionForm = (): UseValidation<AnomalyConditionForm> => {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      AccountId: '',
      Region: '',
      Service: '',
      Duration: 0,
      Threshold: 0,
    },
    validationSchema: Yup.object({
      AccountId: Yup.string().trim().defined(t('common.required')),
      Region: Yup.string().trim().defined(t('common.required')),
      Service: Yup.string().trim().defined(t('common.required')),
      Duration: Yup.number().defined(t('common.required')),
      Threshold: Yup.number().defined(t('common.required')),
    }).defined(),
  };
};

const ConditionDetailsModal: React.FC<
  CCModalProps & { condition?: OptimizationAnomalyThreshold }
> = ({ open, onClose, condition }) => {
  const { t } = useTranslation('optimization', { useSuspense: false });
  const { t: commonT } = useTranslation('common', { useSuspense: false });
  const { t: toastT } = useTranslation('toast', { useSuspense: false });
  const { getAWSRegionName } = useAWSHelper();
  const { getAWSAccountNickname, getAccountNameById } = useAWSAccountHelper();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { currentCompany } = useCurrentInfo(false);
  const [EmailList, setEmailList] = useState<Array<AnomalyRecipient>>([]);
  const { data } = useAnomalyNotificationAutoComplete();
  const { mutateAsync: createCondition } = useAddAnomalyThresholdNotification();
  const { mutateAsync: updateCondition } =
    useUpdateAnomalyThresholdNotification();
  const { initialValues, validationSchema } = useAnomalyConditionForm();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (v) => {
      try {
        if (condition) {
          await updateCondition({
            original: condition,
            changes: {
              ...v,
              AccountName: getAccountNameById(v.AccountId),
              EmailList,
            },
          });
          showSuccessSnackbar(toastT('success.anomaly.condition.update'));
        } else {
          await createCondition({
            ...v,
            CompanyId: currentCompany?.id,
            AccountName: getAccountNameById(v.AccountId),
            EmailList,
          });
          showSuccessSnackbar(toastT('success.anomaly.condition.add'));
        }
        onClose();
      } catch (e) {
        if (isAxiosError(e) && e.response?.data?.msg) {
          switch (e.response.data.msg) {
            case 'Already exists':
              showErrorSnackbar(toastT('error.anomaly.condition.exist'));
              setFieldError(
                'Service',
                'Threshold notification is already set for this service and region',
              );
              setFieldError(
                'Region',
                'Threshold notification is already set for this service and region',
              );
              break;
            default:
              showErrorSnackbar(toastT('error.general'));
              break;
          }
        }
      }
    },
  });
  const handleAddEmail = useCallback(
    (newValue: AnomalyRecipient) => {
      const matched = EmailList.find((v) => v.Email === newValue.Email);
      if (!matched) {
        const clone = _.cloneDeep(EmailList);
        clone.push(newValue);
        setEmailList(clone);
      }
    },
    [EmailList],
  );
  const handleDeleteEmail = useCallback(
    (idx: number) => {
      if (EmailList.length > idx) {
        const clone = _.cloneDeep(EmailList);
        clone.splice(idx, 1);
        setEmailList(clone);
      }
    },
    [EmailList],
  );
  const Region = useMemo<Array<string>>(() => {
    if (data?.items?.length) {
      const items = Array.from(data.items[0].Region);
      const idx = items.findIndex((v) => v === 'All');
      if (idx > -1) {
        items.splice(idx, 1);
        items.unshift('All');
      }
      return items;
    }
    return [];
  }, [data?.items]);
  const Service = useMemo<Array<string>>(() => {
    if (values.Region && values.Region.length && data?.items?.length) {
      const items = Array.from(data.items[0].Service);
      if (values.Region === 'All') {
        return items.filter((v) => v === 'TotalBilling');
      }
      return items.filter((v) => v !== 'TotalBilling');
    }
    return [];
  }, [data?.items, values.Region]);
  useEffect(() => {
    if (condition) {
      setFieldValue('AccountId', condition.AccountId);
      setFieldValue('Region', condition.Region);
      setFieldValue('Service', condition.Service);
      setFieldValue('Duration', condition.Duration);
      setFieldValue('Threshold', condition.Threshold);
      setEmailList(condition.EmailList);
    }
  }, [condition]);
  if (!data?.items?.length) {
    return null;
  }
  const { AccountId } = data.items[0];
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="lg"
      HeaderProps={{
        icon: 'Anomaly Detection',
        label: condition
          ? t('label.anomaly.conditionDetails')
          : t('label.anomaly.addCondition'),
      }}
      Actions={[
        {
          color: 'inherit',
          label: commonT('button.cancel'),
          onClick: onClose,
        },
        {
          type: 'submit',
          label: condition
            ? commonT('button.saveChanges')
            : t('label.anomaly.addCondition'),
          disabled: !isValid,
        },
      ]}
    >
      <Typography variant="subtitle2" mb={3}>
        {t('label.anomaly.conditionSettings')}
      </Typography>
      <AnomalyConditionGrid
        conditions={[
          {
            label: t('label.common.accountId'),
            Element: (
              <TextField
                name="AccountId"
                value={values.AccountId}
                onChange={handleChange}
                onBlur={handleBlur}
                autoCapitalize="off"
                error={Boolean(errors.AccountId && touched.AccountId)}
                helperText={
                  Boolean(errors.AccountId && touched.AccountId) &&
                  String(errors.AccountId)
                }
                select
                fullWidth
              >
                {Boolean(AccountId?.length) &&
                  AccountId.map((v) => (
                    <MenuItem key={v} value={v}>
                      {getAWSAccountNickname(v)}
                    </MenuItem>
                  ))}
              </TextField>
            ),
          },
          {
            label: t('label.common.region'),
            Element: (
              <TextField
                name="Region"
                value={values.Region}
                onChange={(e) => {
                  if (
                    values.Region?.length &&
                    values.Region !== e.target.value
                  ) {
                    setFieldValue('Service', '');
                    setFieldTouched('Service', false);
                  }
                  handleChange(e);
                }}
                onBlur={handleBlur}
                autoCapitalize="off"
                error={Boolean(errors.Region && touched.Region)}
                helperText={
                  Boolean(errors.Region && touched.Region) &&
                  String(errors.Region)
                }
                select
                fullWidth
              >
                {Boolean(Region?.length) &&
                  Region.map((v) => (
                    <MenuItem key={v} value={v}>
                      {getAWSRegionName(v)}
                    </MenuItem>
                  ))}
              </TextField>
            ),
          },
          {
            label: t('label.common.service'),
            Element: (
              <TextField
                name="Service"
                value={values.Service}
                onChange={handleChange}
                onBlur={handleBlur}
                autoCapitalize="off"
                error={Boolean(errors.Service && touched.Service)}
                helperText={
                  Boolean(errors.Service && touched.Service) &&
                  String(errors.Service)
                }
                select
                fullWidth
              >
                {Boolean(Service?.length) &&
                  Service.map((v) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
              </TextField>
            ),
          },
          {
            label: t('label.anomaly.duration'),
            Element: (
              <TextField
                name="Duration"
                type="number"
                inputMode="decimal"
                value={values.Duration}
                onChange={handleChange}
                onBlur={handleBlur}
                autoCapitalize="off"
                error={Boolean(errors.Duration && touched.Duration)}
                helperText={
                  Boolean(errors.Duration && touched.Duration) &&
                  String(errors.Duration)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {t('label.anomaly.hour')}
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            ),
          },
          {
            label: t('label.anomaly.excessCost'),
            Element: (
              <TextField
                name="Threshold"
                type="number"
                inputMode="decimal"
                value={values.Threshold}
                onChange={handleChange}
                onBlur={handleBlur}
                autoCapitalize="off"
                error={Boolean(errors.Threshold && touched.Threshold)}
                helperText={
                  Boolean(errors.Threshold && touched.Threshold) &&
                  String(errors.Threshold)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            ),
          },
        ]}
      />
      <AnomalyRecipientList
        recipients={EmailList}
        onAdd={handleAddEmail}
        onDelete={handleDeleteEmail}
      />
    </DialogModal>
  );
};
ConditionDetailsModal.defaultProps = {
  condition: undefined,
};

export default ConditionDetailsModal;
