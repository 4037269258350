import { useQuery } from 'react-query';
import { QUERY_KEY } from '@constants';
import { OverviewService } from '@api/service';
import { useSelectedAWSAccounts } from '@hooks/api/common';

export default () => {
  const { apiQueryString } = useSelectedAWSAccounts();
  return useQuery(
    [QUERY_KEY.OVERVIEW.POSSIBLE_SAVINGS, apiQueryString],
    ({ queryKey }) => OverviewService.getPossibleSavings(queryKey[1]),
    { suspense: true, useErrorBoundary: true },
  );
};
