import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { GrmtTableColumnInfo, SimpleTable } from '@components/table';
import { ValueFormatter } from '@utils';

const BillingSummaryTable: React.FC<{
  invoices: BillingInvoice[];
  lastUpdated?: Date;
}> = ({ invoices, lastUpdated }) => {
  const { t } = useTranslation('report');
  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('table.aws_account'),
        accessor: 'AccountId' as const,
        Cell: ({ value }) => value,
        disableGlobalFilter: true,
      },
      {
        Header: t('table.cloud_service_charge_usd'),
        accessor: 'CloudCost.USD' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value) ?? '-',
      },
      {
        Header: t('table.cloud_service_charge_krw'),
        accessor: 'CloudCost.KRW' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value, 'KRW', 0) ?? '-',
        disableGlobalFilter: true,
      },
      {
        Header: t('table.additional_service_krw'),
        accessor: 'AdditionalCost.KRW' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value, 'KRW', 0) ?? '-',
      },
      {
        Header: t('table.discount_krw'),
        accessor: 'DiscountCost.KRW' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value, 'KRW', 0) ?? '-',
        disableGlobalFilter: true,
      },
      {
        Header: t('table.total_charge_krw'),
        accessor: 'TotalCost.KRW' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value, 'KRW', 0) ?? '-',
      },
    ],
    [],
  );
  return (
    <Box>
      <Box sx={{ display: 'flex', gap: '24px', mb: 3 }}>
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: '8px',
              height: '8px',
              borderRadius: '999px',
              backgroundColor: '#ff7976',
              mr: '8px',
            }}
          />
          <Typography variant="body2" color="textSecondary" mr={2}>
            {t('text.last_update')}
          </Typography>
          {!!lastUpdated && (
            <Typography variant="body2" color="textSecondary">
              {ValueFormatter.toDateString(lastUpdated, 'YYYY/MM/DD HH:mm')}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: '8px',
              height: '8px',
              borderRadius: '999px',
              backgroundColor: '#57caeb',
              mr: '8px',
            }}
          />
          <Typography variant="body2" color="textSecondary" mr={1}>
            {t('text.exchange_rate')}:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {`KRW ${ValueFormatter.toLocaleString(
              invoices[0].ExchangeRateValue,
            )} / 1 USD ${
              !_.isEmpty(invoices[0].ExchangeRateSource)
                ? invoices[0].ExchangeRateSource
                : t('source.korea_exim')
            }`}
          </Typography>
        </Box>
      </Box>
      <SimpleTable data={invoices} columnInfo={columnInfo} />
    </Box>
  );
};
BillingSummaryTable.defaultProps = {
  lastUpdated: undefined,
};

export default BillingSummaryTable;
