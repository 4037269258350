import React, { useMemo } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import _ from 'lodash';
import { useGetLastMonthBillingReport } from '@hooks/api/cmp';
import BillingSummaryTotal from './BillingSummaryTotal';
import BillingSummaryTable from './BillingSummaryTable';

const BillingSummary: React.FC = () => {
  const { data: invoices } = useGetLastMonthBillingReport();
  const orgTotalCost = useMemo(() => {
    if (invoices?.data.length) {
      return _.sumBy(invoices.data, 'TotalCost.KRW');
    }
    return 0;
  }, [invoices?.data]);
  return (
    <Box>
      <Paper sx={{ px: 7, py: 5 }}>
        {invoices?.data.length ? (
          <BillingSummaryTotal
            invoice={invoices.data[0]}
            totalCost={orgTotalCost}
          />
        ) : (
          <Typography>There is no invoice to show</Typography>
        )}
      </Paper>
      {Boolean(invoices?.data.length) && (
        <Paper sx={{ px: 7, py: 5, mt: 6 }}>
          <BillingSummaryTable
            invoices={invoices.data}
            lastUpdated={invoices.extras.LastUpdated}
          />
        </Paper>
      )}
    </Box>
  );
};

export default BillingSummary;
