import { useMutation } from 'react-query';
import { AuthService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useMutation({
    mutationKey: QUERY_KEY.AUTH.RESEND_VERIFICATION,
    mutationFn: (variables: { email: string }) =>
      AuthService.resendVerification(variables.email),
  });
