import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useRDSStorageChart } from '@hooks/api/optimization/rds';
import { StorageChart } from '@components/chart/optimization';
import { ValueFormatter } from '@utils';
import { ChartCoverBox } from '@components/styled/noData';
import {
  RIGHTSIZING_DUMMY_EXTRA,
  STORAGE_DUMMY,
} from '@components/chart/chart.dummy';

const DatabaseStorageChart: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { data } = useRDSStorageChart();
  return (
    <ChartCoverBox isEmpty={Boolean(!data?.items?.length)}>
      <StorageChart
        data={data?.items?.length ? data.items : STORAGE_DUMMY}
        xAxis={{
          dataKey: 'Date',
          interval: 0,
          tickFormatter: (v) => moment(v).format('YYYY MMM'),
        }}
        chartInfo={{
          line: { dataKey: 'GeneralPurpose', name: t('label.volume.general') },
          area: {
            dataKey: 'ProvisionedIOPS',
            name: t('label.volume.provisioned'),
          },
        }}
        extraInfo={[
          {
            label: t('label.chart.volume.totalVolume'),
            value: ValueFormatter.toLocaleString(
              data?.items?.length
                ? data?.extras?.TotalNumberOfStorages || 0
                : RIGHTSIZING_DUMMY_EXTRA.Total,
              0,
            ),
          },
          {
            label: t('label.chart.volume.totalModified'),
            value: ValueFormatter.toLocaleString(
              data?.items?.length
                ? data?.extras?.TotalNumberOfSavings || 0
                : RIGHTSIZING_DUMMY_EXTRA.Right,
              0,
            ),
          },
          {
            label: t('label.chart.volume.totalSavings'),
            value: ValueFormatter.toCurrency(
              data?.items?.length
                ? data?.extras?.TotalPossibleSavings || 0
                : RIGHTSIZING_DUMMY_EXTRA.Cost,
            ),
          },
        ]}
      />
    </ChartCoverBox>
  );
};

export default DatabaseStorageChart;
