import { useMutation } from 'react-query';
import { IntegrationService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useMutation({
    mutationKey: QUERY_KEY.SETTINGS.INTEGRATION.VERIFY_NEWRELIC,
    mutationFn: (variables: {
      APIKey: string;
      AccountId: string;
      QueryKey: string;
    }) =>
      IntegrationService.verifyNewrelic(
        variables.APIKey,
        variables.AccountId,
        variables.QueryKey,
      ),
  });
