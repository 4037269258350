import { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

export default () => {
  const [initialized, setInitialized] = useState(false);
  const initializeGTM = useCallback(() => {
    if (process.env.REACT_APP_GTM_ID) {
      TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
      setInitialized(true);
    }
  }, [process.env.REACT_APP_GTM_ID, setInitialized]);
  const sendDataLayer = useCallback(
    (event: string, category?: string, action?: string) => {
      if (!initialized) {
        initializeGTM();
      }
      TagManager.dataLayer({
        dataLayer: {
          event,
          eventCategory: category,
          eventAction: action,
        },
      });
    },
    [initialized],
  );

  useEffect(() => {
    initializeGTM();
  }, []);
  return {
    sendDataLayer,
  };
};
