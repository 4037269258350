import React from 'react';
import { Box, Paper } from '@mui/material';
import { WithCUR, WithTrial } from '@hooks/hoc';
import { SuspenseBox } from '@components/styled/suspense';
import { GrmtDateTimeRangePicker } from '@components/datetimerange';
import { AnomalySummaryChart } from './chart';
import { AnomalySummaryTable } from './table';

const AnomalySummaryPage: React.FC = () => (
  <Paper square sx={{ px: 7, py: 5 }}>
    <Box display="flex" justifyContent="end" alignItems="center" mb={3}>
      <GrmtDateTimeRangePicker />
    </Box>
    <SuspenseBox>
      <AnomalySummaryChart />
    </SuspenseBox>
    <SuspenseBox mt={10}>
      <AnomalySummaryTable />
    </SuspenseBox>
  </Paper>
);

export default WithTrial(WithCUR(AnomalySummaryPage));
