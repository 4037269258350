import { Shadows } from '@mui/material/styles/shadows';

export const darkShadows = [
  '0px 1px 2px 1px rgba(0, 0, 0, 0.5)',
  '0px 6px 10px 1px rgba(138, 139, 153, 0.4)',
  '0px 3px 4px 1px rgba(0, 0, 0, 0.5)',
  '0px 4px 6px 1px rgba(0, 0, 0, 0.5)',
  '0px 5px 8px 1px rgba(0, 0, 0, 0.5)',
  '0px 5px 8px 1px rgba(0, 0, 0, 0.5)',
];

const costClipperShadows: Shadows = [
  'none',
  '0px 1px 2px 1px rgba(138, 139, 153, 0.4)',
  '0px 2px 3px 1px rgba(138, 139, 153, 0.4)',
  '0px 3px 4px 1px rgba(138, 139, 153, 0.4)',
  '0px 4px 6px 1px rgba(138, 139, 153, 0.4)',
  '0px 5px 8px 1px rgba(138, 139, 153, 0.4)',
  '0px 6px 10px 1px rgba(138, 139, 153, 0.4)',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
];

export default costClipperShadows;
