import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import _ from 'lodash';
import { AWSCEService } from '@api/service';
import { ceUsageState } from '@atoms/aws';
import { QUERY_KEY } from '@constants';

export default function useGetUsage() {
  const filterValue = useRecoilValue(ceUsageState);
  const rawValue = useMemo(() => {
    return {
      ...filterValue,
      FromDate: moment(filterValue.FromDate).format('YYYY-MM-DDT00:00:00'),
      ToDate: moment(filterValue.ToDate).format('YYYY-MM-DDT23:59:59'),
    };
  }, [filterValue]);
  return useQuery(
    [QUERY_KEY.AWS.CE.GET_USAGE, JSON.stringify(rawValue)],
    ({ queryKey }) => AWSCEService.getUsage(JSON.parse(queryKey[1])),
    {
      suspense: true,
      useErrorBoundary: false,
      enabled: !(
        filterValue.GroupBy === 'TAG' && _.isEmpty(filterValue.TagKey?.trim())
      ),
    },
  );
}
