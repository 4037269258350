import React, { useMemo } from 'react';
import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { ColorDot } from '@components/styled';
import { ValueFormatter } from '@utils';
import { useTranslation } from 'react-i18next';
import { useAWSAccountHelper, useAWSHelper } from '@hooks/helper';

const OD_COLORS = ['#FF7976', '#FF797699', '#FF797666', '#FF797633'] as const;
const RI_COLORS = ['#57CAEB', '#57CAEB99', '#57CAEB66', '#57CAEB33'] as const;
const SP_COLORS = ['#9594FF', '#9594FF99', '#9594FF66', '#9594FF33'] as const;

export const PlannerChartDetail: React.FC<{
  type: 'OD' | 'RI' | 'SP';
  count: number;
}> = ({ type, count, children }) => (
  <Box display="flex" flexDirection="column" minHeight="100%">
    <Grid container item mb={4}>
      <Grid item xs={4}>
        <Typography color="textSecondary">Instance Family</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography color="textSecondary">
          {type === 'OD' && 'On-Demand Usage'}
          {type === 'RI' && 'RI Coverage'}
          {type === 'SP' && 'SP Coverage'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color="textSecondary">Recommendation</Typography>
      </Grid>
    </Grid>
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      justifyContent={count < 3 ? 'flex-start' : 'space-between'}
    >
      {children}
    </Box>
  </Box>
);

export const PlannerChartDetailItem: React.FC = () => (
  <Paper
    variant="elevation"
    sx={{ display: 'flex', alignItems: 'center', py: 2 }}
  >
    <Grid item xs={4}>
      <Box display="flex" alignItems="center" ml={4}>
        <ColorDot color="#333" />
        <Typography ml={1}>c5</Typography>
      </Box>
    </Grid>
    <Grid item xs={5}>
      <Typography>3,067 Hours ($601.08)</Typography>
    </Grid>
  </Paper>
);

export const PlannerChartLegend: React.FC<{
  type: 'OD' | 'RI' | 'SP';
  families: ReadonlyArray<{ label: string; coverage: number }>;
}> = ({ type, families }) => {
  const COLORS = useMemo(() => {
    switch (type) {
      case 'RI':
        return RI_COLORS;
      case 'SP':
        return SP_COLORS;
      default:
        return OD_COLORS;
    }
  }, [type]);
  return (
    <Box width={110} alignSelf="stretch" mr={5}>
      {families.map((family, idx) => (
        <Box
          key={`${type}_${family.label}_${idx.toString()}`}
          bgcolor={COLORS[idx]}
          height={`${family.coverage}%`}
          pt={2}
          pl={2}
          mt={idx !== 0 ? 1 : 0}
        >
          <Typography sx={{ color: idx < 2 ? '#fff' : '#A0A1B2' }}>
            {family.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const PlannerComparisonPaperComponent: React.FC<{
  type: 'Aggressive' | 'Passive';
  item: RIRecommend | SPRecommend;
  enableRadio?: boolean;
  selected?: 'None' | 'Aggressive' | 'Passive';
}> = ({ type, item, enableRadio, selected }) => {
  const { t } = useTranslation('planner');
  const { t: optT } = useTranslation('optimization');
  const color = useMemo<string>(() => {
    if (enableRadio) {
      return selected === type ? 'textPrimary' : 'textSecondary';
    }
    return type === 'Aggressive' ? 'textPrimary' : 'textSecondary';
  }, [type, enableRadio, selected]);
  const costColor = useMemo<string>(() => {
    if (enableRadio) {
      return selected === type ? 'primary' : 'textSecondary';
    }
    return type === 'Aggressive' ? 'primary' : 'textSecondary';
  }, [type, enableRadio, selected]);
  const purchaseType = useMemo<string>(() => {
    if (item.Type === 'ReservedInstance') {
      return item.PurchaseOption.OfferingClass === 'standard'
        ? t('RIClass.standard')
        : t('RIClass.convertible');
    }
    return `${t(`SPType.${item.PurchaseOption.PlanType}`)} Savings Plans`;
  }, [item, t]);
  return (
    <Paper variant="elevation" sx={{ px: 5, py: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {enableRadio ? (
          <FormControlLabel
            value={type}
            control={<Radio size="small" />}
            label={t(`PlanType.${type}`)}
            componentsProps={{
              typography: {
                color,
              },
            }}
          />
        ) : (
          <Typography color={color}>{t(`PlanType.${type}`)}</Typography>
        )}
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color={color}>
            {optT('label.common.savings_monthly')}
          </Typography>
          <Typography variant="h3" color={costColor} ml={2}>
            {ValueFormatter.toCurrency(item.CostSaving)}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 4 }} />
      <Typography mb={2}>
        {item?.Type === 'ReservedInstance' &&
          t('buyMore.ri', { count: item.NumberOfRI, ...item })}
        {item?.Type === 'SavingsPlan' &&
          t('buyMore.sp', {
            cost: ValueFormatter.toCurrency(item.Commitment),
          })}
      </Typography>
      <Stack direction="row" spacing={2}>
        <Chip label={purchaseType} />
        <Chip label={t(`Term.${type}`)} />
        <Chip label={t(`PaymentOption.${item.PurchaseOption.PaymentOption}`)} />
      </Stack>
    </Paper>
  );
};
PlannerComparisonPaperComponent.defaultProps = {
  enableRadio: false,
  selected: 'None',
};
export const PlannerComparisonPaper = React.memo(
  PlannerComparisonPaperComponent,
);

const PlannerRecommendationPaperComponent: React.FC<{
  type: 'None' | 'Aggressive' | 'Passive';
  item: RIRecommend | SPRecommend | undefined;
}> = ({ type, item }) => {
  const { t } = useTranslation('planner');
  const purchaseType = useMemo<string>(() => {
    if (type !== 'None' && item) {
      if (item.Type === 'ReservedInstance') {
        return item.PurchaseOption.OfferingClass === 'standard'
          ? t('RIClass.standard')
          : t('RIClass.convertible');
      }
      return `${t(`SPType.${item.PurchaseOption.PlanType}`)} Savings Plans`;
    }
    return '';
  }, [type, item, t]);
  return (
    <Paper variant="elevation" sx={{ px: 5, py: 4 }}>
      {type === 'None' && (
        <Typography color="textSecondary">
          {t('label.noRecommendation')}
        </Typography>
      )}
      {item && type !== 'None' && (
        <>
          <Typography
            color="textSecondary"
            mb={2}
            dangerouslySetInnerHTML={{
              __html:
                item.Type === 'ReservedInstance'
                  ? t('buyMore.riHtml', { count: item.NumberOfRI, ...item })
                  : t('buyMore.spHtml', {
                      cost: ValueFormatter.toCurrency(
                        item.Commitment,
                        undefined,
                        3,
                      ),
                    }),
            }}
            sx={{
              '> span': {
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontWeight: 500,
              },
            }}
          />
          <Stack direction="row" spacing={2}>
            <Chip label={purchaseType} />
            <Chip label={t(`Term.${type}`)} />
            <Chip
              label={t(`PaymentOption.${item.PurchaseOption.PaymentOption}`)}
            />
          </Stack>
        </>
      )}
    </Paper>
  );
};
export const PlannerRecommendationPaper = React.memo(
  PlannerRecommendationPaperComponent,
);

const PlannerFamilyDetailPaperComponent: React.FC<{
  AccountId: string;
  InstanceFamily: string;
  Platform: string;
  Region: string;
  Tenancy: string;
  TotalInstanceCount: number;
}> = ({
  AccountId,
  InstanceFamily,
  Platform,
  Region,
  Tenancy,
  TotalInstanceCount,
}) => {
  const { t } = useTranslation('planner');
  const { getAWSRegionName } = useAWSHelper();
  const { getAWSAccountNickname } = useAWSAccountHelper();
  return (
    <Paper variant="elevation" sx={{ px: 5, py: 4 }}>
      <Typography
        color="textSecondary"
        mb={2}
        dangerouslySetInnerHTML={{
          __html: t('label.familyDetail', {
            count: TotalInstanceCount,
            value: getAWSAccountNickname(AccountId, true),
          }),
        }}
        sx={{
          '> span': {
            fontSize: '1rem',
            lineHeight: '1.5rem',
            fontWeight: 500,
          },
        }}
      />
      <Stack direction="row" spacing={1}>
        <Chip label={`${InstanceFamily} Family`} />
        <Chip label={getAWSRegionName(Region)} />
        <Chip label={Platform} />
        <Chip label={Tenancy} />
      </Stack>
    </Paper>
  );
};
export const PlannerFamilyDetailPaper = React.memo(
  PlannerFamilyDetailPaperComponent,
);
