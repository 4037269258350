import axios from '@utils/axios';
import { API_URL } from '@constants';
import { generateHash, parseContentRange, treeShakeObject } from '@utils';

const getAnomalyList: PaginatedAxiosWithDate<
  WithContentRange<CCQueryResponse<OptimizationAnomaly>>
> = async (skip, limit, startDate, endDate, filter, sort) => {
  const filterWithDates = filter
    ? { ...JSON.parse(filter), DetectedDate: `[${startDate}/${endDate}]` }
    : `{"DetectedDate":["${startDate}/${endDate}"]}`;

  const { headers, data } = await axios.get(
    `${API_URL.OPTIMIZATION}/anomaly_detection_notification/`,
    {
      params: {
        skip,
        limit,
        filter: filterWithDates,
        sort,
      },
    },
  );
  return { headers: parseContentRange(headers), body: data };
};

const getAnomalyThresholdList: PaginatedAxios<
  WithContentRange<CCQueryResponse<OptimizationAnomalyThreshold>>
> = async (skip, limit, filter, sort) => {
  const { headers, data } = await axios.get(
    `${API_URL.OPTIMIZATION}/anomaly_detection_threshold/`,
    {
      params: {
        skip,
        limit,
        filter,
        sort,
      },
    },
  );
  return { headers: parseContentRange(headers), body: data };
};

const getAnomalyThresholdAutoComplete = async (): Promise<
  CCQueryResponse<OptimizationAnomalyThresholdAutoComplete>
> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/anomaly_detection_threshold/acdata`,
  );
  return data;
};

const getAnomalyNotificationAutoComplete = async (): Promise<
  CCQueryResponse<OptimizationAnomalyNotificationAutoComplete>
> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/anomaly_detection_notification/acdata`,
  );
  return data;
};

const deleteAnomalyThresholdNotification = async (AnomalyId: string) => {
  const { data } = await axios.delete(
    `${API_URL.OPTIMIZATION}/anomaly_detection_threshold/${AnomalyId}`,
  );
  return data;
};

const addAnomalyThresholdNotification = async (
  CompanyId: string,
  AccountId: string,
  AccountName: string,
  Duration: number,
  EmailList: AnomalyRecipient[],
  Region: string,
  Service: string,
  Threshold: number,
) => {
  const { data } = await axios.post(
    `${API_URL.OPTIMIZATION}/anomaly_detection_threshold`,
    {
      UID: generateHash(),
      CompanyId,
      AccountId,
      AccountName,
      Duration,
      EmailList,
      Region,
      Service,
      Threshold,
    },
  );
  return data;
};

const updateAnomalyThresholdNotification = async (
  original: OptimizationAnomalyThreshold,
  changes: Partial<OptimizationAnomalyThreshold>,
) => {
  const { data } = await axios.put(
    `${API_URL.OPTIMIZATION}/anomaly_detection_threshold`,
    {
      ...treeShakeObject(original),
      ...changes,
    },
  );
  return data;
};

const getAnomalyOverview = async (
  startDate: string,
  endDate: string,
): Promise<
  CCQueryResponse<
    OptimizationAnomalyOverview,
    OptimizationAnomalyOverviewExtras
  >
> => {
  const { data } = await axios.get(
    `${API_URL.OPTIMIZATION}/anomaly_detection_overview/?start_date=${startDate}&end_date=${endDate}`,
  );
  return data;
};

export default {
  getAnomalyThresholdAutoComplete,
  getAnomalyList,
  getAnomalyThresholdList,
  getAnomalyNotificationAutoComplete,
  deleteAnomalyThresholdNotification,
  addAnomalyThresholdNotification,
  updateAnomalyThresholdNotification,
  getAnomalyOverview,
};
