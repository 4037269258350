import React, { useCallback, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ValueFormatter } from '@utils';
import { useDownloadInvoice } from '@hooks/api/cmp';
import { LoadingButton } from '@mui/lab';

const BillingSummaryTotal: React.FC<{
  invoice: BillingInvoice;
  totalCost: number;
}> = ({ invoice, totalCost }) => {
  const { t } = useTranslation('report');
  const [isDownloading, setIsDownloading] = useState(false);
  const { mutateAsync } = useDownloadInvoice();
  const downloadInvoice = useCallback(async () => {
    setIsDownloading(true);
    try {
      const blob = await mutateAsync(invoice.InvoiceId);
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.id = 'cc_invoice_link';
      link.href = url;
      link.download = `Invoice${invoice.InvoiceId}.pdf`;
      const child = document.body.appendChild(link);
      link.click();
      document.body.removeChild(child);
      setIsDownloading(false);
    } catch (e) {
      setIsDownloading(false);
      console.error(e);
    }
  }, [invoice.InvoiceId, mutateAsync]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xl: 'row', xs: 'column' },
        justifyContent: { xl: 'space-between', xs: 'flex-start' },
        alignItems: { xl: 'flex-end', xs: 'flex-start' },
        gap: 5,
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 5 }}>
          <Box>
            <Typography color="textSecondary" mb={1}>
              {t('text.invoice_total_cost')}
            </Typography>
            <Typography variant="h2" color="primary">
              KRW {ValueFormatter.toLocaleString(totalCost, 0)}
            </Typography>
          </Box>
          <LoadingButton
            variant="contained"
            size="large"
            loading={isDownloading}
            onClick={downloadInvoice}
          >
            {t('button.download_invoice')}
          </LoadingButton>
        </Box>
      </Box>
      <Box>
        <Grid container columnSpacing={7}>
          <Grid item>
            <Typography variant="body2" color="textSecondary" mb={1}>
              {t('text.invoice_no')}
            </Typography>
            <Typography>{invoice.InvoiceId}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary" mb={1}>
              {t('text.invoiced_date')}
            </Typography>
            <Typography>
              {ValueFormatter.toDateString(invoice.InvoicedDate, 'YYYY/MM/DD')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary" mb={1}>
              {t('text.due_date')}
            </Typography>
            <Typography>
              {ValueFormatter.toDateString(invoice.PaymentDate, 'YYYY/MM/DD')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary" mb={1}>
              {t('text.billing_month')}
            </Typography>
            <Typography>
              {ValueFormatter.toDateString(invoice.Date, 'YYYY MMM')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary" mb={1}>
              {t('text.usage_period')}
            </Typography>
            <Typography>
              {`${ValueFormatter.toDateString(
                moment(invoice.Date).startOf('month').toDate(),
                'YYYY.MM.DD',
              )} ~ ${ValueFormatter.toDateString(
                moment(invoice.Date).endOf('month').toDate(),
                'YYYY.MM.DD',
              )}`}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BillingSummaryTotal;
