import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useResetPassword } from '@hooks/api/auth';
import { ROUTES } from '@routes';
import { isAxiosError } from '@utils';
import { useSnackbar } from '@hooks/common';
import { useResetPasswordForm } from './ResetPasswordPage.form';
import { PureAuthCompleted, PureAuthContent } from '../AuthPage.styled';

const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation('auth');
  const { t: formT } = useTranslation('form');
  const { t: toastT } = useTranslation('toast');
  const { showSuccessSnackbar, showErrorSnackbar, showInfoSnackbar } =
    useSnackbar();
  const navigate = useNavigate();
  const { mutateAsync, isSuccess } = useResetPassword();
  const { initialValues, validationSchema } = useResetPasswordForm();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: async (v) => {
      try {
        await mutateAsync(v);
        showSuccessSnackbar(toastT('success.auth.reset'));
        navigate(ROUTES.AUTH.SIGNIN);
      } catch (e) {
        if (isAxiosError(e) && e.response?.status && e.response?.data?.msg) {
          switch (e.response.data.msg) {
            case 'User not found':
              setFieldError('email', formT('error.email.notFound'));
              showInfoSnackbar(toastT('info.auth.reset'));
              return;
            default:
              showErrorSnackbar(toastT('error.general'));
          }
        }
      }
    },
  });
  return (
    <>
      {isSuccess ? (
        <PureAuthCompleted
          title={t('cta.resetPw.completeTitle')}
          description={t('cta.resetPw.completeDesc')}
          label={t('cta.resetPw.completeButton')}
          link={ROUTES.AUTH.SIGNIN}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <PureAuthContent
            title={t('cta.resetPw.title')}
            subtitle={t('cta.resetPw.subtitle')}
            description={t('cta.resetPw.desc')}
          >
            <TextField
              name="email"
              type="email"
              inputMode="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              label={formT('holder.email')}
              autoComplete="username"
              autoCapitalize="off"
              error={Boolean(errors.email && touched.email)}
              helperText={
                Boolean(errors.email && touched.email) && String(errors.email)
              }
              fullWidth
            />
            <Button type="submit" size="large" fullWidth sx={{ mt: 7 }}>
              {t('cta.resetPw.button')}
            </Button>
            <Box display="flex" justifyContent="center" mt={3}>
              <Typography variant="caption">{t('cta.signUp.title')}</Typography>
              <Typography
                variant="caption"
                color="primary"
                onClick={() => navigate(ROUTES.AUTH.SIGNUP)}
                sx={{ cursor: 'pointer', ml: 2 }}
              >
                {t('cta.signUp.label')}
              </Typography>
            </Box>
          </PureAuthContent>
        </form>
      )}
    </>
  );
};

export default ResetPasswordPage;
