import React, {
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { css, CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import moment from 'moment';
import 'moment/locale/ko';
import { RecoilRoot } from 'recoil';
import { Helmet } from 'react-helmet';
import Pages from '@pages';
import theme from '@theme';
import { Splash } from '@components/common';
import i18n from '@utils/i18n';

const queryClient = new QueryClient();

const App: React.FC = () => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);
  const languageListener = useCallback((lng: string) => {
    moment.locale(lng);
  }, []);
  useEffect(() => {
    i18n.on('languageChanged', languageListener);
    return () => i18n.off('languageChanged', languageListener);
  }, []);
  return (
    <>
      <Helmet defaultTitle="CostClipper" titleTemplate="%s - CostClipper" />
      <Suspense fallback={<Splash />}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <GlobalStyles
              styles={css`
                .cc-snackbar-root {
                  z-index: 1299;
                }
                .cc-snackbar-top-right {
                  top: 64px !important;
                }
              `}
            />
            <CssBaseline />
            <SnackbarProvider
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              maxSnack={5}
              classes={{
                containerRoot: 'cc-snackbar-root',
                containerAnchorOriginTopRight: 'cc-snackbar-top-right',
              }}
            >
              <RecoilRoot>
                <Pages />
              </RecoilRoot>
            </SnackbarProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </Suspense>
    </>
  );
};

export default App;
