import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useRDSDetailsUtilization } from '@hooks/api/optimization/rds';
import { useToggle } from '@hooks/common';
import { detailsRDSDateState } from '@atoms/details';
import { MonthlyInstanceUtilChart } from '@components/chart/optimization';
import { NoAvailableData } from '@components/styled/noData';
import { bluegrey } from '@theme/colors';
import { filterPositive, ValueFormatter } from '@utils';
import DetailsDatabaseDailyModal from './DetailsDatabaseDailyModal';

const DetailsDatabaseUtilChart: React.FC = () => {
  const { t } = useTranslation('optimization');
  const setSelectedDate = useSetRecoilState(detailsRDSDateState);
  const [openPeriodic, togglePeriodic] = useToggle();
  const [chartData, setChartData] = useState<
    Array<{ Timestamps: Date; CPU: number; Mem: number }>
  >([]);
  const { data } = useRDSDetailsUtilization();
  const cpuUtilMax = useMemo<number>(() => {
    if (data?.items?.length && data.items[0].CPUUtilization?.length) {
      return (
        _.maxBy(data.items[0].CPUUtilization, 'Maximum')?.Maximum || undefined
      );
    }
    return undefined;
  }, [data?.items]);
  const cpuUtilMean = useMemo(() => {
    if (data?.items?.length && data.items[0].CPUUtilization?.length) {
      return _.meanBy(data.items[0].CPUUtilization, (v) => Number(v.Maximum));
    }
    return undefined;
  }, [data?.items]);
  const memUtilMax = useMemo<number>(() => {
    if (data?.items?.length && data.items[0].MemoryUtilization?.length) {
      return (
        _.maxBy(data.items[0].MemoryUtilization, 'Maximum')?.Maximum ||
        undefined
      );
    }
    return undefined;
  }, [data?.items]);
  const memUtilMean = useMemo(() => {
    if (data?.items?.length && data.items[0].MemoryUtilization?.length) {
      return _.meanBy(data.items[0].MemoryUtilization, (v) =>
        Number(v.Maximum),
      );
    }
    return undefined;
  }, [data?.items]);
  useEffect(() => {
    if (data?.items?.length) {
      const keys = _.union(
        data.items[0]?.CPUUtilization?.map((item) =>
          moment(item.Date).format('YYYY-MM-DD'),
        ),
        data.items[0]?.MemoryUtilization?.map((item) =>
          moment(item.Date).format('YYYY-MM-DD'),
        ),
      );
      keys.sort();
      setChartData(
        keys.map((date) => {
          const cpu = data.items[0]?.CPUUtilization?.find((v) =>
            moment(v.Date).isSame(moment(date), 'date'),
          );
          const mem = data.items[0]?.MemoryUtilization?.find((v) =>
            moment(v.Date).isSame(moment(date), 'date'),
          );
          return {
            Timestamps: moment(date).toDate(),
            CPU: filterPositive(cpu?.Maximum),
            Mem: filterPositive(mem?.Maximum),
          };
        }),
      );
    }
  }, [data?.items]);
  if (!data?.items?.length) {
    return <NoAvailableData />;
  }
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        columns={20}
      >
        <Grid item md={15} xs={20}>
          <MonthlyInstanceUtilChart
            data={chartData}
            onDotClick={(v) => {
              if (v) {
                setSelectedDate(moment(v).format('YYYY-MM-DD'));
                togglePeriodic();
              }
            }}
          />
        </Grid>
        <Grid
          item
          display={{ md: 'block', xs: 'flex' }}
          justifyContent="space-between"
          md={3}
          xs={20}
          mt={{ md: 0, xs: 4 }}
          mr={{ md: 4, xs: 0 }}
        >
          <Box>
            <Typography
              variant="body2"
              borderLeft={2}
              borderColor={bluegrey[700]}
              pl={2}
              mb={2}
            >
              {t('label.util.cpu_long')}
            </Typography>
            <Box borderLeft={1} borderColor={bluegrey[700]} ml={2} pl={2}>
              <Stack spacing={1}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="caption" color="textSecondary">
                    {t('label.util.max_short')}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {ValueFormatter.toPercent(cpuUtilMax) || '-'}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="caption" color="textSecondary">
                    {t('label.util.avg_short')}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {ValueFormatter.toPercent(cpuUtilMean) || '-'}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
          <Box mt={{ md: 5, xs: 0 }}>
            <Typography
              variant="body2"
              borderLeft={2}
              borderColor={bluegrey[700]}
              pl={2}
              mb={2}
            >
              {t('label.util.mem_long')}
            </Typography>
            <Box borderLeft={1} borderColor={bluegrey[700]} ml={2} pl={2}>
              <Stack spacing={1}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="caption" color="textSecondary">
                    {t('label.util.max_short')}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {ValueFormatter.toPercent(memUtilMax) || '-'}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="caption" color="textSecondary">
                    {t('label.util.avg_short')}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {ValueFormatter.toPercent(memUtilMean) || '-'}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {openPeriodic && (
        <DetailsDatabaseDailyModal
          open={openPeriodic}
          onClose={togglePeriodic}
        />
      )}
    </>
  );
};

export default DetailsDatabaseUtilChart;
