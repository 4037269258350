import moment from 'moment';

export const RIGHTSIZING_DUMMY = [
  {
    CPUAverage: 5,
    MemAverage: 15,
    CPUMax: 12,
    MemMax: 47,
  },
  {
    CPUAverage: 8,
    MemAverage: 29,
    CPUMax: 47,
    MemMax: 32,
  },
  {
    CPUAverage: 15,
    MemAverage: 37,
    CPUMax: 26,
    MemMax: 39,
  },
  {
    CPUAverage: 20,
    MemAverage: 47,
    CPUMax: 46,
    MemMax: 53,
  },
  {
    CPUAverage: 16,
    MemAverage: 34,
    CPUMax: 58,
    MemMax: 67,
  },
];

export const RDS_RIGHTSIZING_DUMMY = [
  {
    CPUAverage: [{ cloudwatch: 5 }],
    MemAverage: [{ cloudwatch: 15 }],
    CPUMax: [{ cloudwatch: 12 }],
    MemMax: [{ cloudwatch: 47 }],
  },
  {
    CPUAverage: [{ cloudwatch: 8 }],
    MemAverage: [{ cloudwatch: 29 }],
    CPUMax: [{ cloudwatch: 47 }],
    MemMax: [{ cloudwatch: 32 }],
  },
  {
    CPUAverage: [{ cloudwatch: 15 }],
    MemAverage: [{ cloudwatch: 37 }],
    CPUMax: [{ cloudwatch: 26 }],
    MemMax: [{ cloudwatch: 39 }],
  },
  {
    CPUAverage: [{ cloudwatch: 20 }],
    MemAverage: [{ cloudwatch: 47 }],
    CPUMax: [{ cloudwatch: 46 }],
    MemMax: [{ cloudwatch: 53 }],
  },
  {
    CPUAverage: [{ cloudwatch: 16 }],
    MemAverage: [{ cloudwatch: 34 }],
    CPUMax: [{ cloudwatch: 58 }],
    MemMax: [{ cloudwatch: 67 }],
  },
];

export const RIGHTSIZING_DUMMY_EXTRA = {
  Total: 5,
  Right: 1,
  Cost: 231,
};

export const COVERAGE_DUMMY = [
  {
    Date: moment().subtract(5, 'months').toDate(),
    Ondemand: 270,
    Reserved: 0,
    SavingsPlan: 0,
  },
  {
    Date: moment().subtract(4, 'months').toDate(),
    Ondemand: 300,
    Reserved: 0,
    SavingsPlan: 0,
  },
  {
    Date: moment().subtract(3, 'months').toDate(),
    Ondemand: 250,
    Reserved: 70,
    SavingsPlan: 0,
  },
  {
    Date: moment().subtract(2, 'months').toDate(),
    Ondemand: 150,
    Reserved: 100,
    SavingsPlan: 50,
  },
  {
    Date: moment().subtract(1, 'months').toDate(),
    Ondemand: 130,
    Reserved: 100,
    SavingsPlan: 50,
  },
  {
    Date: moment().toDate(),
    Ondemand: 30,
    Reserved: 200,
    SavingsPlan: 80,
  },
];

export const UTILIZATION_DUMMY = [
  {
    Date: moment().subtract(5, 'months').toDate(),
    Reserved: 0,
    ReservedPurchase: 0,
    ReservedUtilization: 0,
    SavingsPlan: 0,
    SavingsPlanUtilization: 0,
  },
  {
    Date: moment().subtract(4, 'months').toDate(),
    Reserved: 0,
    ReservedPurchase: 0,
    ReservedUtilization: 0,
    SavingsPlan: 0,
    SavingsPlanUtilization: 0,
  },
  {
    Date: moment().subtract(3, 'months').toDate(),
    Reserved: 70,
    ReservedPurchase: 70,
    ReservedUtilization: 100,
    SavingsPlan: 0,
    SavingsPlanUtilization: 0,
  },
  {
    Date: moment().subtract(2, 'months').toDate(),
    Reserved: 100,
    ReservedPurchase: 100,
    ReservedUtilization: 100,
    SavingsPlan: 50,
    SavingsPlanUtilization: 100,
  },
  {
    Date: moment().subtract(1, 'months').toDate(),
    Reserved: 100,
    ReservedPurchase: 100,
    ReservedUtilization: 100,
    SavingsPlan: 50,
    SavingsPlanUtilization: 100,
  },
  {
    Date: moment().toDate(),
    Reserved: 200,
    ReservedPurchase: 200,
    ReservedUtilization: 100,
    SavingsPlan: 80,
    SavingsPlanUtilization: 100,
  },
];

export const STORAGE_DUMMY = [
  {
    Date: moment().subtract(5, 'months').toDate(),
    GeneralPurpose: 30,
    ProvisionedIOPS: 19,
    StorageCost: 30,
    TransferCost: 19,
    Storage: 30,
    'Data Transfer': 19,
    Cost: 30,
  },
  {
    Date: moment().subtract(4, 'months').toDate(),
    GeneralPurpose: 25,
    ProvisionedIOPS: 21,
    StorageCost: 25,
    TransferCost: 21,
    Storage: 25,
    'Data Transfer': 21,
    Cost: 21,
  },
  {
    Date: moment().subtract(3, 'months').toDate(),
    GeneralPurpose: 27,
    ProvisionedIOPS: 15,
    StorageCost: 27,
    TransferCost: 15,
    Storage: 27,
    'Data Transfer': 15,
    Cost: 27,
  },
  {
    Date: moment().subtract(2, 'months').toDate(),
    GeneralPurpose: 29,
    ProvisionedIOPS: 13,
    StorageCost: 29,
    TransferCost: 13,
    Storage: 29,
    'Data Transfer': 13,
    Cost: 29,
  },
  {
    Date: moment().subtract(1, 'months').toDate(),
    GeneralPurpose: 23,
    ProvisionedIOPS: 16,
    StorageCost: 23,
    TransferCost: 16,
    Storage: 23,
    'Data Transfer': 16,
    Cost: 23,
  },
  {
    Date: moment().toDate(),
    GeneralPurpose: 15,
    ProvisionedIOPS: 9,
    StorageCost: 15,
    TransferCost: 9,
    Storage: 15,
    'Data Transfer': 9,
    Cost: 15,
  },
];

export const STORAGE_DUMMY_EXTRA = {
  Storage: 118,
  Transfer: 94,
  Total: 212,
};

export const VOLUME_DUMMY = [
  {
    Date: moment().subtract(5, 'months').toDate(),
    gp3: 35,
    gp2: 10,
    io2: 15,
  },
  {
    Date: moment().subtract(4, 'months').toDate(),
    gp3: 35,
    gp2: 10,
    io2: 15,
  },
  {
    Date: moment().subtract(3, 'months').toDate(),
    gp3: 37,
    gp2: 17,
    io2: 5,
  },
  {
    Date: moment().subtract(2, 'months').toDate(),
    gp3: 43,
    gp2: 11,
    io2: 0,
  },
  {
    Date: moment().subtract(1, 'months').toDate(),
    gp3: 46,
    gp2: 7,
    io2: 0,
  },
  {
    Date: moment().toDate(),
    gp3: 48,
    gp2: 4,
    io2: 0,
  },
];
