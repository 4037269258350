import React from 'react';
import { Paper } from '@mui/material';
import { SuspenseBox } from '@components/styled/suspense';
import { DatabaseStorageChart } from './chart';
import { DatabaseStorageTable } from './table';

const DatabaseStoragePage: React.FC = () => (
  <Paper sx={{ px: 7, py: 5 }}>
    <SuspenseBox>
      <DatabaseStorageChart />
    </SuspenseBox>
    <SuspenseBox mt={12}>
      <DatabaseStorageTable />
    </SuspenseBox>
  </Paper>
);

export default DatabaseStoragePage;
