import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { SuspenseBox } from '@components/styled/suspense';
import { DialogModalProps } from '@components/modal';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { ceUsageState } from '@atoms/aws';
import { useGetUsageAutoComplete } from '@hooks/api/aws/ce';
import _ from 'lodash';
import { DEFAULT_FILTER_STATE } from '@constants';
import { FieldLabel } from '@pages/private/report/usage/root/components/styled';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import UsageAutocomplete from './UsageAutoComplete';
import UsageDatePicker from './UsageDatePicker';
import UsageLinkedCompaniesAutocomplete from './UsageLinkedCompaniesAutocomplete';
import TagFilter from './TagFilter';

type ReportFilterModalBodyProps = {
  onClose: {
    bivarianceHack(
      event?: any,
      reason?: 'backdropClick' | 'escapeKeyDown',
    ): void;
  }['bivarianceHack'];
};
function ReportFilterModalBody({ onClose }: ReportFilterModalBodyProps) {
  const { t } = useTranslation('usage');
  const { t: commonT } = useTranslation('common');
  const [filter, setFilter] = useRecoilState(ceUsageState);
  const [filterRaw, setFilterRaw] = useState(filter);
  const { data: response } = useGetUsageAutoComplete();
  const handleGroupByChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterRaw((v) => ({
        ...v,
        GroupBy: e.target.value as CEGroupBy,
        ...(e.target.value !== 'TAG' && {
          TagKey: ' ',
        }),
      }));
    },
    [setFilterRaw],
  );
  const handleTagKeyChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterRaw((v) => ({
        ...v,
        TagKey: e.target.value,
      }));
    },
    [setFilterRaw],
  );
  const handleGranularityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterRaw((v) => ({
        ...v,
        Granuality: e.target.value as CEGranularity,
      }));
    },
    [setFilterRaw],
  );
  const resetFilter = useCallback(() => {
    setFilterRaw(DEFAULT_FILTER_STATE);
  }, []);
  const applyChange = useCallback(() => {
    setFilter(filterRaw);
    onClose();
  }, [onClose, filterRaw, setFilter]);
  return (
    <>
      <DialogContent>
        <Grid container rowSpacing="24px" columnSpacing="16px">
          <UsageDatePicker filter={filterRaw} setFilter={setFilterRaw} />
          <Grid
            container
            item
            rowSpacing="24px"
            columnSpacing="16px"
            lg={6}
            xs={12}
          >
            <Grid item xs={6}>
              <UsageAutocomplete
                basis="LINKED_ACCOUNT"
                filter={filterRaw}
                setFilter={setFilterRaw}
              />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <UsageLinkedCompaniesAutocomplete
                filter={filterRaw}
                setFilter={setFilterRaw}
              />
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12}>
            <FieldLabel>{t('filter.granularity')}</FieldLabel>
            <TextField
              value={filterRaw.Granuality}
              onChange={handleGranularityChange}
              fullWidth
              select
              sx={{
                [`> .${outlinedInputClasses.root} > svg`]: {
                  top: 'calc(50% - 10px)',
                  width: '20px',
                  height: '20px',
                  color: '#a0a1b2',
                },
              }}
            >
              <MenuItem value="MONTHLY">{t('filter.monthly')}</MenuItem>
              <MenuItem value="DAILY">{t('filter.daily')}</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={filterRaw.GroupBy === 'TAG' ? 3 : 6} xs={12}>
            <FieldLabel>{t('filter.group_by')}</FieldLabel>
            <TextField
              value={filterRaw.GroupBy}
              onChange={handleGroupByChange}
              fullWidth
              select
              sx={{
                [`> .${outlinedInputClasses.root} > svg`]: {
                  top: 'calc(50% - 10px)',
                  width: '20px',
                  height: '20px',
                  color: '#a0a1b2',
                },
              }}
            >
              <MenuItem value="Cost">{t('filter.by_none')}</MenuItem>
              <MenuItem value="SERVICE">{t('filter.by_service')}</MenuItem>
              {Boolean(_.get(response, 'items.0.NumberOfTagKeys')) && (
                <MenuItem value="TAG">{t('filter.by_tag')}</MenuItem>
              )}
              {Boolean(_.get(response, 'items.0.NumberOfAccounts')) && (
                <MenuItem value="LINKED_ACCOUNT">
                  {t('filter.by_linked_account')}
                </MenuItem>
              )}
              <MenuItem value="PURCHASE_TYPE">
                {t('filter.by_purchase_type')}
              </MenuItem>
            </TextField>
          </Grid>
          {filterRaw.GroupBy === 'TAG' && (
            <Grid item lg={3} xs={12}>
              <FieldLabel>{t('filter.tag_key')}</FieldLabel>
              <TextField
                value={filterRaw.TagKey}
                onChange={handleTagKeyChange}
                fullWidth
                select
                sx={{
                  [`> .${outlinedInputClasses.root} > svg`]: {
                    top: 'calc(50% - 10px)',
                    width: '20px',
                    height: '20px',
                    color: '#a0a1b2',
                  },
                }}
              >
                <MenuItem value=" " disabled>
                  {!_.isEmpty(_.get(response, 'items.0.TagKeys'))
                    ? '태그 키 선택'
                    : '태그 키 없음'}
                </MenuItem>
                {_.get(response, 'items.0.TagKeys')?.map((v: string) => (
                  <MenuItem key={`tag_key_menu_${v}`} value={v}>
                    {v}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item lg={6} xs={12}>
            <UsageAutocomplete
              basis="SERVICE"
              filter={filterRaw}
              setFilter={setFilterRaw}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <UsageAutocomplete
              basis="INSTANCE_TYPE"
              filter={filterRaw}
              setFilter={setFilterRaw}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <UsageAutocomplete
              basis="USAGE_TYPE"
              filter={filterRaw}
              setFilter={setFilterRaw}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <UsageAutocomplete
              basis="USAGE_TYPE_GROUP"
              filter={filterRaw}
              setFilter={setFilterRaw}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TagFilter filter={filterRaw} setFilter={setFilterRaw} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          type="button"
          variant="text"
          color="tertiary"
          size="large"
          onClick={resetFilter}
        >
          {t('filter.reset_filter')}
        </Button>
        <Box>
          <Button type="button" color="inherit" size="large" onClick={onClose}>
            {commonT('button.cancel')}
          </Button>
          <Button
            type="button"
            size="large"
            onClick={applyChange}
            disabled={
              filterRaw.GroupBy === 'TAG' && _.isEmpty(filterRaw.TagKey?.trim())
            }
            sx={{ ml: '16px' }}
          >
            {t('filter.apply_filter')}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}

export default function ReportFilterModal({ open, onClose }: DialogModalProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      scroll="body"
      disablePortal
      fullWidth
    >
      <SuspenseBox>
        <ReportFilterModalBody onClose={onClose} />
      </SuspenseBox>
    </Dialog>
  );
}
