/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState } from 'react';
import { Box, Grid, Paper, PaperProps, Typography } from '@mui/material';
import { Timeline } from '@mui/lab';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useNotifications } from '@hooks/api/overview';
import { actionRequiredState, generalAlertState } from '@atoms/practice';
import { NotificationTimelineItem } from '@components/styled/notification';
import { bluegrey, primary } from '@theme/colors';
import { ValueFormatter } from '@utils';
import { NoAvailableData } from '@components/styled/noData';

type NotificationType = 'ActionRequired' | 'GeneralAlert';

const NotificationSelector: React.FC<
  {
    type: NotificationType;
    count?: number;
    selected?: boolean;
  } & Pick<PaperProps, 'onClick'>
> = ({ type, count, selected, onClick }) => {
  const { t } = useTranslation('common');
  return (
    <Paper
      onClick={count ? onClick : undefined}
      sx={{
        width: 360,
        cursor: count ? 'pointer' : 'default',
        borderColor: selected ? '#d8c0f7' : bluegrey[300],
        backgroundColor: selected ? primary[50] : undefined,
        px: 3,
        py: 2,
        '&:hover': {
          backgroundColor: count
            ? selected
              ? primary[100]
              : bluegrey[100]
            : undefined,
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="body2"
          color={selected ? 'textPrimary' : 'textSecondary'}
        >
          {type === 'ActionRequired'
            ? t('label.actionRequired')
            : t('label.generalAlerts')}
        </Typography>
        <Typography
          variant="caption"
          fontWeight={700}
          color={selected ? 'primary' : 'textSecondary'}
        >
          {ValueFormatter.toLocaleString(count, 0) || '-'}
        </Typography>
      </Box>
    </Paper>
  );
};
NotificationSelector.defaultProps = {
  count: 0,
  selected: false,
};

const NotificationTimeline: React.FC = () => {
  const { t } = useTranslation('common');
  const [selected, setSelected] = useState<Array<NotificationType>>([]);
  const [actionRequired] = useRecoilState(actionRequiredState);
  const [generalAlerts] = useRecoilState(generalAlertState);
  const { data } = useNotifications();
  const notifications = useMemo<Array<NotificationItem>>(() => {
    const items: Array<NotificationItem> = [];
    if (data?.items?.length) {
      if (
        data.items[0].ActionRequired &&
        data.items[0].ActionRequired.length &&
        (!selected.length || selected.includes('ActionRequired'))
      ) {
        items.push(...data.items[0].ActionRequired);
      }
      if (
        data.items[0].GeneralAlert &&
        data.items[0].GeneralAlert.length &&
        (!selected.length || selected.includes('GeneralAlert'))
      ) {
        items.push(...data.items[0].GeneralAlert);
      }
    }
    return _.orderBy(items, 'Created', 'desc');
  }, [data?.items, selected]);
  const handleChange = useCallback(
    (type: NotificationType) => () => {
      const set = new Set(selected);
      if (set.has(type)) {
        set.delete(type);
      } else {
        set.add(type);
      }
      setSelected(Array.from(set));
    },
    [selected],
  );
  return (
    <>
      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid item>
          <NotificationSelector
            type="ActionRequired"
            count={actionRequired}
            onClick={handleChange('ActionRequired')}
            selected={selected.includes('ActionRequired')}
          />
        </Grid>
        <Grid item>
          <NotificationSelector
            type="GeneralAlert"
            count={generalAlerts}
            onClick={handleChange('GeneralAlert')}
            selected={selected.includes('GeneralAlert')}
          />
        </Grid>
      </Grid>
      <Box mt={10} maxWidth="md">
        {notifications.length ? (
          <Timeline>
            {notifications.map((item) => (
              <NotificationTimelineItem key={item.UID} item={item} />
            ))}
          </Timeline>
        ) : (
          <NoAvailableData text={t('label.noNotification')} />
        )}
      </Box>
    </>
  );
};

export default NotificationTimeline;
