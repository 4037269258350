import React, { useEffect } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGetCompany, useUpdateCompany } from '@hooks/api/admin/company';
import { CCModalProps, DialogModal } from '@components/modal';
import { useSnackbar } from '@hooks/common';

type UpdateCompanyForm = {
  name: string;
  email: string;
};

const useUpdateCompanyModal = (): UseValidation<UpdateCompanyForm> => {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().defined(t('common.required')),
      email: Yup.string().trim().email().defined(t('common.required')),
    }).defined(),
  };
};

const UpdateCompanyModal: React.FC<CCModalProps & { companyId: string }> = ({
  open,
  onClose,
  companyId,
}) => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { data } = useGetCompany(companyId);
  const { mutateAsync } = useUpdateCompany();
  const { initialValues, validationSchema } = useUpdateCompanyModal();
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      if (data?.items?.length) {
        try {
          await mutateAsync({ original: data.items[0], changes: v });
          showSuccessSnackbar('Company has been updated');
          onClose();
        } catch (e) {
          showErrorSnackbar('Error occurred while update company');
        }
      }
    },
  });
  useEffect(() => {
    if (data?.items?.length) {
      setFieldValue('name', data.items[0].name);
      setFieldValue('email', data.items[0].email);
    }
  }, [data?.items]);
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="sm"
      HeaderProps={{
        icon: 'Admin',
        label: 'Edit Company',
      }}
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: 'Cancel',
        },
        {
          type: 'submit',
          label: 'Update',
          disabled: !isValid,
        },
      ]}
    >
      <Typography variant="subtitle2" color="textSecondary">
        Company Information
      </Typography>
      <Box mt={3}>
        <TextField
          name="name"
          type="text"
          inputMode="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          label="Company name"
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          error={Boolean(touched.name && errors.name)}
          helperText={
            Boolean(touched.name && errors.name) && String(errors.name)
          }
          fullWidth
        />
      </Box>
      <Box mt={3}>
        <TextField
          name="email"
          type="email"
          inputMode="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          label="Company email"
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          error={Boolean(touched.email && errors.email)}
          helperText={
            Boolean(touched.email && errors.email) && String(errors.email)
          }
          fullWidth
        />
      </Box>
    </DialogModal>
  );
};

export default UpdateCompanyModal;
