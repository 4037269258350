import { useMutation, useQueryClient } from 'react-query';
import { PaymentService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.SETTINGS.PAYMENT.CREATE_METHOD,
    mutationFn: (variables: { CardToken: string }) =>
      PaymentService.createPaymentMethod(variables.CardToken),
    onSuccess: () =>
      queryClient.invalidateQueries(QUERY_KEY.SETTINGS.PAYMENT.GET_METHOD),
  });
};
