import { useQuery } from 'react-query';
import { AWSAccountService } from '@api/service';
import { QUERY_KEY } from '@constants';
import { useRecoilValue } from 'recoil';
import { externalTokenState } from '@pages/private/onboard/external/ExternalAccount.atoms';

export default () => {
  const token = useRecoilValue(externalTokenState);
  const { data } = useQuery(QUERY_KEY.ONBOARD.EXTERNAL_ID, () =>
    AWSAccountService.getExternalId(token),
  );
  return {
    externalId:
      data?.items && data.items.length ? data.items[0].ExternalId : undefined,
  };
};
