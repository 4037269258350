import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCreateCompany } from '@hooks/api/admin/company';
import { CCModalProps, DialogModal } from '@components/modal';
import { useSnackbar } from '@hooks/common';

type CreateCompanyForm = {
  name: string;
  email: string;
};

const useCreateCompanyForm = (): UseValidation<CreateCompanyForm> => {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().defined(t('common.required')),
      email: Yup.string().trim().email().defined(t('common.required')),
    }).defined(),
  };
};

const CreateCompanyModal: React.FC<CCModalProps> = ({ open, onClose }) => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutateAsync } = useCreateCompany();
  const { initialValues, validationSchema } = useCreateCompanyForm();
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        await mutateAsync(v);
        showSuccessSnackbar('Company has been created');
        onClose();
      } catch (e) {
        showErrorSnackbar('Error occurred while creating company');
      }
    },
  });
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="sm"
      HeaderProps={{
        icon: 'Admin',
        label: 'Add Company',
      }}
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: 'Cancel',
        },
        {
          type: 'submit',
          label: 'Create',
          disabled: !isValid,
        },
      ]}
    >
      <Typography variant="subtitle2" color="textSecondary">
        Company Information
      </Typography>
      <Box mt={3}>
        <TextField
          name="name"
          type="text"
          inputMode="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          label="Company name"
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          error={Boolean(touched.name && errors.name)}
          helperText={
            Boolean(touched.name && errors.name) && String(errors.name)
          }
          fullWidth
        />
      </Box>
      <Box mt={3}>
        <TextField
          name="email"
          type="email"
          inputMode="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          label="Company email"
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          error={Boolean(touched.email && errors.email)}
          helperText={
            Boolean(touched.email && errors.email) && String(errors.email)
          }
          fullWidth
        />
      </Box>
    </DialogModal>
  );
};

export default CreateCompanyModal;
