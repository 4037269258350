import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export type VerifyARNForm = {
  arn: string;
  name: string;
};

export const useValidation = (): UseValidation<VerifyARNForm> => {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      arn: '',
      name: '',
    },
    validationSchema: Yup.object({
      arn: Yup.string()
        .trim()
        .matches(
          /^arn:aws:iam::\d{12}:role\/[A-Za-z0-9+=,.@\-_]+$/,
          t('onboard.arn.format'),
        )
        .defined(t('common.required')),
      name: Yup.string().trim().defined(t('common.required')),
    }).defined(),
  };
};
