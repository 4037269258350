import { useQuery } from 'react-query';
import { CouponService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useQuery(
    QUERY_KEY.ADMIN.COUPON.GET_COUPONS,
    () => CouponService.getCoupons(),
    { suspense: true, useErrorBoundary: true },
  );
