import { useMutation, useQueryClient } from 'react-query';
import { AWSAccountService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.SETTINGS.ACCOUNT.RELEASE,
    mutationFn: (variables: {
      UserUID: string;
      AWSAccountID: string;
      ReadOnly: boolean;
    }) =>
      AWSAccountService.releaseAWSAccount(
        variables.UserUID,
        variables.AWSAccountID,
        variables.ReadOnly,
      ),
    onSuccess: () =>
      queryClient.invalidateQueries(QUERY_KEY.SETTINGS.ACCOUNT.DETAIL),
  });
};
