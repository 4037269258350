import { useMutation } from 'react-query';
import { QUERY_KEY } from '@constants';
import { AWSAccountService } from '@api/service';
import { useRecoilValue } from 'recoil';
import { externalTokenState } from '@pages/private/onboard/external/ExternalAccount.atoms';

export default () => {
  const token = useRecoilValue(externalTokenState);
  return useMutation({
    mutationKey: QUERY_KEY.ONBOARD.CREATE_CUR,
    mutationFn: (variables: {
      reportName: string;
      bucketName: string;
      region: string;
      uid: string;
    }) =>
      AWSAccountService.createCUR(
        variables.reportName,
        variables.bucketName,
        variables.region,
        variables.uid,
        token,
      ),
  });
};
