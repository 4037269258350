import React, { useCallback } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  useSimulationGeneral,
  useSimulationRecommendation,
} from '@hooks/api/planner';
import { useParams } from 'react-router-dom';
import {
  PlannerFamilyDetailPaper,
  PlannerRecommendationPaper,
} from '@pages/private/planner/Planner.styled';
import { useTranslation } from 'react-i18next';
import { SimulationHeader } from '../Simulation.styled';
import {
  selectedRecommendState,
  simulateRequestState,
  simulateResponseState,
  simulationStepState,
} from '../Simulation.atoms';
import { SimulationCalculator, SimulationGuide } from '../components';

const PreviewStep: React.FC = () => {
  const { instanceId } = useParams();
  const { t } = useTranslation('planner');
  const setActive = useSetRecoilState(simulationStepState);

  const handlePrev = useCallback(() => {
    setActive((v) => v - 1);
  }, [setActive]);

  const handleNext = useCallback(() => {
    setActive((v) => v + 1);
  }, [setActive]);
  const option = useRecoilValue(selectedRecommendState);
  const { data } = useSimulationGeneral(instanceId);
  const { data: recommend } = useSimulationRecommendation(instanceId);
  const request = useRecoilValue(simulateRequestState);
  const response = useRecoilValue(simulateResponseState);

  if (!data?.items?.length || !recommend?.items?.length) {
    return null;
  }
  const { SelectedInstance, AccountId } = data.items[0];
  return (
    <>
      <Grid container columnSpacing={3} rowSpacing={3}>
        <Grid item md={6} xs={12}>
          <SimulationHeader Title={t('label.instanceFamilyTotal')} mb={3} />
          <PlannerFamilyDetailPaper
            AccountId={AccountId}
            {...SelectedInstance}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SimulationHeader Title={t('label.ourRecommendation')} mb={3} />
          <PlannerRecommendationPaper
            type={option}
            item={
              option !== 'None'
                ? data.items[0].Recommendation[option]
                : undefined
            }
          />
        </Grid>
      </Grid>
      <SimulationHeader Title={t('label.newMonthly')} mt={10} mb={3} />
      <SimulationGuide
        recommendation={
          option !== 'None' ? data.items[0].Recommendation[option] : undefined
        }
      />
      <SimulationCalculator
        recommendation={
          option !== 'None' ? data.items[0].Recommendation[option] : undefined
        }
        InstanceFamily={SelectedInstance.InstanceFamily}
      />
      <Box display="flex" justifyContent="flex-end" mt={7}>
        <Button color="inherit" size="large" onClick={handlePrev}>
          {t('button.backUsage')}
        </Button>
        <Button
          size="large"
          onClick={handleNext}
          disabled={!request || !response}
          sx={{ ml: 3 }}
        >
          {t('button.review')}
        </Button>
      </Box>
    </>
  );
};

export default PreviewStep;
