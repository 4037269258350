import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import {
  usePaginatedQuery,
  usePaginatedQueryWithUtilization,
} from '@hooks/api/base';
import { OptimizationEC2Service } from '@api/service';
import { apmSourceState } from '@atoms/global';
import { QUERY_KEY } from '@constants';
import { useState } from 'react';
import { detailsEC2DateState, detailsEC2State } from '@atoms/details';
import usePaginatedQueryWithId from '@hooks/api/base/usePaginatedQueryWithId';
import { useSelectedAWSAccounts } from '@hooks/api/common';
import {
  recommendCategoryState,
  recommendPeriodState,
  tableFilterState,
} from '@atoms/optimization';

export const useEC2Information = () =>
  usePaginatedQueryWithUtilization(
    QUERY_KEY.OPTIMIZATION.EC2.UTILIZATION,
    OptimizationEC2Service.getEC2InformationList,
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2Coverage = () =>
  usePaginatedQuery(
    QUERY_KEY.OPTIMIZATION.EC2.PURCHASING,
    OptimizationEC2Service.getEC2CoverageList,
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2RIUtilization = () =>
  usePaginatedQuery(
    QUERY_KEY.OPTIMIZATION.EC2.RI_UTILIZATION,
    OptimizationEC2Service.getEC2RIUtilizationList,
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2DetailsGeneral = () => {
  const instanceId = useRecoilValue(detailsEC2State);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.EC2.DETAILS_GENERAL, instanceId],
    ({ queryKey }) => OptimizationEC2Service.getEC2DetailsGeneral(queryKey[1]),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(instanceId) },
  );
};

export const useEC2DetailsUtilization = () => {
  const apmSource = useRecoilValue(apmSourceState);
  const instanceId = useRecoilValue(detailsEC2State);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.EC2.DETAILS_UTILIZATION, instanceId, apmSource],
    ({ queryKey }) =>
      OptimizationEC2Service.getEC2DetailsUtilization(
        queryKey[1],
        queryKey[2] as APMSource,
      ),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(instanceId) },
  );
};

export const useEC2DetailsDailyUtilization = () => {
  const apmSource = useRecoilValue(apmSourceState);
  const instanceId = useRecoilValue(detailsEC2State);
  const selectedDate = useRecoilValue(detailsEC2DateState);
  return useQuery(
    [
      QUERY_KEY.OPTIMIZATION.EC2.DETAILS_UTILIZATION,
      instanceId,
      apmSource,
      selectedDate,
    ],
    ({ queryKey }) =>
      OptimizationEC2Service.getEC2DetailsDailyUtilization(
        queryKey[1],
        queryKey[2] as APMSource,
        queryKey[3],
      ),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(instanceId) },
  );
};

export const useEC2DetailsRecommend = () => {
  const instanceId = useRecoilValue(detailsEC2State);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.EC2.DETAILS_RECOMMEND, instanceId],
    ({ queryKey }) =>
      OptimizationEC2Service.getEC2DetailsRecommend(queryKey[1]),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(instanceId) },
  );
};

export const useEC2DetailsPurchasing = () => {
  const instanceId = useRecoilValue(detailsEC2State);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.EC2.DETAILS_PURCHASING, instanceId],
    ({ queryKey }) =>
      OptimizationEC2Service.getEC2DetailsPurchasing(queryKey[1]),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(instanceId) },
  );
};

export const useEC2DetailsTransferSummary = () => {
  const instanceId = useRecoilValue(detailsEC2State);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.EC2.DETAILS_TRANSFER_SUMMARY, instanceId],
    ({ queryKey }) =>
      OptimizationEC2Service.getEC2DetailsTransferSummary(queryKey[1]),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(instanceId) },
  );
};

export const useEC2DetailsTransferDetails = () =>
  usePaginatedQueryWithId(
    QUERY_KEY.OPTIMIZATION.EC2.DETAILS_TRANSFER_DETAIL,
    OptimizationEC2Service.getEC2DetailsTransferDetails,
    detailsEC2State,
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2DetailsOthers = () => {
  const instanceId = useRecoilValue(detailsEC2State);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.EC2.DETAILS_OTHERS, instanceId],
    ({ queryKey }) => OptimizationEC2Service.getEC2DetailsOthers(queryKey[1]),
    { suspense: true, useErrorBoundary: true, enabled: Boolean(instanceId) },
  );
};

export const useEC2SPUtilization = () =>
  usePaginatedQuery(
    QUERY_KEY.OPTIMIZATION.EC2.SP_UTILIZATION,
    OptimizationEC2Service.getEC2SPUtilizationList,
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2DataTransfer = () =>
  usePaginatedQuery(
    QUERY_KEY.OPTIMIZATION.EC2.TRANSFER,
    OptimizationEC2Service.getEC2DataTransferList,
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2DataTransferSummary = () => {
  const [itemList, setItemList] = useState<string[]>([]);
  const { apiQueryString } = useSelectedAWSAccounts();
  const query = useQuery(
    [QUERY_KEY.OPTIMIZATION.EC2.TRANSFER_SUMMARY, apiQueryString, ...itemList],
    ({ queryKey }) => {
      const queryString = queryKey[1];
      queryKey.shift();
      queryKey.shift();
      return OptimizationEC2Service.getEC2DataTransferSummary(
        queryKey,
        queryString,
      );
    },
    { suspense: true, useErrorBoundary: true },
  );
  return {
    ...query,
    setItemList,
  };
};

export const useEC2Others = () =>
  usePaginatedQuery(
    QUERY_KEY.OPTIMIZATION.EC2.OTHER,
    OptimizationEC2Service.getEC2OtherList,
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2UtilizationChart = () => {
  const apmSource = useRecoilValue(apmSourceState);
  const period = useRecoilValue(recommendPeriodState);
  const category = useRecoilValue(recommendCategoryState);
  const { apiQueryString } = useSelectedAWSAccounts();
  const tableFilter = useRecoilValue(tableFilterState);
  return useQuery(
    [
      QUERY_KEY.OPTIMIZATION.EC2.UTILIZATION_CHART,
      apmSource,
      period,
      category,
      apiQueryString,
      tableFilter,
    ],
    ({ queryKey }) =>
      OptimizationEC2Service.getEC2UtilizationChart(
        queryKey[1] as APMSource,
        queryKey[2] as RecommendPeriod,
        queryKey[3] as RecommendCategory,
        queryKey[4],
        queryKey[5],
      ),
    { suspense: true, useErrorBoundary: true },
  );
};

export const useEC2PurchasingChart = () => {
  const { apiQueryString } = useSelectedAWSAccounts();
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.EC2.PURCHASING_CHART, apiQueryString],
    ({ queryKey }) => OptimizationEC2Service.getEC2PurchasingChart(queryKey[1]),
    { suspense: true, useErrorBoundary: true },
  );
};

export const useEC2DataTransferChart = () => {
  const { apiQueryString } = useSelectedAWSAccounts();
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.EC2.TRANSFER_CHART, apiQueryString],
    ({ queryKey }) =>
      OptimizationEC2Service.getEC2DataTransferChart(queryKey[1]),
    { suspense: true, useErrorBoundary: true },
  );
};

export const useEC2OthersChart = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.EC2.OTHER_CHART,
    () => OptimizationEC2Service.getEC2OtherList(0, 1),
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2AutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.EC2.AC_DATA,
    () => OptimizationEC2Service.getEC2AutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2CoverageAutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.EC2.COVERAGE_AC_DATA,
    () => OptimizationEC2Service.getEC2CoverageAutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2RIUtilAutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.EC2.RI_UTIL_AC_DATA,
    () => OptimizationEC2Service.getEC2RIUtilizationAutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2SPUtilAutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.EC2.SP_UTIL_AC_DATA,
    () => OptimizationEC2Service.getEC2SPUtilizationAutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2DataTransferAutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.EC2.TRANSFER_AC_DATA,
    () => OptimizationEC2Service.getEC2DataTransferAutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2OtherAutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.EC2.OTHER_AC_DATA,
    () => OptimizationEC2Service.getEC2OtherAutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useEC2Miscellaneous = () => {
  const { apiQueryString } = useSelectedAWSAccounts();
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.EC2.MISCELLANEOUS, apiQueryString],
    ({ queryKey }) => OptimizationEC2Service.getEC2Miscellaneous(queryKey[1]),
    { suspense: true, useErrorBoundary: true },
  );
};
