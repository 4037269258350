import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { usePaginatedQuery, usePaginatedQueryWithDate } from '@hooks/api/base';
import { OptimizationAnomaly } from '@api/service';
import { apiEndDateState, apiStartDateState } from '@atoms/global';
import { QUERY_KEY } from '@constants';

export const useOptimizationAnomaly = () =>
  usePaginatedQueryWithDate(
    QUERY_KEY.OPTIMIZATION.ANOMALY.ANOMALY,
    OptimizationAnomaly.getAnomalyList,
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );

export const useOptimizationAnomalyThreshold = () =>
  usePaginatedQuery(
    QUERY_KEY.OPTIMIZATION.ANOMALY.THRESHOLD,
    OptimizationAnomaly.getAnomalyThresholdList,
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );

export const useAnomalyThresholdAutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.ANOMALY.AC_DATA,
    () => OptimizationAnomaly.getAnomalyThresholdAutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useAnomalyNotificationAutoComplete = () =>
  useQuery(
    QUERY_KEY.OPTIMIZATION.ANOMALY.AC_DATA,
    () => OptimizationAnomaly.getAnomalyNotificationAutoComplete(),
    { suspense: true, useErrorBoundary: true },
  );

export const useDeleteAnomalyNotification = () => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: QUERY_KEY.OPTIMIZATION.ANOMALY.DELETE,
    mutationFn: (variables: { anomalyId: string }) =>
      OptimizationAnomaly.deleteAnomalyThresholdNotification(
        variables.anomalyId,
      ),
    onSuccess: () =>
      client.invalidateQueries(QUERY_KEY.OPTIMIZATION.ANOMALY.THRESHOLD),
  });
};

export const useAddAnomalyThresholdNotification = () => {
  const client = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.OPTIMIZATION.ANOMALY.CREATE,
    mutationFn: (variables: {
      CompanyId: string;
      AccountId: string;
      AccountName: string;
      Duration: number;
      EmailList: AnomalyRecipient[];
      Region: string;
      Service: string;
      Threshold: number;
    }) =>
      OptimizationAnomaly.addAnomalyThresholdNotification(
        variables.CompanyId,
        variables.AccountId,
        variables.AccountName,
        variables.Duration,
        variables.EmailList,
        variables.Region,
        variables.Service,
        variables.Threshold,
      ),
    onSuccess: () =>
      client.invalidateQueries(QUERY_KEY.OPTIMIZATION.ANOMALY.THRESHOLD),
  });
};

export const useUpdateAnomalyThresholdNotification = () => {
  const client = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.OPTIMIZATION.ANOMALY.UPDATE,
    mutationFn: (variables: {
      original: OptimizationAnomalyThreshold;
      changes: Partial<Omit<OptimizationAnomalyThreshold, 'UID'>>;
    }) =>
      OptimizationAnomaly.updateAnomalyThresholdNotification(
        variables.original,
        variables.changes,
      ),
    onSuccess: () =>
      client.invalidateQueries(QUERY_KEY.OPTIMIZATION.ANOMALY.THRESHOLD),
  });
};

export const useAnomalyOverview = () => {
  const startDate = useRecoilValue(apiStartDateState);
  const endDate = useRecoilValue(apiEndDateState);
  return useQuery(
    [QUERY_KEY.OPTIMIZATION.ANOMALY.ANOMALY_OVERVIEW, startDate, endDate],
    ({ queryKey }) =>
      OptimizationAnomaly.getAnomalyOverview(queryKey[1], queryKey[2]),
    { suspense: true, useErrorBoundary: true },
  );
};
