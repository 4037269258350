import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMasqueradeLogin } from '@hooks/api/settings/user';
import { CCModalProps, ConfirmDialog } from '@components/modal';
import { GLOBAL } from '@constants';
import { useNavigate } from 'react-router-dom';
import { isAxiosError } from '@utils';
import { useSnackbar } from '@hooks/common';

const MasqueradeModal: React.FC<CCModalProps & { uid: string }> = ({
  open,
  onClose,
  uid,
}) => {
  const { t } = useTranslation('dialog');
  const { t: commonT } = useTranslation('common');
  const { t: toastT } = useTranslation('toast');

  const navigate = useNavigate();
  const { mutateAsync } = useMasqueradeLogin();
  const { showErrorSnackbar } = useSnackbar();

  const masqueradeLogin = useCallback(async () => {
    try {
      const data = await mutateAsync({ uid });
      if (data?.items?.length) {
        localStorage.removeItem(GLOBAL.REFRESH_TOKEN_EXPIRED);
        localStorage.removeItem(GLOBAL.REFRESH_TOKEN);
        localStorage.removeItem(GLOBAL.ACCESS_TOKEN_EXPIRED);
        localStorage.removeItem(GLOBAL.ACCESS_TOKEN);
        localStorage.setItem(GLOBAL.ACCESS_TOKEN, data.items[0].access_token);
        localStorage.setItem(
          GLOBAL.ACCESS_TOKEN_EXPIRED,
          data.items[0].access_token_expired.toString(),
        );
        navigate('/');
        window.location.reload();
      }
    } catch (e: any) {
      if (isAxiosError(e) && e.response?.data?.message) {
        switch (e.response.data.message) {
          case 'Account is deactivated':
            showErrorSnackbar(
              "You can't use masquerade login with a deactivated account",
            );
            break;
          default:
            showErrorSnackbar(toastT('error.general'));
            break;
        }
      }
    }
  }, [uid]);

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={t('settings.user.masquerade.title')}
      content={t('settings.user.masquerade.content')}
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: commonT('button.cancel'),
        },
        {
          onClick: masqueradeLogin,
          label: commonT('button.confirm'),
        },
      ]}
    />
  );
};

export default MasqueradeModal;
