import React, { MouseEventHandler } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as PropTypes from 'prop-types';
import { ConfirmModalProps } from './modal.types';

const ConfirmDialog: React.FC<
  ConfirmModalProps & Pick<DialogProps, 'hideBackdrop'>
> = ({ open, onClose, onSubmit, title, content, Actions, hideBackdrop }) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="sm"
    hideBackdrop={hideBackdrop}
    PaperProps={{
      variant: 'elevation',
      elevation: 1,
    }}
    fullWidth
  >
    {onSubmit ? (
      <form onSubmit={onSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        {Actions?.length && (
          <DialogActions>
            {Actions.map(
              ({
                type = 'button',
                color,
                onClick,
                disabled,
                loading,
                label,
              }) => (
                <LoadingButton
                  key={`cc_dialog_action_${label}`}
                  variant="contained"
                  type={type}
                  size="large"
                  color={color}
                  onClick={onClick as MouseEventHandler<HTMLButtonElement>}
                  loading={loading}
                  disabled={disabled}
                >
                  {label}
                </LoadingButton>
              ),
            )}
          </DialogActions>
        )}
      </form>
    ) : (
      <>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        {Actions?.length && (
          <DialogActions>
            {Actions.map(
              ({
                type = 'button',
                color,
                onClick,
                disabled,
                loading,
                label,
              }) => (
                <LoadingButton
                  key={`cc_dialog_action_${label}`}
                  variant="contained"
                  type={type}
                  size="large"
                  color={color}
                  onClick={onClick as MouseEventHandler<HTMLButtonElement>}
                  loading={loading}
                  disabled={disabled}
                >
                  {label}
                </LoadingButton>
              ),
            )}
          </DialogActions>
        )}
      </>
    )}
  </Dialog>
);
ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  Actions: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf<'button' | 'submit' | 'reset'>([
        'button',
        'submit',
        'reset',
      ]),
      color: PropTypes.oneOf<'inherit' | 'primary' | 'secondary' | 'tertiary'>([
        'inherit',
        'primary',
        'secondary',
        'tertiary',
      ]),
      loading: PropTypes.bool,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
    }).isRequired,
  ),
};
ConfirmDialog.defaultProps = {
  onClose: undefined,
  onSubmit: undefined,
  Actions: undefined,
};

export default React.memo(ConfirmDialog);
