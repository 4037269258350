import { useMutation } from 'react-query';
import { AWSAccountService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useMutation({
    mutationKey: QUERY_KEY.ONBOARD.VERIFY_ARN,
    mutationFn: (variables: { arn: string; externalId: string }) =>
      AWSAccountService.verifyARN(variables.arn, variables.externalId),
  });
