import React, { ComponentType } from 'react';
import { useCurrentInfo } from '@hooks/api/common';
import { AccessDenied } from '@components/ui/common';

const WithRoot =
  <P extends Record<string, unknown>>(
    Component: ComponentType<P>,
  ): React.FC<P> =>
  (props) => {
    const { currentRole } = useCurrentInfo();
    if (currentRole !== 1) {
      return <AccessDenied />;
    }
    return <Component {...props} />;
  };

export default WithRoot;
