/* eslint-disable no-nested-ternary */
import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Box,
  experimental_sx as sx,
  styled,
  SvgIcon,
  Typography,
} from '@mui/material';
import { ValueFormatter } from '@utils';
import { ReactComponent as ArrowUp } from '@assets/icons/common/ArrowUp.svg';
import { ReactComponent as ArrowDown } from '@assets/icons/common/ArrowDown.svg';
import { bluegrey, secondary, tertiary } from '@theme/colors';

export const ColorDot = styled(Box, {
  shouldForwardProp: (propName) =>
    propName !== 'color' && propName !== 'lineColor',
})<{
  color?: string;
  lineColor?: string;
}>(({ color = '#fff', lineColor = undefined }) => ({
  display: 'inline-block',
  width: '8px',
  height: '8px',
  borderRadius: '10px',
  backgroundColor: color,
  border: lineColor ? `1px solid ${lineColor}` : 'none',
  // borderWidth: lineColor ? '1px' : 'none',
  // borderColor: lineColor,
}));

export const ColorDiamond = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'color',
})<{
  color?: string;
}>(({ color = '#fff' }) => ({
  display: 'inline-block',
  width: 0,
  height: 0,
  position: 'relative',
  top: '-5px',
  border: '5px solid transparent',
  borderBottomColor: color,
  '&:after': {
    content: '""',
    width: 0,
    height: 0,
    position: 'absolute',
    left: '-5px',
    top: '5px',
    border: '5px solid transparent',
    borderTopColor: color,
  },
}));

const DotLegendItemComponent: React.FC<{
  color: string;
  borderColor?: string;
  textColor?: string;
  label?: string;
  diamond?: boolean;
}> = ({ color, borderColor, textColor, label = '', diamond = false }) => (
  <Box className="cc-legend-dot-item">
    {diamond ? (
      <ColorDiamond color={color} mr={1} />
    ) : (
      <ColorDot color={color} lineColor={borderColor} mr={1} />
    )}
    <Typography
      variant="body2"
      color={textColor || 'textSecondary'}
      component="span"
    >
      {label}
    </Typography>
  </Box>
);
DotLegendItemComponent.propTypes = {
  color: PropTypes.string.isRequired,
  borderColor: PropTypes.string,
  textColor: PropTypes.string,
  label: PropTypes.string,
  diamond: PropTypes.bool,
};
DotLegendItemComponent.defaultProps = {
  borderColor: undefined,
  textColor: undefined,
  label: '',
  diamond: false,
};
export const DotLegendItem = React.memo(DotLegendItemComponent);

export const DotLegendWrapper = styled(Box)(
  sx({
    display: 'flex',
    justifyContent: 'flex-end',
    mb: 3,
    '> .cc-legend-dot-item:not(:first-of-type)': {
      ml: 4,
    },
  }),
);

export const LegendWithPercent = React.memo(
  ({ label, percent }: { label: string | number; percent: number }) => (
    <Box display="flex" alignItems="center">
      <Typography variant="subtitle2" component="span" color="inherit" mr={2}>
        {label}
      </Typography>
      {percent !== 0.0 && (
        <SvgIcon
          component={percent > 0.0 ? ArrowUp : ArrowDown}
          sx={{
            width: '0.5rem',
            height: '0.5rem',
            mr: 1,
            color: percent > 0.0 ? secondary[500] : tertiary[300],
          }}
        />
      )}
      <Typography
        variant="footer"
        sx={{
          color:
            percent === 0.0
              ? bluegrey[600]
              : percent > 0.0
              ? secondary[500]
              : tertiary[300],
        }}
      >
        {ValueFormatter.toFixed(Math.abs(percent))}%
      </Typography>
    </Box>
  ),
);
LegendWithPercent.displayName = 'LegendWithPercent';
