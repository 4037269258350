import React from 'react';
import { Paper, Typography } from '@mui/material';
import { WithCUR, WithTrial } from '@hooks/hoc';
import { SuspenseBox } from '@components/styled/suspense';
import { useTranslation } from 'react-i18next';
import { AnomalySettingsTable } from './table';

const AnomalySettingsPage: React.FC = () => {
  const { t } = useTranslation('optimization');
  return (
    <Paper square sx={{ px: 7, py: 5 }}>
      <Typography mb={3}>{t('label.anomaly.conditionList')}</Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        dangerouslySetInnerHTML={{ __html: t('label.anomaly.conditionDesc') }}
      />
      <SuspenseBox mt={8}>
        <AnomalySettingsTable />
      </SuspenseBox>
    </Paper>
  );
};

export default WithTrial(WithCUR(AnomalySettingsPage));
