import React from 'react';
import {
  TextField,
  MenuItem,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  Box,
} from '@mui/material';
import { useCreateCoupon } from '@hooks/api/admin/coupon';
import { CCModalProps, DialogModal } from '@components/modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';

type CreateCouponForm = {
  Name: string;
  Duration: 'forever' | 'once' | 'repeating';
  PercentOff: number;
  DurationInMonths: number;
};

const useCreateCouponForm = (): UseValidation<CreateCouponForm> => ({
  initialValues: {
    Name: '',
    Duration: 'forever',
    PercentOff: 1,
    DurationInMonths: 0,
  },
  validationSchema: Yup.object({
    Name: Yup.string().trim().defined('Name is required'),
    Duration: Yup.string().trim().defined('Duration is required'),
    PercentOff: Yup.string()
      .matches(/^(?:[1-9][0-9]?|100)$/, 'Only 1 to 100 are allowed')
      .defined('PercentOff is required'),
    DurationInMonths: Yup.string().when('Duration', {
      is: 'repeating',
      then: Yup.string()
        .matches(/^[1-9][0-9]?$/, 'Only 1 to 99 are allowed')
        .defined('Duration in month is required'),
      otherwise: Yup.string(),
    }),
  }).defined(),
});

const CreateCouponModal: React.FC<CCModalProps> = ({ open, onClose }) => {
  const { mutateAsync } = useCreateCoupon();
  const { initialValues, validationSchema } = useCreateCouponForm();
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (v) => {
      try {
        await mutateAsync({
          ...v,
          PercentOff: Number(v.PercentOff),
          DurationInMonths: Number(v.DurationInMonths),
        });
        onClose();
      } catch (e) {
        console.error(e);
      }
    },
  });
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="md"
      HeaderProps={{
        icon: 'Admin',
        label: 'Add New Coupon',
      }}
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: 'Cancel',
        },
        {
          type: 'submit',
          label: 'Add coupon',
          disabled: !isValid,
        },
      ]}
    >
      <Box>
        <TextField
          name="Name"
          type="text"
          inputMode="text"
          label="Coupon Name"
          value={values.Name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(touched.Name && errors.Name)}
          helperText={
            Boolean(touched.Name && errors.Name) && String(errors.Name)
          }
          fullWidth
        />
      </Box>
      <Box mt={5}>
        <TextField
          name="PercentOff"
          value={values.PercentOff}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Percent Off"
          error={Boolean(touched.PercentOff && errors.PercentOff)}
          helperText={
            Boolean(touched.PercentOff && errors.PercentOff) &&
            String(errors.PercentOff)
          }
          InputProps={{
            inputProps: {
              style: { textAlign: 'right' },
            },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          fullWidth
        />
      </Box>
      <Box mt={5}>
        <FormControl fullWidth>
          <InputLabel shrink id="label_duration">
            Duration
          </InputLabel>
          <Select
            name="Duration"
            value={values.Duration}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Duration"
            labelId="label_duration"
          >
            <MenuItem value="forever">Every month</MenuItem>
            <MenuItem value="once">First month only</MenuItem>
            <MenuItem value="repeating">Limited number of months</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {values.Duration === 'repeating' && (
        <Box mt={5}>
          <TextField
            name="DurationInMonths"
            value={values.DurationInMonths}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Duration In Months"
            error={Boolean(touched.DurationInMonths && errors.DurationInMonths)}
            helperText={
              Boolean(touched.DurationInMonths && errors.DurationInMonths) &&
              String(errors.DurationInMonths)
            }
            InputProps={{
              inputProps: {
                style: { textAlign: 'right' },
              },
              endAdornment: (
                <InputAdornment position="end">month(s)</InputAdornment>
              ),
            }}
            fullWidth
          />
        </Box>
      )}
    </DialogModal>
  );
};

export default CreateCouponModal;
