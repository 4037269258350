import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import { Grid, Typography, TextField, Box, Stack, Button } from '@mui/material';
import { CCModalProps, ConfirmDialog, DialogModal } from '@components/modal';
import { useGetUser, useUpdateUser } from '@hooks/api/settings/user';
import { useCurrentInfo } from '@hooks/api/common';
import { GrmtTableColumnInfo, SimpleTable } from '@components/table';
import { useTranslation } from 'react-i18next';
import { useAWSAccountHelper } from '@hooks/helper';
import { useSnackbar, useToggle } from '@hooks/common';

const UserDetailModal: React.FC<CCModalProps & { uid?: string }> = ({
  open,
  onClose,
  uid,
}) => {
  const { t } = useTranslation('dialog');
  const { t: commonT } = useTranslation('common');
  const { t: formT } = useTranslation('form');
  const { t: settingsT } = useTranslation('settings');
  const { t: toastT } = useTranslation('toast');
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [showConfirm, toggleConfirm] = useToggle();
  const { findAWSAccountByUid } = useAWSAccountHelper();
  const columns = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: settingsT('account.label.account', { context: 'type' }),
        Cell: ({ row }) => {
          const account = findAWSAccountByUid(row.original.id);
          if (account) {
            return account.isPayer
              ? settingsT('account.accountType.management')
              : settingsT('account.accountType.member');
          }
          return '-';
        },
      },
      {
        Header: `${settingsT('account.label.account')} ID`,
        Cell: ({ row }) => findAWSAccountByUid(row.original.id)?.id || '-',
      },
      {
        Header: settingsT('account.label.account', { context: 'nickname' }),
        Cell: ({ row }) => findAWSAccountByUid(row.original.id)?.name || '-',
      },
      {
        Header: settingsT('user.label.permission'),
        accessor: 'readOnly' as const,
        Cell: ({ value }) =>
          value
            ? settingsT('account.roleType.user')
            : settingsT('account.roleType.manager'),
      },
    ],
    [findAWSAccountByUid],
  );
  const { mutateAsync, isLoading } = useUpdateUser();
  const { currentUser, currentAWSAccounts } = useCurrentInfo();
  const { data } = useGetUser(uid);
  const roleLabel = useMemo(() => {
    if (data?.items?.length && data.items[0].roles?.length) {
      switch (Math.min(...data.items[0].roles)) {
        case 1:
          return settingsT('user.role.root');
        case 2:
          return settingsT('user.role.admin');
        default:
          return settingsT('user.role.user');
      }
    }
    return '-';
  }, [data?.items]);
  const assignedAccounts = useMemo<
    Array<{ id: string; readOnly: boolean }>
  >(() => {
    const items: Array<{ id: string; readOnly: boolean }> = [];
    if (data?.items?.length) {
      const { roles, assignedAWSAccount } = data.items[0];
      if (Math.min(...roles) < 3 && currentAWSAccounts?.length) {
        currentAWSAccounts.forEach((acc) => {
          items.push({
            id: acc.uid,
            readOnly: false,
          });
        });
      } else if (Math.min(...roles) === 3) {
        items.push(...assignedAWSAccount);
      }
    }
    return items;
  }, [data?.items, currentAWSAccounts]);
  const changeRole = useCallback(async () => {
    if (data?.items?.length) {
      const role = Math.min(...data.items[0].roles);
      const toRole = role === 2 ? 3 : 2;
      try {
        await mutateAsync({
          original: data.items[0],
          changes: {
            roles: [toRole],
          },
        });
        showSuccessSnackbar(toastT('success.settings.user.detail.role'));
      } catch (e) {
        showErrorSnackbar(toastT('error.general'));
      }
      toggleConfirm();
    }
  }, [data?.items]);
  if (!data?.items?.length) {
    return null;
  }
  const selectedUser = data.items[0];
  const userRole = Math.min(...selectedUser.roles);
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      maxWidth="md"
      HeaderProps={{
        icon: 'Admin',
        label: t('settings.user.detail.title'),
      }}
      Actions={[
        {
          color: 'inherit',
          onClick: onClose as MouseEventHandler<HTMLButtonElement>,
          label: commonT('button.close'),
        },
      ]}
    >
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            variant="standard"
            label={formT('holder.firstName')}
            value={selectedUser.firstname}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            variant="standard"
            label={formT('holder.lastName')}
            value={selectedUser.lastname}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            variant="standard"
            label={formT('holder.email')}
            value={selectedUser.email}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Stack direction="row" alignItems="flex-end" spacing={1}>
            <TextField
              variant="standard"
              label={formT('holder.role')}
              value={roleLabel}
              sx={{
                flexGrow: 1,
              }}
              disabled
            />
            {userRole > 1 && selectedUser.uid !== currentUser?.uid && (
              <Button onClick={toggleConfirm}>
                {userRole === 2 && settingsT('user.label.makeUser')}
                {userRole > 2 && settingsT('user.label.makeAdmin')}
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Typography variant="caption" component="p" color="textSecondary" mt={8}>
        {settingsT('user.label.accountAccess')}
      </Typography>
      <Box mt={2}>
        {assignedAccounts.length ? (
          <SimpleTable data={assignedAccounts} columnInfo={columns} />
        ) : (
          <Typography>{settingsT('user.label.emptyAccount')}</Typography>
        )}
      </Box>
      {showConfirm && (
        <ConfirmDialog
          open={showConfirm}
          onClose={toggleConfirm}
          title={t('settings.user.role.title')}
          content={t('settings.user.role.content')}
          Actions={[
            {
              label: commonT('button.cancel'),
              color: 'inherit',
              onClick: toggleConfirm,
            },
            {
              label: commonT('button.okay'),
              onClick: changeRole,
              loading: isLoading,
            },
          ]}
          hideBackdrop
        />
      )}
    </DialogModal>
  );
};
UserDetailModal.defaultProps = {
  uid: undefined,
};

export default UserDetailModal;
