import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useEC2DataTransfer,
  useEC2DataTransferSummary,
} from '@hooks/api/optimization/ec2';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { DetailLinkIcon } from '@components/table/table.styled';
import { ROUTES } from '@routes';
import { ValueFormatter } from '@utils';
import { useAWSHelper } from '@hooks/helper';
import ComputeTransferFilter from './ComputeTransferFilter';

const ComputeTransferTable: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { getDataTransferRegion, getDataTransferType } = useAWSHelper();
  const { data, onPageChange, pageSize } = useEC2DataTransfer();
  const { data: summmaryData } = useEC2DataTransferSummary();

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.common.instanceId'),
        accessor: 'InstanceId' as const,
        minWidth: 180,
        maxWidth: 180,
        disableSortBy: true,
      },
      {
        Header: t('label.transfer.type'),
        accessor: 'TransferType' as const,
        minWidth: 170,
        maxWidth: 170,
        disableSortBy: true,
        Cell: ({ value }) => getDataTransferType(value),
      },
      {
        Header: t('label.transfer.from'),
        accessor: 'FromLocation' as const,
        disableSortBy: true,
        Cell: ({ value }) => getDataTransferRegion(value),
      },
      {
        Header: t('label.transfer.to'),
        accessor: 'ToLocation' as const,
        disableSortBy: true,
        Cell: ({ value }) => getDataTransferRegion(value),
      },
      {
        Header: `${t('label.transfer.traffic')} (GB)`,
        accessor: 'Traffic' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toLocaleString(value) || '-',
      },
      {
        Header: t('label.common.cost'),
        accessor: 'CurrentCost' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value) || '-',
      },
      {
        Header: t('label.common.savings'),
        accessor: 'MonthlySaving' as const,
        align: 'right',
        Cell: ({ value }) => ValueFormatter.toCurrency(value) || '-',
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailLinkIcon
            to={`${ROUTES.DETAILS.COMPUTE}/${row.original.InstanceId}?tab=transfer`}
            requireCUR
          />
        ),
      },
    ],
    [getDataTransferRegion],
  );

  return (
    <DataPaginationTable
      data={data?.body?.items}
      columnInfo={columnInfo}
      onPageChange={onPageChange}
      totalCount={data?.body?.totalCount}
      pageSize={pageSize}
      FilterElement={ComputeTransferFilter}
      CSVUrl="datatransferec2"
      enableFilter
      enableSort
      LastUpdate={summmaryData.extras.LatestUpdate}
    />
  );
};

export default ComputeTransferTable;
