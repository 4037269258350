import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { UserService } from '@api/service';
import { QUERY_KEY } from '@constants';
import {
  externalTokenState,
  externalUserIdState,
} from '@pages/private/onboard/external/ExternalAccount.atoms';

export default () => {
  const activeToken = useRecoilValue(externalTokenState);
  const userId = useRecoilValue(externalUserIdState);
  return useQuery(
    [QUERY_KEY.CURRENT.USERS, userId, activeToken],
    ({ queryKey }) => {
      if (queryKey[1] && queryKey[2]) {
        return UserService.getUserExternal(queryKey[1], queryKey[2]);
      }
      return undefined;
    },
    {
      cacheTime: 15000,
      enabled: Boolean(activeToken) && Boolean(userId),
    },
  );
};
