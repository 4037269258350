import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { usePlannerUncovered } from '@hooks/api/planner';
import { useAWSHelper } from '@hooks/helper';
import { useToggleWithValue } from '@hooks/common';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { DetailButtonIcon } from '@components/table/table.styled';
import { LinkTypography } from '@components/styled';
import { ROUTES } from '@routes';
import { ValueFormatter } from '@utils';
import ComputeCoverageFilter from '@pages/private/optimization/compute/purchasing/table/coverage/ComputeCoverageFilter';
import UncoveredDetailModal from './UncoveredDetailModal';

const UncoveredTable: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { t: plannerT } = useTranslation('planner');
  const { getOptimizationAWSRegion } = useAWSHelper();
  const navigate = useNavigate();
  const { data, onPageChange, pageSize } = usePlannerUncovered();
  const [selected, openDetail, toggleDetail] = useToggleWithValue();
  const columns = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        disableSortBy: true,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.common.instanceId'),
        accessor: 'InstanceId' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.common.instanceType'),
        accessor: 'InstanceType' as const,
        disableSortBy: true,
      },
      {
        Header: t('label.common.od'),
        accessor: 'OnDemandHours' as const,
        Cell: ({ value, row }) => (
          <>
            {`${t('label.common.hours', {
              value: ValueFormatter.toLocaleString(value, 0),
            })}`}
            <br />
            {`${t('label.common.cost')}: ${ValueFormatter.toCurrency(
              row.original.OnDemandCost,
            )}`}
          </>
        ),
      },
      {
        Header: t('label.common.ri', { context: 'coverage' }),
        accessor: 'RICoverage' as const,
        Cell: ({ value, row }) =>
          `${t('label.common.hours', {
            value: ValueFormatter.toLocaleString(row.original.ReservedHours, 0),
          })} (${ValueFormatter.toPercent(value)})`,
      },
      {
        Header: t('label.common.sp', { context: 'coverage' }),
        accessor: 'SavingsPlanCoverage' as const,
        Cell: ({ value, row }) =>
          `${t('label.common.hours', {
            value: ValueFormatter.toLocaleString(
              row.original.SavingsPlanHours,
              0,
            ),
          })} (${ValueFormatter.toPercent(value)})`,
      },
      {
        Header: plannerT('label.recommendation'),
        accessor: 'RecommendPricingPlan' as const,
        align: 'center',
        disableSortBy: true,
        Cell: ({ value, row }) => {
          if (_.isEmpty(value)) {
            return '-';
          }
          return (
            <LinkTypography
              color="primary"
              onClick={() =>
                navigate(
                  `${ROUTES.PLANNER.SIMULATION_UNCOVERED}/${row.original.InstanceId}`,
                )
              }
            >
              {plannerT(
                `recommendButton.${value.Aggressive.Type as PurchasingType}`,
              )}
            </LinkTypography>
          );
        },
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        Cell: ({ row }) => (
          <DetailButtonIcon
            onClick={() => toggleDetail(row.original.InstanceId)}
          />
        ),
      },
    ],
    [getOptimizationAWSRegion],
  );
  return (
    <>
      <DataPaginationTable
        TitleElement={plannerT('header.uncovered')}
        columnInfo={columns}
        data={data?.body?.items}
        totalCount={data?.body?.totalCount}
        onPageChange={onPageChange}
        pageSize={pageSize}
        FilterElement={ComputeCoverageFilter}
        enableSort
      />
      {openDetail && selected && (
        <UncoveredDetailModal
          open={openDetail}
          onClose={toggleDetail}
          instanceId={selected}
        />
      )}
    </>
  );
};

export default UncoveredTable;
