import React from 'react';
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { FilterWrapperProps } from './filter.types';

const FilterWrapper: React.FC<FilterWrapperProps> = ({
  onClose,
  onSubmit,
  isValid = true,
  children,
}) => {
  const { t } = useTranslation('common');
  return (
    <Box>
      <form onSubmit={onSubmit}>
        {children}
        <Box display="flex" justifyContent="flex-end" mt={8} mb={2}>
          <Button type="button" color="inherit" size="large" onClick={onClose}>
            {t('button.close')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            disabled={!isValid}
            sx={{ ml: 2 }}
          >
            {t('button.apply')}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default FilterWrapper;
