import React from 'react';
import {
  Box,
  experimental_sx as sx,
  Grid,
  MenuItem,
  styled,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useCreatePaymentMethod } from '@hooks/api/settings/payment';
import { CCModalProps, DialogModal } from '@components/modal';
import { ReactComponent as CardChip } from '@assets/images/cards/card-chip.svg';
import { useSnackbar } from '@hooks/common';

type CreditCardForm = {
  number: string;
  exp_month: string;
  exp_year: string;
  cvc: string;
  name: string;
};

const useCreditCardForm = (): UseValidation<CreditCardForm> => {
  const { t } = useTranslation('validation');
  const { t: settingsT } = useTranslation('settings');

  const initialValues: CreditCardForm = {
    number: '',
    exp_month: moment().format('M'),
    exp_year: moment().format('YYYY'),
    cvc: '',
    name: '',
  };

  const validationSchema: Yup.SchemaOf<CreditCardForm> = Yup.object({
    number: Yup.string()
      .trim()
      .matches(/^[0-9]{16}$/, t('settings.card.number'))
      .defined(
        t('custom.required', {
          context: 'post2',
          name: settingsT('payment.label.card', { context: 'number' }),
        }),
      ),
    exp_month: Yup.string()
      .trim()
      .matches(/^[0-9]{1,2}$/)
      .defined(),
    exp_year: Yup.string()
      .trim()
      .matches(/^[0-9]{4}$/)
      .defined(),
    cvc: Yup.string()
      .trim()
      .matches(/^[0-9]{3,4}$/)
      .defined(
        t('custom.required', {
          context: 'post2',
          name: 'CVC',
        }),
      ),
    name: Yup.string()
      .trim()
      .defined(
        t('custom.required', {
          context: 'post2',
          name: settingsT('payment.label.card', { context: 'name' }),
        }),
      ),
  }).defined();

  return {
    initialValues,
    validationSchema,
  };
};

export const CardBrand = styled('img')(
  sx({
    width: '50px',
    position: 'absolute',
    top: '16px',
    right: '24px',
  }),
);

const CardWrapper = styled('div')`
  position: relative;
  width: 100%;
  padding-bottom: 63%;
`;

const CardBody = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to right, #8499fe, rgba(143, 0, 255, 0.25));
  border-radius: 30px;
`;

const AddNewCard: React.FC<CCModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const { t: toastT } = useTranslation('toast');
  const currentYear = Number(moment().format('YYYY'));
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { isLoading, mutateAsync } = useCreatePaymentMethod();
  const { initialValues, validationSchema } = useCreditCardForm();
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (card) => {
      if (process.env.REACT_APP_JWT_KEY) {
        try {
          const token = jwt.sign(
            {
              number: card.number,
              exp_month: card.exp_month,
              exp_year: card.exp_year,
              cvc: card.cvc,
              name: card.name,
            },
            process.env.REACT_APP_JWT_KEY,
            { algorithm: 'HS256' },
          );
          await mutateAsync({ CardToken: token });
          showSuccessSnackbar(toastT('success.settings.payment.card.add'));
          onClose();
        } catch (e) {
          showErrorSnackbar(toastT('error.general'));
        }
      }
    },
  });

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="sm"
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: commonT('button.cancel'),
        },
        {
          type: 'submit',
          loading: isLoading,
          disabled: !isValid,
          label: t('payment.button.addCard'),
        },
      ]}
    >
      <Box mt={5} ml={10} mr={10}>
        <CardWrapper>
          <CardBody>
            <SvgIcon
              component={CardChip}
              viewBox="0 0 62 51"
              sx={{
                position: 'absolute',
                width: 62,
                height: 51,
                top: '45%',
                left: '16px',
                transform: 'translate(0, -50%)',
              }}
            />
            <Box sx={{ position: 'absolute', bottom: 24, left: 24, right: 24 }}>
              <Typography variant="subtitle2" color="white" mb={1}>
                **** **** ****{' '}
                {values.number.length > 12
                  ? `${values.number.slice(12)}****`.slice(0, 4)
                  : '****'}
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle2" color="white">
                  {values.name}
                </Typography>
                <Typography variant="subtitle2" color="white">
                  {`0${values.exp_month}`.slice(-2)}/{values.exp_year.slice(-2)}
                </Typography>
              </Box>
            </Box>
          </CardBody>
        </CardWrapper>
      </Box>
      <Box mt={6}>
        <Grid container alignItems="center" columnSpacing={5}>
          <Grid item xs={3}>
            <Typography variant="body2">
              {t('payment.label.card', { context: 'number' })}
            </Typography>
          </Grid>
          <Grid item xs>
            <TextField
              name="number"
              type="tel"
              inputMode="numeric"
              value={values.number}
              onChange={handleChange}
              onBlur={handleBlur}
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="cc-number"
              inputProps={{ pattern: '[0-9]{16}', maxLength: '16' }}
              error={Boolean(errors.number && touched.number)}
              helperText={
                Boolean(errors.number && touched.number) &&
                String(errors.number)
              }
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" columnSpacing={5} mt={2}>
          <Grid item xs={3}>
            <Typography variant="body2">
              {t('payment.label.card', { context: 'valid' })}
            </Typography>
          </Grid>
          <Grid item xs>
            <Box display="flex" alignItems="center">
              <TextField
                name="exp_month"
                value={values.exp_month}
                onChange={handleChange}
                onBlur={handleBlur}
                select
                fullWidth
              >
                {Array.from({ length: 12 }).map((v, i) => (
                  <MenuItem
                    key={`month_select_${i.toString()}`}
                    value={String(i + 1)}
                  >
                    {String(`0${i + 1}`).slice(-2)}
                  </MenuItem>
                ))}
              </TextField>
              <Typography variant="body2" ml={1} mr={1}>
                /
              </Typography>
              <TextField
                name="exp_year"
                value={values.exp_year}
                onChange={handleChange}
                onBlur={handleBlur}
                select
                fullWidth
              >
                {Array.from({ length: 10 }).map((v, i) => (
                  <MenuItem
                    key={`year_select_${i.toString()}`}
                    value={String(currentYear + i)}
                  >
                    {currentYear + i}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
        </Grid>
        <Grid container alignItems="center" columnSpacing={5} mt={2}>
          <Grid item xs={3}>
            <Typography variant="body2">CVC</Typography>
          </Grid>
          <Grid item xs>
            <TextField
              name="cvc"
              type="password"
              inputMode="numeric"
              value={values.cvc}
              onChange={handleChange}
              onBlur={handleBlur}
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="cc-cvc"
              inputProps={{ pattern: '[0-9]{3,4}', maxLength: '4' }}
              error={Boolean(errors.cvc && touched.cvc)}
              helperText={
                Boolean(errors.cvc && touched.cvc) && String(errors.cvc)
              }
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" columnSpacing={5} mt={2}>
          <Grid item xs={3}>
            <Typography variant="body2">
              {t('payment.label.card', { context: 'name' })}
            </Typography>
          </Grid>
          <Grid item xs>
            <TextField
              name="name"
              type="text"
              inputMode="text"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="off"
              error={Boolean(errors.name && touched.name)}
              helperText={
                Boolean(errors.name && touched.name) && String(errors.name)
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </DialogModal>
  );
};

export default AddNewCard;
