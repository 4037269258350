import React from 'react';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckCircleIcon } from '@assets/icons/common/CheckCircle.svg';

export const PureAuthContent: React.FC<{
  title: string;
  subtitle?: string;
  description: string;
}> = ({ title, subtitle, description, children }) => (
  <Box display="flex" alignItems="center" flexDirection="column">
    <Typography
      variant="h3"
      align="center"
      mb={3}
      dangerouslySetInnerHTML={{ __html: title }}
    />
    {subtitle && (
      <Typography
        variant="subtitle2"
        color="textSecondary"
        align="center"
        mb={1}
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
    )}
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      mb={5}
      dangerouslySetInnerHTML={{ __html: description }}
    />
    <Box mx={3}>{children}</Box>
  </Box>
);
PureAuthContent.defaultProps = {
  subtitle: undefined,
};

export const PureAuthCompleted = React.memo(
  ({
    title,
    description,
    label,
    link,
  }: {
    title: string;
    description: string;
    label: string;
    link: string;
  }) => {
    const navigate = useNavigate();
    return (
      <Box display="flex" alignItems="center" flexDirection="column">
        <SvgIcon
          component={CheckCircleIcon}
          sx={{ width: '5.75rem', height: '5.75rem', mb: 4 }}
        />
        <Typography
          variant="subtitle2"
          color="textSecondary"
          align="center"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Typography
          variant="caption"
          color="textSecondary"
          align="center"
          sx={{ mt: 1, mb: 7 }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <Button
          type="button"
          size="large"
          onClick={() => navigate(link, { replace: true })}
          fullWidth
        >
          {label}
        </Button>
      </Box>
    );
  },
);
