import { useQuery } from 'react-query';
import { QUERY_KEY } from '@constants';
import { OverviewService } from '@api/service';
import { useSelectedAWSAccounts } from '@hooks/api/common';

export default (current: boolean) => {
  const { apiQueryString } = useSelectedAWSAccounts();
  return useQuery(
    [QUERY_KEY.OVERVIEW.COST_COMPARISON, current, apiQueryString],
    ({ queryKey }) => {
      const isCurrent = queryKey[1];
      return isCurrent
        ? OverviewService.getThisMonthComparison(queryKey[2] as string)
        : OverviewService.getLastMonthComparison(queryKey[2] as string);
    },
    { suspense: true, useErrorBoundary: true },
  );
};
