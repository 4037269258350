import React from 'react';
import * as PropTypes from 'prop-types';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Box, Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { GrmtDefaultChartProps } from '@components/chart';
import { ChartCoverBox } from '@components/styled/noData';

const COLORS = ['#7800EF', '#A860F0', '#CCA8F0', '#CCA8F099', '#EEE1FC'];

type ReportSummaryChartProps = GrmtDefaultChartProps<{
  name: string;
  value: number | string;
}> & {
  total: string;
  valueFormatter?: (value: any) => string;
  noDataText?: string;
};

const ReportSummaryChart: React.FC<ReportSummaryChartProps> = ({
  data,
  total,
  valueFormatter,
  noDataText,
}) => (
  <ChartCoverBox
    isEmpty={
      !_.find(data, (v) => typeof v.value === 'string') &&
      _.sumBy(data, (v) => Number(v.value)) === 0
    }
    text={noDataText}
  >
    <Grid
      container
      justifyContent="space-between"
      p={7}
      pl={6}
      columnSpacing={10}
    >
      <Grid item xs={6}>
        <ResponsiveContainer width="100%" aspect={1}>
          <PieChart>
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize={16}
              fontWeight={500}
            >
              {total}
            </text>
            <Pie
              data={data}
              dataKey="value"
              outerRadius="100%"
              innerRadius="85%"
              startAngle={90}
              endAngle={-270}
              isAnimationActive={false}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${entry.toString()}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item display="flex" alignItems="center" xs={6}>
        <Stack spacing={2} flexGrow={1}>
          {data.map((item, idx) => (
            <Box key={item.name} display="flex" justifyContent="space-between">
              <Typography
                variant="caption"
                sx={{
                  '&:before': {
                    content: '"\\A"',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    background: COLORS[idx % COLORS.length],
                    display: 'inline-block',
                    marginRight: '8px',
                  },
                }}
              >
                {item.name}
              </Typography>
              <Typography variant="caption">
                {valueFormatter ? valueFormatter(item.value) : item.value}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Grid>
    </Grid>
  </ChartCoverBox>
);
ReportSummaryChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]).isRequired,
    }).isRequired,
  ).isRequired,
  total: PropTypes.string.isRequired,
  valueFormatter: PropTypes.func,
  noDataText: PropTypes.string,
};
ReportSummaryChart.defaultProps = {
  valueFormatter: undefined,
  noDataText: undefined,
};

export default React.memo(ReportSummaryChart);
