/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import {
  PlannerCoverageChart,
  PlannerCoverageDetailChart,
} from '@components/chart/planner';
import { usePlannerChart } from '@hooks/api/planner';

const PlannerChart: React.FC<{ type: 'OD' | 'RI' | 'SP' }> = ({ type }) => {
  const coverageActive = useMemo<0 | 1 | 2>(() => {
    switch (type) {
      case 'RI':
        return 1;
      case 'SP':
        return 2;
      default:
        return 0;
    }
  }, [type]);
  const { data } = usePlannerChart();
  if (!data?.items?.length) {
    return null;
  }
  const { Coverage, UncoveredInstance, ReservedIntance, SavingsPlan } =
    data.items[0];
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      columns={16}
      columnSpacing={6}
    >
      <Grid item xs={6}>
        <PlannerCoverageChart
          data={[
            { value: Coverage.Ondemand },
            { value: Coverage.Reserved },
            { value: Coverage.SavingsPlan },
          ]}
          active={coverageActive}
        />
      </Grid>
      <Grid item xs={10}>
        <PlannerCoverageDetailChart
          type={type}
          data={
            type === 'OD'
              ? UncoveredInstance
              : type === 'RI'
              ? ReservedIntance
              : SavingsPlan
          }
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(PlannerChart);
