import { useMutation } from 'react-query';
import { OptimizationService } from '@api/service';
import { QUERY_KEY } from '@constants';

export const useOptimizationDownloadCSV = () =>
  useMutation({
    mutationKey: QUERY_KEY.OPTIMIZATION.CSV,
    mutationFn: (variables: {
      url: string;
      filter?: string;
      sort?: string;
      apm?: string;
      period?: string;
    }) =>
      OptimizationService.downloadOptimizationCSV(
        variables.url,
        variables.filter,
        variables.sort,
        variables.apm,
        variables.period,
      ),
  });
