import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataPaginationTable, GrmtTableColumnInfo } from '@components/table';
import { ValueFormatter } from '@utils';
import { useOptimizationAnomaly } from '@hooks/api/optimization/anomaly';
import { useAWSHelper } from '@hooks/helper';
import { useToggle } from '@hooks/common';
import { DetailButtonIcon } from '@components/table/table.styled';
import AnomalySummaryFilter from './AnomalySummaryFilter';
import AnomalySummaryDetailsModal from '../modal/AnomalySummaryDetailsModal';

const AnomalySummaryTable: React.FC = () => {
  const { t } = useTranslation('optimization');
  const { getOptimizationAWSRegion } = useAWSHelper();
  const { data, onPageChange } = useOptimizationAnomaly();
  const [open, toggle] = useToggle();
  const [anomaly, setAnomaly] = useState<OptimizationAnomaly | undefined>(
    undefined,
  );

  const columnInfo = useMemo<Array<GrmtTableColumnInfo>>(
    () => [
      {
        Header: t('label.anomaly.detected'),
        accessor: 'DetectedDate' as const,
        minWidth: 152,
        maxWidth: 152,
        disableSortBy: true,
        Cell: ({ value }) =>
          ValueFormatter.toDateString(value, 'YYYY-MM-DD HH:mm'),
      },
      {
        Header: t('label.common.accountId'),
        accessor: 'AccountId' as const,
        minWidth: 130,
        maxWidth: 130,
        disableSortBy: true,
      },
      {
        Header: t('label.common.region'),
        accessor: 'Region' as const,
        minWidth: 140,
        maxWidth: 140,
        disableSortBy: true,
        Cell: ({ value }) => getOptimizationAWSRegion(value),
      },
      {
        Header: t('label.common.service'),
        accessor: 'Service' as const,
        maxWidth: 320,
        disableSortBy: true,
      },
      {
        Header: t('label.common.service_type'),
        accessor: 'Usage' as const,
        maxWidth: 320,
        disableSortBy: true,
      },
      {
        Header: t('label.anomaly.excessCost'),
        accessor: 'AnomalySpend' as const,
        align: 'right',
        minWidth: 128,
        maxWidth: 128,
        Cell: ({ value }) => ValueFormatter.toCurrency(value),
      },
      {
        Header: t('label.common.details'),
        align: 'center',
        minWidth: 84,
        maxWidth: 84,
        Cell: ({ row }) => (
          <DetailButtonIcon
            onClick={() => {
              setAnomaly(row.original as OptimizationAnomaly);
              toggle();
            }}
          />
        ),
      },
    ],
    [getOptimizationAWSRegion, setAnomaly, toggle],
  );

  return (
    <>
      <DataPaginationTable
        TitleElement={t('label.anomaly.detectedAnomalies')}
        titleColor="textPrimary"
        data={data?.body?.items}
        columnInfo={columnInfo}
        totalCount={data?.body?.totalCount}
        onPageChange={onPageChange}
        FilterElement={AnomalySummaryFilter}
        enableSort
      />
      {open && anomaly && (
        <AnomalySummaryDetailsModal
          open={open}
          onClose={toggle}
          anomaly={anomaly}
        />
      )}
    </>
  );
};

export default AnomalySummaryTable;
