import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyService } from '@api/service';
import { GLOBAL } from '@constants';
import { useSnackbar } from '@hooks/common';
import { ROUTES } from '@routes';
import { useTranslation } from 'react-i18next';

const VerifyCompanyPage: React.FC = () => {
  const { t } = useTranslation('toast');
  const { id } = useParams();
  const navigate = useNavigate();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const verifyCompany = useCallback(async () => {
    if (id) {
      try {
        const data = await CompanyService.verifyCompany(id);
        if (data.items?.length && data.items[0]) {
          const body = data.items[0];
          localStorage.setItem(GLOBAL.ACCESS_TOKEN, body.access_token);
          showSuccessSnackbar(t('success.auth.verify'));
          navigate(ROUTES.OVERVIEW.ROOT, { replace: true });
          return;
        }
        showErrorSnackbar(t('error.auth.verify.general'));
        navigate(ROUTES.AUTH.SIGNIN, { replace: true });
      } catch (e) {
        showErrorSnackbar(t('error.auth.verify.general'));
        navigate(ROUTES.AUTH.SIGNIN, { replace: true });
      }
    }
  }, [id]);

  useEffect(() => {
    verifyCompany();
  }, [id]);

  return <></>;
};

export default VerifyCompanyPage;
