const overviewRoutes = {
  ROOT: '/overview',
} as const;

const onboardRoutes = {
  CONNECT: '/onboard/connect',
  DIAGNOSTIC: '/onboard/diagnostic',
  CUR: '/onboard/cur',
  REPORT: '/onboard/diagnostic/report',
} as const;

const computeRoutes = {
  UTIL: '/optimization/compute/utilization',
  PURCHASE: '/optimization/compute/purchasing',
  TRANSFER: '/optimization/compute/transfer',
  OTHER: '/optimization/compute/other',
} as const;

const databaseRoutes = {
  UTIL: '/optimization/database/utilization',
  PURCHASE: '/optimization/database/purchasing',
  STORAGE: '/optimization/database/storage',
} as const;

const storageRoutes = {
  ROOT: '/optimization/storage',
} as const;

const miscellaneousRoutes = {
  ROOT: '/optimization/miscellaneous',
} as const;

const volumeRoutes = {
  ROOT: '/optimization/volume',
} as const;

const detailsRoutes = {
  COMPUTE: '/details/compute',
  DATABASE: '/details/database',
  STORAGE: '/details/storage',
  VOLUME: '/details/volume',
} as const;

const anomalyRoutes = {
  SUMMARY: '/anomaly/summary',
  SETTINGS: '/anomaly/settings',
} as const;

const plannerRoutes = {
  UNCOVERED: '/planner/uncovered',
  RI: '/planner/reserved',
  SP: '/planner/committed',
  SIMULATION: '/planner/simulation',
  SIMULATION_UNCOVERED: '/planner/simulation/uncovered',
} as const;

const settingsRoutes = {
  PROFILE: '/settings/profile',
  ACCOUNTS: '/settings/accounts',
  ACCOUNTS_ORGANIZATION: '/settings/accounts/organizations',
  ACCOUNTS_DETAIL: '/settings/accounts/details',
  USERS: '/settings/users',
  PAYMENT: '/settings/payment',
  INTEGRATION: '/settings/integration',
} as const;

const adminRoutes = {
  COMPANIES: '/admin/companies',
  SUBSCRIPTIONS: '/admin/subscriptions',
} as const;

const notificationRoutes = {
  ROOT: '/notifications',
} as const;

const externalRoutes = {
  ROOT: '/external',
  MASTER: '/external/master',
  LINKED: '/external/linked',
};

const billingRoutes = {
  ROOT: '/report/billing',
};

const usageRoutes = {
  ROOT: '/report/usage',
};

const PRIVATE_ROUTES = {
  OVERVIEW: overviewRoutes,
  ONBOARD: onboardRoutes,
  COMPUTE: computeRoutes,
  DATABASE: databaseRoutes,
  STORAGE: storageRoutes,
  MISCELLANEOUS: miscellaneousRoutes,
  VOLUME: volumeRoutes,
  DETAILS: detailsRoutes,
  ANOMALY: anomalyRoutes,
  PLANNER: plannerRoutes,
  SETTINGS: settingsRoutes,
  ADMIN: adminRoutes,
  NOTIFICATION: notificationRoutes,
  EXTERNAL: externalRoutes,
  BILLING: billingRoutes,
  USAGE: usageRoutes,
} as const;

type OverviewRoutes = ValueOf<typeof overviewRoutes>;
type OnboardRoutes = ValueOf<typeof onboardRoutes>;
type ComputeRoutes = ValueOf<typeof computeRoutes>;
type DatabaseRoutes = ValueOf<typeof databaseRoutes>;
type StorageRoutes = ValueOf<typeof storageRoutes>;
type VolumeRoutes = ValueOf<typeof volumeRoutes>;
type MiscellaneousRoutes = ValueOf<typeof miscellaneousRoutes>;
type DetailsRoutes = ValueOf<typeof detailsRoutes>;
type AnomalyRoutes = ValueOf<typeof anomalyRoutes>;
type SettingsRoutes = ValueOf<typeof settingsRoutes>;
type AdminRoutes = ValueOf<typeof adminRoutes>;
type NotificationRoutes = ValueOf<typeof notificationRoutes>;
type BillingRoutes = ValueOf<typeof billingRoutes>;
type UsageRoutes = ValueOf<typeof usageRoutes>;

export type PrivateRouteValues =
  | OverviewRoutes
  | OnboardRoutes
  | ComputeRoutes
  | DatabaseRoutes
  | StorageRoutes
  | VolumeRoutes
  | MiscellaneousRoutes
  | DetailsRoutes
  | AnomalyRoutes
  | SettingsRoutes
  | AdminRoutes
  | NotificationRoutes
  | BillingRoutes
  | UsageRoutes;

export default PRIVATE_ROUTES;
