import axios from '@utils/axios';
import { API_URL } from '@constants';

const getById = (id: string) => axios.get(`/promotion/${id}`);

const updatePromotion = (promotionCode: any) =>
  axios.put('/promotion', promotionCode);

const getPromotions = async (): Promise<CCQueryResponse<PromotionType>> => {
  const { data } = await axios.get(`${API_URL.PAYMENT}/promotion`);
  return data;
};

const createPromotion = async (
  CouponId: string,
  Code: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.post(`${API_URL.PAYMENT}/promotion`, {
    CouponId,
    Code,
  });
  return data;
};

const deletePromotion = async (
  PromotionId: string,
): Promise<CCQueryResponse<any>> => {
  const { data } = await axios.delete(
    `${API_URL.PAYMENT}/promotion/${PromotionId}`,
  );
  return data;
};

export default {
  getById,
  getPromotions,
  createPromotion,
  updatePromotion,
  deletePromotion,
};
