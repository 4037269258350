import React, { useCallback } from 'react';
import { useDeleteAnomalyNotification } from '@hooks/api/optimization/anomaly';
import { CCModalProps, ConfirmDialog } from '@components/modal';
import { useSnackbar } from '@hooks/common';
import { useTranslation } from 'react-i18next';

const DeleteConditionModal: React.FC<CCModalProps & { anomalyId: string }> = ({
  open,
  onClose,
  anomalyId,
}) => {
  const { t } = useTranslation('dialog');
  const { t: commonT } = useTranslation('common');
  const { t: toastT } = useTranslation('toast');
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { mutateAsync } = useDeleteAnomalyNotification();

  const deleteAnomaly = useCallback(async () => {
    try {
      await mutateAsync({ anomalyId });
      showSuccessSnackbar(toastT('success.anomaly.condition.delete'));
      onClose();
    } catch (e) {
      showErrorSnackbar(toastT('error.general'));
    }
  }, [anomalyId]);
  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={t('anomaly.delete.title')}
      content={t('anomaly.delete.content')}
      Actions={[
        {
          color: 'inherit',
          onClick: onClose,
          label: commonT('button.cancel'),
        },
        {
          onClick: deleteAnomaly,
          label: commonT('button.delete'),
        },
      ]}
    />
  );
};

export default DeleteConditionModal;
