import React, { useMemo } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { OnboardStepper } from '@pages/private/onboard/Onboard.styled';
import { ReactComponent as CURIcon } from '@assets/icons/onboard/CUR.svg';
import { ReactComponent as LinkIcon } from '@assets/icons/onboard/Link.svg';
import { bluegrey } from '@theme/colors';
import { useSearchParams } from 'react-router-dom';
import { externalStepState } from './ExternalAccount.atoms';
import {
  ExternalConfigureCURStep,
  ExternalOpenCloudShellStep,
  ExternalRunScriptStep,
  ExternalVerifyRoleStep,
} from './steps';

const ExternalSteps: React.FC<{ isPayer: boolean }> = ({ isPayer }) => {
  const { t } = useTranslation('onboard');
  const [searchParams] = useSearchParams();
  const activeStep = useRecoilValue(externalStepState);
  const steps = useMemo<Array<string>>(() => {
    const arr = [
      t('step.openShell'),
      t('step.runScript'),
      t('step.verifyRole'),
    ];
    if (isPayer) {
      arr.push(t('step.configure'));
    }
    return arr;
  }, [isPayer, t]);
  return (
    <Box>
      <Box display="flex" pl={9} py={5}>
        {isPayer ? <CURIcon /> : <LinkIcon />}
        <Typography variant="subtitle2" ml={1}>
          {isPayer ? t('title.setup') : t('title.connect')}
        </Typography>
      </Box>
      <Divider sx={{ borderBottomWidth: '1px', borderColor: bluegrey[300] }} />
      <Box p={7}>
        {activeStep < 4 && (
          <OnboardStepper activeStep={activeStep} stepLabels={steps} shrink />
        )}
        <Box mt={7} mb={7}>
          {activeStep === 0 && <ExternalOpenCloudShellStep />}
          {activeStep === 1 && <ExternalRunScriptStep />}
          {activeStep === 2 && <ExternalVerifyRoleStep isPayer={isPayer} />}
          {activeStep === 3 && <ExternalConfigureCURStep />}
          {activeStep === 4 && (
            <Box mx={1}>
              <Typography variant="h3">
                {t('external.title.success')}
              </Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: t('external.description.success', {
                    ACCOUNT_ID: searchParams.get('to'),
                    URL: '/docs/getting-started',
                  }),
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ExternalSteps;
