import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ChartDefaultGradient,
  GrmtAxisDefaultProps,
  GrmtDefaultChartProps,
  GrmtGridDefaultProps,
} from '@components/chart';
import shadows from '@theme/shadows';
import { ValueFormatter } from '@utils';

type SimpleUsageChartProps = GrmtDefaultChartProps & {
  Granularity: CEGranularity;
};

function SimpleUsageChart({ data, Granularity }: SimpleUsageChartProps) {
  const { t } = useTranslation('optimization');
  return (
    <Box>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          barSize={24}
          margin={{ top: 8, bottom: 8, left: 24 }}
        >
          {ChartDefaultGradient}
          <XAxis
            dataKey="Date"
            interval={Granularity === 'MONTHLY' ? 0 : 1}
            tickFormatter={(v) =>
              ValueFormatter.toDateString(
                v,
                Granularity === 'MONTHLY' ? 'YYYY.MM' : 'M/DD',
              )
            }
            {...GrmtAxisDefaultProps}
          />
          <YAxis
            scale="linear"
            {...GrmtAxisDefaultProps}
            tickFormatter={(value) => ValueFormatter.toLocaleString(value, 0)}
            padding={{ top: 16, bottom: 16 }}
            label={{
              value: t('label.chart.common.cost'),
              position: 'insideLeft',
              angle: -90,
              offset: -16,
              stroke: 'none',
              fill: '#A0A1B2',
              fontSize: '12px',
              fontWeight: 400,
            }}
          />
          <CartesianGrid {...GrmtGridDefaultProps} />
          <Tooltip
            cursor={false}
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const { payload: item } = payload[0];
                return (
                  <Box
                    sx={{
                      p: '8px',
                      borderRadius: '4px',
                      backgroundColor: '#ffffff',
                      boxShadow: shadows[2],
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '16px' }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {ValueFormatter.toDateString(
                          item.Date,
                          Granularity === 'MONTHLY' ? 'YYYY.MM' : 'M/DD',
                        )}
                      </Typography>
                      <Typography variant="subtitle2">
                        {ValueFormatter.toCurrency(item.Cost)}
                      </Typography>
                    </Box>
                  </Box>
                );
              }
              return null;
            }}
          />
          <Bar dataKey="Cost" fill="#9594ff" radius={4} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default SimpleUsageChart;
