import { useMutation } from 'react-query';
import { AWSAccountService } from '@api/service';
import { QUERY_KEY } from '@constants';

export default () =>
  useMutation({
    mutationKey: QUERY_KEY.ONBOARD.AUTHORIZE_EXTERNAL,
    mutationFn: (variables: { from: string; to: string; hash: string }) =>
      AWSAccountService.authorizeExternal(
        variables.from,
        variables.to,
        variables.hash,
      ),
  });
