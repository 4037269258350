import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Box, Chip, chipClasses, Typography } from '@mui/material';
import { ArrowDropDown, CalendarToday } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ceUsageState } from '@atoms/aws';
import { BoxProps } from '@mui/material/Box';

type FilterWithChipProps = {
  title: React.ReactNode;
  length: number;
  isExcluded: boolean;
};
function FilterWithChip({ title, length, isExcluded }: FilterWithChipProps) {
  const { t } = useTranslation('usage');
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Typography variant="body2">{title}</Typography>
      {length > 0 && (
        <Chip
          label={t(`filter.${isExcluded ? 'excluded' : 'included'}`, {
            count: length,
          })}
          sx={(theme) => ({
            height: '20px',
            borderRadius: '999px',
            backgroundColor: theme.palette.primary.main,
            ml: '8px',
            [`> .${chipClasses.label}`]: {
              color: '#ffffff',
              padding: '1px 10px',
            },
          })}
        />
      )}
      <ArrowDropDown sx={{ width: '16px', height: '16px' }} />
    </Box>
  );
}

type ReportFilterViewProps = Pick<BoxProps, 'onClick'>;
export default function ReportFilterView({ onClick }: ReportFilterViewProps) {
  const { t } = useTranslation('usage');
  const filter = useRecoilValue(ceUsageState);
  const groupBy = useMemo(() => {
    switch (filter.GroupBy) {
      case 'SERVICE':
        return t('filter.by_service');
      case 'TAG':
        return t('filter.by_tag');
      case 'LINKED_ACCOUNT':
        return t('filter.by_linked_account');
      case 'PURCHASE_TYPE':
        return t('filter.by_purchase_type');
      default:
        return t('filter.by_none');
    }
  }, [filter.GroupBy]);
  const granularity = useMemo(() => {
    switch (filter.Granuality) {
      case 'MONTHLY':
        return t('filter.monthly');
      case 'DAILY':
        return t('filter.daily');
      default:
        return '';
    }
  }, [filter.Granuality]);
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '16px',
        cursor: 'pointer',
        userSelect: 'none',
      }}
    >
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <CalendarToday
          sx={{ width: '16px', height: '16px', color: '#737480' }}
        />
        <Typography variant="body2">
          {moment(filter.FromDate).format('YYYY/MM/DD')}
        </Typography>
        <Typography variant="body2">~</Typography>
        <Typography variant="body2">
          {moment(filter.ToDate).format('YYYY/MM/DD')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <Typography variant="body2">{groupBy}</Typography>
        <ArrowDropDown sx={{ width: '16px', height: '16px' }} />
      </Box>
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <Typography variant="body2">{granularity}</Typography>
        <ArrowDropDown sx={{ width: '16px', height: '16px' }} />
      </Box>
      <FilterWithChip
        title={t('filter.service')}
        length={filter.ServicesExclude?.length ?? filter.Services?.length ?? 0}
        isExcluded={typeof filter.ServicesExclude !== 'undefined'}
      />
      <FilterWithChip
        title={t('filter.linked_account')}
        length={filter.AccountIdList?.length ?? 0}
        isExcluded={false}
      />
      <FilterWithChip
        title={t('filter.linked_companies')}
        length={
          filter.LinkedCompaniesExclude?.length ??
          filter.LinkedCompanies?.length ??
          0
        }
        isExcluded={typeof filter.LinkedCompaniesExclude !== 'undefined'}
      />
      <FilterWithChip
        title={t('filter.instance_type')}
        length={
          filter.InstanceTypeExclude?.length ?? filter.InstanceType?.length ?? 0
        }
        isExcluded={typeof filter.InstanceTypeExclude !== 'undefined'}
      />
      <FilterWithChip
        title={t('filter.usage_type')}
        length={
          filter.UsageTypeExclude?.length ?? filter.UsageType?.length ?? 0
        }
        isExcluded={typeof filter.UsageTypeExclude !== 'undefined'}
      />
      <FilterWithChip
        title={t('filter.usage_type')}
        length={
          filter.UsageTypeGroupExclude?.length ??
          filter.UsageTypeGroup?.length ??
          0
        }
        isExcluded={typeof filter.UsageTypeGroupExclude !== 'undefined'}
      />
      <FilterWithChip
        title={t('filter.tag')}
        length={
          filter.TagsExclude?.flatMap((v) => v.Items).length ??
          filter.Tags?.flatMap((v) => v.Items).length ??
          0
        }
        isExcluded={typeof filter.TagsExclude !== 'undefined'}
      />
    </Box>
  );
}
