import { useMutation } from 'react-query';
import { QUERY_KEY } from '@constants';
import { DiagnosticService } from '@api/service';

export default () =>
  useMutation({
    mutationKey: QUERY_KEY.ONBOARD.RUN_DIAGNOSTIC,
    mutationFn: (variables: { id: string; regions: string[] }) =>
      DiagnosticService.requestDiagnostic(variables.id, variables.regions),
  });
