import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useResetRecoilState } from 'recoil';
import { companyIdState, userIdState } from '@atoms/global';
import { GLOBAL } from '@constants';

export default () => {
  const resetUserId = useResetRecoilState(userIdState);
  const resetCompanyId = useResetRecoilState(companyIdState);
  const queryClient = useQueryClient();
  useEffect(() => {
    resetUserId();
    resetCompanyId();
    queryClient.clear();
    localStorage.removeItem(GLOBAL.ACCESS_TOKEN);
    localStorage.removeItem(GLOBAL.REFRESH_TOKEN);
    localStorage.removeItem(GLOBAL.REFRESH_TOKEN_EXPIRED);
  }, []);
};
