import React, { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CloudShellTutorial } from '@components/ui';
import { useTagManager } from '@hooks/common';
import { connectAWSStepState } from '../ConnectAWS.atoms';

const OpenCloudShellStep: React.FC = () => {
  const { t } = useTranslation('onboard');
  const { t: commonT } = useTranslation('common');
  const [clicked, setClicked] = useState(false);
  const setActive = useSetRecoilState(connectAWSStepState);
  const { sendDataLayer } = useTagManager();
  const handleClick = useCallback(() => {
    sendDataLayer('cc-onboard-connect-shell-next');
    setActive((v) => v + 1);
  }, [sendDataLayer, setActive]);
  return (
    <Box>
      <Grid container spacing={5}>
        <Grid item lg={6}>
          <Typography variant="h3">AWS CloudShell</Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            {t('description.cloudShell')}
          </Typography>
          <Button
            size="large"
            color="secondary"
            sx={{ mt: 6 }}
            onClick={() => {
              window.open(
                'https://ap-northeast-1.console.aws.amazon.com/cloudshell',
                '_blank',
              );
              setClicked(true);
            }}
          >
            {t('button.openShell')}
          </Button>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 4 }}>
            * {t('description.openShell')}
          </Typography>
        </Grid>
        <Grid item lg={6}>
          <CloudShellTutorial />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end" mt={7}>
        <Button
          size="large"
          color="secondary"
          disabled={!clicked}
          onClick={handleClick}
        >
          {commonT('button.next')}
        </Button>
      </Box>
    </Box>
  );
};

export default OpenCloudShellStep;
