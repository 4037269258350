import React from 'react';
import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SuspenseBox } from '@components/styled/suspense';
import { WithAdmin } from '@hooks/hoc';
import { SettingsUserTable } from './table';

const SettingsUserPage: React.FC = () => {
  const { t } = useTranslation('settings');
  return (
    <Paper sx={{ px: 7, py: 5 }}>
      <Typography mb={9}>{t('user.description')}</Typography>
      <SuspenseBox>
        <SettingsUserTable />
      </SuspenseBox>
    </Paper>
  );
};

export default WithAdmin(SettingsUserPage);
