import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import TagManager from 'react-gtm-module';
import useHotjar from 'react-use-hotjar';

export default () => {
  const {
    initHotjar,
    stateChange: hjStateChange,
    readyState: hjInitialized,
  } = useHotjar();
  const [initialized, setInitialized] = useState(false);
  const [ga4Initialized, setGa4Initialized] = useState(false);
  const { pathname, search } = useLocation();
  useEffect(() => {
    setTimeout(() => {
      if (process.env.REACT_APP_GA_TRACKING_ID) {
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
        setInitialized(true);
      }
      if (process.env.REACT_APP_GA4_TRACKING_ID) {
        ReactGA4.initialize(process.env.REACT_APP_GA4_TRACKING_ID);
        setGa4Initialized(true);
      }
      if (process.env.REACT_APP_GTM_ID) {
        TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
      }
      if (process.env.REACT_APP_HORJAR_ID && process.env.REACT_APP_HORJAR_SV) {
        initHotjar(
          Number(process.env.REACT_APP_HORJAR_ID),
          Number(process.env.REACT_APP_HORJAR_SV),
          process.env.NODE_ENV !== 'production',
        );
      }
    }, 0);
  }, []);
  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(`/console${pathname}${search}`);
    }
    if (ga4Initialized) {
      ReactGA4.send({
        hitType: 'pageview',
        page: `/console${pathname}${search}`,
      });
    }
    if (hjInitialized) {
      hjStateChange(`console${pathname}${search}`);
    }
  }, [initialized, ga4Initialized, hjInitialized, pathname, search]);
};
