import { TypographyOptions } from '@mui/material/styles/createTypography';

const costClipperTypography: TypographyOptions = {
  fontSize: 16,
  htmlFontSize: 16,
  fontFamily: ['"NotoSans"', '"Helvetica"', '"Arial"', 'sans-serif'].join(','),
  h1: {
    fontSize: '3rem',
    lineHeight: '4rem',
    fontWeight: 700,
  },
  h2: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
    fontWeight: 700,
  },
  h3: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
  },
  caption: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    fontWeight: 400,
  },
  footer: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    fontWeight: 400,
  },
  button: {
    textTransform: 'none',
  },
};

export default costClipperTypography;
