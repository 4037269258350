import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  usePaymentMethod,
  usePurchaseProduct,
  useRecommendedTier,
  useValidatePromotion,
} from '@hooks/api/settings/payment';
import { bluegrey } from '@theme/colors';
import { ValueFormatter } from '@utils';
import { TypoWithLink, TooltipTypography } from '@components/styled';
import { useSnackbar, useToggle, useTagManager } from '@hooks/common';
import ManageCard from '@pages/private/settings/payment/components/card/ManageCard';
import { QuestionTooltip } from '@components/styled/tooltip';
import { useSubscribeForm } from './Subscribe.form';
import AddNewCard from '../card/AddNewCard';

const Subscribe: React.FC = () => {
  const { data } = useRecommendedTier();
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const { t: toastT } = useTranslation('toast');
  const { showSuccessSnackbar } = useSnackbar();
  const [openCardList, toggleCardList] = useToggle();
  const { data: cardData } = usePaymentMethod();
  const [openAdd, toggleAdd] = useToggle();
  const { sendDataLayer } = useTagManager();
  const {
    isSuccess: isPromotionValidated,
    isLoading: isPromotionFetching,
    data: promotionData,
    reset: resetPromotion,
    mutateAsync: fetchValidate,
  } = useValidatePromotion();
  const {
    mutateAsync: fetchPurchase,
    isLoading: isPurchaseLoading,
    isSuccess: isPurchaseValidated,
  } = usePurchaseProduct();

  const { initialValues, validationSchema } = useSubscribeForm();
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (v) => {
      try {
        await fetchPurchase({
          ...v,
          PromotionCode: isPromotionValidated ? v.PromotionCode : undefined,
        });
        sendDataLayer('cc-subscribe');
        showSuccessSnackbar(toastT('success.settings.payment.purchased'));
      } catch (e) {
        console.error(e);
      }
    },
  });

  const validatePromotion = useCallback(async () => {
    if (data?.items.length) {
      try {
        await fetchValidate({
          ProductId: data.items[0].PlanDetails.UID,
          PromotionCode: values.PromotionCode,
        });
      } catch (e) {
        setFieldError('PromotionCode', 'Invalid Promotion Code');
      }
    }
  }, [data?.items, values.PromotionCode]);

  useEffect(() => {
    if (data?.items.length) {
      setFieldValue('ProductId', data.items[0].PlanDetails.UID);
      setFieldTouched('ProductId');
    }
  }, [data?.items]);

  useEffect(() => {
    if (cardData?.items?.length) {
      const defaultCard = cardData.items.find((v) => v.IsDefault);
      if (defaultCard) {
        setFieldValue('PaymentMethod', defaultCard.UID);
        setTimeout(() => setFieldTouched('PaymentMethod', true));
      }
    }
  }, [cardData?.items, cardData?.items?.length]);
  if (!data?.items?.length) {
    return null;
  }
  return (
    <>
      <form id="cc_subscribe" onSubmit={handleSubmit}>
        <Grid container>
          <Grid
            container
            item
            alignItems="center"
            columnSpacing={2}
            rowSpacing={3}
            xs
          >
            <Grid item display="flex" alignItems="center" xs={12}>
              <Typography mr={1}>
                {t('payment.label.tier')}: {data.items[0].RecommendPlan}
              </Typography>
              <QuestionTooltip
                title={
                  <TooltipTypography
                    variant="body2"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: `${t('payment.tier.title')}<br/><br/>${t(
                        `payment.tier.${
                          data.items[0].RecommendPlan as PricingTier
                        }`,
                      )}`,
                    }}
                  />
                }
              />
            </Grid>
            <Grid item display="flex" alignItems="center" xs={12}>
              <Typography mr={1}>
                {t('payment.label.term')}:{' '}
                {ValueFormatter.toCurrency(
                  data.items[0].IncommingFee - data.items[0].PlanDetails.Amount,
                ) || '-'}
              </Typography>
              <QuestionTooltip
                title={
                  <TooltipTypography
                    variant="body2"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: t('payment.label.term', { context: 'tooltip' }),
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={3}>
              <Typography>
                {t('payment.label.promotion', { context: 'code' })}:
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                name="PromotionCode"
                value={values.PromotionCode}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isPromotionFetching || isPromotionValidated}
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                error={Boolean(touched.PromotionCode && errors.PromotionCode)}
                helperText={
                  Boolean(touched.PromotionCode && errors.PromotionCode) &&
                  String(errors.PromotionCode)
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <LoadingButton
                variant="outlined"
                size="large"
                loading={isPromotionFetching}
                onClick={
                  isPromotionValidated ? resetPromotion : validatePromotion
                }
              >
                {isPromotionValidated
                  ? commonT('button.cancel')
                  : commonT('button.apply')}
              </LoadingButton>
            </Grid>
            <Grid item xs={3}>
              <Typography>
                {t('payment.label.promotion', { context: 'discount' })}:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                {isPromotionValidated && promotionData?.extras
                  ? ValueFormatter.toCurrency(
                      promotionData.extras.IncommingFee -
                        data.items[0].IncommingFee,
                    )
                  : '$0.00'}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>
                {t('payment.label.payment', { context: 'method' })}:
              </Typography>
            </Grid>
            {Boolean(cardData?.items?.length) && (
              <Grid item xs={5}>
                <TextField
                  name="PaymentMethod"
                  value={values.PaymentMethod}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  select
                  fullWidth
                >
                  {cardData?.items.map((card) => (
                    <MenuItem key={card.UID} value={card.UID}>
                      {t('payment.label.card', {
                        context: 'format',
                        card: card.Brand.toUpperCase(),
                        last: card.Last4Digits,
                      })}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={4}>
              {Number(cardData?.items.length) === 0 && (
                <Button variant="text" size="large" onClick={toggleAdd}>
                  {`+ ${t('payment.label.card_add')}`}
                </Button>
              )}
              {Number(cardData?.items.length) > 0 && (
                <Button variant="text" size="large" onClick={toggleCardList}>
                  {t('payment.label.manageCard')}
                </Button>
              )}
            </Grid>
            <Box mt={5}>
              <TypoWithLink
                variant="caption"
                dangerouslySetInnerHTML={{
                  __html: t('payment.label.policy', {
                    tos: 'https://www.grumatic.com/terms-of-service',
                    pp: 'https://www.grumatic.com/privacy-policy',
                  }),
                }}
              />
            </Box>
          </Grid>
          <Box borderLeft="1px solid" borderColor={bluegrey[300]} mx={14} />
          <Grid item>
            <Typography variant="h3" mb={1}>
              {t('payment.label.amount', { context: 'total' })}
            </Typography>
            <Typography variant="h1" color="primary" mb={5}>
              {isPromotionValidated && promotionData?.extras
                ? ValueFormatter.toCurrency(promotionData.extras.IncommingFee)
                : ValueFormatter.toCurrency(data.items[0].IncommingFee)}
            </Typography>
            <LoadingButton
              type="submit"
              variant="outlined"
              size="large"
              disabled={!isValid || isPurchaseLoading || isPurchaseValidated}
              loading={isPurchaseLoading}
              fullWidth
            >
              {t('payment.title.subscribe')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      <ManageCard open={openCardList} />
      {openAdd && <AddNewCard open={openAdd} onClose={toggleAdd} />}
    </>
  );
};

export default Subscribe;
