import { atom } from 'recoil';
import moment from 'moment';
import { ATOM_KEY } from '@constants';

export const apiStartDateState = atom<string>({
  key: ATOM_KEY.GLOBAL.API_START_DATE,
  default: moment().subtract(1, 'months').format('YYYY-MM-DDTHH:00:00'),
});

export const apiEndDateState = atom<string>({
  key: ATOM_KEY.GLOBAL.API_END_DATE,
  default: moment().format('YYYY-MM-DDTHH:00:00'),
});
